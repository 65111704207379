<div [formGroup]="form">
  <app-delivery-seats-checkbox
    [form]="form"
    [invoiceId]="invoiceId"
    [isTemplate]="isTemplate"
  ></app-delivery-seats-checkbox>
  <div [ngSwitch]="form.get('paramsCommonSeats').value">
    <app-delivery-cargo-common
      *ngSwitchCase="true"
      [form]="form"
      [invoiceId]="invoiceId"
      [isTemplate]="isTemplate"
    ></app-delivery-cargo-common>
    <app-delivery-cargo-places
      *ngSwitchCase="false"
      [form]="form"
      [invoiceId]="invoiceId"
      [isTemplate]="isTemplate"
    ></app-delivery-cargo-places>
  </div>
</div>

import { GetCountriesData } from '@shared';
import { LatinLettersTooltip } from '../components/addresses/city-select-international/city-select.constants';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';

// Great Britain
export const GB: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
  CityTooltip: LatinLettersTooltip,
  RecipientSpotSelectionAvailable: true,
};

<div [formGroup]="form" class="form-col form-col-8">
  <mat-form-field class="mat-block">
    <mat-label>{{label}}</mat-label>
       <textarea matInput
                 InputTrim
                 (paste)="blockUnicode($event)"
                 [formControlName]="controlName"
                 class="delivery-additional-info__textarea"
                 cdkTextareaAutosize
                 autoScrollTarget></textarea>
    <mat-error>
      {{getError(control, errors)}}
    </mat-error>
  </mat-form-field>
</div>

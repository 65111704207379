import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AutocompleteWithControlsConfig } from '@autocomplete-with-controls';
import { AbstractFormContainer } from '@shared';
import { defSeat, options } from './delivery-pallets.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-pallets',
  templateUrl: './delivery-pallets.component.html',
  styleUrls: ['./delivery-pallets.component.scss'],
})
export class DeliveryPalletsComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input() controlName = 'OptionsSeat';
  @Input() isTemplate: boolean;
  @Input() invoiceId: string;
  @Input()
  configDescription: AutocompleteWithControlsConfig = {
    ariaLabel: 'Параметри палет',
    placeholder: 'Параметри палет',
    arrow: true,
  };

  defSeat = defSeat;
  seats = [defSeat];
  seatsAmount = 0;
  optionsSeats = [];
  options = options;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  @Input()
  set optionsSeat(opts) {
    if (!opts) {
      return;
    }
    const { optionsVar, amounts } = opts;
    this.seats = optionsVar
      .map((i) => {
        return {
          amount: amounts[i],
          option: this.options.find((opt) => opt.volumetricWeight === i),
        };
      })
      .filter((i) => i.option && i.amount);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  comparePallets(option, value) {
    return (
      option && value && option.volumetricWeight === value.volumetricWeight
    );
  }

  changeWatcher() {
    if (this.seats) {
      const res = this.seats.reduce(
        (mem, seat) => {
          if (seat.amount && seat.option) {
            mem.amount += +seat.amount;
            mem.options = mem.options.concat(
              this.fillArray([], seat.option, +seat.amount),
            );
          }
          return mem;
        },
        { options: [], amount: 0 },
      );
      this.optionsSeats = res.options;
      this.seatsAmount = res.amount;
    } else {
      this.optionsSeats = [];
      this.seatsAmount = 0;
    }
    this.form.get('SeatsAmount').setValue(this.seatsAmount);
    this.form.get('OptionsSeat').setValue(this.optionsSeats);
  }

  fillArray(arr, val, times) {
    if (times > 0) {
      arr.length = times;
      arr.fill(val);
    } else {
      arr.length = 0;
    }
    return arr;
  }

  optionChange(val, seat) {
    seat.option = val;
    this.changeWatcher();
  }

  addOptionsSeat() {
    this.seats.push({ ...this.defSeat });
  }

  removeOptionsSeat(index) {
    if (index >= 0 && index <= this.seats.length) {
      this.seats.splice(index, 1);
      this.changeWatcher();
    }
  }

  addFormControls(): void {
    this.form.addControl('OptionsSeat', new UntypedFormControl(''));
    this.form.addControl(
      'SeatsAmount',
      new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    );
  }

  ngOnDestroy() {
    this.form.removeControl('OptionsSeat');
    this.form.removeControl('SeatsAmount');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from '@autocomplete';
import { MaterialModule } from '@material';
import { NgxMaskModule } from 'ngx-mask';
import { CoreModule } from '../../../core/core.module';
import { PipeModule } from '../../../pipes/pipes.module';
import { CopyClipboardModule } from '../../modules/copy-clipboard/copy-clipboard.module';
import { NgStfAccordeonModule } from '../../modules/ng-stf-accordeon/ng-stf-accordeon.module';
import { PhoneInputModule } from '../../modules/phone-input/phone-input.module';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DoubleDateFilterComponent } from './components/double-date-filter/double-date-filter.component';
import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { RadioFilterComponent } from './components/radio-filter/radio-filter.component';
import { TableFilterMenuFooterComponent } from './components/table-filter-menu-footer/table-filter-menu-footer.component';
import {
  TableFilterMenuHeaderComponent
} from './components/table-filter-menu-header/table-filter-menu-header.component';
import {
  HotDoubleDateFilterComponent
} from './components/table-hot-filters/components/hot-double-date-filter/hot-double-date-filter.component';
import { HotInputFilterComponent } from './components/table-hot-filters/components/hot-input-filter/hot-input-filter.component';
import { HotRadioFilterComponent } from './components/table-hot-filters/components/hot-radio-filter/hot-radio-filter.component';
import { TableHotFiltersComponent } from './components/table-hot-filters/table-hot-filters.component';
import { TableFilterMenuComponent } from './table-filter-menu.component';

const COMPONENTS = [
  TableFilterMenuComponent,
  RadioFilterComponent,
  InputFilterComponent,
  DateFilterComponent,
  DoubleDateFilterComponent,
  TableFilterMenuHeaderComponent,
  TableFilterMenuFooterComponent,
  TableHotFiltersComponent,
  HotInputFilterComponent,
  HotRadioFilterComponent,
  HotDoubleDateFilterComponent,
  ChipListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputModule,
    PipeModule,
    CopyClipboardModule,
    NgStfAccordeonModule,
    CoreModule,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, NgxMaskModule, AutocompleteModule],
})
export class TableFilterMenuModule {}

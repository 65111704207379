<div class="confirm">
  <div class="confirm__content">
    <div id="123" class="confirm__text" *ngIf="!data.isConfirmHtml">{{ data.confirmMsg || '' }}</div>
    <div id="321" class="confirm__text" *ngIf="data.isConfirmHtml" [innerHTML]="data.confirmMsg || ''"></div>
  </div>
  <div class="confirm__buttons">
    <button type="button"
            mat-button color="primary"
            (click)="dialogRef.close(false)">
      {{ data.cancelBtnText || 'Cancel' }}
    </button>
    <button type="button"
            mat-button color="primary"
            (click)="dialogRef.close(true)">
      {{ data.confirmBtnText || 'Confirm' }}
    </button>
  </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {AppRoutes} from "../../../app.routes";

@Component({
  selector: 'app-debt-popup',
  templateUrl: 'debt-popup.component.html',
  styleUrls: ['./debt-popup.component.scss'],
})
export class DebtPopupComponent {

  @Output()
  close = new EventEmitter<void>();

  @Input()
  params;

  constructor(
    private router: Router,
    ) {
  }

  closePopup() {
    this.close.next();
  }

  goToMutual(): void {
    this.close.next();
    this.router.navigate([AppRoutes.dashboard, AppRoutes.mutual_settlements]);
  }

  getDebtsDescriptionNew() {
    return this.params ? this.params
      .filter(e => Number(e.PastDueDebts) > 0)
      .map(e => {
        return `За договором № ${e.AgreementId}  є заборгованість за транспортні послуги від ТОВ «Нова Пошта» на суму ${Number(e.PastDueDebts).toFixed(2)} грн.`;
      }).join('<br>') : '';
  }
}

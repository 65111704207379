import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IApiError } from '@models';
import { AlertToastService } from '@services';
import { GetLightReturnReason, GetLightReturnReasonsData, Unsubscriber } from '@shared';
import { BehaviorSubject, catchError, filter, finalize, merge, Subscription, take } from 'rxjs';
import { Invoice } from '../../../models/invoice/invoice.model';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { LightReturnModalComponent } from './light-return-modal.component';

@Injectable({
  providedIn: 'root',
})
export class LightReturnService extends Unsubscriber {

  constructor(private invoiceService: InvoiceService,
    private alertToastService: AlertToastService,
    private dialog: MatDialog,
  ) {
    super();
  }

  lightReturnInitialised: boolean = false;

  pending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pending$ = this.pending.asObservable();

  shouldReloadGrid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldReloadGrid$ = this.shouldReloadGrid.asObservable().pipe(filter(value => !!value));

  closeSubscription: Subscription;

  init(invoice: Invoice): void {
    this.shouldReloadGrid.next(false);
    this.setPending(true);

    this.subscriptions = this.invoiceService.checkLightReturn(invoice.docNumber)
      .pipe(
        catchError((errors) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.dialog.closeAll();
          this.shouldReloadGrid.next(true);
          return null;
        }),
        finalize(() => this.setPending(false)),
      )
      .subscribe((data: GetLightReturnReasonsData) => {
          if (!data) {
            this.alertToastService.pushError('Послуга недоступна');
            this.shouldReloadGrid.next(true);
            return;
          }
          this.initLightReturnDialog(data[0].subReasonsLightReturn, invoice);
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
            this.shouldReloadGrid.next(true);
          }
        });
  }

  private initLightReturnDialog(reasons: GetLightReturnReason[], invoice: Invoice): void {

    const dialogRef = this.dialog.open(LightReturnModalComponent, {
      panelClass: 'no-padding',
      disableClose: true,
    });

    this.lightReturnInitialised = true;

    dialogRef.componentInstance.type = 'in';
    dialogRef.componentInstance.invoice = invoice;
    dialogRef.componentInstance.reasons = reasons;

    const keys$ = dialogRef
      .keydownEvents()
      .pipe(filter((e) => e.key === 'Escape'));

    const backdrop$ = dialogRef.backdropClick();
    this.subscriptions = merge(keys$, backdrop$)
      .pipe(take(1))
      .subscribe(() => {
        this.shouldReloadGrid.next(false);
        dialogRef.close();
      });
  }

  private setPending(value: boolean): void {
    this.pending.next(value);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';

import { AutocompleteControls, AutocompleteControlsTriggeredEvent } from './autocomplete-controls/autocomplete-controls.component';
import { AutocompleteOptionDirective } from './autocomplete-option/autocomplete-option.directive';

export interface AutocompleteWithControlsConfig {
  arrow?: boolean;
  hint?: string;
  ariaLabel?: string;
  placeholder?: string;
  errorStateMatcher?: ErrorStateMatcher;
  focused?: boolean;
}

export type AutocompleteOptionsFilterFn<T> =
  (value: string | T, options: Array<AutoCompleteOptionItem<T>>) => Array<AutoCompleteOptionItem<T>>;

export type AutoCompleteTransformFn<T> = (value: T) => string;

export interface AutoCompleteOptionItem<T = string> {
  option: T;
  config?: {
    disabled?: boolean;
    data?: any;
    tooltip?: any;
  };
}

export const DEFAULT_AUTOCOMPLETE_SIZE = 8;

@Component({
  selector: 'app-autocomplete-with-controls',
  templateUrl: 'autocomplete-with-controls.component.html',
  styleUrls: ['./autocomplete-with-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteWithControlsComponent implements OnInit, OnDestroy, ControlValueAccessor {

  transformedValue = '';

  @Input()
  formControlParent;

  @Input()
  dropdownSearching;

  @Input()
  dropdownSearchEnabled = false;

  @Input()
  intertationalInv = null;

  @Input()
  createTemplate: boolean;

  formControl = new UntypedFormControl();

  changeFn: (_: any) => void;

  touchFn: () => void;

  @Output()
  onInput: EventEmitter<any> = new EventEmitter();

  @Output()
  onDropdownSearch: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled = false;

  @HostBinding('class.box-with-controls')
  hasControls = false;

  // Compatibility styles to make old component look like new
  @HostBinding('class.compat')
  @Input()
  stylesCompat = false;

  @Input()
  size = DEFAULT_AUTOCOMPLETE_SIZE;
  @Input()
  readonly = false;
  @Input()
  preventInput = false;
  @Input()
  optionsFilterFn: AutocompleteOptionsFilterFn<any> = null;
  @Input()
  transformFn: AutoCompleteTransformFn<any> = null;
  @Output()
  onControlTriggered: EventEmitter<AutocompleteControlsTriggeredEvent> = new EventEmitter<AutocompleteControlsTriggeredEvent>();
  @Output()
  focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @Output()
  onValueSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatAutocomplete)
  readonly matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  readonly matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild(MatInput, {read: MatInput, static: false})
  readonly matInput: MatInput;
  @ContentChild(AutocompleteOptionDirective, {read: TemplateRef})
  readonly autocompleteOptionTemplate: TemplateRef<AutocompleteOptionDirective>;
  private defaultConfig: AutocompleteWithControlsConfig = {
    arrow: false,
    hint: '',
    ariaLabel: '',
    placeholder: '',
    errorStateMatcher: new ShowOnDirtyErrorStateMatcher(),
  };
  @ViewChild(DefaultValueAccessor) private valueAccessor: DefaultValueAccessor;

  constructor(@Self() @Optional() private ngControl: NgControl,
              private cd: ChangeDetectorRef) {
    ngControl.valueAccessor = this;
  }

  private _controlsConfig: AutocompleteControls = null;

  get controlsConfig(): AutocompleteControls {
    return this._controlsConfig;
  }

  @Input()
  set controlsConfig(value: AutocompleteControls) {
    this._controlsConfig = value;
    this.hasControls = !!this._controlsConfig;
  }

  private _options: Array<AutoCompleteOptionItem<any>> = [];

  get options(): Array<AutoCompleteOptionItem<any>> {
    return this._options;
  }

  @Input()
  set options(value: Array<AutoCompleteOptionItem<any>>) {
    if (!value) {
      this._options = [];
      return;
    }
    if (!Array.isArray(value)) {
      throw new Error('Options should be an array!');
    }
    this._options = value;
  }

  private _config: AutocompleteWithControlsConfig;

  get config(): AutocompleteWithControlsConfig {
    return this._config;
  }

  @Input()
  set config(value: AutocompleteWithControlsConfig) {
    this._config = Object.assign({}, this.defaultConfig, value);
  }

  ngOnInit(): void {
    const control = this.ngControl.control;
    const validators = control && control.validator ? control.validator : null;
    setTimeout(() => {
      if (this.formControlParent) {
        this.formControlParent.valueChanges.subscribe(value => {
          if (this.formControlParent.touched || this.formControlParent.dirty) {
            this.formControl.markAsTouched();
            this.formControl.markAsDirty();
          } else {
            this.formControl.markAsUntouched();
            this.formControl.markAsPristine();
          }
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
        });
      }
    });
    if (!this.createTemplate) {
      this.formControl.setValidators(validators);
    }
    /** To show errors onInit uncomment next line:
     * this.formControl.updateValueAndValidity();
     */
  }

  sendDropdownSearchValue(value): void {
    this.onDropdownSearch.emit(value);
  }

  ngOnDestroy(): void {
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.transformedValue = event.option.value || '';
    this.onValueSelected.emit(this.transformedValue);
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.disabled ? this.formControl.disable() : this.formControl.enable();
  }

  writeValue(obj: any): void {
    this.transformedValue = this.transform(obj);
    this.writeValueToControl(obj);
  }

  transform(obj: any): any {
    if (typeof obj === 'string') {
      return obj;
    }
    return this.transformFn ? this.transformFn(obj) : obj;
  }

  triggerControl(event: AutocompleteControlsTriggeredEvent): void {
    this.onControlTriggered.emit(event);
  }

  onFocus(event) {
    this.focus.next(event);
  }

  private writeValueToControl(obj: any): void {
    this.formControl.setValue(obj, {emitEvent: false});
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs';
import {AppRoutes} from '../app.routes';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkIfSessionExists(state.url);
  }

  private checkIfSessionExists(url: string): Observable<boolean> {
    return of(this.authService.user)
      .pipe(
        take(1),
        switchMap(user => user
          ? of(user)
          : this.authService.setUser()),
        map(user => {
          return user
              ? this.navigateLoggedInUser(url)
              : this.navigateNonLoggedInUser(url);
          },
        ),
      );
  }

  private navigateNonLoggedInUser(url: string): boolean {
    if (url.includes(AppRoutes.login)
      || url === '/'
      || url.includes(AppRoutes.registration)
      || url.includes(AppRoutes.auth)) {

      return true;
    }

    if (url.includes(AppRoutes.dashboard)
      || url.includes(AppRoutes.profile)
      || url.includes(AppRoutes.invoice_create)) {
      this.router.navigate(['']);

      return false;
    }
    this.router.navigate(['']);
    return false;
  }

  private navigateLoggedInUser(url: string): boolean {
    if (url.includes(AppRoutes.home)
      || url === '/'
      || url.includes('/#/')
      || url.includes(AppRoutes.login)
      || url.includes(AppRoutes.registration)
      || url.includes(AppRoutes.auth)) {
      this.router.navigate([AppRoutes.dashboard]);

      return false;
    }

    return true;
  }
}

// tslint:disable:max-line-length
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@material';
import { SharedModule } from '@shared';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';
import { Ng2Webstorage } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PipeModule } from './pipes/pipes.module';
import { CustomPreloadingStrategy } from './preload.strategy';
import { ApiHelper } from './services/api-helper';
import { FirebaseModule } from './services/firebase';
import { FirebaseAuthService } from './services/firebase/firebase-auth.service';
import { JWTErrorInterceptor, JWTInterceptor, NotAuthInterceptor } from './services/interceptors';
import { WINDOW_PROVIDERS } from './services/window.service';
import { MobileModalDialogModule } from './shared/components/mobile-modal-dialog/mobile-modal-dialog.module';
import { CopyClipboardModule } from './shared/modules/copy-clipboard/copy-clipboard.module';
import { PhoneInputModule } from './shared/modules/phone-input/phone-input.module';

const allowedUrls = [
  'http://webclient.web-dev.sb.np.ua/data/get/container/json',
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FirebaseModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    PipeModule,
    PhoneInputModule,
    FirebaseModule.forRoot(),
    ToastrModule.forRoot({ newestOnTop: false }),
    Ng2Webstorage.forRoot(),
    OAuthModule.forRoot({
      resourceServer: { allowedUrls, sendAccessToken: true },
    }),
    CoreModule,
    MobileModalDialogModule,
    SharedModule,
    CopyClipboardModule,
  ],
  providers: [
    FirebaseAuthService,
    WINDOW_PROVIDERS,
    ApiHelper,
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotAuthInterceptor, multi: true },
    CustomPreloadingStrategy,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

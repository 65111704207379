import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { clearField, DateTableFilter, getError, SmartFormContainer } from '@shared';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent extends SmartFormContainer {
  readonly getError = getError;
  readonly clearField = clearField;
  @Input() config: DateTableFilter;

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}

import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { clearField, getError, warehouseResultMaskValidator } from '@shared';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
} from 'rxjs';
import { WarehouseErrors } from '../../../shared/errors/error-messages';
import {
  RequestLimit,
  WarehouseClasses,
  WarehouseControlName,
  WarehouseDebounceTime,
  WarehouseLabel,
  WarehouseMaxLength,
  WarehouseMinLength,
  WarehousePlaceholder,
} from './warehouse-select.constants';
import { getWarehouseDescription } from './warehouse-select.utils';
import { CategoryOfWarehouseEnum, WarehouseResult } from '@models';
import { filterInputValue } from '../../../shared/helpers/invoices-my.helpers';
import { AddressService } from '@services';

@Component({
  selector: 'app-warehouse-select',
  templateUrl: './warehouse-select.component.html',
  styleUrls: ['./warehouse-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WarehouseSelectComponent),
      multi: true,
    },
  ],
})
export class WarehouseSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  getError = getError;

  clearField = clearField;

  WarehouseErrors = WarehouseErrors;

  @Input() formControl: UntypedFormControl = new UntypedFormControl('', [
    warehouseResultMaskValidator(),
    Validators.required,
  ]);

  @Input() placeholder: string = WarehousePlaceholder;

  @Input() label: string = WarehouseLabel;

  @Input() testId: string = WarehouseControlName;

  @Input() minLength: number = WarehouseMinLength;

  @Input() maxLength: number = WarehouseMaxLength;

  @Input() requestLimit: number = RequestLimit;

  @Input() debounceTime: number = WarehouseDebounceTime;

  @Input() getWarehouseDescription = getWarehouseDescription;

  @Input() clearEnabled: boolean = false;

  @Input()
  @HostBinding('class')
  classList = WarehouseClasses;

  @Input() itemsFilterConfig = null;

  @ViewChild('input', { read: ElementRef, static: false }) input: ElementRef;

  warehouses: WarehouseResult[] = [];
  loading: boolean = false;
  warehousesSubscription: Subscription = null;
  warehouseFilterQuery$: Observable<string>;

  constructor(
    protected addressService: AddressService,
    protected element: ElementRef,
  ) {}

  private _cityRef: Subject<string> = new Subject<string>();

  cityRef$ = this._cityRef.asObservable();

  @Input() set cityRef(value: string) {
    this._cityRef.next(value);
  }

  get warehouse(): AbstractControl {
    return this.formControl;
  }

  initWarehouseFilterQueryStream(): void {
    this.warehouseFilterQuery$ = this.warehouse?.valueChanges.pipe(
      filter((v) => filterInputValue(v, 1)),
      debounceTime(this.debounceTime),
    );
  }

  initWarehouseSubscription(): void {
    if (this.warehousesSubscription) {
      return;
    }
    this.warehousesSubscription = this.cityRef$
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((value) => this.getWarehouseList(value));
  }

  getWarehouses(cityRef: string): Observable<WarehouseResult[]> {
    return this.addressService.getWarehouses(
      cityRef,
      null,
      undefined,
      false,
      CategoryOfWarehouseEnum.Warehouse,
    );
  }

  blur(): void {
    this.input.nativeElement.blur();
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }

  ngOnDestroy(): void {
    this.warehousesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.initWarehouseSubscription();
    this.initWarehouseFilterQueryStream();
  }

  protected getWarehouseList(value: string): void {
    this.warehouses = [];
    this.warehouse.reset('');
    this.loading = true;
    this.getWarehouses(value)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((warehouses) => {
        this.warehouses = warehouses.map((warehouse) => this.itemsFilterConfig ? this.itemsFilterConfig(warehouse) : warehouse);
        this.element.nativeElement.querySelector('input').focus();
      });
  }
}

import { Address, Contragent, IListData, Locality } from '@models';
import * as moment from 'moment';
import { InvoiceContact } from '../../models/invoice/invoice-contact.model';
import { InvoicePaymentMethod } from '../../models/invoice/invoice-payment-method.model';
import { getOutgoingDocumentsByPhoneResponse, Invoice } from '../../models/invoice/invoice.model';
import { states } from './invoice-states.config';
import { getCounterPartyType } from './invoice.api';

export function splitName(name: string) {
  if (!name) {
    return ['', '', ''];
  }
  return [name, '', ''];
}

export function coerceToNumber(el: any): number {
  return !isNaN(parseFloat(el)) && !!parseFloat(el) ? parseFloat(el) : 0;
}

export function setAddressType(addressDescription: string) {
  return addressDescription.toLowerCase().indexOf('відділення') > -1
    ? 'Warehouse'
    : addressDescription.toLowerCase().indexOf('поштомат') > -1
      ? 'Postbox'
      : 'Address';
}

export function getState(statusCode) {
  return states[statusCode];
}

export function isInternational(rawInvoice: any) {
  return (
    rawInvoice.InternationalDeliveryType === 'Import' ||
    rawInvoice.InternationalDeliveryType === 'Export'
  );
}

export function checkAllowManageButton(rawInvoice: any): boolean {
  return rawInvoice.possibilityCreateRedirecting ||
    rawInvoice.possibilityCreateReturn ||
    rawInvoice.possibilityChangeEW ||
    rawInvoice.possibilityTermExtension;
}

export function createShortDescription(description: string): string {
  if (!description) {
    return '';
  }

  let shortDescription = description.replace(/\(.*\)/gi, '');
  if (/.*(Відділення №\d+)/.test(shortDescription)) {
    shortDescription = /.*(Відділення №\d+)/.exec(shortDescription)[0];
  }
  return shortDescription;
}

export function getRedirectingReturnIndication(invoice: Invoice): void {
  if (!invoice.OrderingAdditionalServices?.length) {
    return;
  }

  const latestService = invoice.OrderingAdditionalServices
    .filter(
      (a) =>
        a.OrderType === 'Redirecting'
        || a.OrderType === 'CargoReturn',
    )
    .filter((a) =>
      a.Status === 'CreateEWCargoReturn'
      || a.Status === 'StoreInProcessing'
      || a.Status === 'Received',
    )
    .sort(
      (a, b) =>
        moment(b.DateTime).valueOf() - moment(a.DateTime).valueOf(),
    )[0];

  if (!latestService) {
    return;
  }

  invoice.RedirectingReturnIndication = { icon: '', tooltipText: '' };

  if (latestService.OrderType === 'Redirecting') {
    invoice.RedirectingReturnIndication.icon = 'call_split';
    if (latestService.Status === 'Received' || latestService.Status === 'StoreInProcessing') {
      invoice.RedirectingReturnIndication.tooltipText = `Оформлено заявку на переадресацію ${latestService.Number ?? ''}`;
    }

    if (latestService.Status === 'CreateEWCargoReturn') {
      invoice.RedirectingReturnIndication.tooltipText = `Відправлення переадресовано по ЕН ${latestService.EWRedirectingNumber ?? ''}`;
    }
  }

  if (latestService.OrderType === 'CargoReturn') {
    invoice.RedirectingReturnIndication.icon = 'import_export';
    if (latestService.Status === 'Received' || latestService.Status === 'StoreInProcessing') {
      invoice.RedirectingReturnIndication.tooltipText = `Оформлено заявку на повернення ${latestService.Number ?? ''}`;
    }

    if (latestService.Status === 'CreateEWCargoReturn') {
      invoice.RedirectingReturnIndication.tooltipText = `Відправлення повернено по ЕН ${latestService.EWCargoReturnNumber ?? ''}`;
    }
  }
}

export function _bindListToModelType2(
  data: getOutgoingDocumentsByPhoneResponse,
): IListData<Invoice> {
  return {
    total: data.info.totalCount,
    list:
      data.data[0]?.result?.map((el: any) => {
        if (el.OrderingAdditionalServices) {
          getRedirectingReturnIndication(el);
        }
        el.docNumber = el.Number;
        el.createTime = moment(el.CreateTime).toDate();
        el.dateTime = moment(el.DateTime).toDate();
        el.dateFirstDayStorage =
          el?.FirstDayStorage !== '0001-01-01 00:00:00'
            ? el.FirstDayStorage
            : null;
        let userNameParts: string[] = splitName(el.SenderName);
        el.sender = new InvoiceContact(
          userNameParts[1],
          userNameParts[0],
          null,
        );
        el.sender.phone = el.PhoneSender;
        el.note = el.Note;
        el.weight =
          Number(el.CalculatedWeight) > 0
            ? el.CalculatedWeight
            : el.DocumentWeight;
        if (el.CounterpartySender) {
          el.sender.contragent = new Contragent(el.CounterpartySender);
        }
        el.counterpartySenderDescription = el.CounterpartySenderDescription;
        el.counterpartySender = el.CounterpartySenderDescription;
        el.SenderAddressShortDescription = createShortDescription(
          el.SenderAddressDescription,
        );
        el.cost = el.Cost;

        el.moneyTransferAmount =
          el.MoneyTransferCash2Card === '0'
            ? coerceToNumber(el.MoneyTransferAmount)
            : null;
        el.moneyTransferCash2Card =
          el.MoneyTransferCash2Card === '1'
            ? coerceToNumber(el.MoneyTransferAmount)
            : null;

        userNameParts = splitName(el.RecipientName);
        el.recipient = new InvoiceContact(
          userNameParts[1],
          userNameParts[0],
          null,
        );
        el.recipientContactPhone = el.RecipientContactPhone || '';
        el.recipient.phone = el.PhoneRecipient;
        el.recipient.city = new Locality(el.CityRecipient);
        el.recipient.city.name = el.CityRecipientDescription;

        if (el.MoneyTransferAmount) {
          el.Cash2CardPAN = el.CardMaskedNumber;
          el.backDeliveryPayer = el.MoneyTransferPayerType;
          el.backMoney = coerceToNumber(el.MoneyTransferAmount);
        }

        el.recipientCounterpartyType =
          el.RecipientCounterpartyType ||
          getCounterPartyType(el.CounterpartyRecipientDescription) ||
          '';

        if (el.CounterpartyRecipient) {
          el.recipient.contragent = new Contragent(el.CounterpartyRecipient);
        }

        el.recipientAddress = new Address();
        if (el.RecipientAddressDescription) {
          el.recipientAddress.description = el.RecipientAddressDescription;
          el.recipientAddress.shortDescription = createShortDescription(
            el.RecipientAddressDescription,
          );
          el.recipientAddress.type = setAddressType(
            el.RecipientAddressDescription,
          );
        }

        el.senderAddress = new Address();
        el.senderAddress.city = new Locality(el.CitySender);

        if (el.SenderAddressDescription) {
          el.senderAddress.shortDescription = createShortDescription(
            el.SenderAddressDescription,
          );
          el.senderAddress.description = el.SenderAddressDescription;
          el.senderAddress.type = setAddressType(el.SenderAddressDescription);
        }

        if (el.ScheduledDeliveryDate) {
          el.scheduleDeliveryDate = moment(el.ScheduledDeliveryDate).toDate();
        }

        if (el.ArrivalDateTime) {
          el.actualDeliveryDate = moment(
            el.ArrivalDateTime,
            'YYYY-MM-DD HH:mm:ss',
          ).toDate();
        }

        el.deliveryPrice = el.DocumentCost;
        el.StoragePrice = el.StoragePrice ? el.StoragePrice : 0;
        el.SumBeforeCheckWeight =
          parseFloat(el.SumBeforeCheckWeight) > 0
            ? parseFloat(el.SumBeforeCheckWeight)
            : el.deliveryPrice;
        el.announcedPrice = el.Cost;
        el.paymentControl =
          (!!el.AfterpaymentOnGoodsCost &&
            parseFloat(el.AfterpaymentOnGoodsCost)) ||
          0;
        el.forwardingCount =
          !!el.ForwardingCount && parseFloat(el.ForwardingCount) > 0
            ? parseFloat(el.ForwardingCount)
            : 0;
        el.ForwardingCount =
          !!el.ForwardingCount && parseFloat(el.ForwardingCount) > 0
            ? parseFloat(el.ForwardingCount)
            : 0;

        el.estimatedDeliveryDate = moment(el.EstimatedDeliveryDate).toDate();

        const date = el.RecipientDateTime || el.ReceivingDateTime;
        el.receivingTime =
          date !== '0001-01-01 00:00:00'
            ? moment(el.RecipientDateTime || el.ReceivingDateTime).toDate()
            : null;

        if (el.PaymentMethod) {
          el.paymentMethod = new InvoicePaymentMethod(el.PaymentMethod);
        }

        el.marketPlaceToken = el.MarketplacePartnerToken;

        el.RedeliveryPaymentCard = el.RedeliveryPaymentCard
          ? el.RedeliveryPaymentCard
          : {
            CardDescription: '',
            CardMaskedNumber: '',
          };

        el.PackingNumber = el.PackingNumber ?? 0;

        el.isInternational = isInternational(el);

        el.state = getState(el.TrackingStatusCode);
        el.statusCode = el.TrackingStatusCode;
        el.allowManageButton = checkAllowManageButton(el);
        el.clientBarcode = el.ClientBarcodes?.[0] || '';
        el.seatsAmount = el.SeatsAmount;
        el.counterpartySenderDescription = el.CounterpartySenderDescription;
        el.CheckWeight = Number(el.CheckWeight) > 0 ? el.CheckWeight : null;
        el.cardMaskedNumber = el.CardMaskedNumber;
        el.isPossibilityDuplicate = el.IsPossibilityDuplicate;
        el.LightReturn = el.LightReturn || null;
        el.possibilityCreateLightReturn = el.possibilityCreateLightReturn || null;
        el.LightReturnNumber = el.LightReturnNumber || null;
        return el;
      }) ?? [],
  };
}

export const documentsList = [
  {
    name: 'Довіреність',
    services: {
      stampSign: {
        name: 'Attorney',
        checked: true,
      },
    },
  },
  {
    name: 'ТТН державного зразка (№1)',
    services: {
      stamp: {
        name: 'WaybillStateNumber1WithStamp',
        checked: true,
      },
      sign: {
        name: 'WaybillStateNumber1WithoutStamp',
        checked: true,
      },
    },
  },
  {
    name: 'Накладна ТОВ «Нова Пошта»',
    services: {
      stamp: {
        name: 'WaybillNewPostWithStamp',
        checked: true,
      },
      sign: {
        name: 'WaybillNewPostWithoutStamp',
        checked: true,
      },
    },
  },
  {
    name: 'Видаткова накладна',
    services: {
      stamp: {
        name: 'CostWaybillWithStamp',
        checked: true,
      },
      sign: {
        name: 'CostWaybillWithoutStamp',
        checked: true,
      },
    },
  },
  {
    name: 'Акт прийому-передачі',
    services: {
      stamp: {
        name: 'AktPPVWithStamp',
        checked: true,
      },
      sign: {
        name: 'AktPPVWithoutStamp',
        checked: true,
      },
    },
  },
  {
    name: 'Міжнародна накладна',
    services: {
      sign: {
        name: 'InternationalWaybill',
        checked: true,
      },
    },
  },
  {
    name: 'Бланк замовлення',
    services: {
      sign: {
        name: 'OrderForm',
        checked: true,
      },
    },
  },
];

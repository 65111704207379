<div class="side-nav__content">
    <div class="side-nav__top">
        <button
                (click)="$event.stopPropagation(); dialogRef.close('backBtnClick')"
                class="mat-button_with-icon"
                mat-button
                type="button"
        >
            <mat-icon> arrow_back</mat-icon>
            Закрити
        </button>
    </div>
    <div class="wallet-form">
        <div class="wallet-form__invoice-wrapper">
            <div class="invoice">
                <div class="invoice-header">
                    <div class="invoice-header__left">
                        <i class="material-icons invoice-header__icon-type">call_made</i>
                        <div class="invoice-header__number">{{ formattedInvoiceNumber }}</div>
                    </div>
                    <div class="invoice-header__right">
                        <div class="invoice-header__info">
                            <div class="info-cell invoice-header__cell">
                                <div class="info-cell__label">Опис відправлення</div>
                                <div class="info-cell__content">
                                    {{
                                    inputInvoice?.Description || inputInvoice?.CargoDescription || inputInvoice.description || '&mdash;'
                                    }}
                                </div>
                            </div>
                            <div class="info-cell invoice-header__cell">
                                <div class="info-cell__label">Оголошена вартість</div>
                                <div class="info-cell__content">
                                    {{ (inputInvoice?.Cost ? inputInvoice?.Cost : (inputInvoice?.announcedPrice | fineNumber)) || 0 }}
                                    ₴
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-progress-bar [style.opacity]="isLoading ? 1 : 0" mode="indeterminate"></mat-progress-bar>
            <mat-accordion
                    *ngIf="paymentCostsInfo?.Services > 0 || (paymentCostsInfo?.ServicesList?.length && !paymentSuccess)"
            >
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="wallet-form-list__item-expand">
                            <mat-checkbox
                                    (change)="setCostSum()"
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="selectedServices.Services"
                                    [disabled]="paymentCostsInfo?.Services <= 0"
                            >Послуги з доставки
                            </mat-checkbox>
                            <div *ngIf="paymentCostsInfo.ServicesPaid > 0" class="wallet-form-list__total">
                                <span class="line-through-text">{{ paymentCostsInfo.TotalServices | fineNumber }}</span>
                                {{ paymentCostsInfo.Services | fineNumber }}
                                грн
                            </div>
                            <div *ngIf="paymentCostsInfo.ServicesPaid === 0" class="wallet-form-list__total">
                <span [class.line-through-text]="paymentCostsInfo?.Services <= 0">
                  {{ paymentCostsInfo.TotalServices | fineNumber }} грн
                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngFor="let service of paymentCostsInfo.ServicesList" class="wallet-form-list__item-grey">
                        <div>
                            {{ service.ServiceName }}
                        </div>
                        <div class="wallet-form-list__total">{{ service.Cost | fineNumber }} грн</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion
                    *ngIf="paymentCostsInfo?.Redirecting > 0 || (paymentCostsInfo?.ServicesListRedirect?.length && !paymentSuccess)"
            >
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="wallet-form-list__item-expand">
                            <mat-checkbox
                                    (change)="setCostSum()"
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="selectedServices.Redirecting"
                                    [disabled]="paymentCostsInfo?.Redirecting <= 0"
                            >Переадресування посилки
                            </mat-checkbox>
                            <div *ngIf="paymentCostsInfo.RedirectingPaid > 0" class="wallet-form-list__total">
                                <span class="line-through-text">{{ paymentCostsInfo.TotalRedirecting | fineNumber }}</span>
                                {{ paymentCostsInfo.Redirecting | fineNumber }}
                                грн
                            </div>
                            <div *ngIf="paymentCostsInfo.RedirectingPaid === 0" class="wallet-form-list__total">
                <span [class.line-through-text]="paymentCostsInfo?.Redirecting <= 0">
                  {{ paymentCostsInfo.TotalRedirecting | fineNumber }} грн
                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngFor="let service of paymentCostsInfo.ServicesListRedirect"
                         class="wallet-form-list__item-grey">
                        <div>
                            {{ service.ServiceName }}
                        </div>
                        <div class="wallet-form-list__total">{{ service.Cost | fineNumber }} грн</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion
                    *ngIf="paymentCostsInfo?.Return > 0 || (paymentCostsInfo?.ServicesListReturn?.length && !paymentSuccess)"
            >
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="wallet-form-list__item-expand">
                            <mat-checkbox
                                    (change)="setCostSum()"
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="selectedServices.Return"
                                    [disabled]="paymentCostsInfo?.Return <= 0"
                            >Повернення посилки
                            </mat-checkbox>
                            <div *ngIf="paymentCostsInfo.ReturnPaid > 0" class="wallet-form-list__total">
                                <span class="line-through-text">{{ paymentCostsInfo.TotalReturn | fineNumber }}</span>
                                {{ paymentCostsInfo.Return | fineNumber }}
                                грн
                            </div>
                            <div *ngIf="paymentCostsInfo.ReturnPaid === 0" class="wallet-form-list__total">
                <span [class.line-through-text]="paymentCostsInfo?.Return <= 0">
                  {{ paymentCostsInfo.TotalReturn | fineNumber }} грн
                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngFor="let service of paymentCostsInfo.ServicesListReturn"
                         class="wallet-form-list__item-grey">
                        <div>
                            {{ service.ServiceName }}
                        </div>
                        <div class="wallet-form-list__total">{{ service.Cost | fineNumber }} грн</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div *ngIf="paymentCostsInfo?.InternationalDelivery > 0" class="wallet-form-list">
                <div *ngIf="paymentCostsInfo.InternationalDelivery > 0" class="wallet-form-list__item">
                    <mat-checkbox
                            (change)="setCostSum()"
                            [(ngModel)]="selectedServices.InternationalDelivery"
                            [disabled]="paymentCostsInfo?.InternationalDelivery <= 0"
                    >Послуги з доставки
                    </mat-checkbox>
                    <div *ngIf="paymentCostsInfo.InternationalDeliveryPaid > 0" class="wallet-form-list__total">
                        <span class="line-through-text">{{ paymentCostsInfo.TotalInternationalDelivery | fineNumber }}</span>
                        {{ paymentCostsInfo.InternationalDelivery | fineNumber }} грн
                    </div>
                    <div *ngIf="paymentCostsInfo.InternationalDeliveryPaid === 0" class="wallet-form-list__total">
            <span [class.line-through-text]="paymentCostsInfo?.InternationalDelivery <= 0">
              {{ paymentCostsInfo.TotalInternationalDelivery | fineNumber }} грн
            </span>
                    </div>
                </div>
            </div>
            <mat-accordion *ngIf="paymentCostsInfo.ServicesListMoneyTransfer">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="wallet-form-list__item-expand">
                            <mat-checkbox
                                    (change)="setCostSum()"
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="selectedServices.MoneyTransfer"
                                    [disabled]="paymentCostsInfo?.MoneyTransfer <= 0 && paymentCostsInfo?.Commission <= 0"
                            >Грошовий переказ
                            </mat-checkbox>
                            <div
                                    *ngIf="
                  paymentCostsInfo.MoneyTransferPaid > 0 ||
                  paymentCostsInfo.CommissionPaid > 0 ||
                  paymentCostsInfo.CommissionPaid > 0
                "
                                    class="wallet-form-list__total"
                            >
                                <span class="line-through-text">{{ paymentCostsInfo.TotalMoneyTransfer }}</span>
                                {{ paymentCostsInfo.MoneyTransfer + paymentCostsInfo.Commission | fineNumber }}
                                грн
                            </div>
                            <div *ngIf="paymentCostsInfo.MoneyTransferPaid === 0" class="wallet-form-list__total">
                <span
                        [class.line-through-text]="paymentCostsInfo?.MoneyTransfer <= 0 && paymentCostsInfo?.Commission <= 0"
                >
                  {{ paymentCostsInfo.TotalMoneyTransfer | fineNumber }} грн
                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <div *ngFor="let service of paymentCostsInfo.ServicesListMoneyTransfer"
                         class="wallet-form-list__item-grey">
                        <div>
                            {{ service.ServiceName }}
                        </div>
                        <div class="wallet-form-list__total">{{ service.Cost | fineNumber }} грн</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion *ngIf="paymentCostsInfo?.ServicesListAfterPayment">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="wallet-form-list__item-expand">
                            <mat-checkbox
                                    (change)="setCostSum()"
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="selectedServices.AfterPayment"
                                    [disabled]="paymentCostsInfo?.AfterPayment <= 0"
                            >Грошовий переказ
                            </mat-checkbox>
                            <div *ngIf="paymentCostsInfo.AfterPaymentPaid > 0" class="wallet-form-list__total">
                                <span class="line-through-text">{{ paymentCostsInfo.TotalAfterPayment }}</span>
                                {{ paymentCostsInfo.AfterPayment | fineNumber }}
                                грн
                            </div>
                            <div *ngIf="paymentCostsInfo.AfterPaymentPaid === 0" class="wallet-form-list__total">
                <span [class.line-through-text]="paymentCostsInfo?.AfterPayment <= 0">
                  {{ paymentCostsInfo.TotalAfterPayment | fineNumber }} грн
                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let service of paymentCostsInfo.ServicesListAfterPayment">
                        <div *ngIf="+service.Cost > 0" class="wallet-form-list__item-grey">
                            <div>
                                {{ service.ServiceName }}
                            </div>
                            <div class="wallet-form-list__total">{{ service.Cost | fineNumber }} грн</div>
                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div
                *ngIf="!!user.discount && !bonusPaymentAmount && !bonusPaymentHidden && paymentCostsInfo?.Services > 0"
                class="wallet-form-box margin-top-24"
        >
            <div class="wallet-form-box__title">Використати бонус-знижку</div>
            <div class="wallet-form-box__text">
                <span class="text-red">{{ user?.discount }} ₴</span> на рахунку
            </div>
            <div class="form-row">
                <div class="form-col form-col-4 wallet-form-box__col">
                    <mat-form-field class="mat-block mat-block_with_symbol" data-symbol="₴">
                        <input [formControl]="bonusPayment"
                               npNumber
                               [numeric]="true"
                               [digitsAfterDot]="2"
                               matInput
                               placeholder="Бонуси"
                               type="text"
                               autocomplete="off"
                               (paste)="onPaste($event)"
                               maxlength="15"
                        />
                    </mat-form-field>
                </div>
                <div class="form-col form-col-6">
                    <div class="info-cell">
                        <div class="info-cell__label">Інформація</div>
                        <div class="info-cell__content">
                            Бонус-знижою можна платити тільки за доставку! На інші послуги дія бонус-знижки не
                            розповсюджується.
                        </div>
                    </div>
                </div>
            </div>
            <div class="wallet-form-box__button">
                <button
                        (click)="payWithBonuses(bonusPayment.value)"
                        [disabled]="bonusPayment?.invalid || isLoading"
                        color="primary"
                        mat-button
                        type="button"
                >
                    Сплатити
                </button>
            </div>
        </div>
        <br/>
        <div *ngIf="!!bonusPaymentAmount" class="wallet-form-box">
            <div class="wallet-form-box__title">Використати бонус-знижку</div>
            <div class="wallet-form-box__row">
                <div class="wallet-form-box__text">
                    Оплата пройшла успішно. Ви сплатили {{ bonusPaymentAmount }} ₴ за рахунок бонус-знижки.
                </div>
                <div class="wallet-form-box__tip"><i class="material-icons material-icons_grey">check</i> Сплачено</div>
            </div>
            <div class="wallet-form-box__hint">На рахунку залишилось {{ userBonusesAmount }}₴</div>
        </div>
        <div
                *ngIf="type !== 'my' && paymentCostsInfo?.Services > 0 && !promocodeSuccessUse && !promocodePaymentHidden"
                [formGroup]="form"
                class="wallet-form-box margin-top-24"
        >
            <div class="wallet-form-box__title">Використати промокод</div>
            <div class="form-row">
                <div class="form-col form-col-4 wallet-form-box__col">
                    <app-delivery-promocode [form]="form" [label]="promocodeLabel"></app-delivery-promocode>
                </div>
                <div class="form-col form-col-6">
                    <div class="info-cell">
                        <div class="info-cell__label">Інформація</div>
                        <div class="info-cell__content">
                            Введіть промокод або оберіть зі списку та використайте для оплати послуг доставки Нової
                            пошти
                        </div>
                    </div>
                </div>
            </div>
            <div class="wallet-form-box__button">
                <button
                        (click)="payWithPromocode()"
                        [disabled]="promocode?.invalid || isLoading"
                        color="primary"
                        mat-button
                        type="button"
                >
                    Використати
                </button>
            </div>
        </div>
        <br/>
        <div *ngIf="promocodeSuccessUse" class="wallet-form-box">
            <div class="wallet-form-box__title">Використати промокод</div>
            <div class="wallet-form-box__row">
                <div class="wallet-form-box__text">Промокод успішно використано.</div>
                <div class="wallet-form-box__tip"><i class="material-icons material-icons_grey">check</i> Сплачено</div>
            </div>
        </div>
    </div>
</div>

<div class="side-nav-bottom">
    <div class="side-nav-bottom__item"></div>
    <div class="side-nav-bottom__item side-nav-bottom__item_align-right">
        <button
                (click)="initPayment()"
                *ngIf="!paymentSuccess && !paymentError && isNewPaymentV3"
                [disabled]="isLoading || isPending || costSum === 0 || paymentSuccess || paymentError || paymentPopupOpened"
                color="primary"
                mat-raised-button
                type="button"
        >
            Сплатити
        </button>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CharityLinkResponse } from '@models';
import { map, Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { NoRestApiHelper } from '../no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class CharityService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {
  }

  getCharityUrl(): Observable<CharityLinkResponse> {
    return this.http.post(`${this.configService.get('apiUrl')}json/`,
      {
        calledMethod: 'initCharity',
        modelName: 'Payment',
        system: 'PA 3.0',
        language: 'uk',
      })
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0] as CharityLinkResponse),
      );
  }
}

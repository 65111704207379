import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isBoolean } from 'lodash';
import { ConfirmDialogModernComponent, ConfirmDialogModernConfig } from './confirm-dialog-modern.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogModernService {

  constructor(private dialog: MatDialog) {
  }

  private dialogRef: MatDialogRef<ConfirmDialogModernComponent> | null = null;

  public get confirmRef(): ConfirmDialogModernComponent {
    return this.dialogRef.componentInstance;
  }

  confirm(config: ConfirmDialogModernConfig): void {
    this.dialogRef = this.dialog.open(ConfirmDialogModernComponent, {
      data: config,
      panelClass: 'no-padding',
      autoFocus: false,
      maxWidth: '640px',
      restoreFocus: isBoolean(config.restoreFocus) ? config.restoreFocus : true,
    });
  }

  close(): void {
    this.dialogRef?.close();
  }
}

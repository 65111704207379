<svg version="1.1" class="logo__img d-block" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 283.46 48.25" style="enable-background:new 0 0 283.46 48.25;" xml:space="preserve">
  <g>
    <path style="fill:#DA292B;" d="M27.31,34.18v-6.74h-5.73v6.74H17.2l5.32,5.32c1.07,1.07,2.8,1.07,3.87,0l5.32-5.32H27.31z
                 M14.33,31.31v-14.5l-5.32,5.32c-1.07,1.07-1.07,2.8,0,3.87L14.33,31.31z M21.58,13.95v6.74h5.73v-6.74h4.38l-5.32-5.32
                c-1.07-1.07-2.8-1.07-3.87,0l-5.32,5.32H21.58z M39.87,22.13l-5.32-5.32v14.5L39.87,26C40.94,24.93,40.94,23.2,39.87,22.13z"/>
    <g>
      <path style="fill:#DA292B;" d="M65.49,21.33h-7.22v-5.05c0-1.33-0.98-2.31-2.31-2.31H53.8h-1.3h-2.89v5.34h2.89v14.87h5.78v-7.36
                  h7.22v7.36h5.78V13.96h-5.78V21.33z"/>
      <path style="fill:#DA292B;" d="M84.55,13.67c-6.09,0-10.54,4.39-10.54,10.4s4.45,10.4,10.54,10.4c6.09,0,10.54-4.39,10.54-10.4
                  S90.64,13.67,84.55,13.67z M84.55,28.83c-2.74,0-4.76-2.02-4.76-4.76s2.02-4.76,4.76-4.76c2.74,0,4.76,2.02,4.76,4.76
                  S87.29,28.83,84.55,28.83z"/>
      <path style="fill:#DA292B;" d="M112.96,23.48c1.43-0.87,2.32-2.36,2.32-4.15c0-3.12-2.02-5.37-6.04-5.37H97.83v20.21h11.41
                  c4.22,0,6.67-2.45,6.67-5.83C115.91,26.18,114.76,24.42,112.96,23.48z M103.2,18.47h4.3c1.36,0,2.14,0.66,2.14,1.79
                  s-0.78,1.79-2.14,1.79h-4.3V18.47z M107.91,29.73h-4.71v-3.75h4.71c1.44,0,2.25,0.69,2.25,1.88
                  C110.16,29.04,109.35,29.73,107.91,29.73z"/>
      <path style="fill:#DA292B;" d="M124.74,13.96l-7.97,20.21h6.15l1.28-3.72h8.12l1.28,3.72h6.27l-7.97-20.21H124.74z M125.77,25.92
                  l2.28-6.61h0.44l2.28,6.61H125.77z"/>
      <path style="fill:#DA292B;" d="M167.48,13.96h-1.53h-1.93h-7.22h-3.23h-1.24h-1.3h-2.89v5.34h2.89v14.87h5.78V19.3h7.22v14.87
                  h5.78v-17.9C169.79,14.8,168.95,13.96,167.48,13.96z"/>
      <path style="fill:#DA292B;" d="M183.07,13.67c-6.09,0-10.54,4.39-10.54,10.4s4.45,10.4,10.54,10.4c6.09,0,10.54-4.39,10.54-10.4
                  S189.17,13.67,183.07,13.67z M183.07,28.83c-2.74,0-4.76-2.02-4.76-4.76s2.02-4.76,4.76-4.76c2.74,0,4.76,2.02,4.76,4.76
                  S185.82,28.83,183.07,28.83z"/>
      <polygon style="fill:#DA292B;" points="218.88,28.83 213.39,28.83 213.39,13.96 207.62,13.96 207.62,28.83 202.13,28.83
                  202.13,13.96 196.36,13.96 196.36,34.18 199.07,34.18 202.13,34.18 218.88,34.18 222.46,34.18 224.66,34.18 224.66,13.96
                  218.88,13.96 		"/>
      <polygon style="fill:#DA292B;" points="247.03,19.3 247.03,13.96 227.4,13.96 227.4,19.3 234.33,19.3 234.33,34.18 240.1,34.18
                  240.1,19.3 		"/>
      <path style="fill:#DA292B;" d="M262.14,34.18h6.27l-7.97-20.21h-7.16l-7.97,20.21h6.15l1.28-3.72h8.12L262.14,34.18z M254.3,25.92
                  l2.28-6.61h0.44l2.28,6.61H254.3z"/>
    </g>
    <rect y="0" style="fill:none;" width="215" height="31"/>
  </g>
</svg>


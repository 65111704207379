import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  cityResultMaskValidator,
  FormContainer,
  TypedFormControl,
  WarehouseMaskValidator,
} from '@shared';
import { WarehouseControls } from './warehouse.enums';
import { WAREHOUSE_TEST_ID } from './warehouse.constants';
import { AddressTypes, CitySearchResult } from '@models';
import { WarehouseClasses } from '../../../../core/components/warehouse-select/warehouse-select.constants';
import { CityClasses } from '../../../../core/components/city-select/city-select.constants';

@Component({
  selector: 'app-warehouse',
  templateUrl: 'warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
})
export class WarehouseComponent
  extends FormContainer
  implements TypedFormControl, OnInit, OnDestroy
{
  type = AddressTypes.WAREHOUSE;

  formGroup: UntypedFormGroup;

  @HostBinding('attr.data-test-id') a = WAREHOUSE_TEST_ID;

  @Input() controlName = 'WarehouseContainer';

  @Input() controlTypeName = 'type';

  @Input() isTemplate = false;

  @Input() cityClasses = CityClasses;

  @Input() warehouseClasses = WarehouseClasses;

  @Input() itemsFilterConfig = null;

  cityRef: string = '';

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  get cityValue(): CitySearchResult | string {
    return this.formGroup.get(WarehouseControls.city).value;
  }

  set cityValue(value: CitySearchResult | string) {
    this.formGroup.get(WarehouseControls.city).setValue(value);
  }

  get city(): AbstractControl | null {
    return this.formGroup.get(WarehouseControls.city);
  }

  get warehouse(): AbstractControl | null {
    return this.formGroup.get(WarehouseControls.warehouse);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form.removeControl(this.controlName);
  }

  loadWarehouses(cityRef: string): void {
    this.cityRef = cityRef;
  }

  addFormControls(): void {
    if (this.form) {
      this.createControl();
    }
  }

  createControl(): void {
    this.formGroup = this.fb.group({
      [WarehouseControls.city]: [
        '',
        [
          cityResultMaskValidator(),
          ...(this.isTemplate ? [] : [Validators.required]),
        ],
      ],
      [WarehouseControls.warehouse]: [
        '',
        [Validators.required, WarehouseMaskValidator()],
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.warehouse.disable();
    this.initFormSubscriptions();
  }

  initFormSubscriptions(): void {
    this.subscriptions = this.city.statusChanges.subscribe((status) => {
      if (status === 'VALID' && this.city.value?.DeliveryCity) {
        this.warehouse.enable();
        this.loadWarehouses(this.city.value.DeliveryCity);
      } else {
        this.warehouse.disable();
        this.warehouse.reset('');
      }
    });
  }
}

import { Directive, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Unsubscriber } from './unsubscriber';

@Directive()
export abstract class FormContainer extends Unsubscriber implements OnInit {
  @Input() form: UntypedFormGroup;

  @Input() controlName = '';

  ngOnInit(): void {
    if (this.form) {
      this.addFormControls();
    }
  }

  abstract addFormControls(): void;
}

<div [formGroup]="form">
  <div class="form-row mt-1">
    <div class="form-col form-col-6 checkbox-row">
      <mat-checkbox
        (change)="changeSeats($event)"
        [(ngModel)]="checked"
        [attr.disabled]="disableButton$ | async"
        [disabled]="disableButton$ | async"
        [formControlName]="'paramsCommonSeats'"
        >Загальні параметри
      </mat-checkbox>
    </div>
  </div>
</div>

<mat-form-field [attr.data-test-id]="testId"
                class="form-input">
  <mat-label>{{ label }}</mat-label>
  <input [formControl]="formControl"
         [matAutocomplete]="auto"
         [maxLength]="maxLength"
         [placeholder]="placeholder"
         #input
         type="text"
         class="input"
         aria-label="Warehouse"
         matInput
         autocomplete="off"
         autoScrollTarget>
  <button *ngIf="!warehouse?.value && !loading && warehouses?.length"
          [disabled]="warehouse?.disabled"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
  </button>
  <button *ngIf="clearEnabled && warehouse?.value && !loading"
          (click)="clearField(warehouse, $event, '')"
          [disabled]="warehouse?.disabled"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="loading"
          class="p-0 transform"
          mat-button
          matSuffix
          mat-icon-button
          [disabled]="true">
    <mat-spinner [diameter]="19.5" class="form-field-spinner"></mat-spinner>
  </button>
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="getWarehouseDescription"
                    (optionSelected)="blur()">
    <mat-option *ngFor="let option of warehouses
                     | filterBy : 'Description'
                     : (warehouseFilterQuery$ | async)"
                [disabled]="loading || option.disabled"
                [matTooltip]="option.tooltip"
                [value]="option">
      {{ option.Description }}
    </mat-option>
  </mat-autocomplete>
  <mat-error>{{ getError(warehouse, WarehouseErrors) }}</mat-error>
</mat-form-field>

import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs';
import { IContactFilterParam } from './icontact.api';
import { IListData } from '../api-helper';
import { Contact, Contragent, IOrderParameter, OrgType } from '@models';
import { AuthService } from '@services';
import { Injectable } from '@angular/core';
import { ContactApiService } from './contact.api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  contactSaved: Contact;

  constructor(
    protected contactApiService: ContactApiService,
    protected authService: AuthService,
  ) {}

  getNewContact(): Contact {
    let contact: Contact;

    if (this.contactSaved) {
      contact = this.contactSaved;
      this.contactSaved = null;
    } else {
      contact = new Contact();
    }

    if (!contact.contragent) {
      contact.contragent = new Contragent();
    }

    return contact;
  }

  getMyContacts(
    filter: IContactFilterParam = {},
    orderParam: IOrderParameter = null,
    page: number = 1,
    limit: number = 20,
  ): Observable<IListData<Contact>> {
    filter.contactType = 'my';
    return this.contactApiService.getList(filter, orderParam, page, limit);
  }

  getPersonalContacts(
    filter: IContactFilterParam = {},
    orderParam: IOrderParameter = null,
    page: number = 1,
    limit: number = 20,
  ): Observable<IListData<Contact>> {
    filter.contactType = 'personal';
    return this.contactApiService.getList(filter, orderParam, page, limit);
  }

  getList(
    filter: IContactFilterParam = {},
    orderParam: IOrderParameter = null,
    page: number = 1,
    limit: number = 20,
    counterpartyRefs?: string[],
  ): Observable<IListData<Contact>> {
    return this.contactApiService.getList(
      filter,
      orderParam,
      page,
      limit,
      counterpartyRefs,
    );
  }

  getOrgTypes(): Observable<OrgType[]> {
    return this.contactApiService.getOrgTypes();
  }

  remove(id: string): Observable<boolean> {
    return this.contactApiService.remove(id);
  }

  removeList(ids: string[]): Observable<boolean> {
    const removed: Observable<boolean>[] = [];
    _.each(ids, (id) => {
      removed.push(this.remove(id));
    });

    return forkJoin(removed).pipe(map((r) => r.every((done) => !!done)));
  }

  add(item: Contact): Observable<Contact> {
    return this.contactApiService.add(item);
  }

  update(item: Contact): Observable<Contact> {
    return this.contactApiService.update(item);
  }

  getOne(id: string): Observable<Contact> {
    return this.contactApiService.getOne(id);
  }

  addContragentByEdpu(edpu: string): Observable<Contragent> {
    return this.contactApiService.addContragentByEdpu(edpu);
  }

  addContragentByName(contragent: Contragent): Observable<Contragent> {
    return this.contactApiService.addContragentByName(contragent);
  }

  findContacts(query: string, phone: string) {
    return this.contactApiService.findContacts(query, phone);
  }

  createContragentFromCatalog(
    contact: Contact,
    phone: string,
  ): Observable<any> {
    return this.contactApiService.createContragentFromCatalog(contact, phone);
  }

  getOneContragent(id: string): Observable<Contragent> {
    return this.contactApiService.getOneContragent(id);
  }

  getByEdrpu(edrpou: string): Observable<Contragent> {
    return this.contactApiService.getByEdrpu(edrpou);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-autocomplete-error',
  templateUrl: 'autocomplete-error.component.html',
  styleUrls: ['./autocomplete-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteErrorComponent {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableToolbarComponent } from './table-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [TableToolbarComponent],
  exports: [TableToolbarComponent],
})
export class TableToolbarModule { }

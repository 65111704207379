import { checkObjectMask } from '@shared';

export const WarehouseKeys = [
  'CategoryOfWarehouse',
  'Description',
  'DescriptionRu',
  'PlaceMaxWeightAllowed',
  'Ref',
  'TotalMaxWeightAllowed',
  'TypeOfWarehouse',
];
export const warehouseResultMaskValidator = checkObjectMask(WarehouseKeys);

export const internationalWarehouseKeys = [
  'FullDescription',
];
export const internationalwarehouseResultMaskValidator = checkObjectMask(internationalWarehouseKeys);

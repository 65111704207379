import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MasterpassCard, PaymentCostsInfo } from '@models';
import { LocalStorageService } from 'ngx-webstorage';
import { first, map, Observable } from 'rxjs';
import { PaymentProcessV3Component } from '../components/dashboard/acquiring/components/payment-process-v3/payment-process-v3.component';
import { Invoice } from '../models/invoice/invoice.model';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { GoogleAnalyticService } from './google-analytic.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class MasterpassService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private noRestApiHelper: NoRestApiHelper,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    public dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticService,
  ) {
  }

  initAddCard(phone?: string, number?: string | number): Observable<any> {
    const body = {
      modelName: 'Payment',
      system: this.configService.get('system'),
      calledMethod: 'initPayout',
      methodProperties: {
        Phone: phone ?? this.authService.user.phone,
        ...(!!number ? { Number: number } : {}),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((d) => d.data.data[0]),
    );
  }

  getWalletManagementUrl() {
    const body = {
      modelName: 'Payment',
      system: this.configService.get('system'),
      calledMethod: 'walletManagement',
      methodProperties: {},
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
    );
  }

  getPaymentInfo(invoiceNumber: string, phone: string) {
    const requestBody = {
      system: this.configService.get('system'),
      modelName: 'Payment',
      calledMethod: 'tlGetRecipientPaymentInfo',
      methodProperties: {
        Document: invoiceNumber,
        // Phone: phone,
        Language: 'uk',
        NewPaymentInfo: '1',
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, requestBody).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data) => data.data.data[0]),
      first(),
    );
  }

  cancelPayment(invoiceRefs: string[]) {
    const body = {
      system: this.configService.get('system'),
      modelName: 'PaymentGeneral',
      calledMethod: 'tlCancelPaymentByDocumentsNumbers',
      methodProperties: {
        Documents: invoiceRefs,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data) => data.data.data[0]),
    );
  }

  payWithBonuses(documentNumber: string, debitingAmount: string | number): Observable<number> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'payEwByLoyaltyPoints',
      methodProperties: {
        DocumentNumber: documentNumber,
        DebitingAmount: String(debitingAmount),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data) => data.data.data[0].Status.DebitingAmount),
    );
  }

  payWithPromocode(documentNumber: string, promocode: string) {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentGeneral',
      calledMethod: 'applyPromoCode',
      methodProperties: {
        DocumentNumber: documentNumber,
        Promocode: promocode,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  initPayment(
    invoice: Invoice | any,
    paymentCostsInfo: PaymentCostsInfo,
    selectedServices: { Services; MoneyTransfer; AfterPayment; InternationalDelivery; Redirecting; Return; },
    selectedCard: MasterpassCard,
    invoiceType: string,
  ): Observable<{ Url: string }> {
    const requestBody: any = {
      system: this.configService.get('system'),
      modelName: 'Payment',
      calledMethod: 'initPayment',
      methodProperties: {
        Document: invoice.docNumber,
        Phone: invoice.PhoneSender || invoice.SendersPhone || '',
        Services: [],
      },
    };

    if (invoiceType === 'in') {
      requestBody.methodProperties.Phone = invoice.PhoneRecipient || invoice.RecipientsPhone || '';
    }

    let PreauthorizationAmount = 0;

    let P2PAmount = 0;

    let PurchaseAmount = 0;

    const servicesNames = {
      Services: 'PaymentForServices',
      MoneyTransfer: 'MoneyTransferCash2Card',
      AfterPayment: 'PaymentForAfterPayment',
      InternationalDelivery: 'PaymentForServices',
      Redirecting: 'PaymentForRedirecting',
      Return: 'PaymentForReturn',
    };

    const Documents = [];

    Object.keys(selectedServices).map((serviceName) => {
      if (selectedServices[serviceName]) {
        const paymentObj: any = {
          PaymentType: servicesNames[serviceName],
          AmountToPay: paymentCostsInfo[serviceName],
        };

        switch (serviceName) {
          case 'Services':
            PreauthorizationAmount += paymentObj.AmountToPay;
            Documents.push(paymentObj);
            break;
          case 'Redirecting':
            PreauthorizationAmount += paymentObj.AmountToPay;
            Documents.push(paymentObj);
            break;
          case 'Return':
            PreauthorizationAmount += paymentObj.AmountToPay;
            Documents.push(paymentObj);
            break;
          case 'InternationalDelivery':
            PreauthorizationAmount += paymentObj.AmountToPay;
            paymentObj.DeliveryType = 'InternationalDelivery';
            Documents.push(paymentObj);
            break;
          case 'AfterPayment':
            if (paymentCostsInfo.pays.length) {
              const afterpayment = [];
              paymentCostsInfo.pays.map((item, index) => {
                afterpayment.push({
                  paycode: item.paycode,
                  PaymentType: 'PaymentForAfterPayment',
                  paysum: item.paysum,
                });
              });
              Documents.push(afterpayment);
            }
            break;
          case 'MoneyTransfer':
            if (paymentCostsInfo.Cash2CardCredit_Sid === '') {
              paymentObj.PaymentType = 'MoneyTransfer';
              P2PAmount += paymentObj.AmountToPay;
            } else {
              paymentObj.PaymentType = 'MoneyTransferCash2Card';
              PreauthorizationAmount += paymentObj.AmountToPay;
            }

            if (paymentCostsInfo.Commission > 0) {
              const commissionObj = {
                PaymentType: 'MoneyTransferCommission',
                AmountToPay: paymentCostsInfo.Commission,
              };

              Documents.push(commissionObj);
              PurchaseAmount += paymentCostsInfo.Commission;
            }
            Documents.push(paymentObj);
            break;
        }
      }
    });

    requestBody.methodProperties.Services = Documents;

    return this.http.post(`${this.configService.get('apiUrl')}json/`, requestBody).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map((data) => data.data.data[0]),
      first(),
    );
  }

  processPaymentV3(
    url: string,
    invoice: Invoice,
    selectedServices: { Services; MoneyTransfer; AfterPayment; InternationalDelivery; Redirecting; Return; },
    invoiceType: string,
    paymentCostsInfo: PaymentCostsInfo,
  ) {
    const dialogRef = this.dialog.open(PaymentProcessV3Component, {
      position: { right: '0' },
      width: '75%',
      height: '100%',
      panelClass: 'setting-content-popup',
      data: { url },
    });

    dialogRef.componentInstance.paymentSuccess.subscribe(() => {
      this.saveToEcommerce(
        `${invoice.docNumber},${Math.floor(Math.random() * Math.floor(100))}`,
        'NovaPoshta',
        invoice,
        selectedServices,
        invoiceType,
      );
    });

    return dialogRef.afterClosed();
  }

  saveToEcommerce(
    draftRef: string,
    affiliation = 'NovaPoshta',
    invoice: Invoice,
    selectedServices: { Services; MoneyTransfer; AfterPayment; InternationalDelivery; Redirecting; Return; },
    invoiceType: string,
    paymentCostsInfo?: PaymentCostsInfo,
  ) {
    if (!paymentCostsInfo) {
      this.getPaymentInfo(invoice.docNumber, invoice.SendersPhone).subscribe((data) =>
        this.saveEccomerceProcess(draftRef, affiliation, invoice, selectedServices, invoiceType, data),
      );
    } else {
      this.saveEccomerceProcess(draftRef, affiliation, invoice, selectedServices, invoiceType, paymentCostsInfo);
    }
  }

  private saveEccomerceProcess(
    draftRef: string,
    affiliation = 'NovaPoshta',
    invoice: Invoice,
    selectedServices: { Services; MoneyTransfer; AfterPayment; InternationalDelivery; Redirecting; Return; },
    invoiceType: string,
    paymentCostsInfo: PaymentCostsInfo,
  ) {
    let revenue = 0;
    if (selectedServices.AfterPayment) {
      revenue += paymentCostsInfo.AfterPayment - paymentCostsInfo.AfterPaymentPaid;
    }
    if (selectedServices.MoneyTransfer) {
      revenue += paymentCostsInfo.MoneyTransfer - paymentCostsInfo.MoneyTransferPaid;
    }
    const categories = {
      my: 'ID_Masterpass',
      in: 'EN_Masterpass',
      out: 'EN_Masterpass',
    };
    const product = {
      id: invoice.docNumber,
      name: `${invoice.docNumber},${draftRef}`,
      category: categories[invoiceType],
      quantity: 1,
    };
    const transaction = {
      id: invoice.docNumber,
      affiliation,
      revenue,
      shipping: selectedServices.Services ? paymentCostsInfo.Services : 0,
      tax: selectedServices.MoneyTransfer ? paymentCostsInfo.Commission : 0,
    };

    this.googleAnalyticsService.saveTransaction(product, transaction);
  }
}

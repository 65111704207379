<div [formGroup]="form" class="filters">
  <ng-container *ngFor="let group of config.filterGroups"
                [formGroupName]="group.controlName">
    <ng-container *ngFor="let filter of group.filters"
                  [ngSwitch]="filter.type">
      <div *ngSwitchCase="'input'">
        <app-hot-input-filter (filterApplied)="applyFilterForm()"
                              [config]="filter"
                              [initialForm]="initialForm[group.controlName]"
                              [controlName]="filter.controlName"
                              [form]="getGroup(group.controlName)">
        </app-hot-input-filter>
      </div>
      <div *ngSwitchCase="'radio'">
        <app-hot-radio-filter (filterApplied)="applyFilterForm()"
                              [config]="filter"
                              [initialForm]="initialForm[group.controlName]"
                              [controlName]="filter.controlName"
                              [form]="getGroup(group.controlName)"></app-hot-radio-filter>
      </div>
      <div *ngSwitchCase="'double-date'">
        <app-hot-double-date-filter (filterApplied)="applyFilterForm()"
                                    [config]="filter"
                                    [initialForm]="initialForm[group.controlName]"
                                    [form]="getGroup(group.controlName).get(filter.controlName)"></app-hot-double-date-filter>
      </div>
    </ng-container>
  </ng-container>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent,
  AutocompleteControlTriggerName,
  AutoCompleteOptionItem,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import {
  AddressType,
  AddressTypes,
  ContactPerson,
  DoorAddress,
  TypedFormGroup,
  WarehouseAddress,
} from '@models';
import {
  AbstractFormContainer,
  EditableAutocompleteControl,
  getAddressType,
  TypedFormControl,
  ValidateAddress,
} from '@shared';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { ChangeDetectionUpdateService } from '../../../../services/change-detection-update.service';
import {
  ErrorMessages,
  ErrorTypeMessages,
  GoogleAnalyticService,
  ValidationService,
} from '@services';
import { TypeRadioButton } from '@type-radio-group';

export type AddressMode = AutocompleteControlTriggerName | 'select';

@Component({
  selector: 'app-inv-address-sender',
  templateUrl: 'address-sender.component.html',
  styleUrls: ['./address-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSenderComponent
  extends AbstractFormContainer
  implements EditableAutocompleteControl, TypedFormControl, OnInit, OnDestroy
{
  AddressTypes = AddressTypes;
  @Input()
  internationalInv = null;
  @Input()
  templateValidation: boolean;
  @Input()
  invoiceForm: UntypedFormGroup;
  @Input() invoiceId: string;
  readonly CONTROL_NAMES: { [key: string]: keyof TypedFormGroup } = {
    MODE: 'mode',
    TYPE: 'type',
    DATA: 'data',
  };
  @Input() showNote: boolean;
  @Input()
  controlName = 'AddressSender';
  @Input()
  controlTypeName = 'type';
  @Input()
  isTmz = false;
  @Input()
  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Адреса відправника',
    placeholder: this.internationalInv ? '' : 'Адреса',
    arrow: true,
  };
  @Input()
  preventInput = false;
  @Input()
  controlsConfig: AutocompleteControls = {
    create: {
      enabled: true,
      tooltip: 'Створити нову адресу',
    },
  };
  @Input()
  types: Array<TypeRadioButton<AddressType>> = [
    {
      value: 'Warehouse',
      label: 'Відділення',
    },
    {
      value: 'Doors',
      label: 'Адреса',
    },
  ];
  errors: ErrorTypeMessages = {
    [this.CONTROL_NAMES.DATA]: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'address', message: 'Виберіть із запропонованих варіантів' },
      {
        key: 'weight',
        message:
          'Вказане відділення не може прийняти таке відправлення, оберіть інше відділення',
      },
    ],
  };
  @Input()
  options: Array<AutoCompleteOptionItem<any>> = [];
  errorMessages: ErrorMessages = {};
  errorsSubscription: Subscription;
  latestCity;
  noteDisabled;
  formGroup: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private validationService: ValidationService,
    private changeDetectionUpdateService: ChangeDetectionUpdateService,
    private googleAnalyticsService: GoogleAnalyticService,
  ) {
    super();
  }

  private _mode: AddressMode = null;

  get mode(): AddressMode {
    return this._mode;
  }

  @Input()
  set mode(value: AddressMode) {
    this._mode = value;
    this.parentForm.get('mode').setValue(this._mode, { emitEvent: false });
  }

  get data() {
    return this.formGroup && this.formGroup.get('data');
  }

  get deliveryForm(): AbstractControl {
    return this.form.parent && this.form.parent.get('stepDeliveryForm')
      ? this.form.parent.get('stepDeliveryForm')
      : null;
  }

  get parentForm(): AbstractControl | null {
    return this.form.get(this.controlName);
  }

  get activeType(): AddressType | null {
    return this.parentForm.get(this.controlTypeName).value;
  }

  set activeType(value: AddressType | null) {
    this.parentForm
      .get(this.controlTypeName)
      .setValue(value, { emitEvent: false });
  }

  setActiveTypeFromObject(value: object): void {
    this.activeType = getAddressType(value);
  }

  ngOnInit() {
    super.ngOnInit();
    this.changeDetectionUpdateService.registerChangeDetectionRef(this.cd);
    if (!this.options.length) {
      this.changeMode({ triggerName: 'create' });
    }
    this.setSelectErrorsSubscription();
    if (this.internationalInv) {
      this.config.placeholder = '';
      this.options = this.options.filter(
        (option) => option.option.TypeOfWarehouse,
      );
      this.types.map((type) => {
        if (type.value === 'Doors') {
          type.tooltip = 'Ця послуга буде можлива в майбутьньому';
        }
      });
    }
    this.noteDisabled = this.showNote ?? true;
  }

  setSelectErrorsSubscription() {
    this.errorMessages = this.validationService.getSingleControlErrorObject(
      this.CONTROL_NAMES.DATA,
      this.form.get(this.controlName).get(this.CONTROL_NAMES.DATA),
      this.errors,
    );
    this.form.valueChanges
      .pipe(
        filter(
          (value) =>
            !!value[this.controlName] &&
            !!value[this.controlName][this.CONTROL_NAMES.DATA],
        ),
        filter(() => this.mode !== 'create'),
        map((value) => value[this.controlName]),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.errorMessages = this.validationService.getSingleControlErrorObject(
          this.CONTROL_NAMES.DATA,
          this.form.get(this.controlName).get(this.CONTROL_NAMES.DATA),
          this.errors,
        );
        this.cd.detectChanges();
      });
    if (this.deliveryForm) {
      this.deliveryForm.valueChanges.subscribe((value) => {
        if (this.form && this.form.get(this.controlName)) {
          this.errorMessages =
            this.validationService.getSingleControlErrorObject(
              this.CONTROL_NAMES.DATA,
              this.form.get(this.controlName).get(this.CONTROL_NAMES.DATA),
              this.errors,
            );
          (
            this.form
              .get(this.controlName)
              .get(this.CONTROL_NAMES.DATA) as UntypedFormControl
          ).updateValueAndValidity({ onlySelf: true });
        }
        this.cd.detectChanges();
      });
    }
  }

  addFormControls() {
    if (!this.form.get(this.controlName)) {
      this.createControl(this.options.length ? this.options[0].option : '');
    }
  }

  createControl(defaultValue: DoorAddress | WarehouseAddress | ''): void {
    this.formGroup = this.fb.group({
      mode: ['select'],
      type: [defaultValue ? getAddressType(defaultValue) : this.types[0].value],
      data: [
        defaultValue,
        Validators.compose([
          Validators.required,
          ValidateAddress,
        ]),
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);

    this.setSubscriptionActiveType();
  }

  setSubscriptionActiveType() {
    this.form
      .get(this.controlName)
      .get(this.CONTROL_NAMES.DATA)
      .valueChanges.pipe()
      .subscribe((data) => {
        this.setActiveTypeFromObject(data);
      });
  }

  transformFn: AutoCompleteTransformFn<DoorAddress & WarehouseAddress> = (
    value: DoorAddress & WarehouseAddress,
  ) => {
    if (value === null) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    const type = getAddressType(value);
    switch (type) {
      case 'Warehouse':
        return this.createWarehouseDescription(value);
      case 'Doors':
        return this.createDoorsDescription(value);
      case 'Postbox':
        return this.createWarehouseDescription(value);
      default:
        return value.Description;
    }
  };

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    this.mode = event.triggerName;
    const { data } = this.parentForm.value as TypedFormGroup;

    if (this.mode === 'create') {
      this.activeType = this.types[0].value;
    } else {
      this.setActiveTypeFromObject(data);
    }

    this.googleAnalyticsService.track(
      'analitics',
      `second-step.createNewAddressSender`,
      {
        eventLabel: `Створення нової адреси відправника`,
        eventCategory: 'create-invoice',
        eventAction: 'create.start',
      },
    );
    this.cd.detectChanges();
  }

  cancel(): void {
    // this.setSelectErrorsSubscription();
    this.createControl(this.options.length ? this.options[0].option : '');
    this.latestCity = null;
    this.mode = 'select';
    this.cd.detectChanges();
  }

  clear() {
    this.latestCity = null;
    this.changeMode({ triggerName: 'create' });
    this.form.get(this.controlName).reset({
      mode: 'create',
      type: 'Warehouse',
      data: {
        city: '',
        warehouse: '',
      },
    });
    this.cd.detectChanges();
  }

  setLatestCity(city) {
    this.latestCity = city;
  }

  createWarehouseDescription(address: WarehouseAddress) {
    return `${address.CityDescription}, ${address.AddressDescription}`;
  }

  createDoorsDescription(address: DoorAddress) {
    return `${address.Type} ${address.SettlementDescription}, ${
      address.StreetsType
    } ${address.StreetDescription} ${address.BuildingNumber}${
      address.Note ? ', ' + address.Note : ''
    }`;
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }
}

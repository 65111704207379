import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'stf-accordeon-item',
  templateUrl: './stf-accordeon-item.component.html',
  styleUrls: ['./stf-accordeon-item.component.scss'],
})
export class StfAccordeonItemComponent implements OnInit {
  @Input() open = false;
  @Output() openChange = new EventEmitter<{ open: boolean }>();
  @Input() showExpandable = true;

  constructor() {
  }

  ngOnInit() {
  }

  openClose() {
    this.open = !this.open;
    this.openChange.emit({ open: this.open });
  }

}

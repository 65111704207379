<div class="stf-select stf-select_sm"
    [ngClass]="{ 'stf-select_has-value': selected !== null && selected !== undefined ? true : false,
        'stf-select_opened': isOpened && !selectDisabled,
        'stf-select_empty': !options || !options.length,
        'stf-select_focus-searh': isFocusSearh
        }"
    (keydown)="keyDown($event)"
    (keypress)="keyPress($event)"
    (click)="open($event)"

>
    <div class="stf-select__container">
        <div class="stf-select__inner-wrapper">
            <div class="stf-select__value">
              <ng-content select="[value-text]"></ng-content>
            </div>
            <div class="stf-select__placeholder">
              <ng-content select="[label]"></ng-content>
            </div>
            <div (click)="openClose($event)" class="stf-select__icon"></div>
        </div>

        <div (focus)="onSelectFocus($event)" (blur)="blurSearch()" [tabindex]="isOpened && !selectDisabled ? -1 : 0" class="stf-select__search-input">
            <ng-content select="[search-input]"></ng-content>
        </div>
        <div [id]="selectId" [ngClass]="{'stf-select_opened': isOpened && !selectDisabled, 'stf-select_empty': !options || !options.length}"
            class="stf-select__options-wraped">
            <div *ngIf="!isNeedHideOption"
                class="stf-select__options stf-select-options"
                role="combobox" aria-haspopup="true" aria-expanded="false"
                (keydown)="keyDown($event)"
            >
                <div (mousewheel)="onMouseWheal($event)"
                (scroll)="makeLoadMore()"
                    class="stf-select__options-container">
                    <ng-content></ng-content>
                </div>
                <ng-content select="[button]"></ng-content>
            </div>
        </div>
    </div>
</div>

import {Component, EventEmitter, OnDestroy, OnInit, Optional, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DateAdapter, NativeDateAdapter} from "@angular/material/core";
import * as moment from "moment";
import {Subscription} from 'rxjs';
import {LocalStorageService} from "ngx-webstorage";
import {SettingsService} from "../../../components/dashboard/settings/services/settings.service";
import {AlertToastService} from "../../../services/alert-toast.service";
import {AuthService} from "../../../services/auth.service";
import {ValidationService} from "../../../services/validation.service";


@Component({
  selector: 'app-change-birth-date',
  templateUrl: './add-birth-date.component.html',
  styleUrls: ['./add-birth-date.component.scss'],
})
export class AddBirthDateComponent implements OnInit, OnDestroy {

  birthDateForm: UntypedFormGroup;

  errorsMessages: { [key: string]: string } = {};

  errors = {
    birthDate: [
      {key: 'required', message: 'Це поле не може бути пустим'},
    ],
  };

  showPassword = {
    currentPassword: false,
    newPassword: false,
  };

  private errorsSubscr: Subscription;

  startDate = moment()
    .toDate();

  maxDate = moment()
    .subtract(1, 'days')
    .toDate();

  dateValue;

  @Output() public close = new EventEmitter<void>();

  constructor(
    private settingsService: SettingsService,
    private alertToastService: AlertToastService,
    private fb: UntypedFormBuilder,
    public authService: AuthService,
    public validationService: ValidationService,
    private localStorageService: LocalStorageService,
    private dateAdapter: DateAdapter<NativeDateAdapter>) {
    dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.birthDateForm = this.fb.group({
      birthDate: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
    });

    this.errorsSubscr = this.birthDateForm.valueChanges.subscribe(data => {
      this.errorsMessages = this.validationService.getSingleErrorsObject(
        this.birthDateForm.controls,
        this.errors,
      );
    });
  }

  ngOnDestroy() {
    this.errorsSubscr.unsubscribe();
  }

  changeBirthDate() {
    this.errorsMessages = this.validationService.getSingleErrorsObject(
      this.birthDateForm.controls,
      this.errors,
    );
    if (this.birthDateForm.valid) {
      this.settingsService.changeBirthDate(this.birthDateForm.value.birthDate).subscribe(user => {
        this.close.next();
        this.localStorageService.store(`addedNewBirthDate${this.authService.user.login}`, true);
        this.alertToastService.pushSuccess(
          'Дату народження успішно додано. Зміни буде відображено в налаштуваннях при наступному вході',
        );
        }, errors => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
        },
      );
    }
  }

  onDateChange($event) {
    const date = new Date($event);
    this.dateValue = date;
    this.birthDateForm.patchValue({
      birthDate: moment(date).format('DD.MM.YYYY'),
    });
  }

  closeDialog() {
    this.close.next();
  }
}

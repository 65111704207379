import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material';
import { LocalExpressTimeIntervalsComponent } from './components/local-express-time-intervals/local-express-time-intervals.component';
import { LocalExpressComponent } from './local-express.component';

const COMPONENTS = [
  LocalExpressComponent,
  LocalExpressTimeIntervalsComponent,
]
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class LocalExpressModule {}

import { Component, EventEmitter, Output } from '@angular/core';
import { GoogleAnalyticService } from '@services';

@Component({
  selector: 'app-invoice-navbar',
  templateUrl: './invoice-navbar.component.html',
  styleUrls: ['./invoice-navbar.component.scss'],
})
export class InvoiceNavbarComponent {
  @Output() exitForm = new EventEmitter<boolean>();

  constructor(private googleAnalyticsService: GoogleAnalyticService) {}

  exit(): void {
    this.exitForm.emit();
    this.googleAnalyticsService.track('analitics', `invoice-header.logo`, {
      eventLabel: 'logo_exit_click',
      eventCategory: 'invoice',
      eventAction: 'click',
    });
  }
}

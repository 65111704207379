import { Contragent } from '@models';

export function searchContragent(contragent: Contragent, query: string): boolean {
  const searchString = query.toLowerCase().trim();
  if (contragent?.CounterpartyFullName?.toLowerCase().includes(searchString)) {
    return true;
  }

  if (contragent?.Description?.toLowerCase().includes(searchString)) {
    return true;
  }

  if (contragent?.EDRPOU?.toLowerCase().includes(searchString)) {
    return true;
  }

  if (contragent?.OwnershipFormDescription?.toLowerCase().includes(searchString)) {
    return true;
  }

  return false;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AbstractFormContainer} from '@shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-by-hand',
  templateUrl: './delivery-by-hand.component.html',
  styleUrls: ['./delivery-by-hand.component.scss'],
})
export class DeliveryByHandComponent extends AbstractFormContainer implements OnInit, OnDestroy {

  @Input() controlName = 'DeliveryByHandRecipients';

  constructor(protected fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get deliveryByHand(): UntypedFormGroup {
    return this.form.get('AdditionalServices.deliveryByHand') as UntypedFormGroup;
  }

  get deliveryRecipientsArray(): UntypedFormArray {
    return this.deliveryByHand.get(this.controlName) as UntypedFormArray;
  };

  addFormControls() {
    this.deliveryByHand.addControl(this.controlName, this.fb.array([ this.getCurrentRecipient() ]));
  }

  createRecipient(name = '', surname = '', middleName = '', disabled = false): UntypedFormGroup {
      return this.fb.group({
      name: [{value: name, disabled}, [
        Validators.required,
      ]],
      surname: [{value: surname, disabled}, [
        Validators.required,
      ]],
      middleName: [{value: middleName, disabled}, [
        Validators.required,
      ]],
    });
  }

  getCurrentRecipient() {
    const data = this.form.getRawValue();
    let name = '',
        surname = '',
        middleName = '';
    if (data.Recipient) {
      name = data.Recipient.FirstName;
      surname = data.Recipient.LastName;
      middleName = data.Recipient.MiddleName;
    } else if (this.form.value.NewRecipientPrivateContact) {
      name = data.NewRecipientPrivateContact.firstName;
      surname = data.NewRecipientPrivateContact.lastName;
      middleName = data.NewRecipientPrivateContact.middleName;
    }
    return this.createRecipient(name, surname, middleName, true);
  }

  removeRecipient(i: number) {
    this.deliveryRecipientsArray.removeAt(i);
  }

  addRecipient(): void {
    this.deliveryRecipientsArray.push(this.createRecipient());
  }

  ngOnDestroy() {
    this.deliveryByHand.removeControl(this.controlName);
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material';
import { CoreModule } from '../../../core/core.module';
import { AutocompleteControlsComponent } from './autocomplete-controls/autocomplete-controls.component';
import { AutocompleteErrorComponent } from './autocomplete-error/autocomplete-error.component';
import { AutocompleteOptionDirective } from './autocomplete-option/autocomplete-option.directive';
import { AutocompleteOptionsFilterPipe } from './autocomplete-options-filter/autocomplete-options-filter.pipe';
import { AutocompleteComponent } from './autocomplete.component';
import { KeyInputPreventDirective } from './keydown-prevent/key-input-prevent.directive';
import { AutocompleteSearchComponent } from './autocomplete-search/autocomplete-search.component';

const COMPONENTS = [
  AutocompleteComponent,
  AutocompleteOptionDirective,
  AutocompleteControlsComponent,
  AutocompleteErrorComponent,
  AutocompleteOptionsFilterPipe,
  AutocompleteSearchComponent,
  KeyInputPreventDirective,
];

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, CoreModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class AutocompleteModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs';
import { ConfigService } from '../config.service';
import { NoRestApiHelper } from '../no-rest-api.helper';
import { capitalize, parseNumbers } from "@shared";
import {
  MutualEmail,
  OverdueContract,
  TrustedCounterpartyContract,
} from '../../components/dashboard/mutual-settlements/mutual-settlements.models';
import {
  CatalogContact,
  ContactPerson,
  ContactProperty,
  Contragent,
  CounterpartyProperty,
  SavedCatalogContact,
} from '@models';
import { ApiMethods } from '../api/apiMethods';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {}

  getContactPersonsList(
    counterpartyRef: string = '',
    contactProperty: ContactProperty = 'Recipient',
    includeAddress: boolean = false,
    findBy: string = '',
    limit: number = 20,
    page: number = 1,
    methodProperties: object = {},
  ): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'getContactPersonsList',
      methodProperties: {
        ...(counterpartyRef ? { CounterpartyRef: counterpartyRef } : {}),
        ContactProperty: contactProperty,
        Limit: limit,
        Page: page,
        getContactPersonAddress: Number(includeAddress),
        FindByString: findBy,
        ...methodProperties,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
        catchError(() => of([])),
      );
  }

  getContactPersonsListWithoutAgents(
    counterpartyRef: string = '',
    contactProperty: ContactProperty = 'Recipient',
    includeAddress: boolean = false,
    findBy: string = '',
    limit: number = 20,
    page: number = 1,
    methodProperties: object = {},
  ): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'getContactPersonsList',
      methodProperties: {
        CounterpartyRef: counterpartyRef,
        ContactProperty: contactProperty,
        Limit: limit,
        WarehouseForAgent: false,
        Page: page,
        getContactPersonAddress: Number(includeAddress),
        FindByString: findBy,
        ...methodProperties,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
      );
  }

  searchRecipientContacts(query: string): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'getContactPersonsCounterparty',
      methodProperties: {
        ContactProperty: 'Recipient',
        Page: '',
        Limit: '',
        getContactPersonAddress: '1',
        FindByString: query,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
        catchError(() => of([])),
      );
  }

  searchContactsInCatalog(
    query: string,
    phone: string,
  ): Observable<CatalogContact[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCatalogCounterparty',
      methodProperties: {
        Phone: phone,
        LastName: capitalize(query),
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as CatalogContact[]),
        catchError((err) => {
          return of([]);
        }),
      );
  }

  createContactFromCatalog(
    contact: CatalogContact,
    phone: string,
  ): Observable<SavedCatalogContact> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPerson',
      calledMethod: 'save',
      methodProperties: {
        CounterpartyRef: contact.RefCounterparty,
        FirstName: contact.FirstName,
        LastName: contact.LastName,
        MiddleName: contact.MiddleName,
        Phone: phone,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as SavedCatalogContact),
      );
  }

  createContact(contact: ContactPerson): Observable<ContactPerson> {
    (contact as any).Phone = parseNumbers(contact.Phones);
    delete contact.Phones;
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: ApiMethods.save,
      methodProperties: {
        ...contact,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as ContactPerson),
      );
  }

  updateContact(contact: ContactPerson) {
    (contact as any).Phone = contact.Phones;
    delete contact.Phones;
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'update',
      methodProperties: {
        ...contact,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data: any) =>
          data.warnings.length && !data.errors.length
            ? { ...data, errors: data.warnings, success: false }
            : data,
        ),
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as ContactPerson),
      );
  }

  getContragent(
    ref: string,
    counterpartyProperty: CounterpartyProperty = 'Recipient',
  ): Observable<Contragent> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: counterpartyProperty,
        Ref: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as Contragent),
      );
  }

  getContragentList(
    counterpartyProperty: CounterpartyProperty = 'Recipient',
  ): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: counterpartyProperty,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
      );
  }

  getCounterparties(): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Sender',
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
      );
  }

  getCounterpartyList(query?: string): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Sender',
        Page: 1,
        Limit: 200,
        ...(query ? { FindByString: query } : {}),
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
      );
  }

  getRecipientCounterpartyList(query?: string): Observable<ContactPerson[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Recipient',
        Page: 1,
        Limit: 200,
        ...(query ? { FindByString: query } : {}),
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as ContactPerson[]),
      );
  }

  checkTrustedCounterparty(ref: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'checkTrustedCounterparty',
      methodProperties: {
        CounterpartyRef: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  getTrustedCounterparties(ref: string): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getTrustedCounterparties',
      methodProperties: {
        CounterpartyRef: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
        // map(response => response.data.data as SettingsCounterpartyContragent),
      );
  }

  getMutualMailing(ref: string): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'getMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as MutualEmail[]),
      );
  }

  addMutualMailing(ref: string, { MailingAddressString }): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'addMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
        MailingAddressString,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  deleteMutualMailing(ref: string, { MailingAddressRef }): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'deleteMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
        MailingAddressRef,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  editMutualMailing(
    ref: string,
    { MailingAddressRef, MailingAddressString }: MutualEmail,
  ): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'editMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
        EditType: 'MailingAddress',
        MailingAddressRef,
        MailingAddressString,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  editMutualMailingEDO(
    ref: string,
    contractRef: string,
    emailEdo: string,
  ): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'editMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
        EditType: 'EmailEdo',
        ContractRef: contractRef,
        EmailEdo: emailEdo,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  editMutualMailingProviderEDO(
    ref: string,
    contractRef: string,
    providerEdo: string,
  ): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'editMutualMailing',
      methodProperties: {
        InternetCounterpartyRef: ref,
        EditType: 'ProviderEdo',
        ContractRef: contractRef,
        ProviderEdo: providerEdo,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  sendActsAndSpecifications(
    ref: string,
    { DateFrom, DateTo },
    email: string,
  ): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'MutualGeneral',
      calledMethod: 'sendActsAndSpecifications',
      methodProperties: {
        InternetCounterpartyRef: ref,
        EMail: email,
        DateFrom,
        DateTo,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
      );
  }

  removeCounterparty(ref: string): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'unsetTrustedCounterpartyByMaster',
      methodProperties: {
        CounterpartyRef: ref,
        logAll: 'log',
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data as any),
      );
  }

  removeRelatedCounterparty(ref: string): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'unsetTrustedCounterpartyBySlave',
      methodProperties: {
        CounterpartyRef: ref,
        logAll: 'log',
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data as any),
      );
  }

  addCounterparty(d: any): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'addTrustedCounterparty',
      methodProperties: {
        Counterparty: d.counterparty.Ref,
        EDRPOU: d.edrpou,
        FirstName: d.firstName,
        MiddleName: d.middleName,
        LastName: d.lastName,
        Phone: d.phone,
        logAll: 'log',
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data as any),
      );
  }

  getTrustedCounterpartyRelation(ref: string): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getTrustedCounterpartyRelation',
      methodProperties: {
        CounterpartyRef: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as any),
        // map(response => response.data.data as SettingsCounterpartyContragent),
      );
  }

  getContragentByDescription(
    edrpou: string,
    description: string = '',
    ownershipForm: string = '',
  ) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Recipient',
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => {
          let contragent;
          const privatePersonOwnershipForm =
            'd558bfcb-7456-11df-ad52-000c29118aa7';
          for (let i = 0; i < response.data.data.length; i++) {
            const el = response.data.data[i];
            if (
              el.EDRPOU === edrpou &&
              description !== 'Приватна особа' &&
              ownershipForm !== privatePersonOwnershipForm
            ) {
              contragent = el;
              break;
            } else if (
              (ownershipForm === privatePersonOwnershipForm ||
                description === 'Приватна особа') &&
              el.OwnershipFormRef === privatePersonOwnershipForm
            ) {
              contragent = el;
              break;
            }
          }
          return contragent as Contragent;
        }),
      );
  }

  getByRef(ref: string): Observable<ContactPerson> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'getContactPersonsList',
      methodProperties: {
        Ref: ref,
        getContactPersonAddress: 1,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as ContactPerson),
        catchError(() => of(null)),
      );
  }

  getByEdrpou(edrpou: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Recipient',
        EDRPOU: edrpou,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as Contragent),
      );
  }

  getByEdrpouBot(edrpou: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'save',
      methodProperties: {
        CityRef: '',
        CounterpartyType: 'Organization',
        CounterpartyProperty: 'Recipient',
        EDRPOU: edrpou,
        OwnershipForm: '',
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as Contragent),
      );
  }

  update(contact: ContactPerson): Observable<ContactPerson> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPerson',
      calledMethod: 'update',
      methodProperties: {
        Ref: contact.Ref,
        CounterpartyRef: contact.CounterpartyRef,
        FirstName: contact.FirstName,
        LastName: contact.LastName,
        MiddleName: contact.MiddleName,
        Phone: contact.Phones,
        AdditionalPhone: contact.AdditionalPhone,
        Email: contact.Email,
        Info: contact.Info,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as ContactPerson),
      );
  }

  remove(ref) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPerson',
      calledMethod: 'delete',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as ContactPerson),
      );
  }

  getPrivatePersonInfo() {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Recipient',
        GetPrivatePerson: '1',
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0]),
      );
  }

  getTrustedCounterpatyOverdueContracts(): Observable<OverdueContract[]> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getTrustedCounterpatyOverdueContracts',
      methodProperties: {},
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data),
      );
  }

  getTrustedCounterpartyContracts(
    ref: string,
    overdueContracts?: boolean,
    activeContracts?: boolean,
  ): Observable<TrustedCounterpartyContract[]> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getTrustedCounterpartyContracts',
      methodProperties: {
        CounterpartyRef: ref,
        OnlyOverdueContracts: overdueContracts,
        OnlyActiveContracts: activeContracts,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data),
      );
  }
}

import { AbstractControl } from '@angular/forms';

export function patternDescription(control: AbstractControl): { [key: string]: boolean } | null {

  const value = control.value;
  const pattern = new RegExp(
    /^[а-яА-Я,ёЁіІїЇєЄґҐюЮa-zA-Z\d\s.№;!:'*+`’\/()\-"«»\\_&„”]*$/,
  );
  if (typeof value === 'string' && !pattern.test(value)) {
    return { patternDescription: true };
  }

  return null;
}

export enum ReturnDocumentType {
  ExpressWaybill = 'ExpressWaybill',
  OrderReturn = 'OrderReturn',
  InternetDocumentCustomReturnAddress = 'InternetDocumentCustomReturnAddress',
  CustomReturnAddress = 'CustomReturnAddress',
}

export enum ReturnRequestControlName {
  Reason = 'Reason',
  SubtypeReason = 'SubtypeReason',
  PaymentMethod = 'PaymentMethod',
  addressType = 'addressType',
  recipientContact = 'recipientContact',
  recipientCounterparty = 'recipientCounterparty',
  recipientAddress = 'recipientAddress',
}

export enum CargoReturnAddressTypes {
  InternetDocumentCustomReturnAddress = 'InternetDocumentCustomReturnAddress',
  CustomReturnAddress = 'CustomReturnAddress',
  ExpressWaybill = 'ExpressWaybill',
}

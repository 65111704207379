import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { parseNumbers } from '@shared';

// Example: <input [textMask]="PhoneMaskConfig" phone

export const PhoneMask = [
  '+',
  '3',
  '8',
  '0',
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];
export const PhoneMaskConfig = { mask: PhoneMask, guide: false };
export const PhonePrefix = '+38';
export const PhonePrefixWithZero = '+380';

@Directive({
  selector: '[phone]',
})
export class PhoneInputDirective {
  @Input() prefix = PhonePrefixWithZero;
  @Input() maskValue = PhoneMask;

  constructor(private ngControl: NgControl) {}

  get length(): number {
    return this.maskValue.length;
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    if (!this.ngControl) {
      return;
    }
    const target = event.target as HTMLInputElement;
    if (target.value.trim() === '') {
      target.value = this.prefix;

      this.setPrefix(target);
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent): void {
    if (!this.ngControl) {
      return;
    }
    const target = e.target as HTMLInputElement;
    if (target.value === '') {
      this.setPrefix(target);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(e: Event): void {
    if (!this.ngControl) {
      return;
    }

    const target = e.target as HTMLInputElement;

    if (this.prefix === PhonePrefixWithZero) {
      if (target.value.length === this.prefix.length + 1) {
        this.ngControl.control.setValue(null);
        return;
      }
    }

    if (this.prefix === PhonePrefix) {
      if (target.value.length === this.prefix.length) {
        this.ngControl.control.setValue(null);
        return;
      }
    }

    if (target.value.length > 0 && target.value.length < this.length) {
      this.ngControl.control.setErrors({ minlength: true });
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    e.preventDefault();
    const f = this.format(parseNumbers(e.clipboardData.getData('Text')));

    if (this.ngControl) {
      this.ngControl.control.setValue(
        `${this.prefix} ${f.slice(0, 2)} ${f.slice(2, 5)} ${f.slice(
          5,
          7,
        )} ${f.slice(7, 9)}`,
      );
    }
  }

  private setPrefix(target: HTMLInputElement): void {
    this.ngControl.control.setValue(this.prefix);

    if (this.prefix === PhonePrefixWithZero) {
      target.selectionStart = this.prefix.length + 1;
    }

    if (this.prefix === PhonePrefix) {
      target.selectionStart = this.prefix.length;
    }
  }

  private format(v: string): string {
    if (!v || typeof v !== 'string') {
      return '';
    }

    switch (v.length) {
      case 11:
        v = v.replace(/^8/, '');
        break;
      case 12:
        v = v.replace(/^38/, '');
        break;
      default:
        v = v.replace(/^3/, '').replace(/^8/, '');
    }

    if (this.prefix === PhonePrefixWithZero) {
      v = v.replace(/^0/, '');
    }

    return v;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { formatUAPhone } from '../../shared/utils';

@Pipe({
  name: 'npFinePhone',
})
export class NpFinePhonePipe implements PipeTransform {
  transform(value: string): string {
    return formatUAPhone(value);
  }
}

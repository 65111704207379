<ng-container [formGroup]="form">
  <app-autocomplete [size]="3"
                    [errors]="errors"
                    [formControl]="control"
                    [options]="options"
                    [displayTransformFn]="transformFn"
                    [config]="configDescription"
                    [controlsConfig]="null"
                    [pointer]="false"
                    [searchEnabled]="false"
                    (input)="setDescriptionQuery($event)">
  </app-autocomplete>
</ng-container>

<div [formGroup]="form">
  <div formArrayName="OptionsSeat">
    <div class="size-form size-form_with-border"
         *ngFor="let optionsSeat of form.controls['OptionsSeat']['controls']; let i=index;">
      <div class="size-form__item">
        <div class="size-form__hint">Місце {{i + 1}}</div>
        <ng-container [formGroupName]="i">
          <div class="form-row">
            <div class="form-col form-col-1 form-col-1_with_symbol">
              <mat-form-field class="mat-block mat-block_with_symbol mat-block_placeholder-top"
                              data-symbol="кг">
                <input
                  matInput
                  npNumber
                  NumberInput="true"
                  [maxValue]="1000"
                  [allowFloat]="true"
                  [digitsAfterDot]="3"
                  placeholder="Вага"
                  [errorStateMatcher]="matcher"
                  formControlName="weight">
              </mat-form-field>
            </div>
            <div class="form-col form-col-1">
              <mat-form-field class="mat-block mat-block_placeholder-top">
                <input matInput npNumber
                       (input)="recalculateVolumetricVolume(i)"
                       placeholder="Довжина"
                       formControlName="volumetricLength"
                       (keypress)="preventDecimal($event)"
                       NumberInput="true"
                       [maxValue]="999"
                       [maxInputLength]="3"
                       [errorStateMatcher]="matcher">
              </mat-form-field>
            </div>
            <div class="form-col form-col-1">
              <mat-form-field class="mat-block mat-block_placeholder-top">
                <input matInput npNumber
                       placeholder="Ширина"
                       (input)="recalculateVolumetricVolume(i)"
                       formControlName="volumetricWidth"
                       (keypress)="preventDecimal($event)"
                       NumberInput="true"
                       [maxValue]="999"
                       [maxInputLength]="3"
                       [errorStateMatcher]="matcher">
              </mat-form-field>
            </div>
            <div class="form-col form-col-1 form-col-1_with_symbol">
              <mat-form-field class="mat-block mat-block_with_symbol mat-block_placeholder-top"
                              data-symbol="см">
                <input
                  matInput
                  npNumber
                  placeholder="Висота"
                  (input)="recalculateVolumetricVolume(i)"
                  formControlName="volumetricHeight"
                  (keypress)="preventDecimal($event)"
                  NumberInput="true"
                  [maxValue]="999"
                  [maxInputLength]="3"
                  [errorStateMatcher]="matcher">
              </mat-form-field>
            </div>
            <div class="form-col form-col-1">
              <div class="info-cell">
                <div class="info-cell__label">Об’ємна вага
                  <i class="material-icons icon-help info-cell__icon cursor-pointer"
                     [matTooltip]="'Об’ємна вага розраховується за формулою (Довжина × Ширина × Висота, см) / 4000 та порівнюється з фактичною вагою. Більший показник використовується в розрахунках вартості перевезення.'"
                     matTooltipPosition="below">help_outline</i>
                </div>
                <div class="info-cell__content">{{optionsSeat.get('volumetricVolume').value || '&mdash;'}}</div>
              </div>
            </div>
            <ng-content></ng-content>
          </div>
          <div class="form-row mr-bottom-16 special-cargo-container mt-3">
            <mat-checkbox formControlName="specialCargo"
                          [matTooltip]="!checkSpecialCargoCondition(i) ? 'Параметри відправлення не відповідають умовам вибору: Вага (об’ємна та фактична) від 0.1 до 30 кг (включно); Сторони: Довжина, Ширина, Висота  <= 120 см' : null"
                          matTooltipPosition="below"
                          [disabled]="!checkSpecialCargoCondition(i)">
              Ручна обробка
            </mat-checkbox>
            <i class="material-icons icon-help icon-help_sm cursor-pointer"
               [matTooltip]="'Встановіть відмітку, якщо дане відправлення (місце): не запаковано в коробку або гофрокартон з пласкою основою та не є пласким пакетом з одягом; відправляється та доставляється з/до вантажних відділень, адреси або пункту приймання-видачі'"
               matTooltipPosition="below">help_outline</i>
          </div>
        </ng-container>
      </div>
      <div class="control-icons size-form__control-icons">
        <div class="control-icons__item">
          <button mat-icon-button>
            <mat-icon class="material-icons_grey" [matTooltip]="'Копіювати місце'"
                      matTooltipPosition="below"
                      (click)="copyOptionsSeat(i);">content_copy
            </mat-icon>
          </button>
        </div>
        <div class="control-icons__item"
             *ngIf="form.controls['OptionsSeat']['controls'] && form.controls['OptionsSeat']['controls'].length > 1">
          <button mat-icon-button (click)="removeOptionsSeat(i);">
            <mat-icon class="material-icons_grey" [matTooltip]="'Видалити місце'" matTooltipPosition="below">close
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row form-row_button">
    <div class="form-col form-col-2">
      <button type="button"
              class="mat-button_with-icon"
              mat-button color="primary"
              (click)="addOptionsSeat()">
        <mat-icon>
          add_circle
        </mat-icon>
        Додати місце
      </button>
    </div>
  </div>
</div>

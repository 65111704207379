import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  Contragent,
  IApiError,
  IListData,
  InvoiceResponseModel,
  IOrderParameter,
  Locality,
  PaymentCard,
  Street,
  TimeInterval,
} from '@models';
import { AuthService } from '@services';
import {
  AddressSettings,
  getBackDeliveriesDescriptionForTooltip,
  GetLightReturnReasonsResponse,
  LightReturnResponse,
  parseBackDeliveriesFromInvoice,
} from '@shared';
import * as _ from 'lodash';
import * as moment from 'moment';
import { concatMap, forkJoin, from, map, Observable, of, switchMap, take, zip as observableZip } from 'rxjs';
import { InvoiceCalculations } from '../../models/invoice/invoice-calculations.model';
import { InvoiceCargoType } from '../../models/invoice/invoice-cargo-type.model';
import { InvoiceContact } from '../../models/invoice/invoice-contact.model';
import { InvoicePaymentMethod } from '../../models/invoice/invoice-payment-method.model';
import { InvoiceServiceRequest } from '../../models/invoice/invoice-service-request.model';
import { InvoiceServiceType } from '../../models/invoice/invoice-service-type.model';
import { InvoiceSize } from '../../models/invoice/invoice-size.model';
import { InvoiceState } from '../../models/invoice/invoice-state.model';
import { getOutgoingDocumentsByPhoneResponse, Invoice, InvoiceMy } from '../../models/invoice/invoice.model';
import { TireWheel } from '../../models/invoice/tire-wheal.model';
import { AddressApiService } from '../address.api.service';
import { ApiMethods, ApiModelName } from '../api/apiMethods';
import { CacheService } from '../cache.service';
import { ConfigService } from '../config.service';
import { ContactApiService } from '../contacts/contact.api.service';
import { NoRestApiHelper } from '../no-rest-api.helper';
import { IFilterParam } from './iapi/iinvoice.api';
import { states } from './invoice-states.config';
import { _bindListToModelType2, getRedirectingReturnIndication } from './invoices.utils';

export enum CargoType {
  'DOCUMENTS' = 'Документи',
  'CARGO' = 'Вантаж',
  'PARCEL' = 'Посилка',
  'DOORSDOORS' = 'Адреса → Адреса',
  'WAREHOUSEDOORS' = 'Відділення → Адреса',
  'DOORSWAREHOUSE' = 'Адреса → Відділення',
  'WAREHOUSEWAREHOUSE' = 'Відділення → Відділення',
  'MONEY' = 'Грошовий переказ',
  'OTHER' = 'Інше',
  'PALLET' = 'Палети',
  'TIRESWHEELS' = 'Шини та диски',
}

export enum CargoTypes {
  PALLET = 'Pallet',
}

export enum InvoiceType {
  MY = 'my',
  IN = 'in',
  OUT = 'out',
  REGISTER = 'register',
  ARCHIVED = 'archived',
  TRASHCAN = 'trashcan',
}

/* Added this to handle invoice-my registries
 * and perform checks for creating and adding registries
 * */
const RefProperties = [
  'Ref',
  'CitySender',
  'CityRecipient',
  'State',
  'SenderAddress',
  'RecipientAddress',
  'Sender',
  'ContactSender',
  'Recipient',
  'ContactRecipient',
  'Cost',
  'AnnouncedPrice',
];

export function getCounterPartyType(value: string) {
  return value && value === 'Приватна особа' ? 'PrivatePerson' : value;
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceApi {
  /**
   * Kostil is used for saving list income and outcome Documents
   * We need becouse information for tracking document is not enaugh and we can use this saved documents
   * as resource of information
   */
  protected _cachedInvoices: any = {};

  constructor(
    protected http: HttpClient,
    protected configService: ConfigService,
    protected noRestApiHelper: NoRestApiHelper,
    protected authService: AuthService,
    protected addressApiService: AddressApiService,
    protected contactApiService: ContactApiService,
    protected cacheService: CacheService,
  ) {
  }

  getOne(id: string): Observable<Invoice> {
    const obs: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getDocument',
      methodProperties: {
        Ref: id,
      },
    });

    return obs.pipe(
      map((data) => this._bindOneToModel(data)),
      concatMap((invoice: any) => {
        return observableZip(
          (() => {
            if (!invoice.recipient || !invoice.recipient.contragent || !invoice.recipient.contragent.id) {
              return of(invoice);
            } else {
              return this.contactApiService.getOneContragent(invoice.recipient.contragent.id).pipe(
                map((contragent) => {
                  invoice.recipient.contragent = contragent;

                  return invoice;
                }),
                switchMap((invoice) => of(invoice)),
              );
            }
          })(),
          (() => {
            if (invoice.senderAddress && invoice.senderAddress.id) {
              return this.addressApiService.getOne(invoice.senderAddress.id).pipe(
                map((address) => {
                  invoice.senderAddress = address;

                  return invoice;
                }),
                switchMap((invoice) => of(invoice)),
              );
            } else {
              return of(invoice);
            }
          })(),
          (() => {
            if (invoice.recipientAddress && invoice.recipientAddress.id && invoice.recipient && invoice.recipient.id) {
              return this.addressApiService.getOne(invoice.recipientAddress.id, invoice.recipient.id).pipe(
                map((address) => {
                  invoice.recipientAddress = address;

                  return invoice;
                }),
              );
            } else {
              return of(invoice);
            }
          })(),
        ).pipe(map(() => invoice));
      }),
    );
  }

  getList(
    filter: IFilterParam = {},
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
    counterpartyRefs?: string[],
    backendFiltersConfiguration?: { [key: string]: any },
    addTimeLimitsParams = true,
    enableSearchByCounterparties = true,
  ): Observable<IListData<Invoice>> {
    let invoicesListPromise: any;
    switch (filter.type) {
      case InvoiceType.MY:
        invoicesListPromise = this._getMyInvoices(
          filter,
          order,
          page,
          limit,
          counterpartyRefs,
          backendFiltersConfiguration,
          addTimeLimitsParams,
          enableSearchByCounterparties,
        );
        break;
      case InvoiceType.OUT:
        invoicesListPromise = this._getOutputInvoices(filter, order, page, limit, counterpartyRefs);
        break;
      case InvoiceType.IN:
        invoicesListPromise = this._getInputInvoices(filter, order, page, limit, counterpartyRefs);
        break;
      case InvoiceType.REGISTER:
        invoicesListPromise = this._getRegisterInvoices(filter, order, page, limit);
        break;
      case InvoiceType.ARCHIVED:
        invoicesListPromise = this._getArchivedInvoices(filter, order, page, limit, counterpartyRefs);
        break;
      case InvoiceType.TRASHCAN:
        invoicesListPromise = this._getTrashcanInvoices(filter, order, page, limit);
        break;
      default:
        throw { message: 'Type is not found' };
    }

    let obs: Observable<any> = invoicesListPromise;

    switch (filter.type) {
      case InvoiceType.MY:
        obs = obs.pipe(map((data: any) => this.bindListToModel(data)));
        break;

      case InvoiceType.OUT:
        obs = obs.pipe(map((data: getOutgoingDocumentsByPhoneResponse) => _bindListToModelType2(data)));
        break;

      case InvoiceType.IN:
        obs = obs.pipe(map((data: any) => _bindListToModelType2(data)));
        break;

      case InvoiceType.ARCHIVED:
        obs = obs.pipe(map((data: any) => _bindListToModelType2(data)));
        break;

      case InvoiceType.REGISTER:
        obs = obs.pipe(map((data: any) => this.bindListToModel(data)));
        break;

      case InvoiceType.TRASHCAN:
        obs = obs.pipe(map((data: any) => this.bindListToModel(data)));
        break;
    }

    return obs;
  }

  getTires(): Observable<TireWheel[]> {
    const tires: TireWheel[] = this.cacheService.get('invoice__get_tires');

    if (tires) {
      return of(tires);
    }

    const promise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      modelName: 'Common',
      calledMethod: 'getTiresWheelsList',
      methodProperties: {},
    });

    return promise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map(this.bindTires.bind(this)),
      map((t: TireWheel[]) => {
        if (t && t[0]) {
          this.cacheService.set('invoice__get_tires', t);
        }

        return t;
      }),
    );
  }

  remove(id: string): Observable<boolean> {
    const deletePromise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'delete',
      methodProperties: {
        DocumentRefs: id,
      },
    });

    return deletePromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data: any) => data.data.success),
    );
  }

  add(item: Invoice): Observable<Invoice> {
    const registerPromise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'save',
      methodProperties: this._invoiceToMethodParams(item),
    });

    //noinspection TypeScriptUnresolvedFunction
    return registerPromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this._bindOneToModel(data)),
      concatMap((invoice: Invoice) => {
        if (!invoice.recipient || !invoice.recipient.contragent || !invoice.recipient.contragent.id) {
          return of(invoice);
        } else {
          return this.contactApiService.getOneContragent(invoice.recipient.contragent.id).pipe(
            map((contragent) => {
              invoice.recipient.contragent = contragent;

              return invoice;
            }),
          );
        }
      }),
    );
  }

  update(item: Invoice): Observable<Invoice> {
    const promise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'update',
      methodProperties: this._invoiceToMethodParams(item),
    });

    //noinspection TypeScriptUnresolvedFunction
    return promise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this._bindOneToModel(data)),
      concatMap((invoice: Invoice) => {
        if (!invoice.recipient || !invoice.recipient.contragent || !invoice.recipient.contragent.id) {
          return of(invoice);
        } else {
          return this.contactApiService.getOneContragent(invoice.recipient.contragent.id).pipe(
            map((contragent) => {
              invoice.recipient.contragent = contragent;

              return invoice;
            }),
          );
        }
      }),
    );
  }

  bindRegisterListToModel(data: any): Invoice[] {
    const invoices = this.bindListToModel(data).list;
    return data.data.map((invoice, index) => ({
      ...invoice,
      ...invoices[index],
    }));
  }

  bindListToModel(data: any): IListData<Invoice> {
    return {
      list: data.data.map((el: any) => {
        try {
          const invoice = new InvoiceMy(el.Ref);
          this.copyProps(el, invoice, RefProperties);
          invoice.SenderDescription = el.SenderDescription;
          invoice.SenderContactPerson = el.SenderContactPerson;
          invoice.CitySenderDescription = el.CitySenderDescription;
          invoice.SenderAddressDescription = el.SenderAddressDescription;
          invoice.SenderAddressShortDescription = this.createShortDescription(el.SenderAddressDescription);
          invoice.RecipientDescription = el.RecipientDescription;
          invoice.RecipientContactPerson = el.RecipientContactPerson;
          invoice.CounterpartyRecipientDescription = el.CounterpartyRecipientDescription;
          invoice.ScanSheetNumber = el.ScanSheetNumber;
          invoice.ScanSheetRef = el.ScanSheetRef;
          invoice.estimatedDeliveryDate = el.EstimatedDeliveryDate;
          invoice.securePayment = el.SecurePayment;
          invoice.SendersPhone = el.SendersPhone;
          invoice.BackwardDeliveryDataDocuments = el.BackwardDeliveryDataDocuments;

          invoice.docNumber = el.IntDocNumber || el.Number;
          invoice.CarCallNumber = el.CarCallNumber;
          invoice.announcedPrice = parseFloat(el.Cost || el.AnnouncedPrice) || 0;
          invoice.description = el.Description;
          invoice.payer = el.PayerType;
          invoice.MarketplacePartnerToken = el.MarketplacePartnerToken;
          invoice.backDelivery = new InvoiceCargoType(null);
          invoice.BackwardDeliveryData = el.BackwardDeliveryData;
          if (el.BackwardDeliveryData) {
            invoice.backDeliveries = parseBackDeliveriesFromInvoice(el);
            invoice.backDeliveriesTooltipDescription = getBackDeliveriesDescriptionForTooltip(invoice);
          }
          if (el.BackwardDeliveryCargoType) {
            invoice.backDelivery = new InvoiceCargoType();
            invoice.backDelivery.description = el.BackwardDeliveryCargoType;
            if (el.BackwardDeliveryMoney) {
              invoice.backMoney = el.BackwardDeliveryMoney;
            }
          }

          let userNameParts;
          el.RecipientContactPerson =
            el.RecipientContactPerson || el.RecipientContactPersonFullName || el.RecipientFullName;

          if (el.RecipientContactPerson) {
            userNameParts = this.splitName(el.RecipientContactPerson);
            invoice.recipient = new InvoiceContact(userNameParts[1], userNameParts[0], el.ContactRecipientRef);
          } else {
            invoice.recipient = new InvoiceContact('-', '-', el.ContactRecipientRef);
          }
          invoice.recipientContactPhone = el.RecipientContactPhone || '';
          invoice.recipient.city = new Locality(el.CityRecipient);
          invoice.recipient.city.name = el.CityRecipientDescription;

          invoice.recipientCounterpartyType =
            el.RecipientCounterpartyType || getCounterPartyType(el.CounterpartyRecipientDescription) || '';

          invoice.recipient.description = el.RecipientDescription;
          el.RecipientAddressDescription.replace(/\(.*\)/gi, '');

          invoice.recipientAddress = new Address();

          if (el.RecipientAddressDescription) {
            invoice.recipientAddress.description = el.RecipientAddressDescription;
          }

          if (invoice.recipientAddress.description && invoice.recipient.city.name) {
            invoice.recipientAddress.description = `${invoice.recipient.city.name}, ${invoice.recipientAddress.description}`;
            invoice.recipientAddress.description = el.RecipientAddressDescription;
            invoice.recipientAddress.shortDescription = this.createShortDescription(el.RecipientAddressDescription);
          }

          userNameParts = this.splitName(el.SenderContactPerson);
          invoice.sender = new InvoiceContact(userNameParts[1], userNameParts[0], el.ContactSenderRef);
          invoice.sender.city = new Locality(el.CitySender);
          invoice.sender.city.name = el.CitySenderDescription;
          invoice.sender.description = el.SenderDescription;
          invoice.senderAddress = new Address();

          if (el.SenderAddressDescription) {
            invoice.senderAddress.shortDescription = this.createShortDescription(el.SenderAddressDescription);
            invoice.senderAddress.description = el.SenderAddressDescription;
          }

          if (
            invoice.senderAddress.description &&
            invoice.senderAddress.description.toLowerCase().indexOf(invoice.sender.city.name.toLowerCase()) === -1
          ) {
            invoice.senderAddress.description = `${invoice.sender.city.name}, ${invoice.senderAddress.description}`;
          }

          invoice.isPrinted = el.Printed === '1' || el.Printed === true;

          invoice.state = this._getState(el);
          invoice.serviceType = new InvoiceServiceType(el.ServiceType);
          invoice.cargoType = new InvoiceCargoType(el.CargoType);
          invoice.createTime = moment(el.CreateTime).toDate();
          invoice.dateTime = moment(el.DateTime).toDate();
          invoice.deliveryPrice = parseFloat(el.CostOnSite || el.DocumentCost) || 0;
          invoice.StoragePrice = el.StoragePrice ? el.StoragePrice : 0;
          invoice.SumBeforeCheckWeight =
            parseFloat(el.SumBeforeCheckWeight) > 0 ? parseFloat(el.SumBeforeCheckWeight) : invoice.deliveryPrice;
          invoice.paymentControl = this.coerceToNumber(el.AfterpaymentOnGoodsCost);
          invoice.forwardingCount =
            !!el.ForwardingCount && parseFloat(el.ForwardingCount) > 0 ? parseFloat(el.ForwardingCount) : 0;
          invoice.ForwardingCount =
            !!el.ForwardingCount && parseFloat(el.ForwardingCount) > 0 ? parseFloat(el.ForwardingCount) : 0;
          invoice.paymentMethod = new InvoicePaymentMethod(el.PaymentMethod);
          invoice.weight = el.Weight;
          invoice.seatsAmount = el.SeatsAmount;
          invoice.infoRegClientBarcodes = el.InfoRegClientBarcodes;

          if (el.bCardPaymentCancelled !== undefined) {
            invoice.isPayCancel = el.bCardPaymentCancelled;
          }

          if (el.bCardPaymentPayed !== undefined) {
            invoice.isPayPaid = el.bCardPaymentPayed;
          }

          invoice.marketPlaceToken = el.MarketplacePartnerToken;
          invoice.isInternational = this.isInternational(el);
          invoice.registerDocNumber = el.ScanSheetNumber ? el.ScanSheetNumber : '';
          invoice.isRegisterPrinted = el.ScanSheetPrinted === '1';
          invoice.MarketplacePartnerDescription = el.MarketplacePartnerDescription;
          invoice.RedeliveryPaymentCard = el.RedeliveryPaymentCard
            ? el.RedeliveryPaymentCard
            : {
              CardDescription: '',
              CardMaskedNumber: '',
            };
          invoice.estimatedDeliveryDate = moment(el.EstimatedDeliveryDate).toDate();
          invoice.PackingNumber = el.PackingNumber ?? '';
          invoice.AccompanyingDocuments = el.AccompanyingDocuments ?? '';
          invoice.AdditionalInformation = el.AdditionalInformation ?? '';
          invoice.RegionCode = el.RegionCode ?? '';
          invoice.RedeliveryPaymentCard.CardMaskedNumber = el.CardMaskedNumber;
          invoice.LightReturn = el.LightReturn;
          invoice.possibilityCreateLightReturn = el.possibilityCreateLightReturn;
          invoice.LightReturnNumber = el.LightReturnNumber;
          invoice.DeliveryPharma = el.DeliveryPharma;
          invoice.CanCourierCall = el.CanCourierCall;
          invoice.PickupNumber = el.PickupNumber;
          invoice.IntDocNumber = el.IntDocNumber;
          invoice.DeliveryFrom = el.DeliveryFrom;

          return invoice;
        } catch (e) {
        }
      }),
      total: data.info?.totalCount ?? data?.data?.length,
    };
  }

  track(invoiceNumber: string, system: boolean = true, phone?: string): Observable<Invoice> {
    const promise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: system ? this.configService.get('system') : '',
      modelName: 'TrackingDocument',
      calledMethod: 'getStatusDocuments',
      methodProperties: {
        Documents: [
          {
            DocumentNumber: invoiceNumber,
            Phone: phone ?? this.authService.user.phone,
          },
        ],
        Language: 'UA',
      },
    });

    return promise.pipe(map((data) => this._bindOneToModel({ data })));
  }

  trackMany(invoiceNumbers: string[], system: boolean = true): Observable<any> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: system ? this.configService.get('system') : '',
        modelName: 'TrackingDocument',
        calledMethod: 'getStatusDocuments',
        methodProperties: {
          Documents: invoiceNumbers.map((el) => ({
            DocumentNumber: el,
          })),
          Language: 'UA',
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors(data)),
        take(1),
      );
  }

  userIsSemiCorporateOrCorporate(): boolean {
    return (
      this.authService.user.loyaltyCardType === 'Identification' ||
      this.authService.user.loyaltyCardType === 'cCard' ||
      this.authService.user.type === 'org'
    );
  }

  trackManyForSearch(invoicePhoneAndNumbers: any[]): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'TrackingDocument',
      calledMethod: 'getStatusDocuments',
      methodProperties: {
        Documents: invoicePhoneAndNumbers.map((el) => ({
          DocumentNumber: el.docNumber,
          Phone: this.userIsSemiCorporateOrCorporate() ? el.phone : this.authService.user.phone,
        })),
        Language: 'UA',
      },
    };
    const promise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, body);
    return from(promise);
  }

  trackInvoicesForSearch(invoicePhoneAndNumbers: any[], chunkSize = 100): Observable<any> {
    return forkJoin(
      this.splitArray(invoicePhoneAndNumbers, chunkSize).map((chunk) => {
        return this.trackManyForSearch(chunk).pipe(map((result) => result?.data));
      }),
    ).pipe(
      map((chunkResults) => {
        return [].concat(...chunkResults);
      }),
      map((d: any) =>
        d.map((item) => {
          const response = {
            data: {
              success: true,
              data: [item],
            },
          };

          const data = this._bindOneToModel(response);
          if (data.recipient && !data.sender && this.authService.user) {
            data.sender = this.authService.user;
          }

          if (data.sender && !data.recipient && this.authService.user) {
            data.recipient = this.authService.user;
          }
          return data;
        }),
      ),
    );
  }

  trackManyWithBinding(invoiceNumbers: string[], chunkSize = 100, system: boolean = true): Observable<any> {
    return forkJoin(
      this.splitArray(invoiceNumbers, chunkSize).map((chunk) =>
        this.trackMany(chunk, system).pipe(map((result) => result.data)),
      ),
    ).pipe(
      map((chunkResults) => [].concat(...chunkResults)),
      map((d) =>
        d.map((item) => {
          const response = {
            data: {
              success: true,
              data: [item],
            },
          };
          const data = this._bindOneToModel(response);
          if (data.recipient && !data.sender && this.authService.user) {
            data.sender = this.authService.user;
          }

          if (data.sender && !data.recipient && this.authService.user) {
            data.recipient = this.authService.user;
          }
          return data;
        }),
      ),
    );
  }

  _getState(invoice: any): InvoiceState {
    const state = new InvoiceState();
    Object.assign(
      state,
      states[invoice.StateId || invoice.StatusCode] || {
        id: '0',
        name: invoice.StateName || invoice.Status,
        shortName: invoice.StateName || invoice.Status,
        isPayAllowed: false,
      },
    );
    state.name = invoice.StateName || invoice.Status;

    return state;
  }

  getRefList(refs: string[]): Observable<Array<InvoiceResponseModel>> {
    const obs: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getEWTemplateList',
      methodProperties: {
        DocumentRefs: refs,
        Page: '1',
        Limit: refs.length,
      },
    });
    return obs.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response: any) => this.bindRegisterListToModel(response)),
    );
  }

  getRefListInvoicesMyBinding(refs: string[]) {
    return this.getRefList(refs).pipe(map((data) => this.bindListToModel(data)));
  }

  public getCargoTypes() {
    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getCargoTypes',
    });
  }

  calculatePrice(invoice: Invoice): Observable<InvoiceCalculations> {
    const pomise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getDocumentPrice',
      methodProperties: this._invoiceToMethodParams(invoice),
    });

    return pomise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response: any) => {
        const invoiceCalculations: InvoiceCalculations = new InvoiceCalculations();
        invoiceCalculations.deliveryCost = parseFloat(response.data.data[0].Cost) || 0;
        invoiceCalculations.reDeliveryCost = parseFloat(response.data.data[0].CostRedelivery) || 0;

        return invoiceCalculations;
      }),
    );
  }

  printPdf(
    invoicesIds: string[],
    options: {
      modelName: string;
      calledMethod: string;
      type: string;
      props?: any;
    },
  ): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: options.modelName,
      calledMethod: options.calledMethod,
      methodProperties: {
        DocumentRefs: invoicesIds,
        Type: options.type,
        ...options.props,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response: any) => response.data),
      );
  }

  printDocuments(invoicesIds: string[], type: string = 'pdf', props?): Observable<any> {
    const options = {
      modelName: 'InternetDocument',
      calledMethod: 'printFull',
      type,
      props: {
        printForm: 'Document_new',
      },
    };

    if (props) {
      options.props = { ...options.props, ...props };
    }

    return this.printPdf(invoicesIds, options);
  }

  printCopies(
    invoicesIds: string[],
    copies: number = 1,
  ): Observable<any> {
    const body = {
      'system': this.configService.get('system'),
      'modelName': 'InternetDocument',
      'calledMethod': 'printEW',
      'methodProperties': {
        'DocumentNumbers': invoicesIds,
        'Copies': copies,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response: any) => response.data),
      );
  }

  printMarking(invoicesIds: string[], type: string = 'pdf'): Observable<any> {
    return this.printPdf(invoicesIds, {
      modelName: 'InternetDocument',
      calledMethod: 'printFull',
      type,
      props: {
        printForm: 'Marking',
        Position: '',
      },
    });
  }

  printDocsNew(invoicesNumbers: string[], type: string = 'pdf', form: string = 'Document_new'): Observable<any> {
    const printDocumentsPromise: any = this.http.post(
      `${this.configService.get('apiUrl')}json/`,
      {
        system: this.configService.get('system'),
        modelName: 'InternetDocument',
        calledMethod: 'printFull',
        methodProperties: {
          printForm: form,
          DocumentNumbers: invoicesNumbers,
          Type: type,
        },
      },
      { responseType: 'arraybuffer' },
    );

    return printDocumentsPromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response: any) => response.data),
    );
  }

  printDocumentsNew(invoicesIds: string[]): Observable<any> {
    return this.printPdf(invoicesIds, {
      modelName: 'InternetDocument',
      calledMethod: 'printDocument',
      type: 'pdf_new',
    });
  }

  printMarkings(invoicesIds: string[]): Observable<any> {
    return this.printPdf(invoicesIds, {
      modelName: 'InternetDocument',
      calledMethod: 'printMarkings',
      type: 'pdf',
    });
  }

  printMarkingsNew(invoicesIds: string[]): Observable<any> {
    return this.printPdf(invoicesIds, {
      modelName: 'InternetDocument',
      calledMethod: 'printMarking100x130',
      type: 'pdf',
    });
  }

  printMarkingZebraHundred(invoicesIds: string[], type: string = 'pdf'): Observable<any> {
    return this.printPdf(invoicesIds, {
      modelName: 'InternetDocument',
      calledMethod: 'printFull',
      type: 'pdf',
      props: {
        printForm: 'Marking_100x100',
        Position: '',
      },
    });
  }

  cancelDeletion(ids: string[]): Observable<boolean> {
    const printDocumentsPromise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      modelName: 'InternetDocument',
      calledMethod: 'cancelDeletion',
      system: this.configService.get('system'),
      methodProperties: {
        DocumentRefs: ids,
      },
    });

    return printDocumentsPromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response: any) => response.data.success),
    );
  }

  checkServicesAvailability(): Observable<any> {
    const checkServicesAvailabilityPromise: any = this.http.post(`${this.configService.get('apiUrl')}json/`, {
      modelName: 'Counterparty',
      calledMethod: 'getCounterpartyOptions',
      system: this.configService.get('system'),
      methodProperties: {
        Ref: this.authService.user.contragent
          ? this.authService.user.contragent.id
          : this.authService.user.contacts.map((contact) => contact.CounterpartyRef),
      },
    });

    return checkServicesAvailabilityPromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response: any) => response.data.data[0]),
    );
  }

  getMoneyTransfers(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 100,
    counterpartyRefs?: string[],
  ): Observable<IListData<Invoice>> {
    return from(this.loadMoneyTransfers(filter, order, page, limit, counterpartyRefs)).pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this.bindMoneyTransfers(data)),
    );
  }

  getState(statusCode) {
    return states[statusCode];
  }

  checkPossibilityLightReturn(invoice: string): Observable<GetLightReturnReasonsResponse> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'checkPossibilityLightReturn',
        methodProperties: {
          IntDocNumber: invoice,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data),
      );
  }

  createLightReturn(reasonRef: string, invoice: string): Observable<LightReturnResponse> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'save',
        methodProperties: {
          OrderType: 'orderLightReturn',
          SubtypeReason: reasonRef,
          IntDocNumber: invoice,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data),
      );
  }

  protected splitArray(arr, chunkSize) {
    const result = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  protected _getMyInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
    counterpartyRefs?: string[],
    backendFiltersConfiguration?: { [key: string]: any },
    addTimeLimitsParams = true,
    enableSearchByCounterparties = true,
  ): any {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'InternetDocument',
        calledMethod: 'getEWTemplateList',
        methodProperties: {
          Page: page,
          Limit: limit,
          ...(addTimeLimitsParams ? {
            DateTimeFrom: moment().subtract(1, 'month').format('DD.MM.YYYY'),
            DateTimeTo: moment().add(1, 'month').format('DD.MM.YYYY'),
          } : {}),
          ...(enableSearchByCounterparties && this.authService.user.contacts ? { SearchByCounterparties: 1 } : {}),
          ...(backendFiltersConfiguration ? backendFiltersConfiguration : {}),
          ...(counterpartyRefs ? { iCounterparties: counterpartyRefs } : {}),
          ...(filter.IsPickupTable ? { IsPickupTable: filter.IsPickupTable } : {}),
          ...(filter.PickupNumber ? { PickupNumber: filter.PickupNumber } : {}),
          ...(filter.needPayInfo ? { SelectPaymentOnSite: '1' } : {}),
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data as InvoiceMy[]),
      );
  }

  protected _getTrashcanInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
  ): any {
    const date = new Date();

    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getEWTemplateList',
      methodProperties: {
        DateTimeFrom: moment().subtract(30, 'days').format('DD.MM.YYYY'),
        DateTimeTo: moment().format('DD.MM.YYYY'),
        Page: page,
        Limit: limit,
        GetOnlyDeleted: 1,
        SearchByCounterparties: this.authService.user.contacts ? 1 : null,
      },
    });
  }

  protected _getRegisterInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
  ): any {
    const date = new Date();

    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'ScanSheet',
      calledMethod: 'getScanSheetDocuments',
      methodProperties: {
        Ref: filter.registerId,
        Page: page.toString(),
        Limit: limit.toString(),
      },
    });
  }

  protected _getOutputInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
    counterpartyRefs?: string[],
  ): Observable<getOutgoingDocumentsByPhoneResponse> {
    return this.http.post<getOutgoingDocumentsByPhoneResponse>(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: ApiModelName.InternetDocument,
      calledMethod: ApiMethods.getOutgoingDocumentsByPhone,
      methodProperties: {
        DateFrom: moment().subtract(3, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        DateTo: moment().add(1, 'days').add(1, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        Page: page,
        Limit: limit,
        SearchByCounterparties: this.authService.user.contacts ? 1 : null,
        iCounterparties: counterpartyRefs ? counterpartyRefs : null,
      },
    });
  }

  protected _getArchivedInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
    counterpartyRefs?: string[],
  ): any {
    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getArchiveDocumentsByPhone',
      methodProperties: {
        DateFrom: moment().subtract(3, 'month').format('DD.MM.YYYY') + ' 00:00:00',
        DateTo: moment().add(1, 'days').format('DD.MM.YYYY') + ' 00:00:00',
        Page: page,
        Limit: limit,
        SearchByCounterparties: this.authService.user.contacts ? 1 : null,
        iCounterparties: counterpartyRefs ? counterpartyRefs : null,
      },
    });
  }

  protected _getInputInvoices(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 10,
    counterpartyRefs?: string[],
  ): any {
    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getIncomingDocumentsByPhone',
      methodProperties: {
        DateFrom: moment().subtract(3, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        DateTo: moment().add(1, 'days').add(1, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        Page: page,
        Limit: limit,
        SearchByCounterparties: this.authService.user.contacts ? 1 : null,
        iCounterparties: counterpartyRefs ? counterpartyRefs : null,
      },
    });
  }

  protected _invoiceToMethodParams(invoice: Invoice): any {
    const methodParams: any = {};
    methodParams.Ref = invoice.id;
    methodParams.DateTime = this.noRestApiHelper.getFormatedDateForRequest(new Date());
    methodParams.AdditionalInformation = invoice.additionalInformation;
    methodParams.InfoRegClientBarcodes = invoice.infoRegClientBarcodes;

    methodParams.Cost = invoice.announcedPrice;

    if (invoice.backDelivery && invoice.backDelivery.id) {
      methodParams.BackwardDeliveryData = [
        {
          PayerType: invoice.backDeliveryPayer,
          CargoType: invoice.backDelivery.id,
        },
      ];

      if (invoice.backDelivery.id === 'Money') {
        methodParams.BackwardDeliveryData[0].RedeliveryString = invoice.backMoney;
      } else if (invoice.backDelivery.id) {
        methodParams.BackwardDeliveryData[0].RedeliveryString = invoice.backDeliveryDescription;
      }
    }

    methodParams.Description = invoice.description;
    methodParams.IntDocNumber = invoice.docNumber;
    methodParams.PayerType = invoice.payer;
    methodParams.ServiceType = invoice.serviceType.id;

    if (invoice.sender) {
      methodParams.ContactSender = invoice.sender.id;
      methodParams.Sender = invoice.sender.contragent.id;
      methodParams.SendersPhone = invoice.sender.phone;
    }

    if (invoice.senderAddress) {
      if (invoice.senderAddress.city) {
        methodParams.CitySender = invoice.senderAddress.city.id;
      }
      methodParams.SenderAddress = invoice.senderAddress.id;
    }

    methodParams.NewAddress = '1';
    if (invoice.recipient) {
      methodParams.Recipient = invoice.recipient.contragent.id;
      methodParams.ContactRecipient = invoice.recipient.id;
      methodParams.RecipientsPhone = invoice.recipient.phone;
    }

    if (invoice.recipientAddress) {
      if (invoice.recipientAddress.city) {
        methodParams.CityRecipient = invoice.recipientAddress.city.id;
      }
      methodParams.RecipientContactPersonAddress = invoice.recipientAddress.id;
    }

    methodParams.PayerType = invoice.payer;
    methodParams.SeatsAmount = invoice.sizes && invoice.sizes.length;
    methodParams.CargoType = invoice.cargoType.id;

    if (invoice.desiredTimeInterval && invoice.desiredTimeInterval.id) {
      methodParams.TimeInterval = invoice.desiredTimeInterval.id;
    }

    methodParams.CargoDescription = invoice.description;
    if (invoice.paymentMethod) {
      methodParams.PaymentMethod = invoice.paymentMethod.id;
    }

    methodParams.NumberOfFloorsLifting = invoice.recipientNumberOfFloorsLifting;
    methodParams.Elevator = invoice.recipientElevator ? 1 : 0;

    if (methodParams.ServiceType === 'WarehouseWarehouse' || methodParams.ServiceType === 'DoorsWarehouse') {
      delete methodParams.SameDayDelivery;
    }

    methodParams.OptionsSeat = [];

    if (invoice.deliveryToHand) {
      methodParams.DeliveryByHand = '1';
    }

    _.each(invoice.sizes, (size, key) => {
      methodParams.OptionsSeat.push({
        volumetricWidth: (size.width || 0).toString(),
        volumetricLength: (size.length || 0).toString(),
        volumetricHeight: (size.height || 0).toString(),
        weight: (invoice.weights[key] || 0).toString(),
        volumetricVolume: '0,0001',
      });
    });

    methodParams.MarketplacePartnerToken = '005056887b8d-b5da-11e6-9f54-cea38574';

    if (invoice.paymentCard) {
      methodParams.BackwardDeliveryData[0].PaymentCard = invoice.paymentCard.id;
    }

    if (invoice.desiredDate) {
      methodParams.PreferredDeliveryDate = moment(invoice.desiredDate).format('DD.MM.YYYY');
    }

    if (invoice.forwardingCount) {
      methodParams.ForwardingCount = '' + invoice.forwardingCount;
    }

    if (invoice.desiredTimeInterval) {
      methodParams.TimeInterval = invoice.desiredTimeInterval.id;
    }

    if (invoice.tiresWheels) {
      methodParams.CargoDetails = [];

      for (const key in invoice.tiresWheels) {
        methodParams.CargoDetails.push({
          CargoDescription: key,
          Amount: invoice.tiresWheels[key].toString(),
        });
      }

      methodParams.SeatsAmount = methodParams.CargoDetails.length;
    }

    if (invoice.cargoType.id === 'Documents') {
      methodParams.Weight = invoice.weight;
      methodParams.SeatsAmount = '1';
    }

    if (invoice.paymentControl) {
      methodParams.AfterpaymentOnGoodsCost = invoice.paymentControl;
    }

    return methodParams;
  }

  protected createShortDescription(description: string): string {
    if (!description) {
      return '';
    }

    let shortDescription = description.replace(/\(.*\)/gi, '');
    if (/.*(Відділення №\d+)/.test(shortDescription)) {
      shortDescription = /.*(Відділення №\d+)/.exec(shortDescription)[0];
    }
    return shortDescription;
  }

  protected _bindOneToModel(response: any): Invoice {
    let invoice: Invoice;

    if (response.data.success) {
      try {
        const item: any = response.data.data[0];
        invoice = new Invoice(item.Ref);
        invoice.id = item.Ref;
        invoice.CheckWeightMethod = item.CheckWeightMethod;
        invoice.additionalInformation = item.AdditionalInformation;
        invoice.note = item.Note;
        invoice.infoRegClientBarcodes = item.InfoRegClientBarcodes;
        invoice.clientBarcode = item.ClientBarcode;
        invoice.DaysStorageCargo = item.DaysStorageCargo || '';
        invoice.RecipientFullName = item.RecipientFullName;
        if (item.Cost || item.AnnouncedPrice !== '') {
          invoice.announcedPrice = parseFloat(item.Cost || item.AnnouncedPrice) || 0;
        }
        if (item.BackwardDeliveryData && item.BackwardDeliveryData[0]) {
          invoice.backDelivery = new InvoiceCargoType(item.BackwardDeliveryData[0].CargoTypeRef);
          if (item.BackwardDeliveryData[0].InvoiceCargoType || item.BackwardDeliveryData[0].CargoTypeRef) {
            // invoice.backDelivery.description = this.$translations.instant(
            //   `INVOICE_SERVICES.${(item.BackwardDeliveryData[0]
            //     .InvoiceCargoType || item.BackwardDeliveryData[0].CargoTypeRef)
            //     .toUpperCase()}`,
            // );
            invoice.backDelivery.description =
              CargoType[
                (
                  item.BackwardDeliveryData[0].InvoiceCargoType || item.BackwardDeliveryData[0].CargoTypeRef
                ).toUpperCase()
                ];
          }
          invoice.backDeliveryPayer = item.BackwardDeliveryData[0].PayerTypeRef;

          if (invoice.backDelivery.id === 'Money') {
            invoice.backMoney = +item.BackwardDeliveryData[0].RedeliveryString;
          } else if (invoice.backDelivery.id) {
            invoice.backDeliveryDescription = item.BackwardDeliveryData[0].RedeliveryString;
          }
        }

        if (item.RedeliveryPayer) {
          invoice.backDeliveryPayer = item.RedeliveryPayer;
        }

        if (item.OrderingAdditionalServices) {
          invoice.OrderingAdditionalServices = item.OrderingAdditionalServices;
          getRedirectingReturnIndication(invoice);
        }

        if (item.RedeliverySum || item.RedeliverySum === 0) {
          invoice.backMoney = +item.RedeliverySum;
        }

        if (item.AmountPaid || item.AmountPaid === 0) {
          invoice.payedSum = +item.AmountPaid;
        }

        invoice.cargoType = new InvoiceCargoType(item.CargoTypeRef || item.CargoType);
        if (item.CargoTypeRef || item.CargoType) {
          invoice.cargoType.description = CargoType[(item.CargoTypeRef || item.CargoType).toUpperCase()];
        }
        invoice.description = item.Description || item.CargoDescriptionString;

        if (item.DateTime) {
          invoice.createTime = moment(item.CreateTime).toDate();
          invoice.dateTime = moment(item.DateTime).toDate();
        } else if (item.DateCreated) {
          const stringArr: string[] = item.DateCreated.split('-');
          stringArr[2] = stringArr[2].split(' ')[0];
          invoice.createTime = new Date();
          invoice.createTime.setFullYear(+stringArr[2]);
          invoice.createTime.setMonth(+stringArr[1] - 1);
          invoice.createTime.setDate(+stringArr[0]);
        }

        if (item.RecipientDateTime) {
          invoice.receivingTime = new Date();
          const stringArr: string[] = item.RecipientDateTime.split('.');
          stringArr[2] = stringArr[2].split(' ')[0];
          const hoursArr: any[] = item.RecipientDateTime.split(':');
          hoursArr[0] = hoursArr[0].split(' ')[1];

          invoice.receivingTime = new Date();
          invoice.receivingTime.setFullYear(+stringArr[2]);
          invoice.receivingTime.setMonth(+stringArr[1] - 1);
          invoice.receivingTime.setDate(+stringArr[0]);
          invoice.receivingTime.setHours(+hoursArr[0]);
          invoice.receivingTime.setMinutes(+hoursArr[1]);
          invoice.receivingTime.setSeconds(+hoursArr[2]);
        } else if (item.ScheduledDeliveryDate) {
          invoice.receivingTime = moment(item.ScheduledDeliveryDate, 'DD-MM-YYYY HH:mm:ss').toDate();
        }

        if (item.ActualDeliveryDate) {
          invoice.actualDeliveryDate = moment(item.ActualDeliveryDate, 'YYYY-MM-DD HH:mm:ss').toDate();
        }

        if (item.ScheduledDeliveryDate) {
          invoice.scheduleDeliveryDate = moment(item.ScheduledDeliveryDate, 'DD-MM-YYYY HH:mm:ss').toDate();
        }

        if (item.DateScan) {
          invoice.dateScan = moment(item.DateScan, 'HH:mm DD.MM.YYYY').toDate();
        }
        invoice.docNumber = item.Number || item.IntDocNumber;
        invoice.OwnerDocumentNumber = item.OwnerDocumentNumber;
        invoice.package = !!item.Pack;
        invoice.counterpartySenderType = item.CounterpartySenderType;
        invoice.counterpartySenderDescription = item.CounterpartySenderDescription;

        invoice.seatsAmount = item.SeatsAmount;
        invoice.volumeWeight = item.VolumeWeight;

        invoice.dateFirstDayStorage = item.DateFirstDayStorage || '';
        invoice.dateReturnCargo = item.DateReturnCargo || '';
        invoice.estimatedDeliveryDate = moment(item.EstimatedDeliveryDate).toDate();

        invoice.payer = item.PayerTypeRef || item.PayerType;

        let stringArray: string[];

        if (item.ContactRecipient) {
          stringArray = this.splitName(item.ContactRecipient);

          invoice.recipient = new InvoiceContact(stringArray[1], stringArray[0], item.ContactRecipientRef);
          invoice.recipientContactPhone = item.RecipientContactPhone || '';
          invoice.recipient.middleName = stringArray[2] || null;
          invoice.recipient.city = new Locality(item.CityRecipientRef || item.RefCityRecipient);
          invoice.recipient.city.name = item.CityRecipient;
          invoice.recipient.phone = item.RecipientsPhone || item.PhoneRecipient;
          invoice.recipient.contragent = new Contragent(item.RecipientRef);
          invoice.recipientCounterpartyType =
            item.RecipientCounterpartyType || getCounterPartyType(item.CounterpartyRecipientDescription) || '';
          if (item.Recipient !== 'Приватна особа') {
            invoice.recipient.contragent.name = item.Recipient;
            invoice.recipient.contragent.ownerType = 'Org';
          } else {
            invoice.recipient.contragent.ownerType = 'Priv';
          }
          invoice.recipientAddress = new Address(item.RecipientAddressRef);

          invoice.recipientAddress.city = invoice.recipient.city;
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;

          if (item.ServiceTypeRef === 'DoorsDoors' || item.ServiceTypeRef === 'WarehouseDoors') {
            invoice.recipientAddress.street = new Street('fake');
            invoice.recipientAddress.street.locality = invoice.recipientAddress.city;
          }

          invoice.recipientAddress.shortDescription = this.createShortDescription(item.RecipientAddress);

          if (
            (item.ServiceTypeRef === 'DoorsWarehouse' || item.ServiceTypeRef === 'WarehouseWarehouse') &&
            invoice.recipientAddress.city.name
          ) {
            invoice.recipientAddress.description += ', ' + invoice.recipientAddress.city.name;
          }
        } else if (item.RecipientFullName) {
          stringArray = this.splitName(item.RecipientFullName);
          invoice.recipient = new InvoiceContact(stringArray[1], stringArray[0], item.ContactRecipientRef);
          invoice.recipientContactPhone = item.RecipientContactPhone || '';
          invoice.recipient.middleName = stringArray[2] || null;
          invoice.recipient.city = new Locality(item.RefCityRecipient);
          invoice.recipient.city.name = item.CityRecipient;
          invoice.recipientCounterpartyType =
            item.RecipientCounterpartyType || getCounterPartyType(item.CounterpartyRecipientDescription) || '';
          invoice.recipient.phone = item.PhoneRecipient;
          invoice.recipientAddress = new Address(item.WarehouseRecipientInternetAddressRef);
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;

          invoice.recipientAddress.city = invoice.recipient.city;
          invoice.recipientAddress.description = item.RecipientAddress || item.WarehouseRecipient;
          if (invoice.recipientAddress.description) {
            invoice.recipientAddress.shortDescription = this.createShortDescription(
              invoice.recipientAddress.description,
            );
          }

          if (
            invoice.recipientAddress.description.toLowerCase().indexOf(invoice.recipient.city.name.toLowerCase()) === -1
          ) {
            invoice.recipientAddress.description = `${invoice.recipient.city.name}, ${invoice.recipientAddress.description}`;
          }
        } else if (item.RecipientFullNameEW) {
          stringArray = this.splitName(item.RecipientFullNameEW);
          invoice.recipient = new InvoiceContact(stringArray[1], stringArray[0], item.ContactRecipientRef);
          invoice.recipientContactPhone = item.RecipientContactPhone || '';
          invoice.recipient.phone = item.PhoneRecipient;
          invoice.recipient.middleName = stringArray[2] || null;
          invoice.recipient.city = new Locality(item.RefCityRecipient);
          invoice.recipient.city.name = item.CityRecipient;
          invoice.recipientCounterpartyType =
            item.RecipientCounterpartyType || getCounterPartyType(item.CounterpartyRecipientDescription) || '';

          const cachedInvoice: Invoice = this._cachedInvoices[invoice.docNumber];
          if (cachedInvoice && cachedInvoice.recipient) {
            invoice.recipient.contragent = cachedInvoice.recipient.contragent;
          }

          invoice.recipientAddress = new Address(null);
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;

          invoice.recipientAddress.description = item.RecipientAddress || item.WarehouseRecipient;
          if (invoice.recipientAddress.description) {
            invoice.recipientAddress.shortDescription = this.createShortDescription(
              invoice.recipientAddress.description,
            );
          }

          if (
            invoice.recipientAddress.description.toLowerCase().indexOf(invoice.recipient.city.name.toLowerCase()) === -1
          ) {
            invoice.recipientAddress.description = `${invoice.recipient.city.name}, ${invoice.recipientAddress.description}`;
          }

          if (cachedInvoice && cachedInvoice.recipientAddress && cachedInvoice.recipientAddress.city) {
            invoice.recipientAddress.city = cachedInvoice.recipientAddress.city;
          }
        }

        if (item.ContactSender) {
          stringArray = this.splitName(item.ContactSender);
          invoice.sender = new InvoiceContact(stringArray[1], stringArray[0], item.ContactSenderRef);
          invoice.sender.middleName = stringArray[2] || null;
          invoice.sender.city = new Locality(item.CitySenderRef);
          invoice.sender.city.name = item.CitySender;
          invoice.sender.phone = item.SendersPhone;
          invoice.senderAddress = new Address(item.SenderAddressRef);
          invoice.senderAddress.shortDescription = this.createShortDescription(item.SenderAddress);
          invoice.sender.contragent = new Contragent(item.SenderRef);
          invoice.senderAddress.city = invoice.sender.city;

          if (item.ServiceTypeRef === 'DoorsDoors' || item.ServiceTypeRef === 'DoorsWarehouse') {
            invoice.senderAddress.street = new Street('fake');
            invoice.senderAddress.street.locality = invoice.senderAddress.city;
          }

          if (
            (item.ServiceTypeRef === 'WarehouseDoors' || item.ServiceTypeRef === 'WarehouseWarehouse') &&
            invoice.senderAddress.city.name
          ) {
            invoice.senderAddress.description += ', ' + invoice.senderAddress.city.name;
          }
        } else if (item.SenderFullNameEW) {
          stringArray = this.splitName(item.SenderFullNameEW);
          invoice.sender = new InvoiceContact(stringArray[1], stringArray[0], item.ContactRecipientRef);
          invoice.sender.phone = item.PhoneSender;
          invoice.sender.middleName = stringArray[2] || null;
          invoice.sender.city = new Locality(null);
          invoice.sender.city.name = item.CitySender;

          const cachedInvoice: Invoice = this._cachedInvoices[invoice.docNumber];
          if (cachedInvoice && cachedInvoice.sender) {
            invoice.sender.contragent = cachedInvoice.sender.contragent;
          }

          invoice.senderAddress = new Address(null);
          invoice.senderAddress.description = item.SenderAddress || item.WarehouseSender;
          if (invoice.senderAddress.description) {
            invoice.senderAddress.shortDescription = this.createShortDescription(invoice.senderAddress.description);
          }

          if (invoice.senderAddress.description.toLowerCase().indexOf(invoice.sender.city.name.toLowerCase()) === -1) {
            invoice.senderAddress.description = `${invoice.sender.city.name}, ${invoice.senderAddress.description}`;
          }

          if (cachedInvoice && cachedInvoice.senderAddress && cachedInvoice.senderAddress.city) {
            invoice.senderAddress.city = cachedInvoice.senderAddress.city;
          }
        }

        /**
         * Kostil is used for saving list income and outcome Documents
         * We need becouse information for tracking document is not enaugh and we can use this saved documents
         * as resource of information
         */
        if (!invoice.senderAddress && invoice.docNumber && this._cachedInvoices[invoice.docNumber]) {
          invoice.senderAddress = this._cachedInvoices[invoice.docNumber].senderAddress;
          invoice.sender = this._cachedInvoices[invoice.docNumber].sender;
          if (invoice.senderAddress.description) {
            invoice.senderAddress.shortDescription = this.createShortDescription(invoice.senderAddress.description);
          }
        }

        if (
          (!invoice.recipientAddress || !invoice.recipient || !invoice.recipient.phone) &&
          invoice.docNumber &&
          this._cachedInvoices[invoice.docNumber]
        ) {
          invoice.recipientAddress = this._cachedInvoices[invoice.docNumber].recipientAddress;
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;

          invoice.recipient = this._cachedInvoices[invoice.docNumber].recipient;
          invoice.recipient.city = {
            id: item.RefCityRecipient,
            name: item.CityRecipient,
          } as Locality;
          invoice.recipientContactPhone = item.RecipientContactPhone || '';
          invoice.recipientCounterpartyType =
            item.RecipientCounterpartyType || getCounterPartyType(item.CounterpartyRecipientDescription) || '';
          if (invoice.recipientAddress.description) {
            invoice.recipientAddress.shortDescription = this.createShortDescription(
              invoice.recipientAddress.description,
            );
          }
        }

        invoice.sizes = [];
        invoice.weights = [];
        if (item.OptionsSeat && !item.OptionsSeat.length && item.OptionsSeat[0]) {
          const arr = [];

          for (const seatKey in item.OptionsSeat) {
            arr.push(item.OptionsSeat[seatKey]);
          }

          item.OptionsSeat = arr;
        }

        invoice.CalculatedWeight =
          item.CalculatedWeight && parseFloat(item.CalculatedWeight) > 0 ? parseFloat(item.CalculatedWeight) : 0;

        if (item.FactualWeight) {
          invoice.FactualWeight = parseFloat(item.FactualWeight);
        }

        if (item.DocumentWeight) {
          invoice.DocumentWeight = parseFloat(item.DocumentWeight);

          invoice.weight = invoice.CalculatedWeight > 0 ? invoice.CalculatedWeight : parseFloat(item.DocumentWeight);
        }

        if (item.OptionsSeat && item.OptionsSeat.length) {
          _.each(item.OptionsSeat, (el: any) => {
            const size: InvoiceSize = new InvoiceSize();

            size.width = parseFloat(el.volumetricWidth);
            size.height = parseFloat(el.volumetricHeight);
            size.length = parseFloat(el.volumetricLength);

            invoice.sizes.push(size);
            invoice.weights.push(parseFloat(el.weight));
          });
        } else {
          const size: InvoiceSize = new InvoiceSize();
          invoice.sizes.push(size);
          invoice.weights.push(
            invoice.CalculatedWeight > 0 ? invoice.CalculatedWeight : parseFloat(item.DocumentWeight) ?? 0,
          );
        }

        invoice.AfterpaymentOnGoodsCost = item.AfterpaymentOnGoodsCost ?? 0;
        invoice.moneyTransferAmount = item.moneyTransferAmount ?? 0;

        invoice.sumWeights();

        invoice.state = this._getState(item);

        invoice.redeliveryNum = item.RedeliveryNum ? item.RedeliveryNum : null;

        invoice.deliveryPrice = parseFloat(item.CostOnSite || item.DocumentCost || '0') || 0;
        invoice.StoragePrice = item.StoragePrice ? item.StoragePrice : 0;
        invoice.SumBeforeCheckWeight =
          parseFloat(item.SumBeforeCheckWeight) > 0 ? parseFloat(item.SumBeforeCheckWeight) : invoice.deliveryPrice;
        invoice.recipientNumberOfFloorsLifting = item.NumberOfFloorsLifting && parseInt(item.NumberOfFloorsLifting, 10);
        invoice.recipientElevator = item.Elevator === '1';
        /* invoice.senderNumberOfFloorsLifting =
         item.NumberOfFloorsLifting && parseInt(item.NumberOfFloorsLifting);
         invoice.senderElevator = item.ElevatorSender === '1' ? true : false; */
        invoice.isPrinted = item.Printed === '1' || item.Printed === true;
        invoice.isRegisterPrinted = item.ScanSheetPrinted === '1';
        invoice.paymentControl = parseFloat(item?.AfterpaymentOnGoodsCost) || 0;
        invoice.forwardingCount =
          !!item.ForwardingCount && parseFloat(item.ForwardingCount) > 0 ? parseFloat(item.ForwardingCount) : 0;
        invoice.ForwardingCount =
          !!item.ForwardingCount && parseFloat(item.ForwardingCount) > 0 ? parseFloat(item.ForwardingCount) : 0;
        invoice.paymentMethod = new InvoicePaymentMethod(item.PaymentMethodRef || item.PaymentMethod);

        invoice.deliveryToHand = item.DeliveryByHand === '1';
        invoice.undeliveryReasonsSubtypeDescription = item.UndeliveryReasonsSubtypeDescription;
        invoice.serviceType = new InvoiceServiceType(item.ServiceTypeRef || item.ServiceType);

        invoice.isPayPaid = !!item.AmountPaid;

        if (item.ServiceTypeRef || item.ServiceType) {
          // invoice.serviceType.name = this.$translations.instant(
          //   `INVOICE_SERVICES.${(item.ServiceTypeRef || item.ServiceType)
          //     .toUpperCase()}`,
          // );

          invoice.serviceType.name = `INVOICE_SERVICES.${(item.ServiceTypeRef || item.ServiceType).toUpperCase()}`;
        }

        if (
          invoice.senderAddress &&
          invoice.senderAddress.city &&
          !invoice.senderAddress.city.description &&
          item.CitySender !== invoice.senderAddress.city.id
        ) {
          invoice.senderAddress.city.description = item.CitySender;
        }

        if (item.PaymentCardToken && item.PaymentCardToken !== '00000000-0000-0000-0000-000000000000') {
          invoice.paymentCard = new PaymentCard(item.PaymentCardToken);
        }

        if (
          item.PreferredDeliveryDate &&
          item.PreferredDeliveryDate !== '0000-00-00 00:00:00' &&
          item.PreferredDeliveryDate !== '0001-01-01 00:00:00'
        ) {
          invoice.desiredDate = moment(item.PreferredDeliveryDate, 'YYYY-MM-DD').toDate();
        }

        if (item.TimeIntervalRef) {
          invoice.desiredTimeInterval = new TimeInterval(item.TimeIntervalRef);
          const intervals = item.TimeIntervalString.split('-');
          invoice.desiredTimeInterval.start = intervals[0].trim();
          invoice.desiredTimeInterval.end = intervals[1].trim();
        }

        if (item.CargoDetails) {
          invoice.tiresWheels = {};

          for (const key in item.CargoDetails) {
            const element = item.CargoDetails[key];

            invoice.tiresWheels[element.CargoDescriptionRef] = parseFloat(element.Amount);
          }
        }

        if (item.RecipientAddress && !invoice.recipientAddress) {
          invoice.recipientAddress = new Address();
          invoice.recipientAddress.description = item.RecipientAddress;
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;
        }

        if (item.WarehouseRecipient && !invoice.recipientAddress) {
          invoice.recipientAddress = new Address();
          invoice.recipientAddress.description = `${item.CityRecipient}, ${item.WarehouseRecipient}`;
          invoice.recipientAddress.type =
            item.RecipientWarehouseTypeRef && item.RecipientWarehouseTypeRef !== AddressSettings.POSTBOX_TYPE
              ? 'Warehouse'
              : undefined;
        }

        if (item.SenderAddress && !invoice.senderAddress) {
          invoice.senderAddress = new Address();
          invoice.senderAddress.description = item.SenderAddress;
          invoice.senderAddress.city = Object.assign(new Locality(), {
            name: item.CitySender,
          } as Locality);
        } else if (invoice.senderAddress && !invoice.senderAddress.city && item.CitySender) {
          invoice.senderAddress.city = Object.assign(new Locality(), {
            name: item.CitySender,
            description: item.CitySender,
          } as Locality);
        }

        if (item.LastCreatedOnTheBasisDocumentType) {
          invoice.lastCreatedOnTheBasisDocumentType = item.LastCreatedOnTheBasisDocumentType;
        }

        if (item.LastCreatedOnTheBasisNumber) {
          invoice.serviceRequests = [
            Object.assign(new InvoiceServiceRequest(), {
              newInvoiceNumber: item.LastCreatedOnTheBasisNumber,
              serviceName: item.LastCreatedOnTheBasisDocumentType,
            } as InvoiceServiceRequest),
          ];
        }

        if (invoice.recipientAddress && invoice.recipientAddress.description) {
          invoice.recipientAddress.shortDescription = this.createShortDescription(invoice.recipientAddress.description);
        }

        if (invoice.senderAddress && invoice.senderAddress.description) {
          invoice.senderAddress.shortDescription = this.createShortDescription(invoice.senderAddress.description);
        }

        if (!invoice.senderAddress && item.CitySender) {
          invoice.senderAddress = new Address();
          invoice.senderAddress.city = new Locality();
          invoice.senderAddress.city.description = item.CitySender;
        }

        if (!invoice.recipientAddress && item.CityRecipient) {
          invoice.recipientAddress = new Address();
          invoice.recipientAddress.description = item.CityRecipient;
        }

        if (item.StatusCode) {
          invoice.statusCode = item.StatusCode;
        }

        invoice.isInternational = this.isInternational(item);
        invoice.marketPlaceToken = item.MarketplacePartnerToken;
        invoice.Cash2CardPAN = item.Cash2CardPAN ? item.Cash2CardPAN : '';
        invoice.cardMaskedNumber = item.CardMaskedNumber ? item.CardMaskedNumber : '';
        invoice.RedeliveryPaymentCard = item.RedeliveryPaymentCard
          ? item.RedeliveryPaymentCard
          : {
            CardDescription: '',
            CardMaskedNumber: '',
          };
        invoice.DeliveryPharma = !!parseInt(item.DeliveryPharma);
      } catch (e) {
      }
    } else {
      const errors: IApiError[] = [];
      _.each(response.data.errorCodes, (el, key) => {
        errors.push({
          messageCode: el,
          errorCode: this.noRestApiHelper.replaceErrorCodeOnCustom(el),
          message: response.data.errors[key],
        });
      });

      throw errors;
    }
    return invoice;
  }

  protected bindMoneyTransfers(data: any): IListData<Invoice> {
    return {
      total: data.info.totalCount,
      list: data.data[0]
        ? _.map(data.data, (el: any) => {
          try {
            const invoice = new InvoiceMy(el.Ref);
            /// MoneyTransfer fields
            invoice.dateTime = moment(el.DateTime).toDate();
            invoice.moneyTransferPayerType = el.MoneyTransferPayerType;
            invoice.moneyTransferCash2Card = this.coerceToNumber(el.MoneyTransferCash2Card);
            invoice.number = el.Number;
            invoice.moneyTransferPaymentMethod = el.MoneyTransferPaymentMethod;
            invoice.moneyTransferAmount = parseFloat(el.MoneyTransferAmount).toFixed(0) || 0;
            invoice.ref = el.Ref;
            invoice.moneyTransferCommission = parseFloat(el.MoneyTransferCommission).toFixed(0) || 0;
            invoice.moneyTransferNumber = el.MoneyTransferNumber;
            invoice.moneyTransferCreationDate = el.MoneyTransferCreationDate;
            invoice.moneyTransferPayerCommission = el.MoneyTransferPayerCommission;
            invoice.moneyTransferStatus = el.MoneyTransferStatus;
            invoice.moneyTransferStatusDateTime = el.MoneyTransferStatusDateTime;
            /// end moneyTransfer fields
            let userNameParts: string[] = this.splitName(el.SenderName);
            invoice.sender = new InvoiceContact(userNameParts[1], userNameParts[0], null);
            invoice.senderContactPhone = el.PhoneSender;
            invoice.sender.phone = el.PhoneSender;

            userNameParts = this.splitName(el.RecipientName);
            invoice.recipient = new InvoiceContact(userNameParts[1], userNameParts[0], null);
            invoice.recipientContactPhone = el.PhoneRecipient;
            invoice.recipient.phone = el.PhoneRecipient;

            return invoice;
          } catch (ex) {
          }
        })
        : [],
    };
  }

  private setAddressType(addressDescription: string) {
    return addressDescription.toLowerCase().indexOf('відділення') > -1
      ? 'Warehouse'
      : addressDescription.toLowerCase().indexOf('поштомат') > -1
        ? 'Postbox'
        : 'Address';
  }

  private copyProps(src: any, dist: any, props: string[]): void {
    props.forEach((prop) => (dist[prop] = src[prop] ? src[prop] : ''));
  }

  private coerceToNumber(el: any): number {
    return !isNaN(parseFloat(el)) && !!parseFloat(el) ? parseFloat(el) : 0;
  }

  private bindTires(res: any): TireWheel[] {
    return res.data.map((item) => {
      const tire = new TireWheel(item.Ref);
      tire.description = item.Description;
      tire.weight = parseFloat(item.Weight);
      tire.isTruck = item.Description.indexOf('вант') !== -1;
      tire.type = item.Description.indexOf('Шина') === -1 ? 'Wheels' : 'Tires';
      tire.radius = item.Description.split(' R ')[1];

      return tire;
    });
  }

  private splitName(name: string) {
    if (!name) {
      return ['', '', ''];
    }
    // see https://npteam.atlassian.net/browse/CBC-109
    return [name, '', ''];
  }

  private isInternational(rawInvoice: any) {
    return rawInvoice.InternationalDeliveryType === 'Import' || rawInvoice.InternationalDeliveryType === 'Export';
  }

  private loadMoneyTransfers(
    filter: IFilterParam = null,
    order: IOrderParameter = null,
    page: number = 1,
    limit: number = 100,
    counterpartyRefs?: string[],
  ): any {
    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getMoneyTransferDocuments',
      methodProperties: {
        DateFrom: moment().subtract(3, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        DateTo: moment().add(1, 'days').add(1, 'months').format('DD.MM.YYYY') + ' 00:00:00',
        Page: page,
        Limit: limit,
        SearchByCounterparties: this.authService.user.contacts ? 1 : null,
        iCounterparties: counterpartyRefs ? counterpartyRefs : null,
      },
    });
  }
}

import { Address, Contact, Model } from '@models';
import { OrderState } from '../components/dashboard/business-services/models/order-state';
import { Pricing } from '../components/dashboard/business-services/models/pricing';
import { InvoiceState } from './invoice/invoice-state.model';

export class BackInvoice extends Model {
  Number: string;
  createDateTime: Date;
  description: string;
  docNumber: string;
  recipient: Contact;
  recipientAddress: Address;
  deliveryCost: number;
  estimatedDeliveryDate: Date;
  state: OrderState;
  newDocNumber: string;
  newDocState: InvoiceState;
  AddressRecipient: string;
  ContactPersonRecipient: string;
  CounterpartyRecipient: string;
  DateTime: string;
  DeliveryCost: string;
  DocumentNumber: string;
  EstimatedDeliveryDate: string;
  ExpressWaybillNumber: string;
  ExpressWaybillStatus: string;
  OrderNumber: string;
  OrderRef: string;
  OrderStatus: string;
  RecipientPhone: string;
  CanDeleteOrder: boolean;
  ScheduledDeliveryDate: string;
  Pricing: Pricing;
  MessageText: string;
}

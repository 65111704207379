import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, AddressTypes, ContactPerson, Contragent, ITimeInterval, Locality } from '@models';
import { AuthService } from '@services';
import { parseNumbers } from '@shared';
import * as moment from 'moment';
import { catchError, map, Observable, of } from 'rxjs';
import { InvoiceCargoType } from '../../models/invoice/invoice-cargo-type.model';
import { Invoice } from '../../models/invoice/invoice.model';
import { GetTemplateInfoResponseData } from '../../models/template.model';
import { AllowedStatusCodes } from '../../shared/constants';
import { ApiMethods } from '../api/apiMethods';
import { ConfigService } from '../config.service';
import { NoRestApiHelper } from '../no-rest-api.helper';
import { IFilterParam, IOrderParameter } from './iapi/iinvoice.api';
import { UpdateInvoiceApiParams } from './invoice-api.models';
import { InvoiceStateConfig, states } from './invoice-states.config';
import { getRedirectingReturnIndication } from './invoices.utils';

@Injectable({
  providedIn: 'root',
})
export class InvoiceApiService {
  public trackUrl = 'https://track.novaposhta.io/v1/publicapi/standard';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
    private authorizationService: AuthService,
  ) {
  }

  getCargoDescriptionList(query): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getCargoDescriptionList',
      methodProperties: {
        FindByString: query,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
    );
  }

  searchInvoices(query, { page, limit }): Observable<any> {
    const searchInvoices = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'findDocumentByData',
      methodProperties: {
        FindByData: query,
        Page: page,
        Limit: limit,
        DateFrom: moment().subtract(3, 'months').format('DD.MM.YYYY'),
        DateTo: moment().add(1, 'days').add(1, 'months').format('DD.MM.YYYY'),
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, searchInvoices).pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((response) => ({
        totalCount: response.info.totalCount,
        invoices: response.data.map((el) => el.result.map((invoice) => this.mapInvoice(invoice))),
      })),
    );
  }

  mapInvoice(invoice: any): Invoice {
    invoice.state = this.getState(invoice.TrackingStatusCode);
    invoice.statusCode = invoice.TrackingStatusCode;
    invoice.weight = Number(invoice.CalculatedWeight) > 0 ? invoice.CalculatedWeight : invoice.DocumentWeight;
    invoice.docNumber = invoice.Number;
    invoice.deliveryPrice = parseFloat(invoice.CostOnSite || invoice.DocumentCost) || 0;
    invoice.StoragePrice = invoice.StoragePrice ? invoice.StoragePrice : 0;
    invoice.moneyTransferCash2Card =
      invoice.MoneyTransferCash2Card === '1' ? this.coerceToNumber(invoice.MoneyTransferAmount) : null;
    invoice.moneyTransferAmount =
      invoice.MoneyTransferCash2Card === '0' ? this.coerceToNumber(invoice.MoneyTransferAmount) : null;
    if (invoice.MoneyTransferAmount) {
      invoice.Cash2CardPAN = invoice.CardMaskedNumber;
      invoice.backDeliveryPayer = invoice.MoneyTransferPayerType;
      invoice.MoneyTransferAmount = this.coerceToNumber(invoice.MoneyTransferAmount);
    }

    if (invoice.OrderingAdditionalServices) {
      getRedirectingReturnIndication(invoice);
    }

    invoice.AfterpaymentOnGoodsCost =
      (!!invoice.AfterpaymentOnGoodsCost && parseFloat(invoice.AfterpaymentOnGoodsCost)) || 0;
    invoice.SumBeforeCheckWeight =
      parseFloat(invoice.SumBeforeCheckWeight) > 0 ? parseFloat(invoice.SumBeforeCheckWeight) : invoice.deliveryPrice;
    const date = invoice.RecipientDateTime || invoice.ReceivingDateTime;
    invoice.receivingTime =
      date !== '0001-01-01 00:00:00' ? moment(invoice.RecipientDateTime || invoice.ReceivingDateTime).toDate() : null;
    invoice.ReceivingDateTime =
      date !== '0001-01-01 00:00:00' ? invoice.RecipientDateTime || invoice.ReceivingDateTime : null;
    if (invoice.ArrivalDateTime && invoice.ArrivalDateTime !== '0001-01-01 00:00:00') {
      invoice.ArrivalDateTime = moment(invoice.ArrivalDateTime, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    invoice.payer = invoice.PayerType;

    const cargoTypeTranslation = {
      Cargo: 'Вантаж',
      Pallet: 'Палети',
      Documents: 'Документи',
      TiresWheels: 'Шини та диски',
      Parcel: 'Посилка',
    };
    invoice.cargoType = new InvoiceCargoType(invoice.CargoType);
    invoice.cargoType.description = cargoTypeTranslation[invoice.cargoType.id];
    invoice.scheduleDeliveryDate = invoice.ScheduledDeliveryDate;
    invoice.clientBarcode = invoice.ClientBarcodes?.[0] || '';
    invoice.ClientBarcodes = invoice.ClientBarcodes?.[0] || '';
    invoice.paymentControl = invoice.AfterpaymentOnGoodsCost;
    invoice.recipientAddress = new Address();
    if (invoice.RecipientAddressDescription) {
      invoice.recipientAddress.description = invoice.RecipientAddressDescription;
      invoice.recipientAddress.shortDescription = this.createShortDescription(invoice.RecipientAddressDescription);
      invoice.recipientAddress.type = this.setAddressType(invoice.RecipientAddressDescription);
    }

    invoice.senderAddress = new Address();
    invoice.senderAddress.city = new Locality(invoice.CitySender);
    if (invoice.SenderAddressDescription) {
      invoice.senderAddress.shortDescription = this.createShortDescription(invoice.SenderAddressDescription);
      invoice.senderAddress.description = invoice.SenderAddressDescription;
      invoice.senderAddress.type = this.setAddressType(invoice.SenderAddressDescription);
    }
    invoice.announcedPrice = invoice.Cost;
    invoice.isInternational = this.isInternational(invoice);
    invoice.FirstDayStorage = invoice.FirstDayStorage === '0001-01-01 00:00:00' ? '' : invoice.FirstDayStorage;
    invoice.dateFirstDayStorage = invoice.FirstDayStorage;
    invoice.actualDeliveryDate = invoice.ArrivalDateTime;
    invoice.dateReturnCargo = invoice.CargoAutoReturnDate.split(' ')[0];
    invoice.allowManageButton = invoice.possibilityCreateRedirecting || invoice.possibilityCreateReturn || invoice.possibilityChangeEW || invoice.possibilityTermExtension

    return {
      ...invoice,
      moneyTransferCash2Card: Number(invoice.MoneyTransferCash2Card),
      backDeliveryPayer: invoice.MoneyTransferPayerType,
      backMoney: Number(invoice.MoneyTransferAmount) ?? invoice.BackwardDeliveryMoney,
      cardMaskedNumber: invoice.CardMaskedNumber ?? '',
    };
  }

  getState(statusCode): InvoiceStateConfig {
    if (!!statusCode) {
      return states[statusCode];
    }

    return states['1'];
  }

  saveForTracking(
    invoices: Invoice[],
    trackingType: 'MANUAL' | 'AUTO',
    senderType: 'RECIPIENT' | 'SENDER' | 'THIRD_PERSON',
  ) {
    const requestBody = {
      loyalyCardNumber: this.authorizationService.user.loyaltyCardNumber,
      trakingChanel: 'BUSINESS_CABINET',
      trackingType,
      phoneNumber: this.authorizationService.user.phone,
      calledMethod: '',
      documents: [],
    };

    for (const invoice of invoices) {
      requestBody.documents.push({
        number: invoice.docNumber,
        senderType,
        status: invoice.statusCode,
        payerType: invoice.payer ? invoice.payer.toLowerCase() : '',
      });
    }

    return this.http.post(this.trackUrl, requestBody).pipe(
      catchError((error) => {
        return invoices;
      }),
      map((data) => {
        return invoices;
      }),
    );
  }

  searchRecipientContacts(query: string, { page, limit }): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'ContactPersonGeneral',
      calledMethod: 'getContactPersonsCounterparty',
      methodProperties: {
        ContactProperty: 'Recipient',
        Page: page,
        Limit: limit,
        getContactPersonAddress: '1',
        FindByString: query,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => ({
        totalCount: response.data.info.totalCount,
        contacts: response.data.data as ContactPerson[],
      })),
    );
  }

  addByEdpu(edpu: string): Observable<any> {
    const promise = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'save',
      methodProperties: {
        CounterpartyType: 'Organization',
        CounterpartyProperty: 'ThirdPerson',
        EDRPOU: edpu,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, promise).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
    );
  }

  checkServiceAvailability(ref: string): Observable<any> {
    const checkServicesAvailabilityPromise = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterpartyOptions',
      methodProperties: {
        Ref: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, checkServicesAvailabilityPromise).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(({ data }: any) => data.data[0]),
    );
  }

  createTemplate(template): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentTemplate',
      calledMethod: ApiMethods.save,
      methodProperties: this.templateToApi(template),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
    );
  }

  getOrganization(ref?): Observable<Array<Contragent>> {
    const createTemplatePromise = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterparties',
      methodProperties: {
        CounterpartyProperty: 'Sender',
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, createTemplatePromise).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
      catchError(() => of([])),
    );
  }

  getOrganizationContacts(ref): Observable<any> {
    const createTemplatePromise = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterpartyContactPersons',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, createTemplatePromise).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getTemplateInfo(Ref: string): Observable<GetTemplateInfoResponseData> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentTemplate',
      calledMethod: 'getDocumentTemplate',
      methodProperties: { Ref },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0] as GetTemplateInfoResponseData),
    );
  }

  getInvoiceInfo(id: string): Observable<any> {
    const promise: any = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getDocument',
      methodProperties: {
        Ref: id,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, promise)
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  createInvoice(item: Invoice, isOrder = false): Observable<any> {
    const createTemplatePromise = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'save',
      methodProperties: this._invoiceToMethodParams(item, isOrder),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, createTemplatePromise).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
    );
  }

  updateInvoice(item, isOrder = false): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'update',
      methodProperties: this._invoiceToMethodParams(item, isOrder),
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  updateTemplate(template): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentTemplate',
      calledMethod: ApiMethods.update,
      methodProperties: this.templateToApi(template),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getTemplateList(filter: IFilterParam, order: IOrderParameter, page = 1, limit = 20, query?: string): Observable<any> {
    const getTemplatePromise = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentTemplate',
      calledMethod: 'getDocumentTemplateList',
      methodProperties: {
        Page: page,
        Limit: limit,
        ...(query ? { FindByString: query.trim() } : {}),
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, getTemplatePromise).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data) => data.data.data),
      catchError(() => of([])),
    );
  }

  getForRef(docNumber: any): Observable<any> {
    const updatePromise = {
      system: this.configService.get('system'),
      modelName: 'InternetDocument',
      calledMethod: 'getEWTemplateList',
      methodProperties: {
        IntDocNumber: docNumber,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, updatePromise).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
    );
  }

  getTimeIntervals(dateTime: string, recipientCityRef: string) {
    const requestBody = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getTimeIntervals',
      methodProperties: {
        RecipientCityRef: recipientCityRef,
        DateTime: dateTime,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, requestBody).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(({ data }: any) => data.data),
    );
  }

  getPickupTimeIntervals(dateTime: string, senderCityRef: string, checkInfoCode: boolean = true): Observable<ITimeInterval[]> {
    const requestBody = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getPickupTimeIntervals',
      methodProperties: {
        SenderSettlementRef: senderCityRef,
        DateTime: dateTime,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, requestBody).pipe(
      map((data) => {
        if (checkInfoCode) {
          if ((data as any).infoCodes[0] === '40000401809') {
            throw new Error('There is no available time intervals through the day');
          }
        }
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(({ data }: any) => data.data),
    );
  }

  addDocumentToArchive(invoice: Invoice, invoiceType: 'in' | 'out'): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'InternetDocumentGeneral',
      calledMethod: 'addDocumentToArchive',
      methodProperties: {
        Documents: [
          {
            Number: invoice.docNumber,
            Ref: invoice.RefEW,
            ...(invoiceType === 'in' ? { RecipientArchive: '1' } : { SenderArchive: '1' }),
          },
        ],
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map(({ data }: any) => data.data),
    );
  }

  track(invoiceNumber: string): Observable<Invoice> {
    const requestBody = {
      system: this.configService.get('system'),
      modelName: 'TrackingDocument',
      calledMethod: 'getStatusDocuments',
      methodProperties: {
        Documents: [
          {
            DocumentNumber: invoiceNumber,
            Phone: '',
          },
        ],
        Language: 'UA',
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, requestBody).pipe(
      map((data) => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(({ data }: any) => data.data),
    );
  }

  getCitySender(invoice) {
    return (
      invoice.NewAddressSender?.CityRef || invoice.AddressSender.data?.CityRef || invoice.AddressSender.data?.city?.Ref
    );
  }

  getRecipientType(invoice): UpdateInvoiceApiParams['RecipientType'] {
    if (invoice.Recipient.Counterparty === 'Приватна особа'
      || invoice.Recipient.Counterparty === 'приватна особа') {
      return 'PrivatePerson';
    }

    if (invoice.Recipient.CounterpartyDesctiption === 'Приватна особа'
      || invoice.Recipient.CounterpartyDesctiption === 'приватна особа') {
      return 'PrivatePerson';
    }

    return 'Organization';
  }

  getSenderAddress(invoice) {
    return invoice.NewAddressSender?.Ref || invoice.AddressSender.data.Ref || invoice.AddressSender.data.warehouse.Ref;
  }

  protected createShortDescription(description: string): string {
    if (!description) {
      return '';
    }

    let shortDescription = description.replace(/\(.*\)/gi, '');
    if (/.*(Відділення №\d+)/.test(shortDescription)) {
      shortDescription = /.*(Відділення №\d+)/.exec(shortDescription)[0];
    }
    return shortDescription;
  }

  protected templateToApi(template): Partial<UpdateInvoiceApiParams> {
    const methodParams: Partial<UpdateInvoiceApiParams> = {};
    methodParams.Ref = template.ref;
    methodParams.CitySender = this.getCitySender(template);
    methodParams.Sender = template.ContactSender.data.CounterpartyRef || template.ContactSenderOrg.Ref;
    methodParams.ContactSender = template.ContactSender.data.Ref || template.Sender.Ref;
    methodParams.SenderAddress = this.getSenderAddress(template);
    methodParams.SendersPhone = parseNumbers(template.ContactSender.data.Phones || template.ContactSender.data.phone);
    methodParams.Recipient = (template.Recipient || {}).CounterpartyRef;
    methodParams.CityRecipient = template.NewAddressRecipient
      ? template.NewAddressRecipient.CityRef
      : template.AddressRecipient.data.CityRef;
    methodParams.RecipientAddress = template.NewAddressRecipient
      ? template.NewAddressRecipient.Ref
      : template.AddressRecipient.data.Ref;
    methodParams.ContactRecipient = template.Recipient?.Ref;
    methodParams.RecipientsPhone = parseNumbers(template.Recipient?.Phones);
    methodParams.ServiceType = `${
      template.AddressSender.type === AddressTypes.POSTBOX || template.AddressSender.type === AddressTypes.INDEX
        ? AddressTypes.WAREHOUSE
        : template.AddressSender.type
    }${
      template.AddressRecipient.type === AddressTypes.POSTBOX || template.AddressRecipient.type === AddressTypes.INDEX
        ? AddressTypes.WAREHOUSE
        : template.AddressRecipient.type
    }`;
    methodParams.CargoType = template.CargoType;
    methodParams.ParamsOptionsSeats = template.paramsCommonSeats;
    methodParams.Cost = template.Cost;
    methodParams.Description = template.Description?.Description || template.Description;
    methodParams.PayerType = template.PayerType;
    methodParams.PaymentMethod = template.PaymentMethod;
    methodParams.InfoRegClientBarcodes = template.InfoRegClientBarcodes;
    methodParams.PackingNumber = template.PackingNumber;
    methodParams.Promocode = template.Promocode?.trim();
    methodParams.AdditionalInformation = template.AdditionalInformation;
    methodParams.AccompanyingDocuments = template.AccompanyingDocuments;
    methodParams.DescriptionTemplate = template.description;
    if (template.CargoType === 'TiresWheels') {
      methodParams.CargoDetails = template.tiresWheels
        .filter((item) => item.count > 0)
        .map((item) => {
          return { CargoDescription: item.id, Amount: item.count };
        });
    }
    methodParams.DateTime = this.noRestApiHelper.getFormatedDateForRequest(new Date());
    if (methodParams.ServiceType === 'WarehouseWarehouse' || methodParams.ServiceType === 'DoorsWarehouse') {
      delete methodParams.SameDayDelivery;
    }
    if (template.OptionsSeat) {
      methodParams.SeatsAmount = template.OptionsSeat.length;
      methodParams.Weight = template.OptionsSeat.reduce((acc, curr) => acc + +curr.weight, 0);
      methodParams.VolumeGeneral = template.OptionsSeat.volumetricVolume;
      methodParams.OptionsSeat = template.OptionsSeat.map((seat) => {
        const seatNoVolume = JSON.parse(JSON.stringify(seat));
        seatNoVolume.volumetricVolume = '';
        return seatNoVolume;
      });
    } else if (template.commonParams) {
      methodParams.SeatsAmount = template.commonParams.SeatsAmount;
      methodParams.Weight = template.commonParams.Weight;
      methodParams.VolumeGeneral = template.commonParams.VolumeGeneral;
    } else {
      methodParams.SeatsAmount = template.SeatsAmount;
      methodParams.Weight = Number(template.Weight).toString();
    }
    if (template.PayerType === 'ThirdPerson') {
      methodParams.ThirdPerson = template.ThirdPerson;
    }

    const backDelivery = template.AdditionalServices?.backDelivery;
    if (backDelivery?.BackwardDeliveryData) {
      const returnData = backDelivery.BackwardDeliveryData;
      const backObj = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        RedeliveryString: returnData.RedeliveryString,
        ...(returnData.CustomBackwardDeliveryParameter
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameter,
          }
          : {}),
      };

      if (
        backObj.CargoType === 'Money' &&
        template.AdditionalServices?.backDelivery?.BackwardDeliveryData.ReceivingType === 'Card'
      ) {
        Object.assign(backObj, {
          Cash2CardPayout_Id: returnData.Cash2CardPayout_Id,
          Cash2CardAlias: returnData.Cash2CardAlias,
          Cash2CardPAN: returnData.Cash2CardPAN,
        });
        methodParams.Number = backDelivery.number;
        methodParams.Cash2Card = true;
      }
      methodParams.BackwardDeliveryData = [backObj];
    }

    const backDocuments = template.AdditionalServices?.backDocuments;
    if (backDocuments?.BackwardDeliveryData) {
      const backObj = {
        PayerType: backDocuments.BackwardDeliveryData.PayerType,
        CargoType: backDocuments.BackwardDeliveryData.CargoType,
        RedeliveryString: backDocuments.BackwardDeliveryData.RedeliveryString,
        ...(backDocuments.BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments
          ? {
            CustomBackwardDeliveryParameter:
            backDocuments.BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments,
          }
          : {}),
        ReceivingType: backDocuments.BackwardDeliveryData.ReceivingType,
        ReceivingData: backDocuments.BackwardDeliveryData.ReceivingData,
      };
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, backObj]
        : [backObj];
    }

    if (template.AdditionalServices?.afterpayment?.AfterpaymentOnGoodsCost) {
      methodParams.AfterpaymentOnGoodsCost = template.AdditionalServices?.afterpayment.AfterpaymentOnGoodsCost;
    }
    if (template.AdditionalServices?.forwardingCount?.ForwardingCount) {
      methodParams.ForwardingCount = template.AdditionalServices?.forwardingCount.ForwardingCount;
    }

    if (template.AdditionalServices?.doorstepPickup?.DoorstepPickupData) {
      methodParams.NumberOfFloorsDescent =
        template.AdditionalServices?.doorstepPickup.DoorstepPickupData.NumberOfFloorsDescent;
    }

    if (template.AdditionalServices?.floorsLifting?.FloorsLiftingData) {
      methodParams.NumberOfFloorsLifting =
        template.AdditionalServices?.floorsLifting.FloorsLiftingData.NumberOfFloorsLifting;
      methodParams.Elevator = template.AdditionalServices?.floorsLifting.FloorsLiftingData.Elevator ? '1' : '0';
    }

    if (
      template.AdditionalServices?.preferredDate?.PreferredDeliveryDate &&
      template.AdditionalServices?.preferredDate.TimeInterval
    ) {
      methodParams.TimeInterval = template.AdditionalServices?.preferredDate.TimeInterval;
    }
    if (template.AdditionalServices?.signedDocuments?.BackwardDeliveryData) {
      const returnData = template.AdditionalServices?.signedDocuments.BackwardDeliveryData;
      const backObj = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        RedeliveryString: returnData.RedeliveryString,
        ...(returnData.CustomBackwardDeliveryParameter
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameter,
          }
          : {}),
      };
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, backObj]
        : [backObj];
    }
    if (template.AdditionalServices?.differentDocuments?.BackwardDeliveryData) {
      const returnData = template.AdditionalServices?.differentDocuments.BackwardDeliveryData;
      const data = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        ...(returnData.CustomBackwardDeliveryParameterDifferentDocuments
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameterDifferentDocuments,
          }
          : {}),
      };
      const documents = returnData.documents;
      const services = {};
      for (const document of documents) {
        const docServices = document.services;
        Object.keys(docServices).map((key) => {
          const service = docServices[key];
          if (service.checked) {
            services[service.name] = service.checked;
          }
        });
      }
      (services as any).UserActions = returnData.UserActions;
      (data as any).Services = services;
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, data]
        : [data];
    }
    if (template.AdditionalServices?.packingService?.PackingService && template.CargoType !== 'Documents') {
      if (template.commonParams) {
        methodParams.OptionsSeat = [
          {
            weight: template.commonParams.Weight,
            volumetricVolume: template.commonParams.VolumeGeneral,
            volumetricWeight: template.commonParams.VolumeWeight,
            packRef: template.AdditionalServices?.packingService.PackingService[0].packRef,
          },
        ];
      } else {
        methodParams.OptionsSeat = template.AdditionalServices?.packingService.PackingService.map((seat) => {
          if (template.CargoType === 'Cargo') {
            delete seat.Description;
            delete seat.Cost;
          }
          const seatNoVolume = JSON.parse(JSON.stringify(seat));
          seatNoVolume.volumetricVolume = '';
          return seatNoVolume;
        });
      }
    } else if (template.AdditionalServices?.packingService.PackingService && template.CargoType === 'Documents') {
      methodParams.OptionsSeat = template.AdditionalServices?.packingService.PackingService.map((seat) => {
        const seatNoVolume = JSON.parse(JSON.stringify(seat));
        if (!template.createTemplate) {
          seatNoVolume.volumetricVolume = '';
          seatNoVolume.volumetricLength = 20;
          seatNoVolume.volumetricWidth = 35;
          seatNoVolume.volumetricHeight = 5;
        }
        return seatNoVolume;
      });
    }
    if (!template.AdditionalServices?.packingService?.PackingService) {
      methodParams.OptionsSeat?.map((seat) => delete seat.packRef);
    }
    if (template.NewRecipientPrivateContact) {
      methodParams.RecipientsPhone = parseNumbers(template.NewRecipientPrivateContact.phone);
    }
    return methodParams;
  }

  protected _invoiceToMethodParams(invoice, isOrder = false): Partial<UpdateInvoiceApiParams> {
    const methodParams: Partial<UpdateInvoiceApiParams> = {};
    methodParams.Ref = invoice.ref;
    methodParams.RecipientType = this.getRecipientType(invoice);
    methodParams.CitySender = this.getCitySender(invoice);
    methodParams.Sender = invoice.ContactSender.data.CounterpartyRef || invoice.ContactSenderOrg.Ref;
    methodParams.ContactSender = invoice.ContactSender.data.Ref || invoice.Sender.Ref;
    methodParams.SenderAddress = this.getSenderAddress(invoice);
    methodParams.SendersPhone = invoice.ContactSender.data.Phones || invoice.ContactSender.data.phone;
    methodParams.Recipient = (invoice.Recipient || {}).CounterpartyRef;
    methodParams.CityRecipient = invoice.NewAddressRecipient
      ? invoice.NewAddressRecipient.CityRef
      : invoice.AddressRecipient.data.CityRef;
    methodParams.RecipientAddress = invoice.NewAddressRecipient
      ? invoice.NewAddressRecipient.Ref
      : invoice.AddressRecipient.data.Ref;
    methodParams.ContactRecipient = (invoice.Recipient || {}).Ref;
    methodParams.RecipientsPhone = (invoice.Recipient || {}).Phones;
    methodParams.ServiceType = `${
      invoice.AddressSender.type === AddressTypes.POSTBOX || invoice.AddressSender.type === AddressTypes.INDEX
        ? AddressTypes.WAREHOUSE
        : invoice.AddressSender.type
    }${
      invoice.AddressRecipient.type === AddressTypes.POSTBOX || invoice.AddressRecipient.type === AddressTypes.INDEX
        ? AddressTypes.WAREHOUSE
        : invoice.AddressRecipient.type
    }`;
    methodParams.CargoType = invoice.CargoType;
    methodParams.ParamsOptionsSeats = invoice.paramsCommonSeats;
    methodParams.Cost = invoice.Cost;
    methodParams.Description = invoice.Description?.Description || invoice.Description;
    methodParams.PayerType = invoice.PayerType;
    methodParams.PaymentMethod = invoice.PaymentMethod;
    methodParams.InfoRegClientBarcodes = invoice.InfoRegClientBarcodes;
    methodParams.PackingNumber = invoice.PackingNumber;
    methodParams.Promocode = invoice.Promocode?.Promocode ?? invoice.Promocode;
    methodParams.MarketplacePartnerToken = '005056887b8d-b5da-11e6-9f54-cea38574';
    if (isOrder) {
      methodParams.MarketplacePartnerToken2 = '0050568046cd-95eb-11e3-c6c1-369e2544';
    }
    methodParams.AdditionalInformation = invoice.AdditionalInformation;
    methodParams.AccompanyingDocuments = invoice.AccompanyingDocuments;
    methodParams.DescriptionTemplate = invoice.description;
    if (invoice.CargoType === 'TiresWheels') {
      methodParams.CargoDetails = invoice.tiresWheels
        .filter((item) => item.count > 0)
        .map((item) => {
          return { CargoDescription: item.id, Amount: item.count };
        });
    }
    methodParams.DateTime = this.noRestApiHelper.getFormatedDateForRequest(new Date());
    if (methodParams.ServiceType === 'WarehouseWarehouse' || methodParams.ServiceType === 'DoorsWarehouse') {
      delete methodParams.SameDayDelivery;
    }
    if (invoice.OptionsSeat) {
      methodParams.SeatsAmount = invoice.OptionsSeat.length;
      methodParams.Weight = invoice.OptionsSeat.reduce((acc, curr) => acc + +curr.weight, 0);
      methodParams.VolumeGeneral = invoice.OptionsSeat.volumetricVolume;
      methodParams.OptionsSeat = invoice.OptionsSeat.map((seat) => {
        const seatNoVolume = JSON.parse(JSON.stringify(seat));
        seatNoVolume.volumetricVolume = '';
        return seatNoVolume;
      });
    } else if (invoice.commonParams) {
      methodParams.SeatsAmount = invoice.commonParams.SeatsAmount;
      methodParams.Weight = invoice.commonParams.Weight;
      methodParams.VolumeGeneral = invoice.commonParams.VolumeGeneral;
    } else {
      methodParams.SeatsAmount = invoice.SeatsAmount;
      methodParams.Weight = Number(invoice.Weight).toString();
    }
    if (invoice.PayerType === 'ThirdPerson') {
      methodParams.ThirdPerson = invoice.ThirdPerson;
    }

    const backDelivery = invoice.AdditionalServices?.backDelivery;
    if (backDelivery?.BackwardDeliveryData) {
      const returnData = backDelivery.BackwardDeliveryData;
      const backObj = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        RedeliveryString: returnData.RedeliveryString,
        ...(returnData.CustomBackwardDeliveryParameter
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameter,
          }
          : {}),
      };
      if (
        backObj.CargoType === 'Money' &&
        invoice.AdditionalServices?.backDelivery?.BackwardDeliveryData.ReceivingType === 'Card'
      ) {
        Object.assign(backObj, {
          Cash2CardPayout_Id: returnData.Cash2CardPayout_Id,
          Cash2CardAlias: returnData.Cash2CardAlias,
          Cash2CardPAN: returnData.Cash2CardPAN,
        });
        methodParams.Number = backDelivery.number;
        methodParams.Cash2Card = true;
      }
      methodParams.BackwardDeliveryData = [backObj];
    }

    const securePayment = invoice.AdditionalServices.securePayment;
    if (securePayment.BackwardDeliveryData) {
      const returnData = securePayment.BackwardDeliveryData;
      const backObj = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        RedeliveryString: returnData.RedeliveryString,
        Cash2CardPayout_Id: returnData.Cash2CardPayout_Id,
        Cash2CardAlias: returnData.Cash2CardAlias,
        Cash2CardPAN: returnData.Cash2CardPAN,
        ...(returnData.CustomBackwardDeliveryParameter
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameter,
          }
          : {}),
      };
      methodParams.BackwardDeliveryData = [backObj];
      methodParams.SecurePayment = true;
      methodParams.Number = securePayment.number;
    }

    const backDocuments = invoice.AdditionalServices?.backDocuments;
    if (backDocuments?.BackwardDeliveryData) {
      const backObj = {
        PayerType: backDocuments.BackwardDeliveryData.PayerType,
        CargoType: backDocuments.BackwardDeliveryData.CargoType,
        RedeliveryString: backDocuments.BackwardDeliveryData.RedeliveryString,
        ...(backDocuments.BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments
          ? {
            CustomBackwardDeliveryParameter:
            backDocuments.BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments,
          }
          : {}),
        ReceivingType: backDocuments.BackwardDeliveryData.ReceivingType,
        ReceivingData: backDocuments.BackwardDeliveryData.ReceivingData,
      };
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, backObj]
        : [backObj];
    }

    const customBackwardsDeliveryAddress = invoice.AdditionalServices?.customBackwardsDeliveryAddress;
    if (customBackwardsDeliveryAddress.selected) {
      methodParams.CustomReturnAddressRef =
        customBackwardsDeliveryAddress.BackwardDeliveryData.CustomBackwardDeliveryParameterCustomAddress || '';
    }

    if (invoice.AdditionalServices?.afterpayment.AfterpaymentOnGoodsCost) {
      methodParams.AfterpaymentOnGoodsCost = invoice.AdditionalServices?.afterpayment.AfterpaymentOnGoodsCost;
    }
    if (invoice.AdditionalServices?.forwardingCount.ForwardingCount) {
      methodParams.ForwardingCount = invoice.AdditionalServices?.forwardingCount.ForwardingCount;
    }

    if (invoice.AdditionalServices?.doorstepPickup?.DoorstepPickupData) {
      methodParams.NumberOfFloorsDescent =
        invoice.AdditionalServices?.doorstepPickup.DoorstepPickupData.NumberOfFloorsDescent;
    }

    if (invoice.AdditionalServices?.floorsLifting.FloorsLiftingData) {
      methodParams.NumberOfFloorsLifting =
        invoice.AdditionalServices?.floorsLifting.FloorsLiftingData.NumberOfFloorsLifting;
      methodParams.Elevator = invoice.AdditionalServices?.floorsLifting.FloorsLiftingData.Elevator ? '1' : '0';
    }
    if (invoice.AdditionalServices?.dateTime.DateTime) {
      methodParams.DateTime = moment(invoice.AdditionalServices?.dateTime.DateTime).format('DD.MM.YYYY');
    }
    if (invoice.AdditionalServices?.preferredDate.PreferredDeliveryDate) {
      methodParams.PreferredDeliveryDate = invoice.AdditionalServices?.preferredDate.PreferredDeliveryDate;
    }
    if (
      invoice.AdditionalServices?.preferredDate.PreferredDeliveryDate &&
      invoice.AdditionalServices?.preferredDate.TimeInterval
    ) {
      methodParams.TimeInterval = invoice.AdditionalServices?.preferredDate.TimeInterval;
    }

    if (invoice.AdditionalServices?.localExpress.selected) {
      methodParams.LocalExpress = '1';

      if (invoice.AdditionalServices?.localExpress.TimeInterval !== null) {
        methodParams.TimeInterval = invoice.AdditionalServices?.localExpress.TimeInterval === 1 ?
          '' :
          invoice.AdditionalServices?.localExpress.TimeInterval;
      }
    }

    if (invoice.AdditionalServices?.deliveryByHand.selected) {
      methodParams.DeliveryByHand = '1';
      methodParams.DeliveryByHandRecipients = invoice.AdditionalServices?.deliveryByHand.DeliveryByHandRecipients.map(
        (recipient) => {
          return `${recipient.surname || ''} ${recipient.name || ''} ${recipient.middleName || ''}`;
        },
      );
    }
    if (invoice.AdditionalServices?.signedDocuments.BackwardDeliveryData) {
      const returnData = invoice.AdditionalServices?.signedDocuments.BackwardDeliveryData;
      const backObj = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        RedeliveryString: returnData.RedeliveryString,
        ...(returnData.CustomBackwardDeliveryParameter
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameter,
          }
          : {}),
      };
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, backObj]
        : [backObj];
    }
    if (invoice.AdditionalServices?.differentDocuments.BackwardDeliveryData) {
      const returnData = invoice.AdditionalServices?.differentDocuments.BackwardDeliveryData;
      const data = {
        PayerType: returnData.PayerType,
        CargoType: returnData.CargoType,
        ...(returnData.CustomBackwardDeliveryParameterDifferentDocuments
          ? {
            CustomBackwardDeliveryParameter: returnData.CustomBackwardDeliveryParameterDifferentDocuments,
          }
          : {}),
      };
      const documents = returnData.documents;
      const services = {};
      for (const document of documents) {
        const docServices = document.services;
        Object.keys(docServices).map((key) => {
          const service = docServices[key];
          if (service.checked) {
            services[service.name] = service.checked;
          }
        });
      }
      (services as any).UserActions = returnData.UserActions;
      (data as any).Services = services;
      methodParams.BackwardDeliveryData = methodParams.BackwardDeliveryData
        ? [...methodParams.BackwardDeliveryData, data]
        : [data];
    }
    if (invoice.AdditionalServices?.packingService.PackingService && invoice.CargoType !== 'Documents') {
      if (invoice.commonParams) {
        methodParams.OptionsSeat = [
          {
            weight: invoice.commonParams.Weight,
            volumetricVolume: invoice.commonParams.VolumeGeneral,
            volumetricWeight: invoice.commonParams.VolumeWeight,
            packRef: invoice.AdditionalServices?.packingService.PackingService[0].packRef,
          },
        ];
      } else {
        methodParams.OptionsSeat = invoice.AdditionalServices?.packingService.PackingService.map((seat) => {
          if (invoice.CargoType === 'Cargo') {
            delete seat.Description;
            delete seat.Cost;
          }
          const seatNoVolume = JSON.parse(JSON.stringify(seat));
          seatNoVolume.volumetricVolume = '';
          return seatNoVolume;
        });
      }
    } else if (invoice.AdditionalServices?.packingService.PackingService && invoice.CargoType === 'Documents') {
      methodParams.OptionsSeat = invoice.AdditionalServices?.packingService.PackingService.map((seat) => {
        const seatNoVolume = JSON.parse(JSON.stringify(seat));
        if (!invoice.createTemplate) {
          seatNoVolume.volumetricVolume = '';
          seatNoVolume.volumetricLength = 20;
          seatNoVolume.volumetricWidth = 35;
          seatNoVolume.volumetricHeight = 5;
        }
        return seatNoVolume;
      });
    }
    if (!invoice.AdditionalServices?.packingService.PackingService) {
      methodParams.OptionsSeat?.map((seat) => delete seat.packRef);
    }

    if (invoice.AdditionalServices.pharma.selected) {
      methodParams.DeliveryPharma = true;
    }

    if (invoice.createTemplate && invoice.NewRecipientPrivateContact) {
      methodParams.RecipientsPhone = invoice.NewRecipientPrivateContact.phone;
    }
    return methodParams;
  }

  private isInternational(rawInvoice: any) {
    return rawInvoice.InternationalDeliveryType === 'Import' || rawInvoice.InternationalDeliveryType === 'Export';
  }

  private setAddressType(addressDescription: string) {
    return addressDescription.toLowerCase().indexOf('відділення') > -1
      ? 'Warehouse'
      : addressDescription.toLowerCase().indexOf('поштомат') > -1
        ? 'Postbox'
        : 'Address';
  }

  private coerceToNumber(el: any): number {
    return !isNaN(parseFloat(el)) && !!parseFloat(el) ? parseFloat(el) : 0;
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs';
import {Observable} from 'rxjs';
import Rxmq, {Channel} from 'rxmq';
import {Invoice} from '../../../../models/invoice/invoice.model';
import {PaymentInfo} from '../../../../models/acquiring/payment-info';
// import {$} from 'protractor';
import {AcquiringApiService} from './acquiring-api.service';
import {AlertToastService} from '../../../../services/alert-toast.service';

@Injectable({
  providedIn: 'root',
})

export class AcquiringService {
  constructor(
    private acquiringApiService: AcquiringApiService,
    private alertToastService: AlertToastService,
  ) {
    this.acquiringApiService = acquiringApiService;
  }

  getPaymentInfo(invoices: Invoice[]) {
    return this.acquiringApiService.getPaymentInfo(invoices);
  }

  pay(invoice: Invoice): Observable<PaymentInfo> | any {
    // return this.acquiringApiService.getPaymentInfo([invoice]).pipe(map((payInfo: any) => {
    //   const successPaid = false;
    //   const iframeId = `iframe${Date.now()}`;
    //   const fancyboxWindow: any = ($ as any).fancybox.open(
    //     [
    //       {
    //         type: 'html',
    //         content: `<div class="np-fancybox" style="position: relative; width: 80%; height: 1000px; padding: 0;">
    //       <a style="position: absolute" id="fancybox-close" ></a>
    //       <iframe style="width: 99%; height: 99%; border: 0; outline: 0"  name="${iframeId}" id="${iframeId}">
    //       </iframe>`,
    //       },
    //     ],
    //     {
    //       padding: 0,
    //       afterClose: () => {
    //         if (!successPaid) {
    //           Rxmq.channel<Channel<Invoice>, Invoice, Invoice>(
    //             'np-acquiring-service',
    //           )
    //             .subject('invoice-payment-is-not-complited')
    //             .next(invoice);
    //         }
    //       },
    //     },
    //   );
    //
    //   const $form = $(
    //     `
    //         <form style="width:0; heighy: 0; overflow: hidden" action="${payInfo.serviceAction}" target="${iframeId}" method="post">
    //         <input type="hidden" name="payee_id" value="${payInfo.payeeId}" />
    //         <input type="hidden" name="shop_order_number" value="${payInfo.shopOrderNumber}" />
    //
    //         <input type="hidden" name="bill_amount" value="${payInfo.billAmount}"/>
    //         <input type="hidden" name="description" value="${payInfo.description}"/>
    //         <input type="hidden" name="success_url"
    //         value="${payInfo.successUrl}" />
    //         <input type="hidden" name="failure_url"
    //         value="${payInfo.failureUrl}" />
    //         <input type="hidden" name="lang" value="${payInfo.lang}" />
    //         <input type="hidden" name="preauth_flag" value="${payInfo.preauthFlag}" />
    //         <input type=”hidden” name="encoding" value="${payInfo.encoding}" />
    //         <input type="submit" value="Оплатить" />
    //         </form>
    //         </div>
    //         `,
    //   );
    //
    //   $('body').append($form);
    //   $form.submit();
    //   const iframe: any = document.getElementById(iframeId) as HTMLIFrameElement;
    //   iframe.onmessage = function (event) {};
    //   iframe.onMessage = function (event) {};
    //   iframe.contentWindow.onMessage = function (event) {};
    //   iframe.contentWindow.onmessage = function (event) {};
    //   window.addEventListener('message', receiveMessage, false);
    //
    //   function receiveMessage(event) {
    //   }
    //
    //   iframe.onload = e => {
    //     try {
    //       if (
    //         'http://webclient.sb.np.ua/orders/index' ===
    //         iframe.contentWindow.location.href
    //       ) {
    //         fancyboxWindow.close();
    //         this.alertToastService.pushSuccess('Успішно оплачено!');
    //       } else {
    //         iframe.contentWindow.location.href;
    //       }
    //     } catch (e) {
    //       // console.warn(e);
    //     }
    //   };
    //
    //   return payInfo;
    // }));
  }

  cancelPayment(docNumbers: string[]): Observable<string[]> {
    return this.acquiringApiService.cancelPayment(docNumbers);
  }
}

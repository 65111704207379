<app-date-filter [config]="config.fromFilter"
                 [controlName]="config.fromFilter.controlName"
                 [form]="form"
                 [class.sm]="sm"></app-date-filter>

<span class="filter-separator">—</span>

<app-date-filter [config]="config.toFilter"
                 [controlName]="config.toFilter.controlName"
                 [form]="form"
                 [class.sm]="sm"></app-date-filter>

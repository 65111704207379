import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressTypes, IListData } from '@models';
import { GetCitiesData, GetCountriesData, GetGoodsClassifierData, GetWarehousesData, WarehouseCategory } from '@shared';
import * as moment from 'moment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { normalizeCountry } from '../components/invoice-form/containers/international-invoice/countries/country.utils';
import { Invoice } from '../models/invoice/invoice.model';
import { ApiMethods } from './api/apiMethods';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class InternationalInvApiService {
  private toggle = new BehaviorSubject<boolean>(false);

  $toggle = this.toggle.asObservable();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {}

  setToggle(val: boolean) {
    this.toggle.next(val);
  }

  saveContact(contact): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'saveRecipient',
      methodProperties: this._contactToMethodParams(contact),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getCities(countryRef: string, name: string): Observable<GetCitiesData[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'International',
      calledMethod: 'getCities',
      methodProperties: {
        Country: countryRef,
        FindByString: name,
        Page: '1',
        Limit: '200',
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getGoodsClassifier(query: string, page = 1, limit = 15): Observable<GetGoodsClassifierData[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'International',
      calledMethod: 'getGoodsClassifier',
      methodProperties: {
        FindByString: query,
        Page: page,
        Limit: limit,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getWarehouses(
    countryRef: string,
    cityName: string,
    warehouseCategory: WarehouseCategory,
    CounterpartyRef?: string,
  ): Observable<GetWarehousesData[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'International',
      calledMethod: 'getWarehouses',
      methodProperties: {
        Country: countryRef,
        City: cityName,
        WarehouseCategory: warehouseCategory,
        ...(CounterpartyRef ? { CounterpartyRef } : {}),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  searchRecipientByPhone(phone: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getRecipients',
      methodProperties: {
        Phone: phone,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getRecipientByRef(ref: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getRecipients',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getCountries(ref?): Observable<GetCountriesData[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'International',
      calledMethod: 'getCountries',
      methodProperties: {
        Ref: ref,
        Limit: 300,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => this.filterCountries(response.data.data)),
      map((countries) => this.normalizeCountries(countries)),
    );
  }

  getUSStates(searchBy?: 'Code' | 'FindByString', query?: string) {
    const body = {
      modelName: 'InternationalGeneral',
      calledMethod: 'getStatesOfAmerica',
      methodProperties: searchBy ? { [searchBy]: query } : {},
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getProvinciesOfCanada(searchBy?: 'Code' | 'FindByString', query?: string) {
    const body = {
      modelName: 'InternationalGeneral',
      calledMethod: 'getProvinciesOfCanada',
      methodProperties: searchBy ? { [searchBy]: query } : {},
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getIncoterms(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getIncoterms',
      methodProperties: {
        Limit: 300,
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getCurrency(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getCurrencies',
      methodProperties: {
        Limit: 1000,
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getUnits(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getUnits',
      methodProperties: {
        Limit: 300,
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getExportTarget(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getExportReasons',
      methodProperties: {
        Limit: 100,
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getCargoTypes(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getShipmentTypes',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getDeliveryTypes(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getDeliveryTypes',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getInvoice(ref): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getDocument',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
    );
  }

  updateInvoice(invoice): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'update',
      methodProperties: this.mapFormToApi(invoice),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  deleteInvoice(ref): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'delete',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getRecipientWarhouses(code: string, warehouse?: string): Observable<any> {
    const postObject = {
      system: 'MobileApp',
      modelName: 'International',
      calledMethod: ApiMethods.getWarehouses,
      methodProperties: {
        WarehouseNumber: warehouse,
        CountryCode: code,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getInvoicesList(state): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getDocumentList',
      methodProperties: {
        GetFullList: 1,
        State: state,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => this.bindListToModel(response)),
    );
  }

  bindListToModel(response: any): IListData<Invoice> {
    return {
      list: response.data.data,
      total: response.data.data.length,
    };
  }

  translateText(text: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'translateText',
      methodProperties: {
        Text: text,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  currencyRate(code?: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getCurrencyRates',
      methodProperties: {
        Date: moment().format('DD.MM.YYYY'),
        Code: code,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getDocumentPrice(form): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getDocumentPrice',
      methodProperties: this._priceToMethodParams(form),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  createIntInvoice(invoice): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'save',
      methodProperties: this.mapFormToApi(invoice),
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getPayersTypes(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getPayerTypes',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  delete(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'deleteRecipient',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getPaymentTypes(ref?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'InternationalGeneral',
      calledMethod: 'getPaymentTypes',
      methodProperties: {
        Ref: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  getPictures(ref) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'International',
      calledMethod: 'getFileForExpressWaybill',
      methodProperties: {
        DocumentRef: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data),
    );
  }

  public _contactToMethodParams(contact): any {
    const methodParams: any = {};
    methodParams.Phone = contact.RecipientContacts.phone;
    methodParams.Type = contact.RecipientCounterpartyType;
    methodParams.Email = contact.RecipientContacts.email;
    methodParams.ContactPerson = contact.translits.recipientFirstName + ' ' + contact.translits.recipientLastName;
    methodParams.ZipCode = contact.AddressRecipient.index;
    methodParams.CountryRef = contact.AddressRecipient.country.Ref;
    methodParams.CityDescription = contact.translits.recipientCity;
    const state = contact.AddressRecipient.state ? `${contact.AddressRecipient.state.Code}; ` : '';
    methodParams.AddressDescription =
      state + contact.translits.recipientStreet + '; ' + contact.translits.recipientBuilding + '; ';
    if (contact.RecipientCounterpartyType === 'Organization') {
      methodParams.Description = contact.translits.organization;
    } else {
      methodParams.Description = 'Private Person';
    }
    return methodParams;
  }

  translit(text: string): string {
    if (typeof text !== 'string' || text.length < 1) {
      return text;
    }
    const dictionary = [
      { ua: 'зг', en: 'zgh' },
      { ua: 'Зг', en: 'Zgh' },

      { ua: "'", en: '' },
      { ua: '`', en: '' },
      { ua: 'а', en: 'a' },
      { ua: 'А', en: 'A' },
      { ua: 'б', en: 'b' },
      { ua: 'Б', en: 'B' },
      { ua: 'в', en: 'v' },
      { ua: 'В', en: 'V' },
      { ua: 'г', en: 'h' },
      { ua: 'Г', en: 'H' },
      { ua: 'ґ', en: 'g' },
      { ua: 'Ґ', en: 'G' },
      { ua: 'д', en: 'd' },
      { ua: 'Д', en: 'D' },
      { ua: 'е', en: 'e' },
      { ua: 'Е', en: 'E' },
      { ua: 'ж', en: 'zh' },
      { ua: 'Ж', en: 'Zh' },
      { ua: 'з', en: 'z' },
      { ua: 'З', en: 'Z' },
      { ua: 'и', en: 'y' },
      { ua: 'И', en: 'Y' },
      { ua: 'й', en: 'i' },
      { ua: 'Й', en: 'Y' },
      { ua: 'к', en: 'k' },
      { ua: 'К', en: 'K' },
      { ua: 'л', en: 'l' },
      { ua: 'Л', en: 'L' },
      { ua: 'м', en: 'm' },
      { ua: 'М', en: 'M' },
      { ua: 'н', en: 'n' },
      { ua: 'Н', en: 'N' },
      { ua: 'о', en: 'o' },
      { ua: 'О', en: 'O' },
      { ua: 'п', en: 'p' },
      { ua: 'П', en: 'P' },
      { ua: 'р', en: 'r' },
      { ua: 'Р', en: 'R' },
      { ua: 'с', en: 's' },
      { ua: 'С', en: 'S' },
      { ua: 'т', en: 't' },
      { ua: 'Т', en: 'T' },
      { ua: 'у', en: 'u' },
      { ua: 'У', en: 'U' },
      { ua: 'ф', en: 'f' },
      { ua: 'Ф', en: 'F' },
      { ua: 'х', en: 'kh' },
      { ua: 'Х', en: 'Kh' },
      { ua: 'ц', en: 'ts' },
      { ua: 'Ц', en: 'Ts' },
      { ua: 'ч', en: 'ch' },
      { ua: 'Ч', en: 'Ch' },
      { ua: 'ш', en: 'sh' },
      { ua: 'Ш', en: 'Sh' },
      { ua: 'щ', en: 'shch' },
      { ua: 'Щ', en: 'Shch' },
      { ua: 'ъ', en: '' },
      { ua: 'Ъ', en: '' },
      { ua: 'ы', en: 'y' },
      { ua: 'Ы', en: 'Y' },
      { ua: 'ь', en: '' },
      { ua: 'Ь', en: '' },
      { ua: 'э', en: 'e' },
      { ua: 'Э', en: 'E' },
      { ua: 'ю', en: 'iu' },
      { ua: 'Ю', en: 'Yu' },
      { ua: 'я', en: 'ia' },
      { ua: 'Я', en: 'Ya' },
      { ua: 'і', en: 'i' },
      { ua: 'І', en: 'I' },
      { ua: 'ї', en: 'i' },
      { ua: 'Ї', en: 'Yi' },
      { ua: 'є', en: 'ie' },
      { ua: 'Є', en: 'Ye' },
    ];
    let result = text;
    dictionary.map((el) => {
      const reg = new RegExp(el.ua, 'g');
      result = result.replace(reg, el.en);
    });
    return result;
  }

  getSenderAddress(invoice): string {
    return invoice.NewSenderAddress?.Ref || invoice.SenderAddress.data.Ref || invoice.SenderAddress.data.warehouse.Ref;
  }

  getSenderRegistrationAddress(invoice): string {
    return invoice.NewSenderRegistrationAddress?.Ref || invoice.SenderRegistrationAddress?.data.Ref;
  }

  private filterCountries(countries: GetCountriesData[]): GetCountriesData[] {
    return countries.filter((c) => c.Code !== 'RU').filter((c) => c.Code !== 'BY');
  }

  private normalizeCountries(countries: GetCountriesData[]): GetCountriesData[] {
    return countries.map(normalizeCountry);
  }

  private _priceToMethodParams(post): any {
    const methodParams: any = {};
    methodParams.CounterpartySender = post.ContactSender.data.CounterpartyRef ?? post.ContactSenderOrg.Ref;
    methodParams.PickupCountry = 'UA';
    methodParams.DeliveryCountry = post.AddressRecipient.country.Code;
    methodParams.Promocode = '';

    const addressType = post.AddressRecipient.Address.type;
    const senderAddressType = post.SenderAddress.type;

    if (addressType === AddressTypes.WAREHOUSE || addressType === AddressTypes.POSTBOX) {
      methodParams.ServiceType = `${senderAddressType}Warehouse`;
    } else {
      methodParams.ServiceType = `${senderAddressType}Doors`;
    }

    methodParams.DeliveryType = post.type.DeliveryType;
    methodParams.ShipmentType = post.CargoType.Ref;
    methodParams.Parcels = [];
    post.OptionsSeat.forEach((option) => {
      methodParams.Parcels.push({
        Length: option.volumetricLength,
        Height: option.volumetricHeight,
        Width: option.volumetricWidth,
        ActualWeight: option.weight,
      });
    });
    return methodParams;
  }

  private mapFormToApi(invoice): any {
    const methodParams: any = {};
    if (invoice.ref) {
      methodParams.Ref = invoice.ref;
    }

    if (invoice.Sender) {
      methodParams.CounterpartySender = invoice.Sender.CounterpartyRef;
      methodParams.CounterpartySenderTranslit = invoice.editTranslit.translateCounterpartyName || '';
      methodParams.ContactPersonSender = invoice.Sender.Ref;
      methodParams.ContactPersonSenderTranslit = invoice.editTranslit.editedName ?? invoice.translits?.senderName;
      methodParams.PhoneSender = invoice.Sender.Phones;
    } else {
      methodParams.CounterpartySender = invoice.ContactSender.data.CounterpartyRef;
      methodParams.CounterpartySenderTranslit = invoice.editTranslit.translateCounterpartyName || '';
      methodParams.ContactPersonSender = invoice.ContactSender.data.Ref;
      methodParams.ContactPersonSenderTranslit = invoice.editTranslit.editedName ?? invoice.translits?.senderName;
      methodParams.PhoneSender = invoice.ContactSender.data.Phones;
    }

    methodParams.EmailSender = invoice.SenderEmail;
    if (invoice.SenderIOSS) {
      methodParams.IOSSNumber = invoice.SenderIOSS;
    }

    // region sender address
    methodParams.SenderAddressRef = this.getSenderAddress(invoice);
    if (this.getSenderRegistrationAddress(invoice)) {
      methodParams.SenderAddressRegistrationRef = this.getSenderRegistrationAddress(invoice);
    }
    // endregion sender address

    methodParams.DeliveryType = invoice.DeliveryTypes.type.DeliveryType || invoice.DeliveryTypes.type.Ref;

    // region recipient
    if (invoice.RecipientContacts.recipientRef && invoice.recipientRef === undefined) {
      methodParams.CounterpartyRecipientRef = invoice.RecipientContacts.recipientRef;
    } else {
      methodParams.CounterpartyRecipientRef = invoice.recipientRef;
    }

    if (invoice.AddressRecipient.country.RecipientRegistrationAddressRequired) {
      if (invoice.AddressRecipient.Address.type === AddressTypes.DOORS) {
        methodParams.RegistrationAddressRecipient = [
          {
            State: '',
            ZipCode: invoice.AddressRecipient.index,
            City: invoice.translits.recipientCity,
            Street: invoice.translits.recipientStreet,
            Building: invoice.translits.recipientBuilding,
            Apartment: invoice.translits.recipientRoom,
          },
        ];
      } else {
        methodParams.RegistrationAddressRecipient = [
          {
            State: '',
            ZipCode: invoice.RegistrationAddressRecipient.index,
            City: invoice.translits.recipientPostalAddressCity,
            Street: invoice.translits.recipientPostalAddressStreet,
            Building: invoice.translits.recipientPostalAddressBuilding,
            Apartment: invoice.translits.recipientPostalAddressRoom,
          },
        ];
      }
    }

    methodParams.CounterpartyRecipientLastName = invoice.translits?.recipientLastName;
    methodParams.CounterpartyRecipientFirstName = invoice.translits.recipientFirstName;

    // Phone and email
    methodParams.PhoneRecipient = invoice.RecipientContacts.phone.replace(/\D/gi, '');
    methodParams.EmailRecipient = invoice.RecipientContacts.email;

    methodParams.CompanyTINRecipient = invoice.RecipientInfo.RecipientNIP;

    methodParams.CounterpartyTypeRecipient = invoice.RecipientCounterpartyType;

    if (invoice.RecipientCounterpartyType === 'Organization') {
      methodParams.CounterpartyRecipient = invoice.translits.organization;
    }

    // region recipientAddress
    if (invoice.AddressRecipient.state) {
      methodParams.RegionRecipientCode = invoice.AddressRecipient.state.Code;
    }

    // region CASE DOORS
    if (invoice.AddressRecipient.Address.type === AddressTypes.DOORS) {
      methodParams.CountryRecipient = invoice.AddressRecipient.country.Ref;
      methodParams.ZipCodeRecipient = invoice.AddressRecipient.index;
      methodParams.CityRecipient = invoice.translits.recipientCity;
      methodParams.CounterpartyRecipientStreet = invoice.translits.recipientStreet;
      methodParams.CounterpartyRecipientHouse = invoice.translits.recipientBuilding;
      methodParams.CounterpartyRecipientApart = invoice.translits.recipientRoom;
      methodParams.AdditionalInfoRecipient = invoice.translits.recipientInfo;
    }
    // endregion CASE DOORS

    // region CASE WAREHOUSE
    if (invoice.AddressRecipient.Address.type === AddressTypes.WAREHOUSE) {
      methodParams.CountryRecipient = invoice.AddressRecipient.country.Ref;
      methodParams.CityRecipient = invoice.translits.recipientCity;
      methodParams.WarehouseRecipient = invoice.AddressRecipient.Address.data.warehouse.WarehouseRef;
    }
    // endregion CASE WAREHOUSE

    // region CASE POSTBOX
    if (invoice.AddressRecipient.Address.type === AddressTypes.POSTBOX) {
      methodParams.CountryRecipient = invoice.AddressRecipient.country.Ref;
      methodParams.CityRecipient = invoice.translits.recipientCity;
      methodParams.WarehouseRecipient = invoice.AddressRecipient.Address.data.postbox.WarehouseRef;
    }
    // endregion CASE POSTBOX

    // if (invoice.DeliveryTypes.warehouse) {
    //   methodParams.WarehouseRecipient =
    //     invoice.DeliveryTypes.warehouse.WarehouseRef;
    // } else {
    //   methodParams.WarehouseRecipient = '';
    // }
    // endregion recipientAddress
    // endregion recipient

    methodParams.TypeOfShipment = invoice.CargoType.Ref;
    methodParams.CargoDescription = invoice.translits.parcelDescription;
    methodParams.PlacesAmount = invoice.OptionsSeat.length;
    methodParams.ExportReason = invoice.ExportTarget.target.Ref;
    if (invoice.ExportTarget.additionalInfo) {
      methodParams.AdditionalInformationExportReason = invoice.additionalExportInfo.additionalInfoTranslit;
    } else {
      methodParams.AdditionalInformationExportReason = '';
    }
    methodParams.PayerType = invoice.Payer.Ref;
    methodParams.PaymentMethod = invoice.PaymentType.Ref;

    methodParams.Places = invoice.OptionsSeat.map((option) => {
      return {
        ActualWeight: option.weight,
        Width: option.volumetricWidth,
        Length: option.volumetricLength,
        Height: option.volumetricHeight,
        VolumetricWeight: option.volumetricVolume,
      };
    });
    if (invoice.ParcelPlaces) {
      methodParams.Invoices = invoice.ParcelPlaces.map((place) => {
        const Note = `${place.description.SubCategoryDescription}: ${place.description.ProductDescription}`;
        return {
          Note,
          CodeUKTZED: place.description.HsCode,
          Material: place.materials,
          Country: place.country.Ref,
          Cost: place.cost,
          Currency: place.currency.Code || 'UAH',
          MeasurementsUnit: place.units.Ref,
          Amount: place.amount,
        };
      });
    }
    if (invoice.InvoiceCheckbox === true) {
      methodParams.ClientInvoice = '1';
    } else {
      methodParams.ClientInvoice = '0';
    }
    invoice.DocumentUploader?.forEach((doc) => {
      if (doc.name !== '') {
        methodParams.Files = invoice.DocumentUploader.map((document) => {
          return {
            string: document.string,
            extension: document.extension,
          };
        });
      }
    });
    methodParams.Currency = invoice.InvoiceInformation.currency.Ref;
    methodParams.Incoterm = invoice.InvoiceInformation.conditions.Ref;
    methodParams.Cost = invoice.InvoiceInformation.totalCost;

    return methodParams;
  }
}

export const NameErrors = {
  pattern: 'Містить недопустимий символ',
  required: 'Це поле обов\'язкове',
  maxlength: 'Максимальна довжина 25 символів',
  minlength: 'Мінімальна довжина 2 символи',
  firstCharNotUp: 'Має починатись з великої літери',
  hasDoubleApostrophe: 'Містить подвійний апостроф',
  hasDoubleDots: 'Містить подвійну крапку',
  hasDoubleSpaces: 'Містить подвійний пробіл',
  hasDoubleHyphen: 'Містить подвійний дефіс',
  hasInvalidLastChar: 'Містить неправильний останній символ',
  hasInvalidFirstChar: 'Містить неправильний символ в першій літері',
  hasInvalidCharacters: 'Містить неправильні символи або латиницю',
};
export const SurnameErrors = NameErrors;
export const MiddleNameErrors = NameErrors;
export const EmailErrors = {
  required: 'Це поле обов\'язкове',
  maxlength: 'Максимальна довжина 100 символів',
  hasDoubleDots: 'Містить подвійну крапку',
  invalidEmail: 'Email вказано невірно',
};
export const PhoneErrors = {
  required: 'Це поле обов\'язкове',
  minlength: 'Занадто короткий номер',
};
export const PasswordErrors = {
  required: 'Це поле обов\'язкове',
  invalidPassword: '',
  notEqual: 'Паролі не співпадають',
};

export const LoginErrors = {
  required: 'Це поле обов\'язкове',
};

export const EmptyField = {
  required: 'Це поле обов\'язкове',
};

export const EDRPOUErrors = {
  required: 'Це поле обов\'язкове',
  minlength: 'Занадто короткий номер',
  maxlength: 'Занадто довгий номер',
};

export const SmsCodeErrors = {
  required: 'Це поле обов\'язкове',
};

export const ContactInfoErrors = {
  pattern: 'Введено недопустимі символи',
  maxlength: 'Максимальна довжина 100 символів',
};

export const CityErrors = {
  objectMask: 'Оберіть населений пункт зі списку',
  pattern: 'Це поле має містити хоча б одну латинську літеру чи кирилицю',
  required: 'Це поле обов\'язкове',
};

export const GetGoodsClassifierErrors = {
  minlength: 'Мінімальна довжина 3 символи',
  objectMask: 'Оберіть позицію зі списку',
  pattern: 'Це поле має містити хоча б одну латинську літеру чи кирилицю',
  required: 'Це поле обов\'язкове',
};

export const StreetErrors = {
  objectMask: 'Оберіть вулицю зі списку',
  pattern: 'Це поле має містити хоча б одну латинську літеру чи кирилицю',
  required: 'Це поле обов\'язкове',
};

export const BuildingErrors = {
  required: 'Це поле обов\'язкове',
};

export const RoomErrors = {
  required: 'Це поле обов\'язкове',
};

export const AddressNoteErrors = {
  required: 'Це поле обов\'язкове',
  pattern: 'Містить недопустимий символ',
};

export const WarehouseErrors = {
  objectMask: 'Оберіть відділення зі списку',
  required: 'Це поле обов\'язкове',
};

export const PostboxErrors = {
  objectMask: 'Оберіть поштомат зі списку',
  required: 'Це поле обов\'язкове',
};

export const DocumentErrors = {
  mask: 'Введіть, будь ласка, дійсний № накладної',
  wrongSuffix: 'Номер накладної починається з \'1\', \'5\', \'2\'',
};

export const AddressIndexErrors = {
  objectMask: 'Оберіть значення',
  required: 'Це поле обов\'язкове',
  postboxUnavailable: 'Параметри відправлення не задовільняють умови для відправки з поштомату',
};

export const AutocompleteErrors = {
  required: 'Це поле обов\'язкове',
  objectMask: 'Оберіть зі списку',
  address: 'Оберіть зі списку',
};

export const CompanyVATCodeErrors = {
  required: 'Це поле обов\'язкове',
  minlength: 'Індивідуальний податковий номер платника ПДВ повинен містити 10-12 цифр',
  lengthNotEqual: 'Індивідуальний податковий номер платника ПДВ повинен містити 10-12 цифр',
};

export const CompanyVATStatusErrors = {
  required: 'Це поле обов\'язкове',
  objectMask: 'Оберіть значення зі списку',
};

export const PaymentAccountErrors = {
  required: 'Це поле обов\'язкове',
};

export const BankNameErrors = {
  required: 'Це поле обов\'язкове',
};

export const SortCodeErrors = {
  required: 'Це поле обов\'язкове',
};

export const BankDescriptionErrors = {
  noOptions: 'Будь ласка, спробуйте знову, або введiть данi вручну',
  objectMask: 'Оберіть значення зі списку',
  required: 'Це поле обов\'язкове',
  minlength: 'Мінімальна довжина 3 символи. Спробуйте знову, або введiть данi вручну',
};

export const BirthDateErrors = {
  required: 'Це поле обов\'язкове',
};

export const TimeIntervalErrors = {
  required: 'Це поле обов\'язкове',
};

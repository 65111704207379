import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {AbstractFormContainer} from '@shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-address-recipient-note',
  templateUrl: './address-recipient-note.component.html',
  styleUrls: ['./address-recipient-note.component.scss'],
})

export class AddressRecipientNoteComponent extends AbstractFormContainer implements OnInit {

  @Input() controlName = 'Note';

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    const noteControl = new UntypedFormControl('', Validators.maxLength(40));
    this.form.addControl(this.controlName, noteControl);
  }
}

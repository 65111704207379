<div class="confirm">
  <div class="confirm__head">
    <span class="modal__close ngdialog-close"
          (click)="$event.stopPropagation(); dialogRef.close()">
    <i class="material-icons">close</i>
    </span>
    <div class="confirm__title">Поділитись</div>
  </div>
  <form [formGroup]="shareForm">
    <div class="confirm__content confirm__content_ptop-none">
      <mat-form-field *ngIf="smsClubToken">
        <mat-select placeholder="Відправка СМС від альфа-імені"
                    formControlName="alphaName">
          <mat-option *ngFor="let name of alphaNames" [value]="name">{{name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Шаблон повідомлення"
                    (selectionChange)="changeTemplate($event.value)"
                    formControlName="name">
          <mat-option *ngFor="let template of templates" [value]="template.name">{{template.name}}</mat-option>
          <mat-option disabled class="select-option-hint">
            Управляти шаблонами можна у розділі <br>
            “Налаштування” — “Інформування”
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
         <textarea matInput
                   (input)="setCurrentTextParams(content)"
                   class="textarea-sms"
                   formControlName="content"
                   placeholder="Текст повідомлення"></textarea>
      </mat-form-field>
      <div class="template-buttons">
        <button type="button"
                (click)="translit()"
                mat-button>
          транслітерація
        </button>
        <span class="link-grey">Розмір: {{content.length}}/{{currentMaxLetters}} ({{currentSendMessagesCount}} СМС)</span>
      </div>
    </div>
    <div class="confirm__buttons" *ngIf="smsClubToken">
      <button type="submit"
              (click)="sendMessage()"
              mat-button
              color="primary">
        Надіслати в смс
      </button>
    </div>
    <div class="confirm__buttons" *ngIf="!smsClubToken">
      <div>
        <button type="button"
                (click)="copyTemplateContent()"
                mat-button>
          скопіювати текст
        </button>
      </div>
      <div>
        <button type="button"
                (click)="goSettingsSmsService()"
                mat-button
                color="primary">
          підключити смс-інформування
        </button>
      </div>
    </div>
  </form>
</div>

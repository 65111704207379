import { Model } from './base.model';
/**
 * Created by Ruslan on 25.08.2016.
 */

export class Schedule extends Model {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AutocompleteControlsTriggeredEvent,
  AutocompleteMode,
  AutoCompleteOptionItem,
  AutoCompleteTransformFn,
} from '@autocomplete-with-controls';
import {
  AddressType,
  AddressTypes,
  AutocompleteControlTriggerName,
  DoorAddress,
  TypedFormGroup,
  WarehouseAddress,
} from '@models';
import {
  EditableAutocompleteControl,
  FormContainer,
  getAddressType,
  TypedFormControl,
  ValidateAddress,
} from '@shared';
import { CheckPossibilityCreateReturnData } from '../../back-invoices-form/return-request.interfaces';
import { Label } from '../address-by-index/address-by-index.constants';
import {
  ADDRESS_TYPES,
  AUTOCOMPLETE_CONFIG,
  AUTOCOMPLETE_CONTROLS_CONFIG,
  CONTROL_NAME,
  CONTROL_TYPE_NAME,
} from './address-block.constants';
import { ControlModes } from './address-block.enums';

export type AddressMode = AutocompleteControlTriggerName | 'select';

@Component({
  selector: 'app-address-block',
  templateUrl: 'address-block.component.html',
  styleUrls: ['./address-block.component.scss'],
})
export class AddressBlockComponent
  extends FormContainer
  implements EditableAutocompleteControl, TypedFormControl, OnInit, OnDestroy {
  AutocompleteMode = AutocompleteMode;
  AddressTypes = AddressTypes;

  @Input() controlName = CONTROL_NAME;
  @Input() controlTypeName = CONTROL_TYPE_NAME;
  @Input() config = AUTOCOMPLETE_CONFIG;
  @Input() controlsConfig = AUTOCOMPLETE_CONTROLS_CONFIG;
  @Input() types = ADDRESS_TYPES;
  @Input() showFlat = false;
  @Input() filterPostboxes = false;
  @Input() forSender = false;
  @Input() addressByIndexLabel: string = Label;

  ControlModes = ControlModes;

  formGroup: UntypedFormGroup;

  private _options: Array<AutoCompleteOptionItem<DoorAddress | WarehouseAddress>> = [];

  @Input() set options(data) {
    this._options = data;
    if (!data?.length) {
      this.type = this.types[0].value;
      this.setCreateMode();
    }
    if (data?.length > 0) {
      this.createControl(data[0].option);
      this.setSelectMode();
    }
  }

  setSelectMode(): void {
    this.mode = AutocompleteMode.SELECT;
  }

  setCreateMode(): void {
    this.mode = AutocompleteMode.CREATE;
  }

  get options(): Array<AutoCompleteOptionItem<any>> {
    return this._options;
  }

  get control(): UntypedFormGroup {
    return this.form?.get(this.controlName) as UntypedFormGroup;
  }

  constructor(private fb: UntypedFormBuilder, public router: Router) {
    super();
  }

  private _mode: AddressMode = null;

  get mode(): AddressMode {
    return this._mode;
  }

  @Input()
  set mode(value: AddressMode) {
    this._mode = value;
    if (this.parentForm) {
      this.parentForm.get('mode').setValue(value);
    }
  }

  set type(type: AddressType) {
    if (this.parentForm) {
      this.parentForm.get('type').setValue(type, { emitEvent: false });
    }
  }

  get parentForm(): AbstractControl | null {
    return this.form.get(this.controlName);
  }

  get activeType(): AddressType | null {
    return this.parentForm.get(this.controlTypeName).value;
  }

  set activeType(value: AddressType | null) {
    this.parentForm
      .get(this.controlTypeName)
      .setValue(value, { emitEvent: false });
  }

  setActiveTypeFromObject(value: object): void {
    this.activeType = getAddressType(value);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addFormControls(): void {
    if (!this.form.get(this.controlName)) {
      this.createControl(!!this.options?.length ? this.options[0].option : '');
    }
  }

  createControl(defaultValue: DoorAddress | WarehouseAddress | ''): void {
    this.formGroup = this.fb.group({
      mode: [defaultValue ? AutocompleteMode.SELECT : AutocompleteMode.CREATE],
      type: [
        defaultValue ? getAddressType(defaultValue) : AddressTypes.WAREHOUSE,
      ],
      data: [
        { value: defaultValue, disabled: this.options?.length === 1 },
        Validators.compose([Validators.required, ValidateAddress]),
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);
  }

  setSubscriptionActiveType() {
    this.form
      .get(this.controlName)
      .get(this.ControlModes.DATA)
      .valueChanges.subscribe((data) => {
      this.setActiveTypeFromObject(data);
    });
  }

  transformFn: AutoCompleteTransformFn<CheckPossibilityCreateReturnData> = (
    value: CheckPossibilityCreateReturnData,
  ) => {
    if (value === null) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    const type = getAddressType(value);
    switch (type) {
      case AddressTypes.WAREHOUSE:
      case AddressTypes.DOORS:
        return this.createDoorsDescription(value);
      default:
        return value.Address;
    }
  };

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    this.mode = event.triggerName;
    if (this.mode === AutocompleteMode.CREATE) {
      this.activeType = 'Warehouse';
    } else {
      const { data } = this.parentForm.value as TypedFormGroup;
      this.setActiveTypeFromObject(data);
    }
  }

  cancel(): void {
    this.createControl(this.options.length ? this.options[0].option : '');
    this.setSelectMode();
  }

  clear(): void {
    this.changeMode({ triggerName: AutocompleteMode.CREATE });

    this.form.get(this.controlName).reset({
      mode: AutocompleteMode.CREATE,
      type: 'Warehouse',
      data: {
        city: '',
        warehouse: '',
      },
    });
  }

  createWarehouseDescription(address: WarehouseAddress): string {
    if (!address) {
      return '';
    }
    return `${address.CityDescription}, ${address.AddressDescription}`;
  }

  createDoorsDescription(address: CheckPossibilityCreateReturnData): string {
    return address.Address;
  }
}

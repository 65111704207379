import { Directive, HostListener, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import {AlertToastService} from '../../../services/alert-toast.service';

@Directive({
  selector: '[appCopy]',
  exportAs: 'appCopy',
})
export class CopyClipboardDirective {

  @Input('appCopy')
  textToCopy: string;

  constructor(private alertToastService: AlertToastService,
              private cbService: ClipboardService) {
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.copyText(this.textToCopy || '');
  }

  copyText(value: string): void {
    this.cbService.copyFromContent(this.formatContent(value));
    this.alertToastService.pushSuccess(this.formatSuccessMessage());
  }

  formatContent(value: string): string {
    return value;
  }

  formatSuccessMessage(): string {
    return 'Скопійовано';
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Rxmq, { Channel } from 'rxmq';
import { IApiError } from '../../../models/iapi';
import { AlertToastService } from '../../../services/alert-toast.service';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { EVENT_NAMES } from '../../constants';
import { AnalyticEvent } from '../../interfaces/analytic-event';

const componentName = 'app-printing-setting';

@Component({
  selector: componentName,
  templateUrl: './printing-setting.component.html',
  styleUrls: ['./printing-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingSettingComponent implements OnInit {
  isPending = false;

  value: string;

  printFormat = [
    { value: 'Marking6', viewValue: 'Маркування на листі А4 (6 наклейок)' },
  ];

  amounts = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
    { value: '9', viewValue: '9' },
    { value: '10', viewValue: '10' },
  ];

  markupItems = new Array(8);

  selectedMarkup = 1;

  selectedCopies = '1';

  selectedFormat = 'Marking8';

  selectedType = 'pdf';

  checked = true;

  orientations = [
    {
      value: 'landscape',
      label: 'Горизонтальний друк',
    },
    {
      value: 'portrait',
      label: 'Вертикальний друк',
    },
  ];

  selectedOrientation = 'landscape';

  invoiceIds = [];

  constructor(
    private alertToastService: AlertToastService,
    private dialogRef: MatDialogRef<PrintingSettingComponent>,
    public invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    if (this.value === 'Marking8') {
      this.selectedFormat = 'Marking8';
      this.markupItems.length = 8;
      this.checked = true;
    } else if (this.value === 'Marking6') {
      this.selectedFormat = 'Marking6';
      this.checked = true;
      this.markupItems.length = 6;
    }
  }

  print(): void {
    this.isPending = true;
    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '' + this.selectedMarkup,
      Copies: this.selectedCopies,
    };

    let type;
    if (this.selectedFormat === 'Marking8') {
      props.printForm = 'Marking';
      type = 'pdf8';
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        EVENT_NAMES.ANALYTICS,
      )
        .subject(`app-printing.print`)
        .next({
          eventLabel: `Друк маркування на листі А4 (8 наклейок)`,
          eventCategory: 'app-printing',
          eventAction: 'click',
        });
    } else if (this.selectedFormat === 'Marking6') {
      props.printForm = 'Marking_85x85';
      type = 'pdf8';
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        EVENT_NAMES.ANALYTICS,
      )
        .subject(`app-printing.print`)
        .next({
          eventLabel: `Друк маркування на листі А4 (6 наклейок)`,
          eventCategory: 'app-printing',
          eventAction: 'click',
        });
    } else {
      props.PageFormat = this.selectedFormat;
      type = this.selectedType;
      if (this.selectedFormat === 'A4') {
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print`)
          .next({
            eventLabel: `Друк експрес посилки А4`,
            eventCategory: 'app-printing',
            eventAction: 'click',
          });
      } else if (this.selectedFormat === 'A5') {
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print`)
          .next({
            eventLabel: `Друк експрес посилки А5`,
            eventCategory: 'app-printing',
            eventAction: 'click',
          });
      }
    }
    this.invoiceService.printDocuments(this.invoiceIds, type, props).subscribe(
      () => {
        this.isPending = false;
        this.dialogRef.close({ success: true });
        if (this.selectedFormat === 'Marking8') {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print`)
            .next({
              eventLabel: `Друк маркування на листі А4 (8 наклейок)`,
              eventCategory: 'app-printing',
              eventAction: 'click',
            });
        } else if (this.selectedFormat === 'Marking6') {
          props.printForm = 'Marking_85x85';
          type = 'pdf8';
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print`)
            .next({
              eventLabel: `Друк маркування на листі А4 (6 наклейок)`,
              eventCategory: 'app-printing',
              eventAction: 'click',
            });
        } else if (this.selectedFormat === 'A4') {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print`)
            .next({
              eventLabel: `Друк експрес посилки А4`,
              eventCategory: 'app-printing',
              eventAction: 'click',
            });
        } else if (this.selectedFormat === 'A5') {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print`)
            .next({
              eventLabel: `Друк експрес посилки А5`,
              eventCategory: 'app-printing',
              eventAction: 'click',
            });
        }
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
          if (this.selectedFormat === 'Marking8') {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              EVENT_NAMES.ANALYTICS,
            )
              .subject(`app-printing.print`)
              .next({
                eventLabel: `Друк маркування на листі А4 (8 наклейок)`,
                eventCategory: 'app-printing',
                eventAction: 'click',
              });
          } else if (this.selectedFormat === 'Marking6') {
            props.printForm = 'Marking_85x85';
            type = 'pdf8';
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              EVENT_NAMES.ANALYTICS,
            )
              .subject(`app-printing.print`)
              .next({
                eventLabel: `Друк маркування на листі А4 (6 наклейок)`,
                eventCategory: 'app-printing',
                eventAction: 'click',
              });
          } else if (this.selectedFormat === 'A4') {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              EVENT_NAMES.ANALYTICS,
            )
              .subject(`app-printing.print`)
              .next({
                eventLabel: `Друк експрес посилки А4`,
                eventCategory: 'app-printing',
                eventAction: 'click',
              });
          } else if (this.selectedFormat === 'A5') {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              EVENT_NAMES.ANALYTICS,
            )
              .subject(`app-printing.print`)
              .next({
                eventLabel: `Друк експрес посилки А5`,
                eventCategory: 'app-printing',
                eventAction: 'click',
              });
          }
        }
      },
    );
  }

  showOptions(event) {
    if (event.value === 'Marking6') {
      this.markupItems.length = 6;
    } else if (event.value === 'Marking8') {
      this.markupItems.length = 8;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  selectMarkup(id) {
    if (this.selectedMarkup !== id) {
      this.selectedMarkup = id;
    } else {
      this.selectedMarkup = 1;
    }
  }
}

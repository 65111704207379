import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteWithControlsModule } from '@autocomplete-with-controls';
import { MaterialModule } from '@material';
import { GoogleAnalyticService } from '@services';
import { TextMaskModule } from 'angular2-text-mask';
import { CoreModule } from '../../../core/core.module';
import { TextareaAutosizeModule } from '../../../shared/modules/textarea-autosize/textarea-autosize.module';
import { AddressSenderNoteComponent } from '../components/address-sender-note/address-sender-note.component';
import { AddressSenderComponent } from '../components/address-sender/address-sender.component';
import { AddressDoorsComponent } from '../components/address-types/address-doors/address-doors.component';
import { AddressPostboxComponent } from '../components/address-types/address-postbox/address-postbox.component';
import { AddressWarehouseComponent } from '../components/address-types/address-warehouse/address-warehouse.component';
import { CancelControlButtonComponent } from '../components/back-control-button/cancel-control-button.component';
import { EditContainerComponent } from '../components/edit-container/edit-container.component';
import { TypeRadioGroupComponent } from '../components/type-radio-group/type-radio-group.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TextMaskModule,
    TextareaAutosizeModule,
    AutocompleteWithControlsModule,
  ],
  providers: [GoogleAnalyticService],
  declarations: [
    CancelControlButtonComponent,

    /* Abstract */
    TypeRadioGroupComponent,
    EditContainerComponent,

    /* Address */
    AddressWarehouseComponent,
    AddressDoorsComponent,
    AddressPostboxComponent,

    AddressSenderComponent,
    AddressSenderNoteComponent,
  ],
  exports: [
    CancelControlButtonComponent,

    /* Abstract */
    TypeRadioGroupComponent,
    EditContainerComponent,

    /* Address */
    AddressWarehouseComponent,
    AddressDoorsComponent,
    AddressPostboxComponent,

    AddressSenderComponent,
    AddressSenderNoteComponent,
  ],
})
export class AddressSenderModule {}

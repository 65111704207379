import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { AddressNoteErrors } from 'app/shared/errors/error-messages';
import { getError } from 'app/shared/utils/error.utils';
import {
  AddressNoteClasses,
  AddressNoteControlName,
  AddressNoteLabel,
  AddressNoteMaxLength,
  AddressNotePattern,
  AddressNotePlaceholder,
} from './address-note.constants';

@Component({
  selector: 'app-address-note',
  templateUrl: './address-note.component.html',
  styleUrls: ['./address-note.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressNoteComponent),
      multi: true,
    },
  ],
})
export class AddressNoteComponent implements ControlValueAccessor {
  getError = getError;

  AddressNoteErrors = AddressNoteErrors;

  @Input() formControl: UntypedFormControl | AbstractControl = new UntypedFormControl('', [
    Validators.maxLength(AddressNoteMaxLength),
    Validators.pattern(AddressNotePattern),
  ]);

  @Input() placeholder: string = AddressNotePlaceholder;

  @Input() label: string = AddressNoteLabel;

  @Input() testId: string = AddressNoteControlName;

  @Input() maxLength: number = AddressNoteMaxLength;

  @Input()
  @HostBinding('class')
  classList = AddressNoteClasses;

  get addressNote(): AbstractControl {
    return this.formControl;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }
}

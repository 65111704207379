export enum AppRoutes {
  home = 'home',
  // region auth routes
  auth = 'auth',
  login = 'login',
  login_organization = 'login-organization',
  login_private = 'login-private-person',
  forgot_password = 'forgot-password',
  forgot_card_number = 'forgot-card-number',
  registration = 'registration',
  sms_confirm = 'sms-confirm',
  /// endregion auth routes
  // region dashboard routes
  dashboard = 'dashboard',
  help = 'help',
  profile = 'profile',
  contacts = 'contacts',
  registers = 'registers',
  mutual_settlements = 'mutual-settlements',
  money_transfers = 'money-transfers',
  fulfillment = 'fulfillment',
  business_services = 'business-services',
  tmz = 'tmz',
  car_call = 'car-call',
  courier_pickup = 'courier-pickup',
  // region settings
  settings = 'settings',
  common = 'common',
  bonuses = 'bonuses',
  promo_code = 'promo-code',
  business = 'business',
  access = 'access',
  wallet_management = 'wallet-management',
  developers = 'developers',
  sms_service = 'sms-service',
  integration = 'integration',
  counterparty = 'counterparty',
  cards = 'cards',
  enrollment = 'enrollment',
  masterpass = 'masterpass',
  cash2card = 'cash2card',
  // endregion settings
  reporting = 'reporting',
  internet_orders = 'internet-orders',
  invoices_my = 'invoices-my',
  invoices_out = 'invoices-out',
  invoices_in = 'invoices-in',
  invoices_templates = 'invoices-templates',
  invoices_requests = 'invoices-requests',
  // region invoices-request types
  redirected = 'redirected',
  returned = 'returned',
  changed = 'change-ew',
  // endregion invoices-request types
  invoices_archived = 'invoices-archived',
  invoices_deleted = 'invoices-deleted',
  search_result = 'search-result',
  // endregion dashboard routes
  // region invoice-form routes
  invoice_create = 'create',
  invoice_create_international = 'create-international',
  invoice_template_create = 'create-template',
  invoice_template_edit = 'edit-template',
  invoice_template_copy = 'copy-template',
  invoice_edit = 'edit',
  invoice_edit_international = 'edit-international',
  invoice_copy = 'copy',
  internet_order_create = 'internet-order-create',
  // endregion invoice-form routes
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { from as fromPromise, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, mapTo, switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Organization, User } from '@models';

@Injectable()
export class FirebaseAuthService {
  private readonly sessionAuthSubject =
    new ReplaySubject<firebase.auth.Auth | null>(1);

  readonly auth: firebase.auth.Auth = firebase.auth();

  constructor(protected http: HttpClient) {
    this.setPersistence();
  }

  protected setPersistence(): void {
    fromPromise(
      this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION),
    )
      .pipe(
        mapTo(this.auth),
        catchError((e) => {
          return of(null);
        }),
      )
      .subscribe((auth) => this.sessionAuthSubject.next(auth));
  }

  get currentUser(): firebase.User {
    return this.auth.currentUser;
  }

  signIn(user: User | Organization): Observable<User | Organization> {
    return this.http
      .get(
        `${environment.functionsUrl}/getToken?uid=${
          user.loyaltyCardNumber || user.apiKey
        }`,
        { observe: 'response' },
      )
      .pipe(
        catchError((error) => {
          return of(user);
        }),
        switchMap((response: HttpResponse<any>) => {
          return response.body?.token
            ? fromPromise(
                this.auth
                  .signInWithCustomToken(response.body.token)
                  .catch((error) =>
                    console.error('signInWithCustomToken-error', error),
                  )
                  .then((data) => data),
              )
            : of(user);
        }),
        mapTo(user),
        first(),
      );
  }

  signOut(): Observable<void> {
    return fromPromise(this.auth.signOut());
  }

  get auth$(): Observable<boolean> {
    return this.sessionAuthSubject.asObservable().pipe(
      take(1),
      map((auth) => !!auth),
    );
  }
}

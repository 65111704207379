export interface Position {
  left: number;
  top: number;
}

export function getOffset(obj: HTMLElement|Element): Position {
  let rect;
  let  win;
  const elem = obj;

  if (!elem) {
    return;
  }

  if (!elem.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  rect = elem.getBoundingClientRect();
  win = elem.ownerDocument.defaultView;
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
}

export function getPosition(obj: HTMLElement): Position {
  let curleft = 0;
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
    } while (obj = (obj.offsetParent as HTMLElement));
    return { left: curleft, top: curtop };
  }
  return undefined;
}

export function findAncestor(el: HTMLElement, sel) {
  if (typeof el.closest === 'function') {
    return el.closest(sel) || null;
  }
  while (el) {
    if (el.matches(sel)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

export function hasPositioFixedAncestor(el: HTMLElement): boolean {
  while (el) {
    if (window.getComputedStyle(el, null).getPropertyValue('position') === 'fixed') {
      return true;
    }
    el = el.parentElement;
  }
  return false;
}

export function isMob(): boolean {
  if (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export function triggerEvent(el: HTMLElement, eventName: string, options) {
  let event;
  if ((window as any).CustomEvent) {
    event = new CustomEvent(eventName, options);
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, true, true, options);
  }
  el.dispatchEvent(event);
}

export function hasClass(el: HTMLElement, className: string): boolean {
  if (el?.classList) {
    return el.classList.contains(className);
  } else {
    return !!el?.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  }
}

export function addClass(el: HTMLElement, className: string) {
  if (el.classList) {
    el.classList.add(className);
  } else if (!hasClass(el, className)) {
     el.className += ' ' + className;
  }
}

export function removeClass(el: HTMLElement, className) {
  if (el.classList) {
    el.classList.remove(className);
  } else if (hasClass(el, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    el.className = el.className.replace(reg, ' ');
  }
}

if (!Element.prototype.matches) {
  Element.prototype.matches =
    (Element as any).prototype.matchesSelector ||
    (Element as any).prototype.mozMatchesSelector ||
    (Element as any).prototype.msMatchesSelector ||
    (Element as any).prototype.oMatchesSelector ||
    (Element as any).prototype.webkitMatchesSelector ||
    function(s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) { }
      return i > -1;
    };
}

export interface PartialSuccessResponse {
  Barcode: string,
  succeeded: string[],
  failed: FailedObject[],
  total: {
    succeededAmount: number,
    failedAmount: number,
    totalAmount: number
  }
}

export interface PartialSuccessModalConfig {
  data: PartialSuccessResponse,
  config: PartialSuccessConfig
}

export interface FailedObject {
  number: string,
  errorMessage: string
}

export enum PartialSuccessDataType {
  Invoice = 'invoice',
}

export const invoicesPartialSuccessConfig: PartialSuccessConfig = {
  caption: 'Посилки, що не увійшли до заявки',
  label: '',
  showBanner: true,
  dataType: PartialSuccessDataType.Invoice,
  banner: {
    icon: 'warning',
    title: 'У вас є посилки, що не були додані у заявку',
    successLabel: 'Успішно додано:',
    failedLabel: 'Помилка додавання:',
  },
  options: {
    identifierLabel: '№ накладної',
    reasonLabel: 'Причина',
  },
};

export const invoicesRemovePartialSuccessConfig: PartialSuccessConfig = {
  caption: 'Посилки, що не були видалені з заявки',
  label: '',
  showBanner: true,
  dataType: PartialSuccessDataType.Invoice,
  banner: {
    icon: 'warning',
    title: 'У вас є посилки, що не були видалені з заявки',
    successLabel: 'Успішно видалені:',
    failedLabel: 'Помилка видалення:',
  },
  options: {
    identifierLabel: '№ накладної',
    reasonLabel: 'Причина',
  },
};

export interface PartialSuccessConfig {
  caption: string,
  label?: string,
  showBanner: boolean,
  dataType: PartialSuccessDataType,
  banner: {
    icon?: string,
    title?: string,
    titleLabel?: string,
    successLabel?: string
    failedLabel?: string
  },
  options: {
    identifierLabel: string,
    reasonLabel: string
  }
}

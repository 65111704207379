import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
})
export class DropdownSearchComponent implements OnInit, OnDestroy {

  @Output()
  onTextInput: EventEmitter<string> = new EventEmitter<string>();

  control = new UntypedFormControl();
  inputFocused = false;

  @Input() searching = false;

  ngOnInit(): void {
    this.initInputValueSubscription();
  }

  ngOnDestroy(): void {
  }

  initInputValueSubscription(): void {
    this.control.valueChanges
      .pipe(
        tap(() => this.searching = true),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe(query => {
        this.searching = !!query;

        this.onTextInput.emit(query);
      });
  }

  clearSearch(): void {
    this.control.reset();
    this.onTextInput.emit(null);
  }

  onBlur(event) {
    event.preventDefault();
    if (!event.relatedTarget || (event.relatedTarget && (event.relatedTarget as any).innerText !== 'close')) {
      this.inputFocused = false;
    }
  }

  onFocus(event) {
    this.inputFocused = true;
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { getContactType } from '../../../../shared/utils/contact.util';
import { ErrorMessages, ErrorTypeMessages, ValidationService } from '@services';
import {
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent,
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import {
  AbstractFormContainer,
  EditableAutocompleteControl,
  TypedFormControl,
} from '@shared';
import { ContactPerson, ContactType, Contragent } from '@models';

@Component({
  selector: 'np-inv-contact-recipient-counterparty',
  templateUrl: 'contact-recipient-counterparty.component.html',
  styleUrls: ['./contact-recipient-counterparty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactRecipientCounterpartyComponent
  extends AbstractFormContainer
  implements EditableAutocompleteControl, TypedFormControl, OnInit
{
  @Input()
  controlName = 'Contragent';

  @Input()
  controlTypeName = 'ContactRecipientCounterpartyType';

  @Input()
  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Організація отримувача',
    placeholder: 'Організація',
    arrow: false,
  };

  @Input()
  controlsConfig: AutocompleteControls = {
    create: {
      enabled: true,
      tooltip: 'Створити нову організацію',
    },
  };

  @Input()
  activeType: ContactType | null;

  @Input()
  preventInput = false;

  @Input()
  options: Array<AutoCompleteOptionItem<ContactPerson>> = [];

  @Output()
  controlChanged = new EventEmitter<ContactPerson | ''>();

  errors: ErrorTypeMessages = {
    [this.controlName]: [
      { key: 'required', message: 'This field is required' },
      { key: 'minlength', message: 'should be 3 at least' },
      { key: 'contact', message: 'Please select from autocomplete' },
    ],
  };

  errorMessages: ErrorMessages = {};

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private validationService: ValidationService,
  ) {
    super();
  }

  addFormControls() {
    if (!this.form.get(this.controlName)) {
      this.createControl();
    }
    this.form.get(this.controlName).valueChanges.subscribe((_) => {
      this.errorMessages = this.validationService.getSingleControlErrorObject(
        this.controlName,
        this.form.get(this.controlName),
        this.errors,
      );
    });
  }

  createControl(): void {
    this.form.setControl(
      this.controlName,
      this.fb.control(
        { value: '', disabled: false },
        Validators.compose([Validators.required]),
      ),
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormSubscriptions();
  }

  initFormSubscriptions() {
    this.form
      .get(this.controlName)
      .valueChanges.pipe()
      .subscribe((controlValue) => this.controlChanged.next(controlValue));
  }

  setActiveType(value: object): void {
    this.activeType = getContactType(value);
  }

  transformFn: AutoCompleteTransformFn<Contragent> = (
    value: string | Contragent,
  ) => (typeof value === 'string' ? value : `${value.Description || ''}`);

  optionsFilterFn: AutocompleteOptionsFilterFn<Contragent> = (
    value: string | Contragent,
    options: Array<AutoCompleteOptionItem<Contragent>>,
  ) => {
    const format = (v) => v.replace(/[\s\+\-]+/g, '').toLowerCase();
    const group = (contragent: Contragent) => `${contragent.Description || ''}`;

    const v = format(typeof value === 'string' ? value : group(value));

    return options.filter((el) => format(group(el.option)).includes(v));
  };

  mode: string;

  cancel(): void {
    this.mode = null;
  }

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    this.mode = event.triggerName;
  }
}

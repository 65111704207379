<div class="form-row">
  <div class="form-col">
    <ng-content select="np-inv-type-radio-group"></ng-content>
  </div>
</div>
<div class="form-row box-with-controls">
  <ng-content></ng-content>
  <np-inv-cancel-control-button *ngIf="isCancelable"
                                (onCancel)="cancel()"></np-inv-cancel-control-button>
</div>

<ng-container [formGroup]="formGroup">
  <app-city-select [formControl]="city"
                   [classList]="cityClasses"></app-city-select>

  <app-warehouse-select [formControl]="warehouse"
                        [clearEnabled]="false"
                        [cityRef]="cityRef"
                        [itemsFilterConfig]="itemsFilterConfig"
                        [classList]="warehouseClasses"></app-warehouse-select>
</ng-container>

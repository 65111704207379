<div class="chip-list">
  <div class="clear-button-wrap">
    <button (click)="clearFilters()"
            *ngIf="showClearButton"
            [attr.data-test-id]="'clearFiltersBtn'"
            [disabled]="isLoading"
            class="chip chip_round"
            mat-ripple>
      Очистити
    </button>
  </div>

  <div class="chip-list-wrapper pl-3">
    <ul class="chip-list-container pb-1">
      <li *ngFor="let chip of chips"
          [innerHTML]="chip.content"
          class="chip chip_round disabled ml-3 d-inline-block">
      </li>
    </ul>
  </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormContainer } from '@shared';
import { TypeRadioButton } from '@type-radio-group';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { IdService } from '../../../components/invoice-form/services/id.service';
import { InvoiceApiService } from '../../../services/invoices/invoice-api.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-payment',
  templateUrl: './delivery-payment.component.html',
  styleUrls: ['./delivery-payment.component.scss'],
})
export class DeliveryPaymentComponent extends AbstractFormContainer implements AfterViewInit, OnInit, OnDestroy {
  @Input() controlName = 'PaymentMethod';
  @Input() parentForm;

  types: TypeRadioButton[] = [
    {
      value: 'Cash',
      label: 'Готівка',
    },
    {
      value: 'NonCash',
      label: 'Безготівка',
    },
  ];
  isChecked: boolean;
  debtor: boolean;
  canNonCashPayment: boolean;
  disableRadio$: Observable<any>;
  serviceAvailability$: Subscription;

  payerType$: Subscription;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    protected invoiceApiService: InvoiceApiService,
    private idService: IdService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.authService.user.contacts) {
      this.idService.availableServices$.subscribe((data) => {
        if (data) {
          this.debtor = data.Debtor;
          this.canNonCashPayment = data.CanNonCashPayment;
          this.types.forEach((type) => {
            type.disabled = false;
          });
          return this.disableWorker(this.form.get('PayerType').value);
        }
      });
    } else {
      this.parentForm
        .get('stepAddressForm')
        .valueChanges.pipe(
          filter((value) => !!value['ContactSenderOrg']),
          map((value) => value['ContactSenderOrg']),
          distinctUntilChanged(),
        )
        .subscribe((data) => {
          this.serviceAvailability$ = this.idService.availableServices$
            .pipe(
              catchError(() => of(null)),
              tap((data) => {
                if (data) {
                  this.debtor = data.Debtor;
                  this.canNonCashPayment = data.CanNonCashPayment;
                }
              }),
            )
            .subscribe(() => {
              this.types.forEach((type) => {
                type.disabled = false;
              });
              return this.disableWorker(this.form.get('PayerType').value);
            });
        });
    }

    this.initPayerSubscription();
  }

  disableWorker(payerValue: string) {
    this.types = this.types.map((type) => {
      if (this.debtor || (!this.canNonCashPayment && type.value !== 'Cash')) {
        type.disabled = false;
      }
      return type;
    });
    this.cd.detectChanges();
  }

  initPayerSubscription() {
    this.payerType$ = this.form.get('PayerType').valueChanges.subscribe((value) => {
      this.types.forEach((type) => {
        type.disabled = false;
      });
      this.disableWorker(value);
    });
  }

  setDisalbeStates(value: string) {
    if (value === 'Sender' && this.canNonCashPayment) {
      this.types = this.types.map((type) => {
        if (type.value !== 'Cash') {
          type.disabled = false;
        }
        return type;
      });
      this.cd.detectChanges();
    } else {
      this.types = this.types.map((type) => {
        if (type.value !== 'Cash') {
          type.disabled = true;
        }
        return type;
      });
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.form.get('PayerType')) {
      this.form.get('PayerType').valueChanges.subscribe((value) => {
        if (value === 'ThirdPerson') {
          setTimeout(() => {
            this.form.get('ThirdPerson').valueChanges.subscribe(() => {
              if (this.form.get('ThirdPerson').valid) {
                // this.form.get(this.controlName).setValue('NonCash');
                this.form.get(this.controlName).updateValueAndValidity({ onlySelf: true });
              }
            });
          });
        }
      });
    }
  }

  addFormControls() {
    const deliveryTypeControl = new UntypedFormControl('Cash');
    this.form.addControl(this.controlName, deliveryTypeControl);
  }

  ngOnDestroy() {
    // this.serviceAvailability$.unsubscribe();
    this.payerType$.unsubscribe();
  }
}

<mat-form-field [formGroup]="form"
                class="mat-block mat-block_with_symbol mat-block_placeholder-top" data-symbol="шт">
  <input matInput
         npNumber
         NumberInput="true"
         placeholder="Кількість мiсць"
         [maxValue]="30"
         [maxInputLength]="2"
         (keypress)="preventDecimal($event)"
         [(ngModel)]="seats"
         [formControlName]="controlName">
</mat-form-field>

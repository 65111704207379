<div class="control-icons">
  <div class="control-icons__item">
    <button mat-icon-button
            type="button"
            (click)="cancel()">
      <mat-icon class="material-icons_grey" [matTooltip]="tooltip" matTooltipPosition="below">
        cancel
      </mat-icon>
    </button>
  </div>
</div>

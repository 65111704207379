<ng-container [formGroup]="formGroup">
  <app-city-select [formControl]="city"
                   [classList]="cityClasses"></app-city-select>

  <app-postbox-select [formControl]="warehouse"
                      [clearEnabled]="false"
                      [cityRef]="cityRef"
                      [forSender]="forSender"
                      [classList]="warehouseClasses"></app-postbox-select>
</ng-container>

<div [formGroup]="form">
  <ng-container [formGroupName]="controlName">
    <ng-container *ngIf="mode === AutocompleteMode.SELECT; else createMode">
      <app-autocomplete data-test-id="RecipientAddressSelect"
                        class="form-row"
                        [size]="800"
                        [formControl]="formGroup.get('data')"
                        [options]="options"
                        [pointer]="options.length > 1"
                        [displayTransformFn]="transformFn"
                        [config]="config"
                        [preventInput]="true"
                        [controlsConfig]="controlsConfig"
                        (controlTriggered)="changeMode($event)">
        <ng-template appAutocompleteOption let-option>
          {{ (option?.CityDescription && option?.AddressDescription
          ? createWarehouseDescription(option)
          : createDoorsDescription(option))}}
        </ng-template>
      </app-autocomplete>
    </ng-container>

    <ng-template #createMode>
      <app-edit-container data-test-id="RecipientAddressCreate"
                          [controlName]="controlName"
                          [formTypeName]="controlTypeName"
                          [isCancelable]="!!options?.length"
                          (onCancel)="cancel()"
                          (onClear)="clear()">
        <np-inv-type-radio-group data-test-id="RecipientAddressType"
                                 [formControlName]="ControlModes.TYPE"
                                 [types]="types"
                                 [modifyClass]="'radio-row_mr-top'">
        </np-inv-type-radio-group>

        <ng-container [ngSwitch]="activeType">
          <app-warehouse *ngSwitchCase="AddressTypes.WAREHOUSE"
                         [form]="control"
                         [controlName]="ControlModes.DATA"
                         [controlTypeName]="ControlModes.TYPE">
          </app-warehouse>

          <app-address *ngSwitchCase="AddressTypes.DOORS"
                       [form]="control"
                       [controlName]="ControlModes.DATA"
                       [controlTypeName]="ControlModes.TYPE"
                       [showFlat]="showFlat">
          </app-address>

          <app-postbox *ngSwitchCase="AddressTypes.POSTBOX"
                       [form]="control"
                       [forSender]="forSender"
                       [controlName]="ControlModes.DATA"
                       [controlTypeName]="ControlModes.TYPE">
          </app-postbox>

          <app-address-by-index *ngSwitchCase="AddressTypes.INDEX"
                                [controlName]="ControlModes.DATA"
                                [filterPostboxes]="filterPostboxes"
                                [label]="addressByIndexLabel"
                                [forSender]="forSender"
                                [form]="control">
          </app-address-by-index>
        </ng-container>
      </app-edit-container>
    </ng-template>
  </ng-container>
</div>

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { AlertToastService } from '../alert-toast.service';
import { AuthService } from '../auth.service';
import { OAuth2Service } from '../auth/oAuth2.service';
import { WINDOW } from '../window.service';

@Injectable()
export class JWTErrorInterceptor implements HttpInterceptor {

  private readonly tokenErrors = new Set([
    'Token expired',
    'Token incorrect',
    'User is undefined',
  ]);

  constructor(
    private injector: Injector,
    private alertToastService: AlertToastService,
    @Inject(WINDOW) private window: any,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authService = this.injector.get(AuthService);
    const oAuth2Service = this.injector.get(OAuth2Service);

    const online = this.window.navigator.onLine;

    return next.handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpErrorResponse && event.status === 0 && online) {
            this.alertToastService.pushError('Вибачте, сталася помилка виконання запиту. Для вирішення проблеми зверніться до служби підтримки: support@novaposhta.ua');
            throw new Error('Server response Error');
          }
          if (event instanceof HttpErrorResponse && (event.status === 500 || event.status === 504)) {
            this.alertToastService.pushError('Вибачте, сталася помилка виконання запиту. Для вирішення проблеми зверніться до служби підтримки: support@novaposhta.ua');
            throw new Error('Internal Server Error');
          }
          if (event instanceof HttpErrorResponse && event.status === 401) {
            this.alertToastService.pushError('Сеанс розірвано. Здійсніть повторну авторизацію');
            authService.logout();
            throw new Error('Unauthorized');
          }
          if (event instanceof HttpErrorResponse && event.status === 413) {
            this.alertToastService.pushError('Обсяг ваших даних занадто великий, ми не змогли опрацювати запит. Зменшіть розмір даних для завантаження');
            throw new Error('Payload Too Large');
          }
          if (event instanceof HttpResponse) {
            const response = event as HttpResponse<any>;

            const body = response.body;

            if (body && typeof body !== 'string' && body.errors && !Array.isArray(body.errors)) {
              const errorsObj = body.errors;
              const errors = Object.keys(errorsObj)
                .map(key => errorsObj[key]);
              response.body.errorsObject = Object.assign({}, body.errors);
              response.body.errors = errors;
            }
          }

          return event;
        }),
        tap((event: HttpResponse<{ errors: string[] }>) => {
          if (event && event.status === 401) {
            authService.logout();
            return;
          }
          if (event && event.body && event.body.errors) {
            const errors = event.body.errors;
            for (const error of errors) {
              if (this.tokenErrors.has(error)) {
                authService.logout();
                this.alertToastService.pushError('Сеанс розірвано. Здійсніть повторну авторизацію');
                return;
              }
            }
          }
        }),
      );
  }
}

<div class="services-list__form" [formGroup]="packingServiceGroup">
  <div class="form-row"  *ngFor="let place of placesList; let i = index;">
    <div class="form-col form-col-6 d-flex" *ngIf="packages.length">
      <mat-form-field class="no-shrink">
        <mat-select [placeholder]="'Місце ' + (i + 1)" [value]="setSelectedPackage(place, i)" (selectionChange)="setPackage($event.value, i)">
          <mat-option *ngFor="let package of packages[i]" [value]="package">
            {{getDescription(package)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button
              [style.visibility]="checkValue(i) ? 'visible' : 'hidden'"
              (click)="clearSearch(i)"
              class="clear-btn">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</div>

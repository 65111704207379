import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import Rxmq, {Channel} from 'rxmq';
import {AuthService} from '../../../services/auth.service';
import {OAuth2Service} from '../../../services/auth/oAuth2.service';
import {SpinnerService} from '../../../services/spinner.service';
import {AnalyticEvent} from '../../../shared/interfaces/analytic-event';
import {AlertToastService} from '../../../services/alert-toast.service';

@Component({
  selector: 'app-auth-processing',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss'],
})
export class TokenLoginComponent implements OnInit {

  constructor(
    private spinnerService: SpinnerService,
    private oAuth2Service: OAuth2Service,
    private authService: AuthService,
    private alertToastService: AlertToastService,
    protected storage: LocalStorageService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.checkAuth();
  }

  checkAuth() {
    const token = this.route.snapshot.params.token;
    if (token) {
      this.authService.logout(null, true);
      this.tokenLogin(token);
    } else {
      this.spinnerService.stop();
      this.router.navigate(['home']);
    }
  }

  tokenLogin(token) {
    this.authService.loginWithToken(token).subscribe(data => {
      const user = data;
      (this.authService.user as any).loginEmail = user.loginEmail;
      this.router.navigateByUrl('dashboard/invoices-my');
      this.spinnerService.stop();

      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`token-login.login.success`)
        .next({
          eventLabel: `Увійти з токеном, успіх`,
          eventCategory: 'token-login',
          eventAction: 'submit.success',
        });
    }, errors => {
      this.authService.logout('/');
      this.spinnerService.stop();
      if (errors[0]) {
        this.alertToastService.pushApiErrors(errors);
      } else {
        this.alertToastService.pushError('Сеанс розірвано. Необхідно здійснити повторну авторизацію');
      }
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`token-login.login.error`)
        .next({
          eventLabel: `Увійти з токеном, помилка`,
          eventCategory: 'token-login',
          eventAction: 'submit.error',
        });
    });
  }

}

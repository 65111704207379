// Start region API endpoints configs
const API = 'https://api.novaposhta.ua/v2.0/';
const API1 = 'https://api1.dev.npu.np.work/v2.0/';
const API2 = 'https://api2-stage.npu.np.work/v2.0/';
export const API_URL = API;
// End region API endpoints configs

// Start region Claim frame endpoint configs
const ClaimFrameUrlDev = 'https://gitlab-pages.nova-digital.net/public_front/external-forms/main/additional_services/claim/#/';
const ClaimFrameUrlStage = 'https://employee-stage.nova-digital.net/form/claim/#/';
const ClaimFrameUrlProd = 'https://forms.novapost.world/claim/#/';
export const ClaimFrameUrl = ClaimFrameUrlProd;
// End region Claim frame endpoint configs

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['error-snackbar.component.scss'],
})
export class ErrorSnackbarComponent implements OnInit {

  errorMessages;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

  }

  ngOnInit() {
    this.errorMessages = this.data;
  }
}

import { InvoiceRegister } from '../../models/invoice/invoice-register.model';
import { InvoiceState } from '../../models/invoice/invoice-state.model';
import { BackDeliveries, BackDelivery, Invoice, OrderingAdditionalService } from '../../models/invoice/invoice.model';
import { GetTemplateInfoResponseData } from '../../models/template.model';
import { dateTimeToString, dateToString, todayEqualOrGreaterThan } from './date.util';

export function convertInvoiceDate(invoice: Invoice): Date {
  return new Date(JSON.parse(JSON.stringify(invoice.createTime.getTime())));
}

export function convertRegisterDate(register: InvoiceRegister): Date {
  return new Date(JSON.parse(JSON.stringify(register.dateTime.getTime())));
}

export function checkInRegisters(invoices: Invoice[]): number {
  for (let i = 0; i < invoices.length; i++) {
    if (!!invoices[i].ScanSheetRef) {
      return i;
    }
  }
  return -1;
}

export function handleFirstDayStorage(invoice: Invoice): Invoice {
  const isTodayGreaterThanFirstDayStorage = (i: Invoice): boolean => {
    if (!i) {
      return false;
    }
    try {
      const dateFirstDayStorage = new Date(i.dateFirstDayStorage);
      return todayEqualOrGreaterThan(dateFirstDayStorage);
    } catch (e) {
      return false;
    }
  };

  const isReceived =
    invoice &&
    invoice.statusCode &&
    !!invoice.dateFirstDayStorage &&
    (Number(invoice.statusCode) === 7 || Number(invoice.statusCode) === 8);

  if (isReceived && isTodayGreaterThanFirstDayStorage(invoice)) {
    const actualDeliveryDate = new Date(invoice.actualDeliveryDate);
    const newInvoice = {
      ...(invoice as Invoice),
      state: {
        ...(invoice.state as InvoiceState),
        id: '6',
        name: `Прибув у відділення ${dateTimeToString(
          actualDeliveryDate,
        )} Розпочате платне зберігання`,
        shortName: 'Платне зберігання',
      } as InvoiceState,
    } as Invoice;
    return newInvoice;
  }

  return invoice;
}

export function handleReturnCargoDate(invoice: Invoice): Invoice {
  if (
    !!invoice.dateReturnCargo &&
    invoice.state &&
    Number(invoice.state.id) !== 6
  ) {
    const dateReturn = new Date(invoice.dateReturnCargo);
    const actualDeliveryDate = new Date(invoice.actualDeliveryDate);
    const newInvoice = {
      ...(invoice as Invoice),
      state: {
        ...(invoice.state as InvoiceState),
        name: `Прибув у відділення ${dateTimeToString(
          actualDeliveryDate,
        )} Автоповернення ${dateToString(dateReturn)}`,
      } as InvoiceState,
    } as Invoice;
    return newInvoice;
  }
  return invoice;
}

export type CargoTypeRef = 'Money' | 'Documents' | 'SignedDocuments' | 'Other';

export const findBackDelivery = (
  backwardDeliveryData: any[],
  cargoTypeRef: CargoTypeRef,
) => {
  return backwardDeliveryData
    ? Object.values(backwardDeliveryData).find(
      (delivery) => delivery.CargoTypeRef === cargoTypeRef,
    )
    : null;
};

export const parseBackDeliveriesFromInvoice = (
  invoice: Invoice | GetTemplateInfoResponseData,
) => {
  const backwardDeliveryData: { [key: string]: BackDelivery } =
    invoice.BackwardDeliveryData;
  const backDeliveries: BackDeliveries = {};
  Object.values(backwardDeliveryData).map((delivery) => {
    if (delivery.CargoTypeRef === 'Money') {
      backDeliveries.Money = delivery;
    }
    if (delivery.CargoTypeRef === 'Documents' && !delivery.Services) {
      backDeliveries.Documents = delivery;
    }
    if (delivery.CargoTypeRef === 'Documents' && delivery.Services) {
      backDeliveries.DifferentDocuments = delivery;
    }
    if (delivery.CargoTypeRef === 'SignedDocuments') {
      backDeliveries.SignedDocuments = delivery;
    }
    if (delivery.CargoTypeRef === 'Other') {
      backDeliveries.Other = delivery;
    }
  });
  return backDeliveries;
};

export const getBackDeliveriesDescriptionForTooltip = (
  invoice: Invoice,
): string => {
  return Object.keys(invoice.backDeliveries)
    .map((key) => {
      const delivery = invoice.backDeliveries[key];
      if (delivery.CargoTypeRef === 'Money') {
        return '';
      }
      if (delivery.CargoTypeRef === 'SignedDocuments') {
        return 'Підписані документи';
      }
      if (delivery.CargoTypeRef === 'Documents') {
        return delivery.Services && !(delivery.Services instanceof Array)
          ? 'Підтипи документів'
          : 'Документи';
      }
      return delivery.RedeliveryString;
    })
    .filter((val) => val !== '')
    .join(', ');
};

export const getBackDeliveryDescriptionForForm = (
  backDelivery: BackDelivery,
): string => {
  if (backDelivery.Services && Object.keys(backDelivery.Services).length) {
    return 'Підтипи документів';
  }
  if (
    backDelivery.CargoTypeRef === 'Documents' &&
    backDelivery.RedeliveryString
  ) {
    return backDelivery.RedeliveryString;
  }
  if (backDelivery.RedeliveryString) {
    if (
      isNaN(Number(backDelivery.RedeliveryString)) ||
      backDelivery.RedeliveryString === ' '
    ) {
      return backDelivery.RedeliveryString;
    } else {
      return `${backDelivery.RedeliveryString} грн`;
    }
  }
  return '0';
};

export function checkCanEdit(additionalServices: OrderingAdditionalService[], orderType: string): boolean {
  if (!additionalServices?.length) {
    return false;
  }

  return additionalServices.some(
    (s) => s.OrderType === orderType && (s.Status === 'Received' || s.Status === 'StoreInProcessing'),
  );
}

export function getOrderRef(additionalServices: OrderingAdditionalService[], orderType: string): string | undefined {
  if (!additionalServices?.length) {
    return;
  }

  return additionalServices.find(s => s.OrderType === orderType && (s.Status === 'Received' || s.Status === 'StoreInProcessing'))?.Ref;
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TextMaskModule} from 'angular2-text-mask';
import {PhoneInputComponent} from './phone-input/phone-input.component';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    TextMaskModule,
  ],
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent],
})
export class PhoneInputModule {
}

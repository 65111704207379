<ng-container [formGroup]="form">

    <ng-container *ngIf="!mode">
      <app-autocomplete-with-controls class="form-row"
                                      [formControlName]="controlName"
                                      [options]="options"
                                      [transformFn]="transformFn"
                                      [config]="config"
                                      [preventInput]="true"
                                      (onControlTriggered)="changeMode($event)">
        <ng-template appAutocompleteOption let-option>
          {{ option?.Description }} {{ option?.Phones | npFinePhone }}
        </ng-template>
        <app-autocomplete-error *ngIf="!!errorMessages[controlName]">
          {{ errorMessages[controlName] }}
        </app-autocomplete-error>
      </app-autocomplete-with-controls>
    </ng-container>
  </ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';

import { of } from 'rxjs';
import { catchError, distinctUntilChanged, take } from 'rxjs';
import { BusinessSettingsService } from '../../../../../services/business-settings.service';
import { CustomReturnAddressComponent } from '../custom-return-address/custom-return-address.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'np-inv-back-documents',
  templateUrl: './back-documents.component.html',
  styleUrls: ['./back-documents.component.scss'],
})
export class BackDocumentsComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @ViewChild('customAddress', { read: CustomReturnAddressComponent })
  customAddress;
  @Input() isCorporate = false;
  @Input() controlName = 'BackwardDeliveryData';
  @Input() templateValidation: boolean;
  @Input() templateEditMode;
  backwardDeliveryDataGroup: UntypedFormGroup;
  backDeliveryGroup: UntypedFormGroup;

  returnAddressControlName = 'CustomBackwardDeliveryParameterBackDocuments';

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private settingService: BusinessSettingsService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  _customAddresses: any[] = null;

  get customAddresses() {
    return this._customAddresses;
  }

  set customAddresses(addresses) {
    if (!addresses && this.isCorporate) {
      this.updateRef(null);
    }
    this._customAddresses = addresses;
  }

  _ref = null;

  get ref() {
    return this._ref;
  }

  @Input() set ref(ref: string) {
    this._ref = ref;
    if (this.isCorporate && !this.templateEditMode) {
      this.getReturnAdresses(ref, 'Documents');
    }
  }

  get senderName(): string | null {
    return this.form.get('ContactSender')
      ? this.form.get('ContactSender').value.Description
      : null;
  }

  get recipientName(): string | null {
    if (this.form.get('Recipient')) {
      return this.form.get('Recipient').value.Description;
    } else if (
      this.form.get('NewRecipientPrivateContact') &&
      this.form.get('NewRecipientPrivateContact').value.lastName
    ) {
      const value = this.form.get('NewRecipientPrivateContact').value;
      return `${value.lastName} ${value.firstName ? value.firstName : ''} ${
        value.middleName ? value.middleName : ''
      }`;
    }
    return null;
  }

  getReturnAdresses(ref: string, cargoType: string) {
    this.settingService
      .getReturnAdresses(ref, cargoType)
      .pipe(
        take(1),
        catchError((e) => of(e)),
      )
      .subscribe((data) => {
        this.customAddresses =
          data.length > 0
            ? data.map((el) => ({
                label: el.Address,
                value: el.Ref,
              }))
            : null;
        this.cd.detectChanges();
      });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.templateValidation) {
      const formGroup = this.form
        .get('AdditionalServices.backDelivery')
        .get(this.controlName);
      Object.keys(formGroup['controls']).forEach((key) =>
        formGroup['controls'][key].clearValidators(),
      );
    }

    this.form
      .get('AdditionalServices')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe(() =>
        this.checkIfCustomDeliveryAlreadySelected('backDocuments'),
      );
  }

  checkIfCustomDeliveryAlreadySelected(current: string) {
    if (!this.isCorporate || this.templateEditMode || !this.customAddress) {
      return;
    }
    const elts = this.form.controls['AdditionalServices']['controls'];
    this.customAddress.disabled = Object.keys(elts)
      .filter((it) => it !== current)
      .some(
        (it) =>
          elts[it].get(
            'BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments',
          ) &&
          !!elts[it].get(
            'BackwardDeliveryData.CustomBackwardDeliveryParameterBackDocuments',
          ).value,
      );
  }

  updateRef(ref: string) {
    this.backwardDeliveryDataGroup
      .get('CustomBackwardDeliveryParameterBackDocuments')
      .setValue(ref);
  }

  addFormControls() {
    this.backDeliveryGroup = this.form
      .get('AdditionalServices')
      .get('backDocuments') as UntypedFormGroup;
    this.backwardDeliveryDataGroup = this.formBuilder.group({
      PayerType: ['Recipient', [Validators.required]],
      CargoType: ['Documents', [Validators.required]],
      CustomBackwardDeliveryParameterBackDocuments: [''],
      RedeliveryString: ['', [Validators.required, Validators.maxLength(20)]],
      ReceivingType: ['Warehouse', [Validators.required]],
      ReceivingData: [{}],
    });
    this.backDeliveryGroup.addControl(
      this.controlName,
      this.backwardDeliveryDataGroup,
    );
  }

  ngOnDestroy() {
    this.backDeliveryGroup.removeControl(this.controlName);
  }
}

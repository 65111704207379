import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { CourierPickupApiService } from '../../../../components/dashboard/business-services/services/courier-pickup-api/courier-pickup-api.service';
import { ConfirmDialogModernConfig } from '../../confirm-dialog-modern/confirm-dialog-modern.component';

@Component({
  selector: 'app-courier-pickup-remove-invoice-confirm',
  templateUrl: './courier-pickup-remove-invoice-confirm.component.html',
  styleUrls: ['./courier-pickup-remove-invoice-confirm.component.scss'],
})
export class CourierPickupRemoveInvoiceConfirmComponent extends Unsubscriber {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this._loading.asObservable();

  public set loading(value: boolean) {
    this._loading.next(value);
  }

  constructor(
    private dialog: MatDialogRef<CourierPickupRemoveInvoiceConfirmComponent>,
    private courierApiService: CourierPickupApiService,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogModernConfig,
  ) {
    super();
  }

  cancel(): void {
    this.dialog.close();
  }

  confirm(): void {
    this.loading = true;
  }
}

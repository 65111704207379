import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextareaAutosizeDirective } from './textarea-autosize.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [TextareaAutosizeDirective],
  exports: [TextareaAutosizeDirective],
})
export class TextareaAutosizeModule {

}

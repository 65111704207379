import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { getError, SmartFormContainer, TableFilter } from '@shared';

@Component({
  selector: 'app-hot-input-filter',
  templateUrl: './hot-input-filter.component.html',
  styleUrls: ['./hot-input-filter.component.scss'],
})
export class HotInputFilterComponent extends SmartFormContainer implements OnInit {
  readonly getError = getError;
  @Input() config: TableFilter;
  @Input() initialForm: object;
  @Output() filterApplied: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  resetValue(control: string): void {
    if (this.changed(control)) {
      this.control.setValue(this.initialForm[control]);
    }
  }

  notChanged(name: string): boolean {
    return this.initialForm[name] === this.form.getRawValue()[name];
  }

  changed(name: string): boolean {
    return !this.notChanged(name);
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  clearField(field: AbstractControl, event?: Event, value?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (field.reset) {
      if (value === '' || value === null) {
        field.reset(value);

        return;
      }
      field.reset();
    }
  }

  applyFilterForm(): void {
    this.filterApplied.emit(this.form.value);
    this.menuTrigger.closeMenu();
  }
}

import { Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[LetterInput]',
})
export class LetterInputDirective {

  @Input() LetterInput: boolean;

  @Input() allowDash: boolean;

  @Input() onlyCyrillic: boolean;

  constructor(private el: ElementRef, @Self() @Optional() private ngControl: NgControl) {
  }

  @HostListener('keypress', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    const k = event.keyCode || event.which;
    if (this.allowDash && k === 45) {
      return;
    }

    if (this.LetterInput) {
      if ((k >= 65 && k <= 90 && !this.onlyCyrillic) || (k >= 97 && k <= 122 && !this.onlyCyrillic) || (k >= 1040 && k <= 1103) || (k === 8)
        || (k === 9) || (k === 46) || (k >= 1105 && k <= 1111) || (k >= 1025 && k <= 1031)
        || (k >= 1168 && k <= 1169) || k === 39 && this.onlyCyrillic) {
        return;
      } else {
        e.preventDefault();
      }
    }
  }

}

<div class="form-row">
  <div class="input-container box-with-controls">
    <mat-form-field class="mat-block input-readonly">
      <input matInput disabled [value]="value" [placeholder]="placeholder">
    </mat-form-field>
    <div class="control-icons" *ngIf="showControls">
      <div class="control-icons__item" [matTooltip]="BlockChangeSenderDataIfAvailableCargoTypeMoney && disableCreation ? 'Нажаль з міркувань безпеки операція зміни даних Відправника для ЕН із замовленою послугою Грошовий переказ неможлива. Для її проведення зверніться, будь ласка, до оператора будь-якого відділення Нова Пошта' : null">
        <button mat-icon-button
                type="button"
                (click)="create()"
                [disabled]="disableCreation">
          <mat-icon class="material-icons_red" [matTooltip]="'Змінити'" matTooltipPosition="below">
            add_circle_outline
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractFormContainer} from '@shared';

@Component({
  selector: 'app-forwarding-count',
  templateUrl: './forwarding-count.component.html',
  styleUrls: ['./forwarding-count.component.scss'],
})
export class ForwardingCountComponent extends AbstractFormContainer implements OnInit, OnDestroy {

  @Input() controlName = 'ForwardingCount';

  forwardingCountGroup: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    this.forwardingCountGroup = (this.form.get('AdditionalServices').get('forwardingCount') as UntypedFormGroup);
    this.forwardingCountGroup.addControl(this.controlName, new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.min(0),
      ]),
    ));
  }

  ngOnDestroy(): void {
    this.forwardingCountGroup.removeControl(this.controlName);
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { parseNumbers } from '@shared';

// Example: <input [textMask]="PhoneMaskConfig" phoneInt

export const PhonePrefixInt = '+';
export const PhonePrefixPoland = '+48';

@Directive({
  selector: '[phoneInt]',
})
export class InternationalPhoneInputDirective {
  @Input() prefix = PhonePrefixInt;
  @Input() maskValue: boolean | Array<string | RegExp> = false;

  constructor(private ngControl: NgControl) {}

  get length(): number {
    if (typeof this.maskValue === 'boolean') {
      return 0;
    }
    return +this.maskValue?.length;
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    if (!this.ngControl) {
      return;
    }
    const target = event.target as HTMLInputElement;
    if (target.value.trim() === '') {
      target.value = this.prefix;

      this.setPrefix(target);
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent): void {
    if (!this.ngControl) {
      return;
    }
    const target = e.target as HTMLInputElement;
    if (target.value === '') {
      this.setPrefix(target);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(e: Event): void {
    if (!this.ngControl) {
      return;
    }

    const target = e.target as HTMLInputElement;

    if (this.prefix === PhonePrefixInt) {
      if (target.value.length === this.prefix.length) {
        this.ngControl.control.setValue(null);
        return;
      }
    }

    if (this.prefix === PhonePrefixPoland) {
      if (target.value.length === this.prefix.length + 1) {
        this.ngControl.control.setValue(null);
        return;
      }
    }

    if (target.value.length > 0 && target.value.length < this.length) {
      this.ngControl.control.setErrors({ minlength: true });
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    e.preventDefault();
    const f = this.format(parseNumbers(e.clipboardData.getData('Text')));

    if (this.ngControl) {
      if (this.prefix === PhonePrefixInt) {
        this.ngControl.control.setValue(`${this.prefix}${f}`);
        return;
      }

      if (this.prefix === PhonePrefixPoland) {
        this.ngControl.control.setValue(
          `${this.prefix} ${f.slice(2, 5)} ${f.slice(
            5,
            7,
          )} ${f.slice(7, 9)} ${f.slice(9, 11)}`,
        );
      }
    }
  }

  private setPrefix(target: HTMLInputElement): void {
    this.ngControl.control.setValue(this.prefix);

    if (this.prefix === PhonePrefixInt) {
      target.selectionStart = this.prefix.length;
    }
  }

  private format(v: string): string {
    if (!v || typeof v !== 'string') {
      return '';
    }

    switch (v.length) {
      case 11:
        v = v.replace(/^8/, '');
        break;
      case 12:
        v = v.replace(/^48/, '');
        break;
      default:
        v = v.replace(/^4/, '').replace(/^8/, '');
    }

    if (this.prefix === PhonePrefixInt) {
      return v.replace(' ', '');
    }

    return v;
  }
}

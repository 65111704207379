import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ContactsService } from '../../../../services/contacts/contacts.service';
import { GoogleAnalyticService } from "@services";
import {AbstractFormContainer} from '@shared';
import { ContactPerson, Contragent } from "@models";

@Component({
  selector: 'np-inv-recipient-search',
  templateUrl: 'recipient-search.component.html',
  styleUrls: ['./recipient-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientSearchComponent extends AbstractFormContainer implements OnInit, OnDestroy {

  @Input() controlName = 'RecipientSearchObject';

  contactOptions: ContactPerson[] = [];

  recipientSearchControl: UntypedFormControl;

  showSpinner = false;

  showNotFoundContacts = false;

  inputFocused = false;

  @Output()
  recipientSelected = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  @Output()
  onClear = new EventEmitter<void>();

  constructor(private cd: ChangeDetectorRef,
              private contactsService: ContactsService,
              private googleAnalyticsService: GoogleAnalyticService) {
                super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initSearchSubscription();
  }

  addFormControls() {
    this.recipientSearchControl = new UntypedFormControl('');
    this.form.addControl(this.controlName, this.recipientSearchControl);
  }

  initSearchSubscription() {
    this.recipientSearchControl.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.showSpinner = true;
      this.showNotFoundContacts = false;
      if (typeof value === 'string' && value.length >= 3) {
        this.searchRecipientContacts(value);
      } else {
        this.contactOptions = [];
        this.showSpinner = false;
      }
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });
  }

  searchRecipientContacts(query) {
    this.contactsService.searchRecipientContacts(query).subscribe(contacts => {
      if (!contacts.length) {
        this.showNotFoundContacts = true;
      }
      this.contactOptions = contacts;
      this.showSpinner = false;
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    })
  }

  getFormattedPhone(phone) {
    return `+${phone.slice(0, 3)} (${phone.slice(3, 5)}) ${phone.slice(5, 7)}-${phone.slice(7, 10)}-${phone.slice(10, 12)}`;
  }

  onSelectContact(event): void {
    const contact: ContactPerson = event.source.value;
    this.searchInput.nativeElement.value = '';
    this.form.removeControl('NewRecipientPrivateContact');
    this.form.removeControl('NewAddressRecipient');
    this.form.removeControl('RecipientSearchObject');
    if (this.form.get('Recipient')) {
      this.form.get('Recipient').setValue(contact);
    } else {
      this.form.setControl('Recipient', new UntypedFormControl(contact, Validators.required));
    }
    this.form.get('Recipient').updateValueAndValidity();
    this.form.setControl('RecipientSearchObject', new UntypedFormControl(contact));
    this.getContragent(contact.CounterpartyRef).subscribe((contragent: Contragent) => {
      const contragentControl = new UntypedFormControl({ value: contragent, disabled: true });
      if (!this.form.get('Contragent')) {
        this.form.setControl('Contragent', contragentControl);
      } else {
        this.form.get('Contragent').setValue(contragent);
      }
      this.setRecipientCounterpartyType(contragent);
      this.inputFocused = false;
      this.searchInput.nativeElement.blur();
      this.searchInput.nativeElement.value = '';
      this.cd.markForCheck();
      this.recipientSelected.emit();
    });

    this.googleAnalyticsService.track('analitics', `second-step.searchRecipient`, {
      eventLabel: `Пошук отримувача`,
      eventCategory: 'create-invoice',
      eventAction: 'search.success',
    });
  }

  setRecipientCounterpartyType(contragent: Contragent) {
    const recipientCounterpartyType = contragent.CounterpartyType === 'PrivatePerson' ? 'PrivatePerson' : 'Organization';
    if (this.form.get('RecipientCounterpartyType')) {
      this.form.get('RecipientCounterpartyType').setValue(recipientCounterpartyType);
    } else {
      this.form.addControl('RecipientCounterpartyType', new UntypedFormControl(recipientCounterpartyType));
    }

  }

  getContragent(ref: string) {
    return this.contactsService.getContragent(ref);
  }

  displayFn(contact: ContactPerson) {
    const phone = contact.Phones;
    if (!contact) { return ''; }
    return `${contact.Description} +${phone.slice(0, 3)} (${phone.slice(3, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;
  }

  clearContact() {
    this.form.get('RecipientSearchObject').reset('');

    this.searchInput.nativeElement.value = '';

    this.form.removeControl('Recipient');
    this.form.removeControl('Contragent');
    this.form.removeControl('ContactRecipient');
    this.form.removeControl('NewContactOrganization');
    this.form.get('RecipientCounterpartyType').setValue('PrivatePerson');
    this.form.get('AddressRecipient').reset({
      mode: 'create',
      type: 'Warehouse',
      data: '',
    });
    this.onClear.next();
    this.inputFocused = false;

    this.googleAnalyticsService.track('analitics', `second-step.searchRecipientRemove`, {
      eventLabel: `Видалення контакта з пошуку`,
      eventCategory: 'create-invoice',
      eventAction: 'search.remove',
    });
  }

  onBlur(event) {
    event.preventDefault();
    if (!event.relatedTarget || (event.relatedTarget && (event.relatedTarget as any).innerText !== 'close')) {
      this.inputFocused = false;
    }
  }

  onFocus(event) {
    this.inputFocused = true;
  }

  ngOnDestroy() {}

}

<div *ngIf="(dataSource.total$ | async) === 0 && (dataSource.loading$ | async)">
  <mat-spinner class="mat-spinner-el" [diameter]="56"></mat-spinner>
</div>

<div class="content-shadow" [hidden]="(dataSource.total$ | async) === 0">
  <stf-table-toolbar [hasSelected]="selection.hasValue()">
    <section table-has-no-selected>
      <section class="mat-table-toolbar mat-default">
        <div class="mat-toolbar-tools">
          <span class="mat-toolbar-tools__title">Реєстри</span>
        </div>
      </section>
    </section>
  </stf-table-toolbar>

  <div class="register__wrapper">
    <mat-progress-bar [style.opacity]="printLoad ? 1 : 0" mode="indeterminate"></mat-progress-bar>
    <table mat-table #table [dataSource]="dataSource.getSnapshot()" multiTemplateDataRows
           class="register-table flex-direction-column">
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef class="register-header__title">Назва</mat-header-cell>
        <mat-cell *matCellDef="let register" class="description_cell">
          <div class="register-header__title">
            <i class="material-icons material-icons_grey register-header__title-arrow">keyboard_arrow_down</i>
            <div class="register-header__number">
              <span class="nowrap register-header__number copy-content" *ngIf="register?.description">
              <span class="register-header__number-item">{{ register.description }}</span>
                <button mat-icon-button class="copy-content__btn"
                        [appCopy]="register.description"
                        [matTooltip]="'Скопіювати'" matTooltipPosition="below" data-test-id="CopyDescription">
                  <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
                </button>
            </span>
              <span class="nowrap register-header__number copy-content" *ngIf="!register?.description">
                <span class="register-header__number-item">{{ register.docNumber }}</span>
                <button mat-icon-button class="copy-content__btn"
                        [appCopy]="register.docNumber"
                        [matTooltip]="'Скопіювати'" matTooltipPosition="below" data-test-id="CopyDescription">
                  <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
                </button>
             </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef class="register-header__date">Дата створення</mat-header-cell>
        <mat-cell *matCellDef="let register"
                  class="register-header__date"
                  data-test-id="RegisterDateTime">{{ register.dateTime ? (register.dateTime | date : "dd.MM.yyyy") : '&mdash;' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef class="register-header__count">Кількість посилок</mat-header-cell>
        <mat-cell *matCellDef="let register" class="register-header__count"
                  data-test-id="RegisterParcelCount">{{ register.count }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef class="register-header__scansheet_number">Номер реєстру</mat-header-cell>
        <mat-cell *matCellDef="let register" class="register-header__scansheet_number nowrap" data-test-id="RegisterDocNumber">
                    <span class="copy-content">
                      {{ register.docNumber }}
                      <button mat-icon-button class="copy-content__btn"
                              [appCopy]="register.docNumber"
                              [matTooltip]="'Скопіювати'" matTooltipPosition="below" data-test-id="CopyDescription">
                        <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
                      </button>
                    </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="printed">
        <mat-header-cell *matHeaderCellDef class="register-header__text">Статус друку</mat-header-cell>
        <mat-cell *matCellDef="let register" class="register-header__text" data-test-id="RegisterPrinted">
          <span *ngIf="register.printed != 0">Роздруковано</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="options" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="register-header__buttons"></th>
        <td mat-cell *matCellDef="let register" class="register-header__buttons border-bottom-0">
          <button mat-icon-button [matMenuTriggerFor]="regMenu"
                  *ngIf="!customRegisters"
                  (click)="$event.stopPropagation(); getInvoices(register.id, register.count)"
                  data-test-id="RegisterMenu">
            <mat-icon class="material-icons_grey">more_vert</mat-icon>
          </button>
          <mat-menu #regMenu="matMenu" class="mat-menu_width_3">
            <mat-progress-bar [style.opacity]="isPrinting ? 1 : 0" mode="indeterminate"></mat-progress-bar>
            <button mat-menu-item
                    [matMenuTriggerFor]="printMenu"
                    *ngIf="register.count && register.count > 0" (click)="$event.stopPropagation()"
                    data-test-id="RegisterMenu-print">
              <mat-icon class="material-icons_grey">print</mat-icon>
              Роздрукувати реєстр
            </button>
            <mat-menu #printMenu="matMenu" class="mat-menu_width_3">
              <button mat-menu-item (click)="printRegisters([register], 'pdf', 'landscape')"
                      data-test-id="RegisterMenu-print-landscape">
                Друк реєстру (альбомний формат)
              </button>
              <button mat-menu-item (click)="printRegisters([register], 'pdf', 'portrait')"
                      data-test-id="RegisterMenu-print-portrait">
                Друк реєстру (книжковий формат)
              </button>
              <button mat-menu-item (click)="printRegisterMarkingZebraHundred(invoices, 'pdf')"
                      *ngIf="!printingm100x100" [disabled]="isPrinting"
                      data-test-id="RegisterMenu-print-100*100">
                Друк маркування 100*100 типу "зебра", PDF
              </button>
              <button mat-menu-item (click)="printSettings(invoices, 'Marking6')"
                      [disabled]="isPrinting"
                      data-test-id="RegisterMenu-print-Marking6">
                Маркування на листі А4 (6 наклейок)
              </button>
              <button mat-menu-item (click)="printA5Register(invoices, 'pdf')"
                      [disabled]="isPrinting"
                      data-test-id="RegisterMenu-print-A5">
                Експрес-накладну, 1 копiя (А5)
              </button>
              <button mat-menu-item (click)="printRegisterOneCopy(invoices, 'pdf')"
                      [disabled]="isPrinting"
                      data-test-id="RegisterMenu-print-1copy">
                Експрес-накладну, 1 копiя
              </button>
              <button mat-menu-item (click)="printRegister(invoices, 'pdf')"
                      [disabled]="isPrinting"
                      data-test-id="RegisterMenu-print-2copy">
                Експрес-накладну, 2 копiї
              </button>
            </mat-menu>
            <button mat-menu-item
                    (click)="rename(register)"
                    data-test-id="RegisterMenu-print">
              <mat-icon class="material-icons_grey">edit</mat-icon>
              Змінити назву реєстру
            </button>
            <button mat-menu-item
                    [confirmMsg]="'<h4>Дійсно бажаєте розформувати даний реєстр?</h4> Після розформування реєстру можна переглянути кожну посилку окремо у розділі Чернетка'"
                    [isConfirmHtml]="true"
                    [confirmBtnText]="'Так, розформувати'"
                    [cancelBtnText]="'Ні, залишити'"
                    (click)="$event.stopPropagation()"
                    (confirm)="remove(register.id)"
                    data-test-id="RegisterMenu-RemoveRegister">
              <mat-icon class="material-icons material-icons_delete-sweep fz-28 material-icons_grey">delete_sweep
              </mat-icon>
              Розформувати реєстр
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"
                      class="register-table__row register-table__row_header"></mat-header-row>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let register" [attr.colspan]="displayedColumns.length">
          <div class="element-detail d-flex flex-direction-column"
               [@detailExpand]="register == expandedElement ? 'expanded' : 'collapsed'">
            <app-registers-show *ngIf="register == expandedElement"
                                [registerDescription]="register.description"
                                [testId]="register.id"
                                [customRegisters]="customRegisters"
                                (onRemoveRegister)="update()"
                                (onRemovedFromRegister)="updateRegisterHeaderCount($event, register)"
                                (onPrintRegister)="updateRegisterHeaderPrinted(register, $event)"
                                (onRenameRegister)="updateRegisterHeaderDescription(register, $event)"
            ></app-registers-show>

          </div>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let register; columns: displayedColumns;"
          class="element-row"
          [class.expanded-row]="expandedElement === register"
          (click)="expandedElement = expandedElement === register? null : register"
          data-test-id="RegisterRow">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>
</div>

<div [hidden]="(dataSource.total$ | async) === 0" class="mb-3">
  <mat-paginator [length]="dataSource.total$ | async" [pageSize]="itemsPerPage" (page)="onPageChange($event)"
                 [pageSizeOptions]="limitsList" [showFirstLastButtons]="false"></mat-paginator>
</div>


<div class="d-flex flex-direction-column justify-content-center align-items-center h-100"
     *ngIf="!(dataSource.loading$ | async) && (dataSource.total$ | async) === 0">
  <div class="empty-page">
    <div class="empty-page__icon">
            <span class="empty-page__icon-wrapper">
                 <mat-icon class="material-icons register-main_44">library_books</mat-icon>
            </span>
    </div>
    <div class="empty-page__title">У вас поки що немає жодного реєстру</div>
    <div class="empty-page__text">Тут буде перелік створених вами реєстрів. Щоб додати посилки до реєстру, позначте
                                  галочками створені посилки, які ви хочете об’єднати, й натисніть Створити реєстр.
    </div>
  </div>
</div>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-grid-footer',
  templateUrl: './custom-grid-footer.component.html',
  styleUrls: ['./custom-grid-footer.component.scss']
})
export class CustomGridFooterComponent {
  @Input() total: number;
}

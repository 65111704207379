import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs';
import {
  ChangeEwOrderItem,
  ChangeEwSaveRequest,
  ChangeEwSaveResponseData,
  PossibilityChangeEw,
} from '../shared/components/change-ew/models';
import {IListData, IOrderParameter} from '../models/iapi';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {IFilterParam} from './invoices/iapi/iinvoice.api';
import {NoRestApiHelper} from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class ChangeEwService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
    protected _authorizationService: AuthService) {
  }

  checkPossibilityChange(docNumber: string | number): Observable<PossibilityChangeEw> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'CheckPossibilityChangeEW',
      methodProperties: {
        IntDocNumber: '' + docNumber,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(response => response.data.data[0] as PossibilityChangeEw),
      );
  }

  save(parameters: { docNumber: string | number, data: Partial<ChangeEwSaveRequest> }): Observable<ChangeEwSaveResponseData> {
    const {docNumber, data} = parameters;
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'save',
      methodProperties: {
        IntDocNumber: '' + docNumber,
        OrderType: 'orderChangeEW',
        ...data,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        // tslint:disable-next-line:no-shadowed-variable
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(response => response.data.data[0]),
      );
  }

  getList(filter: IFilterParam = {},
          order: IOrderParameter = null,
          page: number = 1,
          limit: number = 100000): Observable<IListData<ChangeEwOrderItem>> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'getChangeEWOrdersList',
      methodProperties: {
        BeginDate: moment().subtract(3, 'months').format('DD.MM.YYYY'),
        EndDate: moment().format('DD.MM.YYYY'),
        Page: page,
        Limit: limit,
        OrderField: 'DateTime',
        SearchByCounterparties: this._authorizationService.user.contacts ? 1 : null,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(response => ({
          list: response.data.data,
          total: response.data.info.totalCount,
        }) as IListData<ChangeEwOrderItem>),
      );
  }
}

import { Component, EventEmitter, Input, OnDestroy, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

import { TypeRadioButton } from './type-radio-button';


@Component({
  selector: 'np-inv-type-radio-group',
  templateUrl: 'type-radio-group.component.html',
  styleUrls: ['./type-radio-group.component.scss'],
})
export class TypeRadioGroupComponent implements ControlValueAccessor, OnDestroy {

  control: UntypedFormControl = new UntypedFormControl('');

  @Input()
  form: UntypedFormGroup;

  @Input()
  types: TypeRadioButton[];

  @Input()
  disabled: false;

  @Input()
  modifyClass: string;

  @Output()
  onTypeChanged = new EventEmitter<MatRadioChange>();

  changeFn: (_: any) => void;

  touchFn: () => void;

  constructor(@Self() @Optional() private ngControl: NgControl,
              @Optional() private parent: FormGroupDirective) {
    if (parent) {
      this.form = parent.form;
    }
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  changeType(event: MatRadioChange): void {
    this.onTypeChanged.next(event);
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
    this.control.valueChanges

      .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(obj: any): void {
    this.control.setValue(obj, {emitEvent: false});
  }

  ngOnDestroy(): void {
  }
}

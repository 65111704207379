<div class="modal modal_large" [ngClass]="{'is-full': isShowFull}">
  <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); dialogRef.close()">
    <i class="material-icons">close</i>
  </span>
  <div class="modal-header">
    <div class="invoice__date" *ngIf="inputInvoice?.dateTime || inputInvoice?.DateTime">
      Створена: {{(inputInvoice?.dateTime | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss")
    || (inputInvoice?.DateTime | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss") }}
      <span *ngIf="invoice?.OwnerDocumentNumber"
            class="invoice__date-hint">{{inputInvoice?.note}}</span>
    </div>
    <div class="invoice-header">
      <div class="invoice-header__left">
        <mat-icon *ngIf="type === 'out'" class="material-icons invoice-header__icon-type">call_made</mat-icon>
        <mat-icon *ngIf="type === 'in'" class="material-icons invoice-header__icon-type">call_received</mat-icon>
        <mat-icon *ngIf="type === 'arch-main'" class="material-icons invoice-header__icon-type">archive</mat-icon>
        <div class="invoice-header__number copy-content copy-content_lg">{{formattedInvoiceNumber}}
          <button mat-icon-button class="copy-content__btn"
                  [appCopy]="invoice?.docNumber"
                  [matTooltip]="'Скопіювати'" matTooltipPosition="below">
            <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
          </button>
        </div>
        <np-share-invoice-state *ngIf="invoice"
                                [invoice]="invoice"
                                [backMoney]="backMoney"
                                [paymentControl]="paymentControl"
                                [contactPhone]="contactPhone">
        </np-share-invoice-state>
      </div>
      <div class="invoice-header__right">
        <div class="invoice-header__info">
          <div class="info-cell invoice-header__cell">
            <div class="info-cell__label">Опис відправлення</div>
            <div class="info-cell__content"
                 [matTooltip]="inputInvoice?.CargoDescription"
                 matTooltipPosition="below">{{inputInvoice?.CargoDescription || '&mdash;'}}</div>
          </div>
          <div class="info-cell invoice-header__cell">
            <div class="info-cell__label">Оголошена вартість</div>
            <div class="info-cell__content" *ngIf="inputInvoice?.announcedPrice || inputInvoice?.Cost;else dash">
              {{inputInvoice?.announcedPrice || inputInvoice?.Cost}} грн
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <main class="modal-content modal-content_pad-none">
    <mat-progress-bar [style.opacity]="+(loading$ | async)" mode="indeterminate">
    </mat-progress-bar>
    <div class="invoice">
      <div class="banner"
           *ngIf="(invoice?.deliveryPrice !== invoice?.SumBeforeCheckWeight && invoice?.SumBeforeCheckWeight > 0) || invoice?.CalculatedWeight > 0">
        <div class="banner__info">
          <div class="warning-message">
            <div class="warning-message__text">
              <span *ngIf="invoice?.CalculatedWeight > 0">Вага відправлення змінена по результатам контрольного зважування. <br></span>
              <span
                *ngIf="(invoice?.deliveryPrice !== invoice?.SumBeforeCheckWeight && invoice?.SumBeforeCheckWeight > 0)">Вартість доставки змінена по результатам контрольного зважування. <br></span>
              У випадку незгоди з результатами зважування, у вас є можливість перезважити відправлення в момент
              отримання.
            </div>
          </div>
        </div>
      </div>
      <div class="invoice-body">
        <div class="invoice-body__left">
          <div class="invoice-state" [ngClass]="statusClass">
            <div class="invoice-state__progress" [npInvoiceProgress]="invoice?.state.id"></div>
            <div class="invoice-state__startpoint">
              Дата
              виїзду: {{(inputInvoice?.dateTime | np_to_date_format| date :"dd.MM.yyyy") || (inputInvoice?.DateTime | np_to_date_format| date :"dd.MM.yyyy")}}
              <div class="invoice-state__startpoint-location">{{inputInvoice?.SenderAddressDescription}}</div>
            </div>
            <div class="invoice-state__name" *ngIf="invoice?.state?.id === '5' || invoice?.state?.id === '4'"
                 [ngClass]="statusClass + '__color'">
              <span>{{invoice?.state?.name}}</span>
              <span *ngIf="invoice?.state?.name === 'Відправлення отримано'">
                           {{invoice?.receivingTime | np_to_date_format| date :"dd.MM.yyyy"}}
              </span>
              <span *ngIf="invoice?.state?.name === 'Прибув у відділення'">
                           {{(invoice?.actualDeliveryDate || invoice?.ArrivalDateTime) | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss"}}
              </span>
            </div>

            <div class="invoice-state__name invoice-state__name_width"
                 *ngIf="invoice?.state?.id !== '4' && invoice?.state?.id !== '5' && invoice?.state?.id !== '0'"
                 [matTooltip]="invoice?.state?.name"
                 matTooltipPosition="below"
                 [ngClass]="statusClass + '__color'">
              {{invoice?.state?.name}}
            </div>
            <div class="invoice-state__endpoint">
              <div
                *ngIf="invoice?.state?.id !== '4' && invoice?.state?.id !== '5' && invoice?.state?.id !== '0' && invoice?.state?.id !== '6'">
                Плановий час доставки: {{invoice?.scheduleDeliveryDate | np_to_date_format | deliveryDate}}</div>
              <div *ngIf="invoice?.state?.id === '5' && inputInvoice?.receivingTime && invoice?.receivingTime">
                Дата
                отримання: {{(inputInvoice?.receivingTime | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss") || (invoice?.receivingTime | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss")}}
              </div>
              <div *ngIf="invoice?.dateFirstDayStorage
                        && invoice?.dateFirstDayStorage.length > 0
                        && invoice?.dateFirstDayStorage !== '0001-01-01 00:00:00'
                        && (!inputInvoice?.ReceivingDateTime?.length
                        || inputInvoice?.ReceivingDateTime === '0001-01-01 00:00:00')">
                Платне зберігання з:
                {{invoice?.dateFirstDayStorage | np_to_date_format| date :"dd.MM.yyyy"}}
              </div>
              <div class="invoice-state__endpoint-location">
                {{inputInvoice?.RecipientAddressDescription}}
              </div>
            </div>
          </div>
        </div>
        <div class="invoice-body__right">
          <div>
            <div class="invoice-body__info">
              <div class="info-cell invoice-body__info-cell">
                <div class="info-cell__label">Отримувач</div>
                <div class="info-cell__content">
                  <ng-container
                    *ngIf="recipientCounterpartyType !== 'Приватна особа' && recipientCounterpartyType !== 'PrivatePerson'">
                    {{ inputInvoice?.CounterpartyRecipientDescription || '&mdash;'}}<br>
                  </ng-container>
                  {{inputInvoice?.RecipientName || '&mdash;'}}
                </div>
              </div>
              <div class="info-cell invoice-body__info-cell">
                <div class="info-cell__label">Телефон отримувача</div>
                <div class="info-cell__content nowrap-text" *ngIf="contactPhone || invoice?.recipient?.phone">
                  <span class="nowrap copy-content">
                    {{(contactPhone | npFinePhone) || (invoice?.recipient?.phone | npFinePhone) || '&mdash;'}}
                    <button mat-icon-button class="copy-content__btn"
                            [appPhoneCopy]="contactPhone || invoice?.recipient?.phone"
                            [matTooltip]="'Скопіювати'" matTooltipPosition="below">
                      <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
                    </button>
                  </span>
                </div>
                <div class="info-cell__content" *ngIf="!contactPhone && !invoice?.recipient?.phone">
                  &mdash;
                </div>
              </div>

              <div class="info-cell invoice-body__info-cell last"
                   *ngIf="inputInvoice?.RecipientFullName &&
                          inputInvoice?.RecipientFullName !== '' &&
                          inputInvoice?.receivingTime &&
                          inputInvoice?.receivingTime !== '0001-01-01 00:00:00' &&
                          inputInvoice?.statusCode !== '102' && inputInvoice?.statusCode !== '103' && inputInvoice?.statusCode !== '108'">
                <div class="info-cell__label">Отримав(ла)</div>
                <div class="info-cell__content nowrap-text">
                  {{inputInvoice?.RecipientFullName || '&mdash;'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="invoice-services"
           *ngIf="inputInvoice?.backDeliveryPayer
           || !!inputInvoice?.paymentControl
           || (inputInvoice.RedirectingReturnIndication && !redirectRequest && !returnRequest)">
        <div class="invoice-services__title">Додаткові послуги</div>

        <div class="invoice-services__item" *ngIf="(inputInvoice.RedirectingReturnIndication && !redirectRequest && !returnRequest)">
          <div class="invoice-services__name nowrap-text">
            <i class="material-icons invoice-services__icon">{{ inputInvoice.RedirectingReturnIndication.icon }}</i>
            <span>{{ inputInvoice.RedirectingReturnIndication.tooltipText }}</span>
          </div>
        </div>

        <div class="invoice-services__item wrap" *ngIf="inputInvoice?.backDeliveryPayer">
          <ng-container *ngIf="backMoney">
            <div class="invoice-services__name nowrap-text">
              <i class="material-icons invoice-services__icon">reply</i>
              <span>Зворотна доставка: {{(inputInvoice?.backMoney + ' грн') || '0'}}</span>
            </div>
            <div class="invoice-services__text nowrap-text" *ngIf="inputInvoice?.Cash2CardPAN === ''">
              <span *ngIf="inputInvoice?.backMoney">• Вартість комісії: {{paymentSum + ' грн'}}&nbsp;</span>
            </div>
            <div class="invoice-services__text nowrap-text">• Платник за
                                                            комісію: {{(payerNameTranslationsLong[inputInvoice?.backDeliveryPayer])}}
            </div>
            <div class="invoice-services__text nowrap-text" *ngIf="type === 'out'">
              &nbsp;•&nbsp;Тип доставки: {{((inputInvoice?.moneyTransferCash2Card)
              ? 'На картку ' + inputInvoice?.cardMaskedNumber
              : 'У відділення')}}
            </div>
          </ng-container>
        </div>
        <div class="invoice-services__item" *ngIf="!!inputInvoice?.paymentControl">
          <i class="material-icons invoice-services__icon">reply</i>
          <span class="invoice-services__name">Контроль оплати:
            {{(inputInvoice?.paymentControl) + ' грн'}}</span>
        </div>
        <div class="invoice-services__item" *ngIf="!!inputInvoice?.AccompanyingDocuments">
          <div class="invoice-services__name d-flex w-100 nowrap-text align-items-center">
            <i class="material-icons invoice-services__icon">description</i>
            <span class="text-overflow-ellipsis">Супроводжуючі документи: {{inputInvoice?.AccompanyingDocuments}}</span>
          </div>
        </div>
      </div>

      <div class="invoice__payment">
        <div class="info-row invoice__row">
          <div class="info-row__left">
            <div class="info-row__col">
              <div class="info-cell info-row__cell">
                <div class="info-cell__label">Вартість доставки</div>
                <div *ngIf="invoice?.deliveryPrice !== invoice?.SumBeforeCheckWeight"
                     class="info-cell__content info-cell__content_lg">
                  <span class="line-through-text">{{invoice?.SumBeforeCheckWeight}}</span>
                  {{(invoice?.deliveryPrice + invoice?.StoragePrice) + ' грн'}}
                </div>
                <div *ngIf="invoice?.deliveryPrice === invoice?.SumBeforeCheckWeight
                && !checkIfPaid(paymentCostsInfo)
                || invoice?.lastCreatedOnTheBasisDocumentType"
                     class="info-cell__content info-cell__content_lg">
                  {{(invoice?.deliveryPrice + invoice?.StoragePrice) + ' грн'}}
                </div>
                <div *ngIf="invoice?.deliveryPrice === invoice?.SumBeforeCheckWeight
                && (paymentCostsInfo && checkIfPaid(paymentCostsInfo))
                && !invoice?.lastCreatedOnTheBasisDocumentType"
                     class="info-cell__content info-cell__content_lg">
                  <span class="line-through-text">{{(invoice?.deliveryPrice + invoice?.StoragePrice) + ' грн'}}</span>
                  Сплачено
                </div>
              </div>
            </div>
            <div class="info-row__col">
              <div class="info-cell info-row__cell">
                <div class="info-cell__label">Платник за доставку</div>
                <div class="info-cell__content">
                  {{payerNameTranslationsShort[invoice?.payer]}}
                </div>
              </div>
            </div>
          </div>
          <div class="info-row__right">
            <div class="info-cell info-row__cell">
              <div class="info-cell__label">Форма оплати за доставку</div>
              <div class="info-cell__content">
                {{(paymentMethodTranslations[invoice?.paymentMethod?.id]) || paymentMethodTranslations[invoice?.PaymentMethod]}}
              </div>
            </div>
            <div
              [matTooltip]="!paymentCostsInfo || (paymentCostsInfo && checkIfPaid(paymentCostsInfo)) || paymentCostsInfo?.UseNovaPay === null ? 'Оплата недоступна' : null"
              class="payment-btn-left"
              matTooltipPosition="below">
              <button *ngIf="((type === 'in' || type === 'out') && !isPressentedInTrackingDocuments(invoice?.docNumber)) &&
                      ((inputInvoice?.recipient?.phone || inputInvoice?.sender?.phone) || (invoice?.PhoneSender || invoice?.PhoneRecipient))"
                      class="mat-button_sm mat-button_uppercase invoice__payment-btn"
                      (click)="createPayment(invoice, type)"
                      [disabled]="checkPaymentDisabled()"
                      color="primary"
                      mat-raised-button>
                Сплатити
              </button>
            </div>
          </div>
        </div>
      </div>
      <np-invoice-full-info-modal *ngIf="isShowFull"
                                  [invoice]="invoice"
                                  [invoiceFullData]="inputInvoice">
      </np-invoice-full-info-modal>
      <div class="invoice-return-info" *ngIf="!!(redirectRequest || returnRequest)">
        <div class="invoice-return-info__title" *ngIf="!!returnRequest">Повернення посилки</div>
        <div class="invoice-return-info__title" *ngIf="!!redirectRequest">Переадресування посилки</div>
        <div class="invoice-return-info__location">{{newInvoice?.recipientAddress?.shortDescription}}</div>
        <button type="button"
                (click)="goToNewInvoice(redirectRequest?.newInvoiceNumber  || returnRequest?.newInvoiceNumber, $event)"
                class="mat-button_transparent matF-button_sm mat-button_uppercase invoice-return-info__btn"
                mat-button color="primary">
          Нова накладна {{(redirectRequest?.newInvoiceNumber || returnRequest?.newInvoiceNumber)|fineDocNumber}}
        </button>
        <div class="invoice-return-info__total">Вартість
                                                доставки: {{(newInvoice?.deliveryPrice + newInvoice?.StoragePrice) + ' грн'}}</div>
      </div>
    </div>
  </main>
  <div class="modal-footer p-2">
    <div class="modal-footer-buttons">
      <div class="modal-footer-buttons__item">
        <button type="button"
                class="mat-button_with-icon"
                [disableRipple]="true" (click)="switchShowFull()"
                mat-button>
          <mat-icon *ngIf="!isShowFull" class="material-icons_grey button-more__icon">keyboard_arrow_down
          </mat-icon>
          <mat-icon *ngIf="isShowFull" class="material-icons_grey button-more__icon">keyboard_arrow_up
          </mat-icon>
          {{!isShowFull ? 'Показати більше' : 'Приховати'}}
        </button>
      </div>
      <div class="modal-footer-buttons__item modal-footer-buttons__right">
<!--        <app-donate-button></app-donate-button>-->
        <button mat-button (click)="copy(invoice)"
                *ngIf="(type === 'out' && !isPressentedInTrackingDocuments(invoice?.docNumber)
                        && (invoice?.docNumber.slice(0, 2)) === '20') || ((type === 'arch-main') && isPossibilityDuplicate)"
        >
          <mat-icon class="material-icons_grey"
                    *ngIf="invoice?.marketPlaceToken !== '005056b24375-ad0d-11e8-a601-05690e3b'"
                    [matTooltip]="'Дублювати'"
                    matTooltipPosition="below">file_copy
          </mat-icon>
        </button>

        <button mat-button [matMenuTriggerFor]="printMenu" [matTooltip]="'Друкувати'"
                *ngIf="!isPressentedInTrackingDocuments(invoice?.docNumber)">
          <mat-icon class="material-icons_grey">print</mat-icon>
        </button>

        <mat-menu #printMenu="matMenu" class="mat-menu_width_3">
          <button mat-menu-item (click)="printOneCopy([invoice])">
            Експрес-накладну, 1 копiя
          </button>
          <button mat-menu-item (click)="print([invoice])">
            Експрес-накладну, 2 копiї
          </button>
        </mat-menu>
        <button mat-button
                *ngIf="type === 'in' && isPressentedInTrackingDocuments(invoice?.docNumber)"
                [confirmMsg]="'Ви впевнені, що бажаєте виконати цю дію?'"
                [confirmBtnText]="'Видалити'"
                [cancelBtnText]="'Скасувати'"
                [matTooltip]="'Видалити'"
                matTooltipPosition="below"
                (confirm)="removeFromTrackingDocuments()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-button
                *ngIf="inputInvoice.possibilityCreateLightReturn && (type === 'in' || type === 'arch-main') "
                [disabled]="(lightReturnPending$ | async)"
                (click)="lightReturn()"
                [matTooltip]="'Легке повернення'"
                matTooltipPosition="below"
        >
          <mat-icon>subdirectory_arrow_left</mat-icon>
        </button>
        <button mat-button
                *ngIf="!currentStateIsArchived && !isPressentedInTrackingDocuments(invoice?.docNumber)"
                class="mat-button_with-icon"
                [disabled]="currentStateIsArchived ||
                    ((invoice?.state.id !== '5') && ((inputInvoice?.statusCode !== '102') &&
                    (inputInvoice?.statusCode !== '103') && (inputInvoice?.statusCode !== '108'))) ||
                    (['2', '5', '1'].indexOf(invoice?.docNumber[0]) === -1)"
                [matTooltip]="'Архівувати'"
                matTooltipPosition="below"
                (click)="addDocumentToArchive(inputInvoice)">
          <mat-icon>archive</mat-icon>
        </button>
        {{ type }}
        <button
          mat-button
          *ngIf="(type === 'in' || type === 'out' || type === 'arch-main') && !isPressentedInTrackingDocuments(invoice?.docNumber)"
          (click)="claim(invoice)"
          class="mat-button_with-icon"
        >
          <mat-icon class="material-icons_grey"
                    [matTooltip]="'Зафіксувати звернення'"
                    matTooltipPosition="below">flag
          </mat-icon>
        </button>
        <button mat-button [matMenuTriggerFor]="appMenu"
                *ngIf="inputInvoice.allowManageButton"
                class="mat-button_with-icon"
        >
          <mat-icon>swap_vertical_circle</mat-icon>
          Управління
        </button>
        <mat-menu #appMenu="matMenu" class="max-width-400">
          <button *ngIf="inputInvoice.possibilityCreateRedirecting" mat-menu-item
                  (click)="redirectInvoice(inputInvoice)">
            {{ checkCanEdit(inputInvoice.OrderingAdditionalServices, 'Redirecting')
            ? "Редагування переадресації"
            : "Заявка на переадресацію" }}
          </button>
          <button *ngIf="inputInvoice.possibilityCreateReturn" mat-menu-item (click)="returnInvoice(inputInvoice)">
            {{ checkCanEdit(inputInvoice.OrderingAdditionalServices, 'CargoReturn')
              ? "Редагування повернення"
              : "Заявка на повернення" }}
          </button>
          <button *ngIf="inputInvoice.possibilityChangeEW" mat-menu-item (click)="changeEw(inputInvoice)">
            Заявка на зміну даних
          </button>
          <button *ngIf="inputInvoice.possibilityTermExtension" mat-menu-item
                  (click)="changeCustomerReturn(inputInvoice)">
            Заявка на подовження терміну зберігання
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<ng-template #dash>—</ng-template>

<ng-container [formGroup]="form">
  <ng-container [formGroupName]="controlName">
    <ng-container *ngIf="!mode || mode === 'select'; else editModeTemplate">
      <app-autocomplete-with-controls data-test-id="SenderContactSelect"
                                      class="form-row mr-bottom-16"
                                      [formControl]="formGroup.get('data')"
                                      [options]="options"
                                      [transformFn]="transformFn"
                                      [config]="config"
                                      [controlsConfig]="controlsConfig"
                                      [preventInput]="true"
                                      (onControlTriggered)="changeMode($event)"
                                      (onDropdownSearch)="searchContact($event)"
                                      [dropdownSearching]="dropdownSearching"
                                      [dropdownSearchEnabled]="true">
        <ng-template appAutocompleteOption let-option>
          {{ option?.Description }} {{ option?.Phones | npFinePhone }}
        </ng-template>
        <app-autocomplete-error *ngIf="!!errorMessages[CONTROL_NAMES.DATA]">
          {{ errorMessages[CONTROL_NAMES.DATA] }}
        </app-autocomplete-error>
      </app-autocomplete-with-controls>
    </ng-container>
    <ng-template #editModeTemplate>
      <div class="form-row padding-none new-contact-row">
        <np-inv-edit-container data-test-id="SenderContactCreate"
                               [controlName]="controlName"
                               [formTypeName]="controlTypeName"
                               [isCancelable]="mode === 'edit'"
                               (onCancel)="cancel()">
          <np-inv-contact-private-person
            [form]="form.get(controlName)"
            [controlName]="CONTROL_NAMES.DATA"
            [hintSubstitution]="false"
            [contactProperty]="'Sender'"></np-inv-contact-private-person>
        </np-inv-edit-container>
        <button *ngIf="saveBtnShown"
                mat-button
                (click)="changeFullName()"
                color="primary">Зберегти</button>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<div class="mat-stf-accordion__wrapper"
     [ngClass]="{'mat-stf-accordion__item_active': open}"
>
  <div class="mat-stf-accordion__item">
    <div class="mat-stf-accordion__header">
      <section>
        <ng-content select="[header]"></ng-content>
      </section>
    </div>
    <ng-container *ngIf="showExpandable">
      <div class="mat-stf-accordion__body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="mat-stf-accordion__panel">
        <button (click)="openClose()"
                mat-button
                type="button"
                class="mat-button_with-icon">
          <i class="material-icons material-icons_grey">{{!open ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</i>
          {{!open ? 'Показати більше' : 'Приховати'}}
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-container position-relative p-6">
  <span (click)="close()" class="close-btn">
      <mat-icon class="material-icons_grey material-icons">close</mat-icon>
    </span>

  <h4 class="modal-title">
    Заявка {{ Number ? Number + ' ' : '' }}на зміну даних
  </h4>

  <div class="modal-content">
      <p *ngIf="messageText">{{ messageText }}</p>
      <p *ngIf="alertForCall">{{ alertForCall }}</p>
      <p *ngIf="!messageText && !alertForCall">Зміни буде внесено до ЕН</p>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { RadioTableFilter, SmartFormContainer } from '@shared';

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss'],
})
export class RadioFilterComponent extends SmartFormContainer {
  @Input() config: RadioTableFilter;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';

@Component({
  selector: 'app-doorstep-pickup',
  templateUrl: './doorstep-pickup.component.html',
  styleUrls: ['./doorstep-pickup.component.scss']
})
export class DoorstepPickupComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  FloorMax = 99;
  FloorMin = 1;

  @Input() controlName = 'DoorstepPickupData';
  @Input() showTooltipFloor = false;
  @Input() showTooltipElevator = false;

  doorstepPickupGroup: UntypedFormGroup;

  doorstepPickupDataGroup: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    this.doorstepPickupGroup = this.form.get('AdditionalServices').get('doorstepPickup') as UntypedFormGroup;
    this.doorstepPickupDataGroup = this.formBuilder.group({
      NumberOfFloorsDescent: ['', Validators.required],
      Elevator: [false, []],
    });
    this.doorstepPickupGroup.addControl(this.controlName, this.doorstepPickupDataGroup);
  }

  ngOnDestroy() {
    this.doorstepPickupGroup.removeControl(this.controlName);
  }
}

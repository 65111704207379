<div [formGroup]="form">
  <mat-form-field [attr.data-test-id]="controlName"
                  class="form-input mt-6">
    <mat-label>{{label}}</mat-label>

    <input [formControl]="control"
           [matAutocomplete]="autocomplete"
           [maxLength]="maxLength"
           (focus)="getPromocodes()"
           (paste)="paste($event)"
           matInput
           InputTrim
           type="text"
           autocomplete="off"
           class="text-overflow-ellipsis"
           autoScrollTarget>

    <button *ngIf="!control?.value && !loading && items?.length"
            class="p-0"
            mat-button
            matSuffix
            mat-icon-button>
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>

    <button *ngIf="control?.value && !loading"
            (click)="clearField(control, $event, '')"
            class="p-0"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>

    <button *ngIf="loading"
            class="p-0 transform"
            mat-button
            matSuffix
            mat-icon-button
            [disabled]="true">
      <mat-spinner [diameter]="19.5" class="form-field-spinner"></mat-spinner>
    </button>

    <mat-autocomplete #autocomplete="matAutocomplete"
                      [displayWith]="displayTransformFn"
                      (optionSelected)="blur()">
      <mat-option *ngFor="let item of items"
                  [disabled]="item.config?.disabled"
                  [matTooltip]="item.config?.tooltip"
                  [value]="item.option">
        <p>{{item.option?.Promocode}}</p>
        <span class="promocode-date">Дійсний до: {{
          item.option?.DateOfEnding
          | np_to_date_format
          | date : "dd.MM.yyyy"
          }}</span>
      </mat-option>
    </mat-autocomplete>

    <mat-error>{{getError(control, errors)}}</mat-error>
  </mat-form-field>
</div>

<div class="modal modal_small">
    <span class="modal__close ngdialog-close" (click)="close()">
        <i class="material-icons">close</i>
    </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">Налаштування друку</h3>
      </div>
    </div>
  </div>
  <mat-progress-bar [style.opacity]="(isPending) ? 1 : 0"
                    mode="indeterminate">
  </mat-progress-bar>
  <main class="modal-content p-0">
    <div class="form-row">
      <div class="form-col form-col-8">
        <mat-form-field>
          <mat-select placeholder="Кількість маркувань (наклейок) для 1-ї накладної" [(value)]="selectedCopies">
            <mat-option *ngFor="let amount of amounts" [value]="amount.value">
              {{ amount.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="modal-content__text p-6">Обрати розташування маркування на листі</div>
    <div class="print-markup mb-1 d-flex justify-content-between align-items-center" *ngIf="checked">
      <div class="print-markup__item d-flex justify-content-center align-items-center cursor-pointer"
           [ngClass]="{'print-markup__item_empty':selectedMarkup > i+1}"
           *ngFor="let item of markupItems; let i = index" (click)="selectMarkup(i+1)">
        <div class="print-markup__number text-center">{{i + 1}}</div>
      </div>
    </div>
  </main>
  <div class="modal-footer">
    <div class="modal-footer-buttons">
      <div class="modal-footer-buttons__item">
        <button type="button" mat-button (click)="close()">скасувати</button>
      </div>
      <div class="modal-footer-buttons__item">
        <button type="button" mat-button (click)="print()" color="primary">Друкувати</button>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
// Formats to hide/show time
const shortFormat = 'DD.MM.YYYY';
const extendedFormat = 'DD.MM.YYYY HH:mm';

@Pipe({
  name: 'deliveryDate',
})
export class DeliveryDatePipe implements PipeTransform {
  // Change format to show (extendedFormat) or hide (shortFormat) time
  transform(date: string, format: string = extendedFormat): string {
    if (date) {
      return moment(date).format(format);
    }
    return '—';
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FailedObject,
  PartialSuccessConfig,
  PartialSuccessDataType,
  PartialSuccessModalConfig,
} from './partial-success.model';
import { PartialSuccessService } from './partial-success.service';

@Component({
  selector: 'app-partial-success',
  templateUrl: './partial-success.component.html',
  styleUrls: ['./partial-success.component.scss'],
})
export class PartialSuccessComponent {

  constructor(
    private pss: PartialSuccessService,
    @Inject(MAT_DIALOG_DATA) private data: PartialSuccessModalConfig,
  ) {
  }

  get config(): PartialSuccessConfig {
    return this.data.config;
  }

  get succeededAmount(): number {
    return this.data.data?.total?.succeededAmount || 0;
  }

  get failedAmount(): number {
    return this.data.data?.total?.failedAmount || 0;
  }

  get totalAmount(): number {
    return this.data.data?.total?.totalAmount || 0;
  }

  get titleLabel(): string {
    return this.config.label || '';
  }

  get failedOptions(): Array<FailedObject> {
    return this.data.data.failed;
  }

  close(): void {
    this.pss.partialSuccessRef.close();
  }

  protected readonly PartialSuccessDataType = PartialSuccessDataType;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SEARCH_DEBOUNCE_TIME, SEARCH_MAX_LENGTH } from "@autocomplete";

@Component({
  selector: 'app-autocomplete-search',
  templateUrl: './autocomplete-search.component.html',
  styleUrls: ['./autocomplete-search.component.scss'],
})
export class AutocompleteSearchComponent implements OnInit {
  @Input() loading: boolean = false;

  @Input() searchDebounce: number = SEARCH_DEBOUNCE_TIME;

  @Input() searchMaxLength: number = SEARCH_MAX_LENGTH;

  @Input() placeholder: string = 'Пошук у контактах за ПІБ';

  @Output() textInput: EventEmitter<string> = new EventEmitter<string>();

  control: UntypedFormControl = new UntypedFormControl();

  inputFocused: boolean = false;

  ngOnInit(): void {
    this.initInputValueSubscription();
  }

  initInputValueSubscription(): void {
    this.control.valueChanges
      .pipe(debounceTime(this.searchDebounce), distinctUntilChanged())
      .subscribe((query) => this.textInput.emit(query));
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    this.control.reset('');
    this.textInput.emit('');
  }

  onBlur(event): void {
    event.preventDefault();
    if (
      !event.relatedTarget ||
      (event.relatedTarget &&
        (event.relatedTarget as any).innerText !== 'close')
    ) {
      this.inputFocused = false;
    }
  }

  onFocus(): void {
    this.inputFocused = true;
  }
}

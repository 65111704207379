<div class="services-list__form" [formGroup]="BackwardDeliveryDataGroup">
  <div class="form-row">
    <div class="form-col form-col-1 form-col-1_with_symbol" *ngIf="displaySum">
      <mat-form-field class="mat-block mat-block_with_symbol" data-symbol="грн">
        <input matInput
               npNumber
               placeholder="Сума"
               formControlName="RedeliveryString"
               #sum
               NumberInput="true"
               [maxValue]="1000000"
               [allowFloat]="true"
               [digitsAfterDot]="2"
               (change)="change($event)"
               (input)="changeSum(sum.value)">
      </mat-form-field>
    </div>

    <div class="form-col form-col-5">
      <div class="label-radio">Платник за комісію
        <i
          [matTooltip]="'Комісія - вартість послуги переказу коштів відправнику посилки. Розмір комісії - 2% від суми переказу + 20 грн.'"
          class="material-icons icon-help cursor-pointer"
          matTooltipPosition="below">help_outline</i>
      </div>
      <mat-radio-group class="radio-row" formControlName="PayerType">
        <mat-radio-button
          [value]="'Sender'"
          [matTooltip]="senderName"
          [disabled]="receivingTypeIsCard"
        >Відправник посилки
        </mat-radio-button>
        <mat-radio-button [value]="'Recipient'" [matTooltip]="recipientName">Отримувач посилки</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="receivingTypeSelectAvailable"
       class="form-row mr-bottom-16 box-with-controls">
    <div class="form-col">
      <div class="label-radio">Форма отримання</div>
      <mat-radio-group class="radio-row nowrap-text"
                       (change)="onChangeReceivingType($event)"
                       formControlName="ReceivingType">
        <mat-radio-button
          [value]="'Warehouse'">У відділенні
        </mat-radio-button>
        <mat-radio-button
          [value]="'Card'"
          [matTooltip]="checkDisableToCardTooltip()"
          [disabled]="checkDisableToCard()">На картку
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="displayAddCard"
         class="form-col form-col_with-btn"
         matTooltipPosition="below"
         [matTooltip]="addCardTooltip">
      <button
        mat-button
        color="primary"
        [disabled]="(isChangeEw && !CanChangeCash2Card) || !receivingTypeIsCard || isInternetOrderConfig || pendingAddCard"
        (click)="openAddCardModal()">
        {{cards?.value?.length ? 'Змінити картку' : 'Додати картку'}}
      </button>
    </div>

    <div class="form-col card-list" [class.change-ew-card-select]="isChangeEw"
         *ngIf="cards?.value?.length">
      <mat-form-field>
        <mat-select
          placeholder="Отримати на картку"
          [(ngModel)]="cards?.value[0][0]"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true">
          <mat-optgroup *ngFor="let cardGroup of cards?.value" [label]="'Для зарахування'">
            <mat-option *ngFor="let card of cardGroup" [value]="card">
              <div class="payment-option">
                <img *ngIf="card.pan[0] === '4'" src="../../../../assets/images/visa-dropdown.svg" alt=""
                     class="payment-option__img">
                <img *ngIf="card.pan[0] === '5'" src="../../../../assets/images/master-dropdown.svg" alt=""
                     class="payment-option__img">
                <div class="payment-option__name"
                     [class.payment-option__name-change-ew]="isChangeEw">{{card.alias + ' '}}</div>
                <div
                  class="payment-option__number">{{card.pan.substring(0, 4) + ' ' + card.pan.substring(4, 8) + ' ' + card.pan.substring(8, 12) + ' ' + card.pan.substring(12, 16)}}</div>
              </div>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control-icons control-icons_help pr-3"
         *ngIf="!showCreateWallet && cards?.value?.length && !isLoading">
      <div class="control-icons__item">
        <div class="material-tooltip">
          <i class="material-icons icon-help material-tooltip__btn cursor-pointer">help_outline</i>
          <div class="material-tooltip__content" [class.display-left]="isChangeEw">У разі незарахування коштів на
                                                                                   картку, ви зможете отримати грошовий
                                                                                   переказ готівкою з наступного дня у
                                                                                   будь-якому відділенні Нової пошти. Ми
                                                                                   направимо вам повідомлення.
            <a href="https://novaposhta.ua/money_order" target="_blank">Детальніше</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-col">
      <div *ngIf="receivingTypeIsCard" class="form-section__hint mb-0">
        Зверніть увагу! Використання карток фізичних осіб для здійснення підприємницької діяльності заборонено.
      </div>
    </div>
  </div>
</div>

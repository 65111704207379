import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Injectable } from '@angular/core';
import { AvailableServices } from '@models';
import { AuthService } from '@services';
import { BehaviorSubject, Observable, of, Subject, tap } from 'rxjs';
import { BusinessSettingsService } from '../../dashboard/settings/services/business-settings.service';

@Injectable({
  providedIn: 'root',
})
export class IdService {
  removeRecipientEventSubject: Subject<void> = new Subject<void>();
  backDeliveryChangedEventSubject: Subject<void> = new Subject<void>();
  private _availableServicesSubject: BehaviorSubject<AvailableServices> = new BehaviorSubject<AvailableServices>(null);
  availableServices$ = this._availableServicesSubject.asObservable();
  private _stepperSelectionEventSubject: BehaviorSubject<StepperSelectionEvent> =
    new BehaviorSubject<StepperSelectionEvent>(null);
  stepperSelectionEvent$ = this._stepperSelectionEventSubject.asObservable();

  constructor(private businessSettingsService: BusinessSettingsService, private authService: AuthService) {
  }

  get availableServices(): AvailableServices {
    return this._availableServicesSubject.value;
  }

  set stepperSelectionEvent(value: StepperSelectionEvent) {
    this._stepperSelectionEventSubject.next(value);
  }

  initIdService(): Observable<AvailableServices> {
    return this.setAvailableServices();
  }

  setAvailableServices(ref?: string): Observable<AvailableServices | null> {
    const contragentRef = ref ? ref : this.authService.user.contragent ? this.authService.user.contragent.id : '';
    return contragentRef
           ? this.businessSettingsService
             .getAvailableServices(contragentRef)
             .pipe(tap((data) => this._availableServicesSubject.next(data)))
           : of(null);
  }
}

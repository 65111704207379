import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';
import { IdService } from '../../services/id.service';
import { ContactType } from '@models';

@Component({
  selector: 'np-inv-recipient-counterparty-radio-group',
  templateUrl: 'recipient-counterparty-radio-group.component.html',
  styleUrls: ['./recipient-counterparty-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientCounterpartyRadioGroupComponent
  extends AbstractFormContainer
  implements OnInit
{
  @Input() controlName = 'RecipientCounterpartyType';

  recipientCounterpartyTypeControl: UntypedFormControl;

  @Input() organizationDelegateDisabled = false;

  @Input()
  internationalInvRecipient;

  @Input()
  currentType: ContactType;

  @Input() invoiceId: string;

  @Output()
  onClear = new EventEmitter<void>();

  constructor(private cd: ChangeDetectorRef, private idService: IdService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (
      (this.form.get('RecipientSearchObject') &&
        this.form.get('RecipientSearchObject').value === null) ||
      (this.form.get('RecipientSearchObject') &&
        this.form.get('RecipientSearchObject').value === '')
    ) {
      this.form.get('RecipientSearchObject').reset('');
      this.form.removeControl('ContactRecipient');
    }
  }

  addFormControls() {
    this.currentType = this.form.get(this.controlName)
      ? this.form.get(this.controlName).value
      : 'PrivatePerson';
    this.recipientCounterpartyTypeControl = new UntypedFormControl(
      {
        value: this.currentType,
        disabled:
          this.form.get('RecipientSearchObject') &&
          this.form.get('RecipientSearchObject').value !== null
            ? this.form.get('RecipientSearchObject').value.Ref
            : false,
      },
      Validators.required,
    );
    this.form.setControl(
      this.controlName,
      this.recipientCounterpartyTypeControl,
    );
  }

  clearRecipient() {
    this.idService.removeRecipientEventSubject.next();
    this.form.get('RecipientSearchObject').reset('');
    this.form.removeControl('Recipient');
    this.form.removeControl('Contragent');
    this.form.removeControl('ContactRecipient');
    this.form.removeControl('NewContactOrganization');
    this.form.get(this.controlName).setValue('PrivatePerson');
    if (this.form.get('NewRecipientPrivateContact')) {
      this.form.get('NewRecipientPrivateContact.middleName').clearValidators();
      this.form.get('NewRecipientPrivateContact').reset({
        phone: '',
        lastName: '',
        firstName: '',
        middleName: '',
      });
    }
    if (this.form.get('AddressRecipient') !== null) {
      this.form.get('AddressRecipient').reset({
        mode: 'create',
        type: 'Warehouse',
        data: '',
      });
    }
    this.onClear.next();
  }
}

<div class="modal modal_small modal_full-height">
    <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); dialogRef.close()">
        <i class="material-icons">close</i>
    </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">{{ title }}</h3>
      </div>
    </div>
  </div>
  <form class="form-flex pt-1">
    <mat-progress-bar [style.opacity]="+(pending$ | async)" mode="indeterminate"></mat-progress-bar>
    <main class="modal-content" [formGroup]="form">
      <div class="form-row mr-bottom-16">
        <div class="form-col form-col-6 select-wrapper">
          <mat-form-field class="mat-block">
            <mat-select formControlName="registerRef"
                        [disabled]="(pending$ | async)">
              <mat-option [value]="newRegisterName">Створити новий реєстр</mat-option>
              <mat-option *ngFor="let register of registers" [value]="register">
                <span class="select-register-option" [innerHTML]="registerDescription(register)"></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div [hidden]="!canViewDescription">
        <div class="form-row">
          <div class="form-col form-col-6">
            <mat-form-field class="form-input">
              <mat-label>Назва реєстру (не обов'язково)</mat-label>
              <input formControlName="description"
                     class="input"
                     matInput
                     type="text"
                     (blur)="onBlur($event)"
                     autocomplete="off">
              <button *ngIf="description?.value && !description?.disabled"
                      (click)="clearField()"
                      class="p-0"
                      mat-button
                      matSuffix
                      mat-icon-button
                      aria-label="Clear">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error>{{ getError(description, descriptionErrors) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div [hidden]="!canViewDate">
        <div class="form-row">
          <div class="form-col" (click)="picker.open()">
            <mat-form-field class="mat-block date-picker-field" [class.cursor-pointer]="!(pending$ | async) && !input.disabled">
              <input
                #input
                [min]="minDate"
                matInput
                readonly="true"
                [matDatepicker]="picker"
                formControlName="date"
                (dateInput)="onDateChange($event.value)"
                placeholder="Оберіть дату"
                class="input date-picker"
                [class.cursor-pointer]="!(pending$ | async) && !input.disabled"
              >
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="date.value"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </main>
    <div class="modal-footer ">
      <div class="modal-footer-buttons pr-3">
        <div class="modal-footer-buttons__item modal-footer-buttons__right">
          <button type="button"
                  mat-button
                  [disabled]="(pending$ | async)"
                  (click)="dialogRef.close();">
            скасувати
          </button>
          <button type="button"
                  mat-button
                  [disabled]="(pending$ | async)"
                  color="primary"
                  (click)="save()">
            зберегти
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

import { Address, DoorAddress, WarehouseAddress } from './address.model';
/**
 * Created by andrey on 03.07.16.
 */
import { Model } from './base.model';
import { Contragent } from './contragent.model';
import { Locality } from './locality.model';
import { Member } from './member.model';

export enum CounterpartyType {
  PrivatePerson = 'PrivatePerson',
  Organization = 'Organization',
}

export enum CounterpartyName {
  PrivatePerson = 'Приватна особа',
}

export class Contact extends Model {
  city: Locality;
  phone: string;
  additionalPhone: string;
  birthDate: Date;
  email: string;
  login: string;
  description: string;
  middleName: string;
  contragent: Contragent;
  info: string;
  addresses: Address[];
  isCatalogContact = false;
  contacts?: Member[];
  userLogin: string;
  userLoginOldAuth: string;
  contactSender?: string;
  BirthDateString?: string;
  Cid?: string;
  CounterpartyCode?: string;

  constructor(public firstName: string = '', public lastName: string = '', id: string = null) {
    super(id);
  }
}

export interface CatalogContact {
  Description: string;
  EDRPOU: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  OwnershipForm: string;
  RefCounterparty: string;
}

export interface SavedCatalogContact {
  Description: string;
  Email: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Phones: string;
  Recipient: string;
  Ref: string;
}

export type ContactProperty = 'Sender' | 'Recipient';
export type CounterpartyProperty = ContactProperty;
export type ContactType = 'PrivatePerson' | 'Organization';

export interface ContactAddresses {
  DoorsAddresses: DoorAddress[];
  WarehouseAddresses: WarehouseAddress[];
}

export interface ContactPerson {
  AdditionalPhone?: string | null;
  CounterpartyDesctiption?: string;
  CounterpartyRef?: string;
  Description?: string;
  Email?: string;
  FirstName?: string;
  Info?: object | null;
  LastName?: string;
  MiddleName?: string;
  Phones?: string;
  Ref?: string;
  CounterpartyFullName?: string;
  CounterpartyType?: string;
  Counterparty?: string;
  Addresses?: ContactAddresses;
}

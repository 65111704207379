/**
 * Created by andrey on 03.07.16.
 */
import { Model } from './base.model';
import { Region } from './region.model';
import { State } from './state.model';

export class Locality extends Model {
    name: string;
    state: State;
    region: Region;
    id2: string;
    typeName: string;
    fullName: string;
    warehousesCount: number;
    note: string;
    deliveryDays = {
        Delivery1: true,
        Delivery2: true,
        Delivery3: true,
        Delivery4: true,
        Delivery5: true,
        Delivery6: false,
        Delivery7: false,
    };

    protected _description: string;

    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }
}

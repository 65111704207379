import {Model} from '../base.model';

export class InvoiceSize extends Model {

  description: string;

  constructor(id: string = null) {
    super(id || (Math.round(Math.random() * 100000000000)).toString());
  }

  protected _height: number;

  get height(): number {
    return this._height;
  }

  set height(height: number) {
    this._height = height;
  }

  protected _width: number;

  get width(): number {
    return this._width;
  }

  set width(width: number) {
    this._width = width;
  }

  protected _length: number;

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

}

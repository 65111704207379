import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroupDirective,
  NgForm,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractFormContainer, roundNumber } from '@shared';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-delivery-cargo-places',
  templateUrl: './delivery-cargo-places.component.html',
  styleUrls: ['./delivery-cargo-places.component.scss'],
})
export class DeliveryCargoPlacesComponent extends AbstractFormContainer implements OnDestroy, OnInit {
  @Input() controlName = 'OptionsSeat';

  @Input() isTemplate: boolean;

  @Input() invoiceId: string;

  matcher = new MyErrorStateMatcher();

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  get OptionsSeat(): UntypedFormArray {
    return this.form.get('OptionsSeat') as UntypedFormArray;
  }

  static optionsSeatValidations(isTemplate: boolean = false) {
    return {
      weight: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricLength: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricWidth: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricHeight: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricVolume: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      packRef: [''],
      specialCargo: [false],
    };
  }

  static firstOptionsSeatValidations(isTemplate: boolean = false) {
    return {
      weight: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricLength: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricWidth: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricHeight: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      volumetricVolume: ['', Validators.compose([...(isTemplate ? [] : [Validators.required]), Validators.min(0.1)])],
      packRef: [''],
      specialCargo: [false],
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    const optionsSeatArray = this.formBuilder.array([this.initFirstOptionSeat()]);
    this.form.addControl(this.controlName, optionsSeatArray);
  }

  initFirstOptionSeat() {
    return this.formBuilder.group(DeliveryCargoPlacesComponent.firstOptionsSeatValidations(this.isTemplate));
  }

  initOptionsSeat() {
    return this.formBuilder.group(DeliveryCargoPlacesComponent.optionsSeatValidations(this.isTemplate));
  }

  addOptionsSeat() {
    const control = this.OptionsSeat;
    this.OptionsSeat.controls.length ? control.push(this.initOptionsSeat()) : control.push(this.initFirstOptionSeat());
    this.OptionsSeat.updateValueAndValidity();
  }

  removeOptionsSeat(index: number) {
    const control = this.OptionsSeat;
    if (control.value.length > 1) {
      control.removeAt(index);
    }
  }

  copyOptionsSeat(index: number) {
    const control = this.OptionsSeat;
    const newSeatControl = control['controls'].length ? this.initOptionsSeat() : this.initFirstOptionSeat();
    newSeatControl.patchValue(control.controls[index].value);
    newSeatControl.patchValue({
      Description: '',
      Cost: '',
    });
    control.push(newSeatControl);
  }

  recalculateVolumetricVolume(index) {
    const optionsSeat = this.OptionsSeat.controls[index].value;
    const length = Number(optionsSeat['volumetricLength']);
    const width = Number(optionsSeat['volumetricWidth']);
    const height = Number(optionsSeat['volumetricHeight']);
    let volumetricVolume = length && width && height ? roundNumber((length * width * height) / 4000) : '';
    if (volumetricVolume <= '0.10' && length && width && height) {
      volumetricVolume = '0.10';
    }
    this.OptionsSeat.controls[index].patchValue({
      volumetricVolume,
    });
  }

  preventDecimal(event) {
    return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 0;
  }

  ngOnDestroy() {
    this.form.removeControl(this.controlName);
  }

  checkSpecialCargoCondition(i: number) {
    const optionsSeat = (this.OptionsSeat as UntypedFormArray).controls[i].value;
    const weight = Number(optionsSeat['weight']);
    const volumetricVolume = Number(optionsSeat['volumetricVolume']);
    const length = Number(optionsSeat['volumetricLength']);
    const width = Number(optionsSeat['volumetricWidth']);
    const height = Number(optionsSeat['volumetricHeight']);
    if (
      weight >= 0.1 &&
      weight <= 30 &&
      volumetricVolume >= 0.1 &&
      volumetricVolume <= 30 &&
      length <= 120 &&
      width <= 120 &&
      height <= 120
    ) {
      return true;
    }
    this.OptionsSeat.controls[i].patchValue({
      specialCargo: false,
    });
    return false;
  }
}

import {
  AddressType,
  AddressTypes,
  ApiAddress,
  WarehouseAddress,
} from '@models';
import { AddressSettings } from '@shared';

const WAREHOUSE_TYPE_KEYS: Set<string> = new Set([
  'TypeOfWarehouse',
  'WarehouseNumber',
]);

const DOORS_TYPE_KEYS: Set<string> = new Set([
  'RegionDescription',
  'SettlementDescription',
  'SettlementRef',
  'StreetDescription',
  'StreetRef',
  'StreetsType',
  'StreetsTypeRef',
]);

const checkKeys = (keys: string[], setOfKeys: Set<string>): boolean => {
  return keys.some((key) => setOfKeys.has(key));
};

export function getAddressType(obj: object): AddressType {
  if (!obj || typeof obj !== 'object') {
    return AddressTypes.WAREHOUSE;
  }
  const keys = Object.keys(obj);
  if (checkKeys(keys, WAREHOUSE_TYPE_KEYS)) {
    return (obj as WarehouseAddress).TypeOfWarehouse ===
      AddressSettings.POSTBOX_TYPE
      ? AddressTypes.POSTBOX
      : AddressTypes.WAREHOUSE;
  } else if (checkKeys(keys, DOORS_TYPE_KEYS)) {
    return AddressTypes.DOORS;
  }
  return AddressTypes.WAREHOUSE;
}

export function mapNewAddressToAddressFormat(
  data: any,
  type: AddressTypes,
  note?: string,
): Partial<ApiAddress> {
  const address: Partial<ApiAddress> = { AddressType: type };
  if (
    (type === AddressTypes.WAREHOUSE || type === AddressTypes.INDEX) &&
    data.warehouse
  ) {
    address.SettlementRef = data?.city?.Ref ?? data.warehouse.SettlementRef;
    address.AddressRef = data.warehouse.Ref;
  } else if (type === AddressTypes.POSTBOX && data.postbox) {
    address.AddressType = AddressTypes.WAREHOUSE;
    address.SettlementRef = data.city.Ref;
    address.AddressRef = data.postbox.Ref;
  } else if (data.street) {
    address.SettlementRef = data.street.SettlementRef;
    address.AddressRef = data.street.SettlementStreetRef;
    address.BuildingNumber = data.building;
    address.Flat = data.room;
    // @TODO data.note || note is used for compatibility, arg note should be removed
    address.Note = data.note || note;
  }
  return address;
}

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-messengers-popup-instruction',
  templateUrl: './messengers-popup-instruction.component.html',
  styleUrls: ['./messengers-popup-instruction.component.scss']
})
export class MessengersPopupInstructionComponent {
  constructor(
    public dialogRef: MatDialogRef<MessengersPopupInstructionComponent>
  ) {
  }
}

import { Model } from './base.model';

export class TimeInterval extends Model {
  start: string;
  end: string;
}

export interface ITimeInterval {
  Number: string,
  End: string,
  Start: string
}

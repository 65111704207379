import { AvailableServices } from '@models';

export const availableServices: AvailableServices = {
  AddressDocumentDelivery: false,
  AfterpaymentType: 'NovaPoshta',
  BackDeliveryValuablePapers: false,
  BackwardDeliverySubtypesDocuments: false,
  BlockInternationalSenderLKK: false,
  BusinessClient: false,
  CanAfterpaymentOnGoodsCost: false,
  CanEWTransporter: false,
  CanForwardingService: false,
  CanNonCashPayment: false,
  CanPayTheThirdPerson: false,
  CanSameDayDelivery: false,
  CanSameDayDeliveryStandart: true,
  CanSignedDocuments: false,
  CustomerReturn: false,
  Debtor: false,
  DeliveryByHand: false,
  DescentFromFloor: false,
  FillingWarranty: false,
  HaveMoneyWallets: false,
  HideDeliveryCost: false,
  InternationalDeliveryServiceType: false,
  LoyaltyProgram: true,
  PartialReturn: false,
  PickupService: false,
  Services: false,
  ShowDeliveryByHand: false,
  SignedDocuments: false,
  CanDeliveryPharma: false,
};

export enum AvailableServiceNames {
  backDelivery = 'backDelivery',
  securePayment = 'securePayment',
  backDocuments = 'backDocuments',
  customBackwardsDeliveryAddress = 'customBackwardsDeliveryAddress',
  afterpayment = 'afterpayment',
  forwardingCount = 'forwardingCount',
  doorstepPickup = 'doorstepPickup',
  floorsLifting = 'floorsLifting',
  dateTime = 'dateTime',
  preferredDate = 'preferredDate',
  signedDocuments = 'signedDocuments',
  deliveryByHand = 'deliveryByHand',
  localExpress = 'localExpress',
  accompanyingDocuments = 'accompanyingDocuments',
  differentDocuments = 'differentDocuments',
  packingService = 'packingService',
  pharma = 'pharma',
}

export interface ServiceDetails {
  name: string;
  description: string;
}

export const SERVICE_NAMES: Record<AvailableServiceNames, ServiceDetails> | any = {
  [AvailableServiceNames.backDelivery]: {
    name: 'Грошовий переказ',
    description: 'Послуга передбачає повернення коштів Отримувачем за відправлення Відправнику',
  },
  [AvailableServiceNames.securePayment]: {
    name: 'Сейф-сервіс',
    description:
      'Послуга для онлайн-оплати за товар у додатку Нової пошти до його відправки. Якщо товар отримано – оплата зараховується продавцю на картку, при відмові повертається покупцю. Повернення товару – безкоштовне',
  },
  [AvailableServiceNames.backDocuments]: {
    name: 'Зворотна доставка документів',
    description: 'Послуга передбачає повернення документів Відправнику. Забір документів не є обов\'язковим для видачі відправлення Отримувачу',
  },
  [AvailableServiceNames.customBackwardsDeliveryAddress]: {
    name: 'Нестандартна адреса повернення',
    description: 'Послуга передбачає вибір нестандартної адреси повернення',
  },
  [AvailableServiceNames.afterpayment]: {
    name: 'Контроль оплати',
    description:
      'Послуга передбачає контроль оплати готівкою за отримане Отримувачем відправлення. Кошти перераховуються Відправнику на його поточний рахунок (наступного робочого дня) на підставі укладеного договору',
  },
  [AvailableServiceNames.forwardingCount]: {
    name: 'Контроль поштучної передачі',
    description:
      'Послуга передбачає своєчасну та надійну доставку відправлень з контролем процесу розпакування та перерахунку кожної одиниці товару',
  },
  [AvailableServiceNames.doorstepPickup]: {
    name: 'Від дверей',
    description:
      `Якщо ви замовили послугу*, кур’єр забере вашу посилку від вхідних дверей**.
        Вартість послуги – 200 грн (за кожні 100 кг).
        *Безплатно для відправлень до 30 кг.
        **Ідеться про двері квартири, офісу або вхідні двері приватного будинку.`,
  },
  [AvailableServiceNames.floorsLifting]: {
    name: 'До дверей',
    description:
      `Якщо ви замовили послугу*, кур’єр доставить вашу посилку до вхідних дверей**.
        Вартість послуги – 100 грн (за кожні 100 кг).
        *Безплатно для відправлень до 30 кг.
        **Ідеться про двері квартири, офісу або вхідні двері приватного будинку.`,
  },
  [AvailableServiceNames.dateTime]: {
    name: 'Дата відправки',
    description:
      'Фактична дата створення відправлення встановлюється за замовчуванням. Якщо відправка здійснюватиметься пізніше за дату створення ЕН, вкажіть фактичну дату відправлення',
  },
  [AvailableServiceNames.preferredDate]: {
    name: 'Бажана дата та час доставки',
    description:
      'Якщо відправлення необхідно доставити пізніше фактичної дати доставки (застосовується тільки для послуги «До дверей»)',
  },
  [AvailableServiceNames.signedDocuments]: {
    name: 'Зворотна доставка підписаних документів',
    description:
      'Послуга дозволяє Відправнику отримати документи, які були підписані Отримувачем під контролем працівника компанії «Нова пошта»',
  },
  [AvailableServiceNames.deliveryByHand]: {
    name: 'Доставка особисто в руки',
    description: 'Послуга надає можливість доставити відправлення особисто в руки Отримувача',
  },
  [AvailableServiceNames.localExpress]: {
    name: '«Локал Експрес»',
    description:
      'Своєчасна та надійна доставка відправлень у межах Києва впродовж дня в зручний для Отримувача інтервал часу',
  },
  [AvailableServiceNames.accompanyingDocuments]: {
    name: 'Супроводжуючі документи',
    description: 'Послуга передбачає вказання документиів які будуть передані разом з вантажем',
  },
  [AvailableServiceNames.differentDocuments]: {
    name: 'Зворотна доставка підтипів документів',
    description:
      'Послуга, яка передбачає повернення Відправнику тих підтипів документів, які були зазначені під час оформлення відправлення',
  },
  [AvailableServiceNames.packingService]: {
    name: 'Пакування',
    description:
      'Послуга передбачає розміщення відправлення в найбільш придатний вид упаковки, який забезпечить його надійне транспортування, складування, зберігання та захист. Пакування виконується в упаковку із запропонованого асортименту компанії «Нова пошта».',
  },
  [AvailableServiceNames.pharma]: {
    name: 'Фарма',
    description:
      'Послуга доступна виключно для клієнтів юридичних осіб, при наявності договору про здійснення доставки лікарських засобів кінцевому споживачу',
  },
};

export const COUNTERPARTY_OPTIONS_PROPS = {
  afterpayment: 'CanAfterpaymentOnGoodsCost',
  securePayment: 'SecurePayment',
  forwardingCount: 'CanForwardingService',
  signedDocuments: 'CanSignedDocuments',
  differentDocuments: 'BackwardDeliverySubtypesDocuments',
  pharma: 'CanDeliveryPharma',
  localExpress: 'CanSameDayDelivery',
};

<div class="invoice-header">
  <div class="invoice-header__left">
    <mat-icon *ngIf="isOut" class="material-icons invoice-header__icon-type">
      call_made
    </mat-icon>
    <mat-icon *ngIf="isIn" class="material-icons invoice-header__icon-type">
      call_received
    </mat-icon>
    <div class="invoice-header__number copy-content copy-content_lg">
      {{invoice?.docNumber | fineDocNumber}}
      <button mat-icon-button
              class="copy-content__btn"
              [appCopy]="invoice?.docNumber"
              [matTooltip]="'Скопіювати'"
              matTooltipPosition="below">
        <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
      </button>
    </div>
  </div>
  <div class="invoice-header__right">
    <div class="invoice-header__info">
      <div class="info-cell invoice-header__cell">
        <div class="info-cell__label">Тип</div>
        <div class="info-cell__content">{{CargoType[invoice?.CargoType?.toUpperCase()]}}</div>
      </div>
      <div class="info-cell invoice-header__cell">
        <div class="info-cell__label">Вага</div>
        <div class="info-cell__content">{{invoice?.weight}} кг</div>
      </div>
    </div>
  </div>
</div>

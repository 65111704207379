import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class Unsubscriber implements OnDestroy {
  private _subscriptions: Subscription[] = [];

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(subscription: any) {
    this._subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

<div class="size-form mb-1" [formGroup]="form">
  <div class="form-row">
      <div class="form-col form-col-1 form-col-1_with_symbol">
          <mat-form-field class="mat-block mat-block_with_symbol mat-block_placeholder-top" data-symbol="кг">
            <mat-select placeholder="Вага"
                        [formControlName]="controlName">
              <mat-option *ngFor="let option of options; let i = index" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
            <mat-error
              class="nowrap-text"
              *ngIf="!!errorMessages[controlName] && (form.get(controlName).touched || form.get(controlName).dirty)"
              [matTooltip]="errorMessages[controlName]">
              <i class="material-icons mat-select-error__icon">error_outline</i>
              {{errorMessages[controlName]}}
            </mat-error>
          </mat-form-field>
      </div>
      <div class="form-col form-col-1 form-col-1_with_symbol">
        <app-delivery-documents-amount [form]="form" [invoiceId]="invoiceId"></app-delivery-documents-amount>
       </div>
      <ng-content></ng-content>
  </div>
</div>

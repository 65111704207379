import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material';
import { PipeModule } from '../pipes/pipes.module';
import { FirebaseModule } from '../services/firebase';
import { ConfirmDialogModule } from '../shared/components/confirm-dialog';
import { AutoScrollTargetInputDirective } from '../shared/directives/auto-sсroll-target-input.directive';
import { AutofocusDirective } from '../shared/directives/mat-focus.directive';
import { AppMatTableModule } from '../shared/modules/mat-table/app-mat-table.module';
import { AddressNoteComponent } from './components/address-note/address-note.component';
import { BuildingComponent } from './components/building/building.component';
import { CitySelectComponent } from './components/city-select/city-select.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { LoginByTokenComponent } from './components/login-by-token/login-by-token.component';
import { RoomComponent } from './components/room/room.component';
import { StreetSelectComponent } from './components/street-select/street-select.component';
import { PostboxSelectComponent } from './components/warehouse-select/postbox-select/postbox-select.component';
import { WarehouseSelectComponent } from './components/warehouse-select/warehouse-select.component';
import { CapitalizeWithDashDirective, NumberDirective, PhoneInputDirective } from './directives';
import { FillBeforeDirective } from './directives/fill-before/fill-before.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { InternationalPhoneInputDirective } from './directives/international-phone-input.directive';
import { IntinputDirective } from './directives/intinput.directive';
import { InvoiceProgressDirective } from './directives/invoice-progress.directive';
import { DebouncePipe } from './pipes/debounce.pipe';
import { DeliveryDatePipe } from './pipes/delivery-date.pipe.';
import { DigitRoundPipe } from './pipes/digit-round.pipe';
import { FineDocNumberPipe } from './pipes/fine-doc-number.pipe';
import { FineDocSearchPipe } from './pipes/fine-doc-search';
import { FineNumberPipe } from './pipes/fine-number.pipe';
import { NpDatePipe } from './pipes/np-date.pipe';
import { NpFinePhonePipe } from './pipes/np-fine-phone.pipe';
import { SumFormatPipe } from './pipes/sum-format.pipe';
import { ToDateFormatPipe } from './pipes/to-date-format.pipe';
import { MarketplacePartnerToken } from './tokens/marketplace-partner.token';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ConfirmDialogModule.forRoot(),
    AppMatTableModule.forRoot(),
    FirebaseModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
  ],
  exports: [
    NpDatePipe,
    NpFinePhonePipe,
    FineDocNumberPipe,
    FineDocSearchPipe,
    FineNumberPipe,
    FillBeforeDirective,
    NumberDirective,
    AutofocusDirective,
    IntinputDirective,
    ToDateFormatPipe,
    DigitRoundPipe,
    DebouncePipe,
    InvoiceProgressDirective,
    SumFormatPipe,
    InputTrimDirective,
    AutoScrollTargetInputDirective,
    DeliveryDatePipe,
    PhoneInputDirective,
    InternationalPhoneInputDirective,
    CapitalizeWithDashDirective,
    CitySelectComponent,
    WarehouseSelectComponent,
    PostboxSelectComponent,
    StreetSelectComponent,
    BuildingComponent,
    RoomComponent,
    AddressNoteComponent,
  ],
  declarations: [
    NpDatePipe,
    NpFinePhonePipe,
    FineDocNumberPipe,
    FineDocSearchPipe,
    FineNumberPipe,
    FillBeforeDirective,
    NumberDirective,
    IntinputDirective,
    LoginByTokenComponent,
    ToDateFormatPipe,
    AutofocusDirective,
    DigitRoundPipe,
    DebouncePipe,
    InvoiceProgressDirective,
    ErrorSnackbarComponent,
    SumFormatPipe,
    InputTrimDirective,
    AutoScrollTargetInputDirective,
    DeliveryDatePipe,
    PhoneInputDirective,
    InternationalPhoneInputDirective,
    CapitalizeWithDashDirective,
    CitySelectComponent,
    WarehouseSelectComponent,
    PostboxSelectComponent,
    StreetSelectComponent,
    BuildingComponent,
    RoomComponent,
    AddressNoteComponent,
  ],
  providers: [
    {
      provide: MarketplacePartnerToken,
      useValue: '005056887b8d-b5da-11e6-9f54-cea38574',
    },
  ],
})
export class CoreModule {
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { clearField, DoubleDateTableFilter, getError, SmartFormContainer } from '@shared';

@Component({
  selector: 'app-double-date-filter',
  templateUrl: './double-date-filter.component.html',
  styleUrls: ['./double-date-filter.component.scss'],
})
export class DoubleDateFilterComponent extends SmartFormContainer implements OnInit {
  readonly getError = getError;
  readonly clearField = clearField;
  @Input() config: DoubleDateTableFilter;
  @Input() sm: boolean = false;

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  override ngOnInit(): void {
    // We don't need this because we don't want to
    // get such control { '': null }
    void 0;
  }
}

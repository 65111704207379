<ng-container *ngIf="servicesInfo.bannerPacking; else banner">
  <div class="services-banner banner banner-packing p-0">
    <div class="banner-packing__wrap pr-5 my-5 d-flex">
      <img *ngIf="servicesInfo.img" alt="{{servicesInfo.title}}" class="d-flex no-shrink mt-4"
           src="{{servicesInfo.img}}">
      <div class="banner-packing__text pl-5">
        <div *ngIf="servicesInfo.title" class="banner-packing__title text-bold pb-4">{{servicesInfo.title}}</div>
        <div class="d-flex w-100">
          <div *ngIf="servicesInfo.firstList" class="banner-packing__col">
            <div *ngIf="servicesInfo.firstListTitle"
                 class="banner-packing__subtitle">{{servicesInfo.firstListTitle}}</div>
            <ul *ngFor="let item of servicesInfo.firstList">
              <li [innerHTML]="item"></li>
            </ul>
          </div>
          <div *ngIf="servicesInfo.secondList" class="banner-packing__col ml-6">
            <div *ngIf="servicesInfo.secondListTitle"
                 class="banner-packing__subtitle">{{servicesInfo.secondListTitle}}</div>
            <ul *ngFor="let item of servicesInfo.secondList">
              <li [innerHTML]="item"></li>
            </ul>
          </div>
        </div>
        <div *ngIf="servicesInfo.description" class="banner-packing__description pt-4"
             [innerHTML]="servicesInfo.description"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #banner>
  <div class="services-banner banner p-0" [ngClass]="servicesInfo.modifier ? servicesInfo.modifier : ''">
    <div class="banner__info p-0">
      <div class="px-24 my-5 d-flex">
        <div class="services-banner__item">
          <div class="d-flex">
            <img *ngIf="servicesInfo.img" alt="" class="d-block mr-6" src="{{servicesInfo.img}}">
            <div>
              <div *ngIf="servicesInfo.title" class="services-banner__title mb-2">{{servicesInfo.title}}</div>
              <p *ngIf="servicesInfo.text" class="services-banner__text">
                {{servicesInfo.text}}
              </p>
              <a *ngIf="servicesInfo.link" href="{{servicesInfo.link.url}}" target="_blank"
                 class="link-red">{{servicesInfo.link.name}}</a>
            </div>
          </div>
        </div>
        <div *ngIf="servicesInfo.firstList" class="services-banner__item ml-6">
          <div *ngIf="servicesInfo.firstListTitle"
               class="services-banner__subtitle">{{servicesInfo.firstListTitle}}</div>
          <ul class="marked-list" *ngFor="let item of servicesInfo.firstList">
            <li>{{item}}</li>
          </ul>
          <p *ngIf="servicesInfo.firstListNote" class="note">
            {{servicesInfo.firstListNote}}
          </p>
        </div>
        <div *ngIf="servicesInfo.secondList" class="services-banner__item ml-6">
          <div *ngIf="servicesInfo.secondListTitle"
               class="services-banner__subtitle">{{servicesInfo.secondListTitle}}</div>
          <ul class="marked-list" *ngFor="let item of servicesInfo.secondList">
            <li>{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { cityResultMaskValidator, WarehouseMaskValidator } from '@shared';
import { WarehouseComponent } from '../warehouse/warehouse.component';
import { POSTBOX_TEST_ID } from './postbox.constants';
import { PostBoxControls } from './postbox.enums';
import { AddressTypes, CitySearchResult } from '@models';

@Component({
  selector: 'app-postbox',
  templateUrl: './postbox.component.html',
  styleUrls: ['./postbox.component.scss'],
})
export class PostboxComponent extends WarehouseComponent {
  type = AddressTypes.POSTBOX;

  @HostBinding('attr.data-test-id') a = POSTBOX_TEST_ID;

  @Input() controlName = 'PostboxContainer';

  @Input() isTemplate = false;

  @Input() forSender: boolean = false;

  cityRef: string = '';

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get cityValue(): CitySearchResult | string {
    return this.formGroup.get(PostBoxControls.city).value;
  }

  set cityValue(value: CitySearchResult | string) {
    this.formGroup.get(PostBoxControls.city).setValue(value);
  }

  get city(): AbstractControl | null {
    return this.formGroup.get(PostBoxControls.city);
  }

  get warehouse(): AbstractControl | null {
    return this.formGroup.get(PostBoxControls.postbox);
  }

  loadWarehouses(cityRef: string): void {
    this.cityRef = cityRef;
  }

  addFormControls(): void {
    if (this.form) {
      this.createControl();
    }
  }

  createControl(): void {
    this.formGroup = this.fb.group({
      [PostBoxControls.city]: [
        '',
        [
          ...(this.isTemplate ? [] : [Validators.required]),
          cityResultMaskValidator(),
        ],
      ],
      [PostBoxControls.postbox]: [
        '',
        [Validators.required, WarehouseMaskValidator()],
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.warehouse.disable();
    this.initFormSubscriptions();
  }

  initFormSubscriptions(): void {
    this.subscriptions = this.city.statusChanges.subscribe((status) => {
      if (status === 'VALID' && this.city.value?.DeliveryCity) {
        this.warehouse.enable();
        this.loadWarehouses(this.city.value.DeliveryCity);
      } else {
        this.warehouse.disable();
        this.warehouse.reset('');
      }
    });
  }
}

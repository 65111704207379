import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import {
  ErrorMessages,
  ErrorTypeMessages,
  ValidationService,
} from '../../../../services/validation.service';
import { AbstractFormContainer } from '@shared';
import {
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '../../../modules/autocomplete-with-controls/autocomplete-with-controls.component';
import { formatUAPhone } from '../../../utils';
import { ContactsService } from '../../../../services/contacts/contacts.service';
import { CatalogContact, ContactPerson, Contragent } from "@models";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-private-person-sender-form-change-ew',
  templateUrl: './private-person-sender-form.component.html',
  styleUrls: ['./private-person-sender-form.component.scss'],
})
export class PrivatePersonSenderFormChangeEwComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input() controlName = 'PrivatePersonContact';
  @Input() contactProperty = 'Sender';
  @Input() templateValidation: boolean;
  @Input() invoiceId: string;
  @Input() IsEnabledCancel = false;
  @Input() isMiddleNameRequired = false;
  @Input() isOrganizationDelegate = false;
  @Input() initialContactValue: ContactPerson;
  @Input() clearOnPhoneChange = true;
  @Input() patchRecipientControlName: string;
  @Input() patchRecipientData: string;
  @Input() patchSenderControlName: string;
  @Input() patchSenderData: string;
  @Input() disablePhoneInput: boolean;
  @Input() use: boolean;

  @Output() onCancel = new EventEmitter<string>();
  @Output() recipientSelected: EventEmitter<ContactPerson> = new EventEmitter();

  @ViewChild('lastNameInput') lastNameInput: ElementRef;

  matcher = new MyErrorStateMatcher();
  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Місто',
    placeholder: 'Місто',
    arrow: false,
  };
  privatePersonFormGroup: UntypedFormGroup;
  contactOptions: ContactPerson[] = [];
  catalogOptions: CatalogContact[] = [];
  showNotFoundContacts = false;
  errors: ErrorTypeMessages = {
    phone: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'minlength', message: "Це поле обов'язкове" },
    ],
    lastName: [{ key: 'required', message: "Це поле обов'язкове" }],
    firstName: [{ key: 'required', message: "Це поле обов'язкове" }],
    middleName: [{ key: 'required', message: "Це поле обов'язкове" }],
  };

  errorMessages: ErrorMessages = {};
  lastNameAutocompleteConfig: AutocompleteWithControlsConfig = {
    ariaLabel: 'Прізвище',
    placeholder: 'Прізвище',
    arrow: true,
  };

  private _subscriptions: Subscription[] = [];

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(subscription: any) {
    this._subscriptions.push(subscription);
  }

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private contactService: ContactsService,
    private authService: AuthService,
    private validationService: ValidationService,
  ) {
    super();
  }

  get phoneControl(): AbstractControl {
    return this.privatePersonFormGroup.get('phone');
  }

  get lastNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('lastName');
  }

  get firstNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('firstName');
  }

  get middleNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('middleName');
  }

  get recipientTypeControl(): AbstractControl {
    return this.form.get('RecipientCounterpartyType');
  }

  get PrivatePersonContact(): AbstractControl {
    return this.form.get(this.controlName);
  }

  get patchRecipientControl(): AbstractControl {
    return this.form.get(this.patchRecipientControlName);
  }

  get patchSenderControl(): AbstractControl {
    return this.form.get(this.patchSenderControlName);
  }

  get recipientType(): AbstractControl {
    return this.form.get('recipientType');
  }

  get senderType(): AbstractControl {
    return this.form.get('senderType');
  }

  get privatePerson(): boolean {
    return this.senderType.value === 'PrivatePerson';
  }

  transformFn: AutoCompleteTransformFn<ContactPerson> = (
    value: string | ContactPerson,
  ) =>
    typeof value === 'string'
      ? value
      : `${value.Description || ''} ${formatUAPhone(value.Phones || '')}`;

  ngOnInit(): void {
    super.ngOnInit();
    this.initStatusSubscriptions();
    this.initChangesSubscription();
    this.initRecipientCounterpartyTypeSubscription();

    if (this.PrivatePersonContact) {
      this.subscriptions = this.PrivatePersonContact.valueChanges.subscribe(
        () => {
          this.errorMessages = this.validationService.getSingleErrorsObject(
            (this.PrivatePersonContact as UntypedFormGroup).controls,
            this.errors,
          );

          if (
            this.PrivatePersonContact.get('firstName') &&
            this.PrivatePersonContact.get('firstName').status === 'DISABLED'
          ) {
            this.PrivatePersonContact.get('firstName').markAsPristine();
            this.PrivatePersonContact.get('firstName').markAsUntouched();
            this.PrivatePersonContact.get('firstName').updateValueAndValidity({
              onlySelf: true,
            });
          }
          if (
            this.PrivatePersonContact.get('lastName') &&
            this.PrivatePersonContact.get('lastName').status === 'DISABLED'
          ) {
            this.PrivatePersonContact.get('lastName').markAsPristine();
            this.PrivatePersonContact.get('lastName').markAsUntouched();
            this.PrivatePersonContact.get('lastName').updateValueAndValidity({
              onlySelf: true,
            });
          }
        },
      );
    }

    if (this.templateValidation) {
      Object.keys(this.PrivatePersonContact['controls']).forEach((key) => {
        this.form.controls[this.controlName]['controls'][key].clearValidators();
      });
    }
  }

  initChangesSubscription(): void {
    this.subscriptions = this.privatePersonFormGroup.valueChanges.subscribe(
      () => this.form.removeControl('Recipient'),
    );
  }

  initRecipientCounterpartyTypeSubscription(): void {
    if (!this.recipientTypeControl) {
      return;
    }

    this.subscriptions = this.recipientTypeControl.valueChanges.subscribe(
      (type) => {
        if (type === 'OrganizationDelegate') {
          this.privatePersonFormGroup.patchValue({
            lastName: 'Представник',
            firstName: 'Представник',
            middleName: 'Представник',
          });
          this.lastNameControl.disable();
          this.firstNameControl.disable();
          this.middleNameControl.disable();
        }
        if (
          this.form.value.RecipientCounterpartyType ===
            'OrganizationDelegate' &&
          type !== 'OrganizationDelegate'
        ) {
          this.privatePersonFormGroup.patchValue({
            lastName: '',
            firstName: '',
            middleName: '',
          });
          if (this.phoneControl.valid) {
            this.lastNameControl.enable();
          }
        }
      },
    );
  }

  addFormControls(): void {
    this.privatePersonFormGroup = this.fb.group({
      phone: [
        this.initialContactValue ? this.initialContactValue.Phones : '',
        Validators.compose([Validators.required, Validators.minLength(12)]),
      ],
      lastName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.LastName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([Validators.required]),
      ],
      firstName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.FirstName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([Validators.required]),
      ],
      middleName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.MiddleName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([
          this.isMiddleNameRequired ? Validators.required : null,
        ]),
      ],
      ref: [{ value: '' }],
      counterRef: [{ value: '' }],
    });
    this.form.setControl(this.controlName, this.privatePersonFormGroup);
    this.patchData();
  }

  patchData(): void {
    if (this.patchRecipientControlName && this.patchRecipientData) {
      this.PrivatePersonContact.patchValue({
        lastName:
          this.patchRecipientControl.value[this.patchRecipientData]?.split(
            ' ',
          )[0] || '',
        firstName:
          this.patchRecipientControl.value[this.patchRecipientData]?.split(
            ' ',
          )[1] || '',
        middleName:
          this.patchRecipientControl.value[this.patchRecipientData]?.split(
            ' ',
          )[2] || '',
        phone: this.patchRecipientControl.value.PhoneRecipient || '',
      });
    }
    if (this.patchSenderControlName && this.patchSenderData) {
      this.PrivatePersonContact.patchValue({
        lastName:
          this.patchSenderControl.value[this.patchSenderData]?.split(' ')[0] ||
          '',
        firstName:
          this.patchSenderControl.value[this.patchSenderData]?.split(' ')[1] ||
          '',
        middleName:
          this.patchSenderControl.value[this.patchSenderData]?.split(' ')[2] ||
          '',
        phone: this.patchSenderControl.value.phone || '',
      });
    }
  }

  initStatusSubscriptions(): void {
    this.subscriptions = this.phoneControl.statusChanges.subscribe(
      (status) => {},
    );
  }

  searchCatalogContacts(query: string): void {
    const observables: Array<
      Observable<CatalogContact[]> | Observable<ContactPerson[]>
    > = [
      this.contactService.searchContactsInCatalog(
        query,
        this.phoneControl.value,
      ),
    ];

    forkJoin(...observables).subscribe((data: any) => {
      this.catalogOptions = data[0];
      this.contactOptions = data[1]
        ? data[1].filter(
            (contact) => contact.Phones === this.phoneControl.value,
          )
        : [];
    });
  }

  setRecipient(
    contact: ContactPerson,
    isFromCatalog: boolean,
    contragent: Contragent,
  ) {
    this.privatePersonFormGroup.patchValue({
      lastName: contact.LastName ? contact.LastName : '',
      firstName: contact.FirstName ? contact.FirstName : '',
      middleName: contact.MiddleName ? contact.MiddleName : '',
    });

    this.form.get('Contragent')
      ? this.form
          .get('Contragent')
          .reset({ value: contragent, disabled: isFromCatalog })
      : this.form.setControl(
          'Contragent',
          new UntypedFormControl(
            {
              value: contragent,
              disabled: isFromCatalog,
            },
            Validators.required,
          ),
        );
    this.setOrganizationDisplay(contragent);
    this.setRecipientCounterpartyType(contragent);
    if (!isFromCatalog) {
      this.form.setControl(
        'RecipientSearchObject',
        new UntypedFormControl(contact, Validators.required),
      );
    }
    this.form.setControl(
      this.contactProperty,
      new UntypedFormControl(contact, Validators.required),
    );
    this.recipientSelected.next(contact);
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  setOrganizationDisplay(contragent: Contragent) {
    if (this.form.get('NewContactOrganization')) {
      this.form.get('NewContactOrganization').patchValue({
        edrpou: contragent ? contragent.EDRPOU : '',
        companyName: contragent.Description
          ? contragent.Description
          : contragent.Description,
        orgType: contragent.OwnershipFormDescription
          ? contragent.OwnershipFormDescription
          : '',
      });
    }
  }

  setRecipientCounterpartyType(contragent: Contragent) {
    const recipientCounterpartyType =
      contragent.CounterpartyType === 'PrivatePerson'
        ? 'PrivatePerson'
        : 'Organization';
    if (this.form.get('RecipientCounterpartyType')) {
      this.form
        .get('RecipientCounterpartyType')
        .setValue(recipientCounterpartyType);
    } else if (this.form.get('recipientType')) {
      this.form.get('recipientType').setValue(recipientCounterpartyType);
    }
  }

  setContactFromCatalog(event) {
    this.privatePersonFormGroup.patchValue({ lastName: '' });

    const catalogContact: CatalogContact = event.source.value;

    this.contactService
      .getContragentByDescription(
        catalogContact.EDRPOU,
        catalogContact.Description,
        catalogContact.OwnershipForm,
      )
      .pipe(
        switchMap((contr: Contragent | undefined) =>
          !contr
            ? this.contactService.getByEdrpouBot(catalogContact.EDRPOU)
            : of(contr),
        ),
        catchError((err) => of(err)),
      )
      .subscribe((contragent) =>
        this.setRecipient(catalogContact, true, contragent),
      );
  }

  clear(): void {
    this.form.removeControl(this.contactProperty);
    this.privatePersonFormGroup.patchValue({
      phone: '',
      lastName: '',
      firstName: '',
      middleName: '',
    });
  }

  onChangePhone(event) {
    // event.preventDefault();
    // const phone = event.srcElement.value;
    // if (phone.length === 19) {
    //   this.lastNameInput.nativeElement.focus();
    // }
  }

  cancel(): void {
    this.onCancel.next('select');
  }

  ngOnDestroy(): void {
    if (this.clearOnPhoneChange) {
      this.form.removeControl(this.controlName);
    }

    this.form.removeControl('NewSenderPrivateContact');

    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

<div class="services-list__form" [formGroup]="backwardDeliveryDataGroup">
  <div class="form-row">
    <div class="form-col">
      <div class="label-radio">Платник за доставку</div>
      <mat-radio-group class="radio-row" formControlName="PayerType">
        <mat-radio-button [value]="'Sender'">Відправник документів</mat-radio-button>
        <mat-radio-button [value]="'Recipient'">Отримувач документів</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row" formArrayName="documents"
       *ngFor="let document of backwardDeliveryDataGroup.get('documents')['controls']; let i = index;">
    <div class="form-col form-col-4" [formGroupName]="i">
      <mat-form-field class="mat-block">
        <mat-select placeholder="Тип документу" [value]="findDoc(document.value.name)"
                    (selectionChange)="changeDoc($event.value, i)">
          <mat-option *ngFor="let doc of documents" [value]="doc">
            {{doc.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-col form-col-3 box-with-controls" [formGroupName]="i">
      <app-stamp-checkboxes [selectedDocumentGroup]="document"></app-stamp-checkboxes>
      <div class="control-icons" *ngIf="i > 0">
        <div class="control-icons__item">
          <button class="mat-icon-button mat-button" (click)="removeDocument(i)">
            <mat-icon class="material-icons">
              close
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row form-row_button">
    <div class="form-col form-col-3">
      <button type="button"
              class="mat-button_transparent mat-button_sm mat-button_uppercase mat-button_with-icon"
              (click)="addDocument()"
              mat-button>
        <i class="material-icons">
          add_circle
        </i>
        ДОДАТИ ТИП ДОКУМЕНТУ
      </button>
    </div>
  </div>
  <app-custom-return-address *ngIf="customAddresses"
                             [controlName]="returnAddressControlName"
                             [formGroup]="backwardDeliveryDataGroup"
                             [options]="customAddresses"
                             ></app-custom-return-address>
</div>

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { AutoCompleteOptionItem } from '@autocomplete';
import { WarehouseResult } from '@models';
import { AddressService } from '@services';
import {
  AbstractFormContainer,
  clearField,
  getError,
  PostboxAvailabilityValidator,
  WarehouseMaskValidator,
} from '@shared';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { RequestLimit } from '../../../../core/components/city-select/city-select.constants';
import { AddressIndexErrors } from '../../../errors/error-messages';
import { filterInputValue } from '../../../helpers/invoices-my.helpers';
import {
  Classes,
  ControlName,
  DebounceTime,
  Label,
  MaxLength,
  MinLength,
  NothingFoundLabel,
  Placeholder,
} from './address-by-index.constants';
import { getDescription } from './address-by-index.utils';

@Component({
  selector: 'app-address-by-index',
  templateUrl: './address-by-index.component.html',
  styleUrls: ['./address-by-index.component.scss'],
})
export class AddressByIndexComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  getError = getError;

  clearField = clearField;

  errors = AddressIndexErrors;

  @Input() placeholder: string = Placeholder;

  @Input() label: string = Label;

  @Input() nothingFoundLabel: string = NothingFoundLabel;

  @Input() testId: string = ControlName;

  @Input() minLength: number = MinLength;

  @Input() maxLength: number = MaxLength;

  @Input() requestLimit: number = RequestLimit;

  @Input() debounceTime: number = DebounceTime;

  @Input() getDescription = getDescription;

  @Input() clearEnabled: boolean = false;
  @Input() controlName = 'AddressSender';
  @Input() templateValidation: boolean = false;
  @Input() forSender: boolean = false;
  @Input()
  @HostBinding('class')
  classList = Classes;
  @ViewChild(MatInput, { read: ElementRef }) input;
  items: AutoCompleteOptionItem<WarehouseResult>[] = null;
  loading: boolean = false;
  subscription: Subscription = null;
  nothingFound = false;

  constructor(private addressService: AddressService, private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
    super();
  }

  private _filterPostboxes: boolean = false;

  get filterPostboxes(): boolean {
    return this._filterPostboxes;
  }

  @Input() set filterPostboxes(value: boolean) {
    this._filterPostboxes = value;
    this.updateItems(this.items);
  }

  get control(): AbstractControl | UntypedFormControl {
    return this.form?.get(this.controlName)?.get('warehouse');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.disableValidatorForTemplate();
  }

  disableValidatorForTemplate(): void {
    if (this.templateValidation) {
      this.control?.clearValidators();
      this.control?.setValidators([Validators.maxLength(MaxLength), WarehouseMaskValidator()]);
    }
  }

  setLoading(value: boolean): void {
    this.loading = value;
    this.cd.detectChanges();
  }

  initSubscription(): void {
    if (this.subscription) {
      return;
    }
    this.subscription = this.control?.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        map((q) => (q === null ? '' : q)),
        tap((query) => {
          if (query.length === 0) {
            this.items = [];
            this.nothingFound = false;
            this.cd.detectChanges();
          }
        }),
        filter((v) => filterInputValue(v, MinLength)),
        map((v) => v?.trim()),
        filter((v) => v.length >= MinLength),
        filter((v) => v.length <= MaxLength),
        switchMap((value) => this.getItemList(value)),
      )
      .subscribe((items) => {
        this.items = items.map((option) => this.makeItem(option));
        this.nothingFound = !items.length;
        this.cd.detectChanges();
      });
  }

  blur(): void {
    this.input.nativeElement.blur();
  }

  addFormControls(): void {
    this.form.setControl(
      this.controlName,
      this.fb.group({
        warehouse: [
          '',
          [
            Validators.required,
            Validators.maxLength(MaxLength),
            WarehouseMaskValidator(),
            ...(this.forSender ? [PostboxAvailabilityValidator(this.form.parent.parent)] : []),
          ],
        ],
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.form.removeControl(this.controlName);
  }

  private makeItem(option: WarehouseResult): AutoCompleteOptionItem<WarehouseResult> {
    return {
      option,
      ...(this.filterPostboxes || (this.forSender && option.PostomatFor !== 'Sender')
        ? {
            config: {
              disabled: option.CategoryOfWarehouse === 'Postomat',
              tooltip: (() => {
                if (option.CategoryOfWarehouse === 'Postomat') {
                  if (this.filterPostboxes && option.PostomatFor !== 'Sender') {
                    return 'Відправлення з даного поштомату недоступно';
                  }
                  return 'Параметри відправлення не задовільняють умови для відправки з поштомату';
                }
                return '';
              })(),
            },
          }
        : {}),
    };
  }

  private updateItems(items): void {
    if (!items?.length) {
      return;
    }

    this.items = items.map((item) => ({
      option: item.option,
      ...(this.filterPostboxes
        ? {
            config: {
              disabled: item.option.CategoryOfWarehouse === 'Postomat',
              tooltip: item.option.CategoryOfWarehouse === 'Postomat' ? 'Відправлення з поштомату недоступно' : '',
            },
          }
        : {}),
    }));
  }

  private getItemList(value: string): Observable<WarehouseResult[]> {
    this.setLoading(true);
    return this.getWarehouseList(value).pipe(
      finalize(() => this.setLoading(false)),
      catchError(() => of([])),
    );
  }

  private getWarehouseList(value: string): Observable<WarehouseResult[]> {
    return this.addressService.getWarehousesByIndex(value);
  }
}

import * as moment from 'moment';
import { CompareFunction, CompareTranslationFunction } from '../interfaces/compare-function';

export function compareNoop(a: any, b: any, isAsc: boolean): number {
  return 0;
}

export function getDeepKey(obj: Object = {}, keys: string[] = []): any {
  return keys.reduce((acc, curr) => {
    return acc[curr];
  }, obj);
}

export function compareNumbers(a: string | number = 0, b: string | number = 0, isAsc: boolean = true): number {
  return (Number(a) < Number(b) ? -1 : 1) * (isAsc ? 1 : -1);
}

// sorting for a string of numbers with a hyphen for example column transferNumber
export function compareSeparatorStringNumbers(a: string | number = 0, b: string | number = 0, isAsc: boolean = true): number {
  if (typeof a !== 'number' && typeof b !== 'number') {
    const newStringA = a.split('-');
    const newStringB = b.split('-');
    if (newStringA[0] === newStringB[0]) {
      if (newStringA[1] !== newStringB[1]) {
        return (Number(newStringA[1]) < Number(newStringB[1]) ? -1 : 1) * (isAsc ? 1 : -1);
      }
    } else {
      if (newStringA[0] !== newStringB[0]) {
        return (Number(newStringA[0]) < Number(newStringB[0]) ? -1 : 1) * (isAsc ? 1 : -1);
      }
    }
  }
}

// sorting for a string is the largest of the numbers for example column docNumber
export function compareSeparatorLongStringNumbers(a: string | number = 0, b: string | number = 0, isAsc: boolean = true): number {
  if (typeof a !== 'number' && typeof b !== 'number') {
    const firstSliceA = a.slice(0, 6);
    const firstSliceB = b.slice(0, 6);

    const lastSliceA = a.slice(6);
    const lastSliceB = b.slice(6);

    if (firstSliceA === firstSliceB) {

      if (lastSliceA !== lastSliceB) {
        return (Number(lastSliceA) < Number(lastSliceB) ? -1 : 1) * (isAsc ? 1 : -1);
      }
    } else {
      if (firstSliceA !== firstSliceB) {
        return (Number(firstSliceA) < Number(firstSliceB) ? -1 : 1) * (isAsc ? 1 : -1);
      }
    }
  }
}

export function compareEmptyNumbers(a: string | number = 0, b: string | number = 0, isAsc: boolean = true): number {
  if (a === '' || a === 0) {
    return 1;
  } else if (b === '' || b === 0) {
    return -1;
  } else {
    return (Number(a) < Number(b) ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

export function compareStrings(a: string = '', b: string = '', isAsc: boolean = true): number {
  if (a === '') {
    return 1;
  } else if (b === '') {
    return -1;
  } else {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }
}

export function compareStringsWithTranslation(translation: { [key: string]: string }, a: string = '', b: string = '', isAsc: boolean = true): number {
  return translation[a].localeCompare(translation[b]) * (isAsc ? 1 : -1);
}

export function compareEmptyStrings(a: string = '', b: string = '', isAsc: boolean = true): number {
  if (a === '' || a === 'Приватна особа') {
    return 1;
  } else if (b === '' || b === 'Приватна особа') {
    return -1;
  } else {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }
}

export function compareStringWithoutRegister(a: string = '', b: string = '', isAsc: boolean = true): number {
  return compareStrings(a.toLowerCase(), b.toLowerCase(), isAsc);
}

export function compareByNestedKey<T>(compareFn: CompareFunction<any>, ...keys: string[]): CompareFunction<T> {
  return function <T>(obj1: T, obj2: T, isAsc: boolean): number {
    return compareFn(getDeepKey(obj1, keys), getDeepKey(obj2, keys), isAsc);
  } as CompareFunction<T>;
}

export function compareByNestedKeyWithTranslation<T>(compareFn: CompareTranslationFunction<any>, translation: { [key: string]: string }, ...keys: string[]): CompareFunction<T> {
  return function <T>(obj1: T, obj2: T, isAsc: boolean): number {
    return compareFn(translation, getDeepKey(obj1, keys), getDeepKey(obj2, keys), isAsc);
  } as CompareFunction<T>;
}

export function compareDates(a: Date, b: Date, isAsc: boolean): number {
  if (a === null) {
    return 1;
  } else if (b === null) {
    return -1;
  } else {
    const getTime = (date: Date): number => date ? date.getTime() : 1;
    return compareNumbers(getTime(a), getTime(b), isAsc);
  }
}

export function compareStringDates(a: string = '', b: string = '', isAsc: boolean = true): number {
  if (a === null) {
    return 1;
  } else if (b === null) {
    return -1;
  } else {
    return compareDates(moment(a).isValid() ? moment(a).toDate() : null, moment(b).isValid() ? moment(b).toDate() : null, isAsc);
  }
}

export function compareStringDatesWithoutTime(a: string = '', b: string = '', isAsc: boolean = true): number {
  return compareDates(moment(a, 'DD.MM.YYYY').isValid() ? moment(a, 'DD.MM.YYYY').toDate() : null, moment(b, 'DD.MM.YYYY').isValid() ? moment(b, 'DD.MM.YYYY').toDate() : null, isAsc);
}

export function compareStringDatesFormat(a: string = '', b: string = '', isAsc: boolean = true): number {
  return compareDates(moment(a, 'DD.MM.YYYY HH:MM:SS').isValid() ? moment(a, 'DD.MM.YYYY').toDate() : null, moment(b, 'DD.MM.YYYY').isValid() ? moment(b, 'DD.MM.YYYY').toDate() : null, isAsc);
}

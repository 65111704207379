<div class="p-6 position-relative d-flex flex-direction-column h-100 light-return__confirm">
  <div class="modal-title text-medium ">Накладна на повернення створена</div>
  <div class="modal-text pt-4">Запакуйте та відправте свою посилку.<br>
                               Дані про відправлення можна переглянути в розділі Чернетка
  </div>
  <mat-form-field class="main">
    <input matInput [value]="number | fineDocNumber"
           readonly
           placeholder="Номер накладної повернення">
    <button mat-icon-button
            matSuffix
            [appCopy]="number"
            [matTooltip]="'Скопіювати'"
            class="form-field__btn-copy p-0">
      <mat-icon class="material-icons_grey form-field__btn-copy-icon">content_copy</mat-icon>
    </button>
  </mat-form-field>
  <div class="modal-footer pt-20 d-flex justify-content-flex-end">
    <div class="d-inline-block flex-none">
      <button mat-button
              (click)="close()"
      >
        Зрозуміло
      </button>
      <button
        color="primary"
        mat-button
        (click)="closeAndRedirect()"
      >
        <mat-icon>content_paste</mat-icon>
        Перейти до чернетки
      </button>
    </div>
  </div>
</div>


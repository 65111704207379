<div [className]="'form-col form-col-' + size">
  <mat-form-field [class.pointer]="pointer"
                  [class.mat-block_select-arrow]="config?.arrow"
                  class="form-input">
    <input type="text"
           class="input"
           #input
           matInput
           [class.no-events]="pointer"
           [matAutofocus]="!config.focused"
           [formControl]="formControl"
           [readonly]="readonly"
           [matAutocomplete]="auto"
           [placeholder]="config.placeholder"
           [attr.aria-label]="config.ariaLabel"
           [errorStateMatcher]="config.errorStateMatcher"
           [keyInputPrevent]="preventInput"
           (focus)="onFocus($event)"
           (input)="sendInputValue($event)"
           (keypress)="textInput.emit($event)"
           autoScrollTarget>
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayTransformFn"
                      (opened)="panelOpened()"
                      [panelWidth]="panelWidth"
                      (optionSelected)="onSelected($event)">

      <app-autocomplete-search *ngIf="searchEnabled"
                               [searchDebounce]="searchDebounce"
                               [placeholder]="searchPlaceholder"
                               (textInput)="sendDropdownSearchValue($event)"
                               [loading]="searching"></app-autocomplete-search>

      <mat-option *ngIf="searchEnabled && !options.length"
                  disabled>Нічого не знайдено
      </mat-option>

      <mat-option *ngFor="let optionItem of options"
                  [value]="optionItem.option"
                  [matTooltip]="optionItem?.config?.tooltip"
                  matTooltipPosition="below"
                  [disabled]="optionItem?.config?.disabled">
        {{displayTransformFn(optionItem.option)}}
      </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="config.hint">
      {{ config.hint }}
    </mat-hint>

    <mat-error>{{getError(formControl, errors)}}</mat-error>
  </mat-form-field>
</div>

<app-autocomplete-controls [config]="controlsConfig"
                           (triggered)="triggerControl($event)">
</app-autocomplete-controls>

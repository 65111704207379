/**
 * Created by andrey on 03.07.16.
 */
import { Model } from './base.model';
import {Locality} from './locality.model';

export class Street extends Model {
    name: string;
    locality: Locality;
    protected _description: string;
    type: string;

    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }
}

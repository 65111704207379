import { CollectionViewer } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs';
import { ListDataResponse, TableDataSource } from '@shared';
import { Invoice } from '../../../../models/invoice/invoice.model';
import { InvoiceService } from '../../../../services/invoices/invoice.service';
import { IListData, InvoiceResponseModel } from '@models';

export class RegistersShowDatasource extends TableDataSource<InvoiceResponseModel> {
  bindedToModelInvoices: BehaviorSubject<Map<string, Invoice>> =
    new BehaviorSubject<Map<string, Invoice>>(new Map());

  invoices = [];

  constructor(protected invoiceService: InvoiceService) {
    super();
  }

  loadRegistryShowInvoices(
    filter: string,
    sortEvent: Sort = null,
    pageIndex: number = 0,
    pageSize = 100,
    registerId: string,
    sortConf: any = null,
  ): void {
    this.loading.next(true);

    const dbFilter = { type: 'register', registerId };

    this.invoiceService
      .getInvoiceList(dbFilter, null, pageIndex + 1, pageSize)
      .pipe(
        switchMap((invoices: IListData<Invoice>) => {
          this.bindedToModelInvoices.next(
            invoices.list.length === 0 || invoices.total === 0
              ? new Map()
              : invoices.list.reduce(
                  (m, invoice) => m.set(invoice.id, invoice),
                  new Map(),
                ),
          );

          this.invoices = invoices.list;
          if (invoices.list.length) {
            return this.invoiceService
              .getRefList(invoices.list.map((invoice) => invoice.id))
              .pipe(map((data) => ({ list: data, total: invoices.total })));
          } else {
            return of({ list: [], total: 0 });
          }
        }),
        catchError(() => of(new ListDataResponse<InvoiceResponseModel>())),
        finalize(() => this.loading.next(false)),
      )
      .subscribe((invoices) => {
        this.total.next(Number(invoices.total) || invoices.list.length || 0);
        this.totalInCurrentSearch.next(invoices.list.length);

        this.source.next(
          invoices.list.sort(
            (a, b) =>
              moment(b.CreateTime).valueOf() - moment(a.CreateTime).valueOf(),
          ) || [],
        );

        if (sortConf && sortEvent) {
          this.sort(sortConf[sortEvent.active], sortEvent.direction === 'asc');
        }
      });
  }

  disconnect(collectionViewer: CollectionViewer): void {
    super.disconnect(collectionViewer);
    this.bindedToModelInvoices.complete();
  }

  getBindedInvoice(ref: string): Invoice {
    return this.bindedToModelInvoices.getValue().get(ref) || null;
  }
}

export const options: Array<Record<string, string>> = [
  {
    weight: '500',
    volumetricLength: '60',
    volumetricWidth: '80',
    volumetricHeight: '170',
    volumetricVolume: '0,816',
    volumetricWeight: '204',
  },
  {
    weight: '500',
    volumetricLength: '120',
    volumetricWidth: '80',
    volumetricHeight: '170',
    volumetricVolume: '1,632',
    volumetricWeight: '408',
  },
  {
    weight: '750',
    volumetricLength: '120',
    volumetricWidth: '120',
    volumetricHeight: '170',
    volumetricVolume: '2,448',
    volumetricWeight: '612',
  },
  {
    weight: '1000',
    volumetricLength: '141',
    volumetricWidth: '141',
    volumetricHeight: '170',
    volumetricVolume: '3,264',
    volumetricWeight: '816',
  },
];
export const defSeat = {
  amount: '1',
  option: {
    weight: '500',
    volumetricLength: '120',
    volumetricWidth: '80',
    volumetricHeight: '170',
    volumetricVolume: '1,632',
    volumetricWeight: '408',
  },
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, Contact, Contragent, IListData } from '@models';
import { isNumber } from 'lodash';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { OrderState } from '../components/dashboard/business-services/models/order-state';
import { ReturnReason } from '../components/dashboard/business-services/models/return-reason';
import { BackInvoice } from '../models/back-invoice';
import { InvoiceState } from '../models/invoice/invoice-state.model';
import { Invoice } from '../models/invoice/invoice.model';
import { CheckPossibilityCreateReturnApiResponse } from '../shared/components/back-invoices-form/return-request.interfaces';
import { ApiMethods } from './api/apiMethods';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class BackInvoicesApiService {
  constructor(
    private http: HttpClient,
    private authorizationService: AuthService,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {
  }

  create(invoice: Invoice, data, onlyGetPricing: boolean = false, Ref?: string): Observable<BackInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalServiceGeneral',
        calledMethod: ApiMethods.save,
        methodProperties: {
          OrderType: 'orderCargoReturn',
          IntDocNumber: invoice.docNumber,
          OnlyGetPricing: onlyGetPricing ? 1 : 0,
          Ref,
          ...data,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data.data.data && data.data.data[0]),
      );
  }

  update(invoice: Invoice, data, onlyGetPricing: boolean = false, Ref?: string): Observable<BackInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: ApiMethods.update,
        methodProperties: {
          OrderType: 'orderCargoReturn',
          IntDocNumber: invoice.docNumber,
          OnlyGetPricing: onlyGetPricing ? 1 : 0,
          Ref,
          ...data,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data.data.data && data.data.data[0]),
      );
  }

  getList(
    page: number = 1,
    limit: number = 10,
  ): Observable<IListData<BackInvoice>> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getReturnOrdersList',
        methodProperties: {
          Page: page.toString(),
          Limit: limit.toString(),
          BeginDate: moment().subtract(3, 'months').format('DD.MM.YYYY'),
          EndDate: moment().format('DD.MM.YYYY'),
          OrderField: 'DateTime',
          SearchByCounterparties: this.authorizationService.user.contacts
            ? 1
            : null,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => {
          data = data.data;

          return {
            list: data.data.map((el) => this.bindOne(el)),
            total: (data.info && data.info.totalCount) || data.length,
          };
        }),
        map((data) => {
          data.list = (data.list || []).sort(
            (back1: BackInvoice, back2: BackInvoice) => {
              const time1 = back1.createDateTime?.getTime();
              const time2 = back2.createDateTime?.getTime();

              if (time1 < time2) {
                return 1;
              } else if (time1 > time2) {
                return -1;
              }

              return 0;
            },
          );

          return data;
        }),
      );
  }

  getOne(orderId: string): Observable<BackInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getReturnOrdersList',
        methodProperties: {
          Ref: orderId,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => {
          data = (data.data.data && data.data.data[0]) || [];

          return this.bindOne(data);
        }),
      );
  }

  getReasons(): Observable<ReturnReason[]> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getReturnReasons',
        methodProperties: {},
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) =>
          data.data.data.map((item) =>
            Object.assign(new ReturnReason(item.Ref), {
              title: item.Description,
            }),
          ),
        ),
      );
  }

  getSubReasons(idReason: string): Observable<ReturnReason[]> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getReturnReasonsSubtypes',
        methodProperties: {
          ReasonRef: idReason,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) =>
          data.data.data.map((item) =>
            Object.assign(new ReturnReason(item.Ref), {
              title: item.Description,
            }),
          ),
        ),
      );
  }

  remove(idOrder: string): Observable<boolean> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'delete',
        methodProperties: {
          Ref: idOrder,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => data.data.success),
      );
  }

  checkIfPossibleCreate(
    docNumber: string,
    OrderRef?: string,
  ): Observable<CheckPossibilityCreateReturnApiResponse> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'CheckPossibilityCreateReturn',
        methodProperties: {
          Number: docNumber,
          OrderRef,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data?.data),
      );
  }

  private bindOne(data): BackInvoice {
    const contactArr: string[] =
      (data.ContactPersonRecipient && data.ContactPersonRecipient.split(' ')) ||
      [];

    return Object.assign(new BackInvoice(data.OrderRef), {
      number: data.OrderNumber,
      createDateTime: data.DateTime
        ? moment(data.DateTime,
        'D.M.YYYY H:m:s',
          )
          .toDate()
        : null,
      state: Object.assign(
        new OrderState(),
        // see CBC-87
        {
          name: data.OrderStatus === 'Прийняте' ? 'Прийнята' : data.OrderStatus,
        } as OrderState,
      ),
      docNumber: data.DocumentNumber,
      recipient: Object.assign(new Contact(contactArr[1], contactArr[0]), {
        phone: data.RecipientPhone && `+${data.RecipientPhone}`,
        middleName: contactArr[2],
        contragent:
          data.CounterpartyRecipient &&
          data.CounterpartyRecipient !== 'Приватна особа'
            ? Object.assign(new Contragent(), {
              name: data.CounterpartyRecipient,
            } as Contragent)
            : null,
      } as Contact),
      recipientAddress: Object.assign(new Address(), {
        description: data.RecipientAddress || data.AddressRecipient,
      } as Address),
      deliveryCost: isNumber(data.DeliveryCost) ? data.DeliveryCost : -1,
      estimatedDeliveryDate: data.EstimatedDeliveryDate ? moment(
        data.EstimatedDeliveryDate,
        'D.M.YYYY H:m:s',
      ).toDate() : null,
      newDocNumber: data.ExpressWaybillNumber,
      newDocState: Object.assign(new InvoiceState(), {
        name: data.ExpressWaybillStatus,
      } as InvoiceState),
      ...data,
    } as BackInvoice);
  }
}

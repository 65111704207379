<ng-container [formGroup]="form">
  <div class="d-flex flex-direction-column">
    <div class="filter-label">{{config.title}}</div>
    <mat-form-field [floatLabel]="'never'"
                    (click)="stopPropagation($event)"
                    class="form-input form-input_date filter-field m-0">
      <input [formControlName]="controlName"
             [attr.data-test-id]="controlName+'-input'"
             [matDatepicker]="pickerFrom"
             (click)="pickerFrom.open()"
             [min]="config.minDate"
             [max]="config.maxDate"
             [readonly]="true"
             [placeholder]="config.placeholder"
             class="date-input"
             matInput>
      <button *ngIf="control?.value"
              (click)="clearField(control, $event)"
              [attr.data-test-id]="controlName+'-clear'"
              class="p-0"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix class="mat-datepicker-toggle"
                             [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
      <mat-error>{{ getError(control, config.errors) }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>

<div class="navbar__container d-flex align-items-center pl-3 pr-8 w-100">
  <svg (click)="exit()" class="cursor-pointer" fill="none" height="40" viewBox="0 0 40 40" width="40"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2833 28.337V22.8223H17.5955V28.337H14.0113L18.359 32.6847C19.2325 33.5582 20.6478 33.5582 21.5199 32.6847L25.8676 28.337H22.2833ZM11.6674 25.9931V14.1367L7.31967 18.4844C6.44618 19.3579 6.44618 20.7733 7.31967 21.6454L11.6674 25.9931ZM17.5955 11.7928V17.3076H22.2833V11.7928H25.8676L21.5199 7.44514C20.6464 6.57165 19.2311 6.57165 18.359 7.44514L14.0113 11.7928H17.5955ZM32.5578 18.4844L28.2101 14.1367V25.9931L32.5578 21.6454C33.4313 20.7719 33.4313 19.3565 32.5578 18.4844Z"
      fill="#DA292B"/>
  </svg>
  <div class="navbar__title pl-2">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="navbar__search px-5">
    <ng-content select="app-seek-templates"></ng-content>
  </div>
  <ng-content select="app-donate-button"></ng-content>
  <ng-content select="app-header-chat"></ng-content>
</div>

import { Model } from './base.model';
import { Contact } from './contact.model';
import {Locality} from './locality.model';
import {Schedule} from './schedule';
/**
 * Created by Ruslan on 25.08.2016.
 */

export class Warehouse extends Model {
    protected _description: string;
    descriptionRu: string;
    phone: string;
    type: string;
    buildingNumber: string;
    city: Locality;
    warehouseNumber: string;
    contactPerson: Contact;

    reception: Schedule;
    delivery: Schedule;
    schedule: Schedule;

    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }
}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { isInt } from '@shared';
import { take } from 'rxjs';
import { ErrorSnackbarComponent } from '../core/components/error-snackbar/error-snackbar.component';
import { ERROR_PREFIX } from '../shared/constants';
import { IApiError } from './api-helper';

export type AlertToastType = 'SUCCESS' | 'WARNING' | 'ERROR';

export const AlertToastTypes = {
  SUCCESS: 'Готово!',
  WARNING: 'Попередження',
  ERROR: 'Помилка',
};

export type AppSnackBar = MatSnackBarRef<SimpleSnackBar>;

export const defaultDuration = 5000;

export interface IAlertToastAction {
  label?: string;
  actionCallback?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AlertToastService {
  readonly NP_ERROR_PREFIX = `${ERROR_PREFIX}.`;

  readonly ALERT_TOAST_TRANSLATE_PREFIX = 'ALERT_TOAST_SERVICE';

  readonly UNHANDLED_ERROR = `${this.ALERT_TOAST_TRANSLATE_PREFIX}.UNHANDLED_ERROR`;

  constructor(private snackBar: MatSnackBar) {
  }

  pushError(
    message: string,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): AppSnackBar {
    const { label, actionCallback } = this.getCheckedAction(action);
    const messageCode = message.substring(this.NP_ERROR_PREFIX.length);

    const isCodeError = isInt(messageCode);
    const errorMessage = !isCodeError
      ? message
      : message.substring(this.NP_ERROR_PREFIX.length);

    const snackMessage = `${this.getPrefix('ERROR')} ${
      isCodeError ? messageCode : ''
    }: ${errorMessage}`;

    return this.open(snackMessage, label, actionCallback, duration);
  }

  pushApiError(
    error: IApiError,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): AppSnackBar {
    const { messageCode, message } = this.getCheckedError(error);
    const { label, actionCallback } = this.getCheckedAction(action);

    const isNumberCode = !!messageCode && isInt(Number(messageCode));

    const errorMessage = isNumberCode
      ? error.translatedError || error.addInfo
      : message;

    const snackMessage = `${this.getPrefix('ERROR')} ${
      isNumberCode ? messageCode : ''
    }: ${errorMessage}`;

    return this.open(snackMessage, label, actionCallback, duration);
  }

  pushApiErrors(
    errors: IApiError[],
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): MatSnackBarRef<ErrorSnackbarComponent> {
    const snackMessages = [];
    let errorLabel = '';
    let errorActionCallback;
    for (const error of errors) {
      const { messageCode, message } = this.getCheckedError(error);
      const { label, actionCallback } = this.getCheckedAction(action);

      errorLabel = label;
      errorActionCallback = actionCallback;

      const isNumberCode = !!messageCode && isInt(Number(messageCode));

      const errorMessage = isNumberCode
        ? error.translatedError || error.addInfo
        : message;

      snackMessages.push(
        `${this.getPrefix('ERROR')} ${
          isNumberCode ? messageCode : ''
        }: ${errorMessage}`,
      );
    }

    return this.openErrorComponent(
      snackMessages,
      '',
      errorActionCallback,
      duration,
    );
  }

  pushRegisterApiErrors(
    errors: IApiError[],
    headerMessage: string,
    message: string,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): MatSnackBarRef<ErrorSnackbarComponent> {
    const snackMessages = [];
    snackMessages.push(headerMessage);
    for (const error of errors) {
      snackMessages.push(`${message}: ${error}`);
    }

    return this.openErrorComponent(snackMessages, '', null, duration);
  }

  pushSpecificApiErrors(errors: IApiError[],
                headerMessage: string,
                message: string,
                action: IAlertToastAction = {},
                translateParams = {},
                duration: number | false = defaultDuration): MatSnackBarRef<ErrorSnackbarComponent> {
    const snackMessages = [];
    snackMessages.push(headerMessage);
    for (const error of errors) {
      snackMessages.push(`${message}: ${error}`);
    }

    return this.openErrorComponent(snackMessages, '', null, duration);
  }

  pushRegisterRemoveApiErrors(
    errors: { Ref: string; Error: string; Number: string }[],
    message: string,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): MatSnackBarRef<ErrorSnackbarComponent> {
    const snackMessages = [];
    snackMessages.push('Попередження! Обраний реєстр не розформовано');
    for (const error of errors) {
      snackMessages.push(`${message}: ${error.Error}`);
    }

    return this.openErrorComponent(snackMessages, '', null, duration);
  }

  pushWarn(
    message: string,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): AppSnackBar {
    const { label, actionCallback } = this.getCheckedAction(action);
    const snackMessage = `${this.getPrefix('WARNING')}: ${message}`;
    return this.open(snackMessage, label, actionCallback, duration);
  }

  pushSuccess(
    message: string,
    action: IAlertToastAction = {},
    translateParams = {},
    duration: number | false = defaultDuration,
  ): AppSnackBar {
    const { label, actionCallback } = this.getCheckedAction(action);
    const snackMessage = `${this.getPrefix('SUCCESS')} ${message}`;
    return this.open(snackMessage, label, actionCallback, duration);
  }

  hide(): void {
    this.snackBar.dismiss();
  }

  private getCheckedAction(action: IAlertToastAction = {}): IAlertToastAction {
    if (!action) {
      return { label: null, actionCallback: null };
    }
    const label = action.label ? action.label : null;
    const actionCallback = action.actionCallback ? action.actionCallback : null;
    return { label, actionCallback };
  }

  private getCheckedError(error: IApiError): IApiError {
    const checkedError = (error || {}) as IApiError;
    return {
      messageCode: checkedError.message || '',
      errorCode: checkedError.errorCode || '',
      message: error.translatedError || error.addInfo,
      addInfo: checkedError.addInfo || {},
    };
  }

  private getPrefix(type: AlertToastType): string {
    return AlertToastTypes[type];
  }

  private open(
    message: string = '',
    actionMessage: string = null,
    actionCallback: () => void,
    duration: number | false = defaultDuration,
  ): AppSnackBar {
    const snackBarRef = this.snackBar.open(message, actionMessage, {
      panelClass: 'app-snackbar-center',
      ...(!!duration ? { duration } : {}),
    });
    if (actionCallback) {
      snackBarRef.onAction().pipe(take(1)).subscribe(actionCallback);
    }
    return snackBarRef;
  }

  private openErrorComponent(
    messages: string[],
    actionMessage: string = null,
    actionCallback: () => void,
    duration: number | false = defaultDuration,
  ): MatSnackBarRef<ErrorSnackbarComponent> {
    return this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: messages,
      ...(!!duration ? { duration } : {}),
    });
  }
}

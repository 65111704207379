<ng-container [formGroup]="addressFormGroup">
  <div class="top-row">
    <app-city-select [formControl]="city"
                     [disableOptionsIfNoWarehouses]="false"
                     [classList]="cityClasses"></app-city-select>

    <app-street-select [formControl]="street"
                       [classList]="streetClasses"
                       [cityRef]="cityRef"></app-street-select>

    <app-building [formControl]="building"
                  [classList]="buildingClasses"></app-building>

    <ng-container *ngIf="showFlat">
      <app-autocomplete data-test-id="room"
                        [size]="1"
                        [config]="configRoom"
                        [formControl]="room"
                        [pointer]="false"
                        [searchEnabled]="false"
                        [controlsConfig]="null">
      </app-autocomplete>
    </ng-container>
  </div>

  <app-address-note *ngIf="showNote" [formControl]="note"></app-address-note>
</ng-container>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-services-banner',
  templateUrl: './services-banner.component.html',
  styleUrls: ['./services-banner.component.scss']
})
export class ServicesBannerComponent  {
  @Input() servicesInfo = {
    bannerPacking: false,
    modifier: '',
    title: '',
    img: '',
    text: '',
    link: {
      url: '',
      name: '',
    },
    firstListTitle: '',
    firstList: '',
    firstListNote: '',
    secondListTitle: '',
    secondList: '',
    description: ''
  };
}


<mat-form-field>
  <mat-select placeholder="Часовий інтервал"
              [disabled]="pending || !recipientCityRef"
              (selectionChange)="changeInterval($event)"
              [value]="initialInterval">
    <mat-option [value]="''" *ngIf="intervalMode === 'ID' || (intervalMode === 'Pickup' && !timeIntervals.length)">
      Впродовж дня
    </mat-option>
    <mat-option *ngFor="let interval of timeIntervals"
                [value]="interval.Number">
      {{interval.Start}}-{{interval.End}}
    </mat-option>
  </mat-select>
</mat-form-field>

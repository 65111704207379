import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { finalize, take } from 'rxjs';
import { InvoiceApiService } from '../../../../services/invoices/invoice-api.service';

@Component({
  selector: 'app-time-intervals',
  templateUrl: './time-intervals.component.html',
  styleUrls: ['./time-intervals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeIntervalsComponent implements OnInit, OnChanges {
  @Output()
  onIntervalChange = new EventEmitter<string>();

  @Input()
  initialInterval = '';

  @Input()
  recipientCityRef: string;

  @Input()
  dateTime: string = moment().format('DD.MM.YYYY');

  timeIntervals = [];

  pending = true;

  @Input()
  intervalMode: 'ID' | 'Pickup' = 'ID';

  constructor(
    private invoiceService: InvoiceApiService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getTimeIntervals(this.dateTime, this.recipientCityRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTimeIntervals(this.dateTime, this.recipientCityRef);
  }

  getTimeIntervals(dateTime, recipientCityRef) {
    if (!dateTime || !recipientCityRef) {
      return;
    }
    this.pending = true;
    const intervalMethodObs =
      this.intervalMode === 'ID'
      ? this.invoiceService.getTimeIntervals(dateTime, recipientCityRef)
      : this.invoiceService.getPickupTimeIntervals(
        dateTime,
        recipientCityRef,
      );
    intervalMethodObs
      .pipe(
        take(1),
        finalize(() => (this.pending = false)),
      )
      .subscribe(
        (data) => {
          this.timeIntervals = data;
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
        },
        (error) => {
          this.timeIntervals = [];
          this.onIntervalChange.next('');
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
        },
      );
  }

  changeInterval(event) {
    this.onIntervalChange.next(event.value);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent, AutocompleteMode,
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { ContactPerson, ContactType, TypedFormGroup } from '@models';
import { AlertToastService, AuthService, ErrorMessages, ErrorTypeMessages, ValidationService } from '@services';
import {
  AbstractFormContainer,
  CardTypes,
  EditableAutocompleteControl,
  formatUAPhone,
  TypedFormControl,
  ValidateContact,
} from '@shared';
import { TypeRadioButton } from '@type-radio-group';
import { filter, switchMap } from 'rxjs';
import { ContactsService } from '../../../../services/contacts/contacts.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog';
import { SettingsService } from '../../../dashboard/settings/services/settings.service';

@Component({
  selector: 'app-inv-contact-sender',
  templateUrl: 'contact-sender.component.html',
  styleUrls: ['./contact-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSenderComponent
  extends AbstractFormContainer
  implements EditableAutocompleteControl, TypedFormControl, OnInit {
  readonly CONTROL_NAMES: { [key: string]: keyof TypedFormGroup } = {
    MODE: 'mode',
    DATA: 'data',
  };
  @Input() controlName = 'ContactSender';
  @Input() controlTypeName = 'ContactSenderType';
  @Input() config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Контакт відправника',
    placeholder: 'Контакт',
    arrow: true,
  };
  @Input() invoiceId: string;
  @Input() currentContact;
  @Input() fixedContact;
  @Input() controlsConfig: AutocompleteControls = {
    edit: {
      enabled: true,
      tooltip: 'Редагувати відправника',
    },
    create: {
      enabled: true,
      tooltip: 'Створити нового відправника',
    },
    delete: {
      enabled: false,
      tooltip: 'Видалити контакт',
    },
  };
  formGroup: UntypedFormGroup;
  @Input() types: Array<TypeRadioButton<ContactType>> = [
    {
      value: 'PrivatePerson',
      label: 'Фізична особа',
    },
    {
      value: 'Organization',
      label: 'Організація',
    },
  ];
  @Input() activeType: ContactType | null;
  @Input() preventInput = false;
  @Output() controlChanged = new EventEmitter<{
    data: ContactPerson | '';
    mode: string;
  }>();
  @Output() onUpdate = new EventEmitter<boolean>();
  @Output() onSenderContactSearch = new EventEmitter<string>();
  errors: ErrorTypeMessages = {
    [this.controlName]: [
      { key: 'required', message: 'Це поле обов\'язкове' },
      { key: 'contact', message: 'Виберіть із запропонованих варіантів' },
    ],
  };
  errorMessages: ErrorMessages = {};
  @Input() saveBtnShown = false;
  @Input() dropdownSearching = false;
  isSearching: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    private validationService: ValidationService,
    private contactsService: ContactsService,
    private settingsService: SettingsService,
    private alertToastService: AlertToastService,
    private dialog: MatDialog,
  ) {
    super();
  }

  private _options = [];

  get options(): Array<AutoCompleteOptionItem<ContactPerson>> {
    return this._options;
  }

  @Input() set options(data) {
    if (!data) {
      return;
    }
    this._options = data;
    if (!this.currentContact) {
      this.createControl(this._options[0].option);
    }
  }

  _mode = null;

  get mode() {
    return this._mode;
  }

  @Input()
  set mode(value) {
    if (typeof value !== 'string') {
      value = 'select';
    }
    this._mode = value;
    if (this.parentForm) {
      this.parentForm.get('mode').setValue(this._mode, { emitEvent: false });
      if (this.form.get(this.controlName)) {
        this.controlChanged.next({
          ...this.form.getRawValue()[this.controlName],
          mode: this._mode,
        });
      }
    }
  }

  get user() {
    return this.authService.user;
  }

  get parentForm(): AbstractControl | null {
    return this.form.get(this.controlName);
  }

  addFormControls(): void {
    const defaultMode = this.options.length ? 'select' : 'create';
    let defautValue;
    const value =
      this.options.length &&
      this.options.find((opt) => opt.option.Phones === this.user.phone);
    if (value) {
      defautValue = value.option;
    } else {
      defautValue = this.options.length ? this.options[0].option : '';
    }
    this.createControl(defautValue, defaultMode);
    this.form.get(this.controlName).valueChanges.subscribe((_) => {
      this.errorMessages = this.validationService.getSingleControlErrorObject(
        this.controlName,
        this.form.get(this.controlName),
        this.errors,
      );
    });
  }

  createControl(
    defaultValue: ContactPerson | '',
    defaultMode: string = 'select',
  ): void {
    this.formGroup = this.fb.group({
      mode: [defaultMode],
      data: [
        {
          value: defaultValue,
          disabled:
            this.options.length === 1 &&
            this.user.loyaltyCardType === CardTypes.ROZNIZA &&
            !this.fixedContact,
        },
        Validators.compose([Validators.required, ValidateContact]),
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);
    this.mode = defaultMode;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  searchContact(value: string): void {
    this.isSearching = value !== '';
    this.onSenderContactSearch.emit(value);
  }

  transformFn: AutoCompleteTransformFn<ContactPerson> = (
    value: string | ContactPerson,
  ) =>
    typeof value === 'string'
      ? value
      : `${(value && value.Description) || ''} ${formatUAPhone(
        (value && value.Phones) || '',
      )}`;

  optionsFilterFn: AutocompleteOptionsFilterFn<ContactPerson> = (
    value: string | ContactPerson,
    options: Array<AutoCompleteOptionItem<ContactPerson>>,
  ) => {
    const format = (v) => v.replace(/[\s\+\-]+/g, '').toLowerCase();
    const group = (contact: ContactPerson) =>
      `${(contact && contact.Description) || ''} ${
        (contact && contact.Phones) || ''
      }`;

    const v = format(typeof value === 'string' ? value : group(value));

    return options.filter((el) => format(group(el.option)).includes(v));
  };

  cancel(): void {
    this.mode = 'select';
    this.createControl(this.options.length ? this.currentContact : '');
    this.cd.markForCheck();
    if (this.isSearching) {
      this.isSearching = false;
      this.onUpdate.next(true);
    }
  }

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    if (event.triggerName === AutocompleteMode.CREATE && !this.options.length) {
      this.searchContact('');
    }

    if (event.triggerName !== 'delete') {
      this.mode = event.triggerName;
      setTimeout(() => {
        if (this.mode === 'edit') {
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['phone'].setValue(
            this.currentContact.Phones,
          );
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['lastName'].setValue(
            this.currentContact.LastName,
          );
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['firstName'].setValue(
            this.currentContact.FirstName,
          );
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['middleName'].setValue(
            this.currentContact.MiddleName,
          );
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['ref'].setValue(
            this.currentContact.Ref,
          );
          this.form
            .get(this.controlName)
            ['controls']['data']['controls']['counterRef'].setValue(
            this.currentContact.CounterpartyRef,
          );
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        panelClass: 'no-padding',
        data: {
          confirmMsg: 'Дійсно бажаєте видалити цей контакт?',
          confirmBtnText: 'Видалити',
          cancelBtnText: 'Скасувати',
          isConfirmHtml: false,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(
          filter((result) => !!result),
          switchMap(() =>
            this.contactsService.remove(
              this.form.get(this.controlName)['controls']['data'].value.Ref,
            ),
          ),
        )
        .subscribe(() => {
          this.currentContact = null;
          this.onUpdate.next(true);
          this.cd.markForCheck();
          this.alertToastService.pushSuccess('Контакт успішно видалено');
        });
    }
  }

  changeFullName(): void {
    const newContactParams = this.form.get(this.controlName)['controls']['data']
      .value;
    const contact: ContactPerson = {
      Phones: newContactParams.phone,
      FirstName: newContactParams.firstName,
      LastName: newContactParams.lastName,
      MiddleName: newContactParams.middleName,
      CounterpartyRef: newContactParams.counterRef,
      Ref: newContactParams.ref,
    };
    if (this.mode === 'edit' && this.controlsConfig.delete.enabled) {
      this.contactsService.updateContact(contact).subscribe(
        (updatedContact: ContactPerson) => {
          this.currentContact = { ...this.currentContact, ...updatedContact };
          this.cancel();
          this.onUpdate.next(true);
        },
        (error) => {
          if (error[0]?.message === 'Person already exists!') {
            this.alertToastService.pushError(
              'Дані не оновлено. Контакт вже існує!',
            );
          }
        },
      );
    } else if (!this.controlsConfig.delete.enabled) {
      const fullNameChanged = {
        firstName: newContactParams.firstName,
        lastName: newContactParams.lastName,
        middleName: newContactParams.middleName,
      };
      this.settingsService
        .changeFullName(fullNameChanged, newContactParams.phone)
        .subscribe(
          () => {
            const user = Object.assign({}, this.authService.user);
            user.firstName = fullNameChanged.firstName;
            user.lastName = fullNameChanged.lastName;
            user.middleName = fullNameChanged.middleName;
            this.authService.updateUser(user);
            this.cancel();
            this.onUpdate.next(true);
            this.alertToastService.pushSuccess('Успішно оновлено дані!');
          },
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './app.routes';
import { AuthProcessingComponent } from './components/authorization/auth-processing/auth-processing.component';
import { DocumentSignResultComponent } from './components/authorization/document-sign-result/document-sign-result.component';
import { ProfileComponent } from './components/authorization/profile/profile.component';
import { TokenLoginComponent } from './components/authorization/token-login/token-login.component';
import { AuthGuard } from './guards/auth.guard';
import { CustomPreloadingStrategy } from './preload.strategy';

const routes: Routes = [
  {
    path: AppRoutes.auth,
    loadChildren: () =>
      import('@authorization').then((m) => m.AuthorizationModule),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: 'auth-token/:token',
    component: TokenLoginComponent,
  },
  {
    path: AppRoutes.dashboard,
    loadChildren: () => import('@dashboard').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  {
    path: `${AppRoutes.invoice_edit}/:invoiceId`,
    loadChildren: () =>
      import('@edit-invoice').then((m) => m.EditInvoiceModule),
    canActivate: [AuthGuard],
    title: 'Редагувати відправлення',
    data: { preload: true },
  },
  {
    path: `${AppRoutes.invoice_copy}/:invoiceId`,
    loadChildren: () =>
      import('@copy-invoice').then((m) => m.CopyInvoiceModule),
    canActivate: [AuthGuard],
    title: 'Дублювати відправлення',
    data: { preload: true },
  },
  {
    path: AppRoutes.invoice_create,
    loadChildren: () =>
      import('@invoice-form').then((m) => m.InvoiceFormModule),
    canActivate: [AuthGuard],
    title: 'Створити відправлення',
    data: { preload: true },
  },
  {
    path: AppRoutes.invoice_create_international,
    loadChildren: () =>
      import('@international-invoice').then(
        (m) => m.InternationalInvoiceModule,
      ),
    canActivate: [AuthGuard],
    title: 'Міжнародна накладна',
    data: { preload: true },
  },
  {
    path: `${AppRoutes.invoice_edit_international}/:idInvoice`,
    loadChildren: () =>
      import('@international-invoice').then(
        (m) => m.InternationalInvoiceModule,
      ),
    canActivate: [AuthGuard],
    title: 'Міжнародна накладна',
    data: { preload: true },
  },
  {
    path: `${AppRoutes.internet_order_create}/:orderId`,
    loadChildren: () =>
      import('@invoice-form').then((m) => m.InvoiceFormModule),
    canActivate: [AuthGuard],
    data: { preload: true },
    title: 'Створити інтернет-замовлення',
  },
  {
    path: AppRoutes.invoice_template_create,
    loadChildren: () => import('@template').then((m) => m.TemplateModule),
    canActivate: [AuthGuard],
    title: 'Створити шаблон',
    data: { preload: true },
  },
  {
    path: `${AppRoutes.invoice_template_edit}/:idTemplate`,
    loadChildren: () => import('@template').then((m) => m.TemplateModule),
    canActivate: [AuthGuard],
    title: 'Редагувати шаблон',
    data: { preload: true },
  },
  {
    path: `${AppRoutes.invoice_template_copy}/:idTemplate`,
    loadChildren: () => import('@template').then((m) => m.TemplateModule),
    canActivate: [AuthGuard],
    title: 'Дублювати шаблон',
    data: { preload: true },
  },
  {
    path: 'contractActivation/:activation_code',
    redirectTo: `${AppRoutes.auth}/contractActivation/:activation_code`,
  },
  {
    path: 'document-sign/:result',
    component: DocumentSignResultComponent,
  },
  {
    path: AppRoutes.profile,
    component: ProfileComponent,
    canActivate: [AuthGuard],
    title: 'Профіль',
  },
  {
    path: 'auth-processing',
    component: AuthProcessingComponent,
    title: 'Бізнес-кабінет - Нова пошта',
  },
  {
    path: '',
    loadChildren: () => import('@home').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    data: { preload: true },
    title: 'Бізнес-кабінет - Нова пошта',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
      preloadingStrategy: CustomPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  AutocompleteControls,
  AutoCompleteOptionItem,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { FormContainer } from '@shared';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { DeliveryDescriptionModel } from './delivery-description.model';
import { patternDescription } from './pattern-description.validator';
import { filterInputValue } from '../../helpers/invoices-my.helpers';
import { InvoiceApiService } from '../../../services/invoices/invoice-api.service';
import { errors, MaxLength } from './delivery-description.constants';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | null,
  ): boolean {
    const isSubmitted = form?.submitted;
    return !!(
      control?.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-description',
  templateUrl: './delivery-description.component.html',
  styleUrls: ['./delivery-description.component.scss'],
})
export class DeliveryDescriptionComponent
  extends FormContainer
  implements OnDestroy, OnInit
{
  errors = errors;

  @Input() controlName = 'Description';

  @Input() isTemplate: boolean;

  @Input()
  configDescription: AutocompleteWithControlsConfig = {
    ariaLabel: 'Опис відправлення',
    placeholder: 'Опис відправлення',
    arrow: false,
  };

  @Input()
  controlsConfig: AutocompleteControls = null;

  descriptionQuery: Subject<string> = new Subject<string>();

  descriptionQuery$ = this.descriptionQuery.asObservable();

  options: Array<AutoCompleteOptionItem<DeliveryDescriptionModel>> = [];

  get control(): UntypedFormControl {
    return this.form?.get(this.controlName) as UntypedFormControl;
  }

  get CargoType(): UntypedFormControl {
    return this.form?.get('CargoType') as UntypedFormControl;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private invoiceApiService: InvoiceApiService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions = this.CargoType.valueChanges.subscribe((value) => {
      if (value !== 'Cargo') {
        this.control?.disable();
      } else {
        this.options = [];
        this.control?.enable();
      }
      this.cd.markForCheck();
    });

    this.subscribeToDescriptionQuery();
  }

  setDescriptionQuery(event: Event): void {
    this.descriptionQuery.next((event.target as HTMLInputElement).value);
  }

  subscribeToDescriptionQuery(): void {
    this.subscriptions = this.descriptionQuery$
      .pipe(
        debounceTime(300),
        filter((v) => filterInputValue(v, 3)),
        switchMap((v) => this.searchOptions(v)),
      )
      .subscribe((options) => {
        this.options = options;
        this.cd.detectChanges();
      });
  }

  addFormControls(): void {
    this.form.addControl(
      this.controlName,
      new UntypedFormControl(
        '',
        Validators.compose([
          ...(this.isTemplate ? [] : [Validators.required]),
          Validators.maxLength(MaxLength),
          patternDescription,
        ]),
      ),
    );
  }

  transformFn: AutoCompleteTransformFn<DeliveryDescriptionModel> = (
    value: string | DeliveryDescriptionModel,
  ) =>
    typeof value === 'string' ? value : `${(value && value.Description) || ''}`;

  searchOptions(query: string): Observable<any> {
    return this.invoiceApiService.getCargoDescriptionList(query).pipe(
      take(1),
      debounceTime(3000),
      map((response) => response.data.data),
      catchError(() => of([])),
      map((list) =>
        list.map((el) => ({ option: el } as AutoCompleteOptionItem)),
      ),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (
      this.form.get(this.controlName).value === 'Документи' ||
      this.form.get(this.controlName).value === 'Палети' ||
      this.form.get(this.controlName).value === 'Шини та диски'
    ) {
      this.form.removeControl(this.controlName);
    }
  }
}

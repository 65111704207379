import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[keyInputPrevent]',
})
export class KeyInputPreventDirective {
  @Input()
  keyInputPrevent = false;

  @HostListener('keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent): void {
    if (this.keyInputPrevent) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNumeric } from '@shared';
import { saveAs } from 'file-saver';
import { map, Observable, switchMap } from 'rxjs';
import { AvailableServices } from '../models/available-services.model';
import { IBankItem } from '../models/bank-item.model';
import { IEDRPOUBotResponse } from '../models/company-info.model';
import { IContractSaveResponse } from '../models/contact-save-response.model';
import { FinancialInstitution } from '../models/institution.model';
import { Invoice } from '../models/invoice/invoice.model';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

const VCHASNO_API = 'https://vchasno.ua/api/v2';

export interface TrustedDevice {
  CreationDate: string;
  Description: string;
  LastAuthorization: string;
  Ref: string;
}

@Injectable({
  providedIn: 'root',
})
export class BusinessSettingsService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
    private authService: AuthService,
  ) {
  }

  getCompanyInfo(code: string): Observable<IEDRPOUBotResponse> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'searchOrganizationByEDRPOU',
      methodProperties: {
        OrganizationType: code.length === 8 ? 'Company' : 'PrivatePerson',
        EDRPOU: code,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0] as IEDRPOUBotResponse),
      );
  }

  uploadFile(file, fileName, extension, ref): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContractGeneral',
      calledMethod: 'uploadDocument',
      methodProperties: {
        ContractRef: ref,
        FileExtention: extension,
        DocumentType: fileName,
        FileSource: file,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0]),
      );
  }

  getRelevantApplications(page = 1, limit: string | number = 5, ref = ''): Observable<any> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContractGeneral',
      calledMethod: 'getContractsList',
      methodProperties: {
        Ref: ref,
        Limit: limit,
        Page: page,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data),
      );
  }

  saveContractData(companyInfo, bankAccountInfo, signedWithECP, files?, mode = 'save', ref?): Observable<IContractSaveResponse> {
    const {
      companyLegalAdress,
      companyCode,
      companyFullName,
      companyShortName,
      companyVATCode,
      companyOwnershipFormCode,
      companyVATStatus,
    } = companyInfo;
    const {
      paymentAccount,
      sortCode,
      bankName,
      accountantPhone,
      email,
      workflowEmail,
      workflowProvider,
    } = bankAccountInfo;
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContractGeneral',
      calledMethod: mode,
      methodProperties: {
        Ref: ref || '',
        FullName: companyFullName,
        Organization: companyShortName,
        [companyCode.length === 8 ? 'EDRPOU' : 'IPN']: companyCode,
        OwnershipFormCode: companyOwnershipFormCode,
        MoneyAccount: paymentAccount,
        Email: email,
        MFO: sortCode,
        AccountantPhone: `+38${accountantPhone}`,
        CustomerPhone: this.authService.user.phone || `+38${accountantPhone}`,
        PhysicalAddress: companyLegalAdress,
        BankName: bankName,
        LegalAddress: companyLegalAdress,
        TaxIDNumber: companyVATCode,
        TaxSystem: companyVATStatus,
        WorkflowSendAct: '',
        WorkflowEmail: workflowEmail,
        WorkflowProvide: workflowProvider,
        Documents: files ? files : [],
        ...(mode === 'update' ? { SignedWithECP: Number(signedWithECP) } : {}),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0] as IContractSaveResponse),
      );
  }

  deleteApplication(ref: string) {
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContractGeneral',
      calledMethod: 'delete',
      methodProperties: {
        Ref: ref,
        Limit: '',
        Page: 0,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0]),
      );
  }

  getOwnershipFormsList(): Observable<any> {
    const body = {
      modelName: 'ContractGeneral',
      calledMethod: 'getOwnershipFormsList',
      methodProperties: {
        Code: '',
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  getAvailableServices(ref): Observable<AvailableServices> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterpartyOptions',
      methodProperties: {
        Ref: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0] as AvailableServices),
      );
  }

  getCardList(counterpartyRef: string = '', page: number = 0, limit: number = 50): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUserGeneral',
      calledMethod: 'getStandartCardsList',
      methodProperties: {
        CounterpartyRef: counterpartyRef,
        Page: page,
        Limit: limit,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => ({
          list: response.data && response.data.data,
          total: response.data && response.data.info && response.data.info.totalCount,
        })),
      );
  }

  cardBlocks(card, status, ref): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUserGeneral',
      calledMethod: 'blockStandartCardData',
      methodProperties: {
        LoyaltyCardNumber: card,
        EnableCard: status,
        CounterpartyRef: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  editContact(card, contact, ref): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUserGeneral',
      calledMethod: 'editStandartCardData',
      methodProperties: {
        LoyaltyCardNumber: card,
        ...contact,
        CounterpartyRef: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  getCounterpartyCustomerReturn(ref: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'getCounterpartyCustomerReturn',
      methodProperties: {
        Ref: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data[0]),
      );
  }

  enableAutoReturn(periodOfStorageCargo: number, counterpartyRef: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'EnableAutoReturn',
      methodProperties: {
        PeriodOfStorageCargo: periodOfStorageCargo.toString(),
        Counterparty: counterpartyRef,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  manageTermExtension(periodOfStorageCargo: number, orderRecipient) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'manageTermExtension',
      methodProperties: {
        Days: periodOfStorageCargo.toString(),
        OrderRecipient: orderRecipient,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  orderChangeCustomerReturn(invoice: Invoice, params: {
    PaymentMethod,
    PayerType,
    _PeriodOfStorageCargo,
    StorageFinalDate
  }) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'save',
      methodProperties: {
        OrderType: 'orderTermExtension',
        IntDocNumber: invoice.docNumber,
        ...params,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  checkPossibilityTermExtension(invoice: Invoice) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AdditionalService',
      calledMethod: 'CheckPossibilityTermExtension',
      methodProperties: {
        IntDocNumber: invoice.docNumber,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  getAttorneysList(counterpartyRef: string, page: number = 0, limit: number = 10): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CounterpartyGeneral',
      calledMethod: 'getAttorneysList',
      methodProperties: {
        CounterpartyRef: counterpartyRef,
        Page: page,
        Limit: limit,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data),
      );
  }

  getReturnAdresses(counterpartyRef: string, cargoType: string,
    ownerDocumentType?): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CounterpartyGeneral',
      calledMethod: 'getCustomBackwardDeliveryAddressesList',
      methodProperties: {
        OwnerDocumentType: ownerDocumentType || 'Redelivery',
        [!!ownerDocumentType ? 'CargoType' : 'BackwardDeliveryCargoType']: cargoType,
        CounterpartyRef: counterpartyRef,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  createAttorney(
    attorneyData: {
      Phone,
      FirstName,
      MiddleName,
      LastName,
      DocumentType,
      DocumentSeries,
      DocumentNumber,
      DocumentDateOfIssue,
      StartDate,
      EndDate,
      Counterparty,
    }): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Counterparty',
      calledMethod: 'createAttorney',
      methodProperties: attorneyData,
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  blockAttorney(attorneyCode: string, counterpartyRef: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CounterpartyGeneral',
      calledMethod: 'blockAttorney',
      methodProperties: {
        AttorneyCode: attorneyCode,
        CounterpartyRef: counterpartyRef,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  getTrustedDevicesList(page: number, limit: number): Observable<{ list: TrustedDevice[], total: number }> {

    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'getTrustedDevicesList',
      methodProperties: {
        Page: page + 1,
        Limit: limit,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => ({
          list: response.data && response.data.data,
          total: response.data && response.data.info && response.data.info.totalCount,
        })),
      );
  }

  deleteTrustedDevice(ref: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'deleteTrustedDevice',
      methodProperties: {
        Ref: ref,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  sendReportEmail(type: string, counterpartyRef: string, email: string, dateFrom: Date, dateTo: Date) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CounterpartyGeneral',
      calledMethod: 'sendReportEmail',
      methodProperties: {
        CounterpartyRef: counterpartyRef,
        DateFrom: this.noRestApiHelper.getFormatedDateForRequest(dateFrom),
        DateTo: this.noRestApiHelper.getFormatedDateForRequest(dateTo),
        Email: email,
        ReportType: type,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  sendStatisticsReportEmail(type: string, counterpartyRefs: string[], email: string, dateFrom: Date, dateTo: Date, filters?: string[]) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CounterpartyGeneral',
      calledMethod: 'sendReportEmail',
      methodProperties: {
        CounterpartyRef: counterpartyRefs,
        DateFrom: this.noRestApiHelper.getFormatedDateForRequest(dateFrom),
        DateTo: this.noRestApiHelper.getFormatedDateForRequest(dateTo),
        Email: email,
        ReportType: type,
        Filtration: filters,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data.data),
      );
  }

  saveFile(url: string) {
    this.http.get(url, { responseType: 'blob' })
      .subscribe(data => saveAs(new Blob([data as any],
        { type: (data as any).type }), url.slice(url.lastIndexOf('/'))));
  }

  getBanksList(query: string | number): Observable<IBankItem[]> {
    const numeric = isNumeric(query);
    const body = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'getBanksList',
      methodProperties: {
        [numeric ? 'MFO' : 'BankName']: query,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(resp => resp.data.data),
      );
  }

  getBankListByCounterparty(ref: string): Observable<FinancialInstitution[]> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CommonGeneral',
      calledMethod: 'getBankList',
      methodProperties: {
        Counterparty: ref,
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(resp => resp.data.data),
      );
  }

  uploadContractToVCHASNO(url) {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        switchMap(data => this.http.post(
          `${VCHASNO_API}/documents`,
          new FormData()
            .append('file',
              new Blob([data as any], { type: (data as any).type })),
          {
            headers: new HttpHeaders()
              .append('Content-Type', 'multipart/form-data'),
          })),
        map(data => this.noRestApiHelper.checkErrors({ data })),
        map(response => response.data),
      );
  }

  addUserCard(data: any): Observable<any> {
    const body = {
      system: 'PA 3.0',
      modelName: 'LoyaltyUser',
      calledMethod: 'registerCardStandart',
      methodProperties: {
        BirthDate: data.birthDate,
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        MiddleName: data.middleName,
        Phone: data.phone,
        ...(data.counterparty ? { CounterpartyRef: data.counterparty } : {}),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map(d => this.noRestApiHelper.checkErrors({ d })),
        map(resp => resp.data.data),
      );
  }

}

import { Invoice } from './invoice.model';

export class InvoiceRegister extends Invoice {
  number: number;
  printed: string;
  dateTime: Date;
  description: string;
  marketplacePartnerDescription: string;
  count: number;
  docNumber: string;
  citySender: string;
  citySenderRef: string;
  senderAddress: any;
  senderAddressRef: any;
  weight: number;
  sender: string;
  senderRef: string;
  ref: string;
  totalCost: number;
  totalRedeliverySum: number;
  totalAfterpaymentOnGoodsCost: number;
  TotalCheckCost: string | any;
  TotalCheckCostSender: string | number;
  TotalCheckWeight: string | number;
  TotalCost: string | number;
  TotalCostOnSite: string | number;
  TotalCostOnSiteSender: string | number;
  TotalRedeliverySum: string | number;
  TotalWeight: string | number;
  Count: string | number;
  Sender: string | number;
  SenderAddress: string | number;
  TotalAfterpaymentOnGoodsCost: string | number;
}

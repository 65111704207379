import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IApiError } from '@models';
import { AlertToastService } from '@services';
import { getError, getStringLength, trimMaxLength, trimSpaces, Unsubscriber } from '@shared';
import { BehaviorSubject, distinctUntilChanged, filter, finalize, merge, take } from 'rxjs';
import { InvoiceRegister } from '../../../../models/invoice/invoice-register.model';
import { InvoiceRegisterApiService } from '../../../../services/registers/invoice-register.api.service';

const descriptionErrors = {
  required: "Це поле обов'язкове",
  maxlength: 'Максимальна довжина 30 символів',
  pattern: 'Містить недопустимий символ',
};

@Component({
  selector: 'app-register-rename',
  templateUrl: './register-rename.component.html',
  styleUrls: ['./register-rename.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterRenameComponent extends Unsubscriber implements OnInit {
  protected readonly descriptionErrors = descriptionErrors;
  protected readonly getError = getError;
  readonly pattern = /^[а-яА-Я,ёЁіІїЇєЄґҐюЮa-zA-Z\d\s\.№;!:/\-'\*\+#`',\/\(\)"«»\\\\_&„""]*$/ui;
  
  maxLength = 30;

  maxInputLength = 50;

  constructor(
    private invoiceRegisterService: InvoiceRegisterApiService,
    private alertToastService: AlertToastService,
    @Inject(MAT_DIALOG_DATA) private data: InvoiceRegister,
    private dialogRef: MatDialogRef<RegisterRenameComponent>,
    private fb: FormBuilder,
  ) {
    super();
  }

  form: FormGroup | null = null;

  private _loading = new BehaviorSubject(false);
  loading$ = this._loading.asObservable();

  ngOnInit(): void {
    this.initForm();
    this.initCheckMaxStringLengthSubscription();
    this.subscribeToClose();
  }

  get registerNumber(): string {
    return this.data.docNumber ?? '';
  }

  private initForm(): void {
    this.form = this.fb.group({
      description: [this.data.description, [Validators.maxLength(30), Validators.required, Validators.pattern(this.pattern)]],
    });
  }

  get description(): AbstractControl {
    return this.form?.get('description') as AbstractControl;
  }

  close(success?: boolean, description?: string): void {
    this.dialogRef.close({ success, description });
  }

  onBlur($event: FocusEvent): void {
    let value = ($event.target as HTMLInputElement).value;
    this.checkMaxStringLength(value, true);
  }

  private initCheckMaxStringLengthSubscription(): void {
    this.subscriptions = this.description?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string) => this.checkMaxStringLength(value));
  }

  private checkMaxStringLength(value: string, shouldTrimSpaces: boolean = false): void {
    if (!this.description) {
      return;
    }

    const cleanValue = shouldTrimSpaces ? trimSpaces(value) : value;
    if (getStringLength(trimSpaces(value)) > this.maxInputLength) {
      this.description?.setValue(trimMaxLength(trimSpaces(value), this.maxInputLength));
    } else {
      this.description?.setValue(cleanValue);
    }
  }

  private subscribeToClose(): void {
    const backdrop = this.dialogRef.backdropClick();
    const keyEvents = this.dialogRef.keydownEvents().pipe(filter((e) => e.key === 'Escape'));
    this.subscriptions = merge(backdrop, keyEvents).pipe(filter(() => !this._loading.value), take(1)).subscribe(() => this.close());
  }

  clearField(): void {
    this.description?.setValue('');
  }

  confirm(): void {
    if (this.form.invalid) {
      this.description.markAsTouched();
      return;
    }

    if (this.description.value === this.data.description) {
      this.close();
      return;
    }

    this._loading.next(true);

    const renamedRegister = {
      ...this.data,
      description: this.description.value,
    } as InvoiceRegister;

    this.subscriptions = this.invoiceRegisterService.update(renamedRegister)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe(
        () => {
          this.close(true, this.description.value);
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
        },
      );
  }
}

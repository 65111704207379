import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {AppSortHeaderDirective} from './app-sort-header.directive';
import {CustomPaginator} from './custom-paginator';

@NgModule({
  declarations: [
    AppSortHeaderDirective,
  ],
  exports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    AppSortHeaderDirective,
  ],
})

export class AppMatTableModule {
  static forRoot(): ModuleWithProviders<AppMatTableModule> {
    return {
      ngModule: AppMatTableModule,
      providers: [
        {
          provide: MatPaginatorIntl,
          useClass: CustomPaginator,
        },
      ],
    };
  }
}

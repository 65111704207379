import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractFormContainer} from '@shared';
import {of} from 'rxjs';
import {catchError, take} from 'rxjs';
import {BusinessSettingsService} from '../../../../../services/business-settings.service';
import {documentsList} from './documents-list.model';

@Component({
  selector: 'app-inv-different-documents',
  templateUrl: './different-documents.component.html',
  styleUrls: ['./different-documents.component.scss'],
})
export class DifferentDocumentsComponent extends AbstractFormContainer implements OnInit, OnDestroy, OnChanges {
  @Input() controlName = 'BackwardDeliveryData';
  @Input() isCorporate = false;
  @Input() templateEditMode;

  returnAddressControlName = 'CustomBackwardDeliveryParameterDifferentDocuments';

  backwardDeliveryDataGroup: UntypedFormGroup;

  differentDocumentsGroup: UntypedFormGroup;

  documents = JSON.parse(JSON.stringify(documentsList));

  documentsFormArray: UntypedFormArray;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private settingService: BusinessSettingsService,
  ) {
    super();
  }

  _customAddresses: any[] = null;
  _ref = null;

  @Input() set ref(ref: string) {
    this._ref = ref;
  }

  get ref() {
    return this._ref;
  }

  get customAddresses() {
    return this._customAddresses;
  }

  set customAddresses(addresses) {
    if (!addresses && this.isCorporate) {
      this.updateRef(null);
    }
    this._customAddresses = addresses;
  };

  get stepAddressControl(): AbstractControl {
    return this.form.parent.get('stepAddressForm');
  }

  get AdditionalServices(): AbstractControl {
    return this.stepAddressControl.get('AdditionalServices');
  }

  getReturnAdresses(ref: string, cargoType: string) {
    this.settingService.getReturnAdresses(ref, cargoType)
      .pipe(take(1),
        catchError(e => of(e)))
      .subscribe(data => {
        this.customAddresses = data.length > 0 ?
          data.map(el => ({
            label: el.Address,
            value: el.Ref,
          })) : null;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isCorporate && !this.templateEditMode) {
      this.getReturnAdresses(this.ref, 'Documents');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.ref) {
      this.getReturnAdresses(this.ref, 'Documents');
    }
  }

  updateRef(ref: string) {
    this.backwardDeliveryDataGroup.get('CustomBackwardDeliveryParameterDifferentDocuments').setValue(ref);
  }

  addFormControls() {
    this.differentDocumentsGroup = (this.form
      .get('AdditionalServices')
      .get('differentDocuments') as UntypedFormGroup);

    this.backwardDeliveryDataGroup = this.formBuilder.group({
      PayerType: ['Recipient', [
        Validators.required,
      ]],
      CargoType: ['Documents', [
        Validators.required,
      ]],
      UserActions: ['UserDontGiveDoc', [
        Validators.required,
      ]],
      CustomBackwardDeliveryParameterDifferentDocuments: [''],
      documents: this.formBuilder.array([this.createDocument(this.documents[0])]),
    });
    this.differentDocumentsGroup.addControl(this.controlName, this.backwardDeliveryDataGroup);

    this.documentsFormArray = this.backwardDeliveryDataGroup.get('documents') as UntypedFormArray;
  }

  createDocument(document: any) {
    return this.formBuilder.group({
      name: document.name,
      services: document.services,
    });
  }

  addDocument() {
    this.documentsFormArray.push(this.createDocument(this.documents[0]));
  }

  removeDocument(i: number) {
    this.documentsFormArray.removeAt(i);
  }

  changeDoc(doc, index: number) {
    this.documentsFormArray.at(index).patchValue({
      name: doc.name,
      services: doc.services,
    });
  }

  findDoc(name) {
    return this.documents.find(el => el.name === name) || this.documents[0];
  }

  ngOnDestroy() {
    this.differentDocumentsGroup.removeControl(this.controlName);
  }

}

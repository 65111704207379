<button (menuClosed)="resetValue(config.fromFilter.controlName); resetValue(config.toFilter.controlName)"
        [attr.data-test-id]="'hot-'+'CreationDateTime'+'-trigger'"
        [class.active]="(controlFrom?.value || controlTo?.value)"
        [matMenuTriggerFor]="input"
        class="chip chip_rect" mat-ripple>{{config.hotFilter.overlayTitle}}
</button>

<mat-menu #input="matMenu"
          [formGroup]="form"
          class="hot-filter-list-menu hot-filter-list-menu_date">
  <div (click)="stopPropagation($event)" class="menu-content">
    <app-double-date-filter (click)="stopPropagation($event)"
                            [config]="config"
                            [form]="form"
                            [sm]="true"
                            class="date-filter"></app-double-date-filter>

    <button (click)="applyFilterForm()"
            [attr.data-test-id]="'hot-'+config.fromFilter.controlName+'-'+config.toFilter.controlName+'-apply'"
            [disabled]="controlTo?.invalid || (notChanged(config.fromFilter.controlName) && notChanged(config.toFilter.controlName))"
            class="mt-8"
            color="primary"
            mat-raised-button>{{config.hotFilter.overlayButtonText}}
    </button>
  </div>
</mat-menu>

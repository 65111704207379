<div class="form-row" [formGroup]="form" *ngFor="let seat of seats; let i = index">
  <div class="form-col form-col-1 form-col-1_with_symbol">
    <np-inv-delivery-pallet-seats [(seats)]="seat.amount"
                                  [isTemplate]="isTemplate"
                                  [invoiceId]="invoiceId"
                                  [form]="form"
                                  (seatsChange)="changeWatcher()"></np-inv-delivery-pallet-seats>
  </div>
  <div class="form-col form-col-3 box-with-controls">

    <mat-form-field>
      <mat-select [placeholder]="configDescription.placeholder"
                  [value]="seat.option"
                  (selectionChange)="optionChange($event.value, seat)"
                  [compareWith]="comparePallets">
        <mat-option *ngFor="let option of options; let i = index" [value]="option">
          до {{option.volumetricWidth}} х {{option.volumetricLength}} х {{option.volumetricHeight}},
          до {{option.weight}} кг <span *ngIf="i === 1">(EUR)</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="control-icons" *ngIf="seats.length > 1">
      <div class="control-icons__item">
        <button mat-icon-button (click)="removeOptionsSeat(i)">
          <mat-icon class="material-icons_grey"
                    [matTooltip]="'Видалити'"
                    matTooltipPosition="below">
            close
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="form-row form-row_button">
  <div class="form-col form-col-3">
    <button type="button"
            class="mat-button_with-icon"
            mat-button color="primary"
            (click)="addOptionsSeat()">
      <mat-icon>
        add_circle
      </mat-icon>
      Додати палети
    </button>
  </div>
</div>

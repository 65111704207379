import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '../../../../../models/invoice/invoice.model';

@Component({
  selector: 'app-invoice-detailed-info',
  templateUrl: './invoice-detailed-info.component.html',
  styleUrls: ['./invoice-detailed-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailedInfoComponent {
  @Input() invoice: Invoice;

  public payerNameTransations = {
    Sender: 'Відправник',
    Recipient: 'Отримувач',
    ThirdPerson: 'Третя особа',
  };
}

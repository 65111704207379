<form class="np-back-invoice-form" [formGroup]="form">
  <div class="modal modal_large">
    <span class="modal__close" matDialogClose>
      <mat-icon class="material-icons">close</mat-icon>
    </span>

    <div class="modal-header">
      <div class="form-row">
        <div class="form-col">
          <h3 class="modal-header__title np-invoices-show__success">
            {{ formLabel }}
          </h3>
        </div>
      </div>
    </div>
    <mat-progress-bar [style.opacity]="+loading" mode="indeterminate"></mat-progress-bar>

    <main #modalContent class="modal-content">
      <div class="form-row">
        <div class="form-col form-col-8">
          <div class="banner">
            <div class="banner__info">
              <div class="warning-message">
                <div class="warning-message__text">
                  Послуга передбачає повернення раніше відправленого відправлення.
                  Вартість послуги розраховується
                  згідно з
                  <a class="link-red"
                     target="_blank"
                     href="https://novaposhta.ua/uploads/misc/doc/tarify.pdf">
                    чинними тарифами
                  </a>
                  компанії «Нова Пошта»
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="np-invoices-show__success">
        <section class="form-row mr-bottom-16">
          <section class="form-col form-col-8">
            <stf-accordeon>
              <stf-accordeon-item (openChange)="onOpenCloseInvoiceInfo($event)" [showExpandable]="isCreateMode">
                <div header>
                  <app-invoice-short-info [isIn]="type === 'in'"
                                          [isOut]="type === 'out'"
                                          [invoice]="invoice">
                  </app-invoice-short-info>
                </div>
                <div body>
                  <app-invoice-detailed-info [invoice]="invoice"></app-invoice-detailed-info>
                </div>
              </stf-accordeon-item>
            </stf-accordeon>
          </section>
        </section>

        <app-address-block data-test-id="RecipientAddress"
                           *ngIf="addresses$ | async"
                           [controlName]="ControlName.recipientAddress"
                           [form]="form"
                           [options]="addresses$ | async"
        ></app-address-block>

        <div class="form-row" *ngIf="!recipientCounterpartyHidden">
          <div class="form-col form-col-800">
            <mat-form-field>
              <mat-select placeholder="Організація отримувача"
                          [formControlName]="ControlName.recipientCounterparty"
                          [disabled]="true">
                <mat-option *ngFor="let address of (addresses$ | async)"
                            [value]="address.option.Counterparty">
                  {{address.option.Counterparty}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col form-col-800">
            <mat-form-field>
              <mat-select placeholder="Контакт отримувача"
                          [formControlName]="ControlName.recipientContact"
                          [disabled]="true">
                <mat-option *ngFor="let address of (addresses$ | async)"
                            [value]="address.option.ContactPerson">
                  {{address.option.ContactPerson}} {{address.option.Phone | npFinePhone}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col form-col-3">
            <div class="label-radio label-radio">Спосіб оплати</div>
            <mat-radio-group [formControlName]="ControlName.PaymentMethod">
              <mat-radio-button *ngFor="let type of types"
                                [value]="type.value">{{type.label}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div *ngIf="isCreateMode" class="form-row mr-bottom-16 mr-top-16">
          <div class="form-col form-col-800">
            <mat-form-field class="form-input">
              <mat-select placeholder="Причина повернення"
                          (change)="onChangeSubReason()"
                          [formControlName]="ControlName.SubtypeReason">
                <mat-option [value]="reason"
                            *ngFor="let reason of returnSubReasons; trackBy: trackBy">
                  {{reason.title}}
                </mat-option>
              </mat-select>
              <mat-error>Це поле обов'язкове</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="canCreate" class="form-row mr-bottom-16 mr-top-16">
          <div class="form-col form-col-4">
            <div class="info-cell__label">Вартість повернення</div>
            <div
              class="info-cell__content-calculated">{{ Pricing >= 0 ? Pricing + " грн" : "—" }}</div>
          </div>
          <div class="form-col form-col-4">
            <div class="info-cell__label">Орієнтовна дата доставки</div>
            <div
              class="info-cell__content-calculated">{{ (ScheduledDeliveryDate | date:"dd.MM.yyyy HH:mm") || "—" }}</div>
          </div>
        </div>
      </div>
    </main>

    <div class="modal-footer modal-footer_pad-8">
      <div class="modal-footer-buttons">
        <div class="modal-footer-buttons">
          <button
            *ngIf="!isCreateMode"
            (click)="cancel($event)"
            [disabled]="submitDisabled"
            class="mat-button modal-footer__button np-submit-redirect-invoice"
            mat-button
          >
            Відмінити повернення
          </button>

          <button
            (click)="canCreate ? submit($event) : calculate($event)"
            [disabled]="submitDisabled"
            class="mat-button modal-footer__button np-submit-redirect-invoice mat-primary"
            color="primary"
            mat-button
          >
            {{ canCreate ? (isCreateMode ? 'Створити заявку' : 'Оновити заявку') : 'Розрахувати'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div>
  <mat-progress-bar [style.opacity]="!showProfile ? 1 : 0"
                    mode="indeterminate">
  </mat-progress-bar>
  <div class="side-nav__top">
    <button type="button" class="mat-button_with-icon" mat-button
            (click)="$event.stopPropagation(); toDashboard()">
      <mat-icon>
        arrow_back
      </mat-icon>
      Назад до кабінету
    </button>
  </div>
  <div class="frame-container">
    <iframe *ngIf="showProfile" #frameElement [src]="getIframeUrl()">
    </iframe>
  </div>
</div>


<div class="label-radio" *ngIf="showLabel">Нестандартна адреса зворотньої доставки</div>

<div [formGroup]="formGroup">
  <mat-form-field ngDefaultControl>
    <mat-select placeholder="Адреса"
                panelClass="select-with-carry"
                [formControlName]="controlName"
                (selectionChange)="updateValue($event)">
      <mat-option [value]="null">Залишити адресу за замовчуванням</mat-option>
      <mat-option *ngFor="let option of options; let i = index"
                  [value]="option.value">
        {{option.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="invoice-full">

  <div *ngIf="invoice?.sender?.lastName" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Відправник</div>
    <div class="info-cell__content">{{invoice?.sender?.lastName}} {{invoice?.sender?.firstName}}
      {{invoice?.sender?.middleName}}
    </div>
  </div>
  <div *ngIf="invoice?.sender?.phone" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Телефон відправника</div>
    <div class="info-cell__content nowrap-text">{{invoice?.sender?.phone | npFinePhone}}</div>
  </div>
  <div *ngIf="invoice?.senderAddress?.description"
       class="info-cell invoice-full__info-cell invoice-full__info-cell_col-3">
    <div class="info-cell__label">Адреса відправника</div>
    <div class="info-cell__content">{{invoice?.senderAddress?.description}}</div>
  </div>
  <div *ngIf="invoice?.sender?.lastName" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Отримувач</div>
    <div class="info-cell__content">{{invoice?.recipient?.lastName}} {{invoice?.recipient?.firstName}}
      {{invoice?.recipient?.middleName}}
    </div>
  </div>

  <div *ngIf="invoice?.recipient?.phone" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Телефон отримувача</div>
    <div class="info-cell__content">{{invoice?.recipient?.phone | npFinePhone}}</div>
  </div>
  <div *ngIf="invoice?.recipientAddress?.description"
       class="info-cell invoice-full__info-cell invoice-full__info-cell_col-3">
    <div class="info-cell__label">Адреса отримувача</div>
    <div class="info-cell__content nowrap-text">{{invoice?.recipientAddress?.description}}</div>
  </div>
  <div *ngIf="invoice?.announcedPrice" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Оголошена вартість</div>
    <div class="info-cell__content">{{invoice?.announcedPrice}} грн</div>
  </div>
  <div *ngIf="invoice?.senderElevator" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Підйом на етаж</div>
    <div class="info-cell__content">Так</div>
  </div>
  <div *ngIf="invoice?.cargoType?.description" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Тип</div>
    <div class="info-cell__content">{{invoice?.cargoType?.description}}</div>
  </div>
  <div *ngIf="invoice?.dateTime" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Дата створення</div>
    <div class="info-cell__content">{{invoice?.dateTime | np_to_date_format | date :"dd.MM.yyyy"}}</div>
  </div>
  <div *ngIf="invoice?.deliveryPrice" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Вартість доставки</div>
    <div class="info-cell__content">{{invoice?.deliveryPrice}} грн</div>
  </div>
  <div *ngIf="invoice?.weight" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Вага</div>
    <div class="info-cell__content">{{invoice?.weight}} кг</div>
  </div>
  <div *ngIf="invoice?.weight" class="info-cell invoice-full__info-cell">
    <div class="info-cell__label">Платник</div>
    <div class="info-cell__content">{{payerNameTransations[invoice?.PayerType]}}</div>
  </div>
  <div *ngIf="invoice?.description" class="info-cell invoice-full__info-cell_col-3">
    <div class="info-cell__label">Опис</div>
    <div class="info-cell__content">{{invoice?.description}}</div>
  </div>
</div>

<div class="np-fancybox np-fancybox_pay2card process-modal">
  <div class="side-nav__top">
    <button (click)="$event.stopPropagation(); dialogRef.close()" class="mat-button_with-icon" mat-button
            type="button">
      <mat-icon>
        arrow_back
      </mat-icon>
      Закрити
    </button>
  </div>
  <iframe [src]="iframeUrl" allowpaymentrequest id="TSframe" style="width: 100%;  height: 92%; border: 0; outline: 0">
  </iframe>
</div>

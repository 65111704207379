import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AbstractFormContainer, AddressSettings } from '@shared';
import { AlertToastService } from '@services';
import { forkJoin, merge, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs';
import { IdService } from '../../../services/id.service';
import { Package } from './package.model';
import { PackingService } from './packing-service.service';
import { AvailableServiceNames } from '../availableServices';
import { ApiMethods } from '../../../../../services/api/apiMethods';

@Component({
  selector: 'app-packing-service',
  templateUrl: './packing-service.component.html',
  styleUrls: ['./packing-service.component.scss'],
})
export class PackingServiceComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input() controlName = 'PackingService';

  @Input() senderAddressRef: string;

  packingServiceGroup: UntypedFormGroup;

  packages: any = [];

  placesList = [];

  get cargoType(): string {
    return this.stepDeliveryControl.get('CargoType').value;
  }

  private requestSubscription: Subscription;
  private stepDeliveryControlSubscription: Subscription;
  private addressSenderControlSubscription: Subscription;
  private cargoTypeSubscription: Subscription;
  private addressRecipientControlSubscription: Subscription;
  private paramsSeatsSubscription: Subscription;
  private stepperSelectionEventSubs: Subscription;

  get stepDeliveryControl(): AbstractControl {
    return this.form.parent.get('stepDeliveryForm');
  }

  get addressSenderControl(): AbstractControl {
    return this.form.get('AddressSender');
  }

  get addressRecipientControl(): AbstractControl {
    return this.form.get('AddressRecipient');
  }

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected packingService: PackingService,
    protected cd: ChangeDetectorRef,
    protected idService: IdService,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const method = ApiMethods.getPackListSpecial;
    this.requestSubscription = merge(
      of(this.form.value),
      this.form.valueChanges,
    )
      .pipe(
        filter((value) => value.hasOwnProperty('AddressSender')),
        debounceTime(50),
        distinctUntilChanged(
          (a, b) => a.AddressSender.data.Ref === b.AddressSender.data.Ref,
        ),
        filter(
          (data) =>
            !this.senderAddressRef ||
            data.AddressSender.data.Ref === this.senderAddressRef,
        ),
        switchMap(() => this.setPlacesList()),
      )
      .subscribe(() =>
        this.getPackList(this.placesList, method, this.cargoType),
      );
    this.stepperSelectionEventSubs =
      this.idService.stepperSelectionEvent$.subscribe((data) => {
        if (data?.selectedIndex === 1) {
          this.setPlacesList().subscribe(() =>
            this.getPackList(this.placesList, method, this.cargoType),
          );
        }
      });
    if (this.addressSenderControl) {
      this.addressSenderControlSubscription =
        this.addressSenderControl?.valueChanges
          // Fixes the issue when packing api request is triggered in cycle
          // because of lines 233-235 in
          // src/app/components/invoice-form/components/sender/components/sender-address/sender-address.component.ts
          .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
          .subscribe((value) => {
              this.getPackList(this.placesList, method, this.cargoType);
              if (value.type === 'Doors') {
                this.form
                  .get('AdditionalServices.packingService.selected')
                  .setValue(false);
              }
        });
    }

    if (this.addressRecipientControl) {
      this.addressRecipientControlSubscription =
        this.addressRecipientControl.valueChanges.subscribe((value) => {
          this.getPackList(this.placesList, method, this.cargoType);
          if (value.type === 'Doors') {
            this.form
              .get('AdditionalServices.packingService.selected')
              .setValue(false);
          }
        });
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
    this.packingServiceGroup.valueChanges.subscribe((data) => {
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });
  }

  addFormControls() {
    this.packingServiceGroup = this.form
      .get('AdditionalServices')
      .get(AvailableServiceNames.packingService) as UntypedFormGroup;
    this.packingServiceGroup.addControl(
      this.controlName,
      new UntypedFormControl(
        this.placesList,
        Validators.compose([Validators.required]),
      ),
    );
  }

  getPackList(placesList, method, cargoType?) {
    const observables = forkJoin([
      ...placesList.map((place) => {
        return this.packingService.getPackListSpecial(method, {
          volumetricWeight: place['volumetricVolume'],
          cargoType,
          recipientWarehouseRef:
            this.addressRecipientControl?.value?.data?.Ref ||
            (this.addressRecipientControl?.value?.data?.warehouse
              ?.TypeOfWarehouse !== AddressSettings.POSTBOX_TYPE &&
              this.addressRecipientControl?.value?.data?.warehouse?.Ref),
          senderWarehouseRef:
            this.addressSenderControl?.value?.data?.Ref ||
            (this.addressSenderControl?.value?.data?.warehouse
              ?.TypeOfWarehouse !== AddressSettings.POSTBOX_TYPE &&
              this.addressSenderControl?.value?.data?.warehouse?.Ref),
        });
      }),
    ]);
    observables.subscribe(
      (data: any) => {
        data = data.map(
          (packages: { Filtered: Package[]; Common: Package[] }) => {
            return [
              ...(packages.Filtered ? packages.Filtered : []),
              ...(packages.Common ? packages.Common : []),
            ].sort((a, b) => a.Description.localeCompare(b.Description));
          },
        );
        this.packages = data;
        const places = [];
        for (let i = 0; i < this.placesList.length; i++) {
          const place = this.placesList[i];
          let existFlag = false;
          for (const deliveryPackage of this.packages[i]) {
            if (place['packRef'] === deliveryPackage.Ref) {
              existFlag = true;
              break;
            }
          }
          if (!existFlag) {
            place['packRef'] = '';
          }
          places.push(place);
        }
        this.placesList = places;
        this.packingServiceGroup
          .get(this.controlName)
          ?.setValue(this.placesList);
        this.stepDeliveryControl.get('OptionsSeat')?.setValue(this.placesList);
        if (!this.cd['destroyed']) {
          this.cd.markForCheck();
        }
      },
      (errors) => {
        if (errors[0]) {
          if (
            errors[0].message === 'Warehouse Type does not have pack' ||
            errors[0].addInfo === 'Warehouse Type does not have pack'
          ) {
            this.alertToastService.pushError(
              'Для обраного типу відділень можливість замовлення пакування відсутня!',
            );
          } else {
            this.alertToastService.pushError(
              'Обраний тип пакування відсутній для заданих параметрів відправлення!',
            );
          }
          this.packages = [];
          this.packingServiceGroup.patchValue({
            selected: false,
          });
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
        }
      },
    );
  }

  setPlacesList() {
    if (
      this.stepDeliveryControl.get(['OptionsSeat']) &&
      this.stepDeliveryControl.get(['OptionsSeat']).value
    ) {
      this.placesList = [];
      (
        this.stepDeliveryControl.get(['OptionsSeat']) as UntypedFormArray
      ).controls.forEach((control) => this.placesList.push(control.value));
    } else {
      const stepDeliveryValue = this.stepDeliveryControl.value;
      const length = Number(stepDeliveryValue.SeatsAmount);
      if (length > 1 && this.cargoType !== 'Documents') {
        return;
      }
      if (!stepDeliveryValue.commonParams) {
        this.placesList.length = Number(
          this.stepDeliveryControl.get('SeatsAmount').value,
        );
        this.placesList = Array.from(this.placesList, (v) =>
          this.cargoType !== 'Documents'
            ? v || {}
            : { packRef: v?.packRef || '' },
        );
      } else {
        const seatNoVolume = {
          volumetricVolume: stepDeliveryValue.commonParams.VolumeWeight,
          packRef:
            this.packingServiceGroup.get(this.controlName).value[0]?.packRef ||
            '',
        };
        this.placesList = [seatNoVolume];
      }
    }
    if (
      this.packingServiceGroup &&
      this.packingServiceGroup.get(this.controlName)
    ) {
      this.packingServiceGroup.get(this.controlName).setValue(this.placesList);
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
    return of(true);
  }

  getDescription(pkg: Package): string {
    return `${pkg.Description}, ${Number(pkg.Length)}x${Number(
      pkg.Width,
    )}x${Number(pkg.Height)}, ціна ${pkg.Cost} грн`;
  }

  setPackage(pkg, index: number) {
    this.placesList[index]['packRef'] = pkg['Ref'];
    this.packingServiceGroup.get(this.controlName).setValue(this.placesList);
    this.stepDeliveryControl.get('OptionsSeat')?.setValue(this.placesList);
  }

  setSelectedPackage(place, index) {
    if (this.packages[index]) {
      for (const pkg of this.packages[index]) {
        if (place['packRef'] && place['packRef'] === pkg['Ref']) {
          return pkg;
        }
      }
      this.cd.markForCheck();
    }
    return null;
  }

  checkValue(index: number) {
    return !!this.placesList[index]['packRef'];
  }

  clearSearch(index: number) {
    this.placesList[index]['packRef'] = '';
    (this.stepDeliveryControl.get(['OptionsSeat']) as UntypedFormArray)
      ?.at(index)
      .patchValue({ packRef: '' });
  }

  ngOnDestroy() {
    this.packingServiceGroup.removeControl(this.controlName);
    this.requestSubscription?.unsubscribe();
    this.stepDeliveryControlSubscription?.unsubscribe();
    this.addressSenderControlSubscription?.unsubscribe();
    this.cargoTypeSubscription?.unsubscribe();
    this.addressRecipientControlSubscription?.unsubscribe();
    this.paramsSeatsSubscription?.unsubscribe();
    this.stepperSelectionEventSubs?.unsubscribe();
  }
}

<div class="form-row" [formGroup]="form">
  <div class="form-col form-col-7">
    <div class="label-radio" *ngIf="internationalInvRecipient">Тип контрагента</div>
    <mat-radio-group
      class="radio-row"
      [formControl]="recipientCounterpartyTypeControl">
      <mat-radio-button
        data-test-id="PrivatePerson"
        [value]="'PrivatePerson'"
        [checked]="!recipientCounterpartyTypeControl || (recipientCounterpartyTypeControl.value === 'PrivatePerson')">
        Приватна особа</mat-radio-button>
      <mat-radio-button
        data-test-id="Organization"
        [value]="'Organization'"
        [checked]="recipientCounterpartyTypeControl && recipientCounterpartyTypeControl.value === 'Organization'">
        <span *ngIf="!internationalInvRecipient">Організація</span>
        <span *ngIf="internationalInvRecipient">Юридична особа</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="form-col form-col_with-btn-right" *ngIf="!internationalInvRecipient">
    <button type="button" class="mat-button_with-icon"
            mat-button (click)="clearRecipient()">
      <mat-icon>close</mat-icon>
      Очистити
    </button>
  </div>
</div>

<div class="services-list__form" [formGroup]="afterpaymentGroup">
  <div class="form-row">
    <div class="form-col form-col-1 form-col-1_with_symbol">
      <mat-form-field class="mat-block mat-block_with_symbol"
                      data-symbol="грн"
                      [matTooltip]="showTooltipAfterpayment ? 'Відсутня можливість зміни для даного відправлення' : ''">
        <input matInput
               npNumber
               placeholder="Сума"
               NumberInput="true"
               [maxValue]="1000000"
               [allowFloat]="true"
               [digitsAfterDot]="2"
               (change)="change($event)"
               [formControlName]="controlName">
      </mat-form-field>
    </div>
  </div>
</div>

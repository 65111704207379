import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-sign-result',
  templateUrl: './document-sign-result.component.html',
  styleUrls: ['./document-sign-result.component.scss'],
})
export class DocumentSignResultComponent implements OnInit {
  constructor(public route: ActivatedRoute) {}

  ngOnInit() {
    parent.postMessage(this.route.snapshot.params['result'], '*');
  }
}

import { Injectable } from '@angular/core';
import { from as fromPromise ,  Observable } from 'rxjs';
import { map } from 'rxjs';

import * as firebase from 'firebase/app';
import 'firebase/database';

@Injectable()
export class FirebaseDatabaseService {

  readonly db = firebase.database();

  setToRef(ref: string, value: any): Observable<any> {
    return fromPromise(this.db.ref(ref).set(value));
  }

  pushToRef(ref: string, value: any) {
    return fromPromise(this.db.ref(ref).push(value));
  }

  getArrayByRef(ref: string): Observable<any[]> {
    return fromPromise(this.db.ref(ref).once('value'))
      .pipe(
        map(snapshot => {
          const data = snapshot.val();
          return !!data ? Object.keys(data).map(key => data[key]) : [];
        }),
      );
  }

  removeByRef(ref: string): Observable<any> {
    return fromPromise(this.db.ref(ref).remove());
  }

  getSnapshotByRef(ref: string): Observable<any> {
    return fromPromise(this.db.ref(ref).once('value'))
      .pipe(
        map((snapshot: any) => snapshot.exists() ? snapshot.val() : null),
      );
  }

}

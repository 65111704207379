import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AuthService } from '@services';
import { TableChip, TableFilterConfig } from '@shared';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent {
  @Input() config: TableFilterConfig;
  @Input() chips: TableChip[] = [];
  @Input() showClearButton: boolean = true;
  @Input() isLoading: boolean = true;
  @Output() filtersCleared: EventEmitter<void> = new EventEmitter<void>();

  constructor(private storage: LocalStorageService, private authService: AuthService) {}

  @HostBinding('class') get classes() {
    return (this.isLoading && !this.chips.length) || !this.chips.length ? 'hidden' : '';
  }

  clearFilters(): void {
    this.storage.clear(`${this.authService.user?.userLogin}_${this.config.storageKey}`);
    this.filtersCleared.emit();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injectable,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import Rxmq, { Channel } from 'rxmq';
import { finalize } from 'rxjs';
import { PayInvoiceType } from 'app/shared/other/payment-strategy';
import { Invoice } from 'app/models/invoice/invoice.model';
import { AlertToastService, AnalyticEvent, AuthService } from '@services';
import { IApiError, User } from '@models';
import { BusinessSettingsService } from '../../../services/business-settings.service';

const componentName = 'app-change-customer-return';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: object): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return this._to2digit(day) + '.' + this._to2digit(month) + '.' + year;
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

@Component({
  selector: 'app-change-customer-return',
  templateUrl: './change-customer-return.component.html',
  styleUrls: ['./change-customer-return.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }],
})
export class ChangeCustomerReturnComponent implements OnInit {
  @Input()
  type: PayInvoiceType;

  @Input()
  invoice: Invoice;

  @Input()
  user: User;

  dayCustomerReturn: number;

  periodOfStorageCargo: number;

  customerReturnForm: UntypedFormGroup;

  @Input()
  allowedDates = [];

  @Input()
  minDate: Date;

  placeholder: string;
  dateFilter = this.dateFilter2.bind(this);
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeCustomerReturnComponent>,
    public businessSettingsService: BusinessSettingsService,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private alertToastService: AlertToastService,
    private dateAdapter: DateAdapter<MyDateAdapter>,
    private authService: AuthService,
  ) {
    dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.createForm();
    if (!this.minDate || !this.allowedDates.length) {
      this.checkTermExtension();
    }
    this.placeholder =
      this.type === 'in'
        ? 'Дозволений термін продовження'
        : 'Термін автоматичного повернення';
    this.customerReturnForm
      .get('StorageFinalDate')
      .valueChanges.subscribe((value) => {
      this.periodOfStorageCargo = this.getDaysCargo(
        this.minDate,
        moment(value).endOf('day'),
      );
      this.customerReturnForm
        .get('PeriodOfStorageCargo')
        .setValue(this.periodOfStorageCargo || '');
    });
  }

  createForm() {
    this.customerReturnForm = this.formBuilder.group({
      StorageFinalDate: ['', Validators.compose([Validators.required])],
      PeriodOfStorageCargo: [{ value: '', disabled: true }],
      PayerType: ['Recipient'],
      PaymentMethod: ['Cash'],
    });
  }

  dateFilter2(d: Date): boolean {
    const date = moment(d).format('YYYY-MM-DD');
    return this.allowedDates && this.allowedDates.indexOf(date) > -1;
  }

  getDaysCargo(from, to) {
    return this.allowedDates
      ? this.allowedDates
        .map((day) => new Date(day))
        .filter((day) => day >= from && day <= to).length
      : 0;
  }

  save() {
    this.pending = true;
    const formValue = this.customerReturnForm.value;
    this.businessSettingsService
      .orderChangeCustomerReturn(this.invoice, {
        PaymentMethod: formValue.PaymentMethod,
        PayerType: formValue.PayerType,
        _PeriodOfStorageCargo: formValue.PeriodOfStorageCargo,
        StorageFinalDate: moment(formValue.StorageFinalDate).format(
          'DD.MM.YYYY',
        ),
      })
      .pipe(finalize(() => (this.pending = false)))
      .subscribe(
        (data) => {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.submit.success`)
            .next({
              eventLabel: `Подовження терміну зберігання: ${
                this.invoice.docNumber || ''
              }`,
              eventCategory: componentName,
              eventAction: 'submit.success',
            });
          if (data[0].Message) {
            this.alertToastService.pushSuccess(data[0].Message);
          } else {
            this.alertToastService.pushSuccess(
              'Заявку на подовження терміну зберігання успішно створено!',
            );
          }
          this.dialogRef.close({ success: true });
        },
        (errors: IApiError[]) => {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.submit.error`)
            .next({
              eventLabel: `Подовження терміну зберігання: ${
                this.invoice.docNumber || ''
              } ${errors[0] && errors[0].message}`,
              eventCategory: componentName,
              eventAction: 'submit.error',
            });
          this.alertToastService.pushApiErrors(errors);
        },
      );
  }

  checkTermExtension() {
    this.businessSettingsService
      .checkPossibilityTermExtension(this.invoice)
      .subscribe((data) => {
          const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
          this.allowedDates = data[0].AvailableStorageExtensionDates.map((el) =>
            el.DayDate.replace(pattern, '$3-$2-$1'),
          );
          this.minDate = new Date(this.allowedDates[0]);
        },
        (errors) => {
          this.dialogRef.close();
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          } else {
            this.alertToastService.pushError('Щось пішло не так!');
          }
        },
      );
  }

  getDays() {
    return Array.from({ length: 10 }, (v, k) => k + 1);
  }
}

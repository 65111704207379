import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs';
import {
  AlertToastService,
  AuthService,
  ErrorMessages,
  ErrorTypeMessages,
  GoogleAnalyticService,
  ValidationService,
} from '@services';
import { ContactsService } from '../../../../../services/contacts/contacts.service';
import { AbstractFormContainer } from '@shared';
import { Contragent } from '@models';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'np-inv-contact-organization',
  templateUrl: 'contact-organization.component.html',
  styleUrls: ['./contact-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactOrganizationComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input()
  controlName = 'ContactOrganization';

  @Input() recipientEDRPOU: string;

  @Input()
  templateValidation: boolean;

  @Input()
  contactProperty = 'Recipient';

  @Input()
  IsEnabledCancel = false;

  @Input()
  initialOrg: Contragent;

  @Input() disableForm: boolean;

  @Output()
  onCancel = new EventEmitter<string>();

  matcher = new MyErrorStateMatcher();

  organizationFormGroup: UntypedFormGroup;

  errors: ErrorTypeMessages = {
    edrpou: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'minlength', message: 'Має містити 8-10 цифр' },
      { key: 'maxlength', message: 'Має містити 8-10 цифр' },
    ],
  };

  errorMessages: ErrorMessages = {};

  errorsSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private contactService: ContactsService,
    private authService: AuthService,
    private validationService: ValidationService,
    private googleAnalyticsService: GoogleAnalyticService,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  get edrpouControl(): AbstractControl {
    return this.organizationFormGroup.get('edrpou');
  }

  get companyNameControl(): AbstractControl {
    return this.organizationFormGroup.get('companyName');
  }

  get orgTypeControl(): AbstractControl {
    return this.organizationFormGroup.get('orgType');
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.disableForm) {
      this.organizationFormGroup.disable();
    }
    this.initEdrpouSubscriptions();
    this.errorsSubscription = this.form
      .get(this.controlName)
      .valueChanges.subscribe((value) => {
        this.errorMessages = this.validationService.getSingleErrorsObject(
          (this.form.get(this.controlName) as UntypedFormGroup).controls,
          this.errors,
        );
      });
    this.patchEDRPOU();
    if (this.templateValidation) {
      Object.keys(this.form.get(this.controlName)['controls']).forEach(
        (key) => {
          this.form.controls[this.controlName]['controls'][
            key
          ].clearValidators();
        },
      );
    }
  }

  private patchEDRPOU(): void {
    if (this.recipientEDRPOU && this.edrpouControl) {
      this.edrpouControl.patchValue(this.recipientEDRPOU);
    }
  }

  onTouchValidation() {
    this.errorMessages = this.validationService.getSingleErrorsObject(
      (this.form.get(this.controlName) as UntypedFormGroup).controls,
      this.errors,
    );
  }

  addFormControls(): void {
    let contragent: Contragent = this.form.get('Contragent')
      ? this.form.get('Contragent').value
      : null;
    if (this.initialOrg) {
      contragent = this.initialOrg;
    }
    this.organizationFormGroup = this.fb.group({
      edrpou: [
        contragent ? contragent.EDRPOU : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
        ]),
      ],
      companyName: [
        {
          value: contragent ? contragent.Description : '',
          disabled: true,
        },
        Validators.compose([Validators.required]),
      ],
      orgType: [
        {
          value:
            contragent && contragent.OwnershipFormDescription
              ? contragent.OwnershipFormDescription
              : '',
          disabled: true,
        },
        Validators.compose([Validators.required]),
      ],
    });
    this.form.setControl(this.controlName, this.organizationFormGroup);
    if (contragent) {
      this.searchOrganization(contragent.EDRPOU);
    }
  }

  initEdrpouSubscriptions(): void {
    this.edrpouControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.edrpouControl.status === 'VALID') {
        this.searchOrganization(this.edrpouControl.value);
      } else {
        this.organizationFormGroup.patchValue({
          companyName: '',
          orgType: '',
        });
      }
    });
  }

  searchOrganization(query: string) {
    this.contactService.getByEdrpouBot(query).subscribe(
      (contragent: Contragent) => {
        if (contragent) {
          this.form.setControl('Contragent', new UntypedFormControl(contragent));
          this.organizationFormGroup.patchValue({
            companyName: contragent.Description
              ? contragent.Description
              : contragent.Description,
            orgType: contragent.OwnershipFormDescription
              ? contragent.OwnershipFormDescription
              : '',
          });

          this.googleAnalyticsService.track(
            'analitics',
            `second-step.searchContragent`,
            {
              eventLabel: `Пошук організації`,
              eventCategory: 'create-invoice',
              eventAction: 'search.success',
            },
          );
        }
      },
      (errors) => {
        this.alertToastService.pushApiErrors(errors);
        this.form.removeControl('Contragent')
        this.organizationFormGroup.patchValue({
          companyName: '',
          orgType: '',
        });
      },
    );
  }

  clear() {
    this.form.removeControl('Contragent');
    this.organizationFormGroup.patchValue({
      edrpou: '',
      companyName: '',
      orgType: '',
    });
    this.form.get('RecipientCounterpartyType').setValue('PrivatePerson');
  }

  cancel() {
    this.onCancel.next('select');
  }

  ngOnDestroy() {
    this.form.removeControl('Contragent');
    this.form.removeControl(this.controlName);
    this.errorsSubscription.unsubscribe();
  }
}

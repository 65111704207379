import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[matAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {
  }

  private _matAutofocus: string;

  get matAutofocus(): string {
    return this._matAutofocus;
  }

  @Input()
  set matAutofocus(value) {
    this._matAutofocus = value;
    this.autoFocus();
  }

  ngAfterViewInit(): void {
    this.autoFocus();
  }

  autoFocus(): void {
    setTimeout(() => {
      if (!this.matAutofocus) {
        if (this.elementRef.nativeElement.classList.contains('mat-radio-button')) {
          this.elementRef.nativeElement.querySelector('.mat-radio-input').focus();
        } else {
          this.elementRef.nativeElement.focus();
        }
      }
    });
  }
}

<div class="size-form mb-1" [formGroup]="commonParamsGroup">
    <div class="form-row">
      <div class="form-col form-col-1 form-col-1_with_symbol">
        <mat-form-field class="mat-block mat-block_with_symbol mat-block_placeholder-top"
                        data-symbol="кг">
          <input
            matInput
            npNumber
            NumberInput="true"
            [maxValue]="999"
            [allowFloat]="true"
            [digitsAfterDot]="3"
            placeholder="Загальна вага"
            [errorStateMatcher]="matcher"
            formControlName="Weight">
        </mat-form-field>
      </div>
      <div class="form-col form-col-1 form-col-1_with_symbol">
        <mat-form-field class="mat-block mat-block_with_symbol mat-block_with_help mat-block_placeholder-top"
                        data-symbol="м³">
          <input
            matInput
            npNumber
            NumberInput="true"
            [maxInputLength]="5"
            [digitsAfterDot]="3"
            [maxValue]="999"
            placeholder="Загальний об’єм"
            formControlName="VolumeGeneral"
            [errorStateMatcher]="matcher"
            (input)="recalculateVolumeWeight($event)">
          <i class="material-icons icon-help cursor-pointer"
             [matTooltip]="'Загальний об’єм є необовязковим за умови заповнення поля  «Загальна вага»'"
             matTooltipPosition="below">help_outline</i>
        </mat-form-field>
      </div>
      <div class="form-col form-col-1 form-col-1_with_symbol">
        <mat-form-field class="mat-block mat-block_with_symbol mat-block_placeholder-top"
                        data-symbol="шт">
          <input
            matInput
            npNumber
            NumberInput="true"
            [maxInputLength]="3"
            [maxValue]="999"
            placeholder="Кількість місць"
            formControlName="SeatsAmount"
            [errorStateMatcher]="matcher"
            (keypress)="preventDecimal($event)">
        </mat-form-field>
      </div>
      <div class="form-col form-col-1">
        <div class="info-cell">
          <div class="info-cell__label">Об’ємна вага
            <i class="material-icons icon-help info-cell__icon cursor-pointer"
                [matTooltip]="'Об’ємна вага розраховується за формулою (Довжина × Ширина × Висота, см) / 4000 та порівнюється з фактичною вагою. Більший показник використовується в розрахунках вартості перевезення.'"
                matTooltipPosition="below">help_outline</i>
          </div>
          <div class="info-cell__content">{{commonParamsGroup.get('VolumeWeight').value || '&mdash;'}}</div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>

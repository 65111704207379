import { Address, Model, TimeInterval } from '@models';
import * as _ from 'lodash';
import { ApiResponse } from '../../core/interfaces/api-interfaces';
import { InvoiceCargoType } from './invoice-cargo-type.model';
import { InvoiceContact } from './invoice-contact.model';
import { InvoicePaymentMethod } from './invoice-payment-method.model';
import { InvoiceRegister } from './invoice-register.model';
import { InvoiceServiceRequest } from './invoice-service-request.model';
import { InvoiceServiceType } from './invoice-service-type.model';
import { InvoiceSize } from './invoice-size.model';
import { InvoiceState } from './invoice-state.model';

export interface BackDelivery {
  CargoType: string;
  CargoTypeRef: string;
  CustomBackwardDeliveryParameter: string;
  CustomBackwardDeliveryParameterRef: string;
  PayerType: string;
  PayerTypeRef: string;
  RedeliveryString: string;
  Services: any;
  Type: string;
  TypeRef: string;
}

export interface BackDeliveries {
  Money?: BackDelivery;
  Documents?: BackDelivery;
  DifferentDocuments?: BackDelivery;
  SignedDocuments?: BackDelivery;
  Other?: BackDelivery;
}

export class Invoice extends Model {
  AfterpaymentOnGoodsCost: any;
  AccompanyingDocuments: any;
  AdditionalInformation: string;
  docNumber: string;
  moneyTransferAmount: number | string;
  moneyTransferCash2Card: number;
  recipient: InvoiceContact;
  recipientContactPhone: string | number;
  recipientAddress: Address;
  RecipientName?: string;
  recipientCounterpartyType: 'PrivatePerson' | 'Organization' | '';
  sender: any;
  senderContactPhone?: string | number;
  BackwardDeliveryData: any;
  senderAddress: Address;
  state: InvoiceState;
  weights: number[];
  weight: number;
  FactualWeight?: number;
  DocumentWeight?: number;
  CheckWeight?: number;
  CalculatedWeight: number;
  CheckWeightMethod?: string;
  sizes: InvoiceSize[];
  payer: 'Sender' | 'Recipient';
  backDeliveryPayer: 'Sender' | 'Recipient';
  package = false;
  description: string;
  Description?: string;
  clientBarcode: string;
  backDeliveryDescription: string;
  announcedPrice: number;
  Cost?: number;
  cost: number;
  CostOnSite?: number;
  backMoney: number;
  backDelivery: InvoiceCargoType;
  backDeliveries: BackDeliveries;
  backDeliveriesTooltipDescription: string;
  additionalInformation: string;
  additionals: any[];
  paymentCard: any;
  serviceType: InvoiceServiceType;
  cargoType: InvoiceCargoType;
  createTime: Date;
  DateTime?: string;
  dateTime: Date;
  dateScan: Date;
  deliveryPrice: number;
  SumBeforeCheckWeight: number;
  StoragePrice: number;
  forwardingCount: number | string | null;
  ForwardingCount: number | string | null;
  redeliveryNum: number;
  saturdayDelivery = false;
  deliveryToHand = false;
  paymentMethod: InvoicePaymentMethod;
  infoRegClientBarcodes: string;
  senderNumberOfFloorsLifting: number;
  senderElevator = false;
  recipientNumberOfFloorsLifting: number;
  recipientElevator = false;
  isPrinted = false;
  isRegisterPrinted = false;
  isPayCancel: boolean;
  isPayPaid: boolean;
  payedSum: number;
  desiredDate: Date;
  desiredTimeInterval: TimeInterval;
  receivingTime: Date;
  RecipientDateTime?: string;
  actualDeliveryDate: Date;
  undeliveryReasonsSubtypeDescription: string;
  tiresWheels: { [key: string]: number };
  serviceRequests: InvoiceServiceRequest[];
  marketPlaceToken: string;
  isInternational: boolean;
  registerDocNumber: string;
  register: InvoiceRegister;
  RegionCode: any;
  statusCode: string;
  paymentControl: number;
  dateFirstDayStorage: string;
  dateReturnCargo: string;
  estimatedDeliveryDate: Date;
  scheduleDeliveryDate: Date;
  MarketplacePartnerDescription: string;
  OwnerDocumentNumber: string;
  counterpartySenderType: string;
  counterpartySenderDescription: string;
  counterpartySender: string;
  seatsAmount: number;
  volumeWeight: any;
  VolumetricWeight: any;
  Ref: string;
  RefEW: string;
  Cash2CardPAN: string;
  RedeliveryPaymentCard: any;
  PackingNumber: any;
  OptionsSeat: any;
  PaymentMethodRef: string;
  PayerTypeRef: string;
  MarketplacePartnerToken: string;
  note: string;
  $isTracking: boolean;
  DaysStorageCargo: string;
  RecipientFullName: string;
  CargoDescription: string;
  MoneyTransferAmount: number;
  MoneyTransferCash2Card: string;
  MoneyTransferPayerType: string;
  MoneyTransferPaymentMethod: string;
  lastCreatedOnTheBasisDocumentType: string;
  cardMaskedNumber: string;
  securePayment?: number;
  SendersPhone: string;
  PhoneSender: string;
  PayerType: string;
  BackwardDeliveryDataDocuments: any;
  CarCallNumber: string;
  CargoType: string;
  ServiceType?: string;
  ScanSheetRef?: string;
  ScanSheetNumber?: string;
  isPossibilityDuplicate: boolean;
  possibilityCreateLightReturn: boolean;
  LightReturn: boolean;
  LightReturnNumber: string;
  DeliveryPharma: boolean;
  DeliveryFrom: string;
  CanCourierCall: boolean;
  PickupNumber: string;
  IntDocNumber: string;
  TypeOfDocument: string;
  OrderingAdditionalServices: OrderingAdditionalService[];
  RedirectingReturnIndication: {
    icon: string;
    tooltipText: string;
  };

  sumWeights() {
    this.weight = _.sum(this.weights);
  }
}

export class InvoiceMy extends Invoice {
  SenderName?: string;
  CalculatedWeight: number;
  RecipientName?: string;
  RecipientContactPerson?: string;
  CounterpartySenderDescription?: string;
  CounterpartyRecipientDescription?: string;
  CitySenderDescription?: string;
  SenderAddressDescription?: string;
  SenderAddressShortDescription?: string;
  RecipientDescription?: string;
  ScanSheetNumber?: string;
  SenderDescription?: string;
  SenderContactPerson?: string;
  Ref: string;
  CitySender: string;
  CityRecipient: string;
  State: string;
  SenderAddress: string;
  RecipientAddress: string;
  Sender: string;
  ContactSender: string;
  Recipient: string;
  ContactRecipient: string;
  CargoDescription: string;
  moneyTransferPayerType?: string;
  number?: string;
  moneyTransferPaymentMethod?: string;
  ref?: string;
  moneyTransferAmount: number | string;
  moneyTransferCommission?: number | string;
  moneyTransferNumber?: string;
  moneyTransferCreationDate?: string;
  moneyTransferPayerCommission?: string;
  moneyTransferStatus?: string;
  moneyTransferStatusDateTime?: string;
  CarCallNumber: string;
  TimeInterval?: string;
  NumberOfFloorsLifting?: string;
}

export class MoneyTransfer extends InvoiceMy {}

export interface getOutgoingDocumentsByPhoneData {
  AdjustedDate: string;
  AfterpaymentOnGoodsCost: string;
  ArrivalDateTime: string;
  AttorneyDescription: null;
  BackwardDeliveryCargoType: string;
  BeginDeliveryTime: string;
  CalculatedWeight: number;
  CardMaskedNumber: string;
  CargoAutoReturnDate: string;
  CargoDescription: string;
  CargoType: string;
  CheckWeight: number;
  CityRecipient: string;
  CityRecipientDescription: string;
  CitySender: string;
  CitySenderDescription: string;
  ClientBarcodes: [];
  Cost: number;
  CounterpartyRecipient: string;
  CounterpartyRecipientDescription: string;
  CounterpartySender: string;
  CounterpartySenderDescription: string;
  DateTime: string;
  DeletionMark: boolean;
  DocumentCost: number;
  DocumentWeight: number;
  EndDeliveryTime: string;
  ExpressWaybillPaymentStatus: string;
  FactualWeight: string;
  FirstDayStorage: string;
  InternationalDeliveryType: string;
  LinkedEWs: [];
  LoyaltyCardRecipient: null;
  LoyaltyCardSender: null;
  MarketplacePartnerDescription: string;
  MarketplacePartnerToken: string;
  MoneyTransferAmount: string;
  MoneyTransferCash2Card: string;
  MoneyTransferCommission: number;
  MoneyTransferCreationDate: string;
  MoneyTransferNumber: string;
  MoneyTransferPayerCommission: string;
  MoneyTransferPayerType: string;
  MoneyTransferPaymentMethod: string;
  MoneyTransferTransactionDate: string;
  Note: string;
  Number: string;
  OrderedBackwardDeliveryDocuments: [string];
  OrderingAdditionalServices: OrderingAdditionalService[];
  OwnerDocumentType: string;
  PayerType: string;
  PaymentMethod: string;
  PhoneRecipient: string;
  PhoneSender: string;
  ReceivingDateTime: string;
  RecipientAddress: string;
  RecipientAddressDescription: string;
  RecipientArchive: false;
  RecipientFullName: string;
  RecipientName: string;
  Redelivery: number;
  RedeliveryNum: string;
  RedeliverySum: number;
  RefEW: string;
  ScanSheetInternetNumber: string;
  ScheduledDeliveryDate: string;
  SeatsAmount: string;
  SenderAddress: string;
  SenderAddressDescription: string;
  SenderArchive: boolean;
  SenderName: string;
  ServiceType: string;
  SettlementRecipient: string;
  SettlementSender: string;
  SettlmentAddressData: {
    SenderWarehouseRef: string;
    RecipientWarehouseRef: string;
    RecipientSettlementDescription: string;
    RecipientSettlementRef: string;
    RecipientWarehouseNumber: string;
    SenderSettlementDescription: string;
    SenderSettlementRef: string;
    SenderWarehouseNumber: string;
  };
  SumBeforeCheckWeight: 0;
  TrackingStatusCode: string;
  TrackingStatusName: string;
  TrackingUpdateDate: string;
  TransferStatusGlobalMoneyStatus: string;
  VolumetricWeight: string;
  possibilityChangeCash2Card: boolean;
  possibilityChangeDeliveryIntervals: boolean;
  possibilityChangeEW: boolean;
  possibilityCreateRedirecting: boolean;
  possibilityCreateRefusa: boolean;
  possibilityCreateReturn: boolean;
  postomatV3CellReservationNumber: boolean;
  recipientEDRPOU: string;
}

export interface OrderingAdditionalService {
  ChangeDataEWNumber: string;
  CityRecipient: string;
  CounterpartyRecipient: string;
  CounterpartySender: string;
  CounterpartyThirdPerson: string;
  Customer: string;
  CustomerName: string;
  DateTime: string;
  EWCargoReturnNumber: string;
  EWCargoReturnRef: string;
  EWRedirectingNumber: string;
  EWRedirectingRef: string;
  Issued: string;
  NewServiceDate: string;
  Note: string;
  Number: string;
  OrderType: 'Redirecting' | 'CargoReturn';
  Status: 'CreateEWCargoReturn' | 'StoreInProcessing' | 'Received';
  PayerType: string;
  PaymentMethod: string;
  PhoneRecipient: string;
  PhoneSender: string;
  RecipientAddress: string;
  RecipientName: string;
  Ref: string;
  SenderName: string;
}

export interface getOutgoingDocumentsByPhoneResponse extends ApiResponse {
  data: [{ result: getOutgoingDocumentsByPhoneData[] }];
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';
import { Subscription } from 'rxjs';
import { ErrorMessages, ErrorTypeMessages, ValidationService } from '@services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-documents',
  templateUrl: './delivery-documents.component.html',
  styleUrls: ['./delivery-documents.component.scss'],
})
export class DeliveryDocumentsComponent
  extends AbstractFormContainer
  implements OnDestroy, OnInit
{
  @Input() controlName = 'Weight';

  @Input() isTemplate: boolean;

  @Input() invoiceId: string;

  errors: ErrorTypeMessages = {
    [this.controlName]: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'min', message: 'Мінімальне значення 0.1' },
      { key: 'max', message: 'Максимальне значення 1' },
    ],
  };

  options: any = ['0.1', '0.5', '1.0'];

  errorMessages: ErrorMessages = {};

  errorsSubscription: Subscription;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private validationService: ValidationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setErrorSubscription();
  }

  setErrorSubscription() {
    this.errorsSubscription = this.form
      .get(this.controlName)
      .valueChanges.subscribe(() => {
        this.errorMessages = this.validationService.getSingleControlErrorObject(
          this.controlName,
          this.form.get(this.controlName),
          this.errors,
        );
      });
  }

  addFormControls(): void {
    const documentsFormGroup = new UntypedFormControl('0.5', [
      ...(this.isTemplate ? [] : [Validators.required]),
      Validators.min(0.1),
      Validators.max(1),
    ]);
    this.form.addControl(this.controlName, documentsFormGroup);
  }

  ngOnDestroy() {
    this.form.removeControl(this.controlName);
    this.errorsSubscription.unsubscribe();
  }
}

<div [formGroup]="doorstepPickupDataGroup" class="services-list__form">
  <div class="form-row">
    <div class="form-col form-col-1">
      <mat-form-field [matTooltip]="showTooltipFloor ? 'Відсутня можливість зміни для даного відправлення' : ''"
                      class="mat-block">
        <input [allowFloat]="false"
               [max]="FloorMax"
               [min]="FloorMin"
               [numeric]="true"
               autocomplete="off"
               formControlName="NumberOfFloorsDescent"
               matInput
               placeholder="Поверх"
               type="number"
        >
      </mat-form-field>
    </div>
    <div class="form-col hint-container">
      <div class="hint">
        <mat-icon class="hint-icon">info_outline</mat-icon>
        Якщо у Вас приватний будинок вкажіть 1-й поверх
      </div>
    </div>
  </div>
</div>

<div class="invoice__detail-info">
  <div class="info-row invoice__row">
    <div class="info-row__left">
      <div class="info-row__col">
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Тип</div>
          <div class="info-cell__content">
            {{ invoice?.cargoType?.description || invoiceFullData.CargoType }}
          </div>
        </div>
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Вага</div>
          <div *ngIf="invoice?.CalculatedWeight > 0 && !!invoice?.CheckWeightMethod && invoice?.CheckWeightMethod !== 'Volumetric';
           else weight" class="info-cell__content">
            <span
              class="line-through-text">{{invoice?.FactualWeight}}</span> {{ (invoice?.CalculatedWeight) + ' кг' }}
          </div>
          <ng-template #weight>
            <div class="info-cell__content" *ngIf="invoice?.FactualWeight; else dash">
              {{ (invoice?.FactualWeight) + ' кг' }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="info-row__col">
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Об’ємна вага</div>
          <div *ngIf="invoice?.CalculatedWeight > 0 && !!invoice?.CheckWeightMethod && invoice?.CheckWeightMethod === 'Volumetric';
           else volume" class="info-cell__content">
            <span
              class="line-through-text">{{invoice?.volumeWeight || invoice?.VolumetricWeight}}</span> {{ (invoice?.CalculatedWeight) + ' кг' }}
          </div>
          <ng-template #volume>
            <div class="info-cell__content" *ngIf="invoice?.volumeWeight || invoice?.VolumetricWeight; else dash">
              {{ (invoice?.volumeWeight || invoice?.VolumetricWeight) + ' кг' }}
            </div>
          </ng-template>
        </div>
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Місць</div>
          <div class="info-cell__content">
            {{ (invoice?.seatsAmount || invoiceFullData?.SeatsAmount) || '&mdash;'}}
          </div>
        </div>
      </div>
    </div>
    <div class="info-row__right wrap">
      <div class="info-row__right-wrap">
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Відправник</div>
          <div class="info-cell__content" *ngIf="invoice?.sender && !invoiceFullData.SenderName">
            {{invoice?.sender?.lastName}} {{invoice?.sender?.firstName}}
            {{invoice?.sender?.middleName}}
          </div>
          <div class="info-cell__content" *ngIf="invoice?.sender && invoiceFullData.SenderName">
            {{invoiceFullData.SenderName}}
          </div>
          <div class="info-cell__content" *ngIf="!invoice?.sender && invoiceFullData.SenderName">
            {{invoiceFullData.SenderName}}
          </div>
          <div class="info-cell__content" *ngIf="!invoice?.sender && !invoiceFullData.SenderName">
            &mdash;
          </div>
        </div>
        <div class="info-cell info-row__cell">
          <div class="info-cell__label">Телефон відправника</div>
          <div class="info-cell__content nowrap-text">
            <span class="copy-content d-inline-block"
                  *ngIf="invoiceFullData?.PhoneSender; else dash">
                {{ invoiceFullData?.PhoneSender | npFinePhone }}
              <button mat-icon-button class="copy-content__btn"
                      [appPhoneCopy]="invoiceFullData?.PhoneSender"
                      [matTooltip]="'Скопіювати'" matTooltipPosition="below">
                      <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
              </button>
          </span>
          </div>
        </div>
      </div>
      <div *ngIf="invoiceFullData?.counterpartySender || invoiceFullData?.CounterpartySenderDescription"
           class="info-cell info-row__cell">
        <div class="info-cell__label">Компанія</div>
        <div class="info-cell__content">
          {{invoiceFullData?.counterpartySender || invoiceFullData?.CounterpartySenderDescription || '&mdash;'}}
        </div>
      </div>
    </div>
  </div>
  <div class="info-row invoice__row">
    <div class="info-row__left">
      <div class="info-cell info-row__cell">
        <div class="info-cell__label">Внутрішній номер відправлення</div>
        <div class="info-cell__content">
          <span class="copy-content d-inline-block"
                *ngIf="invoice?.clientBarcode && invoice?.clientBarcode.length > 0 ||
                       invoiceFullData.ClientBarcodes && invoiceFullData.ClientBarcodes?.length > 0">
                {{ (invoice?.clientBarcode || invoiceFullData.ClientBarcodes)}}
            <button mat-icon-button class="copy-content__btn"
                    [appPhoneCopy]="invoice?.clientBarcode || invoiceFullData.ClientBarcodes"
                    [matTooltip]="'Скопіювати'" matTooltipPosition="below">
                      <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
            </button>
          </span>
          <span *ngIf="!invoice?.clientBarcode || !invoice?.clientBarcode.length &&
                       !invoiceFullData?.ClientBarcodes">&mdash;</span>
        </div>
      </div>
    </div>
    <div class="info-row__right">
      <div class="info-cell info-row__cell info-row__cell-overflow info-row__max-width-fit-content">
        <div class="info-cell__label">Додаткова інформація про відправлення</div>
        <div class="info-cell__content">
          {{ (invoiceFullData?.note) || (invoiceFullData?.Note) || '&mdash;'}}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dash>—</ng-template>

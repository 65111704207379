import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { clearField, getError, InputTableFilter, SmartFormContainer } from '@shared';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent extends SmartFormContainer {
  readonly getError = getError;
  readonly clearField = clearField;

  @Input() config: InputTableFilter;

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'np-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() placeholder = '';
  @Input() hintText;
  @Input() isFormSubmitted = false;
  @Input() readonly = false;
  @Input() blackText = false;
  @Input() disabled = false;
  @Input() focused = false;
  @Output() blur = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();

  @HostBinding('class.is-edited') isEdited = false;
  phoneForm: UntypedFormGroup;
  start = '+38 ';

  @Input()
  intertationalInvRecipient;

  public mask = ['+', '3', '8', /\d/, ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  get value() {
    return this._value;
  }

  @Input()
  set value(value: string) {
    this._value = this.format(value, this.start);

    if (this.phoneForm) {
      this.phoneForm.patchValue({phone: this._value});
    }
  }

  public get isValid(): boolean {
    return this._isValid || false;
  }

  @Input()
  public set isValid(value: boolean) {
    this._isValid = value;

    if (this.phoneForm) {
      this.phoneForm.controls.phone.updateValueAndValidity();
    }
  }

  @Input()
  formControlParent;
  private _isValid: boolean;
  private _value = '';
  private input: any;
  private isTouched = false;
  private onTouch: any;
  error: string;

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private fb: UntypedFormBuilder,
  ) {
  }

  createForm() {
    this.phoneForm = this.fb.group({
      phone: [
        '',
        () => {
          return this.isValid ? null : {error: true};
        },
      ],
    });
  }

  ngOnInit() {
    this.input = this.el.nativeElement.querySelector('input');
    this.initDropEvent();
    this.createForm();
    this.phoneForm.patchValue({phone: this._value});
    this.initOverrideElementFocusMethod();
    setTimeout(() => {
      if (this.formControlParent) {
        this.formControlParent.valueChanges.subscribe(value => {
          if (this.formControlParent.touched || this.formControlParent.dirty) {
            this.phoneForm.controls.phone.markAsTouched();
            this.phoneForm.controls.phone.markAsDirty();
          } else {
            this.phoneForm.controls.phone.markAsUntouched();
            this.phoneForm.controls.phone.markAsPristine();
          }
          this.cd.detectChanges();
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.focused) {
      this.input.focus();
      this.cd.detectChanges();
    }
  }

  onBlur(event) {
    if (event.target.value.trim() === this.start.trim()) {
      event.target.value = '';
      this.phoneForm.controls.phone.markAsDirty();
    } else {
    }

    if (this._value !== event.target.value) {
      this._value = event.target.value;
      this.propagateChange(this.value.replace(/\D/gi, ''));
      this.change.emit(event);
    }
    this.phoneForm.patchValue({phone: this._value});
    this.blur.emit(event);

    if (!this.isEdited) {
      this.isEdited = !!this._value;
    }
    this.isTouched = true;
  }

  onKeyUp(event) {
    if (!this.readonly && !this.disabled) {
      setTimeout(() => {
        if (this._value !== event.target.value) {
          this._value = event.target.value;
          this.phoneForm.patchValue({phone: this._value});
          this.propagateChange(this.value.replace(/\D/gi, ''));
          this.change.emit(event);
          this.cd.markForCheck();
        }
        if (event.target.value === '') {
          this._value = this.start;
          this.phoneForm.patchValue({phone: this._value});
        }
      });
    }
  }

  onFocus(event) {
    if (event.target.value.trim() === '' && !this.intertationalInvRecipient) {
      event.target.value = this.start;
      this.phoneForm.patchValue({phone: this.start});
      setTimeout(() => {
        event.target.selectionStart = this.start.length;
      }, 100);
    }
    this.focus.emit(event);
  }

  onPaste(event) {
    if (!this.intertationalInvRecipient) {
      const clipboardData = (event.originalEvent || event).clipboardData;
      if (clipboardData) {
        const clearNumber: string = clipboardData
          .getData('Text')
          .replace(/\D/gi, '');
        event.target.value = this.format(clearNumber, this.start);
      } else {
        if (event.target.value.trim() === '') {
          event.target.value = this.start;
        }
      }

      if (this._value !== event.target.value) {
        this._value = event.target.value;
        this.propagateChange(this.value.replace(/\D/gi, ''));
        this.change.emit(event);
      }

    }
    this.isEdited = true;
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  // TODO: ErrorStateMatcher
  showError = () => {
    return !this.isValid && (this.isEdited || this.isFormSubmitted);
  }

  writeValue(value: any) {
    this.value = value;
  }

  private format(v: string, start: string) {
    if (v && typeof v === 'string') {
      switch (v.length) {
        case 11:
          v = v.replace(/^8/, '');
          break;
        case 12:
          v = v.replace(/^38/, '');
          break;
        case 13:
          v = v.replace(/^\+38/, '');
          break;
      }
      if (v.length === 9) {
        v = `${v[0]}${v[1]} ${v[2]}${v[3]}${v[4]}-${v[5]}${v[6]}-${v[7]}${v[8]}`;
      }
      v = v.replace(start, '');
      return start + v;
    }

    return '';
  }

  private initDropEvent() {
    this.input.addEventListener('drop', function (event) {
      event.preventDefault();
    });
  }

  private initOverrideElementFocusMethod() {
    this.el.nativeElement.focus = () => {
      this.el.nativeElement.querySelector('input').focus();
    };
  }
}

import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super();
    this.nextPageLabel = 'Наступна сторінка';
    this.previousPageLabel = 'Попередня сторінка';
    this.itemsPerPageLabel = 'Елементів на сторінці:';
    this.getRangeLabel = function(page: number, pageSize: number, length: number) {
      return `${page} ${pageSize} ${length}`;
    };
    this.getRangeLabel = function(page: number, pageSize: number, length: number) {
      if (!length || !pageSize) {
        return `0 з ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

      return `${startIndex + 1}–${endIndex} з ${length}`;
    };
  }
}

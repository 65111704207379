import { GetCountriesData } from '@shared';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';
import { CA } from './ca.config';
import { CZ } from './cz.config';
import { DE } from './de.config';
import { EE } from './ee.config';
import { ES } from './es.config';
import { FR } from './fr.config';
import { GB } from './gb.config';
import { HU } from './hu.config';
import { IT } from './it.config';
import { LT } from './lt.config';
import { LV } from './lv.config';
import { MD } from './md.config';
import { PL } from './pl.config';
import { RO } from './ro.config';
import { SK } from './sk.config';
import { US } from './us.config';

export const BaseCountryConfig: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
};

export function getCountryConfig(
  baseConfig: GetCountriesData,
  extendedConfig: Partial<GetCountriesData>,
): GetCountriesData {
  return {
    ...baseConfig,
    ...extendedConfig,
  };
}

export function normalizeCountry(c: GetCountriesData): GetCountriesData {
  switch (c.Code) {
    // Country codes in alpha-2 standard
    case 'LT':
      return getCountryConfig(c, LT);
    case 'LV':
      return getCountryConfig(c, LV);
    case 'CZ':
      return getCountryConfig(c, CZ);
    case 'RO':
      return getCountryConfig(c, RO);
    case 'DE':
      return getCountryConfig(c, DE);
    case 'SK':
      return getCountryConfig(c, SK);
    case 'PL':
      return getCountryConfig(c, PL);
    case 'MD':
      return getCountryConfig(c, MD);
    case 'EE':
      return getCountryConfig(c, EE);
    case 'HU':
      return getCountryConfig(c, HU);
    case 'IT':
      return getCountryConfig(c, IT);
    case 'US':
      return getCountryConfig(c, US);
    case 'CA':
      return getCountryConfig(c, CA);
    case 'GB':
      return getCountryConfig(c, GB);
    case 'ES':
      return getCountryConfig(c, ES);
    case 'FR':
      return getCountryConfig(c, FR);
    default:
      return getCountryConfig(c, BaseCountryConfig);
  }
}

<div [formGroup]="dateTimeGroup" class="services-list__form">
  <div class="form-row">
    <div (click)="picker.open()" class="form-col form-col-2">
      <mat-form-field class="mat-block">
        <input
          [formControl]="control"
          [matDatepicker]="picker"
          [max]="maxDate"
          [min]="minDate"
          matInput
          placeholder="Дата"
          readonly="true">
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AbstractFormContainer, getError } from '@shared';
import { errors, label, maxLength } from './delivery-additional-info.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-additional-info',
  templateUrl: './delivery-additional-info.component.html',
  styleUrls: ['./delivery-additional-info.component.scss'],
})
export class DeliveryAdditionalInfoComponent
  extends AbstractFormContainer
  implements OnInit
{
  getError = getError;

  errors = errors;

  label = label;

  @Input() controlName = 'AdditionalInformation';

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    const descriptionControl = new UntypedFormControl('', [
      Validators.maxLength(maxLength),
    ]);
    this.form.addControl(this.controlName, descriptionControl);
  }

  blockUnicode(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    const reg = new RegExp('[\u{FFFF}-\u{10FFFF}]', 'gu');
    if (reg.test(pastedText)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { CitySearchResult, ContactPerson, WarehouseResult } from '@models';
import {
  AbstractFormContainer,
  AddressSettings,
  cityResultMaskValidator,
  TemplateCitySearchResultMaskValidator,
  TemplateWarehouseMaskValidator,
  TypedFormControl,
  WarehouseMaskValidator,
} from '@shared';

import { debounceTime, distinctUntilChanged, map, merge, of, Subscription, switchMap, tap } from 'rxjs';
import { AddressService } from '../../../../../services/address.service';
import { ErrorMessages, ErrorTypeMessages, ValidationService } from '../../../../../services/validation.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'np-inv-address-postbox',
  templateUrl: 'address-postbox.component.html',
  styleUrls: ['./address-postbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressPostboxComponent extends AbstractFormContainer implements TypedFormControl, OnInit, OnDestroy {
  readonly type = 'Postbox';

  editForm: UntypedFormGroup;

  @HostBinding('attr.data-test-id') a = 'AddressTypeDoors';

  @Input()
  controlName = 'AddressSender';

  @Input()
  invoiceForm: UntypedFormGroup;

  @Input()
  templateValidation: boolean;

  @Input()
  controlTypeName = 'type';

  @Input()
  configCity: AutocompleteWithControlsConfig = {
    ariaLabel: 'Населений пункт',
    placeholder: 'Населений пункт',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configPostbox: AutocompleteWithControlsConfig = {
    ariaLabel: 'Поштомат',
    placeholder: 'Поштомат',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  cityOptions: Array<AutoCompleteOptionItem<CitySearchResult>> = [];

  postboxOptions: Array<AutoCompleteOptionItem<WarehouseResult>> = [];
  totalMaxWeight = 0;
  placeMaxWeight = 0;
  @Input()
  currentContact: ContactPerson;
  @Input()
  focused: boolean;
  @Input()
  latestCity: string | CitySearchResult;
  @Output()
  onCitySelected = new EventEmitter<CitySearchResult | string>();
  disablePostbox = false;
  tooltip: string;
  errorMessages: ErrorMessages = {};
  errorsSubscription: Subscription;
  errors: ErrorTypeMessages = {
    city: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'objectMask', message: 'Виберіть населений пункт' },
    ],
    postbox: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'objectMask', message: 'Виберіть поштомат' },
    ],
  };

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private addressService: AddressService,
    private validationService: ValidationService,
    private element: ElementRef,
  ) {
    super();
  }

  get deliveryForm(): AbstractControl {
    return this.form?.parent?.parent?.get('stepDeliveryForm');
  }

  get city(): CitySearchResult | string {
    return this.editForm.get('city').value;
  }

  set city(value: CitySearchResult | string) {
    this.editForm.get('city').setValue(value);
  }

  get cityControl(): AbstractControl | null {
    return this.editForm.get('city');
  }

  get warehouseControl(): AbstractControl | null {
    return this.editForm.get('postbox');
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.deliveryForm) {
      this.listenPlacesControl();
    }
    this.errorsSubscription = this.form.get(this.controlName).valueChanges.subscribe(() => {
      setTimeout(() => {
        if (this.focused) {
          this.element.nativeElement.querySelector('input').focus();
          this.focused = false;
        }
      });
      this.cd.detectChanges();
      this.errorMessages = this.validationService.getSingleErrorsObject(
        (this.form.get(this.controlName) as UntypedFormGroup).controls,
        this.errors,
      );
    });
    if (this.templateValidation) {
      Object.keys(this.form.get('data')['controls']).forEach((key) => {
        this.form.get('data')['controls'][key].clearValidators();
      });
      this.form.get(`${this.controlName}.city`).setValidators([TemplateCitySearchResultMaskValidator()]);
      this.form
        .get(`${this.controlName}.postbox`)
        .setValidators([Validators.required, TemplateWarehouseMaskValidator()]);
    }
    if (this.invoiceForm) {
      const stepDeliveryForm = this.invoiceForm.get('stepDeliveryForm');
      merge(of(stepDeliveryForm.value), stepDeliveryForm.valueChanges)
        .pipe(
          switchMap((value) => {
            if (value.CargoType === 'Cargo') {
              if (value.OptionsSeat && value.OptionsSeat.length > 1) {
                return of([true, 'Параметр місця, повинен бути один']);
              } else {
                return this.checkParamSeats();
              }
            } else {
              return of([false, '']);
            }
          }),
        )
        .subscribe(([value, tooltipText]) => this.togglePostbox(value, tooltipText));
    }
    this.warehouseControl.markAsPristine();
    this.warehouseControl.updateValueAndValidity();
  }

  checkParamSeats() {
    const paramsSeats = this.invoiceForm.get('stepDeliveryForm.paramsCommonSeats');
    const tooltipText =
      'Для можливості здійснення відправлення необхідно вказати габарити відправлення (Довжина х Ширина х Висота, см)';
    if (paramsSeats && paramsSeats.value) {
      this.togglePostbox(true, tooltipText);
      return paramsSeats.valueChanges.pipe(
        map((value) => {
          return [value, tooltipText];
        }),
      );
    }
    return of([false, '']);
  }

  togglePostbox(disable: boolean, tooltip: string): void {
    this.disablePostbox = disable;
    this.tooltip = tooltip;
    this.cd.markForCheck();
  }

  listenPlacesControl() {
    this.calculateMaxWeigth(this.deliveryForm.value);
    this.deliveryForm.valueChanges.subscribe((value) => {
      this.calculateMaxWeigth(value);
    });
  }

  calculateMaxWeigth(deliveryValue) {
    if (!deliveryValue.paramsCommonSeats && deliveryValue.OptionsSeat) {
      this.totalMaxWeight = Math.max(
        ...deliveryValue.OptionsSeat.map((place) => Number(place.weight)),
        ...deliveryValue.OptionsSeat.map((place) => Number(place.volumetricVolume)),
      );
      this.placeMaxWeight = Math.max(
        ...deliveryValue.OptionsSeat.map((place) => Number(place.weight)),
        ...deliveryValue.OptionsSeat.map((place) => Number(place.volumetricVolume)),
      );
    } else if (deliveryValue.commonParams) {
      this.totalMaxWeight = Math.max(
        Number(deliveryValue.commonParams.Weight),
        Number(deliveryValue.commonParams.VolumeWeight),
      );
      this.placeMaxWeight = 0;
    }
  }

  setEditValues(data: any) {
    this.addressService.getOneAddress(data.data.CityRef, this.currentContact.Ref).subscribe();
  }

  loadCities(nameQuery: string): void {
    this.addressService
      .getCities({ name: nameQuery })
      .pipe(tap((response) => (this.cityOptions = response.Addresses.map((city) => ({ option: city })))))
      .subscribe((cities) => this.cd.detectChanges());
  }

  loadPostboxes(cityRef: string): void {
    this.addressService
      .getWarehouses(cityRef, AddressSettings.POSTBOX_TYPE)
      .pipe(
        tap((warehouses) => {
          this.postboxOptions = warehouses.map((w) => {
            const config = this.getWarehouseConfig(w);
            return {
              option: w,
              config: {
                disabled: this.disablePostbox ? true : config.disabled,
                data: config.data,
                tooltip: this.tooltip ? this.tooltip : '',
              },
            };
          });
        }),
      )
      .subscribe((postboxes) => this.cd.detectChanges());
  }

  getWarehouseConfig(warehouse: WarehouseResult): { disabled; data } {
    let disable = false;
    let limitationText = '';
    const totalMaxWeightAllowed = Number(warehouse.TotalMaxWeightAllowed);
    const placeMaxWeightAllowed = Number(warehouse.PlaceMaxWeightAllowed);
    if (totalMaxWeightAllowed > 0 && totalMaxWeightAllowed < this.totalMaxWeight) {
      disable = true;
      limitationText = `Не більше ${totalMaxWeightAllowed} кг`;
    }
    if (placeMaxWeightAllowed > 0 && placeMaxWeightAllowed < this.totalMaxWeight) {
      disable = true;
      limitationText = `Не більше ${placeMaxWeightAllowed} кг`;
    }
    if (placeMaxWeightAllowed > 0 && placeMaxWeightAllowed < this.placeMaxWeight) {
      disable = true;
      limitationText = `Не більше ${placeMaxWeightAllowed} кг на місце`;
    }
    limitationText = !!this.tooltip ? '' : limitationText;
    return { disabled: disable, data: { limitationText } };
  }

  cityTransformFn: AutoCompleteTransformFn<CitySearchResult> = (value: CitySearchResult) => {
    if (value === null) {
      return '';
    }
    return typeof value === 'string' ? value : value.Present || '';
  };

  postboxTransformFn: AutoCompleteTransformFn<WarehouseResult> = (value: WarehouseResult) => {
    if (value === null) {
      return '';
    }
    return typeof value === 'string' ? value : value.Description || '';
  };

  postboxOptionsFilterFn: AutocompleteOptionsFilterFn<WarehouseResult> = (
    value: string | WarehouseResult,
    options: Array<AutoCompleteOptionItem<WarehouseResult>>,
  ) => {
    const format = (v) => v.replace(/[\s\+\-.,:!?#@'"()\[\]()'"-]+/g, '').toLowerCase();
    const group = (warehouse: WarehouseResult) =>
      `${warehouse.Description || ''}${warehouse.DescriptionRu || ''}`.trim();
    const v = format(typeof value === 'string' ? value : group(value));
    return options.filter((el) => format(group(el.option)).includes(v));
  };

  addFormControls() {
    if (this.form) {
      this.createControl();
    }
  }

  createControl(): void {
    this.editForm = this.fb.group({
      city: [this.latestCity ? this.latestCity : '', [Validators.required, cityResultMaskValidator()]],
      postbox: [
        {
          value: '',
          disabled: !this.latestCity,
        },
        [Validators.required, WarehouseMaskValidator()],
      ],
    });
    this.form.setControl(this.controlName, this.editForm);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.initFormSubscriptions();
  }

  initFormSubscriptions(): void {
    this.cityControl.statusChanges.pipe().subscribe((status) => {
      const cityValue = this.cityControl.value;
      if (status === 'VALID' && cityValue?.Ref) {
        this.onCitySelected.next(this.city);
        this.warehouseControl.enable();
        this.warehouseControl.markAsPristine();
        this.warehouseControl.updateValueAndValidity();
        if (!this.templateValidation) {
          this.configPostbox = { ...this.configPostbox, focused: true };
        }
        this.cd.detectChanges();
      } else {
        this.postboxOptions = [];
        this.warehouseControl.reset('');
        this.warehouseControl.disable();
      }
    });
    this.cityControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string' && value.length >= 2) {
        this.loadCities(value);
      } else {
        this.cityOptions = [];
      }
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });
  }

  onFocus(event) {
    this.loadPostboxes((this.city as CitySearchResult).DeliveryCity);
  }

  ngOnDestroy(): void {
    this.errorsSubscription.unsubscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged } from 'rxjs';
import { Subject } from 'rxjs';
import {
  AutocompleteControls,
  AutocompleteControlsItem,
  AutocompleteControlsTriggeredEvent,
} from '../autocomplete.interfaces';

@Component({
  selector: 'app-autocomplete-controls',
  templateUrl: 'autocomplete-controls.component.html',
  styleUrls: ['./autocomplete-controls.component.scss'],
})
export class AutocompleteControlsComponent implements OnInit {
  $configChanged = new Subject<AutocompleteControls>();

  _config: AutocompleteControls;

  @Input() config: AutocompleteControls;

  @Input() disabled = false;

  @Output() triggered = new EventEmitter<AutocompleteControlsTriggeredEvent>();

  get edit(): AutocompleteControlsItem {
    return this._config?.edit;
  }

  get clear(): AutocompleteControlsItem {
    return this.config?.clear;
  }

  get create(): AutocompleteControlsItem {
    return this.config?.create;
  }

  get delete(): AutocompleteControlsItem {
    return this.config?.delete;
  }

  ngOnInit(): void {
    this.$configChanged
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((config) => (this.config = config));
  }

  triggerControl(triggerName: keyof AutocompleteControls): void {
    this.triggered.emit({ triggerName });
  }
}

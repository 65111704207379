import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { AvailableServices } from '@models';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  AvailableServiceNames,
  availableServices,
  COUNTERPARTY_OPTIONS_PROPS,
} from '../../components/invoice-form/components/services/availableServices';

@Injectable()
export class AdditionalServicesService {
  controlName: string;
  form: UntypedFormGroup;
  AvailableServices: ReplaySubject<AvailableServices> = new ReplaySubject<AvailableServices>(1);
  AvailableServices$ = this.AvailableServices.asObservable();
  readonly counterpartyOptionsProps = COUNTERPARTY_OPTIONS_PROPS;

  localExpress: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  localExpressTimeInterval: BehaviorSubject<string | number> = new BehaviorSubject<string | number>('');
  localExpress$ = this.localExpress.asObservable();
  localExpressTimeInterval$ = this.localExpressTimeInterval.asObservable();
  availableServices = availableServices;

  get servicesGroup(): UntypedFormGroup {
    return this.form.get(this.controlName) as UntypedFormGroup;
  }

  get DateTime(): string {
    return this.servicesGroup.get('dateTime').value.DateTime;
  }

  availableByGetCounterpartyOptions(serviceName: AvailableServiceNames): boolean {
    return (
      this.availableServices[this.counterpartyOptionsProps[serviceName]] || !this.counterpartyOptionsProps[serviceName]
    );
  }

  serviceSelected(name: AvailableServiceNames): boolean {
    return this.servicesGroup.get(name).value.selected;
  }

  toggleSelected(controlName: AvailableServiceNames, value: boolean, emitEvent: boolean = true): void {
    this.servicesGroup.get(controlName).get('selected').setValue(value, { emitEvent });
  }

  formGroup(name: AvailableServiceNames): FormGroup {
    return this.servicesGroup.get(name) as FormGroup;
  }
}

<div class="form-col" [ngClass]="'form-col-' + size">
  <mat-form-field class="mat-block" [class.mat-block_select-arrow]="config.arrow">
    <input type="text"
           #input
           matInput
           [matAutofocus]="!config.focused"
           [formControl]="formControl"
           [readonly]="readonly"
           [matAutocomplete]="auto"
           [placeholder]="config.placeholder"
           [attr.aria-label]="config.ariaLabel"
           [errorStateMatcher]="config.errorStateMatcher"
           [keyInputPrevent]="preventInput"
           [class.pointer]="preventInput && !disabled"
           (focus)="onFocus($event)"
           (blur)="touchFn()"
           (keypress)="onInput.emit($event)"
           autoScrollTarget>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="transformFn" (optionSelected)="onSelected($event)">
      <app-dropdown-search *ngIf="dropdownSearchEnabled"
                           (onTextInput)="sendDropdownSearchValue($event)"
                           [searching]="dropdownSearching"></app-dropdown-search>
      <mat-option *ngIf="dropdownSearchEnabled && !options.length" disabled>Нічого не знайдено</mat-option>
      <mat-option *ngFor="let optionItem of options | filter_options:optionsFilterFn:input.value"
                  [value]="optionItem.option"
                  [matTooltip]="optionItem?.config?.tooltip"
                  matTooltipPosition="below"
                  [disabled]="optionItem?.config?.disabled">
        <ng-container
          *ngTemplateOutlet="autocompleteOptionTemplate; context: {$implicit: optionItem.option, disabled: optionItem?.config?.disabled ? optionItem?.config?.disabled : false, data: optionItem?.config?.data }"></ng-container>
      </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="config.hint">
      {{ config.hint }}
    </mat-hint>
  </mat-form-field>
  <ng-content select="app-autocomplete-error"></ng-content>
</div>
<app-autocomplete-controls [disabled]="disabled || readonly"
                           [config]="controlsConfig"
                           [intertationalInv]="intertationalInv"
                           [stylesCompat]="stylesCompat"
                           (onTriggered)="triggerControl($event)"></app-autocomplete-controls>

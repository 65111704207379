<mat-form-field [attr.data-test-id]="testId"
                class="form-input">
  <mat-label>{{label}}</mat-label>

  <input [formControl]="formControl"
         [matAutocomplete]="cityAutocomplete"
         [maxLength]="maxLength"
         (focus)="initCitySubscription()"
         [placeholder]="placeholder"
         matInput
         type="text"
         class="input"
         autocomplete="off"
         autoScrollTarget>

  <button *ngIf="!city?.value && !citiesLoading && cities?.length"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
  </button>

  <button *ngIf="clearEnabled && city?.value && !citiesLoading"
          (click)="clearField(city, $event, '')"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear">
    <mat-icon>close</mat-icon>
  </button>

  <button *ngIf="citiesLoading"
          class="p-0 transform"
          mat-button
          matSuffix
          mat-icon-button
          [disabled]="true">
    <mat-spinner [diameter]="19.5" class="form-field-spinner"></mat-spinner>
  </button>

  <mat-autocomplete #cityAutocomplete="matAutocomplete"
                    [displayWith]="getCityDescription">
    <mat-option *ngFor="let city of cities"
                [value]="city.option"
                [disabled]="city.disabled"
                [matTooltip]="city.tooltip">
      {{city.option.Present}}
    </mat-option>
  </mat-autocomplete>

  <mat-error>{{getError(city, CityErrors)}}</mat-error>
</mat-form-field>

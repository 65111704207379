import { AbstractControl } from '@angular/forms';

export function getError(
  control: AbstractControl,
  errorsDictionary: object,
): string {
  const keys = Object.keys(errorsDictionary);
  const activeError = keys.filter(
    (k) => control && control.errors && !!control.errors[k],
  )[0];
  return (
    errorsDictionary[activeError] ||
    (activeError &&
      control &&
      control.errors &&
      control.errors[activeError].value) ||
    ''
  );
}

export function getErrorWithArgs(
  control: AbstractControl,
  errorsDictionary: object,
  args: object
): string {
  const keys = Object.keys(errorsDictionary);
  const activeError = keys.filter(
    (k) => control && control.errors && !!control.errors[k],
  )[0];
  return (
    (args[activeError] >= 0 ? errorsDictionary[activeError](args[activeError]) : errorsDictionary[activeError]) ||
    (activeError &&
      control &&
      control.errors &&
      control.errors[activeError].value) ||
    ''
  );
}

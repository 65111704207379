import {ChangeDetectorRef, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ChangeDetectionUpdateService {

  private changeDetectorRefs: ChangeDetectorRef[] = [];

  registerChangeDetectionRef(cd: ChangeDetectorRef) {
    this.changeDetectorRefs.push(cd);
  }

  updateChangeDetectionRefs() {
    for (const cd of this.changeDetectorRefs) {
      if (cd) {
        cd.detectChanges();
      }
    }
  }
}

import { GetCountriesData } from '@shared';
import { LatinLettersTooltip } from '../components/addresses/city-select-international/city-select.constants';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';

// Italy
export const IT: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
  CityTooltip: LatinLettersTooltip,
  RecipientPostboxAvailable: true,
  RecipientSpotSelectionAvailable: true,
};

import { CardTypes } from '@shared';

export interface Banner {
  title: string;
  text: string;
  visibleFor: CardTypes[] | [];
}

export const Banners: Banner[] = [
  {
    title: 'Подовжуйте термін зберігання посилок',
    text: 'Термін зберігання у відділенні посилок з послугою «Автоматичне повернення відправлень» можна подовжувати. ' +
      'Деталі за' +
      ' <a href="https://novapost.com/uk-ua/additional-services/auto-return" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Поштомат на території вашого бізнесу за 1 грн',
    text: 'Замовляйте встановлення поштомата на території вашого бізнесу всього за 1 грн. Оптимізуйте витрати на доставку та залучайте нових клієнтів. Деталі за' +
      ' <a href="https://novaposhta.ua/poshtomat/form/" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Бажаєте збільшити продажі та залучити нових клієнтів?',
    text: 'Розміщуйте акційні пропозиції у наших каналах. Деталі за' +
      ' <a href="https://novapost.com/uk-ua/for-business/promotional-integrations/?utm_source=BC&utm_medium=cpc&utm_campaign=npu_promo_07" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Безплатне переадресування посилок по Україні',
    text: 'Змінюйте місце й адресу доставки, поки відправлення ще не прибуло. Деталі' +
      ' <a href="https://novaposhta.ua/pereadresatsiya_im?utm_source=BK&utm_medium=Banner&utm_campaign=Pereadresatsiya" target="_blank">тут</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Відкрийте рахунок ФОП від NovaPay',
    text: 'Отримайте легкий доступ до своїх грошей. Деталі за' +
      ' <a href="https://novapay.ua/rakhunok-fop/?utm_source=business&utm_medium=banner&utm_campaign=seamless" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Послуга «Вантажні перевезення по Україні» – швидка та безпечна доставка ваших вантажів від 10 до 20 тонн окремим авто',
    text: 'Тепер з можливістю оплати готівкою. Деталі за' +
      ' <a href="https://novaposhta.ua/biznes_klientam/vantazhni_perevezennya/?utm_source=2b&utm_medium=referral&utm_campaign=npu_business" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Міжнародні фрахти для бізнесу',
    text: 'Швидко та безпечно доставимо ваш вантаж між країнами Європи та Україною. Вартість перевезення: від 1€/км. Залиште заявку за' +
      ' <a href="https://docs.google.com/forms/d/e/1FAIpQLSczezftcY9377yTNnfYkqSLpF3btk0yQMom2bm75aXq_d28YQ/viewform?usp=sf_link " target="_blank">посиланням</a>' + ', і ми надамо пропозицію спеціально для вашого бізнесу',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Хочеш відправляти безплатно в Польщу?',
    text: 'Реєструйся на KASTA та продавай товари в Польщу, а Нова пошта безплатно доставить їх від 3 днів! Деталі' +
      ' <a href="https://kst.im/ODXdfDK4oDb" target="_blank">тут</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Поштомат для бізнес-клієнтів',
    text: 'Безпечно відправляйте та отримуйте відправлення. Деталі <a href="https://novaposhta.ua/send_from_postomat" target="_blank">тут</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },

  {
    title: 'Пакуйте у себе, як на відділенні',
    text: 'Якісне пакування за оптовими цінами за' +
      ' <a href="https://shop.novaposhta.ua" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.ROZNIZA, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Хочете масштабувати свій бізнес? Навчайтесь у Школі бізнесу Нова пошта!',
    text: 'Отримайте практичні знання та розробіть план розвитку власного бізнесу. Деталі за' +
      ' <a href="https://novaposhta.education/"' +
      ' target="_blank">посиланням</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Ми запустили послугу Міжнародної доставки до Польщі для вашого бізнесу',
    text: 'Бажаєте дізнатись детальніше, переходьте за' +
      ' <a href="https://url.novaposhta.ua/TTrXz7" target="_blank">посиланням</a>',
    visibleFor: [],
  },
  {
    title: 'Запропонуйте клієнтам Посилку в кредит',
    text: 'Продавайте в кредит будь-які товари з післяплатою, вартістю від 1000 грн. Деталі за' +
      ' <a href="https://novapay.ua/credit" target="_blank">посиланням</a>',
    visibleFor: [CardTypes.ROZNIZA, CardTypes.CCARD],
  },
  {
    title: 'Послуги для вашого бізнесу',
    text: 'Продавайте легко, а про складські операції, подбаємо ми. Цікаві умови?' +
      ' <a href="https://docs.google.com/forms/d/e/1FAIpQLSfTpCdJS9c70flQF4ujfdZw4bw93x_T6RHHlC8_CuN_61X37g/viewform?usp=sf_link"' +
      ' target="_blank">Заповніть анкету</a>',
    visibleFor: [CardTypes.CCARD, CardTypes.IDENTIFICATION, CardTypes.CORPORATE, CardTypes.ROZNIZA],
  },
  {
    title: 'Зауваження та пропозиції',
    text: 'Пропозиції щодо покращення роботи бізнес-кабінету надсилайте на' +
      ' <a href="mailto:support@novaposhta.ua">support@novaposhta.ua</a> або скористайтесь чатом з оператором',
    visibleFor: [CardTypes.ROZNIZA, CardTypes.CCARD, CardTypes.IDENTIFICATION, CardTypes.CORPORATE],
  },
  {
    title: 'Оновлення для корпоративних клієнтів з підпорядкованими організаціями',
    text: 'Керуйте даними за своїми організаціями ще зручніше! Детальніше за' +
      ' <a href="https://static.novaposhta.ua/sitecard/misc/doc/new_in_bc.pdf" target="_blank">посиланням</a>',
    visibleFor: [],
  },
];

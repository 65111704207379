/**
 * Created by andrey on 03.07.16.
 */

export interface IModalMeta {
  data: any;
  isFullLoaded: boolean;
  updateFunctionArray: any[];
}

export interface IModel {
  id: string;
  $uid: string;

  isFullLoaded(): boolean;

  setFullLoaded(flag: boolean): this;

  getMetaData(key: string): any;

  setMetaData(key: string, data);

  loadMore();

  addUpdateFunction(funct: (model: IModel) => void);
}

export class Model implements IModel {
  public id: string;
  public $uid: string;

  protected _meta: IModalMeta = {
    data: {},
    isFullLoaded: false,
    updateFunctionArray: [],
  };

  protected _fullLoadStarted = false;

  constructor(id: string = null) {
    if (id) {
      this.id = id;
      this.$uid = id;
    } else {
      this.$uid = Math.random().toString();
    }
  }

  isFullLoaded(): boolean {
    return this._meta.isFullLoaded;
  }

  setFullLoaded(isFullLoaded: boolean): this {
    this._meta.isFullLoaded = isFullLoaded;

    return this;
  }

  getMetaData(key: string): any {
    return this._meta.data[key];
  }

  setMetaData(key: string, data): this {
    this._meta.data[key] = data;

    return this;
  }

  loadMore() {
    /* if (!this._fullLoadStarted) {
        this._fullLoadStarted = true;
        for (let funct of this._meta.updateFunctionArray) {
            funct(this);
        }
    } */
  }

  addUpdateFunction(funct: (model: IModel) => void) {
    this._meta.updateFunctionArray.push(funct);
  }
}

<mat-form-field [attr.data-test-id]="testId"
                class="form-input">
  <mat-label>{{label}}</mat-label>

  <input [formControl]="formControl"
         [matAutocomplete]="auto"
         [maxLength]="maxLength"
         (focus)="initStreetSubscription()"
         [placeholder]="placeholder"
         matInput
         type="text"
         class="input"
         autocomplete="off"
         autoScrollTarget>

  <button *ngIf="!street?.value && !loading && streets?.length"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
  </button>

  <button *ngIf="clearEnabled && street?.value && !loading"
          (click)="clearField(street, $event, '')"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear">
    <mat-icon>close</mat-icon>
  </button>

  <button *ngIf="loading"
          class="p-0 transform"
          mat-button
          matSuffix
          mat-icon-button
          [disabled]="true">
    <mat-spinner [diameter]="19.5" class="form-field-spinner"></mat-spinner>
  </button>

  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="getStreetDescription">
    <mat-option *ngFor="let street of streets"
                [value]="street">
      {{street.Present}}
    </mat-option>
  </mat-autocomplete>

  <mat-error>{{getError(street, StreetErrors)}}</mat-error>
</mat-form-field>

export interface IApiError {
  messageCode: string;
  errorCode: string;
  message?: string;
  addInfo?: any;
  translatedError?: string;
}

export interface IListData<T> {
  list: T[];
  total: number;
}

export class ApiHelper {
  transformServerErrorToFormError(formErrroConfig: any, serverErrors: IApiError[]) {
    const errorsHashObject = {};
    const newHashObject = {};
    const formErrors = {};

    for (const item of serverErrors) {
      errorsHashObject[item.errorCode] = item;
    }

    for (const key in formErrroConfig) {
      if (formErrors[key]) {
        formErrors[key] = [];
        for (const item of formErrroConfig[key]) {
          if (errorsHashObject[item]) {
            formErrors[key].push(errorsHashObject[item]);
            newHashObject[item] = errorsHashObject[item];
          }
        }
      }
    }

    return {formErrors, hashedErrors: newHashObject};
  }
}

<div [formGroup]="form"
     cdkScrollable
     class="filter"
     mat-dialog-content>
  <div class="filter-content">
    <app-table-filter-menu-header [title]="config.title"></app-table-filter-menu-header>

    <!--    accordion animation is disabled because of jump content dialog close https://github.com/angular/components/issues/11765-->
    <mat-accordion [@.disabled]="true" class="accordion" multi>
      <mat-expansion-panel *ngFor="let group of config.filterGroups"
                           [attr.data-test-id]="group.testId"
                           [expanded]="group.expandedByDefault">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ group.title }}
            <span *ngIf="changedControls[group.controlName]"
                  class="counter">({{ changedControls[group.controlName] }})</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div [formGroupName]="group.controlName" class="mt-6 mb-6">
            <ng-container *ngFor="let filter of group.filters"
                          [ngSwitch]="filter.type">
              <div *ngSwitchCase="'input'">
                <app-input-filter [config]="filter"
                                  [controlName]="filter.controlName"
                                  [form]="getGroup(group.controlName)"></app-input-filter>
              </div>
              <div *ngSwitchCase="'radio'">
                <app-radio-filter [config]="filter"
                                  [controlName]="filter.controlName"
                                  [form]="getGroup(group.controlName)"></app-radio-filter>
              </div>
              <div *ngSwitchCase="'date'">
                <app-date-filter [config]="filter"
                                 [controlName]="filter.controlName"
                                 [form]="getGroup(group.controlName)"></app-date-filter>
              </div>
              <div *ngSwitchCase="'double-date'">
                <app-double-date-filter [config]="filter"
                                        [form]="getGroup(group.controlName).get(filter.controlName)"></app-double-date-filter>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <app-table-filter-menu-footer (filtersApplied)="applyFilterForm()"
                                (filtersCleared)="clearFilters()"
                                [config]="config"
                                [isDirty]="isDirty"
                                [isFiltered]="filtered$ | async"
                                [isLoading]="isLoading"
                                [isValid]="form.valid"></app-table-filter-menu-footer>
</div>

<div class="form-row mr-bottom-16" [formGroup]="privatePersonFormGroup">
  <div class="form-col form-col-2">
    <np-phone-input
      [isValid]="privatePersonFormGroup.controls.phone.valid"
      formControlName="phone"
      placeholder="Телефон"
      [formControlParent]="privatePersonFormGroup.get('phone')"
      (change)="onChangePhone($event)"
      [readonly]="disablePhoneInput">
        <span
          *ngIf="!!errorMessages['phone'] && (privatePersonFormGroup.get('phone').touched || privatePersonFormGroup.get('phone').dirty)">
          <i class="material-icons mat-select-error__icon">error_outline</i> {{errorMessages['phone']}}
        </span>

    </np-phone-input>
  </div>
  <div class="form-col form-col-2">
    <mat-form-field class="mat-block">
      <input type="text" placeholder="Прізвище" #lastNameInput
             class="text-capitalize"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             formControlName="lastName" matInput [matAutocomplete]="auto"
             [errorStateMatcher]="matcher">
      <mat-autocomplete #auto="matAutocomplete">
        <!--<mat-option *ngFor="let option of contactOptions" [value]="option" (onSelectionChange)="setContact($event)">-->
        <!--<div class="option-field option-field_sm">-->
        <!--<div class="option-field__hint">{{option.Counterparty}}</div>-->
        <!--<div class="option-field__text">{{option.Description}} {{getFormattedPhone(option.Phones)}}</div>-->
        <!--</div>-->
        <!--</mat-option>-->
        <mat-option *ngFor="let option of catalogOptions" [value]="option"
                    (onSelectionChange)="setContactFromCatalog($event)">
          <div class="option-field option-field_sm">
            <div class="option-field__hint">{{option.Description}}</div>
            <div class="option-field__text">{{option.LastName}} {{option.FirstName}} {{option.MiddleName}}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="!!errorMessages['lastName'] && (form.get(controlName).get('lastName').touched || form.get(controlName).get('lastName').dirty)">
        <i class="material-icons mat-select-error__icon">error_outline</i>
        {{errorMessages['lastName']}}
      </mat-error>
    </mat-form-field>

  </div>
  <div class="form-col form-col-2">
    <mat-form-field class="mat-block">
      <input matInput placeholder="Ім’я"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             class="text-capitalize"
             formControlName="firstName"
             [errorStateMatcher]="matcher">
      <mat-error
        *ngIf="!!errorMessages['firstName'] && (form.get(controlName).get('firstName').touched || form.get(controlName).get('firstName').dirty)">
        <i class="material-icons mat-select-error__icon">error_outline</i>
        {{errorMessages['firstName']}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="form-col form-col-2 box-with-controls" [ngClass]="{'last-col' : IsEnabledCancel}">
    <mat-form-field class="mat-block">
      <input matInput placeholder="По батькові"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             class="text-capitalize"
             formControlName="middleName"
             [errorStateMatcher]="matcher">
      <mat-error
        *ngIf="!!errorMessages['middleName'] && (form.get(controlName).get('middleName').touched || form.get(controlName).get('middleName').dirty)">
        <i class="material-icons mat-select-error__icon">error_outline</i>
        {{errorMessages['middleName']}}
      </mat-error>
    </mat-form-field>
    <div class="control-icons" *ngIf="IsEnabledCancel">
      <div class="control-icons__item">
        <button mat-icon-button (click)="cancel()">
          <mat-icon class="material-icons_grey">
            cancel
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

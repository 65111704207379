import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AlertToastService } from '@services';
import { FormContainer, getError } from '@shared';
import { finalize } from 'rxjs/operators';
import { InvoiceApiService } from '../../../../../services/invoices/invoice-api.service';

@Component({
  selector: 'app-delivery-third-person',
  templateUrl: './delivery-third-person.component.html',
  styleUrls: ['./delivery-third-person.component.scss'],
})
export class DeliveryThirdPersonComponent
  extends FormContainer
  implements OnInit, OnDestroy {
  @Input() controlName = 'ThirdPerson';

  getError = getError;

  edrpou: UntypedFormControl;

  pending = false;

  errors = {
    required: 'Це поле обов\'язкове',
    minlength: 'Має містити 8-10 цифр',
    maxlength: 'Має містити 8-10 цифр',
  };

  get ThirdPersonDescription(): UntypedFormControl {
    return this.form?.get('ThirdPersonDescription') as UntypedFormControl;
  }

  get ThirdPerson(): UntypedFormControl {
    return this.form?.get('ThirdPerson') as UntypedFormControl;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private invoiceApiService: InvoiceApiService,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  initControl(): void {
    this.edrpou = this.fb.control('', [
      Validators.minLength(8),
      Validators.maxLength(10),
      Validators.required,
    ]);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initControl();
  }

  validateEdrpou(value: string): void {
    this.pending = true;
    this.invoiceApiService
      .addByEdpu(value)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.markForCheck();
        }),
      )
      .subscribe(
        (data) => {
          this.ThirdPersonDescription.setValue(data.Description);
          this.ThirdPerson.setValue(data.Ref);
        },
        () => this.alertToastService.pushError('ЄДРПОУ вказано некоректно'),
      );
  }

  closeModal(): void {
    this.ThirdPerson.reset();
    this.ThirdPersonDescription.reset();
    this.edrpou.reset();
    this.edrpou.markAsUntouched();
    this.edrpou.markAsPristine();
  }

  toggleModal(): void {
    this.form.get('PayerType').setValue('Recipient');
  }

  addFormControls(): void {
    this.form.addControl(
      'ThirdPerson',
      this.fb.control('', [Validators.required]),
    );
    this.form.addControl(
      'ThirdPersonDescription',
      this.fb.control('', [Validators.required]),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form.removeControl('ThirdPerson');
    this.form.removeControl('ThirdPersonDescription');
    this.edrpou.reset();
    this.edrpou.markAsUntouched();
    this.edrpou.markAsPristine();
  }
}

import { Directive, ElementRef, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[capitalizeWithDash]',
})
export class CapitalizeWithDashDirective {

  constructor(private el: ElementRef, @Self() @Optional() private ngControl: NgControl) {
  }

  @HostListener('keyup', ['$event']) onKeyUp() {
    const position = this.el.nativeElement.selectionStart;
    const value = this.el.nativeElement.value.split(' ').map((word: string) => {
      return word.indexOf('-') > 0
        ? word.split('-').map((w: string): string => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join('-')
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
    if (this.ngControl) {
      this.ngControl.control.setValue(value);
    }
    this.el.nativeElement.value = value;
    this.el.nativeElement.selectionEnd = position;
  }

}

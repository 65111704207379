import {
  AutocompleteControls,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { TypeRadioButton } from '@type-radio-group';
import { AddressType, AddressTypes } from '@models';

export const CONTROL_NAME = 'AddressRecipient';
export const CONTROL_TYPE_NAME = 'type';
export const AUTOCOMPLETE_CONFIG: AutocompleteWithControlsConfig = {
  ariaLabel: 'Адреса отримувача',
  placeholder: 'Адреса',
  arrow: true,
};
export const AUTOCOMPLETE_CONTROLS_CONFIG: AutocompleteControls = {
  create: {
    enabled: true,
    tooltip: 'Вказати нову адресу',
  },
};
export const ADDRESS_TYPES: Array<TypeRadioButton<AddressType>> = [
  {
    value: AddressTypes.WAREHOUSE,
    label: 'Відділення',
  },
  {
    value: AddressTypes.DOORS,
    label: 'Адреса',
  },
  {
    value: AddressTypes.POSTBOX,
    label: 'Поштомат',
  },
  {
    value: AddressTypes.INDEX,
    label: 'Цифрова адреса',
    tooltip:
      'Цифрова адреса — це унікальний числовий номер, який ідентифікує усі пункти видачі Нової пошти (відділення, поштомат)',
  },
];

import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { getError } from '@shared';
import { AutocompleteErrors } from '../../errors/error-messages';
import {
  DEFAULT_AUTOCOMPLETE_SIZE,
  DefaultConfig,
  DefaultControlsConfig,
  OPTIONS_TO_ENABLE_SEARCH,
  SEARCH_DEBOUNCE_TIME,
  SEARCH_MAX_LENGTH,
} from './autocomplete.constants';
import {
  AutocompleteConfig,
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent,
  AutoCompleteOptionItem,
} from './autocomplete.interfaces';
import { AutoCompleteTransformFn } from './autocomplete.types';

@Component({
  selector: 'app-autocomplete',
  templateUrl: 'autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'overlay-autocomplete' },
    },
  ],
})
export class AutocompleteComponent implements ControlValueAccessor {
  transformedValue = '';

  @Input() errors: Record<string, string> = AutocompleteErrors;

  getError = getError;

  @Input() formControlParent;

  @Input() searching = false;

  @Input() pointer = true;

  @Input() searchPlaceholder: string = 'Пошук';

  private _searchEnabled: boolean = false;

  @Input() set searchEnabled(val: boolean) {
    this._searchEnabled = val;
    this.searchToggled = true;
  }

  get searchEnabled(): boolean {
    return this._searchEnabled;
  }

  @Input() formControl: UntypedFormControl = new UntypedFormControl();

  @Output() textInput: EventEmitter<any> = new EventEmitter();

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Input() disabled: boolean = false;

  @Input() panelWidth: number | string = null;

  @HostBinding('class.box-with-controls') hasControls = false;

  @Input() size = DEFAULT_AUTOCOMPLETE_SIZE;

  @Input() searchDebounce = SEARCH_DEBOUNCE_TIME;

  @Input() searchMaxLength: number = SEARCH_MAX_LENGTH;

  @Input() readonly = false;

  @Input() preventInput = false;

  @Input() displayTransformFn: AutoCompleteTransformFn<any> = null;

  @Output() controlTriggered: EventEmitter<AutocompleteControlsTriggeredEvent> =
    new EventEmitter<AutocompleteControlsTriggeredEvent>();

  @Output() focused: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  @Output() inputChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatAutocomplete) readonly matAutocomplete: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  readonly matAutocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild(MatInput, { read: MatInput, static: false })
  readonly matInput: MatInput;

  @ViewChild('input', { read: ElementRef, static: false }) input: ElementRef;

  private _controlsConfig: AutocompleteControls = DefaultControlsConfig;

  get controlsConfig(): AutocompleteControls {
    return this._controlsConfig;
  }

  @Input()
  set controlsConfig(value: AutocompleteControls) {
    this._controlsConfig = value;
    this.hasControls = !!this._controlsConfig;
  }

  private _options: Array<AutoCompleteOptionItem<any>> = [];

  get options(): Array<AutoCompleteOptionItem<any>> {
    return this._options;
  }

  @Input()
  set options(value: Array<AutoCompleteOptionItem<any>>) {
    if (!value) {
      this._options = [];
      return;
    }
    if (!Array.isArray(value)) {
      throw new Error('Options must be an array!');
    }
    this._options = value;
  }

  private _config: AutocompleteConfig = DefaultConfig;

  get config(): AutocompleteConfig {
    return this._config;
  }

  @Input()
  set config(value: AutocompleteConfig) {
    this._config = Object.assign({}, DefaultConfig, value);
  }

  sendDropdownSearchValue(value: string): void {
    this.search.emit(value);
  }

  @Input() optionsToEnableSearch = OPTIONS_TO_ENABLE_SEARCH;

  searchToggled: boolean = false;

  panelOpened(): void {
    if (this.searchToggled) {
      return;
    }
    this.searchEnabled = this.options?.length >= this.optionsToEnableSearch;
    this.searchToggled = true;
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.transformedValue = event.option.value || '';
    this.valueSelected.emit(this.transformedValue);
    this.input.nativeElement.blur();
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.transformedValue = this.transform(obj);
    this.writeValueToControl(obj);
  }

  transform(obj: any): any {
    if (typeof obj === 'string') {
      return obj;
    }
    return this.displayTransformFn ? this.displayTransformFn(obj) : obj;
  }

  triggerControl(event: AutocompleteControlsTriggeredEvent): void {
    this.controlTriggered.emit(event);
  }

  onFocus(event): void {
    this.focused.next(event);
  }

  sendInputValue(event: InputEvent): void {
    this.inputChanged.emit((event.target as HTMLInputElement).value);
  }

  private writeValueToControl(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IApiError, InvoiceResponseModel, User } from '@models';
import { AlertToastService, AuthService, GoogleAnalyticService } from '@services';
import { TableComponent } from '@shared';
import { LocalStorageService } from 'ngx-webstorage';
import { catchError, filter, finalize, of, Subscription, take } from 'rxjs';
import Rxmq, { Channel } from 'rxmq';
import { InvoiceRegister } from '../../../../models/invoice/invoice-register.model';
import { Invoice } from '../../../../models/invoice/invoice.model';
import { InvoiceApiService } from '../../../../services/invoices/invoice-api.service';
import { InvoiceService } from '../../../../services/invoices/invoice.service';
import { InvoiceRegisterApiService } from '../../../../services/registers/invoice-register.api.service';
import { EVENT_NAMES } from '../../../constants';
import { AnalyticEvent } from '../../../interfaces/analytic-event';
import { PrintingSettingComponent } from '../../pringitng-setting/printing-setting.component';
import { RegisterRenameComponent } from '../register-rename/register-rename.component';
import { DisplayedColumns } from '../registers.constants';
import { RegistersDatasource } from '../registers.datasource';

const componentName = 'app-registers';

@Component({
  selector: 'app-registers-view',
  templateUrl: './registers-view.component.html',
  styleUrls: [
    './registers-view.component.scss',
    '../../../../../styles/mat-override/mat-table.common.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class RegistersViewComponent
  extends TableComponent<InvoiceRegister, RegistersDatasource>
  implements OnInit, OnChanges, OnDestroy {
  dataSource: RegistersDatasource;

  displayedColumns = DisplayedColumns;

  disableColumns: any[];

  expandedElement: InvoiceRegister | null;

  storageKey: string = componentName;

  selection = new SelectionModel<InvoiceRegister>(true, []);

  user: User;

  printingm100x100: boolean = false;
  printLoad: boolean = false;
  invoices: Invoice[];
  isPrinting: boolean = false;

  @Input()
  customRegisters: InvoiceRegister[];

  constructor(
    private alertToastService: AlertToastService,
    protected storage: LocalStorageService,
    public router: Router,
    public invoiceApiService: InvoiceApiService,
    public invoiceRegisterService: InvoiceRegisterApiService,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
    protected authService: AuthService,
    protected googleAnalyticsService: GoogleAnalyticService,
    public invoiceService: InvoiceService,
  ) {
    super();
  }

  private _subscriptions: Subscription[] = [];

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(subscription: any) {
    this._subscriptions.push(subscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDataSource();
    this.loadUser();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  loadUser(): void {
    this.user = this.authService.user;
  }

  initDataSource(): void {
    this.dataSource = new RegistersDatasource(
      this.invoiceRegisterService,
      this.invoiceService,
    );
    if (this.customRegisters) {
      this.dataSource.loadCustomRegistries(this.customRegisters);
    } else {
      this.dataSource.loadRegistries(null, null, 0, this.itemsPerPage);
    }
  }

  update(): void {
    this.selection.clear();
    if (this.customRegisters) {
      this.dataSource.loadCustomRegistries(this.customRegisters);
    } else {
      this.dataSource.loadRegistries(
        null,
        null,
        this.paginator.pageIndex,
        this.itemsPerPage,
      );
    }
  }

  show(register: InvoiceRegister): void {
    this.router.navigate(['registries_show'], {
      state: {
        idRegistries: register.id,
      },
    });

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.show`)
      .next({
        eventLabel: `Показати конкретний реєстр: ${register.id}`,
        eventCategory: componentName,
        eventAction: 'click',
      });
  }

  rename(register: InvoiceRegister): void {
    const renameDialog = this.dialog.open(RegisterRenameComponent, {
      data: register,
      panelClass: 'no-padding',
      autoFocus: false,
      minWidth: '440px',
      disableClose: true,
    });

    renameDialog.afterClosed().pipe(take(1)).subscribe((result: { success?: boolean, description?: string }) => {
      if (result.success && result.description) {
        register.description = result.description;
        this.cd.detectChanges();
      }
    });
  }

  remove(id: string): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.remove`)
      .next({
        eventLabel: `Видалити реєстр`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    this.subscriptions = this.invoiceRegisterService.remove(id)
      .pipe(
        catchError((errors) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.remove.error`)
            .next({
              eventLabel: `Видалити реєстр: ${errors[0]?.messageCode
              }`,
              eventCategory: componentName,
              eventAction: 'remove.error',
            });
          return of(false);
        }),
        filter((data) => !!data),
        finalize(() => this.update()),
      )
      .subscribe(
        (data) => {
          if (data?.ScanSheetRefs?.Errors?.length) {
            this.alertToastService.pushRegisterRemoveApiErrors(
              Array.from(data?.ScanSheetRefs?.Errors),
              'Помилка розформування',
            );
            this.googleAnalyticsService.track(
              'analitics',
              `${componentName}.remove.error`,
              {
                eventLabel: `Помилка видалення реєстру`,
                eventCategory: componentName,
                eventAction: 'remove.error',
              },
            );
          } else {
            this.alertToastService.pushSuccess('Реєстр успішно розформовано!');
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.remove.success`)
              .next({
                eventLabel: `Видалити реєстр`,
                eventCategory: componentName,
                eventAction: 'remove.success',
              });
          }
        },
      );
  }

  removeSelected(): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.removeSelected`)
      .next({
        eventLabel: `Видалити вибрані реєстри`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    if (!this.selection.hasValue()) {
      return;
    }

    const { selected } = this.selection;
    const ids = selected.map((r) => r.id);

    this.subscriptions = this.invoiceRegisterService.removeList(ids).subscribe(
      () => {
        this.update();

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.removeSelected.success`)
          .next({
            eventLabel: `Видалити вибрані реєстри ${ids}`,
            eventCategory: componentName,
            eventAction: 'removeSelected.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.removeSelected.error`)
          .next({
            eventLabel: `Видалити вибрані реєстри: ${errors[0]?.messageCode}`,
            eventCategory: componentName,
            eventAction: 'removeSelected.error',
          });
      },
    );
  }

  printSettings(invoices, value?): void {
    const dialogRef = this.dialog.open(PrintingSettingComponent, {
      panelClass: 'no-padding',
    });
    if (invoices) {
      dialogRef.componentInstance.invoiceIds = invoices.list.map(
        (el: InvoiceResponseModel) => el.Ref,
      );
    }
    if (value) {
      dialogRef.componentInstance.value = value;
    }
  }

  printA5(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: any) => el.Ref);

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      EVENT_NAMES.ANALYTICS,
    )
      .subject(`app-printing.print`)
      .next({
        eventLabel: `Друк маркування`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });

    this.subscriptions = this.invoiceService
      .printDocuments(invoicesIds, 'pdf', {
        printForm: 'Document_new',
        PageFormat: 'A5',
        Position: '1',
        Copies: '1',
      })
      .subscribe(
        () => {
          this.printLoad = false;
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print`)
            .next({
              eventLabel: `Друк експрес посилки А5`,
              eventCategory: 'app-printing',
              eventAction: 'click',
            });
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            EVENT_NAMES.ANALYTICS,
          )
            .subject(`app-printing.print.error`)
            .next({
              eventLabel: `Друк експрес посилки А5`,
              eventCategory: 'app-printing',
              eventAction: 'printing.error',
            });
        },
      );
  }

  print(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: Invoice) => el.id);
    const invoicesDocNumbes = invoices.map((el: Invoice) => el.docNumber);

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      EVENT_NAMES.ANALYTICS,
    )
      .subject(`app-printing.print`)
      .next({
        eventLabel: `Друк експресс-посилки`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });

    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '1',
      Copies: '2',
    };
    this.subscriptions = this.invoiceService.printDocuments(invoicesIds, type, props).subscribe(
      () => {
        this.update();
        this.printLoad = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print.success`)
          .next({
            eventLabel: `Друк експресс-посилки: ${invoicesDocNumbes}`,
            eventCategory: 'app-printing',
            eventAction: 'print.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print.error`)
          .next({
            eventLabel: `Друк експресс-посилки: ${errors[0]?.errorCode}`,
            eventCategory: 'app-printing',
            eventAction: 'print.error',
          });
      },
    );
  }

  printRegisters(registers: InvoiceRegister[], type: string, orientation: string): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`app-printing.print`)
      .next({
        eventLabel: `Друкувати реєстр`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });
    this.printLoad = true;
    if (!registers.length) {
      return;
    }

    const ids = registers.map((register: InvoiceRegister) => register.id);

    this.subscriptions = this.invoiceRegisterService
      .printDocumentsNew(ids, type, orientation)
      .subscribe(
        () => {
          this.update();
          this.printLoad = false;
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`app-printing.print.success`)
            .next({
              eventLabel: `Друкувати реєстр`,
              eventCategory: 'app-printing',
              eventAction: 'print.success',
            });
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`app-printing.print.error`)
            .next({
              eventLabel: `Друкувати реєстр: ${errors[0]?.messageCode}`,
              eventCategory: 'app-printing',
              eventAction: 'print.error',
            });
        },
      );
  }

  printMarking(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: Invoice) => el.id);

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      EVENT_NAMES.ANALYTICS,
    )
      .subject(`app-printing.printMarkings`)
      .next({
        eventLabel: `Друк маркування`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });

    this.subscriptions = this.invoiceService.printMarking(invoicesIds, type).subscribe(
      () => {
        this.update();

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.printMarkings.success`)
          .next({
            eventLabel: `Друк маркування`,
            eventCategory: 'app-printing',
            eventAction: 'printMarking.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.printMarkings.error`)
          .next({
            eventLabel: `Друк маркування ${errors[0]?.errorCode}`,
            eventCategory: 'app-printing',
            eventAction: 'printMarkings.error',
          });
      },
    );
  }

  printMarkingZebraHundred(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: Invoice) => el.id);

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      EVENT_NAMES.ANALYTICS,
    )
      .subject(`app-printing.printMarkings`)
      .next({
        eventLabel: `Друкувати маркування 'Зебра' 100x100`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });

    this.subscriptions = this.invoiceService.printMarkingZebraHundred(invoicesIds, type).subscribe(
      () => {
        this.update();
        this.printLoad = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.printMarkings.success`)
          .next({
            eventLabel: `Друк маркування 'Зебра' 100x100`,
            eventCategory: 'app-printing',
            eventAction: 'printMarking.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.printMarkings.error`)
          .next({
            eventLabel: `Друк маркування 'Зебра' 100x100 ${errors[0]?.errorCode}`,
            eventCategory: 'app-printing',
            eventAction: 'printMarkings.error',
          });
      },
    );
  }

  printRegisterMarking(registerId, total, type): void {
    this.subscriptions = this.dataSource
      .loadInvoices('', null, 0, total, registerId)
      .subscribe((invoices) => {
        this.printMarking(invoices.list, type);
      });
  }

  getInvoices(registerId, total): void {
    this.isPrinting = true;
    this.subscriptions = this.dataSource
      .loadInvoices('', null, 0, total, registerId)
      .subscribe((invoices) => {
          this.invoices = invoices;
          if (!!this.invoices) {
            this.isPrinting = false;
          }
          this.cd.markForCheck();
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.update();
        },
      );
  }

  printRegisterMarkingZebraHundred(invoices, type): void {
    this.printLoad = true;
    this.printMarkingZebraHundred(invoices.list, type);
  }

  printRegister(invoices, type): void {
    this.printLoad = true;
    this.print(invoices.list, type);
  }

  printRegisterOneCopy(invoices, type): void {
    this.printLoad = true;
    this.printOneCopy(invoices.list, type);
  }

  printA5Register(invoices, type): void {
    this.printLoad = true;
    this.printA5(invoices.list, type);
  }

  printOneCopy(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: Invoice) => el.id);
    const invoicesDocNumbers = invoices.map((el: Invoice) => el.docNumber);

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      EVENT_NAMES.ANALYTICS,
    )
      .subject(`app-printing.print`)
      .next({
        eventLabel: `Друк експресс-посилки, 1 копія`,
        eventCategory: 'app-printing',
        eventAction: 'click',
      });

    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '1',
      Copies: '1',
    };
    this.subscriptions = this.invoiceService.printDocuments(invoicesIds, type, props).subscribe(
      () => {
        this.update();
        this.printLoad = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print.success`)
          .next({
            eventLabel: `Друк експресс-посилки, 1 копія: ${invoicesDocNumbers}`,
            eventCategory: 'app-printing',
            eventAction: 'print.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          EVENT_NAMES.ANALYTICS,
        )
          .subject(`app-printing.print.error`)
          .next({
            eventLabel: `Друк експресс-посилки, 1 копія: ${errors[0]?.errorCode}`,
            eventCategory: 'app-printing',
            eventAction: 'print.error',
          });
      },
    );
  }

  save(event: any, register: InvoiceRegister): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.save`)
      .next({
        eventLabel: `Зберегти назву реєстру`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    if (!register.id) {
      return;
    }

    const description = (register.description = event.value);

    const newRegister = {
      ...register,
      description,
    } as InvoiceRegister;

    this.subscriptions = this.invoiceRegisterService.update(newRegister).subscribe(
      () => {
        this.update();
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.save.success`)
          .next({
            eventLabel: `Зберегти назву реєстру`,
            eventCategory: componentName,
            eventAction: 'save.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.save.error`)
          .next({
            eventLabel: `Зберегти назву реєстру: ${errors[0]?.messageCode}`,
            eventCategory: componentName,
            eventAction: 'save.error',
          });
      },
    );
  }

  updateRegisterHeaderCount(docNumbers: string[], register: InvoiceRegister): void {
    register.count = register.count - docNumbers.length;
  }

  updateRegisterHeaderPrinted(register: InvoiceRegister, printed: string): void {
    register.printed = printed;
  }

  updateRegisterHeaderDescription(register: InvoiceRegister, description: string): void {
    register.description = description;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { PhoneClipboardDirective } from './phone-clipboard.directive';

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
  ],
  declarations: [
    CopyClipboardDirective,
    PhoneClipboardDirective,
  ],
  exports: [
    ClipboardModule,
    CopyClipboardDirective,
    PhoneClipboardDirective,
  ],
})
export class CopyClipboardModule {

}

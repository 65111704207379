import { WarehouseResult } from '@models';

export function getDescription(item: WarehouseResult): string {
  if (!item) {
    return '';
  }
  return `${item?.WarehouseIndex ?? ''}, ${item?.SettlementPresent ?? ''}, ${
    item?.Description ?? ''
  }`;
}

import { NgModule } from '@angular/core';
import { BarcodeInputDirective } from './barcode-input.directive';
import { LetterInputDirective } from './letter-input.directive';
import { NumberInputDirective } from './number-input.directive';
import { NumericDirective } from './numeric.directive';

@NgModule({
  declarations: [
    LetterInputDirective,
    NumberInputDirective,
    BarcodeInputDirective,
    NumericDirective,
  ],
  exports: [
    LetterInputDirective,
    NumberInputDirective,
    BarcodeInputDirective,
    NumericDirective,
  ],
})
export class InvoiceDirectivesModule {

}

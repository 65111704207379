import {
  compareByNestedKey,
  compareNumbers,
  compareStringDates, compareStringDatesFormat,
  compareStringDatesWithoutTime,
  compareStrings,
} from '@shared';
import { RadioOption } from '../../invoices/components/invoices-my/invoices-my.models';

export enum CourierPopupMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum CourierAddressTypes {
  DOORS = 'Doors',
}

export const courierPickupControls = {
  Customer: 'Customer',
  PickupDateAndTime: 'PickupDateAndTime',
  PickupDateAndTimeControls: {
    PickupDate: 'PickupDate',
    PickupTimeInterval: 'PickupTimeInterval',
  },
  PlannedWeight: 'PlannedWeight',
};

export const PICKUP_WHOLE_DAY: string = 'whole-day';
export const WeightErrors = {
  required: 'Це поле обов\'язкове',
};

export const MIN_ATTACHING_DURATION: number = 2000;

export const CourierCallSortConf = {
  Number: compareByNestedKey(compareStrings, 'Number'),
  DateTime: compareByNestedKey(compareStringDates, 'DateTime'),
  StatusDescription: compareByNestedKey(compareStrings, 'StatusDescription'),
  SenderAddressDescription: compareByNestedKey(compareStrings, 'SenderAddressDescription'),
  SenderDescription: compareByNestedKey(compareStrings, 'SenderDescription'),
  ContactSenderDescription: compareByNestedKey(compareStrings, 'ContactSenderDescription'),
  ContactSenderPhone: compareByNestedKey(compareStrings, 'ContactSenderPhone'),
  PreferredDeliveryDate: compareByNestedKey(compareStringDatesWithoutTime, 'PreferredDeliveryDate'),
  PlanedWeight: compareByNestedKey(compareNumbers, 'PlanedWeight'),
  FactualWeight: compareByNestedKey(compareNumbers, 'FactualWeight'),
};
export const CourierCallStatusesOptions: RadioOption[] = [
  { label: 'Всі', value: '' },
  { label: 'Готово до виконання', value: 'ReadyToDone' },
  { label: 'В обробці', value: 'InProcessing' },
  { label: 'Виконано', value: 'Done' },
  { label: 'Відмінено', value: 'Rejection' },
  { label: 'Відмінено з оплатою', value: 'RejectionWithPayment' },
];

export const CourierCallStatuses = {
  RejectionWithPayment: 'Відмінено з оплатою',
  Rejection: 'Відмінено',
  ReadyToDone: 'Готово до виконання',
  Done: 'Виконано',
  InProcessing: 'В обробці',
};

export const ClientOrganizationMaxLength = 150;
export const NumberErrors = {
  maxlength: 'Максимальна довжина 12 символів',
  minlength: 'Введіть, будь ласка, повний № заявки',
  pattern: 'Введіть дійсний номер заявки',
};
export const ClientOrganizationErrors = {
  objectMask: 'Оберіть організацію/приватну особу зі списку',
};
export const PhoneErrors = {
  minlength: 'Занадто короткий номер',
};

export enum ControlName {
  callFilters = 'callFilters',
  Number = 'Number',
  Status = 'Status',
  PickupDate = 'PickupDate',
  CreationDate = 'CreationDate',
  ClientOrganization = 'ClientOrganization',
  ClientPhone = 'ClientPhone',
}

export const ChipTruncateLimit = 35;

import { Directive, HostListener, Inject, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { WINDOW } from '@services';

@Directive({
  selector: '[orderNumber]',
})
export class OrderNumberDirective {
  @Input() maxLength = 12;

  constructor(
    @Inject(WINDOW) private window: Window,
    private ngControl: NgControl,
    ) {}

  @HostListener('keydown', ['$event'])
  onInput(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value;
    // Remove any non-digit and non-hyphen characters
    const formattedValue = inputValue.replace(/[^\d-]/g, '');
    const selection = this.window.getSelection()?.toString();

    // Ensure the input doesn't start with a hyphen
    if (formattedValue.startsWith('-')) {
      // Remove the leading hyphen
      target.value = '';
    } else {
      target.value = formattedValue;
    }

    if (
      formattedValue.length === 3
      && formattedValue.charAt(3) !== '-'
      // Backspace
      && event.keyCode !== 8
      // '-' Key
      && event.keyCode !== 189
    ) {
      const firstPart = formattedValue.substring(0, 3);
      const secondPart = formattedValue.substring(3);

      target.value = `${firstPart}-${secondPart}`;
    }

    if (formattedValue.length >= this.maxLength
      // Backspace
      && event.keyCode !== 8
      // Arrow keys
      && event.keyCode !== 37
      && event.keyCode !== 38
      && event.keyCode !== 39
      && event.keyCode !== 40
      && selection !== formattedValue) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    e.preventDefault();
    const setValue = (value) => {
      if (this.ngControl) {
        target.value = value;
        this.ngControl.control.setValue(value)
      }
    };
    const target = event.target as HTMLInputElement;
    const inputValue = e.clipboardData.getData('Text');
    const formattedValue = inputValue.replace(/[^\d-]/g, '');

    if (formattedValue.length >= 3 && formattedValue.charAt(3) !== '-') {
      const firstPart = formattedValue.substring(0, 3);
      const secondPart = formattedValue.substring(3);

      setValue(`${firstPart}-${secondPart}`);
    } else {
      setValue(formattedValue);
    }
  }
}

<div class="services-list__form" [formGroup]="backwardDeliveryDataGroup">
  <div class="form-row">
    <div class="form-col">
      <div class="label-radio">Платник за доставку</div>
      <mat-radio-group class="radio-row" formControlName="PayerType">
        <mat-radio-button [value]="'Sender'">Відправник посилки</mat-radio-button>
        <mat-radio-button [value]="'Recipient'" checked>Отримувач посилки</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-col form-col-5">
      <mat-form-field class="mat-block">
        <input matInput
               [maxlength]="20"
               placeholder="Опис документів"
               formControlName="RedeliveryString"
               autoScrollTarget>
      </mat-form-field>
    </div>
  </div>
  <!--  @TODO changes below commented to deploy version without the feature-->
  <!--  <app-custom-return-address *ngIf="customAddresses"-->
  <!--                             [options]="customAddresses"-->
  <!--                             (updateRef)="updateRef($event)"></app-custom-return-address>-->
</div>

import { AddressSettings } from '@shared';
import {from as observableFrom, Observable, of, of as observableOf} from 'rxjs';
import {concatMap, map} from 'rxjs';
import * as _ from 'lodash';
import {NoRestApiHelper} from './no-rest-api.helper';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {ContactApiService} from './contacts/contact.api.service';
import {IListData, IOrderParameter} from '../models/iapi';
import {
  Address,
  IAddressFilterParam,
  ISettlementFilterParam,
  IStreetFilterParam,
  WarehouseTypeName,
} from '../models/address.model';
import {Locality} from '../models/locality.model';
import {Street} from '../models/street.model';
import {Warehouse} from '../models/warehouse.model';
import {Schedule} from '../models/schedule';
import {State} from '../models/state.model';
import {Region} from '../models/region.model';
import {Contact} from '../models/contact.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressApiService {
  protected _privateContragentId: string = null;

  constructor(
    protected http: HttpClient,
    protected _ConfigStorage: ConfigService,
    protected _noRestApiHelper: NoRestApiHelper,
    protected _authorizationService: AuthService,
    protected _contactApiService: ContactApiService,
  ) {
  }

  getList(filter: IAddressFilterParam = {}, order: IOrderParameter = null, page = 1, limit = 20): Observable<IListData<Address>> {
    let promise: any;
    const storedLimit = limit;

    if (filter.onlyAdresses || filter.onlyWarehouse || filter.isNoPostomat) {
      limit = 1000;
    }

    if (!filter.contactId) {
      promise = this.http
        .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
          system: this._ConfigStorage.get('system'),
          modelName: 'ContactPersonGeneral',
          calledMethod: 'getContactPersonsList',
          methodProperties: {
            Page: page - 1,
            limit,
            CounterpartyRef: this._authorizationService.user && this._authorizationService.user?.contragent?.id,
          },
        })
        .pipe(
          map((data: any) => {
            filter.contactId = data.data.data[0].Ref;

            return this.http
              .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
                system: this._ConfigStorage.get('system'),
                modelName: 'AddressContactPersonGeneral',
                calledMethod: 'getAddresses',
                methodProperties: {
                  Page: page - 1,
                  limit,
                  ContactPersonRef: filter.contactId,
                  CityRef: filter.cityId,
                },
              });
          }));
    } else {
      promise = this.http
        .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
          system: this._ConfigStorage.get('system'),
          modelName: 'AddressContactPersonGeneral',
          calledMethod: 'getAddresses',
          methodProperties: {
            Page: page - 1,
            limit,
            ContactPersonRef: filter.contactId,
            CityRef: filter.cityId,
          },
        })

      ;
    }


    return observableFrom(promise).pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      map(data => this._bindListToModel(data, filter)),
      map((data: IListData<Address>) => {

        return data;
      }));
  }

  getStreets(filter: IStreetFilterParam, page = 1, limit = 10): Observable<IListData<Street>> {
    const promise: any = this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'Address',
        calledMethod: 'searchSettlementStreets',
        methodProperties: {
          StreetName: filter.name,
          SettlementRef: filter.localityRef,
          Limit: '10',
          Page: '1',
        },
      })

    ;


    return observableFrom(promise).pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      map(data => this._bindStreetsListToModel(data)))

      ;
  }

  getPrivatConntragentRef(): Observable<string> {
    return observableFrom(this._getContragentsPromise());
  }

  _getContragentsPromise(): Observable<any> {
    let contragentsPromise: any;

    if (this._privateContragentId) {
      contragentsPromise = of(this._privateContragentId);
    } else {
      contragentsPromise = this.http
        .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
          system: this._ConfigStorage.get('system'),
          modelName: 'Counterparty',
          calledMethod: 'getCounterparties',
          methodProperties: {
            CounterpartyProperty: 'Recipient',
          },
        })
        .pipe(map(data => this._noRestApiHelper.checkErrors({data})))
        .subscribe(
          (data: any) => {
            this._privateContragentId = _.find(data.data.data, (el: any) => el.CounterpartyType === 'PrivatePerson').Ref;

            return this._privateContragentId;
          });
    }

    return of(this._privateContragentId);
  }

  getOne(id: string, contactId: string = null): Observable<Address> {
    let promise: Observable<any>;

    if (!contactId) {
      promise = this.http
        .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
          system: this._ConfigStorage.get('system'),
          modelName: 'ContactPersonGeneral',
          calledMethod: 'getContactPersonsList',
          methodProperties: {
            Page: 1,
            limit: 10,
            CounterpartyRef: this._authorizationService.user && this._authorizationService.user.contragent.id,
          },
        })
        .pipe(
          map(
            (data: any) => {
              contactId = data.data.data[0].Ref;

              return this.http
                .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
                  system: this._ConfigStorage.get('system'),
                  modelName: 'AddressContactPersonGeneral',
                  calledMethod: 'getAddressByRef',
                  methodProperties: {
                    ContactPersonRef: contactId,
                    Ref: id,
                  },
                });
            },
          ),
        );
    } else {
      promise = this.http
        .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
          system: this._ConfigStorage.get('system'),
          modelName: 'AddressContactPersonGeneral',
          calledMethod: 'getAddressByRef',
          methodProperties: {
            ContactPersonRef: contactId,
            Ref: id,
          },
        });
    }

    return promise.pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      map(data => this._bindOneToModel(data)));
  }

  remove(id: string): Observable<boolean> {
    return new Observable<boolean>();
  }

  removeAddress(contactRef: string, refAddress: string): Observable<Address> {
    const addPromise: any = this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'delete',
        methodProperties: {
          Ref: refAddress,
          ContactPersonRef: contactRef,
        },
      });

    return observableFrom(addPromise).pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      map(data => this._bindOneToModel(data)));
  }

  add(address: Address): Observable<Address> {
    const addPromise: any = this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'save',
        methodProperties: this._addressToMethodParams(address),
      });

    return observableFrom(addPromise).pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      concatMap(data => this.getOne(data.data.data[0].Ref, data.data.data[0].ContactPersonRef)));
  }

  update(address: Address): Observable<Address> {
    const updatePromise: any = this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'update',
        methodProperties: this._addressToMethodParams(address),
      });

    return observableFrom(updatePromise).pipe(
      map(data => this._noRestApiHelper.checkErrors({data})),
      map(data => this._bindOneToModel(data)));
  }

  autocomplateCity(query: string, limit: number): Observable<string[]> {
    const cities: string[] = [];

    const service = new (window as any).google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: query,
        types: ['(cities)'],
        componentRestrictions: {country: 'UA'},
        language: 'uk',
      },
      (data) => console.log(data),
    );

    return observableOf(cities);
  }

  addAddressWarehouse(address: Address): Observable<Address> {
    address.type = "Warehouse";
    address.building = null;
    address.flat = null;
    address.floor = null;
    return this.add(address);
  }

  addAddressDoors(address: Address): Observable<Address> {
    address.type = "Doors";
    return this.add(address);
  }

  addAddressPostbox(address: Address): Observable<Address> {
    address.building = null;
    address.flat = null;
    address.floor = null;
    address.type = "Warehouse";
    return this.add(address);
  }

  updateAddressWarehouse(address: Address): Observable<Address> {
    address.type = "Warehouse";
    address.building = null;
    address.flat = null;
    address.floor = null;
    return this.update(address);
  }

  updateAddressDoors(address: Address): Observable<Address> {
    address.type = "Doors";
    return this.update(address);
  }

  updateAddressPostbox(address: Address): Observable<Address> {
    address.type = "Warehouse";
    address.building = null;
    address.flat = null;
    address.floor = null;
    return this.update(address);
  }

  filterWarehouse(warehouses: Warehouse[], searchString: string): Warehouse[] {
    searchString = searchString.toLowerCase();
    let words: string[] = searchString.split(/[.| |,|\-|:]/);
    let filtered: any[] = warehouses;
    _.each(words, (world: string) => {
      filtered = _.filter(filtered,
        (object: Object) => {
          let result: boolean;
          _.each(['description', 'warehouseNumber'], (keyObject: string) => {
            let val: any = _.get(object, keyObject);
            if (_.isString(val)) {
              val = val.toLocaleLowerCase();
              if (val && _.isString(val) && val.indexOf(world) !== -1) {
                result = true;
                return !result;
              } else if (val && _.isString(val) && val.indexOf(world) === -1) {
                result = false;
              }
            }
          });

          return result;
        });
    });


    filtered = _.sortBy(filtered, [
        (o: Warehouse) => {
          if (words.indexOf(o.warehouseNumber) !== -1) {
            return -100
          } else if (
            (() => {
              for (let word of words) {
                if (o.warehouseNumber.indexOf(word) !== -1)
                  return true;

                if (word.indexOf(o.warehouseNumber) !== -1)
                  return true;
              }

              return false;
            })())
          {
            return -10;
          } else {
            return 0;
          }
        }
      ]
    );

    return filtered;
  }


  _detectTypeWarehouse(idType: string): WarehouseTypeName {
    switch (idType) {
      case '6f8c7162-4b72-4b0a-88e5-906948c6a92f':
        return 'NEW_POST';
      case '9a68df70-0267-42a8-bb5c-37f427e36ee4':
        return 'NEW_POST';
      case '841339c7-591a-42e2-8233-7a0a00f0ed6f':
        return 'NEW_POST';
      case '95dc212d-479c-4ffb-a8ab-8c1b9073d0bc':
        return 'POSTBOX';
      case AddressSettings.POSTBOX_TYPE:
        return 'POSTBOX';
      case 'cab18137-df1b-472d-8737-22dd1d18b51d':
        return 'IN_POST';
      default:
        return 'OTHER';
    }
  }

  protected _bindListToModel(data: any, filter: IAddressFilterParam): IListData<Address> {
    let addresses: any[] = [];
    if (!filter.onlyAdresses) {
      addresses.push(...data.data.data[0].Warehouses);
    }
    if (!filter.onlyWarehouse) {
      addresses.push(...data.data.data[0].Addresses);
    }

    if (filter.isNoPostomat) {
      addresses = _.filter(addresses, address => this._detectTypeWarehouse(address.TypeOfWarehouse) !== 'POSTBOX');
    }

    return {
      list: _.map(addresses, (el: any) => {
        const address = new Address(el.Ref);

        address.street = new Street(el.StreetRef);
        address.street.name = el.StreetDescription;
        address.street.locality = new Locality(el.CityRef || el.SettlementRef);
        address.street.locality.id2 = el.SettlementRef;
        address.street.locality.name = el.SettlementDescription || el.CityDescription || el.CityRef;
        address.street.locality.typeName = el.Type;
        address.city = address.street.locality;
        address.building = el.BuildingDescription || el.BuildingNumber;
        address.street.type = el.StreetsType;
        address.flat = el.Flat;
        address.warehouseNumber = el.WarehouseNumber;
        address.note = el.Note;
        address.noteAddressRecipient = el.NoteAddressRecipient;

        if (el.PlaceMaxWeightAllowed && el.PlaceMaxWeightAllowed !== '0') {
          address.placeMaxWeightAllowed = +el.PlaceMaxWeightAllowed;
        }

        if (el.TotalMaxWeightAllowed && el.TotalMaxWeightAllowed !== '0') {
          address.totalMaxWeightAllowed = +el.TotalMaxWeightAllowed;
        }

        address.description = el.Description || el.AddressDescription;
        if (el.AddressDescription && el.CityDescription) {
          address.description = address.description + ', ' + el.CityDescription;
        }

        address.type = address.street && address.street.id ? 'Doors' : 'Warehouse';
        address.typeWarehouse = this._detectTypeWarehouse(el.TypeOfWarehouse);

        // address.description = address.description.replace('Відділення ', '').replace('відділення ', '');
        return address;
      }),
      total: 100,
    };
  }

  protected _bindWarehousesListToModel(data: any): IListData<Warehouse> {
    return {
      list: _.map(data.data.data, (el: any) => {
        const warehouse = new Warehouse(el.Ref);
        warehouse.description = el.Description;
        warehouse.descriptionRu = el.DescriptionRu;
        warehouse.city = new Locality(el.CityRef);
        warehouse.city.description = el.CityDescription || el.CityRef;
        warehouse.city.name = el.CityDescription || el.CityRef;
        warehouse.buildingNumber = el.Number;
        warehouse.phone = el.Phone;
        warehouse.type = el.Phone;
        warehouse.schedule = new Schedule();
        warehouse.warehouseNumber = el.WarehouseNumber || el.Number;

        warehouse.type = this._detectTypeWarehouse(el.TypeOfWarehouse);

        warehouse.description = warehouse.description.replace('Відділення ', '').replace('відділення ', '').replace('Поштомат ', '');


        return warehouse;
      }),
      total: data.data.info.totalCount,
    };
  }

  protected _bindCitiesListToModel(data: any): IListData<Locality> {
    return {
      list: _.map(data.data.data[0].Addresses, (el: any) => {
        const city = new Locality(el.DeliveryCity);
        city.id2 = el.Ref;
        city.name = el.MainDescription;
        city.description = el.SettlementTypeCode + ' ' + el.MainDescription;
        city.typeName = el.SettlementTypeCode;
        city.warehousesCount = el.Warehouses;
        /*city.state = new State();
        city.state.name = el.Region;*/
        city.state = new State();
        city.state.name = el.Area ? el.Area : el.Region;
        city.region = new Region();
        city.region.name = el.Area ? el.Region : null;
        city.region.description = el.Area ? `${el.Region}, ${el.Area}` : el.Region;
        city.fullName = el.Present;
        return city;
      }),
      total: data.data.data[0].TotalCount,
    };
  }

  protected _bindStreetsListToModel(data: any): IListData<Street> {
    return {
      list: _.map(data.data.data[0].Addresses, (el: any) => {
        const street = new Street(el.SettlementStreetRef);
        street.name = el.SettlementStreetDescription;
        street.description = el.Present;
        street.type = el.StreetsTypeDescription;
        street.locality = new Locality(el.SettlementRef);
        return street;
      }),
      total: data.data.data[0].TotalCount,
    };
  }

  protected _bindOneToModel(response: any): Address {
    let address: Address;

    if (response.data.success) {
      const el: any = response.data.data[0];

      address = new Address(el.Ref);
      address.street = new Street(el.StreetRef);
      address.street.name = el.StreetDescription;
      address.street.description = el.StreetDescription;
      address.street.locality = new Locality(el.CityRef);
      address.street.locality.id2 = el.SettlementRef;
      address.street.locality.name = el.SettlementDescription || el.CityDescription;
      address.street.locality.description = address.street.locality.name;
      address.street.locality.typeName = el.Type;
      address.city = address.street.locality;
      address.building = el.BuildingNumber;
      address.flat = el.Flat;
      address.note = el.Note;
      address.city = address.street.locality;
      address.noteAddressRecipient = el.NoteAddressRecipient;
      address.type = el.AddressType;

      if (!address.city.warehousesCount && address.type === 'Warehouse') {
        address.city.warehousesCount = 1;
      }

      address.warehouseNumber = el.WarehouseNumber || (el.TypeOfWarehouse && 'None kostil need from api');

      address.description = el.Description ||
        (el.AddressDescription ? el.AddressDescription + (el.CityDescription ? ', ' + el.CityDescription : '') : '');
      address.contactPerson = new Contact(null, null, el.ContactPersonRef);

      if (el.TypeOfWarehouse) {
        address.typeWarehouse = this._detectTypeWarehouse(el.TypeOfWarehouse);
        address.setFullLoaded(true);
      }

      if (address.type === 'Warehouse') {
        delete address.street;
      }

      let maxWeigth = parseFloat(el.TotalMaxWeightAllowed);
      if (maxWeigth) {
        address.totalMaxWeightAllowed = maxWeigth;
      }

      maxWeigth = parseFloat(el.PlaceMaxWeightAllowed);
      if (maxWeigth) {
        address.placeMaxWeightAllowed = maxWeigth;
      }

      address.setFullLoaded(true);

    }

    return address;
  }

  protected _addressToMethodParams(address: Address): any {
    const methodParams: any = {};

    methodParams.ContactPersonRef = address.contactPerson?.id ?? address.contactPersonRef;
    if (address.id) {
      methodParams.Ref = address.id;
    }
    methodParams.SettlementRef = address.city.id2;
    methodParams.AddressRef = address.street.id;
    methodParams.AddressType = address.type;
    if (address.type === 'Doors') {
      methodParams.BuildingNumber = address.building;
      methodParams.Flat = address.flat;
      methodParams.Floor = address.floor;
      methodParams.Note = address.note;
      methodParams.General = address.general;
      methodParams.NoteAddressRecipient = address.noteAddressRecipient;
    }

    return methodParams;
  }
}

import { Injectable } from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';

export interface ErrorTypeMessages {
  [key: string]: Array<{ key: string, message: string }>;
}

export interface ErrorTypeMessagesArray {
  [key: string]: ErrorMessages;
}

export interface ErrorMessages {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  getSingleErrorsObject(controls: { [key: string]: AbstractControl }, errorsTypeMessages: ErrorTypeMessages): ErrorMessages {
    const messages: ErrorMessages = {};
    for (const key in errorsTypeMessages) {
      if (controls[key] && controls[key].errors) {
        messages[key] = '';
        for (const error of  errorsTypeMessages[key]) {
          if (controls[key].errors[error.key]) {
            messages[key] = error.message;
            break;
          }
        }
      }
    }
    return messages;
  }

  getSingleArrayErrorObject(formArray: AbstractControl[], errorsTypeMessages: ErrorTypeMessages): ErrorTypeMessagesArray {
    const messages: ErrorTypeMessagesArray = {};
    for (let i = 0; i < formArray.length; ++i) {
      messages[i] = {};
      const controls = (formArray[i] as UntypedFormGroup).controls;
      for (const key in errorsTypeMessages) {
        if (controls[key] && controls[key].errors) {
          messages[i][key] = '';
          for (const error of  errorsTypeMessages[key]) {
            if (controls[key].errors[error.key]) {
              messages[i][key] = error.message;
              break;
            }
          }
        }
      }
    }
    return messages;
  }

  getSingleControlErrorObject(controlName: string, control: AbstractControl, errorsTypeMessages: ErrorTypeMessages): ErrorMessages {
    const messages: ErrorMessages = {};
    if (!control?.errors) {
      return messages;
    }
    for (const error of errorsTypeMessages[controlName]) {
      if (control.errors[error.key]) {
        messages[controlName] = error.message;
        break;
      }
    }
    return messages;
  }

}

<div class="modal modal_full-height">
    <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); close()">
        <i class="material-icons">close</i>
    </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">Заявки на виклик курʼєра</h3>
      </div>
    </div>
  </div>
  <mat-progress-bar [style.opacity]="+(loading$ | async)" mode="indeterminate"></mat-progress-bar>
  <form class="form-flex">
    <main class="modal-content">
      <div class="form-row ">
        <div class="text">
          Додайте відправлення до заявки на Виклик кур’єра або створіть нову заявку
        </div>
      </div>
      <div class="form-row mr-bottom-16">
        <div class="form-col form-col-6">
          <mat-form-field class="mat-block">
            <mat-select [(ngModel)]="selectedPickup" [ngModelOptions]="{standalone: true}"
                        [disabled]="loading$ | async">
              <mat-option [value]="newCourierPickup">Додати до нової заявки</mat-option>
              <mat-option *ngFor="let pickup of (pickups$ | async)"
                          [value]="pickup.Number"
                          class="select-register-option">
                <b>{{ pickup.Number }}</b>, {{ pickup.PreferredDeliveryDate }}
                {{ pickup.TimeIntervalDescription }}
                <br>
                {{ pickup.SenderAddressDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </main>
    <div class="modal-footer modal-footer_pad-8">
      <div class="modal-footer-buttons">
        <div class="modal-footer-buttons__item modal-footer-buttons__right mx-5">
          <button type="button"
                  mat-button
                  [disabled]="loading$ | async"
                  (click)="close()">
            скасувати
          </button>
          <button type="button"
                  mat-button
                  [disabled]="loading$ | async"
                  color="primary"
                  (click)="save()">
            {{ saveBtnLabel }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div [class.control-icons]="!stylesCompat" *ngIf="edit?.enabled || create?.enabled">
  <div [class.control-icons__item]="!stylesCompat" *ngIf="create?.enabled" [class.international-inv]="intertationalInv">
    <button mat-icon-button
            type="button"
            [class.control-btn]="stylesCompat"
            [disabled]="disabled"
            (click)="triggerControl('create')">
      <mat-icon class="material-icons_red"  [matTooltip]="create?.tooltip" matTooltipPosition="below">
        add_circle_outline
      </mat-icon>
    </button>
  </div>
  <div [class.control-icons__item]="!stylesCompat" *ngIf="edit?.enabled">
    <button mat-icon-button
            type="button"
            [class.control-btn]="stylesCompat"
            [disabled]="disabled"
            (click)="triggerControl('edit')">
      <mat-icon class="material-icons_grey" [matTooltip]="edit?.tooltip" matTooltipPosition="below">
        edit
      </mat-icon>
    </button>
  </div>
  <div [class.control-icons__item]="!stylesCompat" *ngIf="clear?.enabled">
    <button mat-icon-button
            type="button"
            [class.control-btn]="stylesCompat"
            [disabled]="disabled"
            (click)="triggerControl('clear')">
      <mat-icon class="material-icons_grey" [matTooltip]="clear?.tooltip" matTooltipPosition="below">
        cancel
      </mat-icon>
    </button>
  </div>
  <div [class.control-icons__item]="!stylesCompat" *ngIf="delete?.enabled">
    <button mat-icon-button
            type="button"
            [class.control-btn]="stylesCompat"
            [disabled]="disabled"
            (click)="triggerControl('delete')">
      <mat-icon class="material-icons_grey" [matTooltip]="delete?.tooltip" matTooltipPosition="below">
        delete
      </mat-icon>
    </button>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractFormContainer, roundNumber } from '@shared';
import { Subscription } from 'rxjs';
import { ErrorMessages, ErrorTypeMessages, ValidationService } from '@services';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-delivery-cargo-common',
  templateUrl: './delivery-cargo-common.component.html',
  styleUrls: ['./delivery-cargo-common.component.scss'],
})
export class DeliveryCargoCommonComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input() controlName = 'commonParams';

  @Input() isTemplate: boolean;

  @Input() invoiceId: string;

  matcher = new MyErrorStateMatcher();

  public commonParamsGroup: UntypedFormGroup;

  errors: ErrorTypeMessages = {
    Weight: [{ key: 'required', message: "Це поле обов'язкове" }],
    VolumeGeneral: [{ key: 'required', message: "Це поле обов'язкове" }],
    SeatsAmount: [{ key: 'required', message: "Це поле обов'язкове" }],
  };

  errorMessages: ErrorMessages = {};

  errorsSubscription: Subscription;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private validationService: ValidationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setErrorSubscription();
  }

  setErrorSubscription() {
    this.errorsSubscription = this.form
      .get(this.controlName)
      .valueChanges.subscribe((value) => {
        this.errorMessages = this.validationService.getSingleErrorsObject(
          (this.form.get(this.controlName) as UntypedFormGroup).controls,
          this.errors,
        );
        if (
          this.form.controls[this.controlName]['controls']['Weight'].value !==
          ''
        ) {
          this.form.controls[this.controlName]['controls'][
            'VolumeGeneral'
          ].clearValidators();
          this.form.controls[this.controlName]['controls'][
            'VolumeGeneral'
          ].updateValueAndValidity({ onlySelf: true });
        } else if (
          this.form.controls[this.controlName]['controls']['VolumeGeneral']
            .value !== ''
        ) {
          this.form.controls[this.controlName]['controls'][
            'Weight'
          ].clearValidators();
          this.form.controls[this.controlName]['controls'][
            'Weight'
          ].updateValueAndValidity({ onlySelf: true });
        } else {
          this.form.controls[this.controlName]['controls'][
            'Weight'
          ].setValidators(...(this.isTemplate ? [] : [Validators.required]));
          this.form.controls[this.controlName]['controls'][
            'VolumeGeneral'
          ].setValidators(...(this.isTemplate ? [] : [Validators.required]));
          this.form.controls[this.controlName]['controls'][
            'Weight'
          ].markAsTouched();
          this.form.controls[this.controlName]['controls'][
            'VolumeGeneral'
          ].markAsTouched();
          this.form.controls[this.controlName]['controls'][
            'Weight'
          ].updateValueAndValidity({ onlySelf: true });
          this.form.controls[this.controlName]['controls'][
            'VolumeGeneral'
          ].updateValueAndValidity({ onlySelf: true });
        }
      });
  }

  addFormControls() {
    this.commonParamsGroup = this.formBuilder.group({
      Weight: ['', ...(this.isTemplate ? [] : [Validators.required])],
      VolumeGeneral: ['', ...(this.isTemplate ? [] : [Validators.required])],
      SeatsAmount: ['1', ...(this.isTemplate ? [] : [Validators.required])],
      VolumeWeight: [''],
    });
    this.form.addControl(this.controlName, this.commonParamsGroup);
  }

  recalculateVolumeWeight(event) {
    const VolumeGeneral = Number(event.target.value);
    const VolumeWeight = VolumeGeneral ? roundNumber(VolumeGeneral * 250) : '';
    this.commonParamsGroup.patchValue({
      VolumeWeight,
    });
  }

  preventDecimal(event) {
    return (
      (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 0
    );
  }

  ngOnDestroy() {
    this.form.removeControl(this.controlName);
    this.errorsSubscription.unsubscribe();
  }
}

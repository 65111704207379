<div class="services-list__form" [formGroup]="backwardDeliveryDataGroup">
  <div class="form-row">
    <div class="form-col form-col-3">
      <mat-form-field class="mat-block">
        <input matInput
               [maxlength]="20"
               placeholder="Опис"
               formControlName="RedeliveryString"
               autoScrollTarget>
      </mat-form-field>
    </div>

    <div class="form-col form-col-5">
      <div class="label-radio">Платник за доставку</div>
      <mat-radio-group class="radio-row" formControlName="PayerType">
        <mat-radio-button
          [value]="'Sender'"
          [matTooltip]="senderName"
        >Відправник</mat-radio-button>
        <mat-radio-button [value]="'Recipient'" [matTooltip]="recipientName">Отримувач</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <app-custom-return-address *ngIf="customAddresses"
                             [controlName]="returnAddressControlName"
                             [formGroup]="backwardDeliveryDataGroup"
                             [options]="customAddresses"
                             #customAddress>
  </app-custom-return-address>
</div>

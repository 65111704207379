 <ng-container [formGroup]="editForm">
  <app-autocomplete-with-controls data-test-id="city"
                                  [formControlName]="'city'"
                                  [size]="2"
                                  [createTemplate]="templateValidation"
                                  [config]="configCity"
                                  [formControlParent]="editForm.get('city')"
                                  [controlsConfig]="null"
                                  [options]="cityOptions"
                                  [transformFn]="cityTransformFn">
    <ng-template appAutocompleteOption let-option>
      {{ option.Present }}
    </ng-template>
    <app-autocomplete-error
      *ngIf="!!errorMessages['city'] &&
            (editForm.get('city').touched ||
    editForm.get('city').dirty)">
      {{ errorMessages['city'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>

  <app-autocomplete-with-controls data-test-id="postbox"
                                  [formControlName]="'postbox'"
                                  [size]="6"
                                  [config]="configPostbox"
                                  [createTemplate]="templateValidation"
                                  [controlsConfig]="null"
                                  [options]="postboxOptions"
                                  [formControlParent]="editForm.get('postbox')"
                                  [transformFn]="postboxTransformFn"
                                  [optionsFilterFn]="postboxOptionsFilterFn"
                                  (focus)="onFocus($event)">
    <ng-template appAutocompleteOption let-option let-disabled="disabled" let-data="data">
      <div [matTooltip]="data.limitationText">
        {{ option.Description }}
      </div>
    </ng-template>
    <app-autocomplete-error
      *ngIf="!!errorMessages['postbox'] &&
            (editForm.get('postbox').touched || editForm.get('postbox').dirty)">
      {{ errorMessages['postbox'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>
</ng-container>


import {Directive, OnDestroy} from '@angular/core';
import {PaginationEvent, TableComponent} from './table-component';
import {Invoice} from '../../models/invoice/invoice.model';
import {InvoicesDataSource} from './invoices-data-source';
import {HeaderSearchService} from '../../services/header-search.service';

@Directive()
export abstract class InvoiceTableComponent<D extends InvoicesDataSource> extends TableComponent<Invoice, D> implements OnDestroy {

  itemsPerPage = 5;
  colsShowConfig: Map<string, string> = new Map([
    ['select', 'begin'],
    ['docNumber', 'begin'],
    ['options', 'end'],
  ]);
  protected headerSearchService: HeaderSearchService;

  getInvoiceStatusClass(statusId: number): string {
    switch (+statusId) {
      case 1:
        return '';
      case 5:
        return 'status-col status-col--received';
      case 6:
        return 'status-col status-col--problem';
      case 3:
        return 'delivery-status_in-way';
      case 2:
        return 'delivery-status_in-way';
      default:
        return 'status-col status-col--delivered';
    }
  }

  onPageChange($event: PaginationEvent): void {
    super.onPageChange($event);
    // this.headerSearchService.clearSearch();
  }

  getDocNumberType(docNumber: string) {
    return docNumber.slice(0, 1);
  }

  ngOnDestroy(): void {
    // this.headerSearchService.clearSearch();
  }
}

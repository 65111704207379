import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[npInvoiceProgress]',
})
export class InvoiceProgressDirective implements OnInit {
  @Input() set npInvoiceProgress(proggress: string) {
    this._innvoiceProgress = proggress;

    setTimeout(() => this.calculateProggress(), 50);
  }

  private _innvoiceProgress: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.calculateProggress();
  }

  calculateProggress() {
    const height = this.el.nativeElement.offsetHeight;
    const proggressPx = Math.round(((((+this._innvoiceProgress) || 0) / 5) * height) / 8) * 8;
    this.el.nativeElement.style.backgroundSize = `100% ${proggressPx}px`;
  }

}

export const payerNameTranslationsShort = {
  Sender: 'Відправник',
  Recipient: 'Отримувач',
  ThirdPerson: 'Третя особа',
};

export const payerNameTranslationsLong = {
  Sender: 'Відправник посилки',
  Recipient: 'Отримувач посилки',
  ThirdPerson: 'Третя особа',
};

export const paymentMethodTranslations = {
  Cash: 'Готівка',
  NonCash: 'Безготівка',
};

<mat-progress-bar [style.opacity]="((dataSource.loading$ | async) || printLoad) ? 1 : 0"
                  mode="indeterminate">
</mat-progress-bar>
<div class="register-content__row d-flex px-6 mb-3" *ngIf="(dataSource.total$ | async) !== 0">
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label">Сума до сплати Відправником</div>
      <div class="info-cell__content info-cell__content_lg">
        <span
          data-test-id="TotalCostOnSiteSender"
          *ngIf="register?.TotalCheckCostSender === 0 && register?.TotalCostOnSiteSender !== 0">{{ register?.TotalCostOnSiteSender + ' грн' }}</span>
        <div *ngIf="register?.TotalCheckCostSender !== 0 && register?.TotalCostOnSiteSender !== 0">
          <span class="line-through-text">{{ register?.TotalCostOnSiteSender }}</span>
          {{ register?.TotalCheckCostSender + ' грн' }}
        </div>
        <span
          data-test-id="TotalCheckCostSender"
          *ngIf="register?.TotalCheckCostSender === 0 && register?.TotalCostOnSiteSender === 0">0.00 грн</span>
      </div>
    </div>
  </div>
  <div class="register-content__item register-content__item_buttons">
    <div class="register-content__buttons">
      <button mat-icon-button *ngIf="!customRegisters"
              matTooltip="Роздрукувати реєстр"
              [matMenuTriggerFor]="printMenu"
              (click)="$event.stopPropagation(); getInvoices(register.id, register.count)"
              data-test-id="PrintRegister">
        <mat-icon class="material-icons material-icons_grey">print</mat-icon>
      </button>
      <mat-menu #printMenu="matMenu" class="mat-menu_width_3">
        <mat-progress-bar [style.opacity]="isPrinting ? 1 : 0"
                          mode="indeterminate">
        </mat-progress-bar>
        <button mat-menu-item (click)="printRegisters([register], 'pdf', 'landscape')"
                data-test-id="PrintRegister-landscape">
          Друк реєстру (альбомний формат)
        </button>
        <button mat-menu-item (click)="printRegisters([register], 'pdf', 'portrait')"
                data-test-id="PrintRegister-portrait">
          Друк реєстру (книжковий формат)
        </button>
        <button mat-menu-item (click)="printRegisterMarkingZebraHundred(invoices, 'pdf')"
                *ngIf="!printingm100x100" [disabled]="isPrinting" data-test-id="PrintRegister-100*100">
          Друк маркування 100*100 типу "зебра", PDF
        </button>
        <button mat-menu-item (click)="printRegisterA4(invoices, 'Marking6')"
                [disabled]="isPrinting" data-test-id="PrintRegister-Marking6">
          Маркування на листі А4 (6 наклейок)
        </button>
        <button mat-menu-item (click)="printA5Register(invoices, 'pdf')"
                [disabled]="isPrinting" data-test-id="PrintRegister-A5">
          Експрес-накладну, 1 копiя (А5)
        </button>
        <button mat-menu-item (click)="printRegisterOneCopy(invoices, 'pdf')"
                [disabled]="isPrinting" data-test-id="PrintRegister-1copy">
          Експрес-накладну, 1 копiя
        </button>
        <button mat-menu-item (click)="printRegisterTwoCopy(invoices, 'pdf')"
                [disabled]="isPrinting" data-test-id="PrintRegister-2copy">
          Експрес-накладну, 2 копiї
        </button>
      </mat-menu>
      <button mat-icon-button
              matTooltip="Змінити назву"
              (click)="rename()">
        <mat-icon class="material-icons material-icons_grey">edit</mat-icon>
      </button>
      <button mat-icon-button
              *ngIf="!customRegisters"
              [matTooltip]="'Розформувати реєстр'"
              matTooltipPosition="below"
              [confirmMsg]="'<h4>Дійсно бажаєте розформувати даний реєстр?</h4> Після розформування реєстру можна переглянути кожну посилку окремо у розділі Чернетка'"
              [isConfirmHtml]="true"
              [confirmBtnText]="'Так, розформувати'"
              [cancelBtnText]="'Ні. залишити'"
              (confirm)="removeRegister(register?.id)"
              data-test-id="RemoveRegister">
        <mat-icon class="material-icons material-icons_delete-sweep material-icons_grey">delete_sweep</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="register-content__row d-flex px-6 mb-3" *ngIf="(dataSource.total$ | async) !== 0">
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label">Відправник</div>
      <div class="info-cell__content info-cell__content_clamp-line" *ngIf="register?.Sender" data-test-id="Sender">
        {{ register?.Sender }}
      </div>
      <div class="info-cell__content info-cell__content_clamp-line" *ngIf="!register?.Sender" data-test-id="Sender">
        —
      </div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Кількість посилок</div>
      <div class="info-cell__content" *ngIf="register?.Count !== 0" data-test-id="Count">{{ register?.Count }}</div>
      <div class="info-cell__content" *ngIf="register?.Count === 0" data-test-id="Count">—</div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Оголошена вартість</div>
      <div class="info-cell__content" *ngIf="register?.TotalCost !== 0"
           data-test-id="TotalCost">{{ (register?.TotalCost) + ' грн' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalCost === 0" data-test-id="TotalCost">—</div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Вартість доставки</div>
      <div class="info-cell__content"
           *ngIf="register?.TotalCheckCost === 0 && register?.TotalCostOnSite !== 0"
           data-test-id="TotalCostOnSite">{{ register?.TotalCostOnSite + ' грн' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalCheckCost !== 0" data-test-id="TotalCheckCost">
        <span class="line-through-text">{{ register?.TotalCostOnSite }}</span>
        {{ register?.TotalCheckCost + ' грн' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalCheckCost === 0 && register?.TotalCostOnSite === 0">—</div>
    </div>
  </div>
</div>
<div class="register-content__row d-flex px-6 mb-6" *ngIf="(dataSource.total$ | async) !== 0">
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label">Адреса відправлення</div>
      <div class="info-cell__content info-cell__content_clamp-line" *ngIf="register?.SenderAddress"
           data-test-id="SenderAddress">{{ register?.SenderAddress }}
      </div>
      <div class="info-cell__content info-cell__content_clamp-line" *ngIf="!register?.SenderAddress"
           data-test-id="SenderAddress">—
      </div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Загальна вага</div>
      <div class="info-cell__content nowrap"
           *ngIf="register?.TotalCheckWeight === 0 && register?.TotalWeight !== 0"
           data-test-id="TotalWeight">{{ register?.TotalWeight + ' кг' }}
      </div>
      <div class="info-cell__content nowrap" *ngIf="register?.TotalCheckWeight !== 0" data-test-id="TotalCheckWeight">
        <span class="line-through-text">{{ register?.TotalWeight }}</span>
        {{ register?.TotalCheckWeight + ' кг' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalCheckWeight === 0 && register?.TotalWeight === 0">—</div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Грошовий переказ</div>
      <div class="info-cell__content"
           *ngIf="register?.TotalRedeliverySum !== 0"
           data-test-id="TotalRedeliverySum">{{ register?.TotalRedeliverySum + ' грн' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalRedeliverySum === 0" data-test-id="TotalRedeliverySum">—
      </div>
    </div>
  </div>
  <div class="register-content__item">
    <div class="info-cell">
      <div class="info-cell__label info-cell__label_ellipsis">Контроль оплати</div>
      <div class="info-cell__content"
           *ngIf="register?.TotalAfterpaymentOnGoodsCost !== 0"
           data-test-id="TotalAfterpaymentOnGoodsCost">{{ register?.TotalAfterpaymentOnGoodsCost + ' грн' }}
      </div>
      <div class="info-cell__content" *ngIf="register?.TotalAfterpaymentOnGoodsCost === 0"
           data-test-id="TotalAfterpaymentOnGoodsCost">—
      </div>
    </div>
  </div>
</div>

<div *ngIf="(dataSource.total$ | async) === 0 && (dataSource.loading$ | async) && !register">
  <mat-spinner class="mat-spinner-el" [diameter]="56"></mat-spinner>
</div>

<div class="content-shadow" [hidden]="(dataSource.total$ | async) === 0 || !register">

  <stf-table-toolbar [hasSelected]="selection.hasValue()">
    <section table-has-selected>
      <section class="mat-table-toolbar mat-default mat-accent">
        <div class="mat-toolbar-tools">
                <span class="mat-toolbar-tools__counter">
                  <span>Обрано: {{ selection.selected.length }}</span>
                </span>
          <div class="toolbar-icons toolbar-icons__mat-toolbar-tools" *ngIf="!customRegisters">
            <button mat-button class="mat-button_transparent mat-button_sm mat-button_with-icon mat-button_uppercase"
                    (click)="removeSelected()"
                    [matTooltip]="'Видалити'" matTooltipPosition="below"
                    data-test-id="RemoveRegister-EW">
              <mat-icon class="material-icons material-icons_delete-sweep fz-28">delete_sweep</mat-icon>
              <span>Видалити з реєстру</span>
            </button>
            <!-- Print Menu -->
            <button mat-button class="mat-button_transparent mat-button_sm mat-button_with-icon mat-button_uppercase"
                    [matMenuTriggerFor]="printSelectedMenu"
                    (click)="$event.stopPropagation()"
                    data-test-id="PrintRegister-EW">
              <i class="material-icons">print</i>
              Роздрукувати
            </button>
            <mat-menu #printSelectedMenu="matMenu" class="mat-menu_width_3">
              <button mat-menu-item (click)="printMarkingZebraHundred(selection.selected, 'pdf')"
                      *ngIf="!printingm100x100"
                      data-test-id="PrintRegister-EW-100*100">
                Маркування 100*100 типу "зебра", PDF
              </button>
              <button mat-menu-item (click)="printSettings('', 'Marking6')"
                      data-test-id="PrintRegister-EW-Marking6">
                Маркування на листі А4 (6 наклейок)
              </button>
              <button mat-menu-item (click)="printA5(selection.selected, 'pdf')"
                      data-test-id="PrintRegister-EW-A5">
                Експрес-накладну, 1 копiя (А5)
              </button>
              <button mat-menu-item (click)="printOneCopy(selection.selected, 'pdf')"
                      data-test-id="PrintRegister-EW-1copy">
                Експрес-накладну, 1 копiя
              </button>
              <button mat-menu-item (click)="print(selection.selected, 'pdf')"
                      data-test-id="PrintRegister-EW-2copy">
                Експрес-накладну, 2 копiї
              </button>
            </mat-menu>
            <!-- Print Menu End -->

          </div>
        </div>
      </section>
    </section>

    <section table-has-no-selected>
      <section class="mat-table-toolbar mat-default">
        <div class="mat-toolbar-tools">
          <span class="mat-toolbar-tools__title mat-toolbar-tools__title_sm" (click)="goToRegisties()">посилки</span>
          <stf-table-col-filter [cols]="colsConfRun" (colsChange)="onColsChange($event)"
                                class="mat-toolbar-tools__menu" [color]="'rgba(0,0,0,.56)'"></stf-table-col-filter>
        </div>
      </section>
    </section>

  </stf-table-toolbar>

  <div class="table-wrapper">
    <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <ng-container matColumnDef="select">
        <th mat-header-cell class="mat-checkbox-cell position-sticky" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell class="mat-checkbox-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="docNumber">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Номер
        </th>
        <td mat-cell *matCellDef="let invoice">
          <div class="doc-number-container">
            <span class="nowrap doc-number copy-content">
              <span class="fine-doc-number" [innerHTML]="invoice.IntDocNumber | fineDocSearch"></span>
              <button mat-icon-button class="copy-content__btn"
                      [appCopy]="invoice?.IntDocNumber"
                      [matTooltip]="'Скопіювати'" matTooltipPosition="below">
              <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
            </button>
            </span>
            <i *ngIf="invoice.LightReturn"
               class="material-icons material-icons_grey material-icons_table outline_none"
               [matTooltip]=" 'Легке повернення посилки' + (invoice.LightReturnNumber ? ' ' + invoice.LightReturnNumber : '')"
               matTooltipPosition="below">
              subdirectory_arrow_left</i>
            <i *ngIf="invoice.isInternational"
               class="material-icons material-icons_table material-icons_red material-icons-hover_red"
               [matTooltip]="'Міжнародна доставка'" matTooltipPosition="below">language
            </i>
            <i *ngIf="!invoice.securePayment && invoice.backDeliveries?.Money"
               [matTooltip]="('Грошовий переказ') + ': ' + invoice.backDeliveries.Money.RedeliveryString + ' грн'"
               class="material-icons material-icons_grey material-icons_table outline_none"
               matTooltipPosition="below">reply
            </i>
            <i *ngIf="invoice.securePayment && invoice.backDeliveries?.Money"
               [matTooltip]="('Сейф-сервіс') + ': ' + invoice.backDeliveries.Money.RedeliveryString + ' грн'"
               class="material-icons material-icons_grey material-icons_table outline_none"
               matTooltipPosition="below">reply
            </i>
            <i *ngIf="invoice && invoice.paymentControl && !!invoice.paymentControl"
               class="material-icons material-icons_grey material-icons_table outline_none"
               [matTooltip]="'Контроль оплати' + ': ' + (invoice.paymentControl + ' грн')"
               matTooltipPosition="below">reply
            </i>
            <i *ngIf="invoice.backDeliveriesTooltipDescription"
               class="material-icons material-icons_grey material-icons_table outline_none"
               [matTooltip]="('Зворотна доставка') + ': ' + invoice.backDeliveriesTooltipDescription"
               matTooltipPosition="below">reply
            </i>
            <i *ngIf="invoice.isPrinted === true"
               class="material-icons material-icons_grey material-icons_table outline_none"
               [matTooltip]="'Накладну роздруковано'"
               matTooltipPosition="below">print
            </i>
            <ng-container *ngIf="!features.CanCourierCall">
              <i
                *ngIf="!!invoice.serviceType && !!invoice.serviceType.id && (invoice.serviceType.id === 'DoorsWarehouse' || invoice.serviceType.id === 'DoorsDoors')"
                class="material-icons material-icons_grey material-icons_table outline_none"
                [matTooltip]="'Замовлено виклик кур\'єра'"
                matTooltipPosition="below">local_shipping
              </i>
            </ng-container>
            <i
              *ngIf="!!invoice.DeliveryPharma"
              [matTooltip]="'Фарма'"
              class="material-icons material-icons_grey material-icons_table outline_none"
              matTooltipPosition="below">medication
            </i>
            <i *ngIf="!!invoice.CanCourierCall"
               [matTooltip]="'Доступний виклик кур’єра'"
               class="material-icons material-icons_grey material-icons_table outline_none"
               matTooltipPosition="below"
            >record_voice_over
            </i>
            <i *ngIf="!!invoice.PickupNumber"
               [matTooltip]="'Додано у виклик кур\'єра: ' + invoice.PickupNumber"
               class="material-icons material-icons_grey material-icons_table outline_none"
               matTooltipPosition="below"
            >directions_bus_filled
            </i>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            app-sort-header>Статус
        </th>
        <td mat-cell *matCellDef="let invoice" [class.np-invoices-in__status-col]="invoice.state"
            [ngClass]="invoice.state ? getInvoiceStatusClass(invoice.state?.id) : null">
          <span>{{ invoice.state?.shortName || 'В обробці' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="scanSheetNumber">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            app-sort-header>Номер реєстру
        </th>
        <td mat-cell *matCellDef="let invoice">
              <span class="nowrap doc-number copy-content" *ngIf="invoice?.ScanSheetNumber; else empty">
                <span>{{ invoice?.ScanSheetNumber || '&mdash;' }}</span>
                <button mat-icon-button class="copy-content__btn"
                        [appCopy]="invoice?.ScanSheetNumber"
                        [matTooltip]="'Скопіювати'" matTooltipPosition="below">
                  <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
                </button>
              </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Дата створення
        </th>
        <td mat-cell *matCellDef="let invoice">
          <ng-container *ngIf="!!invoice.CreateTime; else empty">
            {{ invoice.CreateTime | np_to_date_format | date :"dd.MM.yyyy HH:mm:ss" }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="estimatedDeliveryDate">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Плановий час доставки
        </th>
        <td mat-cell
            *matCellDef="let invoice">{{ (invoice.estimatedDeliveryDate || invoice.EstimatedDeliveryDate) | np_to_date_format | deliveryDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cost">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Оголошена вартість
        </th>
        <td mat-cell *matCellDef="let invoice">{{ (invoice.Cost || 0) + ' грн' }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Вартiсть доставки
        </th>
        <td mat-cell *matCellDef="let invoice">
          <div *ngIf="invoice?.CostOnSite !== invoice?.CheckCost && invoice?.CheckCost">
            <span class="line-through-text">{{ invoice?.CostOnSite }}</span>
            {{ (invoice?.CheckCost) + ' грн' }}
          </div>
          <div *ngIf="invoice?.CostOnSite === invoice?.CheckCost || !invoice?.CheckCost">
            {{ (invoice?.CostOnSite) + ' грн' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="weight">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Вага
        </th>
        <td mat-cell *matCellDef="let invoice">
          <div *ngIf="!invoice?.CheckWeight || +invoice?.CheckWeight === 0 || invoice.Weight === invoice?.CheckWeight">
            <span class="nowrap">{{ (invoice.Weight) + ' кг' }}</span>
          </div>
          <div *ngIf="+invoice?.CheckWeight > 0 && invoice.Weight !== invoice?.CheckWeight">
            <span class="line-through-text nowrap">{{ invoice.Weight }}</span> {{ (invoice?.CheckWeight) + ' кг' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Кількість місць
        </th>
        <td mat-cell *matCellDef="let invoice">{{ (invoice.SeatsAmount || 0) }}</td>
      </ng-container>

      <ng-container matColumnDef="barcode">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Внутрішній номер
        </th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.InfoRegClientBarcodes || '&mdash;' }}</td>
      </ng-container>

      <ng-container matColumnDef="senderDescription">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Відправник
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.SenderDescription || '&mdash;' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="senderContactPerson">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Контакт відправника
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.SenderContactPerson || '&mdash;' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="SendersPhone">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            app-sort-header>Телефон відправника
        </th>
        <td mat-cell *matCellDef="let invoice">
          <span class="nowrap copy-content" *ngIf="invoice?.SendersPhone; else empty">
            {{ invoice?.SendersPhone | npFinePhone }}
            <button mat-icon-button class="copy-content__btn"
                    [appPhoneCopy]="invoice?.SendersPhone"
                    [matTooltip]="'Скопіювати'" matTooltipPosition="below">
              <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
            </button>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="citySenderDescription">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Місто відправлення
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.CitySenderDescription || '&mdash;' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="senderAddressDescription">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Адреса відправлення
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.SenderAddressDescription || '&mdash;' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="backDelivery">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Грошовий переказ
        </th>
        <td mat-cell *matCellDef="let invoice">
          <span *ngIf="invoice.BackwardDeliveryMoney">{{ invoice.BackwardDeliveryMoney + ' грн' }}</span>
          <span *ngIf="!invoice.BackwardDeliveryMoney">&mdash;</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="securePayment">
        <th *matHeaderCellDef
            app-sort-header
            mat-header-cell
            mat-sort-header>Сейф-сервіс
        </th>
        <td *matCellDef="let invoice" mat-cell>
          <span *ngIf="invoice.securePayment; else empty">Так</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentControl">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Контроль оплати
        </th>
        <td mat-cell *matCellDef="let invoice">
          <span *ngIf="invoice.AfterpaymentOnGoodsCost !== '0.00'">{{ invoice.AfterpaymentOnGoodsCost + ' грн' }}</span>
          <span *ngIf="invoice.AfterpaymentOnGoodsCost === '0.00'">&mdash;</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="counterpartyRecipientDescription">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Отримувач
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.RecipientDescription }}
        </td>
      </ng-container>

      <ng-container matColumnDef="receiver">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Контакт отримувача
        </th>
        <td mat-cell *matCellDef="let invoice">
          {{ invoice.RecipientContactPerson }}
        </td>
      </ng-container>

      <ng-container matColumnDef="receiverPhone">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Телефон отримувача
        </th>
        <td mat-cell *matCellDef="let invoice">
             <span class="nowrap copy-content" *ngIf="invoice?.RecipientContactPhone; else empty">
             {{ invoice?.RecipientContactPhone | npFinePhone }}
               <button mat-icon-button class="copy-content__btn"
                       [appPhoneCopy]="invoice?.RecipientContactPhone"
                       [matTooltip]="'Скопіювати'" matTooltipPosition="below">
                 <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
               </button>
             </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="receiverCity">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Місто отримувача
        </th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.CityRecipientDescription }}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Адреса отримання
        </th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.RecipientAddressDescription }}</td>
      </ng-container>

      <ng-container matColumnDef="payerType">
        <th mat-header-cell
            mat-sort-header
            app-sort-header
            *matHeaderCellDef>Платник за доставку
        </th>
        <td mat-cell *matCellDef="let invoice">{{ payerNameTransations[invoice.PayerType] || '&mdash;' }}</td>
      </ng-container>

      <ng-container matColumnDef="lightReturn">
        <th *matHeaderCellDef
            app-sort-header
            mat-header-cell
            mat-sort-header>Легке повернення посилки
        </th>
        <td *matCellDef="let invoice" mat-cell>
          <div class="doc-number-container">
            <span class="nowrap doc-number copy-content" *ngIf="invoice.LightReturnNumber; else noLightReturnNumber;">
              <span [innerHTML]="invoice.LightReturnNumber | fineDocSearch" class="fine-doc-number"></span>
              <button [appCopy]="invoice?.LightReturnNumber" [matTooltip]="'Скопіювати'"
                      class="copy-content__btn"
                      mat-icon-button matTooltipPosition="below">
                <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
              </button>
            </span>
            <ng-template #noLightReturnNumber>
              &mdash;
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="mat-icon-cell" *matCellDef="let invoice">
          <div [hidden]="selection.hasValue()">
            <button mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="!customRegisters"
                    (click)="$event.stopPropagation()"
                    data-test-id="EW-menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #appMenu="matMenu" class="mat-menu_width_3">
            <button
              *ngIf="register && (register.printed !== '1' && invoice.Printed !== '1') && !invoice.LightReturn && invoice.StateId == '1'"
              mat-menu-item
              (click)="show(invoice)"
              data-test-id="EW-menu-edit">
              <mat-icon>mode_edit</mat-icon>
              Редагувати
            </button>
            <button mat-menu-item [matMenuTriggerFor]="printMenu"
                    data-test-id="EW-menu-print">
              <mat-icon>print</mat-icon>
              Друкувати
            </button>
            <button mat-menu-item (click)="shareStatus(invoice)"
                    data-test-id="EW-menu-share-status">
              <mat-icon>share</mat-icon>
              Поділитися статусом
            </button>
            <button mat-menu-item
                    (click)="remove(invoice.Ref)"
                    data-test-id="EW-menu-remove-register">
              <mat-icon class="material-icons material-icons_delete-sweep fz-28" disabled>delete_sweep</mat-icon>
              Видалити з реєстру
            </button>
          </mat-menu>
          <mat-menu #printMenu="matMenu" class="mat-menu_width_3">
            <button mat-menu-item (click)="printMarkingZebraHundred(dataSource.invoices, 'pdf')"
                    *ngIf="!printingm100x100"
                    data-test-id="EW-menu-print-100*100">
              Маркування 100*100 типу "зебра", PDF
            </button>
            <button mat-menu-item (click)="printSettings([invoice], 'Marking6')"
                    data-test-id="EW-menu-print-Marking6">
              Маркування на листі А4 (6 наклейок)
            </button>
            <button mat-menu-item (click)="printA5([invoice], 'pdf')"
                    data-test-id="EW-menu-print-A5">
              Експрес-накладну, 1 копiя (А5)
            </button>
            <button mat-menu-item (click)="printOneCopy([invoice], 'pdf')"
                    data-test-id="EW-menu-print-1copy">
              Експрес-накладну, 1 копiя
            </button>
            <button mat-menu-item (click)="print([invoice], 'pdf')"
                    data-test-id="EW-menu-print-2copy">
              Експрес-накладну, 2 копiї
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-row class="mat-row-clickable" *matRowDef="let invoice; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="dataSource.total$ | async" [pageSize]="itemsPerPage" (page)="onPageChange($event)"
                 [showFirstLastButtons]="false" [pageSizeOptions]="limitsList"></mat-paginator>
</div>

<div *ngIf="!(dataSource.loading$ | async) && ((dataSource.total$ | async) === 0 || !register)">
  <div class="p-6">
    <p class="register-content__text register-content__text_lg">В реєстрі немає жодної посилки</p>
    <p class="register-content__text">Для швидкого додавання посилок, виділіть їх галочками та виконайте дію «Додати до
                                      реєстру»</p>
  </div>
</div>
<ng-template #empty>&mdash;</ng-template>

import { Injectable } from '@angular/core';
import { InvoiceResponseModel } from '@models';
import { AuthService } from '@services';
import { GetLightReturnReasonsData } from '@shared';
import * as _ from 'lodash';
import { forkJoin as observableForkJoin, map, Observable, of as observableOf } from 'rxjs';
import { InvoiceCalculations } from '../../models/invoice/invoice-calculations.model';
import { InvoiceCargoType } from '../../models/invoice/invoice-cargo-type.model';
import { InvoiceContact } from '../../models/invoice/invoice-contact.model';
import { InvoicePallet } from '../../models/invoice/invoice-pallet.model';
import { InvoiceServiceType } from '../../models/invoice/invoice-service-type.model';
import { InvoiceSize } from '../../models/invoice/invoice-size.model';
import { Invoice } from '../../models/invoice/invoice.model';
import { TireWheel } from '../../models/invoice/tire-wheal.model';
import { IFilterParam, IOrderParameter } from './iapi/iinvoice.api';
import { InvoiceApi } from './invoice.api';

declare var ga: any;

const DEFAULT_ANNOUNCED_PRICE = 200;

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  readonly defaultInvoiceWeight = 0.5;
  invoiceSaved: any;
  invoiceCopy: any;
  win: Window;

  constructor(
    protected invoiceApiService: InvoiceApi,
    protected authService: AuthService,
  ) {
  }

  get defaultInvoiceSize() {
    const defaultInvoiceSize = new InvoiceSize();
    defaultInvoiceSize.length = 16.5;
    defaultInvoiceSize.width = 11.5;
    defaultInvoiceSize.height = 10;

    return defaultInvoiceSize;
  }

  getInvoiceList(
    filter: IFilterParam = {},
    orderParam: IOrderParameter = null,
    page: number = 1,
    limit: number = 20,
    counterpartyRefs?: string[],
    backendFiltersConfiguration?: { [key: string]: any },
  ): Observable<any> {
    return this.invoiceApiService.getList(
      filter,
      orderParam,
      page,
      limit,
      counterpartyRefs,
      backendFiltersConfiguration,
    );
  }

  getMoneyTransfers(
    filter: IFilterParam = {},
    orderParam: IOrderParameter = null,
    page: number = 1,
    limit: number = 20,
    counterpartyRefs?: string[],
  ): Observable<any> {
    return this.invoiceApiService.getMoneyTransfers(
      filter,
      orderParam,
      page,
      limit,
      counterpartyRefs,
    );
  }

  remove(id: string): Observable<boolean> {
    return this.invoiceApiService.remove(id);
  }

  removeList(ids: string[]): Observable<boolean> {
    const removed: Observable<boolean>[] = [];
    _.each(ids, (id) => {
      removed.push(this.remove(id));
    });

    return observableForkJoin(removed).pipe(
      map((r) => r.every((done) => !!done)),
    );
  }

  getOne(id: string): Observable<Invoice> {
    return this.invoiceApiService.getOne(id);
  }

  getNewInvoice(): Invoice {
    let invoice: Invoice;

    if (this.invoiceSaved) {
      invoice = this.invoiceSaved;
      invoice.id = null;
      this.invoiceSaved = null;
      // invoice.setMetaData('saved', true);
      invoice.isPrinted = false;
      if (!invoice.backDelivery) {
        invoice.backDelivery = new InvoiceCargoType();
        invoice.backDelivery.id = null;
      }

      if (!invoice.sender) {
        invoice.sender = _.assign(new InvoiceContact(), this.authService.user);
      }

      if (!invoice.sizes) {
        invoice.sizes = [this.defaultInvoiceSize];
      }

      if (!invoice.weights) {
        invoice.weights = [this.defaultInvoiceWeight];
      }

      invoice.announcedPrice = invoice.announcedPrice
        ? invoice.announcedPrice
        : DEFAULT_ANNOUNCED_PRICE;
      invoice.backDeliveryPayer = invoice.backDeliveryPayer
        ? invoice.backDeliveryPayer
        : 'Recipient';
      invoice.payer = invoice.payer ? invoice.payer : 'Sender';
    } else {
      invoice = new Invoice();
      invoice.sender = _.assign(new InvoiceContact(), this.authService.user);
      invoice.sizes = [this.defaultInvoiceSize];
      invoice.weights = [this.defaultInvoiceWeight];
      invoice.backDelivery = new InvoiceCargoType();
      invoice.backDelivery.id = null;
      invoice.announcedPrice = DEFAULT_ANNOUNCED_PRICE;
      invoice.backDeliveryPayer = 'Recipient';
      invoice.payer = 'Sender';
      invoice.cargoType = new InvoiceCargoType('Cargo');
    }

    return invoice;
  }

  getTires(): Observable<TireWheel[]> {
    return this.invoiceApiService.getTires();
  }

  add(invoice: Invoice): Observable<Invoice> {
    invoice = this._determinateServiceType(invoice);
    invoice = this._changeParamsAccordinglyServiceType(invoice);
    invoice = this._backDeliveryProcess(invoice);

    return this.invoiceApiService.add(invoice).pipe(
      map((data) => {
        ga('send', 'event', 'Form', 'Submission', 'Parcel_creation');
        return data;
      }),
    );
  }

  getPalletesTypes(): Observable<InvoicePallet[]> {
    return observableOf(
      [
        {
          id: '627b0c26-d110-11dd-8c0d-001d92f78697',
          description: 'Палета до 0,49 м2',
          weight: 204,
          maxSquere: 0.49,
        },
        {
          id: '627b0c25-d110-11dd-8c0d-001d92f78697',
          description: 'Палета від 0,5 м2 до 0,99 м2',
          weight: 408,
          maxSquere: 0.99,
        },
        {
          id: '627b0c24-d110-11dd-8c0d-001d92f78697',
          description: 'Палета від 1 м2 до 1,49 м2',
          weight: 612,
          maxSquere: 1.49,
        },
        {
          id: '627b0c23-d110-11dd-8c0d-001d92f78697',
          description: 'Палета від 1,5 м2 до 2 м2',
          weight: 816,
          maxSquere: 2,
        },
      ].map((pallet) => Object.assign(new InvoicePallet(pallet.id), pallet)),
    );
  }

  update(invoice: Invoice): Observable<Invoice> {
    invoice = this._determinateServiceType(invoice);
    invoice = this._changeParamsAccordinglyServiceType(invoice);
    invoice = this._backDeliveryProcess(invoice);

    return this.invoiceApiService.update(invoice).pipe(
      map((data) => {
        ga('send', 'event', 'Form', 'Submission', 'Parcel_editing');
        return data;
      }),
    );
  }

  printDocuments(
    invoicesIds: string[],
    type?: string,
    props?: any,
  ): Observable<string> {
    return this.invoiceApiService.printDocuments(invoicesIds, type, props).pipe(
      map((data) => {
        this.openBlob(data, type);
        return null;
      }),
    );
  }

  printCopies(invoiceIds: string[], copies: number = 1): Observable<string> {
    return this.invoiceApiService.printCopies(invoiceIds, copies).pipe(
      map((data) => {
        this.openBlob(data, 'pdf');
        return null;
      }),
    );
  }

  openBlob(data, type) {
    const file = new Blob([data], {
      type: type.indexOf('pdf') === 0 ? 'application/pdf' : 'text/html',
    });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(file, 'invoices.' + type);
    } else {
      const url = URL.createObjectURL(file);
      this.win = window.open(url, '_blank');
    }
  }

  printDocsNew(
    invoicesNumbers: string[],
    type?: string,
    form?: string,
  ): Observable<string> {
    return this.invoiceApiService
      .printDocsNew(invoicesNumbers, type, form)
      .pipe(
        map((data) => {
          this.openBlob(data, type);
          return null;
        }),
      );
  }

  printMarking(invoicesIds: string[], type?: string): Observable<string> {
    return this.invoiceApiService.printMarking(invoicesIds, type).pipe(
      map((data) => {
        this.openBlob(data, type);
        return null;
      }),
    );
  }

  printMarkingZebra(invoicesIds: string[], type?: string): Observable<string> {
    return this.invoiceApiService.printMarking(invoicesIds, type).pipe(
      map((data) => {
        this.openBlob(data, type);
        return null;
      }),
    );
  }

  printMarkingZebraHundred(
    invoicesIds: string[],
    type: string = 'pdf',
  ): Observable<string> {
    return this.invoiceApiService
      .printMarkingZebraHundred(invoicesIds, type)
      .pipe(
        map((data) => {
          this.openBlob(data, type);
          return null;
        }),
      );
  }

  printMarkings(invoicesIds: string[]): Observable<string> {
    return this.invoiceApiService.printMarkings(invoicesIds).pipe(
      map((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(
            file,
            'invoice-markers.pdf',
          );

          return null;
        }

        return URL.createObjectURL(file);
      }),
    );
  }

  calculatePrice(invoice: Invoice): Observable<InvoiceCalculations> {
    invoice = this._determinateServiceType(invoice);
    invoice = this._changeParamsAccordinglyServiceType(invoice);

    if (
      invoice.recipient &&
      invoice.recipientAddress &&
      invoice.senderAddress
    ) {
      return this.invoiceApiService.calculatePrice(invoice);
    } else {
      return observableOf(null);
    }
  }

  cancelDeletion(ids: string[]): Observable<boolean> {
    return this.invoiceApiService.cancelDeletion(ids);
  }

  track(
    invoiceNumber: string,
    system: boolean = true,
    phone?: string,
  ): Observable<Invoice> {
    return this.invoiceApiService.track(invoiceNumber, system, phone);
  }

  trackMany(invoiceNumbers: string[]): Observable<Invoice[]> {
    return this.invoiceApiService.trackMany(invoiceNumbers);
  }

  trackManyForSearch(invoicePhoneAndNumbers: any[]): Observable<Invoice[]> {
    return this.invoiceApiService.trackMany(invoicePhoneAndNumbers);
  }

  trackManyWithBinding(
    invoiceNumbers: string[],
    chunkSize = 100,
    system: boolean = true,
  ): Observable<Invoice[]> {
    return this.invoiceApiService.trackManyWithBinding(
      invoiceNumbers,
      chunkSize,
      system,
    );
  }

  trackInvoicesForSearch(
    invoicePhoneAndNumbers: string[],
    chunkSize = 100,
  ): Observable<Invoice[]> {
    return this.invoiceApiService.trackInvoicesForSearch(
      invoicePhoneAndNumbers,
      chunkSize,
    );
  }

  getRefList(refs: string[]): Observable<InvoiceResponseModel[]> {
    return this.invoiceApiService.getRefList(refs);
  }

  calculateVolumeWeight(size: InvoiceSize): number {
    if (size) {
      return (size.height * size.length * size.width) / 4000 || 0;
    } else {
      return 0;
    }
  }

  makeFormatedInvoiceNumberSearch(number: string): string {
    if (!number) {
      return '';
    }

    const firstNumberLength = number.length % 4;
    const length: number = number.length / 4 + 1;
    let formatedNumber = '';
    let start = 0;

    for (let i = 0; i < length; i++) {
      let step = 4;
      if (i === 0) {
        step = firstNumberLength;
      } else {
        formatedNumber += '</span><span>';
      }

      formatedNumber += number.substr(start, step);

      start += step;
    }

    return '<span>' + formatedNumber.trim() + '</span>';
  }

  makeFormatedInvoiceNumber(number: string): string {
    if (!number) {
      return '';
    }

    const firstNumberLength = number.length % 4;
    const length: number = number.length / 4 + 1;
    let formatedNumber = ' ';
    let start = 0;

    for (let i = 0; i < length; i++) {
      let step = 4;
      if (i === 0) {
        step = firstNumberLength;
      } else {
        formatedNumber += ' ';
      }

      formatedNumber += number.substr(start, step);

      start += step;
    }

    return formatedNumber.trim();
  }

  checkServicesAvailability(): Observable<any> {
    return this.invoiceApiService.checkServicesAvailability();
  }

  checkLightReturn(invoice: string): Observable<GetLightReturnReasonsData | null> {
    return this.invoiceApiService.checkPossibilityLightReturn(invoice)
      .pipe(
        map(response => this.checkLightReturnErrors(response)),
      );
  }

  private checkLightReturnErrors(response) {
    if (!response.data[0] || !response.data[0]?.subReasonsLightReturn || response.data[0]?.subReasonsLightReturn?.length === 0) {
      return null;
    }
    return response.data;
  }

  createLightReturn(reason: string, invoice: string): Observable<any> {
    return this.invoiceApiService.createLightReturn(reason, invoice);
  }

  protected _determinateServiceType(invoice: Invoice) {
    invoice.serviceType = new InvoiceServiceType(
      (invoice.senderAddress &&
      invoice.senderAddress.street &&
      invoice.senderAddress.street.id
        ? 'Doors'
        : 'Warehouse') +
      (invoice.recipientAddress &&
      invoice.recipientAddress.street &&
      invoice.recipientAddress.street.id
        ? 'Doors'
        : 'Warehouse'),
    );

    return invoice;
  }

  protected _changeParamsAccordinglyServiceType(invoice: Invoice) {
    if (
      invoice.serviceType.id === 'WarehouseWarehouse' ||
      invoice.serviceType.id === 'DoorsWarehouse'
    ) {
      delete invoice.senderElevator;
      delete invoice.recipientElevator;
      delete invoice.recipientNumberOfFloorsLifting;
      delete invoice.senderNumberOfFloorsLifting;
      delete invoice.desiredTimeInterval;
      delete invoice.saturdayDelivery;
    }

    return invoice;
  }

  protected _backDeliveryProcess(invoice: Invoice) {
    if (invoice.backDelivery.id === 'Money') {
      delete invoice.backDeliveryDescription;
    } else if (invoice.backDelivery.id) {
      delete invoice.backMoney;
    }

    if (!invoice.backDelivery || !invoice.backDelivery.id) {
      delete invoice.backDeliveryPayer;
    }

    return invoice;
  }
}

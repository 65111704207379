import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiError, IListData, IOrderParameter } from '@models';
import { AlertToastService, AuthService } from '@services';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, from, map, Observable } from 'rxjs';
import { InvoiceRegister } from '../../models/invoice/invoice-register.model';
import { ConfigService } from '../config.service';
import { IInvoiceRegisterFilterParam } from '../invoices/iapi/iinvoice-register.api';
import { NoRestApiHelper } from '../no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class InvoiceRegisterApiService {

  win: Window;

  constructor(
    protected http: HttpClient,
    protected configService: ConfigService,
    protected _noRestApiHelper: NoRestApiHelper,
    protected _authorizationService: AuthService,
    protected alertToastService: AlertToastService,
  ) {
  }

  getList(filter: IInvoiceRegisterFilterParam = { isOpen: true },
    order: IOrderParameter, page = 1, limit = 10000): Observable<IListData<InvoiceRegister>> {

    const promise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        modelName: 'ScanSheet',
        system: this.configService.get('system'),
        calledMethod: 'getScanSheetList',
        methodProperties: {
          Open: filter.isOpen ? '1' : undefined,
          Page: page,
          Limit: limit,
          SearchByCounterparties: this._authorizationService.user.contacts ? 1 : null,
        },
      });

    return promise
      .pipe(
        map(data => this._noRestApiHelper.checkErrors(data)),
        map((data: any) => this.bindListToModel(data)),
      );
  }

  getOne(id: string): Observable<InvoiceRegister> {
    const promise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'getScanSheet',
        methodProperties: {
          Ref: id,
        },
      });

    return promise
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data)),
        map(data => this._bindOneToModel(data)),
      );
  }

  remove(id: string): Observable<any> {
    const deletePromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        modelName: 'ScanSheet',
        calledMethod: 'deleteScanSheet',
        methodProperties: {
          ScanSheetRefs: [id],
        },
      });

    //noinspection TypeScriptUnresolvedFunction
    return from(deletePromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data)),
        map(data => data.data),
      );
  }

  removeList(ids: string[]): Observable<boolean> {
    const removed: Observable<boolean>[] = [];
    _.each(ids, id => {
      removed.push(this.remove(id));
    });

    return forkJoin(removed).pipe(
      map((r: any) => r.every(done => !!done)));
  }

  printDocuments(invoicesIds: string[]): Observable<any> {
    const printDocumentsPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'printScanSheet',
        methodProperties: {
          Ref: invoicesIds,
          Type: 'pdf',
        },
      }, { responseType: 'arraybuffer' });

    return from(printDocumentsPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map((response: any) => response.data),
      );
  }

  printDocumentsNew(invoicesIds: string[], type: string = 'pdf', orientation: string): Observable<any> {
    const printDocumentsPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'InternetDocument',
        calledMethod: 'printFull',
        methodProperties: {
          printForm: 'ScanSheet',
          ScanSheetRefs: invoicesIds,
          Type: type,
          PrintOrientation: orientation,
        },
      }, { responseType: 'arraybuffer' });

    return from(printDocumentsPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map((response: any) => response.data),
        map(data => {
          this.openBlob(data, type);
          return null;
        }),
      );
  }

  openBlob(data, type): void {
    const file = new Blob([data], { type: type.indexOf('pdf') === 0 ? 'application/pdf' : 'text/html' });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(file, 'invoices.' + type);
    } else {
      const url = URL.createObjectURL(file);
      this.win = window.open(url, '_blank');
    }
  }

  printMarking(invoicesIds: string[], type: string = 'pdf'): Observable<any> {
    const printDocumentsPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'InternetDocument',
        calledMethod: 'printFull',
        methodProperties: {
          printForm: 'Marking',
          DocumentRefs: invoicesIds,
          Type: type,
          Position: '',
        },
      }, { responseType: 'arraybuffer' });

    return from(printDocumentsPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map((response: any) => response.data),
        map(data => {
          this.openBlob(data, type);
          return null;
        }),
      );
  }

  addWithInvoices(register: InvoiceRegister, idInvoices: string[], date?: Date): Observable<InvoiceRegister> {
    const addPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'insertDocuments',
        methodProperties: {
          Description: register.description,
          DocumentRefs: idInvoices,
          SearchByCounterparties: this._authorizationService.user.contacts ? 1 : null,
          Date: moment(date).format('DD.MM.YYYY'),
          iCounterparties: this._authorizationService.user.contacts
            ? this._authorizationService.user.contacts.map(contact => contact.CounterpartyRef) : null,
        },
      })
    ;

    return from(addPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data, 'Помилка додавання')),
        map(data => this._bindOneToModel(data)),
      );
  }

  add(item: InvoiceRegister): Observable<InvoiceRegister> {
    console.log('using a weird method');
    return null;
  }

  createEmptyRegister(date?: Date, Description?: string): Observable<InvoiceRegister> {
    const insertPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'createEmptyScanSheet',
        methodProperties: {
          Date: moment(date || new Date()).format('DD.MM.YYYY'),
          Description,
        },
      });

    return from(insertPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors(data)),
        map((data: any) => this._bindOneToModel(data)),
      );
  }

  update(item: InvoiceRegister): Observable<InvoiceRegister> {
    const insertPromise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'updateScanSheet',
        methodProperties: {
          Ref: item.id,
          Description: item.description,
        },
      })
    ;

    return from(insertPromise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data)),
        map((data: any) => data.data.success),
      );
  }

  insertDocuments(idRegister: string, idInvoices: string[]): Observable<InvoiceRegister> {
    return this.http.post(`${this.configService.get('apiUrl')}json/`, {
      system: this.configService.get('system'),
      modelName: 'ScanSheet',
      calledMethod: 'insertDocuments',
      methodProperties: {
        Ref: idRegister,
        DocumentRefs: idInvoices,
      },
    })
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data, 'Помилка додавання')),
        map((data: any) => data.success),
      );
  }

  deleteInvoices(idRegister: string, idsInvoices: string[]): Observable<boolean> {
    const promise: any = this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'ScanSheet',
        calledMethod: 'removeDocuments',
        methodProperties: {
          Ref: idRegister,
          DocumentRefs: idsInvoices,
        },
      })
    ;

    return from(promise)
      .pipe(
        map(data => this._noRestApiHelper.checkErrors({ data })),
        map(data => this.checkErrors(data, 'Помилка видалення')),
        map((data: any) => data.data),
      );
  }

  bindListToModel(data: any): IListData<InvoiceRegister> {
    return {
      list: _.map(data.data, (el: any) => {
        const invoiceRegister = new InvoiceRegister(el.Ref);
        invoiceRegister.docNumber = el.Number;
        invoiceRegister.printed = el.Printed;
        // TODO should we set today's date if DateTime is empty?
        if (el.DateTime) {
          invoiceRegister.dateTime = moment(el.DateTime).toDate();
        }
        invoiceRegister.description = el.Description;
        invoiceRegister.marketplacePartnerDescription = el.MarketplacePartnerDescription;
        invoiceRegister.count = el.Count;
        return invoiceRegister;
      }),
      total: data.info.totalCount,
    };
  }

  checkErrors(
    data: any,
    typeMessage = null,
  ): any {
    data = data.data;
    let responseErrors: any[] = _.get(data, 'data[0].Data.Errors', []);
    let responseWarnings: any[] = _.get(data, 'data[0].Data.Warnings', []);
    let successArray = _.get(data, 'data[0].Success', []);

    const responseErrors1: any[] = _.get(data, 'data[0].Errors', []);
    const responseWarnings1: any[] = _.get(data, 'data[0].Warnings', []);

    if (!responseErrors.length && responseErrors1.length) {
      responseErrors = responseErrors1;
    }

    if (!responseWarnings.length && responseWarnings1.length) {
      responseWarnings = responseWarnings1;
    }

    if (typeMessage === 'Помилка видалення') {
      responseErrors = _.get(data, 'data.DocumentRefs.Errors', []);
      successArray = _.get(data, 'data.DocumentRefs.Success', []);
    }

    if ((responseErrors[0] || responseWarnings[0]) && typeMessage) {
      const errors: Set<IApiError> = new Set();

      responseErrors.map(el => errors.add(el.Error));
      responseWarnings.map(el => errors.add(el.Warning));

      if (errors.size) {
        if (!successArray.length) {
          this.alertToastService
            .pushRegisterApiErrors(
              Array.from(errors),
              typeMessage === 'Помилка додавання' ?
                'Попередження! Обрані документи не додано до реєстру' :
                'Попередження! Обрані документи не видалено з реєстру',
              typeMessage,
            );
        } else {
          this.alertToastService
            .pushRegisterApiErrors(
              Array.from(errors),
              typeMessage === 'Помилка додавання' ?
                'Попередження! Не всі документи додано до реєстру' :
                'Попередження! Не всі документи видалено з реєстру',
              typeMessage,
            );
        }
      }

      throw errors;
    }

    return data;
  }

  protected _bindOneToModel(response: any): InvoiceRegister {
    let invoiceRegister: InvoiceRegister = null;
    if (response.success) {
      const item: any = response.data[0];

      if (item) {
        invoiceRegister = new InvoiceRegister(item.Ref);
        invoiceRegister.docNumber = item.Number;
        invoiceRegister.ref = item.Ref;
        invoiceRegister.printed = item.Printed || false;
        invoiceRegister.dateTime = new Date(item.DateTime);
        invoiceRegister.description = item.Description;
        invoiceRegister.marketplacePartnerDescription = item.MarketplacePartnerDescription;
        invoiceRegister.citySender = item.CitySender;
        invoiceRegister.citySenderRef = item.CitySenderRef;
        invoiceRegister.sender = item.Sender;
        invoiceRegister.senderRef = item.SenderRef;
        invoiceRegister.senderAddress = item.SenderAddress;
        invoiceRegister.senderAddressRef = item.SenderAddressRef;
        invoiceRegister.count = item.Count || 0;
        invoiceRegister.weight = item.Weight || 0;
        invoiceRegister.totalCost = item.TotalCost || 0;
        invoiceRegister.totalRedeliverySum = item.TotalRedeliverySum || 0;
        invoiceRegister.totalAfterpaymentOnGoodsCost = item.TotalAfterpaymentOnGoodsCost || 0;
        invoiceRegister.TotalCheckCost = Number(item.TotalCheckCost) ? item.TotalCheckCost : 0;
        invoiceRegister.TotalCheckCostSender = Number(item.TotalCheckCostSender) ? item.TotalCheckCostSender : 0;
        invoiceRegister.TotalCheckWeight = Number(item.TotalCheckWeight) ? item.TotalCheckWeight : 0;
        invoiceRegister.TotalCost = Number(item.TotalCost) ? item.TotalCost : 0;
        invoiceRegister.TotalCostOnSite = Number(item.TotalCostOnSite) ? item.TotalCostOnSite : 0;
        invoiceRegister.TotalCostOnSiteSender = Number(item.TotalCostOnSiteSender) ? item.TotalCostOnSiteSender : 0;
        invoiceRegister.TotalRedeliverySum = Number(item.TotalRedeliverySum) ? item.TotalRedeliverySum : 0;
        invoiceRegister.TotalWeight = Number(item.TotalWeight) ? item.TotalWeight : 0;
        invoiceRegister.TotalAfterpaymentOnGoodsCost = Number(item.TotalAfterpaymentOnGoodsCost) ? item.TotalAfterpaymentOnGoodsCost : 0;
        invoiceRegister.Count = item.Count || 0;
        invoiceRegister.Sender = item.Sender;
        invoiceRegister.SenderAddress = item.SenderAddress;
      }
    } else {
      const errors: IApiError[] = [];
      _.each(response.errorCodes, (el, key) => {
        errors.push({
          messageCode: el,
          errorCode: this._noRestApiHelper.replaceErrorCodeOnCustom(el),
          message: response.data.errors[key],
        });
      });
      throw errors;
    }
    return invoiceRegister;
  }
}

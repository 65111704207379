import {Invoice} from '../../models/invoice/invoice.model';
import {compareStrings} from './compare-functions.util';

export function compareReceiver(i1: Invoice, i2: Invoice, isAsc: boolean): number {
  const formName = (i: Invoice) => i.recipient ? `${i.recipient.lastName || ''} ${i.recipient.firstName || ''}`.trim() : '';
  return compareStrings(formName(i1).toLowerCase(), formName(i2).toLowerCase(), isAsc);
}

export function compareReceiverWithOrganizations(i1: Invoice, i2: Invoice, isAsc: boolean): number {
  const formName = (i: Invoice) => i.recipient ? `${i.recipient.lastName || ''} ${i.recipient.firstName || ''}`.trim() : '';
  const toOrganization = (i: Invoice) => i.recipientCounterpartyType && i.recipientCounterpartyType === 'Organization' ?
    (i.recipient && i.recipient.description || '').trim().toLowerCase() : formName(i).toLowerCase();
  return compareStrings(toOrganization(i1), toOrganization(i2), isAsc);
}

export function compareSender(i1: Invoice, i2: Invoice, isAsc: boolean): number {
  const formName = (i: Invoice) => i.sender ? `${i.sender.lastName || ''} ${i.sender.firstName || ''}`.trim() : '';
  return compareStrings(formName(i1).toLowerCase(), formName(i2).toLowerCase(), isAsc);
}

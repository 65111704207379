import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'np_to_date_format',
  pure: true,
})
export class ToDateFormatPipe implements PipeTransform {
  transform(value: string | Date, format: string = 'YYYY-MM-DD HH:mm:ss'): any {
    return !!value ? moment(value, format) : '';
  }
}

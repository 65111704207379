import { AfterViewInit, Component, OnInit } from '@angular/core';
import { User } from '@models';
import { AuthService } from '@services';
import { LocalStorageService } from 'ngx-webstorage';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { AppRoutes } from './app.routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  routerSubscription: Subscription;
  constructor(protected storage: LocalStorageService, protected authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    const authRoutes = [
      AppRoutes.auth,
      AppRoutes.login,
      AppRoutes.login_organization,
      AppRoutes.login_private,
      AppRoutes.forgot_password,
      AppRoutes.forgot_card_number,
      AppRoutes.registration,
      AppRoutes.sms_confirm,
    ];

    this.routerSubscription = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        filter((e) => !authRoutes.some((r) => (e as RouterEvent).url.indexOf(r) > 0)),
      )
      .subscribe((event) => {
        this.routerSubscription.unsubscribe();

        if (this.authService.user) {
          this.authService.pushUserToAnalytics(this.authService.user);
        }

        this.initAnalytics();
      });
  }

  ngAfterViewInit(): void {
    window.addEventListener('storage', (e) => {
      if (e.key === 'ng2-webstorage|auth.account') {
        const user: User = JSON.parse(e.newValue);
        if (
          (this.authService.user && user && user.userLogin !== this.authService.user.userLogin) ||
          !user ||
          (user && !this.authService.user)
        ) {
          window.location.reload();
        }
      }
    });
  }

  private initAnalytics(): void {
    function loadGoogleTagManager(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    }

    // Example usage:
    loadGoogleTagManager(window, document, 'script', 'dataLayer', 'GTM-T6H65RFN');
  }
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Invoice } from '../../../../models/invoice/invoice.model';
import { compareStrings } from '@shared';
import { InvoiceResponseModel, ISmsTemplate } from '@models';
import { ClipboardService } from 'ngx-clipboard';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, switchMap } from 'rxjs';
import { AlertToastService, GoogleAnalyticService } from '@services';
import { Router } from '@angular/router';
import {
  initialTemplates,
  letterLimits,
  parseContentHtml,
  templateVariables,
} from '../../../utils/template.utils';
import { SmsInformingService } from '../../../../services/sms-informing.service';
import { InternationalInvApiService } from '../../../../services/international-inv-api.service';
import { of } from 'rxjs';
import { AppRoutes } from '@app';

@Component({
  selector: 'app-share-template-message',
  templateUrl: 'share-template-message.component.html',
  styleUrls: ['./share-template-message.component.scss'],
})
export class ShareTemplateMessageComponent implements OnInit {
  @Input() invoice: Invoice | InvoiceResponseModel;
  @Input() estimatedDeliveryDate: Date;
  @Input() backMoney: number;
  @Input() paymentControl: number;
  @Input() contactPhone: string;

  isLoading = false;

  shareForm: UntypedFormGroup;

  smsClubToken: string;
  currentMaxLetters: number;
  currentTextType: 'en' | 'cyr';
  currentSendMessagesCount: number;
  currentLimit: number;
  templates: ISmsTemplate[] = [initialTemplates[0]];
  alphaNames: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private smsInformingService: SmsInformingService,
    private alertToastService: AlertToastService,
    private internationalInvApiService: InternationalInvApiService,
    public dialogRef: MatDialogRef<ShareTemplateMessageComponent>,
    private dialog: MatDialog,
    private cbService: ClipboardService,
    private router: Router,
    protected googleAnalyticsService: GoogleAnalyticService,
  ) {}

  get content(): string {
    return this.shareForm.value.content;
  }

  ngOnInit() {
    this.invoice.estimatedDeliveryDate = this.estimatedDeliveryDate;
    this.invoice.backMoney = this.backMoney;
    this.invoice.paymentControl = this.paymentControl;
    this.createForm(this.templates[0]);
    this.getSmsClubToken();
  }

  getSmsClubToken() {
    this.isLoading = true;
    this.smsInformingService
      .getConnectedAccessToken()
      .pipe(
        catchError((error) => {
          this.isLoading = false;
          this.cd.detectChanges();
          return of('');
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((token) => {
        if (token) {
          this.smsClubToken = token;
          this.getTemplates(token);
        }
      });
  }

  getTemplates(token: string) {
    this.smsInformingService
      .getAlphaNames(token)
      .pipe(
        switchMap((data: any[]) => {
          if (data.length) {
            this.alphaNames = data.sort((a, b) => compareStrings(a, b));
            this.shareForm.patchValue({
              alphaName: this.alphaNames[0],
            });
          }
          return this.smsInformingService.getSmsInformingTemplates();
        }),
      )
      .subscribe((data) => {
        if (data.length) {
          this.templates.push(...data);
        }
      });
  }

  createForm(initialTemplate: ISmsTemplate) {
    const content = this.getParsedContent(initialTemplate.content);
    this.shareForm = this.fb.group({
      name: [initialTemplate.name, Validators.required],
      content: [
        content,
        [Validators.required, Validators.maxLength(this.currentMaxLetters)],
      ],
      alphaName: ['', Validators.required],
    });
    this.setCurrentTextParams(content);
  }

  getParsedContent(content: string) {
    content = parseContentHtml(content);
    for (const variable of templateVariables) {
      const regexp = new RegExp(variable.name, 'g');
      content = content.replace(regexp, variable.getValue(this.invoice));
    }
    return content;
  }

  setCurrentTextParams(content: string) {
    this.currentTextType = /[а-яА-ЯЁё]/.test(content) ? 'cyr' : 'en';
    this.currentMaxLetters =
      letterLimits[this.currentTextType][
        letterLimits[this.currentTextType].length - 1
      ];
    for (let i = 0; i < letterLimits[this.currentTextType].length; i++) {
      const limit = letterLimits[this.currentTextType][i];
      if (content.length <= limit) {
        this.currentLimit = limit;
        this.currentSendMessagesCount = i + 1;
        break;
      }
    }
  }

  sendMessage() {
    this.isLoading = true;
    const phone =
      (this.invoice as any).RecipientsPhone ||
      this.contactPhone ||
      this.invoice.recipient.phone;
    if (this.shareForm.valid) {
      const formValue = this.shareForm.value;
      this.smsInformingService
        .sendMessage(
          formValue.alphaName,
          phone,
          formValue.content,
          this.smsClubToken,
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.alertToastService.pushSuccess(
              `Успішно відправлено повідомлення!`,
            );
            this.googleAnalyticsService.track('analitics', `smsClub.sendSms`, {
              eventLabel: `СМС-інформування, повідомлення відправлено успішно`,
              eventCategory: `smsClub`,
              eventAction: 'send.success',
            });
          },
          (errors) => {
            this.alertToastService.pushError(
              (errors && errors[0].translatedError) || '',
            );
            this.googleAnalyticsService.track('analitics', `smsClub.sendSms`, {
              eventLabel: `СМС-інформування, повідомлення помилка: ${
                (errors && errors[0].message) || ''
              }`,
              eventCategory: `smsClub`,
              eventAction: 'send.error',
            });
          },
        );
    } else {
      this.isLoading = false;
      this.alertToastService.pushError('Заповніть поля форми!');
    }
  }

  translit() {
    this.shareForm.patchValue({
      content: this.internationalInvApiService.translit(this.content),
    });
    this.setCurrentTextParams(this.content);
  }

  changeTemplate(templateName: string) {
    for (const template of this.templates) {
      if (templateName === template.name) {
        const content = this.getParsedContent(template.content);
        this.shareForm.patchValue({
          content,
        });
        this.setCurrentTextParams(content);
        break;
      }
    }
  }

  goSettingsSmsService() {
    this.router.navigate([
      AppRoutes.dashboard,
      AppRoutes.settings,
      'sms-service',
    ]);
    this.dialog.closeAll();
  }

  copyTemplateContent() {
    this.cbService.copyFromContent(this.content);
    this.alertToastService.pushSuccess('Успішно скопійовано текст!');
  }
}

import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const ValidateNewContact =
  (isTemplate: boolean = false): ValidatorFn =>
  (fg: UntypedFormGroup) => {
    const controls = Object.values(fg.controls);

    const valid =
      (isTemplate ? controls.every((c) => c.pristine) : false) ||
      controls.every((c) => c.valid);

    return valid ? null : { notEmpty: true };
  };

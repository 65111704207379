import { Injectable } from '@angular/core';
import { AddressTypes, IListData } from '@models';
import { map, Observable } from 'rxjs';
import { Invoice } from '../models/invoice/invoice.model';
import { RedirectInvoice, RedirectInvoiceResponse } from '../models/redirect-invoice';
import { RedirectInvoicesApiService } from './redirect-invoices-api.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectInvoicesService {
  constructor(private redirectApi: RedirectInvoicesApiService) {
  }

  create(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
  ): Observable<Partial<RedirectInvoice>> {
    return this.redirectApi.create(redirectInvoice, invoice, destination).pipe(map((data: Partial<RedirectInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
        MessageText: data.MessageText,
      }),
    ));
  }

  update(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    onlyGetPricing: boolean = false,
    Ref: string,
  ): Observable<Partial<RedirectInvoice>> {
    return this.redirectApi.update(redirectInvoice, invoice, destination, onlyGetPricing, Ref).pipe(map((data: Partial<RedirectInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
        MessageText: data.MessageText,
      }),
    ));
  }

  getPricing(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    Ref?: string,
  ): Observable<Partial<RedirectInvoice>> {
    return this.redirectApi.create(redirectInvoice, invoice, destination, true, Ref).pipe(map((data: Partial<RedirectInvoice>) =>
      ({
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
      }),
    ));
  }

  getPricingForUpdate(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    Ref?: string,
  ): Observable<Partial<RedirectInvoice>> {
    return this.redirectApi.update(redirectInvoice, invoice, destination, true, Ref).pipe(map((data: Partial<RedirectInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
      }),
    ));
  }

  getList(
    page: number = 1,
    limit: number = 10,
  ): Observable<IListData<RedirectInvoice>> {
    return this.redirectApi.getList(page, limit);
  }

  getOne(orderId: string): Observable<RedirectInvoice> {
    return this.redirectApi.getOne(orderId);
  }

  getNew(invoice?: Invoice) {
    const redirect = new RedirectInvoice();

    if (invoice) {
      redirect.docNumber = invoice.docNumber;
    }

    return redirect;
  }

  remove(idOrder: string): Observable<boolean> {
    return this.redirectApi.remove(idOrder);
  }

  check(docNumber: string, orderRef?: string): Observable<RedirectInvoiceResponse> {
    return this.redirectApi.check(docNumber, orderRef);
  }
}

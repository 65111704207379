<mat-form-field [attr.data-test-id]="testId"
                class="form-input">
  <input [formControl]="formControl"
         [placeholder]="placeholder"
         [attr.aria-label]="label"
         [maxLength]="maxLength"
         type="text"
         class="input"
         autocomplete="off"
         InputTrim
         matInput>
  <mat-error>{{getError(building, BuildingErrors)}}</mat-error>
</mat-form-field>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {AutoCompleteOptionItem, AutoCompleteTransformFn, AutocompleteWithControlsConfig} from '@autocomplete-with-controls';
import {
  AbstractFormContainer,
  cityResultMaskValidator,
  StreetMaskValidator,
  TemplateCitySearchResultMaskValidator,
  TemplateStreetMaskValidator,
  TypedFormControl
} from '@shared';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs';
import {AddressService} from '../../../../../services/address.service';
import {ErrorMessages, ErrorTypeMessages, ValidationService} from '../../../../../services/validation.service';
import { CitySearchResult, StreetSearchResult } from "@models";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'np-inv-address-doors',
  templateUrl: 'address-doors.component.html',
  styleUrls: ['./address-doors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDoorsComponent extends AbstractFormContainer implements OnInit, TypedFormControl, OnDestroy {

  readonly type = 'Doors';

  editForm: UntypedFormGroup;
  @Input() showFlat = true;

  @HostBinding('attr.data-test-id') a = 'AddressTypeDoors';

  @Input()
  controlName = 'DoorsAddress';

  @Input()
  controlTypeName = 'type';

  @Input()
  templateValidation: boolean;

  @Input()
  configCity: AutocompleteWithControlsConfig = {
    ariaLabel: 'Населений пункт',
    placeholder: 'Населений пункт',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configStreet: AutocompleteWithControlsConfig = {
    ariaLabel: 'Вулиця',
    placeholder: 'Вулиця',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configBuilding: AutocompleteWithControlsConfig = {
    ariaLabel: 'Будинок',
    placeholder: 'Будинок',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configRoom: AutocompleteWithControlsConfig = {
    ariaLabel: 'Квартира',
    placeholder: 'Квартира',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  cityOptions: Array<AutoCompleteOptionItem<CitySearchResult>> = [];

  streetOptions: Array<AutoCompleteOptionItem<StreetSearchResult>> = [];

  errors: ErrorTypeMessages = {
    city: [
      {key: 'required', message: 'Це поле обов\'язкове'},
      {key: 'objectMask', message: 'Виберіть населений пункт'},
    ],
    street: [
      {key: 'required', message: 'Це поле обов\'язкове'},
      {key: 'objectMask', message: 'Виберіть вулицю'},
    ],
  };

  errorMessages: ErrorMessages = {};

  errorsSubscription: Subscription;

  @Input()
  latestCity: string | CitySearchResult;

  @Output()
  onCitySelected = new EventEmitter<CitySearchResult | string>();

  constructor(private fb: UntypedFormBuilder,
              private cd: ChangeDetectorRef,
              private addressService: AddressService,
              private validationService: ValidationService) {
    super();
  }

  get cityControl(): AbstractControl | null {
    return this.editForm.get('city');
  }

  get streetControl(): AbstractControl | null {
    return this.editForm.get('street');
  }

  get buildingControl(): AbstractControl | null {
    return this.editForm.get('building');
  }

  get roomControl(): AbstractControl | null {
    return this.editForm.get('room');
  }

  get city(): CitySearchResult | null {
    return this.editForm.get('city').value;
  }

  ngOnInit() {
    super.ngOnInit();
    this.errorsSubscription = this.form.get(this.controlName)
      .valueChanges
      .subscribe(value => {
        this.errorMessages = this.validationService.getSingleErrorsObject((this.form.get(this.controlName) as UntypedFormGroup)
          .controls, this.errors);
      });
    if (this.templateValidation) {
      Object.keys(this.form.get(this.controlName)['controls']).forEach(key => {
        this.form.controls[this.controlName]['controls'][key].clearValidators();
      });
      this.form.get(`${this.controlName}.city`).setValidators([TemplateCitySearchResultMaskValidator()]);
      this.form.get(`${this.controlName}.street`).setValidators([
        Validators.required, TemplateStreetMaskValidator()
      ]);
      this.form.get(`${this.controlName}.building`).setValidators([Validators.required]);
    }
    this.streetControl.markAsPristine();
    this.streetControl.updateValueAndValidity();
  }

  addFormControls() {
    if (this.form) {
      this.createControl();
    }
  }

  createControl(): void {
    this.editForm = this.fb.group({
      city: [this.latestCity ? this.latestCity : '', [Validators.required, cityResultMaskValidator()]],
      street: [{value: '', disabled: !this.latestCity}, [Validators.required, StreetMaskValidator()]],
      building: [{value: '', disabled: true}, [Validators.required]],
      room: [{value: '', disabled: true}],
    });
    this.form.setControl(this.controlName, this.editForm);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.initFormSubscriptions();
  }

  cityTransformFn: AutoCompleteTransformFn<CitySearchResult> =
    (value: CitySearchResult) => {
      if (value === null) {
        return '';
      }
      return typeof value === 'string' ? value : value.Present || '';
    };

  streetTransformFn: AutoCompleteTransformFn<StreetSearchResult> =
    (value: StreetSearchResult) => {
      if (value === null) {
        return '';
      }
      return typeof value === 'string' ? value : value.Present || '';
    };

  initFormSubscriptions(): void {
    this.initCitySubscriptions();
    this.initStreetSubscriptions();
  }

  initCitySubscriptions() {
    this.cityControl.statusChanges
      .subscribe(status => {
        const cityValue = this.cityControl.value;
        if (status === 'VALID' && cityValue?.Ref) {
          this.onCitySelected.next(this.city);
          this.streetControl.enable();
          this.streetControl.markAsPristine();
          this.streetControl.updateValueAndValidity();
          if (!this.templateValidation) {
            this.configStreet = {...this.configStreet, focused: true};
          }
          this.cd.detectChanges();
        } else {
          this.streetOptions = [];
          this.streetControl.reset('');
          this.streetControl.disable();
        }
      });
    this.cityControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        if (typeof value === 'string' && value.length >= 2) {
          this.loadCities(value);
        } else {
          this.cityOptions = [];
        }
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      });
  }

  initStreetSubscriptions() {
    this.streetControl.statusChanges
      .subscribe(status => {
        const streetValue = this.streetControl.value;
        if (status === 'VALID' && streetValue?.SettlementStreetRef) {
          this.buildingControl.enable();
          this.buildingControl.markAsPristine();
          this.buildingControl.updateValueAndValidity();
          this.roomControl.enable();
          if (!this.templateValidation) {
            this.configBuilding = {...this.configBuilding, focused: true};
          }
          this.cd.detectChanges();
        } else {
          this.buildingControl.reset('');
          this.buildingControl.disable();
          this.roomControl.reset('');
          this.roomControl.disable();
        }
      });
    this.streetControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        if (typeof value === 'string' && value.length >= 2) {
          this.loadStreets(value);
        } else {
          this.streetOptions = [];
        }
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      });
  }

  loadCities(nameQuery: string): void {
    this.addressService.getCities({name: nameQuery})
      .pipe(
        tap(response => this.cityOptions = response.Addresses.map(city => ({option: city}))),
      )
      .subscribe(cities => {
        this.cd.detectChanges();
      });
  }

  loadStreets(nameQuery: string): void {
    this.addressService.getStreets({name: nameQuery, localityRef: this.city.Ref})
      .pipe(
        tap(response => this.streetOptions = response.Addresses.map(street => ({option: street}))),
      )
      .subscribe(streets => {
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    this.errorsSubscription.unsubscribe();
  }
}

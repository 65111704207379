<mat-radio-group data-test-id="AddressType"
                 ngClass="{{modifyClass}}" class="radio-row"
                 [formControl]="control"
                 (change)="changeType($event)">
  <mat-radio-button *ngFor="let type of types;"
                    [attr.data-test-id]="type.value"
                    [value]="type.value"
                    [checked]="control.value === type.value"
                    [matTooltip]="type.tooltip" matTooltipPosition="below"
                    [disabled]="type.disabled">
    {{ type.label }}
  </mat-radio-button>
</mat-radio-group>

import {Directive, ElementRef, HostListener, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';

// By default the directive allows float numeric input
// allowFloat - toggles int/float (default is true)
// digitsAfterDot - amount of decimals (optimal here is 2) if not set is unlimited
// maxInputLength - restricts max input length
// min - min value to drop to
// max - max value to drop to

// Example syntax
// <input [formControlName]="SeatsAmountFrom"
//        [numeric]="true"
//        [digitsAfterDot]="2"
//        [min]="1"
//        [max]="100">

@Directive({
  selector: '[numeric]',
})
export class NumericDirective {
  private prevSuccess: string;
  private allowedKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  @Input() numeric: boolean;

  @Input() maxInputLength: number;

  @Input() min = 0;

  @Input() max: number;

  @Input() allowFloat = true;

  @Input() digitsAfterDot: number;

  constructor(private ngControl: NgControl) {
  }

  @HostListener('keypress', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (!this.numeric) {
      return;
    }
    const e = event as KeyboardEvent | any;
    const key = e.keyCode || e.which;

    this.restrictInputLength(e);
    this.cropDigitsAfterDot(e);
    this.toggleCommaAndDotKeys();
    this.allowSpecialCombinations(key, e);
    this.handleKeypress(key, e);
  }

  private restrictInputLength(e: KeyboardEvent): void {
    const target = e.target as HTMLInputElement;
    if (this.maxInputLength
      && target.value.length === this.maxInputLength
      && target.selectionStart === target.selectionEnd) {
      e.preventDefault();
    }
  }

  private cropDigitsAfterDot(e: KeyboardEvent): void {
    const target = e.target as HTMLInputElement;
    target.value = target.value.replace(',', '.');
    if (this.digitsAfterDot
      && target.selectionStart === target.selectionEnd
      && (target.value.indexOf('.') > -1
        && target.selectionStart > target.value.indexOf('.')
        && target.value.slice(target.value.indexOf('.') + 1).length === this.digitsAfterDot)
    ) {
      e.preventDefault();
    }
  }

  private toggleCommaAndDotKeys(): void {
    if (this.allowFloat) {
      this.allowedKeyCodes.push(44);
      this.allowedKeyCodes.push(46);
    }
  }

  private allowSpecialCombinations(key: number, e: KeyboardEvent): void {
    if (this.allowedKeyCodes.includes(key) ||
      // Allow: Ctrl+A
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (key === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (key === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (key === 88 && (e.ctrlKey || e.metaKey))
    ) {
      // let it happen, don't do anything
      return;
    } else if (
      !this.allowedKeyCodes.includes(key) ||
      (e.shiftKey || (key < 48 || key > 57))
      && (key <= 122 || key >= 65)
      && (key !== 8)
      && (e.key !== '.')
      && (e.key !== ',')
      && (key !== 9)
    ) {
      e.preventDefault();
    }
  }

  private handleKeypress(key: number, e: KeyboardEvent): void {
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (key < 48 || key > 57))
      && (key <= 122 || key >= 65)
      && (key !== 8)
      && (e.key !== '.')
      && (e.key !== ',')
      && (key !== 9)) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(e: InputEvent): void {
    if (!this.numeric) {
      return;
    }
    let value: string | number;
    const target = e.target as HTMLInputElement;
    const parsedValue = parseFloat(target.value.replace(',', '.'));

    if (this.allowFloat) {
      value = isNaN(parsedValue) ? '' : parsedValue;
    } else {
      value = isNaN(parsedValue) ? '' : Math.floor(parsedValue);
    }

    if (value === '') {
      target.value = value;
      if (this.ngControl) {
        this.ngControl.control.setValue(value);
      }
      return;
    }

    if (this.max && value > this.max) {
      target.value = String(this.max);
      value = String(this.max);
      if (this.ngControl) {
        this.ngControl.control.setValue(value);
      }
      return;
    }

    if (this.ngControl && !this.allowFloat) {
      this.ngControl.control.setValue(value);
    }
  }

  // This is to drop to min and transform commas into dots
  @HostListener('blur', ['$event'])
  onBlur(e: Event | any): void {
    if (e.target.value === '') {
      return;
    }
    const target = e.target;
    const value = target.value.replace(',', '.');
    const int = parseInt(value, 10);
    const float = parseFloat(value);
    let number: number;

    if (int === float) {
      number = int;
    } else {
      if (float > this.min) {
        if (this.digitsAfterDot) {
          const cropped = String(float).split('.');
          number = +(cropped[0] + '.' + cropped[1].slice(0, this.digitsAfterDot));
        } else {
          number = float;
        }
      } else {
        number = this.min;
      }
    }

    if (isNaN(number)) {
      target.value = this.prevSuccess || '';

      return;
    }

    if (number !== target.value) {
      target.value = number;
    }

    this.prevSuccess = target.value;

    if (this.min && parseFloat(value) < this.min) {
      target.value = String(this.min);
      if (this.ngControl) {
        this.ngControl.control.setValue(String(this.min));
      }
      return;
    }

    if (this.ngControl) {
      this.ngControl.control.setValue(target.value);
    }
  }
}

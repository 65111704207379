<div class="alert-popup">
   <button mat-icon-button (click)="closeModalDialog()" class="close-modal mat-icon-button">
      <i class="material-icons">close</i>
   </button>
   <img src="./assets/images/site-cut.svg" class="alert-popup__img">
   <div mat-dialog-title class="alert-popup__title mat-dialog-title">На комп'ютері зручніше</div>
   <div mat-dialog-content class="mat-dialog-content">
       <p class="alert-popup__text">На жаль, бізнес-кабінет не адаптовано до мобільних пристроїв. Це може спричинити деякі незручності.</p>
       <p class="alert-popup__text">Рекомендуємо користуватись версією кабінету для комп’ютерів.</p>
   </div>
   <button mat-raised-button color="primary" (click)="closeModalDialog()">
       Зрозуміло
   </button>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { AutoCompleteOptionItem } from '../autocomplete-with-controls.component';

@Pipe({
  name: 'filter_options',
  pure: true,
})
export class AutocompleteOptionsFilterPipe implements PipeTransform {
  transform(options: Array<AutoCompleteOptionItem<any>>, fn: Function, input: string): Array<AutoCompleteOptionItem<any>> {
    if (!input) {
      return options || [];
    }
    return !!fn ? fn(input, options) : options.slice();
  }
}

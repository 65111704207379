import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { getError } from 'app/shared/utils/error.utils';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { CityErrors } from 'app/shared/errors/error-messages';
import { debounceTime, filter, finalize, map, tap } from 'rxjs';
import { AddressService } from '@services';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { getCityDescription } from './city-select.utils';
import {
  CityClasses,
  CityControlName,
  CityDebounceTime,
  CityLabel,
  CityMaxLength,
  CityMinLength,
  CityPlaceholder,
  OptionDisabledTooltip,
  RequestLimit,
} from './city-select.constants';
import { cityResultMaskValidator, clearField } from '@shared';
import {
  AutoCompleteOptionItem,
  CitySearchResponse,
  CitySearchResult,
} from '@models';
import { AcOptionItem } from '../../../shared/modules/autocomplete/autocomplete.models';

// Usage: pass existing form control as an input
// <app-city-select [formControl]="this.form.get(CITY_CONTROL_NAME)"></app-city-select>

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CitySelectComponent),
      multi: true,
    },
  ],
})
export class CitySelectComponent implements ControlValueAccessor, OnDestroy {
  getError = getError;

  clearField = clearField;

  CityErrors = CityErrors;

  @Input() formControl: UntypedFormControl | AbstractControl = new UntypedFormControl('', [
    cityResultMaskValidator(),
    Validators.required,
  ]);

  @Input() placeholder: string = CityPlaceholder;

  @Input() label: string = CityLabel;

  @Input() testId: string = CityControlName;

  @Input() minLength: number = CityMinLength;

  @Input() maxLength: number = CityMaxLength;

  @Input() requestLimit: number = RequestLimit;

  @Input() debounceTime: number = CityDebounceTime;

  @Input() getCityDescription = getCityDescription;

  @Input() clearEnabled: boolean = false;

  @Input() disableOptionsIfNoWarehouses: boolean = true;

  @Input()
  @HostBinding('class')
  classList = CityClasses;

  cities: Array<AcOptionItem<CitySearchResult>> = [];

  citiesLoading: boolean = false;

  citySubscription: Subscription = null;

  constructor(
    private addressService: AddressService,
    private cd: ChangeDetectorRef,
  ) {}

  get city(): AbstractControl {
    return this.formControl;
  }

  initCitySubscription(): void {
    if (this.citySubscription) {
      return;
    }
    this.citySubscription = this.city?.valueChanges
      .pipe(
        map((q) => (q === null ? '' : q)),
        tap((query) => {
          if (query.length === 0) {
            this.cities = [];
          }
        }),
        filter((v) => v.length > this.minLength - 1),
        debounceTime(this.debounceTime),
      )
      .subscribe((value) => this.getCities(value));
  }

  mapToOptions(
    cities: CitySearchResult[],
  ): Array<AutoCompleteOptionItem<CitySearchResult>> {
    return cities?.map((city) => ({
      option: city,
      disabled: this.disableOptionsIfNoWarehouses && !city.Warehouses,
      tooltip:
        this.disableOptionsIfNoWarehouses && !city.Warehouses
          ? OptionDisabledTooltip
          : '',
    }));
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }

  ngOnDestroy(): void {
    this.citySubscription?.unsubscribe();
  }

  private getCities(value: string): void {
    this.citiesLoading = true;
    this.getCityList(value)
      .pipe(finalize(() => (this.citiesLoading = false)))
      .subscribe((cities) => {
        this.cities = this.mapToOptions(cities.Addresses);
        this.cd.markForCheck();
      });
  }

  private getCityList(name: string): Observable<CitySearchResponse> {
    return this.addressService.getCities({ name }, 1, this.requestLimit);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {AbstractFormContainer} from '@shared';
import { TypeRadioButton } from '../type-radio-group/type-radio-button';

@Component({
  selector: 'np-inv-edit-container',
  templateUrl: 'edit-container.component.html',
  styleUrls: ['./edit-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditContainerComponent extends AbstractFormContainer {

  @Input()
  controlName = '';

  @Input()
  formTypeName = '';

  @Input()
  types: TypeRadioButton[] = [];

  @Input()
  isCancelable = false;

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onClear = new EventEmitter<void>();

  constructor() {
    super();
  }

  addFormControls() {
    console.warn('Redirect parent form group to child');
  }

  cancel(): void {
    this.onCancel.next();
  }

  clear(): void {
    this.onClear.next();
  }
}

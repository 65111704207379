import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs';
import {AuthService} from '../auth.service';
import {OAuth2Service} from "../auth/oAuth2.service";

@Injectable()
export class NotAuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    const oAuth2Service = this.injector.get(OAuth2Service);
    if (req.body && req.body.methodProperties && req.body.methodProperties.Login && authService.getAuthHash(req.body.methodProperties.Login)) {
      req = req.clone({
        setHeaders: {
          DeviceCode: authService.getAuthHash(req.body.methodProperties.Login),
        },
      });
    }
    return next.handle(req)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              if (!oAuth2Service.getToken()) {
                authService.logout();
              }
            }
          }
          return of(err);
        }),
      );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, AddressTypes, Contact, Contragent, IListData } from '@models';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { OrderState } from '../components/dashboard/business-services/models/order-state';
import { InvoicePaymentMethod } from '../models/invoice/invoice-payment-method.model';
import { InvoiceState } from '../models/invoice/invoice-state.model';
import { Invoice } from '../models/invoice/invoice.model';
import { RedirectInvoice } from '../models/redirect-invoice';
import { ApiMethods } from './api/apiMethods';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class RedirectInvoicesApiService {
  constructor(
    private http: HttpClient,
    private authorizationService: AuthService,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {
  }

  create(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    onlyGetPricing: boolean = false,
    Ref?: string,
  ): Observable<RedirectInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalServiceGeneral',
        calledMethod: ApiMethods.save,
        methodProperties: this.mapPropertiesToMethodProperties(redirectInvoice, invoice, destination, onlyGetPricing, Ref),
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data.data.data && data.data.data[0]),
      );
  }

  update(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    onlyGetPricing: boolean = false,
    Ref?: string,
  ): Observable<RedirectInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalServiceGeneral',
        calledMethod: ApiMethods.update,
        methodProperties: this.mapPropertiesToMethodProperties(redirectInvoice, invoice, destination, onlyGetPricing, Ref),
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data.data.data && data.data.data[0]),
      );
  }

  private mapPropertiesToMethodProperties(
    redirectInvoice: RedirectInvoice,
    invoice: Invoice,
    destination: AddressTypes,
    onlyGetPricing: boolean = false,
    Ref?: string): Record<string, any> {
    return {
      OrderType: 'orderRedirecting',
      IntDocNumber: invoice.docNumber,
      Customer: redirectInvoice.customer,
      ServiceType:
        invoice.ServiceType.match(/[A-Z][a-z]+/g)[0] +
        (destination === AddressTypes.DOORS
          ? AddressTypes.DOORS
          : AddressTypes.WAREHOUSE),
      RecipientSettlement:
        destination === AddressTypes.DOORS
          ? redirectInvoice.recipientAddress.city.id
          : undefined,
      RecipientSettlementStreet:
        destination === AddressTypes.DOORS
          ? redirectInvoice.recipientAddress.street &&
          redirectInvoice.recipientAddress.id
          : undefined,
      RecipientWarehouse:
        destination !== AddressTypes.DOORS
          ? redirectInvoice.recipientAddress.id
          : undefined,
      BuildingNumber:
        destination === AddressTypes.DOORS
          ? redirectInvoice.recipientAddress.building
          : undefined,
      NoteAddressRecipient: redirectInvoice.recipientAddress.noteAddressRecipient,
      Recipient:
        redirectInvoice.recipient.contragent.id ||
        redirectInvoice.recipient.contragent,
      RecipientContactName: redirectInvoice.recipient.description || '',
      RecipientPhone: redirectInvoice.recipient.phone,
      PayerType: redirectInvoice.payer,
      PaymentMethod: redirectInvoice.PaymentMethod,
      Note: redirectInvoice.description,
      OnlyGetPricing: onlyGetPricing ? 1 : 0,
      Ref,
    };
  }

  getList(
    page: number = 1,
    limit: number = 10,
  ): Observable<IListData<RedirectInvoice>> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getRedirectionOrdersList',
        methodProperties: {
          Page: page.toString(),
          Limit: limit.toString(),
          BeginDate: moment().subtract(3, 'months').format('DD.MM.YYYY'),
          EndDate: moment().format('DD.MM.YYYY'),
          OrderField: 'DateTime',
          SearchByCounterparties: this.authorizationService.user.contacts
            ? 1
            : null,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => {
          data = data.data;

          return {
            list: data.data.map((el) => this.bindOne(el)),
            total: (data.info && data.info.totalCount) || data.length,
          };
        }),
        map((data) => {
          data.list = (data.list || []).sort(
            (redirect1: RedirectInvoice, redirect2: RedirectInvoice) => {
              const time1 = redirect1.createDateTime?.getTime();
              const time2 = redirect2.createDateTime?.getTime();

              if (time1 < time2) {
                return 1;
              } else if (time1 > time2) {
                return -1;
              }

              return 0;
            },
          );

          return data;
        }),
      ) as any;
  }

  getOne(orderId: string): Observable<RedirectInvoice> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'getRedirectionOrdersList',
        methodProperties: {
          Ref: orderId,
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => {
          data = (data.data.data && data.data.data[0]) || [];
          return this.bindOne(data);
        }),
      ) as any;
  }

  remove(idOrder: string): Observable<boolean> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'delete',
        methodProperties: {
          Ref: idOrder,
          OrderType: 'orderRedirecting',
        },
      })
      .pipe(
        map((data) => {
          return this.noRestApiHelper.checkErrors({ data });
        }),
        map((data) => data.data.success),
      );
  }

  check(docNumber: string, OrderRef?: string): Observable<any> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AdditionalService',
        calledMethod: 'checkPossibilityForRedirecting',
        methodProperties: {
          Number: docNumber,
          OrderRef,
        },
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((data) => data.data),
      );
  }

  private bindOne(data: any): RedirectInvoice {
    const recipientName = data.ContactPersonRecipient || data.RecipientName;
    const contactArr: string[] =
      (recipientName && recipientName.split(' ')) || [];
    const redirectInvoice = new RedirectInvoice(data.OrderRef);

    redirectInvoice.Number = data.OrderNumber;
    redirectInvoice.createDateTime = data.DateTime
      ? moment(
        data.DateTime,
        'D.M.YYYY H:m:s',
      )
        .toDate()
      : null;
    redirectInvoice.state = Object.assign(new OrderState(), {
      // see CBC-87
      name: data.OrderStatus === 'Прийняте' ? 'Прийнята' : data.OrderStatus,
    } as OrderState);
    redirectInvoice.docNumber = data.DocumentNumber;
    redirectInvoice.description = data.Note;
    redirectInvoice.recipient = Object.assign(
      new Contact(contactArr[1], contactArr[0]),
      {
        phone: data.PhoneRecipient && `+${data.PhoneRecipient}`,
        middleName: contactArr[2],
        contragent:
          data.CounterpartyRecipient &&
          data.CounterpartyRecipient !== 'Приватна особа'
            ? Object.assign(new Contragent(), {
              name: data.CounterpartyRecipient,
            } as Contragent)
            : null,
      } as Contact,
    );

    redirectInvoice.recipientAddress = Object.assign(new Address(), {
      description: data.RecipientAddress,
    } as Address);
    redirectInvoice.deliveryCost = !data.DeliveryCost && data.DeliveryCost !== 0 ? -1 : data.DeliveryCost;
    redirectInvoice.estimatedDeliveryDate = data.EstimatedDeliveryDate ? moment(
      data.EstimatedDeliveryDate,
      'D.M.YYYY H:m:s',
    ).toDate() : null;
    redirectInvoice.newDocNumber = data.ExpressWaybillNumber;
    redirectInvoice.newDocState = Object.assign(new InvoiceState(), {
      name: data.ExpressWaybillStatus,
    } as InvoiceState);

    redirectInvoice.payer = data.PayerType;
    redirectInvoice.noteAddressRecipient = data.NoteAddressRecipient;
    redirectInvoice.paymentMethod = new InvoicePaymentMethod(
      data.PaymentMethod,
    );

    Object.assign(redirectInvoice, data);

    return redirectInvoice;
  }
}

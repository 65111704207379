import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService, GoogleAnalyticService } from '@services';
import { DoubleDateTableFilter, getError, objectIsEmpty, TableChip, TableFilterConfig } from '@shared';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-table-hot-filters',
  templateUrl: './table-hot-filters.component.html',
  styleUrls: ['./table-hot-filters.component.scss'],
})
export class TableHotFiltersComponent implements OnInit {
  readonly getError = getError;

  @Input() config: TableFilterConfig;
  initialForm: object;
  form: UntypedFormGroup;
  @Output() filtersApplied: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();
  private _filtersFromStorage: Record<string, any> = {};

  constructor(
    private fb: UntypedFormBuilder,
    private storage: LocalStorageService,
    private authService: AuthService,
    protected googleAnalyticsService: GoogleAnalyticService,
  ) {}

  private _chips: TableChip[] = [];

  get chips(): TableChip[] {
    return this._chips;
  }

  @Input() set chips(chips: TableChip[]) {
    this._chips = chips;
    this.initForm();
  }

  get filters(): Record<string, any> {
    return this._filtersFromStorage;
  }

  set filters(filters: Record<string, any>) {
    this._filtersFromStorage = filters;
  }

  private get storageKey(): string {
    return `${this.authService.user.userLogin}_${this.config.storageKey}`;
  }

  ngOnInit(): void {
    this.initForm();
  }

  saveInitialForm(): void {
    this.initialForm = this.form?.getRawValue();
  }

  getGroup(groupName: string): UntypedFormGroup {
    return this.form.get(groupName) as UntypedFormGroup;
  }

  notChanged(name: string, group: string): boolean {
    return this.initialForm && this.initialForm[group][name] === this.form.getRawValue()[group][name];
  }

  changed(name: string, group: string): boolean {
    return !this.notChanged(name, group);
  }

  clearFilters(): void {
    this.form = null;
    this.initForm();
  }

  applyFilterForm(): void {
    this.saveFiltersToStorage(this.form.getRawValue());
    this.filtersApplied.emit(this.form.getRawValue());
  }

  saveFiltersToStorage(filters: any): void {
    this.storage.store(this.storageKey, objectIsEmpty(filters) ? {} : filters);
    this.retrieveFiltersFromStorage();
  }

  clearField(field: AbstractControl, event?: Event, value?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (field.reset) {
      if (value === '' || value === null) {
        field.reset(value);

        return;
      }
      field.reset();
    }
  }

  private patchForm(): void {
    this.form.patchValue(this.retrieveFiltersFromStorage());
  }

  private retrieveFiltersFromStorage(): Record<string, any> {
    this.filters = this.storage.retrieve(this.storageKey) ?? {};

    return this.filters;
  }

  private initForm(): void {
    if (!this.config) {
      return;
    }
    this.form = this.fb.group(
      this.config.filterGroups.reduce((acc, group) => {
        if (!group.controlName) {
          return;
        }
        acc[group.controlName] = this.fb.group(
          group.filters.reduce((acc, filter) => {
            if (filter.type === 'double-date') {
              const ddFilter = filter as DoubleDateTableFilter;
              acc[ddFilter.controlName] = this.fb.group(
                {
                  [ddFilter.fromFilter.controlName]: [ddFilter.fromFilter.initialValue, ddFilter.fromFilter.validators],
                  [ddFilter.toFilter.controlName]: [ddFilter.toFilter.initialValue, ddFilter.toFilter.validators],
                },
                { validators: ddFilter.validators },
              );
              return acc;
            } else {
              acc[filter.controlName] = [filter.initialValue, filter.validators];
              return acc;
            }
          }, {}),
        );
        return acc;
      }, {}),
    );
    this.patchForm();
    this.saveInitialForm();
  }
}

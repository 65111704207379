import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '@app';
import {
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent,
  AutocompleteControlTriggerName,
  AutoCompleteOptionItem,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { AddressType, AddressTypes, ContactPerson, DoorAddress, TypedFormGroup, WarehouseAddress } from '@models';
import { GoogleAnalyticService, ValidationService } from '@services';
import {
  AbstractFormContainer,
  EditableAutocompleteControl,
  getAddressType,
  getError,
  TypedFormControl,
  ValidateAddress,
} from '@shared';
import { TypeRadioButton } from '@type-radio-group';

import { map, merge, of, Subscription, switchMap } from 'rxjs';
import { IdService } from '../../services/id.service';

export type AddressMode = AutocompleteControlTriggerName | 'select';

@Component({
  selector: 'np-inv-address-recipient',
  templateUrl: 'address-recipient.component.html',
  styleUrls: ['./address-recipient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressRecipientComponent
  extends AbstractFormContainer
  implements EditableAutocompleteControl, TypedFormControl, OnInit, OnDestroy, AfterViewInit
{
  readonly CONTROL_NAMES: { [key: string]: keyof TypedFormGroup } = {
    MODE: 'mode',
    TYPE: 'type',
    DATA: 'data',
  };

  AddressTypes = AddressTypes;
  @Input()
  invoiceForm: UntypedFormGroup;
  @Input()
  templateValidation: boolean;
  @Input()
  focused: boolean;
  @Input() invoiceId: string;
  @Input()
  controlName = 'AddressRecipient';
  @Input()
  controlTypeName = 'type';
  @Input()
  removedContact;
  @Input()
  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Адреса отримувача',
    placeholder: 'Адреса',
    arrow: true,
  };
  @Input()
  controlsConfig: AutocompleteControls = {
    create: {
      enabled: true,
      tooltip: 'Створити нову адресу',
    },
  };
  @Input()
  types: Array<TypeRadioButton<AddressType>> = [
    {
      value: AddressTypes.WAREHOUSE,
      label: 'Відділення',
    },
    {
      value: AddressTypes.DOORS,
      label: 'Адреса',
    },
    {
      value: AddressTypes.POSTBOX,
      label: 'Поштомат',
    },
    {
      value: AddressTypes.INDEX,
      label: 'Цифрова адреса',
      tooltip:
        'Цифрова адреса — це унікальний числовий номер, який ідентифікує усі пункти видачі Нової пошти (відділення, поштомат)',
    },
  ];

  RecipientAddressErrors = {
    required: "Це поле обов'язкове",
    address: 'Виберіть із запропонованих варіантів',
    weight: 'Вказане відділення не може прийняти таке відправлення, оберіть інше відділення',
  }
  latestCity;
  formGroup: UntypedFormGroup;
  @Input()
  options: Array<AutoCompleteOptionItem<DoorAddress | WarehouseAddress>> = [];
  @Input()
  currentContact: ContactPerson;
  @Input()
  selectedAddress: any;
  @Input()
  clearFunction;
  optionSeatsLength: number;
  state = window.history.state;

  readonly getError = getError;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private validationService: ValidationService,
    private googleAnalyticsService: GoogleAnalyticService,
    private idService: IdService,
    public router: Router,
  ) {
    super();
  }

  private _subscriptions: Subscription[] = [];

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(subscription: any) {
    this._subscriptions.push(subscription);
  }

  private _mode: AddressMode = null;

  get mode(): AddressMode {
    return this._mode;
  }

  @Input()
  set mode(value: AddressMode) {
    this._mode = value;
    this.parentForm.get('mode').setValue(this._mode, { emitEvent: false });
  }

  get control(): AbstractControl | UntypedFormGroup {
    return this.form?.get(this.controlName);
  }

  get data(): AbstractControl | UntypedFormGroup {
    return this.control?.get('data');
  }

  get deliveryForm(): AbstractControl {
    return this.form.parent.get('stepDeliveryForm');
  }

  get parentForm(): AbstractControl | null {
    return this.form.get(this.controlName);
  }

  get activeType(): AddressType | null {
    return this.parentForm.get(this.controlTypeName).value;
  }

  set activeType(value: AddressType | null) {
    this.parentForm.get(this.controlTypeName).setValue(value, { emitEvent: false });
  }

  setActiveTypeFromObject(value: object): void {
    this.activeType = getAddressType(value);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      if (
        (!this.invoiceId && !this.router.url.includes(AppRoutes.invoice_copy) && !this.state.templateId) ||
        this.clearFunction ||
        this.removedContact
      ) {
        if (!this.options.length) {
          this.changeMode({ triggerName: 'create' });
        } else {
          this.mode = 'select';
          const stepDeliveryForm = this.invoiceForm.get('stepDeliveryForm');
          this.subscriptions = merge(of(stepDeliveryForm.value), stepDeliveryForm.valueChanges)
            .pipe(
              switchMap((value) => {
                if (value.CargoType === 'Cargo') {
                  if (value.OptionsSeat && value.OptionsSeat.length > 1) {
                    return of([true]);
                  } else {
                    return this.checkParamSeats();
                  }
                } else {
                  return of([false]);
                }
              }),
            )
            .subscribe(([value]) => this.togglePostbox(value));
        }
        this.cd.markForCheck();
      }
    });
    this.subscriptions = this.idService.removeRecipientEventSubject.subscribe(() => {
      this.latestCity = null;
    });
  }

  checkParamSeats() {
    const paramsSeats = this.invoiceForm.get('stepDeliveryForm.paramsCommonSeats');
    if (paramsSeats && paramsSeats.value) {
      this.togglePostbox(true);
      return paramsSeats.valueChanges.pipe(
        map((value) => {
          return [value];
        }),
      );
    }
    return of([false]);
  }

  togglePostbox(value: boolean): void {
    this.optionSeatsLength = null;
    if (this.invoiceForm.get('stepDeliveryForm.OptionsSeat')) {
      this.optionSeatsLength = this.invoiceForm.get('stepDeliveryForm.OptionsSeat').value.length;
    }
    const tooltipText =
      this.optionSeatsLength > 1
        ? 'Параметр місця, повинен бути один'
        : 'Для можливості здійснення відправлення необхідно вказати габарити відправлення (Довжина х Ширина х Висота, см)';
    this.options.map((opt: any) => {
      if (opt.option.AddressDescription && opt.option.AddressDescription.includes('Поштомат') && opt.config) {
        opt.config.disabled = value;
        opt.config.tooltip = value ? tooltipText : '';
      }
    });
  }

  addFormControls() {
    const defaultValue = this.options.length ? this.options[0].option : '';
    const defaultMode = this.options.length ? 'select' : 'create';
    this.createControl(defaultValue, defaultMode);
  }

  createControl(defaultValue: DoorAddress | WarehouseAddress | '', defaultMode: string = 'select'): void {
    this.formGroup = this.fb.group({
      mode: ['select'],
      type: [defaultValue ? getAddressType(defaultValue) : 'Warehouse'],
      data: [
        defaultValue,
        Validators.compose([Validators.required, ValidateAddress]),
      ],
    });
    this.form.setControl(this.controlName, this.formGroup);

    this.setSubscriptionActiveType();
  }

  setSubscriptionActiveType(): void {
    this.subscriptions = this.form
      .get(this.controlName)
      .get(this.CONTROL_NAMES.DATA)
      .valueChanges.subscribe((data) => {
        this.setActiveTypeFromObject(data);
      });
  }

  transformFn: AutoCompleteTransformFn<DoorAddress & WarehouseAddress> = (value: DoorAddress & WarehouseAddress) => {
    if (value === null) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    const type = getAddressType(value);
    switch (type) {
      case 'Warehouse':
        return this.createWarehouseDescription(value);
      case 'Doors':
        return this.createDoorsDescription(value);
      case 'Postbox':
        return this.createWarehouseDescription(value);
      default:
        return value.Description;
    }
  };

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    this.mode = event.triggerName;
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
    const { data } = this.parentForm.value as TypedFormGroup;
    if (this.mode === 'create') {
      this.activeType = 'Warehouse';
    } else {
      this.setActiveTypeFromObject(data);
    }

    this.googleAnalyticsService.track('analitics', `second-step.createNewAddressRecipient`, {
      eventLabel: `Створення нової адреси отримувача`,
      eventCategory: 'create-invoice',
      eventAction: 'create.start',
    });
  }

  onTypeChanged(event) {
    const addressType = event.value;
    const middleNameControl = this.form.get('NewRecipientPrivateContact.middleName');
    if (!!middleNameControl) {
      if (addressType === 'Doors') {
        middleNameControl.setValidators(Validators.compose([Validators.required]));
        middleNameControl.markAsTouched();
      } else {
        middleNameControl.clearValidators();
      }
      middleNameControl.updateValueAndValidity();
    }
  }

  cancel(): void {
    this.mode = 'select';
    this.createControl(this.options.length ? this.options[0].option : '');
    this.latestCity = null;
    this.cd.detectChanges();
  }

  clear() {
    this.latestCity = null;
    this.changeMode({ triggerName: 'create' });

    this.form.get(this.controlName).reset({
      mode: 'create',
      type: 'Warehouse',
      data: {
        city: '',
        warehouse: '',
      },
    });
    this.cd.detectChanges();
  }

  setLatestCity(city) {
    this.latestCity = city;
  }

  createWarehouseDescription(address: WarehouseAddress) {
    return `${address.CityDescription}, ${address.AddressDescription}`;
  }

  createDoorsDescription(address: DoorAddress) {
    return `${address.Type} ${address.SettlementDescription}, ${address.StreetsType} ${address.StreetDescription} ${
      address.BuildingNumber
    }${address.Note ? ', ' + address.Note : ''}`;
  }

  ngAfterViewInit(): void {
    if (this.selectedAddress) {
      this.subscriptions = this.selectedAddress.subscribe((value) => {
        if (!value) {
          return;
        }
        // This cancel call fixes the display of form field (when it is set on init
        // it was still showing *create* mode
        this.cancel();
        setTimeout(() => {
          this.formGroup.patchValue({
            mode: 'select',
            data: value,
            type: value ? getAddressType(value) : 'Warehouse',
          });
          this.cd.detectChanges();
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

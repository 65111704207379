<form class="np-back-invoice-form" [formGroup]="form">
  <div class="modal modal_large">
    <span class="modal__close" matDialogClose>
      <mat-icon class="material-icons">close</mat-icon>
    </span>

    <div class="modal-header">
      <div class="form-row">
        <div class="form-col form-col-3">
          <h3 class="modal-header__title np-invoices-show__success">
            Легке повернення
          </h3>
        </div>
      </div>
    </div>

    <mat-progress-bar [style.opacity]="+(loading|async)" mode="indeterminate"></mat-progress-bar>

    <main class="modal-content">
      <div class="form-row">
        <div class="form-col form-col-8">
          <div class="banner">
            <div class="banner__info">
              <div class="warning-message">
                <div class="warning-message__text">
                  Послуга передбачає повернення раніше отриманого відправлення. Вартість послуги розраховується згідно з
                  <a class="link-red"
                     target="_blank"
                     href="https://novaposhta.ua/uploads/misc/doc/tarify.pdf">
                    чинними тарифами
                  </a> компанії «Нова Пошта»
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="np-invoices-show__success">
        <section class="form-row mr-bottom-16">
          <section class="form-col form-col-8">
            <stf-accordeon>
              <stf-accordeon-item>
                <div header>
                  <app-invoice-short-info [isIn]="true"
                                          [invoice]="invoice">
                  </app-invoice-short-info>
                </div>
                <div body>
                  <app-invoice-detailed-info [invoice]="invoice"></app-invoice-detailed-info>
                </div>
              </stf-accordeon-item>
            </stf-accordeon>
          </section>
        </section>

        <div class="form-row">
          <div class="form-col form-col-8">
            <mat-form-field class="form-input">
              <mat-select placeholder="Причина повернення"
                          [formControlName]="ControlName.SubtypeReason">
                <mat-option [value]="reason"
                            *ngFor="let reason of reasons; trackBy: trackBy">
                  {{reason.Description}}
                </mat-option>
              </mat-select>
              <mat-error>Це поле обов'язкове</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </main>

    <div class="modal-footer modal-footer_pad-8 d-flex justify-content-flex-end">
      <div class="d-inline-block flex-none">
        <button (click)="submit()"
                [disabled]="(loading|async)"
                color="primary"
                mat-button>
          Створити легке повернення
        </button>
      </div>
    </div>
  </div>
</form>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '../../../models/invoice/invoice.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'np-invoice-full-info-modal',
  styleUrls: ['./invoice-full-info-modal.component.scss'],
  templateUrl: './invoice-full-info-modal.component.html',
})
export class InvoiceFullInfoModalComponent {
  @Input() invoice: Invoice;
  @Input() invoiceFullData: any;
  payerNameTransations = {
    Sender: 'Відправник',
    Recipient: 'Отримувач',
    ThirdPerson: 'Третя особа',
  };
}

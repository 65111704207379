<div class="modal modal_large">
  <span class="modal__close ngdialog-close" (click)="close()">
    <i class="material-icons">close</i>
  </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">Заявка № {{item?.OrderNumber}} </h3>
      </div>
    </div>
  </div>
  <main class="modal-content modal-content_pad-none">
    <div class="data-table-wrapper">
      <table class="data-table">
        <tbody>
        <tr class="data-table__top no-right-border">
          <td>
            <div class="data-table__row">
              <div class="data-table__item">
                <div class="data-table__label">Накладна</div>
                <div class="data-table__text">{{item?.DocumentNumber | fineDocNumber}}</div>
              </div>
            </div>
          </td>
          <td colspan="2">
            <div class="data-table__row">
              <div class="data-table__item">
                <div class="data-table__label">Дата створення</div>
                <div class="data-table__text">{{item?.DateTime | date : "dd.MM.y"}}</div>
              </div>
              <div class="data-table__item">
                <div class="data-table__label">Статус</div>
                <div class="data-table__text">{{item?.OrderStatus}}</div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr class="data-table__head">
          <td>Поле</td>
          <td>До змін</td>
          <td>Після змін</td>
        </tr>
        <tr *ngFor="let el of list">
          <td>{{el?.key}}</td>
          <td><span class="line-trough">{{el?.before}}</span></td>
          <td>{{el?.after}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </main>
  <div class="modal-footer modal-footer_pad-8">
    <div class="form-row">
      <div class="form-col modal-footer-buttons">
        <div class="modal-footer-buttons__item">
          <button class="mat-button_sm mat-button_uppercase" color="primary" mat-raised-button (click)="close()">
            Закрити
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

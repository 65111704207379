import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Contact, IApiError } from '@models';
import { AlertToastService, AuthService } from '@services';
import { formatUAPhone } from '@shared';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, filter, finalize, map, take } from 'rxjs';
import Rxmq, { Channel } from 'rxmq';
import { documentsList } from '../../../components/invoice-form/components/services/different-documents/documents-list.model';
import { Invoice } from '../../../models/invoice/invoice.model';
import { BusinessSettingsService } from '../../../services/business-settings.service';
import { ChangeEwService } from '../../../services/change-ew.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { AnalyticEvent } from '../../interfaces/analytic-event';
import { ChangeEwContactFieldComponent } from './change-ew-contact-field/change-ew-contact-field.component';
import { PossibilityChangeEw } from './models';

const componentName = 'app-change-ew';

@Component({
  selector: 'app-change-ew',
  templateUrl: 'change-ew.component.html',
  styleUrls: ['./change-ew.component.scss'],
})
export class ChangeEwComponent implements OnInit {
  @Input()
  type: 'in' | 'out';

  @Input()
  invoice: Invoice;

  form: UntypedFormGroup;

  isLoadingSpinner: boolean;

  isLoadingProgress: boolean;

  contragent: any;

  possibilityChangeEw: PossibilityChangeEw;
  checkedProps = [
    'CanChangePayerTypeOrPaymentMethod',
    'CanChangeRecipient',
    'CanChangeSender',
    'CanChangeBackwardDeliveryDocuments',
    'CanChangeBackwardDeliveryMoney',
    'CanChangeBackwardDeliveryOther',
    'CanChangeAfterpaymentType',
    'CanChangeLiftingOnFloor',
    'CanChangeFillingWarranty',
  ];

  servicesNames = {
    backDelivery: {
      name: 'Грошовий переказ',
      description:
        'Послуга передбачає повернення коштів Отримувачем за відправлення Відправнику',
    },
    backDocuments: {
      name: 'Зворотна доставка документів',
      description: 'Послуга передбачає повернення документів Відправнику. Забір документів не є обов\'язковим для видачі відправлення Отримувачу',
    },
    afterpayment: {
      name: 'Контроль оплати',
      description:
        'Послуга передбачає контроль оплати готівкою за отримане Отримувачем відправлення.' +
        ' Кошти перераховуються Відправнику на його поточний рахунок (наступного робочого дня) на підставі укладеного договору',
    },
    floorsLifting: {
      name: 'До дверей',
      description:
       `Якщо ви замовили послугу*, кур’єр доставить вашу посилку до вхідних дверей**.
        Вартість послуги – 100 грн (за кожні 100 кг).
        *Безплатно для відправлень до 30 кг.
        **Ідеться про двері квартири, офісу або вхідні двері приватного будинку.`,
    },
    differentDocuments: {
      name: 'Зворотна доставка підтипів документів',
      description:
        'Послуга, яка передбачає повернення Відправнику тих підтипів документів, ' +
        'які були зазначені під час оформлення відправлення',
    },
  };

  displayServices;

  servicesGroup: UntypedFormGroup;

  senderMode: 'create' | 'select' = 'select';

  organizationMode: 'create' | 'select' = 'select';

  recipientMode: 'create' | 'select' = 'select';

  initialServicesValue: any;

  privatePersonRef: string;

  corporate = false;

  payerType: string;

  showTooltipFloor: boolean;

  showTooltip = false;

  showTooltipAfterpayment = false;

  @ViewChild('floors') private floorsElement: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private alertToastService: AlertToastService,
    private dialogRef: MatDialogRef<ChangeEwContactFieldComponent>,
    private settingService: BusinessSettingsService,
    private contactsService: ContactsService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private authService: AuthService,
    private changeEwService: ChangeEwService,
  ) {
  }

  ngOnInit(): void {
    this.corporate = !!this.authService.user.contacts;
    this.createForm();
    this.loadData();
    this.contactsService
      .getPrivatePersonInfo()
      .subscribe((data) => (this.privatePersonRef = data.Ref));
    this.displayServices = this.getAllDisplayServices();
  }

  createForm(): void {
    this.form = this.fb.group({
      CanChangeSender: [false, Validators.required],
      CanChangeRecipient: [false, Validators.required],
      CanChangePayerTypeOrPaymentMethod: [false, Validators.required],
      Cost: [this.invoice.announcedPrice || 0],
      PayerType: [null, Validators.required],
      PaymentMethod: [null, Validators.required],
      recipientType: ['PrivatePerson'],
      senderType: ['PrivatePerson'],
      ContactPersonSender: [''],
      ContactPersonRecipient: [''],
      senderData: [''],
      recipientData: [''],
      AddressRecipient: [
        {
          type: this.invoice.recipientAddress.type,
          data: this.invoice.recipientAddress,
          mode: 'select',
        },
      ],
      AddressSender: [
        {
          type: this.invoice.senderAddress.type,
          data: this.invoice.senderAddress,
          mode: 'select',
        },
      ],
    });
    this.servicesGroup = this.fb.group({
      backDelivery: this.fb.group({
        selected: [false],
      }),
      backDocuments: this.fb.group({
        selected: [false],
      }),
      afterpayment: this.fb.group({
        selected: [false],
      }),
      floorsLifting: this.fb.group({
        selected: [false],
      }),
      differentDocuments: this.fb.group({
        selected: [{ value: false, disabled: false }],
      }),
    });

    this.form.addControl('AdditionalServices', this.servicesGroup);
  }

  getAllDisplayServices() {
    return JSON.parse(JSON.stringify(this.servicesNames));
  }

  close(): void {
    this.dialogRef.close();
  }

  resetRecipientCounterparty(): void {
    const recipientType =
      this.possibilityChangeEw.RecipientCounterparty === 'Приватна особа'
        ? 'PrivatePerson'
        : 'Organization';

    this.form.get('recipientType').setValue(recipientType);
  }

  loadData(): void {
    this.isLoadingSpinner = true;

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.load`)
      .next({
        eventLabel: `Змінна даних: ${this.invoice.docNumber || ''}`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    this.changeEwService
      .checkPossibilityChange(this.invoice.docNumber)
      .pipe(
        take(1),
        map(
          (possibilityChangeEw: PossibilityChangeEw) =>
            possibilityChangeEw || {},
        ),
        finalize(() => (this.isLoadingSpinner = false)),
      )
      .subscribe(
        (possibilityChangeEw: PossibilityChangeEw) => {
          if (this.isAllDisabled(possibilityChangeEw)) {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.load.error`)
              .next({
                eventLabel: `Змінна даних заборонена: ${
                  this.invoice.docNumber || ''
                }`,
                eventCategory: componentName,
                eventAction: 'load.error',
              });
            this.alertToastService.pushError('Змінна даних заборонена');
            this.close();
          } else {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.load.success`)
              .next({
                eventLabel: `Змінна даних можлива: ${
                  this.invoice.docNumber || ''
                }`,
                eventCategory: componentName,
                eventAction: 'load.success',
              });
            this.possibilityChangeEw = possibilityChangeEw;
            this.payerType = possibilityChangeEw.PayerType;
            this.form.patchValue({
              ...this.possibilityChangeEw,
              recipientType:
                this.possibilityChangeEw.RecipientCounterparty ===
                'Приватна особа'
                  ? 'PrivatePerson'
                  : 'Organization',
              senderType:
                this.possibilityChangeEw.SenderCounterparty === 'Приватна особа'
                  ? 'PrivatePerson'
                  : 'Organization',
              senderData: {
                description: possibilityChangeEw.ContactPersonSender,
                phone: possibilityChangeEw.SenderPhone,
              },
              recipientData: {
                description: possibilityChangeEw.ContactPersonRecipient,
                phone: possibilityChangeEw.RecipientPhone,
                edrpou: possibilityChangeEw.EdrpouRecipient,
              },
            });

            this.setDisabledServices();
            this.setServicesToForm(this.possibilityChangeEw);

            this.form
              .get('PayerType')
              .valueChanges.pipe(
              distinctUntilChanged(),
              filter((val) => val === 'ThirdPerson'),
            )
              .subscribe(() =>
                this.form.get('PaymentMethod').setValue('NonCash'),
              );
          }
        },
        (errors: IApiError[]) => {
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.load.error`)
            .next({
              eventLabel: `Змінна даних неможлива: ${
                this.invoice.docNumber || ''
              }, помилка: ${errors[0] && errors[0].messageCode}`,
              eventCategory: componentName,
              eventAction: 'load.error',
            });
          this.alertToastService.pushApiErrors(errors);
          this.close();
        },
      );
  }

  setDisabledServices() {
    if (!this.possibilityChangeEw.CanChangeBackwardDeliveryMoney) {
      this.servicesGroup.get('backDelivery').get('selected').disable();
    }
    if (!this.possibilityChangeEw.CanChangeBackwardDeliveryDocuments) {
      this.servicesGroup.get('backDocuments').get('selected').disable();
    }
  }

  submit(): void {
    const isError = this.checkErrors(this.form);
    if (isError) {
      return;
    }

    const form = this.form.getRawValue();
    const sender = form.NewSender;
    const recipient = form.NewRecipient;
    const payerTypeChanged =
      sender ||
      recipient ||
      this.possibilityChangeEw.PayerType !== form.PayerType;
    const paymentMethodChanged =
      sender ||
      recipient ||
      this.possibilityChangeEw.PaymentMethod !== form.PaymentMethod;
    const servicesChanged = !_.isEqual(
      this.initialServicesValue,
      this.form.controls.AdditionalServices.value,
    );
    this.contragent = this.form.get('Contragent')
      ? this.form.get('Contragent').value
      : null;
    if (
      sender ||
      recipient ||
      payerTypeChanged ||
      paymentMethodChanged ||
      servicesChanged
    ) {
      this.isLoadingProgress = true;
      const { PayerType, PaymentMethod } = form;

      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.submit`)
        .next({
          eventLabel: `Змінна даних: ${this.invoice.docNumber || ''}`,
          eventCategory: componentName,
          eventAction: 'submit',
        });
      this.changeEwService
        .save({
          docNumber: this.invoice.docNumber,
          data: {
            ...(PayerType !== 'ThirdPerson' ? { PayerType } : {}),
            PaymentMethod,
            ...this.getSenderParams(sender),
            ...this.getRecipientParams(recipient, this.contragent),
            ...this.getServicesParams(form.AdditionalServices),
          },
        })
        .pipe(
          finalize(() => (this.isLoadingProgress = false)),
          take(1),
        )
        .subscribe(
          (data) => {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.submit.success`)
              .next({
                eventLabel: `Змінна даних: ${this.invoice.docNumber || ''}`,
                eventCategory: componentName,
                eventAction: 'submit.success',
              });
            this.dialogRef.close({
              success: true,
              AlertForCall: data.AlertForCall,
              MessageText: data.MessageText,
              Number: data.Number,
            });
          },
          (errors: IApiError[]) => {
            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.submit.error`)
              .next({
                eventLabel: `Змінна даних: ${this.invoice.docNumber || ''} ${
                  errors[0] && errors[0].message
                }`,
                eventCategory: componentName,
                eventAction: 'submit.error',
              });
            this.alertToastService.pushApiErrors(errors);
          },
        );
    } else {
      this.alertToastService.pushError(
        'Немає зміни даних. Документ не збережено',
      );
    }
  }

  checkErrors(form: UntypedFormGroup | any) {
    let isError = false;
    if (form.invalid) {
      isError = true;
    }
    if (form.controls.Contragent && !form.controls.Contragent?.value) {
      this.toaster('Організація не існує або неправильно вказано код ЄДРПОУ');
      isError = true;
    }
    if (form.controls.NewSender) {
      if (form.controls.NewSender.controls.phone.invalid) {
        this.toaster('Телефон відправника не заповнено');
      }
      if (form.controls.NewSender.controls.lastName.invalid) {
        this.toaster('Прізвище відправника не заповнено');
      }
      if (form.controls.NewSender.controls.firstName.invalid) {
        this.toaster('Ім\'я відправника не заповнено');
      }
      if (form.controls.NewSender.controls.middleName.invalid) {
        this.toaster('По батькові відправника не заповнено');
      }
    }
    if (form.controls.NewContactOrganization) {
      if (
        form.controls.NewSender.controls.ContactOrganization.controls.edrpou
          .invalid
      ) {
        this.toaster('Код ЄДРПОУ відправника не заповнено');
      }
    }
    if (form.controls.NewRecipient) {
      if (form.controls.NewRecipient.controls.phone.invalid) {
        this.toaster('Телефон отримувача не заповнено');
      }
      if (form.controls.NewRecipient.controls.lastName.invalid) {
        this.toaster('Прізвище отримувача не заповнено');
      }
      if (form.controls.NewRecipient.controls.firstName.invalid) {
        this.toaster('Ім\'я отримувача не заповнено');
      }
      if (form.controls.NewRecipient.controls.middleName.invalid) {
        this.toaster('По батькові отримувача не заповнено');
      }
    }
    if (
      form.controls.AdditionalServices.controls.backDelivery &&
      (this.form.controls.AdditionalServices as any).controls.backDelivery
        .controls.BackwardDeliveryData
    ) {
      // tslint:disable-next-line:max-line-length
      const backDeliveryValue = (this.form.controls.AdditionalServices as any)
        .controls.backDelivery.controls.BackwardDeliveryData.controls
        .RedeliveryString.value;
      if (backDeliveryValue === '') {
        this.toaster('Грошовий переказ не заповнено');
        isError = true;
      }
    }
    if (
      form.controls.AdditionalServices.controls.afterpayment &&
      form.controls.AdditionalServices.controls.afterpayment.value.selected ===
      true
    ) {
    }
    if (form.controls.AdditionalServices.controls.floorsLifting.invalid) {
      this.toaster('Поверх не заповнено');
      isError = true;
    }
    return isError;
  }

  toaster(message: string) {
    this.toastr.info(message, '', {
      closeButton: true,
      timeOut: 10000,
      toastClass: 'toast custom-style',
    });
  }

  setServicesToForm(possibilityChangeEw: PossibilityChangeEw) {
    const backwardDeliveryObject: any = {};
    for (const service of possibilityChangeEw.BackwardDeliveryData) {
      backwardDeliveryObject[service.CargoType] = service;
    }
    if (
      Object.keys(possibilityChangeEw.BackwardDeliveryData).length !== 0 &&
      backwardDeliveryObject.Money
    ) {
      this.form.get('AdditionalServices.backDelivery.selected').setValue(true);
      setTimeout(() => {
        if (
          this.form.get('AdditionalServices.backDelivery.selected').value ===
          true
        ) {
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.RedeliveryString',
            )
            .setValue(backwardDeliveryObject.Money.Description);
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.CargoType',
            )
            .setValue(backwardDeliveryObject.Money.CargoType);
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.PayerType',
            )
            .setValue(backwardDeliveryObject.Money.PayerType);
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.ReceivingType',
            )
            .setValue(
              backwardDeliveryObject.Money.Cash2CardPAN ? 'Card' : 'Warehouse',
            );
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.InitialCard',
            )
            .setValue(backwardDeliveryObject.Money.Cash2CardPAN);
          this.form
            .get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.Cash2CardAlias',
            )
            .setValue(backwardDeliveryObject.Money.Cash2CardAlias);
          if (backwardDeliveryObject.Money.Cash2CardCreditSid?.length) {
            this.form
              .get(
                'AdditionalServices.backDelivery.BackwardDeliveryData.Cash2CardCreditSid',
              )
              .setValue(backwardDeliveryObject.Money.Cash2CardCreditSid);
          }
          if (
            this.form.get(
              'AdditionalServices.backDelivery.BackwardDeliveryData.ReceivingType',
            ).value === 'Card'
          ) {
            const amount = backwardDeliveryObject.Money.Description;
            const payoutId = backwardDeliveryObject.Money.Cash2CardPayout_Id;
            const cardPAN = backwardDeliveryObject.Money.Cash2CardPAN;
            const alias = backwardDeliveryObject.Money.Cash2CardAlias;
            this.cd.detectChanges();

            this.form
              .get(
                'AdditionalServices.backDelivery.BackwardDeliveryData.RedeliveryString',
              )
              .setValue(amount);
            this.form
              .get(
                'AdditionalServices.backDelivery.BackwardDeliveryData.Cash2CardPayout_Id',
              )
              .setValue(payoutId);
            this.form
              .get(
                'AdditionalServices.backDelivery.BackwardDeliveryData.Cash2CardPAN',
              )
              .setValue(cardPAN);
            this.form
              .get('AdditionalServices.backDelivery.BackwardDeliveryData.cards')
              .setValue([
                [
                  {
                    pan: cardPAN,
                    id: payoutId,
                    alias: alias ?? '',
                  },
                ],
              ]);
            (
              this.form.get('AdditionalServices.backDelivery') as UntypedFormGroup
            ).addControl('number', new UntypedFormControl(this.invoice.docNumber));
          }
          this.cd.detectChanges();
        }
      });
    }

    if (
      Object.keys(possibilityChangeEw.BackwardDeliveryData).length !== 0 &&
      backwardDeliveryObject.Documents
    ) {
      if (!possibilityChangeEw.BackwardDeliveryData[0].Services) {
        this.form
          .get('AdditionalServices.backDocuments.selected')
          .setValue(true);
        setTimeout(() => {
          if (
            this.form.get('AdditionalServices.backDocuments.selected').value ===
            true
          ) {
            this.form
              .get(
                'AdditionalServices.backDocuments.BackwardDeliveryData.RedeliveryString',
              )
              .setValue(backwardDeliveryObject.Documents.Description);
            this.form
              .get(
                'AdditionalServices.backDocuments.BackwardDeliveryData.CargoType',
              )
              .setValue(backwardDeliveryObject.Documents.CargoType);
            this.form
              .get(
                'AdditionalServices.backDocuments.BackwardDeliveryData.PayerType',
              )
              .setValue(backwardDeliveryObject.Documents.PayerType);
            this.cd.detectChanges();
          }
        });
      } else {
        this.form
          .get('AdditionalServices.differentDocuments.selected')
          .setValue(true);
        setTimeout(() => {
          if (this.form.get('AdditionalServices.differentDocuments.selected')) {
            this.form
              .get(
                'AdditionalServices' +
                '.differentDocuments.BackwardDeliveryData.PayerType',
              )
              .setValue(backwardDeliveryObject.Documents.PayerType);
            const services =
              possibilityChangeEw.BackwardDeliveryData[0].Services;
            if (services) {
              const documents = this.form.get(
                `AdditionalServices.differentDocuments.BackwardDeliveryData.documents`,
              ) as UntypedFormArray;
              const newDocuments = documentsList
                .filter((el: any) =>
                  el.services.hasOwnProperty('stampSign')
                    ? !!services[el.services.stampSign.name]
                    : (el.services.stamp &&
                      !!services[el.services.stamp.name]) ||
                    (el.services.sign && !!services[el.services.sign.name]),
                )
                .map((el: any) => {
                  if (el.services.hasOwnProperty('stampSign')) {
                    el.services.stampSign.value =
                      !!services[el.services.stampSign.name];
                    el.services.stampSign.checked =
                      !!services[el.services.stampSign.name];
                  } else {
                    if (el.services.hasOwnProperty('stamp')) {
                      el.services.stamp.value =
                        !!services[el.services.stamp.name];
                      el.services.stamp.checked =
                        !!services[el.services.stamp.name];
                    }
                    if (el.services.hasOwnProperty('sign')) {
                      el.services.sign.value =
                        !!services[el.services.sign.name];
                      el.services.sign.checked =
                        !!services[el.services.sign.name];
                    }
                  }
                  return el;
                });
              if (newDocuments.length) {
                documents.removeAt(0);
                newDocuments.forEach((document) =>
                  documents.push(this.fb.group(document)),
                );
              }
            }
          }
        });
      }
    }

    this.setAfterpaymentOnGoodsCost(possibilityChangeEw);

    this.setFloorsLifting(possibilityChangeEw);

    this.initialServicesValue = JSON.parse(
      JSON.stringify(this.form.controls.AdditionalServices.value),
    );
  }

  changeMode(modeType: string, action: string) {
    this[modeType] = action;
  }

  removeControl(controlName: string): void {
    this.form.removeControl(controlName);
  }

  setFloorsLifting(
    possibilityChangeEw: PossibilityChangeEw,
    flag = true,
  ): void {
    if (!flag) {
      return;
    }

    if (
      !possibilityChangeEw.NumberOfFloorsLifting &&
      !possibilityChangeEw.CanChangeLiftingOnFloor
    ) {
      this.form
        .get('AdditionalServices.floorsLifting.selected')
        .setValue(false);
      this.showTooltip = true;
      this.showTooltipFloor = true;
    }

    if (
      !possibilityChangeEw.NumberOfFloorsLifting &&
      possibilityChangeEw.CanChangeLiftingOnFloor
    ) {
      this.form.get('AdditionalServices.floorsLifting.selected').enable();
    }

    if (possibilityChangeEw.NumberOfFloorsLifting) {
      if (
        !possibilityChangeEw.CanChangeLiftingOnFloor
      ) {
        this.form
          .get('AdditionalServices.floorsLifting.selected')
          .setValue(true);
        setTimeout(() => {
          this.form
            .get(
              'AdditionalServices.floorsLifting.FloorsLiftingData.NumberOfFloorsLifting',
            )
            .disable();
          this.form
            .get(
              'AdditionalServices.floorsLifting.FloorsLiftingData.NumberOfFloorsLifting',
            )
            .setValue(possibilityChangeEw.NumberOfFloorsLifting);
        }, 0);
        this.showTooltip = true;
        this.showTooltipFloor = true;
      }

      if (
        possibilityChangeEw.CanChangeLiftingOnFloor
      ) {
        this.form
          .get('AdditionalServices.floorsLifting.selected')
          .setValue(true);
        this.form.get('AdditionalServices.floorsLifting.selected').enable();
        setTimeout(() => {
          this.form
            .get(
              'AdditionalServices.floorsLifting.FloorsLiftingData.NumberOfFloorsLifting',
            )
            .setValue(possibilityChangeEw.NumberOfFloorsLifting);
        }, 0);
      }
    }
  }

  setAfterpaymentOnGoodsCost(
    possibilityChangeEw: PossibilityChangeEw,
    flag = true,
  ): void {
    if (!flag) {
      return;
    }
    if (
      !Number(possibilityChangeEw.AfterpaymentOnGoodsCost) &&
      !possibilityChangeEw.CanChangeAfterpaymentType
    ) {
      this.form.get('AdditionalServices.afterpayment.selected').setValue(false);
      this.form.get('AdditionalServices.afterpayment.selected').disable();
      this.showTooltipAfterpayment = true;
    }

    if (
      Number(possibilityChangeEw.AfterpaymentOnGoodsCost) &&
      !possibilityChangeEw.CanChangeAfterpaymentType
    ) {
      this.showTooltipAfterpayment = true;
      this.form.get('AdditionalServices.afterpayment.selected').setValue(true);
      setTimeout(() => {
        this.form
          .get('AdditionalServices.afterpayment.AfterpaymentOnGoodsCost')
          .setValue(possibilityChangeEw.AfterpaymentOnGoodsCost);
        this.form.get('AdditionalServices.afterpayment.selected').disable();
        this.form
          .get('AdditionalServices.afterpayment.AfterpaymentOnGoodsCost')
          .disable();
      });
    }

    if (
      !Number(possibilityChangeEw.AfterpaymentOnGoodsCost) &&
      possibilityChangeEw.CanChangeAfterpaymentType
    ) {
      this.form
        .get('AdditionalServices.afterpayment.AfterpaymentOnGoodsCost')
        ?.setValue(possibilityChangeEw.AfterpaymentOnGoodsCost);
    }

    if (
      Number(possibilityChangeEw.AfterpaymentOnGoodsCost) &&
      possibilityChangeEw.CanChangeAfterpaymentType
    ) {
      this.form.get('AdditionalServices.afterpayment.selected').setValue(true);
      setTimeout(() => {
        this.form
          .get('AdditionalServices.afterpayment.AfterpaymentOnGoodsCost')
          .setValue(possibilityChangeEw.AfterpaymentOnGoodsCost);
      });
    }
  }

  scroll() {
    this.cd.detectChanges();
    const el: HTMLElement = this.floorsElement
      ? this.floorsElement.nativeElement
      : null;
    if (el) {
      el.scrollIntoView();
    }
  }

  getContactDescription(contactType: 'Recipient' | 'Sender') {
    return `${
      (this.possibilityChangeEw &&
        this.possibilityChangeEw[`ContactPerson${contactType}`]) ||
      ''
    } ${formatUAPhone(
      (this.possibilityChangeEw &&
        this.possibilityChangeEw[`${contactType}Phone`]) ||
      '',
    )}`;
  }

  joinName(recipient): string {
    if (!recipient) {
      return '';
    }
    const firstName = recipient.firstName;
    const lastName = recipient.lastName;
    const middleName = recipient.middleName
      ? recipient.middleName[0]?.toUpperCase() +
      recipient.middleName.substring(1)
      : '';
    return [
      lastName[0]?.toUpperCase() + lastName.substring(1),
      firstName[0]?.toUpperCase() + firstName.substring(1),
      middleName,
    ]
      .join(' ')
      .trim();
  }

  private getSenderParams(sender: Contact): {
    SenderContactName?: string;
    SenderPhone?: string;
  } {
    return sender
      ? {
        SenderContactName: this.joinName(sender),
        SenderPhone: sender.phone,
      }
      : {};
  }

  private getRecipientParams(
    recipient: Contact,
    contragent,
  ): {
    Recipient?: string;
    RecipientContactName?: string;
    RecipientPhone?: string;
  } {
    return recipient
      ? {
        Recipient: contragent ? contragent.Ref : this.privatePersonRef,
        RecipientContactName: this.joinName(recipient),
        RecipientPhone: recipient.phone,
      }
      : {};
  }

  private getServicesParams(services) {
    const servicesParams: any = {};

    if (services) {
      if (services.backDelivery) {
        if (services.backDelivery.BackwardDeliveryData) {
          const backObj: any = {
            PayerType: services.backDelivery.BackwardDeliveryData.PayerType,
            CargoType: services.backDelivery.BackwardDeliveryData.CargoType,
            Description:
            services.backDelivery.BackwardDeliveryData.RedeliveryString,
          };
          if (
            backObj.CargoType === 'Money' &&
            services.backDelivery.BackwardDeliveryData.ReceivingType === 'Card'
          ) {
            if (
              services.backDelivery.BackwardDeliveryData.Cash2CardPayout_Id ===
              '' &&
              services.backDelivery.BackwardDeliveryData.Cash2CardCreditSid ===
              ''
            ) {
              servicesParams.Cash2Card = true;
            }
            if (
              services.backDelivery.BackwardDeliveryData.Cash2CardPayout_Id !==
              ''
            ) {
              Object.assign(backObj, {
                Cash2CardPayout_Id:
                services.backDelivery.BackwardDeliveryData.Cash2CardPayout_Id,
                Cash2CardAlias:
                services.backDelivery.BackwardDeliveryData.Cash2CardAlias,
                Cash2CardPAN:
                services.backDelivery.BackwardDeliveryData.Cash2CardPAN,
              });
              servicesParams.Cash2Card = true;
            } else if (
              services.backDelivery.BackwardDeliveryData.Cash2CardCreditSid !==
              ''
            ) {
              backObj.Cash2CardCreditSid =
                services.backDelivery.BackwardDeliveryData.Cash2CardCreditSid;
              backObj.Cash2CardAlias =
                services.backDelivery.BackwardDeliveryData.Cash2CardAlias;
            }
          }
          servicesParams.BackwardDeliveryData =
            servicesParams.BackwardDeliveryData
              ? [...servicesParams.BackwardDeliveryData, backObj]
              : [backObj];
        }
      }
      if (
        services.backDocuments &&
        services.backDocuments.BackwardDeliveryData
      ) {
        const backObj = {
          PayerType: services.backDocuments.BackwardDeliveryData.PayerType,
          CargoType: services.backDocuments.BackwardDeliveryData.CargoType,
          RedeliveryString:
          services.backDocuments.BackwardDeliveryData.RedeliveryString,
        };
        servicesParams.BackwardDeliveryData =
          servicesParams.BackwardDeliveryData
            ? [...servicesParams.BackwardDeliveryData, backObj]
            : [backObj];
      }

      if (services.afterpayment) {
        if (
          Number(this.possibilityChangeEw.AfterpaymentOnGoodsCost) !==
          Number(services.afterpayment.AfterpaymentOnGoodsCost) &&
          services.afterpayment.AfterpaymentOnGoodsCost
        ) {
          if (services.afterpayment.AfterpaymentOnGoodsCost) {
            servicesParams.AfterpaymentOnGoodsCost =
              services.afterpayment.AfterpaymentOnGoodsCost;
          } else {
            servicesParams.AfterpaymentOnGoodsCost = 0;
          }
        }
        if (
          Number(this.possibilityChangeEw.AfterpaymentOnGoodsCost) &&
          services.afterpayment.AfterpaymentOnGoodsCost === undefined
        ) {
          servicesParams.AfterpaymentOnGoodsCost = 0;
        }
      }

      if (services.floorsLifting) {
        if (
          this.possibilityChangeEw.NumberOfFloorsLifting !==
          Number(
            services.floorsLifting.FloorsLiftingData?.NumberOfFloorsLifting,
          ) &&
          services.floorsLifting.FloorsLiftingData?.NumberOfFloorsLifting
        ) {
          if (
            !isNaN(
              services.floorsLifting.FloorsLiftingData?.NumberOfFloorsLifting,
            )
          ) {
            servicesParams.NumberOfFloorsLifting = Number(
              services.floorsLifting.FloorsLiftingData?.NumberOfFloorsLifting,
            );
          } else {
            servicesParams.NumberOfFloorsLifting = 0;
          }
        }
        if (
          this.possibilityChangeEw.NumberOfFloorsLifting > 0 &&
          services.floorsLifting.FloorsLiftingData?.NumberOfFloorsLifting ===
          undefined
        ) {
          servicesParams.NumberOfFloorsLifting = 0;
        }
      }
      if (
        services.signedDocuments &&
        services.signedDocuments.BackwardDeliveryData
      ) {
        const backObj = {
          PayerType: services.signedDocuments.BackwardDeliveryData.PayerType,
          CargoType: services.signedDocuments.BackwardDeliveryData.CargoType,
          RedeliveryString:
          services.signedDocuments.BackwardDeliveryData.RedeliveryString,
        };
        servicesParams.BackwardDeliveryData =
          servicesParams.BackwardDeliveryData
            ? [...servicesParams.BackwardDeliveryData, backObj]
            : [backObj];
      }
      if (
        services.differentDocuments &&
        services.differentDocuments.BackwardDeliveryData
      ) {
        const data = {
          PayerType: services.differentDocuments.BackwardDeliveryData.PayerType,
          CargoType: services.differentDocuments.BackwardDeliveryData.CargoType,
        };
        const documents =
          services.differentDocuments.BackwardDeliveryData.documents;
        const differentServices: any = {};
        for (const document of documents) {
          const docServices = document.services;
          Object.keys(docServices).map((key) => {
            const service = docServices[key];
            if (service.checked) {
              differentServices[service.name] = service.checked;
            }
          });
        }
        differentServices.UserActions =
          services.differentDocuments.BackwardDeliveryData.UserActions;
        (data as any).Services = differentServices;
        servicesParams.BackwardDeliveryData =
          servicesParams.BackwardDeliveryData
            ? [...servicesParams.BackwardDeliveryData, data]
            : [data];
      }
    }
    if (!servicesParams.BackwardDeliveryData) {
      servicesParams.BackwardDeliveryData = [];
    }
    return servicesParams;
  }

  private formContactName(contact: Contact): string {
    return `${contact.lastName || ''} ${contact.firstName || ''} ${
      contact.middleName || ''
    }`;
  }

  private isAllDisabled(
    possibilityChangeEw: PossibilityChangeEw | any,
  ): boolean {
    return !this.checkedProps.some((key) => !!possibilityChangeEw[key]);
  }
}

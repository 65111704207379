import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AutoCompleteTransformFn } from '@autocomplete-with-controls';
import { PhoneMask } from '@core';
import { CatalogContact, ContactPerson, Contragent } from '@models';
import { formatUAPhone, FormContainer, getError, parseNumbers } from '@shared';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { config, ControlNames, errors, phoneErrors } from './create-contact.constants';
import { ValidateNewContact } from './create-contact.validators';

@Component({
  selector: 'app-create-contact',
  templateUrl: 'create-contact.component.html',
  styleUrls: ['./create-contact.component.scss'],
})
export class CreateContactComponent
  extends FormContainer
  implements OnInit, OnDestroy {
  getError = getError;

  ControlNames = ControlNames;

  config = config;

  phoneErrors = phoneErrors;

  lastNameErrors = errors;

  firstNameErrors = errors;

  middleNameErrors = errors;

  PhoneMaskConfig = {
    mask: PhoneMask,
    guide: false,
  };

  contactForm: UntypedFormGroup;

  @Input() controlName = 'PrivatePersonContact';

  @Input() contactProperty = 'Recipient';

  @Input() isTemplate: boolean;

  @Input() invoiceId: string;

  @Input() clearOnPhoneChange = true;

  @Input() patchRecipientControlName: string;

  @Input() patchRecipientData: string;

  @Input() isSender: boolean;

  // used for highlighting empty phone number where it is needed
  @Input() validateEmptyPhone: boolean = true;

  @Output() recipientSelected: EventEmitter<ContactPerson> = new EventEmitter();

  @ViewChild('lastNameInput') lastNameInput: ElementRef;

  catalogOptions: CatalogContact[] = [];

  showNotFoundContacts = false;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private contactService: ContactsService,
  ) {
    super();
  }

  get control(): UntypedFormGroup {
    return this.form?.get(this.controlName) as UntypedFormGroup;
  }

  get phone(): AbstractControl {
    return this.contactForm?.get(ControlNames.phone);
  }

  get lastName(): AbstractControl {
    return this.contactForm?.get(ControlNames.lastName);
  }

  get firstName(): AbstractControl {
    return this.contactForm?.get(ControlNames.firstName);
  }

  get middleName(): AbstractControl {
    return this.contactForm?.get(ControlNames.middleName);
  }

  transformFn: AutoCompleteTransformFn<ContactPerson> = (
    value: string | ContactPerson,
  ) =>
    typeof value === 'string'
      ? value
      : `${value.Description || ''} ${formatUAPhone(value.Phones || '')}`;

  ngOnInit(): void {
    super.ngOnInit();
    this.initLastNameSubscription();
    this.initStatusSubscriptions();

    if (this.control) {
      this.subscriptions = this.control.valueChanges.subscribe(() => {
        if (this.firstName?.disabled) {
          this.firstName.markAsPristine();
          this.firstName.markAsUntouched();
          this.firstName.updateValueAndValidity({ onlySelf: true });
        }
        if (this.lastName?.disabled) {
          this.lastName.markAsPristine();
          this.lastName.markAsUntouched();
          this.lastName.updateValueAndValidity({ onlySelf: true });
        }
        if (this.middleName?.disabled) {
          this.middleName.markAsPristine();
          this.middleName.markAsUntouched();
          this.middleName.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

    // if (this.isTemplate) {
    //   Object.keys(this.control?.controls).forEach((key) => {
    //     this.control?.controls[key].clearValidators();
    //   });
    // }
  }

  addFormControls(): void {
    this.contactForm = this.fb.group(
      {
        [ControlNames.phone]: [null, [Validators.minLength(17), ...(this.isTemplate ? [] : [Validators.required])]],
        [ControlNames.lastName]: [
          {
            value: '',
            disabled: true,
          },
          [Validators.required],
        ],
        [ControlNames.firstName]: [
          {
            value: '',
            disabled: true,
          },
          [Validators.required],
        ],
        [ControlNames.middleName]: [
          {
            value: '',
            disabled: true,
          },
        ],
      },
      {
        validators: [ValidateNewContact(this.isTemplate)],
      },
    );
    this.form.setControl(this.controlName, this.contactForm);
  }

  initLastNameSubscription(): void {
    this.subscriptions = this.lastName?.valueChanges
      .pipe(
        tap((value) => {
          if (typeof value === 'string') {
            if (value.length >= 2) {
              this.firstName.enable();
              this.middleName.enable();
              // this.cd.detectChanges();
            }
          } else {
            this.catalogOptions = [];
            this.cd.detectChanges();
          }
        }),
        debounceTime(600),
        tap((value) => {
          if (!this.isSender) {
            if (typeof value === 'string') {
              if (value.length >= 3) {
                this.searchRecipientContacts(value);
              } else {
                this.catalogOptions = [];
                this.cd.detectChanges();
              }
            }
          }
        }),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.showNotFoundContacts = false;

        // this.cd.detectChanges();
      });
  }

  initStatusSubscriptions(): void {
    this.subscriptions = this.phone?.statusChanges.subscribe((status) => {
      if (status === 'VALID' && this.phone?.value?.length) {
        this.lastName.enable();
        this.lastNameInput.nativeElement.focus();
      } else {
        this.resetOnPhoneInvalid();
      }
    });
  }

  resetOnPhoneInvalid(): void {
    this.catalogOptions = [];

    this.contactForm.patchValue({
      lastName: '',
      firstName: '',
      middleName: '',
    });
    this.lastName.disable();
    this.firstName.disable();
    this.middleName.disable();
  }

  searchRecipientContacts(query: string): void {
    this.contactService
      .searchContactsInCatalog(query, parseNumbers(this.phone.value))
      .subscribe((data) => {
        this.catalogOptions = data;
        this.cd.detectChanges();
      });
  }

  setOrganizationDisplay(contragent: Contragent) {
    if (this.form.get('NewContactOrganization')) {
      this.form.get('NewContactOrganization').patchValue({
        edrpou: contragent ? contragent.EDRPOU : '',
        companyName: contragent.Description
          ? contragent.Description
          : contragent.Description,
        orgType: contragent.OwnershipFormDescription
          ? contragent.OwnershipFormDescription
          : '',
      });
    }
  }

  setRecipientCounterpartyType(contragent: Contragent): void {
    const recipientCounterpartyType =
      contragent.CounterpartyType === 'PrivatePerson'
        ? 'PrivatePerson'
        : 'Organization';
    if (this.form.get('RecipientCounterpartyType')) {
      this.form
        .get('RecipientCounterpartyType')
        .setValue(recipientCounterpartyType);
    } else if (this.form.get('recipientType')) {
      this.form.get('recipientType').setValue(recipientCounterpartyType);
    }
  }

  setPrivatePerson(contact: ContactPerson): void {
    this.form.get('RecipientCounterpartyType').setValue('PrivatePerson');

    if (this.form.get('Contragent')) {
      this.form.removeControl('Contragent');
    }

    if (this.form.get('NewContactOrganization')) {
      this.form.removeControl('NewContactOrganization');
    }

    this.patchPrivatePerson(contact);
  }

  setOrganization(contact: any): void {
    this.patchPrivatePerson(contact);
    this.form.get('RecipientCounterpartyType').setValue('Organization');
    // Weird EDRPOU substitution without setTimeout
    setTimeout(() => {
      // this.form.setControl(
      //   'Contragent',
      //   this.fb.control({ EDRPOU: contact.EDRPOU }),
      // );
      if (this.form.get('NewContactOrganization')) {
        this.form
          .get('NewContactOrganization.edrpou')
          .patchValue(contact.EDRPOU);
        this.form.get('NewContactOrganization').markAsDirty();
      }
    });
    this.form.updateValueAndValidity();
  }

  phoneBlur(): void {
    if (this.validateEmptyPhone) {
      this.phone.updateValueAndValidity({ onlySelf: true });
      return;
    }

    if (!this.phone.value) {
      this.phone.markAsPristine();
      this.phone.markAsUntouched();
      this.phone.updateValueAndValidity({ onlySelf: true });
    }
  }

  setContactFromCatalog(event): void {
    const catalogContact: CatalogContact = event.option.value;

    if (
      catalogContact.Description === 'Приватна особа' ||
      !catalogContact.EDRPOU
    ) {
      this.setPrivatePerson(catalogContact);
    } else {
      this.setOrganization(catalogContact);
    }
  }

  clear(): void {
    this.form.removeControl(this.contactProperty);
    this.contactForm.patchValue({
      phone: '',
      lastName: '',
      firstName: '',
      middleName: '',
    });
  }

  onChangePhone(event) {
    event.preventDefault();
    const srcElement = event.srcElement || event.target;
    const phone = srcElement ? srcElement.value : '';
    if (phone.length === 19) {
      this.lastNameInput.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }

  private patchPrivatePerson(contact: ContactPerson): void {
    this.contactForm.patchValue({
      lastName: contact.LastName ? contact.LastName : '',
      firstName: contact.FirstName ? contact.FirstName : '',
      middleName: contact.MiddleName ? contact.MiddleName : '',
    });
  }
}

<div [ngClass]="{'form-search_show-spinner': showSpinner}">
  <mat-form-field [formGroup]="form" [floatLabel]="'always'">
    <mat-icon class="form-search__icon">search</mat-icon>
    <mat-icon class="form-search__icon-close" tabindex="0" (click)="clearContact()" *ngIf="inputFocused">close
    </mat-icon>
    <mat-spinner class="form-search__spinner"></mat-spinner>
    <mat-label></mat-label>
    <input class="form-search__input"
           #searchInput
           type="text"
           placeholder="Пошук у контактах (шукайте за ПІБ, телефоном, назвою компанії)"
           aria-label="Number"
           matInput
           [matAutocomplete]="auto1"
           [formControlName]="controlName"
           (blur)="onBlur($event)"
           (focus)="onFocus($event)"
           (keydown.enter)="$event.preventDefault()">
    <mat-autocomplete #auto1="matAutocomplete" class="form-search__panel" [displayWith]="displayFn">
      <mat-option *ngFor="let option of contactOptions" [value]="option" (onSelectionChange)="onSelectContact($event)">
        <div class="option-field option-field_sm">
          <div class="option-field__hint">{{option.Counterparty}}</div>
          <div class="option-field__text">{{option.Description}} {{option.Phones | npFinePhone}}</div>
        </div>
      </mat-option>
      <mat-option disabled *ngIf="showNotFoundContacts && inputFocused" class="no-contacts-option">
        <div class="option-field option-field_sm">
          <div class="option-field__text">Нічого не знайдено</div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>


import { Validators } from '@angular/forms';
import { GetCountriesData } from '@shared';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';

// Canada
export const CA: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
  State: {
    getMethodName: 'getProvinciesOfCanada',
    config: {
      ariaLabel: 'Провінція',
      placeholder: 'Провінція',
      arrow: true,
    },
    errorSnackMessages: {
      empty: 'Не заповнено провінцію отримувача',
      fromList: 'Оберіть провінцію зі списку',
    }
  },
};

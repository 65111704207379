import { ModuleWithProviders, NgModule } from '@angular/core';
import { FirebaseAuthService } from './firebase-auth.service';
import { FirebaseDatabaseService } from './firebase-database.service';
import { FIREBASE_FUNCTIONS_URL } from './firebase-functions-url.token';

import * as firebase from 'firebase/app';
import {environment} from '../../../environments/environment';

firebase.initializeApp(environment.firebaseConfig);

@NgModule()
export class FirebaseModule {
  static forRoot(): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule,
      providers: [
        FirebaseDatabaseService,
        FirebaseAuthService,
        { provide: FIREBASE_FUNCTIONS_URL, useValue: environment.functionsUrl },
      ],
    };
  }
}

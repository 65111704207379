import { AbstractControl } from '@angular/forms';
import { minLength, pattern } from './delivery-promocode.constants';
import { GetPromocodeResponseData } from '@shared';

export function PromocodeValidator(control: AbstractControl) {
  const value = control.value as GetPromocodeResponseData | string;

  if (!value) {
    return null;
  }

  if (typeof value !== 'object') {
    if (!pattern.test(value)) {
      return { pattern: true };
    }

    if (value.length < minLength) {
      return { minlength: true };
    }

    return null;
  }

  const isValid = pattern.test(value?.Promocode);

  return isValid ? null : { pattern: true };
}

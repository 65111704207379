import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-documents-amount',
  templateUrl: './delivery-documents-amount.component.html',
  styleUrls: ['./delivery-documents-amount.component.scss'],
})
export class DeliveryDocumentsAmountComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input() controlName = 'SeatsAmount';

  @Input() invoiceId: string;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls(): void {
    const descriptionControl = new UntypedFormControl('1', [
      Validators.required,
      Validators.min(1),
    ]);
    this.form.setControl(this.controlName, descriptionControl);
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }
}

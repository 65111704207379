import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {
  AutocompleteControls,
  AutocompleteControlsTriggeredEvent,
} from '@autocomplete-with-controls';
import {
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import {AbstractFormContainer} from '@shared';
import {EditableAutocompleteControl} from '@shared';
import {TypedFormControl} from '@shared';
import {formatUAPhone, ValidateContact} from '@shared';
import {distinctUntilChanged} from 'rxjs';
import {ErrorMessages, ErrorTypeMessages, ValidationService} from '../../../../services/validation.service';
import {getContactType} from '../../../../shared/utils/contact.util';
import {TypeRadioButton} from '../type-radio-group/type-radio-button';
import { ContactPerson, ContactType } from "@models";

@Component({
  selector: 'np-inv-contact-recipient',
  templateUrl: 'contact-recipient.component.html',
  styleUrls: ['./contact-recipient.component.scss'],
})
export class ContactRecipientComponent extends AbstractFormContainer implements EditableAutocompleteControl, TypedFormControl, OnInit {

  @Input()
  controlName = 'ContactRecipient';

  @Input()
  controlTypeName = 'ContactRecipientType';

  @Input()
  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Контакт отримувача',
    placeholder: 'Контакт',
    arrow: true,
  };

  @Input()
  controlsConfig: AutocompleteControls = null;

  @Input()
  types: Array<TypeRadioButton<ContactType>> = [
    {
      value: 'PrivatePerson',
      label: 'Фізична особа',
    },
    {
      value: 'Organization',
      label: 'Організація',
    },
  ];

  @Input()
  activeType: ContactType | null;

  @Input()
  preventInput = false;

  @Input()
  options: Array<AutoCompleteOptionItem<ContactPerson>> = [];

  @Output()
  controlChanged = new EventEmitter<ContactPerson | ''>();

  errors: ErrorTypeMessages = {
    [this.controlName]: [
      {key: 'required', message: 'This field is required'},
      {key: 'minlength', message: 'should be 3 at least'},
      {key: 'contact', message: 'Please select from autocomplete'},
    ],
  };

  errorMessages: ErrorMessages = {};
  mode: string;

  constructor(private fb: UntypedFormBuilder,
              private validationService: ValidationService) {
    super();
  }

  addFormControls() {
    this.createControl(this.form.get('RecipientSearchObject').value);
    this.form.get(this.controlName)
      .valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(_ => {
        this.errorMessages =
          this.validationService.getSingleControlErrorObject(this.controlName, this.form.get(this.controlName), this.errors);
      });
  }

  createControl(defaultValue: ContactPerson | ''): void {
    this.form.setControl(this.controlName, this.fb.control(
      { value: defaultValue, disabled: defaultValue !== '' },
      [
        Validators.required,
        ValidateContact,
      ]),
    );
    this.controlChanged.emit(defaultValue);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setActiveType(value: object): void {
    this.activeType = getContactType(value);
  }

  transformFn: AutoCompleteTransformFn<ContactPerson> =
    (value: string | ContactPerson) => {
      if (value) {
        return typeof value === 'string' ?
          value : `${(value.Description || '')} ${formatUAPhone(value.Phones || '')}`;
      } else {
        return '';
      }
    };

  optionsFilterFn: AutocompleteOptionsFilterFn<ContactPerson> =
    (value: string | ContactPerson, options: Array<AutoCompleteOptionItem<ContactPerson>>) => {
      const format = v => v.replace(/[\s\+\-]+/g, '').toLowerCase();
      const group = (contact: ContactPerson) => `${contact.Description || ''} ${contact.Phones || ''}`;

      const v = format(typeof value === 'string' ? value : group(value));

      return options.filter(el => format(group(el.option)).includes(v));
    };

  cancel(): void {
    this.mode = null;
  }

  changeMode(event: AutocompleteControlsTriggeredEvent): void {
    this.mode = event.triggerName;
  }

}

import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const PostboxAvailabilityValidator: ValidatorFn = (form: UntypedFormGroup) => {
  return (control: UntypedFormControl) => {
    if (!form) {
      return null;
    }
    const stepDeliveryForm = form.get('stepDeliveryForm');
    const cargoType = stepDeliveryForm.get('CargoType').value;
    const optionsSeat = stepDeliveryForm.get('OptionsSeat')?.value;
    const seatsAmount = stepDeliveryForm.get('SeatsAmount')?.value;
    const paramsCommonSeats = stepDeliveryForm.get('paramsCommonSeats')?.value;

    if (control.value?.CategoryOfWarehouse === 'Postomat') {
      if (cargoType === 'Pallet' || cargoType === 'TiresWheels') {
        return { postboxUnavailable: true };
      } else if (cargoType === 'Documents' && +seatsAmount > 1) {
        return { postboxUnavailable: true };
      } else if (cargoType === 'Cargo' && (optionsSeat?.length > 1 || paramsCommonSeats)) {
        return { postboxUnavailable: true };
      } else {
        return null;
      }
    }

    return null;
  };
};

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CreateTemplateDialogComponent} from '../../create-template-dialog/create-template-dialog.component';

@Component({
  selector: 'app-delivery-cost-check-popup',
  templateUrl: './delivery-cost-check-popup.component.html',
  styleUrls: ['./delivery-cost-check-popup.component.scss'],
})

export class DeliveryCostCheckPopupComponent {
  @Output()
  onAdd = new EventEmitter<void>();
  @Output()
  onClear = new EventEmitter<void>();
  @Input()
  cost;
  @Input()
  announcedCost;
  message;
  description;
  confirmCloseTooltip;

  constructor(
    private dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
  ) {
  }

  private _type;

  get type() {
    return this._type;
  }

  @Input() set type(value) {
    this._type = value;
    if (value === 'BackwardDeliveryData' || value === 'AdditionalServices') {
      this.message = 'Оголошена вартість буде дорівнювати сумі грошового переказу.';
      this.description = 'Оголошена вартість становитиме';
      this.confirmCloseTooltip = 'Сума грошового переказу буде дорівнювати оголошеній вартості.';
    } else if (value === 'securePayment') {
      this.message = 'Оголошена вартість буде дорівнювати сумі переказу Сейф-сервіс.';
      this.description = 'Оголошена вартість становитиме';
      this.confirmCloseTooltip = 'Сума переказу Сейф-сервіс буде дорівнювати оголошеній вартості.';
    } else {
      this.message = 'Оголошена вартість буде дорівнювати сумі контролю оплати.';
      this.description = 'Оголошена вартість становитиме';
      this.confirmCloseTooltip = 'Сума контролю оплати буде дорівнювати оголошеній вартості.';
    }
  };

  close(): void {
    this.onClear.next();
    this.dialogRef.close();
  }

  onButtonClick() {
    this.onAdd.next();
    this.dialogRef.close();
  }
}

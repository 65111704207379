import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppRoutes } from '@app';
import { AuthApi, AuthService } from '@services';
import * as moment from 'moment';
import { fromEvent, take } from 'rxjs';
import { OAuth2Service } from '../../../services/auth/oAuth2.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('frameElement') frameElement: ElementRef<HTMLIFrameElement>;

  showProfile = false;

  constructor(
    public cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private oAuth2Service: OAuth2Service,
    private http: HttpClient,
    private authApi: AuthApi,
    private authService: AuthService,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {
  }

  subscribeToPostMessage(): void {
    fromEvent(window, 'message')
      .pipe(take(1))
      .subscribe((event: MessageEvent) => {
        if (event.data === 'closeProfile') {
          this.authService.logout();
        }
      });
  }

  ngOnInit(): void {
    this.spinnerService.stop();
    this.subscribeToPostMessage();
  }

  ngAfterViewInit(): void {
    this.setCookie(
      document,
      'profile_access_np',
      this.oAuth2Service.getToken(),
      {
        domain: '.novaposhta.ua',
        path: '/',
        expires: moment().add(1, 'hour').toDate(),
      },
    );
    this.showProfile = true;
    this.cd.detectChanges();
  }

  setCookie(cookieRef, name, value, options: any = {}) {
    options = {
      path: '/',
      ...options,
    };
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    cookieRef.cookie = updatedCookie;
  }

  getIframeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://id.novaposhta.ua/profile#/?system=pwa&lang=uk&country=ua/`,
    );
  }

  toDashboard() {
    this.router.navigate([AppRoutes.dashboard]);
  }
}

export enum ShipmentFrameUrlParam {
  LOCALE = 'locale',
  TOKEN = 'token',
  SOURCE = 'source'
}

export const ClaimLocale = 'uk';

export const ClaimSource = 'external-bc-ua';

export type IframePostMessage = {
  type: string;
  name: string;
  callable: boolean;
  id: string;
  params: {
    [key: string]: string;
  };
};
export const ShipmentFrameCookiePrefix = 'Bearer';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export type InvoiceStatusTabColor =
  | 'red'
  | 'orange'
  | 'green'
  | 'blue'
  | 'dark-blue';

export type InvoiceStatusTabOperation = 'or' | 'and';

export interface InvoiceStatusTab {
  name: string;
  active: boolean;
  statusName: string;
  fn: (...args) => boolean;
  operation: InvoiceStatusTabOperation;
  color?: InvoiceStatusTabColor;
}

export interface InvoiceStatusTabFilter {
  fn: (...args) => boolean;
  op: InvoiceStatusTabOperation;
}

@Component({
  selector: 'app-status-tab',
  templateUrl: 'status-tab.component.html',
  styleUrls: ['./status-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusTabComponent<T extends InvoiceStatusTab> {
  @Input()
  tab: T = null;

  @Output()
  tabSelected = new EventEmitter<T>();

  selectTab(): void {
    this.tabSelected.emit(this.tab);
  }
}

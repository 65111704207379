import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, switchMap, take} from 'rxjs';
import {AuthService} from '../auth.service';
import {OAuth2Service} from '../auth/oAuth2.service';
import {ConfigService} from '../config.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    const oAuth2Service = this.injector.get(OAuth2Service);
    const configService = this.injector.get(ConfigService);
    if (oAuth2Service.getToken()?.length && req.url.indexOf(configService.get('apiUrl')) > -1) {
      if (oAuth2Service.isTokenRefreshProcessStart.getValue() === false) {
        return oAuth2Service.checkTokenValidityAndRefresh().pipe(
          switchMap(data => {
            oAuth2Service.isTokenRefreshProcessStart.next(false);
            req = req.clone({
              setHeaders: {
                TokenOAuth2: oAuth2Service.getToken(),
              },
            });
            return next.handle(req);
          })
        );
      } else {
        return oAuth2Service.isTokenRefreshProcessStart.pipe(
          filter(data => data === false),
          take(1),
          switchMap(data => {
            req = req.clone({
              setHeaders: {
                TokenOAuth2: oAuth2Service.getToken(),
              },
            });
            return next.handle(req);
          })
        );
      }
    }

    if (authService.user && authService.authToken) {
      req = req.clone({
        setHeaders: {
          token: authService.authToken,
          DeviceCode: authService.getAuthHash(authService.user.userLoginOldAuth || authService.user.fullNameCounterparty),
        },
      });
    }
    return next.handle(req);
  }

}

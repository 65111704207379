import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AbstractFormContainer } from '@shared';
import { TypeRadioButton } from '@type-radio-group';

@Component({
  selector: 'app-delivery-payer',
  templateUrl: './delivery-payer.component.html',
  styleUrls: ['./delivery-payer.component.scss'],
})
export class DeliveryPayerComponent extends AbstractFormContainer implements OnInit {
  @Input() controlName = 'PayerType';

  @Input()
  types: TypeRadioButton[] = [
    {
      value: 'Recipient',
      label: 'Отримувач',
    },
    {
      value: 'Sender',
      label: 'Відправник',
    },
    {
      value: 'ThirdPerson',
      label: 'Третя особа',
    },
  ];

  @Input()
  isCarCall = false;

  constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.isCarCall) {
      this.form.get('PayerType').valueChanges.subscribe((v) => {
        if (v === 'ThirdPerson') {
          this.form.get('PaymentMethod').setValue('NonCash');
          this.form.get('PaymentMethod').disable();
        } else {
          this.form.get('PaymentMethod').enable();
        }
      });
    }
  }

  addFormControls() {
    const deliveryTypeControl = new UntypedFormControl('Recipient');
    this.form.addControl(this.controlName, deliveryTypeControl);
  }
}

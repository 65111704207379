import { Directive, HostListener } from '@angular/core';
import { DefaultValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[npNumber]',
})
export class NumberDirective {
  prevSuccess: string;

  constructor(private valueAccessor: DefaultValueAccessor, private ngControl: NgControl) {}

  @HostListener('blur', ['$event.target'])
  blur(target) {
    if (target.value) {
      const number = parseFloat(target.value.replace(',', '.')).toString();
      if (number === 'NaN') {
        target.value = this.prevSuccess || '';
        this.fireChange(event.target);

        return true;
      }

      if (number !== target.value) {
        target.value = number;
        this.prevSuccess = target.value;
        this.fireChange(target);
      } else {
        this.prevSuccess = target.value;
      }
    }
  }

  fireChange(element) {
    if ('createEvent' in document) {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      element.dispatchEvent(evt);
      if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.setValue(element.value);
      }
      this.valueAccessor.onChange(element.value);
    } else {
      element.fireEvent('oninput');
    }
  }
}

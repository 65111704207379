import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import Rxmq, { Channel } from 'rxmq';
import { AnalyticEvent } from '../../../shared/interfaces/analytic-event';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';
import { OAuth2Service } from '../../../services/auth/oAuth2.service';
import { AuthService } from '@services';
import { take } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { MobileModalDialogComponent } from '../../../shared/components/mobile-modal-dialog/mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-auth-processing',
  templateUrl: './auth-processing.component.html',
  styleUrls: ['./auth-processing.component.scss'],
})
export class AuthProcessingComponent implements OnInit {
  code: string;

  dialogRef;

  constructor(
    private spinnerService: SpinnerService,
    private oAuth2Service: OAuth2Service,
    private authService: AuthService,
    protected storage: LocalStorageService,
    public route: ActivatedRoute,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private meta: Meta,
  ) {}

  ngOnInit() {
    this.checkAuth();
  }

  checkAuth() {
    if (this.route.snapshot.queryParams.code) {
      this.code = this.route.snapshot.queryParams.code;
      this.oAuth2Service.tryLogin().then((data) => {
        this.loginOAuth2();
      });
    } else if (this.oAuth2Service.getToken()) {
      this.loginOAuth2();
      this.spinnerService.stop();
    } else {
      this.spinnerService.stop();
      this.router.navigate(['home']);
    }
  }

  loginOAuth2() {
    this.authService.loginOAuth().subscribe((data) => {
      const user = data;
      (this.authService.user as any).loginEmail = user.loginEmail;
      this.router.navigateByUrl('dashboard/invoices-my');
      this.spinnerService.stop();
      this.screenWidthPopup();

      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`app-auth-processing.login.success`)
        .next({
          eventLabel: `Увійти, успіх`,
          eventCategory: 'app-auth-processing',
          eventAction: 'submit.success',
        });
    });
  }

  openModalDialog() {
    this.dialogRef = this.dialog.open(MobileModalDialogComponent);
    this.dialogRef.componentInstance.login = this.authService.user.loginEmail;
    this.storage.store(`loggedIn${this.authService.user.loginEmail}`, true);
  }

  closeModalDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  screenWidthPopup() {
    this.breakpointObserver
      .observe(['(max-width: 1024px)'])
      .pipe(take(1))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.openModalDialog();
        } else {
          this.closeModalDialog();
          this.meta.removeTag('name=viewport');
          this.storage.store(
            `loggedIn${this.authService.user.loginEmail}`,
            true,
          );
        }
      });
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contragent, Locality, Organization, User } from '@models';
import { CardTypes, parseNumbers } from '@shared';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { concatMap, first, map, Observable, tap } from 'rxjs';
import { Md5 } from 'ts-md5';
import { GetPromocodeParams, GetPromocodeResponseData } from '../shared/interfaces/api.interfaces';
import { ApiMethods } from './api/apiMethods';
import { IForgotPasswordForm } from './auth.service';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  constructor(
    protected http: HttpClient,
    protected npNoRestApiHelper: NoRestApiHelper,
    protected configStorage: ConfigService,
    protected localStorageService: LocalStorageService,
  ) {}

  login(login: string, password: string): Observable<User | Organization> {
    const loginObservable = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUser/getLoyaltyUserByLogin`,
      {
        modelName: 'LoyaltyUser',
        calledMethod: 'getUserByLogin',
        system: this.configStorage.get('system'),
        methodProperties: {
          Login: login,
          Password: this.getPasswordMd5(password),
        },
      },
      { observe: 'response' },
    );

    return loginObservable.pipe(
      map((response: HttpResponse<any>) => {
        const token = response.headers.get('token') || response.headers.get('Token');
        if (token) {
          const res = response.body;
          let data = {
            data: response.body,
            token,
            type: res.data[0] && res.data[0].CounterpartyType ? 'private' : 'org',
          };
          data = res.errors.length
            ? { ...data, data: this.npNoRestApiHelper.checkErrors(data) }
            : { ...data, data: this.npNoRestApiHelper.checkWarnings(data) };
          return this.bindUser(data.data, data.token, login, data.type);
        }
        return response.body.errors.length
          ? this.npNoRestApiHelper.checkErrors({ data: response.body })
          : this.npNoRestApiHelper.checkWarnings({ data: response.body });
      }),
      first(),
    );
  }

  loginWithToken(authToken: string): Observable<User | Organization> {
    const body = {
      modelName: 'Common',
      system: this.configStorage.get('system'),
      calledMethod: 'getApiKeyByNewCabinetToken',
      methodProperties: {
        Token: authToken,
      },
    };

    return this.http
      .post(`${this.configStorage.get('apiUrl')}json/`, body, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const token = response.headers.get('token') || response.headers.get('Token');
          const res = response.body;
          const user = res.data[0];
          return {
            data: response.body,
            token,
            type: res.data[0] && res.data[0].CounterpartyType ? 'private' : 'org',
            user,
          };
        }),
        map((res) => ({
          ...res,
          data: this.npNoRestApiHelper.checkErrors(res),
        })),
        map(({ data, token, type, user }) => this.bindUser(data, token, user.UserLogin, type)),
        tap((data) => this.saveLk2Flag(data.userLogin)),
        first(),
      );
  }

  saveLk2Flag(login: string): void {
    this.localStorageService.store(`fromLk2_${login}`, true);
  }

  removeLk2Flag(login: string): void {
    this.localStorageService.clear(`fromLk2_${login}`);
  }

  isFromLk2(login: string) {
    return !!this.localStorageService.retrieve(`fromLk2_${login}`);
  }

  registration(data: any): Observable<any> {
    const registerObservable = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUserGeneral/registration`,
      {
        modelName: 'LoyaltyUserGeneral',
        calledMethod: 'registration',
        system: this.configStorage.get('system'),
        methodProperties: {
          system: 'PA 2.0',
          City: data.city,
          FirstName: data.name,
          LastName: data.surname,
          Patronymic: data.patronymic,
          Phone: data.phone,
          Email: data.email,
          BirthDate: data.birthDate ? moment(data.birthDate).format('DD.MM.YYYY') : '31.01.1990',
          Password: this.getPasswordMd5(data.password),
          Gender: data.sex,
          CounterpartyType: 'PrivatePerson',
          MarketplacePartnerToken: '005056887b8d-b5da-11e6-9f54-cea38574', // ваш маркетплассе
        },
      },
    );
    return registerObservable.pipe(
      map((d) => this.npNoRestApiHelper.checkErrors({ d })),
      first(),
    );
  }

  confirmRegistration(code: number, phone: string): Observable<any> {
    phone = phone.replace('+', '');

    const confirmRegisterObservable: any = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUserGeneral/activationLoyalty`,
      {
        modelName: 'LoyaltyUserGeneral',
        calledMethod: 'activationLoyalty',
        system: this.configStorage.get('system'),
        methodProperties: {
          Code: code,
          Phone: `+${phone}`,
        },
      },
    );

    return confirmRegisterObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      first(),
    );
  }

  confirmLogin<T extends object>(code: string, login: string): Observable<User | Organization> {
    const confirmLoginObservable: any = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUserGeneral/activationLoyalty`,
      {
        modelName: 'LoyaltyUser',
        calledMethod: 'checkVerificationCodeForLoyaltyInfo',
        system: this.configStorage.get('system'),
        methodProperties: {
          VerificationCode: code,
          Login: login,
          AuthVerification: 'true',
        },
      },
      { observe: 'response' },
    );

    return confirmLoginObservable.pipe(
      // map(data => this.npNoRestApiHelper.checkErrors({ data })),
      map((response: HttpResponse<any>) => {
        const token = response.headers.get('token') || response.headers.get('Token');
        const res = response.body;
        return {
          data: response.body,
          token,
          type: res.data[0] && res.data[0].CounterpartyType ? 'private' : 'org',
        };
      }),
      map((res: T) => ({
        ...res,
        data: this.npNoRestApiHelper.checkErrors(res),
      })),
      map(({ data, token, type }) => this.bindUser(data, token, login, type)),
      first(),
    );
  }

  requestRecoveryPassword(form: IForgotPasswordForm): Observable<boolean> {
    const recoveryPasswordObservable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      modelName: 'LoyaltyUserGeneral',
      calledMethod: 'forgotPassword',
      system: this.configStorage.get('system'),
      methodProperties: {
        Email: form.email,
        Card: form.loyaltyCard,
        Password: this.getPasswordMd5(form.password),
        MarketplacePartnerToken: '005056887b8d-b5da-11e6-9f54-cea38574',
      },
    });

    return recoveryPasswordObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.success),
      first(),
    );
  }

  getAccountInfo(): Observable<User | Organization> {
    const getAccountInfoObservable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      system: this.configStorage.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'getLoyaltyInfoByApiKey',
      methodProperties: {},
    });

    return getAccountInfoObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => this.bindUser(data)),
      first(),
    );
  }

  changePassword(oldPassword: string, newPassword: string, email: string = null): Observable<boolean> {
    return this.login(email, oldPassword).pipe(
      concatMap((user: User) => {
        const observable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
          modelName: 'LoyaltyUserGeneral',
          calledMethod: 'forgotPassword',
          system: this.configStorage.get('system'),
          methodProperties: {
            Card: user.loyaltyCardNumber,
            Email: email,
            Password: this.getPasswordMd5(newPassword),
            MarketplacePartnerToken: '005056887b8d-b5da-11e6-9f54-cea38574',
          },
        });
        return observable.pipe(first());
      }),
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.success),
      first(),
    );
  }

  activate(code: string): Observable<boolean> {
    const changePasswordObservable = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUserGeneral/activationLoyalty`,
      {
        modelName: 'LoyaltyUser',
        calledMethod: 'activate',
        system: this.configStorage.get('system'),
        methodProperties: {
          Code: `${code}`,
          MarketplacePartnerToken: '005056887b8d-b5da-11e6-9f54-cea38574',
        },
      },
    );

    return changePasswordObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.success),
      first(),
    );
  }

  forgotLoaltyCardNumber(phone: string): Observable<boolean> {
    const changePasswordObservable = this.http.post(
      `${this.configStorage.get('apiUrl')}json/LoyaltyUserGeneral/activationLoyalty`,
      {
        modelName: 'LoyaltyUserGeneral',
        system: this.configStorage.get('system'),
        calledMethod: 'forgotCard',
        methodProperties: { Phone: parseNumbers(phone) },
      },
    );

    return changePasswordObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.success),
      first(),
    );
  }

  sendEmailWithCode(login: string) {
    const sendEmailWithCodeObservable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      modelName: 'LoyaltyUser',
      system: this.configStorage.get('system'),
      calledMethod: 'sendEmailVerification',
      methodProperties: { Login: login },
    });

    return sendEmailWithCodeObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.success),
      first(),
    );
  }

  getQuestions() {
    const getQuestionsObservable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      modelName: 'IdentificationGeneral',
      system: this.configStorage.get('system'),
      calledMethod: 'getQuestions',
      methodProperties: {},
    });

    return getQuestionsObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data.data),
      first(),
    );
  }

  sendSecretQuestionAnswer(methodProperties: {
    AnswerDescription: string;
    QuestionDescription: string;
    AnswerHelper: string;
    Language: string;
  }) {
    const sendSecretQuestionAnswerObservable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      modelName: 'IdentificationGeneral',
      system: this.configStorage.get('system'),
      calledMethod: 'sendAnswer',
      methodProperties,
    });

    return sendSecretQuestionAnswerObservable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((data) => data.data),
      first(),
    );
  }

  loginByToken(token) {
    const observable = this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
      modelName: 'Common',
      system: this.configStorage.get('system'),
      calledMethod: 'getApiKeyByNewCabinetToken',
      methodProperties: {
        Token: token,
      },
    });

    const observable2Funct = (apiToken) =>
      this.http.post(`${this.configStorage.get('apiUrl')}json/`, {
        modelName: 'LoyaltyUser',
        system: this.configStorage.get('system'),
        calledMethod: 'getLoyaltyInfoByApiKey',
        apiKey: apiToken,
        methodProperties: {},
      });

    let apiKey: string;
    let login: string;

    return observable.pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      concatMap((data) => {
        apiKey = data.data.data[0].ApiKey;
        login = data.data.data[0].Login;
        return observable2Funct(apiKey);
      }),
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map(this.bindUser.bind(this)),
      map((user: User) => {
        user.apiKey = apiKey;
        user.login = login;
        return user;
      }),
      first(),
    );
  }

  getAuthType(phone: string): Observable<{ UserExists: boolean; UseOAuth2: boolean }> {
    const body = {
      system: this.configStorage.get('system'),
      modelName: 'Identification',
      calledMethod: 'getAuthType',
      methodProperties: {
        Phone: phone,
      },
    };
    return this.http.post(`${this.configStorage.get('apiUrl')}json/`, body).pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
    );
  }

  getProfileCookies(): Observable<any> {
    const body = {
      system: this.configStorage.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'getProfileCookies',
      methodProperties: {},
    };
    return this.http.post(`${this.configStorage.get('apiUrl')}json/`, body).pipe(
      map((data) => this.npNoRestApiHelper.checkErrors({ data })),
      map((response) => response.data),
    );
  }

  getPasswordMd5(password: string) {
    return (Md5.hashStr(password) as string).split('').reverse().join('');
  }

  getPromocodeByPhone({ Phone, ShowAutoPromocodes }: GetPromocodeParams): Observable<GetPromocodeResponseData[]> {
    return this.http
      .post(`${this.configStorage.get('apiUrl')}json/`, {
        system: this.configStorage.get('system'),
        modelName: 'LoyaltyUser',
        calledMethod: ApiMethods.getPromocodeByPhone,
        methodProperties: { Phone, ShowAutoPromocodes },
      })
      .pipe(
        map((data) => this.npNoRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data),
      );
  }

  private bindUser(data, token?, login?, type = 'private'): User | Organization {
    if (type === 'private') {
      data = data.data.data[0];
      const user = new User(data.FirstName, data.LastName);
      user.firstName = data.FirstName;
      user.middleName = data.MiddleName;
      user.lastName = data.LastName;
      user.apiKey = data.ApiKey;
      user.authToken = token;
      user.loyaltyCardNumber = data.LoyaltyCard || data.LoyaltyCardNumber;
      user.discount = Number(data.Discount);
      user.city = new Locality(data.City);
      user.phone = data.Phone;
      user.email = data.Email;
      user.loginEmail = login || data.Email;
      user.login = login;
      user.Cid = data.Cid;
      user.userLogin = data.Cid || data.UserLogin;
      user.userLoginOldAuth = data.UserLogin;
      user.contragent = new Contragent(data.CounterpartyRef);
      user.loyaltyCardType = data.LoyaltyCardType;
      user.loyaltyCardSubtype = data.LoyaltyCardSubtype;
      user.fullNameCounterparty = data.FullNameCounterparty;
      user.UseOAuth2 = data.UseOAuth2;
      if (data.CheckQuestion !== undefined) {
        user.CheckQuestion = data.CheckQuestion;
      }
      user.type = type;
      user.contactSender = data.ContactSender;
      user.authHash = data.AuthHash;
      user.hasLoyaltyCard = true;
      user.CounterpartyCode = data.CounterpartyCode ?? '';
      if (data.BirthDate) {
        const datesArrays: string[] = data.BirthDate.split(' ')[0].split('.');

        user.birthDate = new Date();
        user.birthDate.setMonth(parseInt(datesArrays[1], 10) - 1);
        user.birthDate.setDate(parseInt(datesArrays[0], 10));
        user.birthDate.setFullYear(parseInt(datesArrays[2], 10));
        user.birthDate.setHours(0, 0, 0, 0);
      }
      user.BirthDateString = data.BirthDate;
      user.Features = data.Features;
      return user;
    } else {
      data = data.data.data;
      const org = new Organization(data.slice(1, data.length));
      org.authToken = token;
      org.loyaltyCardNumber = data[1].LoyaltyCardNumber || data[1].ApiKey;
      org.fullNameCounterparty = data[1].UserLogin;
      org.userLogin = data[1].UserLogin;
      org.description = data[1].Description;
      org.type = type;
      org.authHash = data[0].DeviceCode;
      org.hasLoyaltyCard = !!data[1].LoyaltyCardNumber;
      org.loyaltyCardType = CardTypes.CORPORATE;
      org.Features = data[0].Features;
      return org;
    }
  }
}

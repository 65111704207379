<div class="edit-field"
     *ngIf="edrpou">
  <mat-progress-bar *ngIf="!ThirdPersonDescription?.value" [style.opacity]="+pending"
                    mode="indeterminate"></mat-progress-bar>
  <span class="edit-field__btn">
        <button *ngIf="!ThirdPersonDescription?.value"
                type="button"
                class="added-button"
                mat-button (click)="toggleModal()">
                Додати платника
        </button>
    </span>
  <div class="wrapper"
       *ngIf="!ThirdPersonDescription?.value; else chip">
    <div class="edit-field__content edit-field__content_open">
      <button class="edit-field__close"
              mat-icon-button
              (click)="toggleModal()">
        <mat-icon class="material-icons_grey">close</mat-icon>
      </button>
      <div class="edit-field__title">Платник</div>
      <div>
        <mat-form-field class="form-input">
          <input matInput
                 placeholder="Код ЄДРПОУ/РНОКПП"
                 [formControl]="edrpou"
                 [mask]="'0000000000'"
                 [validation]="false">
          <mat-error>{{getError(edrpou, errors)}}</mat-error>
        </mat-form-field>
        <div class="edit-field__buttons">
          <button type="button"
                  mat-button (click)="toggleModal()">
            Скасувати
          </button>
          <button type="submit"
                  (click)="validateEdrpou(edrpou.value)"
                  mat-button color="primary"
                  [disabled]="edrpou?.invalid || pending">
            Додати
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #chip>
  <mat-chip-list *ngIf="ThirdPersonDescription?.value">
    <mat-chip class="edit-field__chip">
      {{ ThirdPersonDescription?.value }}
      <mat-icon class="mat-chip-remove" (click)="closeModal()">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</ng-template>

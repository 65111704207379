<ng-container [formGroup]="form">
  <ng-container *ngIf="!mode">
    <app-autocomplete-with-controls class="form-row"
                                    [formControl]="form.get(controlName)"
                                    [options]="options"
                                    [transformFn]="transformFn"
                                    [config]="config"
                                    [controlsConfig]="null"
                                    [preventInput]="true"
                                    (onValueSelected)="changeMode($event)">
      <ng-template appAutocompleteOption let-option>
        {{ option?.Description }} {{ option?.Phones | npFinePhone }}
      </ng-template>
      <app-autocomplete-error *ngIf="!!errorMessages[controlName]">
        {{ errorMessages[controlName] }}
      </app-autocomplete-error>
    </app-autocomplete-with-controls>
  </ng-container>
</ng-container>

<div class="container">
  <form class="edit-field__content edit-field__content_open question-container"
        [formGroup]="birthDateForm"
        (submit)="changeBirthDate()">
    <div class="edit-field__title">Нам важлива ваша безпека</div>
    <div class="edit-field__text">Додайте, будь ласка, дату народження, щоб ми змогли точніше ідентифікувати вас
    </div>
    <div class="form-row">
      <div class="form-col birth-datepicker" (click)="picker.open()">
        <mat-form-field class="mat-block">
          <input
            [max]="maxDate"
            matInput
            readonly="true"
            [matDatepicker]="picker"
            [value]="dateValue"
            (dateInput)="onDateChange($event.value)"
            placeholder="Дата">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="edit-field__buttons">
      <button type="button"
              (click)="closeDialog()"
              mat-button>
        Додати пізніше
      </button>
      <button type="submit"
              mat-button color="primary">
        Зберегти
      </button>
    </div>
  </form>
</div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin as observableForkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs';
import { ConfigService } from '../../../../services/config.service';
import { NoRestApiHelper } from '../../../../services/no-rest-api.helper';
import { parseNumbers } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    protected configService: ConfigService,
    protected noRestApiHelper: NoRestApiHelper,
  ) {}

  changeFullName(fullName: { lastName; firstName; middleName }, phone?) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'updateLoyaltyUser',
      methodProperties: {
        LastName: fullName.lastName,
        FirstName: fullName.firstName,
        MiddleName: fullName.middleName,
        Phone: phone,
      },
    };

    return this.http
      .post(
        `${this.configService.get(
          'apiUrl',
        )}json/LoyaltyUser/getLoyaltyUserByLogin`,
        postObject,
      )
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  changeBirthDate(date: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'updateLoyaltyUser',
      methodProperties: {
        BirthDate: date,
      },
    };

    return this.http
      .post(
        `${this.configService.get(
          'apiUrl',
        )}json/LoyaltyUser/getLoyaltyUserByLogin`,
        postObject,
      )
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  changePhone(phone: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'updateLoyaltyUser',
      methodProperties: {
        Phone: phone,
      },
    };

    return this.http
      .post(
        `${this.configService.get(
          'apiUrl',
        )}json/LoyaltyUser/getLoyaltyUserByLogin`,
        postObject,
      )
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  checkVerificationCode(code: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'LoyaltyUser',
      calledMethod: 'checkVerificationCodeForUpdateLoyaltyUser',
      methodProperties: {
        VerificationCode: code,
      },
    };

    return this.http
      .post(
        `${this.configService.get(
          'apiUrl',
        )}json/LoyaltyUser/getLoyaltyUserByLogin`,
        postObject,
      )
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }

  searchNames(
    query: string,
    type: 'firstName' | 'middleName',
    sex: 'Male' | 'Female' | 'All' = 'All',
    response: 'names' | 'objects' = 'names',
  ) {
    const methods = {
      firstName: 'getCatalogFirstNames',
      middleName: 'getCatalogMiddleNames',
    };
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: methods[type],
      methodProperties: {
        FindByString: query,
        Gender: sex,
      },
    };

    if (sex === 'All') {
      return observableForkJoin(
        ['Male', 'Female'].map((sexName) =>
          this.http
            .post(
              `${this.configService.get(
                'apiUrl',
              )}json/LoyaltyUser/getLoyaltyUserByLogin`,
              {
                system: this.configService.get('system'),
                modelName: 'Common',
                calledMethod: methods[type],
                methodProperties: {
                  FindByString: query,
                  Gender: sexName,
                },
              },
            )
            .pipe(
              map((data) => this.noRestApiHelper.checkErrors({ data })),
              map((data: any) => data.data.data),
              catchError((err: HttpErrorResponse) => of([])),
            ),
        ),
      ).pipe(
        map(([male, female]) => male.concat(female)),
        map((data) =>
          data
            // Backend has a bug where Male === All
            // Filter Unique
            .filter(
              (person, index, self) =>
                index ===
                self.findIndex((p) => p.Description === person.Description),
            )
            // Filter Start with query
            .filter((p) => p.Description.startsWith(query))
            // result array of names or objects
            .map((obj) => (response === 'names' ? obj.Description : obj)),
        ),
      );
    } else {
      return this.http
        .post(
          `${this.configService.get(
            'apiUrl',
          )}json/LoyaltyUser/getLoyaltyUserByLogin`,
          postObject,
        )
        .pipe(
          map((data) => this.noRestApiHelper.checkErrors({ data })),
          map((data: any) => data.data.data),
        )
        .pipe(
          map((data) =>
            data
              // Backend has a bug where Male === All
              // Filter Gender
              .filter(
                (p) =>
                  type !== 'firstName' || sex !== 'Male' || p.Gender === sex,
              )
              // Filter Start with query
              .filter((p) => p.Description.startsWith(query))
              // result array of names or objects
              .map((obj) => (response === 'names' ? obj.Description : obj)),
          ),
        );
    }
  }

  activateAccount(activationData) {
    const body = {
      system: this.configService.get('system'),
      modelName: 'ContractGeneral',
      calledMethod: 'activateContractByCode',
      methodProperties: {
        Code: activationData.activationCode,
        CustomerPhone: parseNumbers(activationData.phone),
        FirstName: activationData.name,
        MiddleName: activationData.middleName,
        LastName: activationData.surname,
        Email: activationData.email,
        Password: activationData.password,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(map((data) => this.noRestApiHelper.checkErrors({ data })));
  }
}

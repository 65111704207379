import { Injectable } from '@angular/core';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { authCodeFlowConfig } from './oAuth2-config';

@Injectable({
  providedIn: 'root',
})
export class OAuth2Service {
  isTokenRefreshProcessStart: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private oauthService: OAuthService) {
    oauthService.configure(authCodeFlowConfig);
    oauthService.setStorage(localStorage);
  }

  login(phone?: string, isRegister?: boolean) {
    const customQueryParams = {
      audience: 'https://id.novaposhta.ua https://api.novaposhta.ua',
      default_language: 'uk',
    };
    if (phone) {
      customQueryParams['login_hint'] = phone;
    }
    if (isRegister) {
      customQueryParams['is_register'] = true;
    }
    this.oauthService.customQueryParams = customQueryParams;
    this.oauthService.initImplicitFlow();
    return this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  getToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  getRefreshToken(): string {
    return this.oauthService.getRefreshToken();
  }

  getEvents() {
    return this.oauthService.events;
  }

  getClaims() {
    return this.oauthService.getIdentityClaims();
  }

  tryLogin() {
    return this.oauthService.tryLoginCodeFlow();
  }

  refreshToken() {
    return from(this.oauthService.refreshToken());
  }

  logout() {
    this.oauthService.revokeTokenAndLogout();
  }

  accessTokenExpirationTimeBoundary: number = 5 * 60 * 1000;

  // It checks only Access token
  checkTokenValidityAndRefresh() {
    if (
      this.oauthService.getAccessTokenExpiration() - this.accessTokenExpirationTimeBoundary <
      Date.now() &&
      !this.isTokenRefreshProcessStart.value
    ) {
      this.isTokenRefreshProcessStart.next(true);
      return this.refreshToken();
    } else {
      this.isTokenRefreshProcessStart.next(false);
      return of(null);
    }
  }

  idTokenExpirationTimeBoundary: number = 5 * 60 * 1000;

  // It checks only ID token
  checkTokenValidityIdTokenAndRefresh(): Observable<TokenResponse> {
    if (
      // We check that token will expire less than in 5 minutes from boundary Token expiration time
      this.oauthService.getIdTokenExpiration() - this.idTokenExpirationTimeBoundary <
      Date.now() &&
      !this.isTokenRefreshProcessStart.value
    ) {
      this.isTokenRefreshProcessStart.next(true);
      return this.refreshToken();
    } else {
      this.isTokenRefreshProcessStart.next(false);
      return of(null);
    }
  }
}

import {Invoice} from '../../models/invoice/invoice.model';
import {TableDataSource} from './table-data-source';
import {InvoiceService} from '../../services/invoices/invoice.service';
import {HeaderSearchService} from '../../services/header-search.service';

export abstract class InvoicesDataSource extends TableDataSource<Invoice> {
  protected constructor(protected invoiceService: InvoiceService,
                        protected headerSearchService: HeaderSearchService) {
    super();
  }

  // connect(collectionViewer: CollectionViewer): Observable<Array<Invoice>> {
  //
  //   const headerSearch$ = this.headerSearchService.search$
  //     .pipe(startWith(this.headerSearchService.getSearchString() || ''));
  //
  //   return combineLatest(this.dataSource$, headerSearch$)
  //     .pipe(
  //       map(([data, filter]) => {
  //         if (!!filter) {
  //           return data.filter((i: Invoice) => i.docNumber.includes(filter));
  //         }
  //         return data;
  //       })
  //     );
  // }
}

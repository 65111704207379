<form [formGroup]="form" class="np-redirect-invoice-form">
  <div class="modal modal-redirect modal_large">
    <span
      (click)="$event.stopPropagation(); dialogRef.close()"
      class="modal__close"
    >
      <i class="material-icons">close</i>
    </span>
    <div class="modal-header">
      <div class="form-row">
        <div class="form-col">
          <h3 class="modal-header__title np-invoices-show__success">
            {{ formLabel }}
          </h3>
        </div>
      </div>
    </div>
    <mat-progress-bar [style.opacity]="+isPending || +isCheckPending" mode="indeterminate"></mat-progress-bar>
    <main #modalContent class="modal-content">
      <div class="np-invoices-show__success">
        <div class="form-row">
          <div class="form-col">
            <div class="banner">
              <div class="banner__info">
                <div class="warning-message">
                  <div class="warning-message__text">
                    Послуга передбачає зміну адреси доставки вже оформленого
                    відправлення. Вартість послуги розраховується згідно з
                    <a
                      class="link-red"
                      href="https://novaposhta.ua/pereadresatsiya"
                      target="_blank"
                    >чинними тарифами</a>
                    компанії «Нова Пошта»
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <section class="form-col form-col-8">
            <stf-accordeon>
              <stf-accordeon-item
                (openChange)="onOpenCloseInvoiceInfo($event)"
                class="np-header-invoice-redirect-info"
                [showExpandable]="isCreateMode"
              >
                <div header>
                  <app-invoice-short-info
                    [invoice]="inputInvoice"
                    [isIn]="type === 'in'"
                    [isOut]="type === 'out'"
                  ></app-invoice-short-info>
                </div>
                <div body>
                  <app-invoice-detailed-info
                    [invoice]="inputInvoice"
                  ></app-invoice-detailed-info>
                </div>
              </stf-accordeon-item>
            </stf-accordeon>
          </section>
        </div>
        <div class="form-row">
          <div class="form-col">
            <h4 class="modal-content__title">Куди переадресувати</h4>
          </div>
        </div>
        <div class="mr-bottom-16">
          <div>
            <np-change-ew-contact-input
              *ngIf="!privatePerson && organizationMode === 'select'"
              [placeholder]="'Організація'"
              [showControls]="false"
              [value]="recipientCounterparty && recipientCounterparty.value"
            ></np-change-ew-contact-input>

            <div *ngIf="organizationMode === 'create'" class="form-row">
              <div class="form-col form-col-7">
                <mat-radio-group
                  [formControl]="recipientType"
                  class="radio-row"
                >
                  <mat-radio-button [value]="'PrivatePerson'">Приватна особа</mat-radio-button>
                  <mat-radio-button [value]="'Organization'">Організація</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <np-inv-contact-organization
              *ngIf="!privatePerson && organizationMode === 'create'"
              [disableForm]="allowRecipientNameChanges"
              [form]="form"
              [recipientEDRPOU]="recipientEDRPOU"
            >
            </np-inv-contact-organization>

            <app-private-person-form
              (onCancel)="
                changeMode('recipientMode', $event);
                changeMode('organizationMode', $event)
              "
              *ngIf="recipientMode === 'create'"
              [IsEnabledCancel]="true"
              [clearOnPhoneChange]="false"
              [controlName]="'recipientData'"
              [disablePhoneInput]="allowRecipientNameChanges"
              [form]="form"
              [isMiddleNameRequired]="false"
              [patchRecipientControlName]="'recipient'"
              [patchRecipientData]="'RecipientName'"
            ></app-private-person-form>

            <np-change-ew-contact-input
              (onCreate)="
                changeMode('recipientMode', $event);
                changeMode('organizationMode', $event)
              "
              *ngIf="recipientMode === 'select'"
              [disableCreation]="
                !(customerIsSender || allowRecipientNameChanges)
              "
              [placeholder]="'Отримувач'"
              [value]="joinDescription(this.form.get('recipientData').value)"
            ></np-change-ew-contact-input>
          </div>
        </div>
        <div class="form-row mr-bottom-16">
          <div class="form-col form-col-3">
            <div class="info-cell__label">Платник за доставку</div>
            <mat-radio-group formControlName="payer">
              <mat-radio-button [value]="'Sender'">Відправник</mat-radio-button>
              <mat-radio-button [value]="'Recipient'">Отримувач</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="form-col form-col-3">
            <div class="info-cell__label">Спосіб оплати</div>
            <mat-radio-group formControlName="PaymentMethod">
              <mat-radio-button [value]="'Cash'">Готівка</mat-radio-button>
              <mat-radio-button [value]="'NonCash'">Безготівка</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <ng-container *ngIf="isCreateMode || isAddressEditing; else addressLabel">
          <div class="form-row">
            <div class="form-col cancel-wrapper">
              <mat-radio-group
                class="radio-row radio-row-with-flex radio-row_mr-top"
                formControlName="addressType"
              >
                <mat-radio-button [value]="AddressTypes.WAREHOUSE">Відділення</mat-radio-button>
                <mat-radio-button [value]="AddressTypes.DOORS">Адреса</mat-radio-button>
                <mat-radio-button [value]="AddressTypes.POSTBOX">Поштомат</mat-radio-button>
                <mat-radio-button [value]="AddressTypes.INDEX">Цифрова адреса</mat-radio-button>
                <mat-radio-button
                  *ngIf="!customerIsSender && !recommendationWarehousesHidden"
                  [value]="AddressTypes.RECOMMENDATION_WAREHOUSES"
                  [disabled]="recommendationWarehousesDisabled"
                  [matTooltip]="
                  recommendationWarehousesDisabled
                    ? 'Вибачте, переадресування день в день неможливе. Оберіть відділення із загального списку'
                    : ''
                "
                >Переадресація день в день
                </mat-radio-button>
              </mat-radio-group>
              <button (click)="toggleAddressEditing()" mat-icon-button *ngIf="isAddressEditing" class="cancel-btn">
                <mat-icon class="material-icons_grey ">
                  cancel
                </mat-icon>
              </button>
            </div>
          </div>
          <div [ngSwitch]="addressTypeValue">
            <div *ngSwitchCase="AddressTypes.WAREHOUSE"
                 class="form-row mr-bottom-16 position-relative">
              <app-warehouse [cityClasses]="'form-col form-col-2'"
                             [warehouseClasses]="'form-col form-col-6'"
                             [form]="recipientAddress"
                             [controlName]="'data'"
                             [controlTypeName]="'type'"
                             class="shrink"></app-warehouse>
            </div>

            <div *ngSwitchCase="AddressTypes.DOORS" class="form-row position-relative">
              <app-address [form]="recipientAddress"
                           [controlName]="ControlModes.DATA"
                           [controlTypeName]="ControlModes.TYPE"
                           [showFlat]="showFlat">
              </app-address>
            </div>

            <div *ngSwitchCase="AddressTypes.POSTBOX"
                 class="form-row mr-bottom-16 position-relative">
              <app-postbox
                [form]="recipientAddress"
                [controlName]="ControlModes.DATA"
                [controlTypeName]="ControlModes.TYPE">
              </app-postbox>
            </div>

            <div *ngSwitchCase="AddressTypes.INDEX"
                 class="form-row mr-bottom-16">
              <app-address-by-index
                [controlName]="'data'"
                [form]="recipientAddress">
              </app-address-by-index>
            </div>

            <div *ngSwitchCase="AddressTypes.RECOMMENDATION_WAREHOUSES"
                 class="form-row mr-bottom-16 position-relative">
              <np-inv-recommendation-warehouses
                *ngIf="formGroup"
                [controlName]="'data'"
                [controlTypeName]="'type'"
                [form]="recipientAddress"
                [recipientCity]="recipientCity"
                [warehouseOptions]="recommendationWarehouses">
              </np-inv-recommendation-warehouses>
            </div>

            <div class="form-row position-relative">
              <div class="form-col form-col-8">
                <mat-form-field class="mat-block" hidden>
                  <input
                    (input)="onInputReason($event)"
                    formControlName="description"
                    matInput
                    placeholder="Причина"
                  />
                  <mat-error>{{ errorsMessages.description }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #addressLabel>
          <div class="form-row">
            <div class="form-col address-cell">
              <div class="info-cell__label">Адреса</div>
              <div class="info-cell__content with-button">
                <div class="with-button-text">{{ recipientAddressLabel || '&mdash;' }}</div>

                <div class="edit-btn-wrapper">
                  <button (click)="toggleAddressEditing()" class="edit-btn" mat-icon-button>
                    <mat-icon class="material-icons_red" [matTooltip]="'Змінити'" matTooltipPosition="below">
                      add_circle_outline
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="canCreate" class="form-row mr-bottom-16">
          <div class="form-col form-col-4">
            <div class="info-cell__label">Вартість переадресування</div>
            <div class="info-cell__content-calculated">{{ Pricing >= 0 ? Pricing + " грн" : "—" }}</div>
          </div>
          <div class="form-col form-col-4">
            <div class="info-cell__label">Орієнтовна дата доставки</div>
            <div
              class="info-cell__content-calculated">{{ (ScheduledDeliveryDate | date:"dd.MM.yyyy HH:mm") || "—" }}
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal-footer modal-footer_pad-8">
      <div class="modal-footer-buttons">
        <div class="modal-footer-buttons">
          <button
            *ngIf="!isCreateMode"
            (click)="cancel($event)"
            [disabled]="isPending || cancelPending"
            class="mat-button modal-footer__button np-submit-redirect-invoice"
            mat-button
          >
            Відмінити переадресацію
          </button>

          <span
            [matTooltip]="submitButtonTooltip"
          >
            <button
              (click)="canCreate ? submit($event) : calculate($event)"
              [disabled]="submitDisabled"
              class="mat-button modal-footer__button np-submit-redirect-invoice mat-primary"
              color="primary"
              mat-button
            >
              {{ canCreate ? (isCreateMode ? 'Створити заявку' : 'Оновити заявку') : 'Розрахувати' }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class CacheService {

  constructor(protected storage: LocalStorageService) {
  }

  set(key: string, value: any, time = 3600 * 1000) {
    this.storage.store(key, {value, time: time + Date.now()});
  }

  get(key: string) {
    const value = this.storage.retrieve(key);

    if (value) {
      if (value.time > Date.now()) {
        return value.value;
      }
    }

    return undefined;
  }

  clear(key: string) {
    this.storage.clear(key);
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AbstractFormContainer, getError } from '@shared';
import { errors, label, maxLength } from './delivery-packing-number.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-packing-number',
  templateUrl: './delivery-packing-number.component.html',
  styleUrls: ['./delivery-packing-number.component.scss'],
})
export class DeliveryPackingNumberComponent
  extends AbstractFormContainer
  implements OnInit
{
  getError = getError;

  errors = errors;

  label = label;

  @Input() controlName = 'PackingNumber';

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls() {
    this.form.addControl(
      this.controlName,
      new UntypedFormControl(
        '',
        Validators.compose([Validators.maxLength(maxLength)]),
      ),
    );
  }
}

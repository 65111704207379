import { Validators } from '@angular/forms';
import { AutocompleteControls, AutocompleteWithControlsConfig } from '@autocomplete-with-controls';
import { AddressType, TypedFormGroup } from '@models';
import { ErrorTypeMessages } from '@services';
import { GetCountriesData, nonZero } from '@shared';
import { TypeRadioButton } from '@type-radio-group';
import { MyErrorStateMatcher } from '../../../../shared/components/delivery-description/delivery-description.component';

export const SenderAddressConfig: AutocompleteWithControlsConfig = {
  ariaLabel: 'Адреса відправки',
  placeholder: 'Адреса відправки',
  arrow: true,
};

export const SenderRegistrationAddressConfig: AutocompleteWithControlsConfig = {
  ariaLabel: 'Адреса проживання',
  placeholder: 'Адреса проживання',
  arrow: true,
};

export const CreateAddressConfig: AutocompleteControls = {
  create: {
    enabled: true,
    tooltip: 'Створити нову адресу',
  },
};

export const SenderPlaceTypes: Array<TypeRadioButton<AddressType>> = [
  {
    value: 'Warehouse',
    label: 'Відділення',
  },
  {
    value: 'Doors',
    label: 'Адреса',
  },
];

export const SenderControlTypes: { [key: string]: keyof TypedFormGroup } = {
  MODE: 'mode',
  TYPE: 'type',
  DATA: 'data',
};

export const SenderWarehouseErrors = {
  required: "Це поле обов'язкове",
  address: 'Виберіть із запропонованих варіантів',
  weight: 'Вказане відділення не може прийняти таке відправлення, оберіть інше відділення',
};

export const ExportTargetErrors: ErrorTypeMessages = {
  target: [{ key: 'required', message: "Це поле обов'язкове" }],
  additionalInfo: [{ key: 'required', message: "Це поле обов'язкове" }],
};

export const ExportTargetConfig: AutocompleteWithControlsConfig = {
  ariaLabel: 'Ціль експорту',
  placeholder: 'Ціль експорту',
  arrow: true,
  errorStateMatcher: new MyErrorStateMatcher(),
};

export const CargoPlacesErrors = {
  weight: {
    required: 'Це поле обов\'язкове',
    min: minValue => `Не може бути менше ${minValue} кг`,
    max: maxValue => `Не може бути більше ${maxValue} кг`,
    nonZero: 'Недопустиме значення',
  },
  volumetricLength: {
    required: 'Це поле обов\'язкове',
    min: minValue => `Не може бути менше ${minValue} см`,
    max: maxValue => `Не може бути більше ${maxValue} см`,
    nonZero: 'Недопустиме значення',
  },
  volumetricWidth: {
    required: 'Це поле обов\'язкове',
    min: minValue => `Не може бути менше ${minValue} см`,
    max: maxValue => `Не може бути більше ${maxValue} см`,
    nonZero: 'Недопустиме значення',
  },
  volumetricHeight: {
    required: 'Це поле обов\'язкове',
    min: minValue => `Не може бути менше ${minValue} см`,
    max: maxValue => `Не може бути більше ${maxValue} см`,
    nonZero: 'Недопустиме значення',
  },
};

export const CargoPlacesFormTemplate = {
  weight: ['', [Validators.required, nonZero]],
  volumetricLength: [
    '',
    {
      validators: [Validators.required, nonZero, Validators.min(1)],
      updateOn: 'blur',
    },
  ],
  volumetricWidth: [
    '',
    {
      validators: [Validators.required, nonZero, Validators.min(1)],
      updateOn: 'blur',
    },
  ],
  volumetricHeight: [
    '',
    {
      validators: [Validators.required, nonZero, Validators.min(1)],
      updateOn: 'blur',
    },
  ],
  volumetricVolume: ['', [Validators.required]],
};

export const RecipientPayerTooltipText =
  'Оплата одержувачем доступна лише за умови підписання договору. ' +
  'За більш детальною інформацією, зверніться до особистого менеджера або до контакт-центру.';

export const DeliveryPayersExcludedCountries = ['Республіка Молдова'];

export const PaymentTypeTooltipText =
  'Безготівковий розрахунок доступний лише за умови підписання договору. ' +
  'За більш детальною інформацією, зверніться до особистого менеджера або до контакт-центру.';

export const PaymentTypeExcludedCountries = ['Республіка Молдова', 'Грузія'];

export const DeliveryTypesConfigWarehouse: AutocompleteWithControlsConfig = {
  ariaLabel: 'Адреса відділення отримувача',
  placeholder: 'Адреса відділення отримувача',
  arrow: false,
};

export const PRIVATE_PERSON_LINK = 'https://novaposhta.ua/international_delivery/uslugi_md';
export const CORPORATE_LINK = 'https://novaposhta.ua/business_delivery_countries/';

export const DeliveryTypes = [
  {
    type: 'Express',
    description: 'це термінова доставка документів, вантажів та посилок у більшість країн світу та в Україну.',
    link: PRIVATE_PERSON_LINK,
    corporateLink: CORPORATE_LINK,
  },
  {
    type: 'Standard',
    description: 'це стандарт',
    link: PRIVATE_PERSON_LINK,
    corporateLink: CORPORATE_LINK,
  },
  {
    type: 'Classic',
    description: 'це швидка та економна доставка посилок вагою до 30 кг у Європу чи з Європи.',
    link: PRIVATE_PERSON_LINK,
    corporateLink: CORPORATE_LINK,
  },
  {
    type: 'Shop to Shop',
    description: 'це доставка до відділень нашого партнера DPD.',
    link: PRIVATE_PERSON_LINK,
  },
  {
    type: 'Max',
    description:
      'це доставка в Європу чи з Європи вантажів, ' +
      'розміщених на піддонах вагою до 1000 кг та висотою до 200 см на одне вантажне місце.',
    link: PRIVATE_PERSON_LINK,
  },
  {
    type: 'Глобальна доставка',
    description: 'Послуга для доставки відправлень у більшість країн світу',
    link: PRIVATE_PERSON_LINK,
    corporateLink: CORPORATE_LINK,
  },
  {
    type: 'Глобальна доставка преміум',
    description: 'Послуга для доставки відправлень у більшість країн світу',
    link: PRIVATE_PERSON_LINK,
    corporateLink: CORPORATE_LINK,
  },
  {
    type: 'Доставка палет',
    description: 'Обов\'язкові умови вибору ',
    linkText: 'тарифу',
    link: 'https://static.novaposhta.ua/sitecard/misc/doc/Terms_Pallet_Delivery.pdf',
    corporateLink: 'https://static.novaposhta.ua/sitecard/misc/doc/Terms_Pallet_Delivery.pdf',
  },
];

export const RecipientPhoneValidators = [Validators.required, Validators.minLength(6), Validators.maxLength(16)];
export const RecipientPhoneValidatorsPoland = [Validators.required, Validators.minLength(16), Validators.maxLength(16)];

export const RecipientPhoneErrors = {
  required: "Це поле обов'язкове",
  minlength: 'Телефон отримувача повинен містити від 5 до 15 цифр',
  maxlength: 'Телефон отримувача повинен містити від 5 до 15 цифр',
};

export const RecipientPhoneErrorsPoland = {
  required: "Це поле обов'язкове",
  minlength: 'Телефон отримувача повинен містити 11 цифр',
  maxlength: 'Телефон отримувача повинен містити 11 цифр',
};
export const IntPhoneMask = [
  '+',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const PolandPhoneMask = [
  '+',
  '4',
  '8',
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

export const DefaultCountryPhoneConfig: Partial<GetCountriesData> = {
  PhonePrefix: '+',
  PhoneMask: IntPhoneMask,
  PhoneValidators: RecipientPhoneValidators,
  PhoneErrors: RecipientPhoneErrors,
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-filter-menu-header',
  templateUrl: './table-filter-menu-header.component.html',
  styleUrls: ['./table-filter-menu-header.component.scss'],
})
export class TableFilterMenuHeaderComponent {
  @Input() title: string;
}

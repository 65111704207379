<ng-container [formGroup]="form">
  <ng-container [formGroupName]="controlName">
    <ng-container *ngIf="!mode || mode === 'select'; else editModeTemplate">
      <app-autocomplete-with-controls data-test-id="RecipientAddressSelect"
                                      class="form-row"
                                      [formControl]="formGroup.get('data')"
                                      [options]="options"
                                      [transformFn]="transformFn"
                                      [config]="config"
                                      [controlsConfig]="controlsConfig"
                                      (onControlTriggered)="changeMode($event)">
        <ng-template appAutocompleteOption let-option>
          {{ (option && option.CityDescription && option.AddressDescription ? createWarehouseDescription(option) : createDoorsDescription(option)) || '' }}
        </ng-template>
        <app-autocomplete-error>
          {{ getError(data, RecipientAddressErrors) }}
        </app-autocomplete-error>
      </app-autocomplete-with-controls>
    </ng-container>
    <ng-template #editModeTemplate>
      <np-inv-edit-container data-test-id="RecipientAddressCreate"
                             [controlName]="controlName"
                             [formTypeName]="controlTypeName"
                             [isCancelable]="!!options.length"
                             (onCancel)="cancel()"
                             (onClear)="clear()">
        <np-inv-type-radio-group data-test-id="RecipientAddressType"
                                 [formControlName]="CONTROL_NAMES.TYPE"
                                 [types]="types"
                                 (onTypeChanged)="onTypeChanged($event)"
                                 [modifyClass]="'radio-row_mr-top'">
        </np-inv-type-radio-group>

        <ng-container [ngSwitch]="activeType">
          <div class="wrapp-form">
            <np-inv-address-warehouse *ngSwitchCase="'Warehouse'"
                                      [templateValidation]="templateValidation"
                                      [form]="control"
                                      [controlName]="CONTROL_NAMES.DATA"
                                      [controlTypeName]="CONTROL_NAMES.TYPE"
                                      [focused]="focused"
                                      [invoiceForm]="invoiceForm"
                                      [latestCity]="latestCity"
                                      (onCitySelected)="setLatestCity($event)">
            </np-inv-address-warehouse>
            <np-inv-address-doors *ngSwitchCase="'Doors'"
                                  [templateValidation]="templateValidation"
                                  [form]="control"
                                  [controlName]="CONTROL_NAMES.DATA"
                                  [latestCity]="latestCity"
                                  (onCitySelected)="setLatestCity($event)"
                                  [controlTypeName]="CONTROL_NAMES.TYPE">
            </np-inv-address-doors>
            <app-address-recipient-note *ngSwitchCase="'Doors'"
                                        [form]="control"
                                        [controlName]="'Note'">
            </app-address-recipient-note>

            <np-inv-address-postbox *ngSwitchCase="'Postbox'"
                                    [templateValidation]="templateValidation"
                                    [form]="control"
                                    [invoiceForm]="invoiceForm"
                                    [controlName]="CONTROL_NAMES.DATA"
                                    [latestCity]="latestCity"
                                    (onCitySelected)="setLatestCity($event)"
                                    [controlTypeName]="CONTROL_NAMES.TYPE">
            </np-inv-address-postbox>

            <app-address-by-index *ngSwitchCase="AddressTypes.INDEX"
                                  [controlName]="CONTROL_NAMES.DATA"
                                  [templateValidation]="templateValidation"
                                  [form]="control">
            </app-address-by-index>
          </div>
        </ng-container>
      </np-inv-edit-container>
    </ng-template>
  </ng-container>
</ng-container>

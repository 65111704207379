<div [ngClass]="{'np-invoices-show_pending': pending}"
     class="modal modal_large contact-edit modal"
     data-test-id="ContactCreateModal">
    <span class="modal__close ngdialog-close">
        <mat-icon class="material-icons" matDialogClose>close</mat-icon>
    </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">Контакт</h3>
      </div>
    </div>
  </div>
  <form [formGroup]="contactForm"
        class="form-flex">
    <main class="modal-content">
      <mat-progress-bar [style.opacity]="pending ? 1 : 0"
                        class="progress-bar"
                        mode="indeterminate">
      </mat-progress-bar>
      <div class="form-row">
        <div class="form-col">
          <mat-radio-group class="radio-row"
                           formControlName="contragentType"
                           name="contragentType"
                           style="margin: 12px 0 12px 2px;">
            <mat-radio-button data-test-id="RadioPrivatePerson"
                              value="Priv">Приватна особа
            </mat-radio-button>
            <mat-radio-button data-test-id="RadioOrganization"
                              value="Org">Організація
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div *ngIf="contactValue.contragentType === 'Org'">
        <np-inv-contact-organization
          *ngIf="initialOrg !== undefined"
          [form]="contactForm"
          [initialOrg]="initialOrg">
        </np-inv-contact-organization>
      </div>
      <div>
        <div class="form-row">
          <div class="form-col form-col-2">
            <np-phone-input
              [formControl]="phone"
              [isValid]="phone.valid"
              autocomplete="off"
              data-test-id="InputPhone"
              placeholder="Телефон">
                  <span *ngIf="(phone.touched || phone.dirty)">
                    <i class="material-icons mat-select-error__icon">error_outline</i> {{getError(phone, phoneErrors)}}
                  </span>
            </np-phone-input>
          </div>
          <div class="form-col form-col-2">
            <mat-form-field class="form-field"
                            data-test-id="InputSurname">
              <input autoScrollTarget
                     formControlName="surname"
                     matInput
                     placeholder="Прізвище">
              <mat-error>{{getError(surname, surnameErrors)}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-col form-col-2">
            <mat-form-field class="form-field"
                            data-test-id="InputName">
              <input autoScrollTarget
                     formControlName="name"
                     matInput
                     placeholder="Ім'я">
              <mat-error>{{getError(name, nameErrors)}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-col form-col-2">
            <mat-form-field class="form-field"
                            data-test-id="InputMiddleName">
              <input autoScrollTarget
                     formControlName="middleName"
                     matInput
                     placeholder="По батьковi">
              <mat-error>{{getError(middleName, middleNameErrors)}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col form-col-2">
            <mat-form-field class="mat-block"
                            data-test-id="InputEmail">
              <input autoScrollTarget
                     formControlName="email"
                     matInput
                     placeholder="Email">
              <mat-error>{{getError(email, emailErrors)}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-col form-col-6">
            <mat-form-field class="mat-block"
                            data-test-id="InputAdditionalInfo">
              <input autoScrollTarget
                     formControlName="info"
                     matInput
                     placeholder="Додаткова інформація">
              <mat-error>{{getError(info, infoErrors)}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="contactId" class="form-row">
        <div class="form-col form-col-8">
          <div *ngIf="contactAddresses.length" class="modal-address">
            <div class="modal-address__title">Адреси доставки</div>
            <div *ngFor="let address of contactAddresses" class="modal-address__item">
              <div class="modal-address__text">
                {{address.description}}{{address.note ? ', ' + address.note : ''}}
              </div>
              <div class="control-icons">
                <!--                <div class="control-icons__item" [matTooltip]="'Редагувати'">-->
                <!--                  <button mat-button class="mat-icon-button" (click)="edit(address)">-->
                <!--                    <mat-icon class="material-icons">-->
                <!--                      edit-->
                <!--                    </mat-icon>-->
                <!--                  </button>-->
                <!--                </div>-->
                <div [matTooltip]="'Видалити'" class="control-icons__item">
                  <button (confirm)="removeAddress(address)"
                          [cancelBtnText]="'Скасувати'"
                          [confirmBtnText]="'Видалити'"
                          [confirmMsg]="'Ви справді хочете видалити цю адресу?'"
                          class="mat-icon-button"
                          data-test-id="AddressDelete"
                          mat-button
                          type="button">
                    <mat-icon class="material-icons">
                      delete
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-address-block *ngIf="newAddress"
                         [controlName]="addressControlName"
                         [form]="contactForm"
                         [options]="addressOptions"
                         [showFlat]="true"
                         data-test-id="ContactAddress">
      </app-address-block>
      <div *ngIf="newAddress" class="form-row form-row_button">
        <div class="form-col form-col-4">
          <button
            (click)="saveAddress()"
            *ngIf="contactId"
            color="primary"
            mat-button
            type="button"
          >Зберегти
          </button>
          <button (click)="cancelEditMode();"
                  mat-button type="button">скасувати
          </button>
        </div>
      </div>
      <div *ngIf="!newAddress" class="form-row form-row_button">
        <div class="form-col form-col-4">
          <button (click)="addNewAddress()" class="mat-button_with-icon"
                  data-test-id="AddressAdd" mat-button
                  type="button">
            <mat-icon>add_circle</mat-icon>
            Додати адресу
          </button>
        </div>
      </div>
    </main>
    <div class="modal-footer modal-footer_pad-8">
      <div class="modal-footer-buttons">
        <button (confirm)="remove()"
                *ngIf="!dontCloseAllPopup && contactId && contactId !== 'null'"
                [cancelBtnText]="'Скасувати'"
                [confirmBtnText]="'Видалити'"
                [confirmMsg]="'Ви справді хочете видалити цей контакт?'"
                [matTooltipPosition]="'above'"
                [matTooltip]="'Видалити'"
                aria-label="delete"
                class="mat-icon-button"
                data-test-id="ContactDelete" mat-button
                type="button">
          <mat-icon class="material-icons">delete</mat-icon>
        </button>
        <button (click)="save()"
                [disabled]="pending"
                color="primary"
                data-test-id="ContactSave"
                id="contact-modal-btn-save"
                mat-button
                type="button">
          Зберегти
        </button>
      </div>
    </div>
  </form>
</div>

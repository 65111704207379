import { Pipe, PipeTransform } from '@angular/core';
import {InvoiceService} from '../../services/invoices/invoice.service';

@Pipe({
  name: 'fineDocSearch',
})

export class FineDocSearchPipe implements PipeTransform {
  constructor(private invoiceService: InvoiceService) {

  }

  transform(value: any, args?: any): any {
    return this.invoiceService.makeFormatedInvoiceNumberSearch(value);
  }

}

<mat-progress-bar [style.opacity]="+pending" class="loader" mode="indeterminate"></mat-progress-bar>
<div class="modal-container position-relative p-6">
  <span (click)="close()" class="close-btn" matDialogClose>
      <mat-icon class="material-icons_grey material-icons">close</mat-icon>
    </span>

  <h4 class="modal-title">
    Заявку {{ Number ? Number + ' ' : '' }}на повернення {{ isCreated ? 'прийнято' : 'оновлено' }}
  </h4>

  <div class="modal-content">
    <div class="banner" *ngIf="showMessageText">
      <div class="banner__info">
        <div class="warning-message">
          <div class="warning-message__text" [innerHTML]="messageText"></div>
        </div>
      </div>
    </div>
    <div class="modal-content-text mr-bottom-16" *ngIf="!isCreated">
        <p class="secondary-color modal-text">Якщо ви замовили й уже оплатили послугу "Повернення", кошти будуть
          повернені найближчим часом.</p>
    </div>
    <div class="modal-content-text">
      <p class="secondary-color modal-text">Очікувана дата доставки:</p>
      <p class="regular-font modal-text">{{ (ScheduledDeliveryDate | date:"dd.MM.yyyy HH:mm") || "—" }}</p>
    </div>
    <div class="modal-content-text">
      <p class="secondary-color modal-text">Вартість повернення: </p>
      <p class="regular-font modal-text">{{ Pricing >= 0 ? Pricing + " грн" : "—" }}</p>
    </div>
  </div>

  <div class="d-flex justify-content-flex-end mt-4 footer">
    <div
      [matTooltip]="!paymentCostsInfo || (paymentCostsInfo && checkIfPaid(paymentCostsInfo)) || !paymentCostsInfo?.UseNovaPay ? 'Оплата недоступна' : null"
      matTooltipPosition="above">
      <button
        (click)="navigateToPaymentFrame()"
        [disabled]="checkPaymentDisabled()"
        class="mat-button mat-primary"
        color="primary"
        mat-button>
        Сплатити
      </button>
    </div>
  </div>
</div>



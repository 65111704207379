export enum ApiMethods {
  getWarehouses = 'getWarehouses',
  createOrder = 'createOrder',
  getPackListSpecial = 'getPackListSpecial',
  getOutgoingDocumentsByPhone = 'getOutgoingDocumentsByPhone',
  getPromocodeByPhone = 'getPromocodeByPhone',
  save = 'save',
  update = 'update',
}

export enum ApiModelName {
  InternetDocument = 'InternetDocument',
}

export const CityMinLength = 3;
export const CityMaxLength = 20;
export const CityDebounceTime = 300;
export const RequestLimit = 100;
export const CityControlName = 'city';
export const CityLabel = 'Населений пункт';
export const CityPlaceholder = '';
export const CityClasses = 'form-col w-30';
export const WarehouseOptionDisabledTooltip =
  'Немає відділень Nova Post. Оберіть інший населений пункт';
export const PostboxOptionDisabledTooltip =
  'Немає поштоматів. Оберіть інший населений пункт';
export const LatinLettersTooltip =
  'Для пошуку міста, почніть вводити назву латинськими літерами';

import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { switchMap } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';

const PRELOAD_DELAY = 5000;

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const delay = route?.data?.delay ?? PRELOAD_DELAY;
    return route?.data?.preload
      ? timer(delay).pipe(switchMap((_) => load()))
      : of(null);
  }
}

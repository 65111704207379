<ng-container [formGroup]="form">
  <mat-form-field [attr.data-test-id]="config.testId"
                  class="form-input">
    <mat-label>{{config.title}}</mat-label>
    <input [formControlName]="controlName"
           autocomplete="off"
           matInput
           InputTrim
           class="input"
           [placeholder]="config.placeholder"
           type="text">
    <button (click)="clearField(control, $event, '')"
            *ngIf="control?.value?.length"
            aria-label="Clear"
            class="p-0"
            mat-button
            mat-icon-button
            matSuffix>
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>{{getError(control, config.errors)}}</mat-error>
  </mat-form-field>
</ng-container>

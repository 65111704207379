import { checkObjectMask } from './validators.util';

export const citySearchResultKeys = [
  'Area',
  'DeliveryCity',
  'MainDescription',
  'ParentRegionCode',
  'ParentRegionTypes',
  'Present',
  'Ref',
  'Region',
  'RegionTypes',
  'RegionTypesCode',
  'SettlementTypeCode',
  'StreetsAvailability',
  'Warehouses',
];

export const cityResultMaskValidator = checkObjectMask(citySearchResultKeys);

export const TemplateCitySearchResultMaskValidator = checkObjectMask(
  citySearchResultKeys,
  true,
);

export const internationalCitySearchResultKeys = [
  'City',
  'CountryRef'
];

export const internationalCityResultMaskValidator = checkObjectMask(internationalCitySearchResultKeys);

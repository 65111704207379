import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IColConfig {
  name: string;
  class: string;
  show: boolean;
  disabled?: boolean;
}

export interface TableColFilterChange {
  cols: IColConfig[];
}

@Component({
  selector: 'stf-table-col-filter',
  templateUrl: './table-col-filter.component.html',
  styleUrls: ['./table-col-filter.component.scss'],
})
export class TableColFilterComponent implements OnInit {
  @Input() cols: IColConfig[] = [];
  @Output() colsChange = new EventEmitter<TableColFilterChange>();
  @Input() color: string;
  constructor() { }

  ngOnInit() {
  }

  onChange($event: Event, item: IColConfig) {
    $event.stopPropagation();
    item.show = item.show ? false : true;
    this.colsChange.emit({ cols: this.cols });
  }

  trackByFn(index: number, item: IColConfig) {
    return item.class;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services';
import * as moment from 'moment';
import { map, Observable, take } from 'rxjs';
import { NoRestApiHelper } from '../../../../../services/no-rest-api.helper';
import { PartialSuccessResponse } from '../../../../../shared/components/partial-success/partial-success.model';
import {
  CourierCall,
  CourierCallApiResponse,
  CourierCallCompact,
  CourierPickupBarcodeResponse,
  CourierPickupFilters,
  CourierPickupPayload,
} from '../../courier-pickup/courier-pickup.model';

@Injectable({
  providedIn: 'root',
})
export class CourierPickupApiService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {
  }

  getCourierPickupList(page = 1, pageSize = 5, filters?: CourierPickupFilters): Observable<CourierCallApiResponse> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'getOrdersListCourierCall',
      methodProperties: {
        Page: page,
        Limit: pageSize,
        ...(filters ? filters : {}),
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => this.mapCourierPickups(response.data)),
      take(1),
    );
  }

  getCourierPickupCompactList(invoiceId?: string): Observable<CourierCallCompact[]> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'getAvailableOrdersList',
      methodProperties: {
        Number: invoiceId,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data),
        take(1),
      );
  }

  cancelCourierPickup(courierPickupRef: string): Observable<CourierCallApiResponse> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'deleteCourierCall',
      methodProperties: {
        Ref: courierPickupRef,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data),
        take(1),
      );
  }

  private mapCourierPickups(apiResponse: CourierCallApiResponse): CourierCallApiResponse {
    return {
      ...apiResponse,
      data: apiResponse.data.map(el => this.mapCourierPickupCall(el)),
    };
  }

  private mapCourierPickupCall(data: CourierCall): CourierCall {
    return {
      ...data,
      SenderDescription: data.SenderDescription === '' ? 'Приватна особа' : data.SenderDescription,
      DateTime: moment(data.DateTime, 'DD.MM.YYYY HH:mm:ss').toDate(),
    };
  }

  savePickupCall(methodProperties: CourierPickupPayload): Observable<CourierPickupBarcodeResponse> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'saveCourierCall',
      methodProperties: {
        ...methodProperties,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
      take(1),
    );
  }

  updatePickupCall(ref: string, methodProperties: CourierPickupPayload): Observable<CourierPickupBarcodeResponse> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'updateCourierCall',
      methodProperties: {
        Ref: ref,
        ...methodProperties,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0]),
        take(1),
      );
  }

  getPickupCallByNumber(Number: string): Observable<CourierCallApiResponse> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'getOrdersListCourierCall',
      methodProperties: {
        Page: 1,
        Limit: 5,
        Number,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => this.mapCourierPickups(response.data)),
      take(1),
    );
  }

  insertDocuments(Number: string, LinkedDocuments: string[]): Observable<PartialSuccessResponse> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'insertDocuments',
      methodProperties: {
        Number,
        LinkedDocuments,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, body)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0]),
        take(1),
      );
  }

  removeDocuments(LinkedDocuments: string[]): Observable<PartialSuccessResponse> {
    const body = {
      system: this.configService.get('system'),
      modelName: 'CarCallGeneral',
      calledMethod: 'removeDocuments',
      methodProperties: {
        LinkedDocuments,
      },
    };

    return this.http.post(`${this.configService.get('apiUrl')}json/`, body).pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((response) => response.data.data[0]),
      take(1),
    );
  }
}

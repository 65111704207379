import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject ,  combineLatest ,  merge ,  Observable ,  Subject } from 'rxjs';
import { map, startWith } from 'rxjs';
import { CompareFunction } from '../interfaces/compare-function';

export abstract class TableDataSource<T> extends DataSource<T> {

  protected dataSource$: Observable<T[]>;

  protected source: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  protected modifiedSource: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  protected filterChanged: Subject<void>  = new Subject<void>();

  protected total: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected totalInCurrentSearch: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  protected loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _loading$ = this.loading.asObservable();

  private _totalInCurrentSearch$ = this.totalInCurrentSearch.asObservable();

  get loading$(): Observable<boolean> {
    return this._loading$;
  }

  get totalInCurrentSearch$(): Observable<number> {
    return this._totalInCurrentSearch$;
  }

  private _total$ = this.total.asObservable();

  get total$(): Observable<number> {
    return this._total$;
  }

  private _filter: (elem: T) => boolean = null;

  get filter(): (elem: T) => boolean {
    return this._filter;
  }

  set filter(value: (elem: T) => boolean) {
    this._filter = value;
    this.filterChanged.next();
  }

  protected constructor() {
    super();
    this.initSource();
  }

  initSource(): void {
    const data$ = this.source.asObservable();
    const modifiedData$ = this.modifiedSource.asObservable();
    const merged$ = merge(data$, modifiedData$);
    const filterChanged$ = this.filterChanged.asObservable().pipe(startWith(null));

    this.dataSource$ = combineLatest(merged$, filterChanged$)
      .pipe(map(([data, _]) => this.filter ? data.filter(this.filter) : data));
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSource$;
  }

  getSnapshot(): T[] {
    return this.source.getValue();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.source.complete();
    this.modifiedSource.complete();
    this.loading.complete();
    this.total.complete();
  }

  sort(compareFn: CompareFunction<T>, isAsc: boolean): void {
    this.loading.next(true);
    const data = this.source.getValue().slice();
    const result = this.sortData(data, isAsc, compareFn);
    this.modifiedSource.next(result);
    this.loading.next(false);
  }

  sortData<T>(data: T[], isAsc: boolean, compareFn: CompareFunction<T>): T[] {
    return data.sort((e1, e2) => compareFn(e1, e2, isAsc));
  }

  resetSort(): void {
    this.source.next(this.source.getValue());
  }

  setLoading(value: boolean): void {
    this.loading.next(value);
  }
}

import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  // issuer: 'http://hydra.sb.np.ua:9001/',
  issuer: 'https://auth.novaposhta.ua:9001/',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth-processing',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  // clientId: 'f5f8e8e5-2249-4efb-8b3f-36ae5b47a47c.bc.apps.novaposhta.ua',
  clientId: 'c536b3fded75739b00bd8e2903b51aaa.auth.apps.novaposhta.ua',

  timeoutFactor: 0.1,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'gvADgi3wKyBFDAvNwd2GRHDOdnpnneI0',
  dummyClientSecret: '745c52f6-ca73-11ea-97a1-0025b501a07b',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  // scope: 'offline force-consent',
  scope: 'openid offline force-consent',

  showDebugInformation: true,

  requireHttps: false,

  // tokenEndpoint: 'http://hydra.sb.np.ua:9001/oauth2/token',
  tokenEndpoint: 'https://auth.novaposhta.ua:9001/oauth2/token',

  // revocationEndpoint: 'http://hydra.sb.np.ua:9001/oauth2/revoke',
  revocationEndpoint: 'https://auth.novaposhta.ua:9001/oauth2/revoke',


  requestAccessToken: true,

  // Not recommented:
  // disablePKCI: true,
};

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Rxmq, { Channel } from 'rxmq';
import { Observable, of } from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { concatMap, finalize, map, switchMap } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactService } from '../../../services/contacts/contact.service';
import { AddressService, AlertToastService, AnalyticEvent } from '@services';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import {
  Address,
  AddressType,
  AutoCompleteOptionItem,
  Contact,
  ContactPerson,
  Contragent,
  IApiError,
  Locality,
  OrgType,
  Street,
} from '@models';
import {
  ContactInfoErrors,
  EmailErrors,
  MiddleNameErrors,
  NameErrors,
  PhoneErrors,
  SurnameErrors,
} from '../../errors/error-messages';
import { FormFieldValidationService } from '../../../services/form-field-validation.service';
import { TypeRadioButton } from '@type-radio-group';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { ToastrService } from 'ngx-toastr';
import { getError, mapNewAddressToAddressFormat } from '@shared';

const componentName = 'app-contact-edit';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss'],
})
export class ContactEditComponent implements OnInit {
  getError = getError;
  contact: Contact;
  errors: IApiError[] = [];
  @Input() contactId: string;
  pending: boolean;
  addAddress = false;
  dontCloseAllPopup: boolean;
  contactAddresses: Address[] = [];
  @Input() needGoToList: boolean;
  orgTypes: OrgType[] = [];
  readonly ownerType: 'Priv' | 'Org';
  readonly lastName: string;
  isEdrpouContragent = false;
  editAddressId: string;
  lastNameText = '';
  addressNew: any;
  contactForm: UntypedFormGroup;

  newAddress: Address;

  addressControlName = 'AddressSender';

  addressTypes: Array<TypeRadioButton<AddressType>> = [
    {
      value: 'Warehouse',
      label: 'Відділення',
      focused: true,
    },
    {
      value: 'Doors',
      label: 'Адреса',
    },
    {
      value: 'Postbox',
      label: 'Поштомат',
    },
  ];

  addressOptions: Array<AutoCompleteOptionItem<any>> = [];

  @Input()
  currentContact: ContactPerson;

  warehouse: Street;

  initialOrg: Contragent | null;

  nameErrors = NameErrors;
  surnameErrors = SurnameErrors;
  middleNameErrors = MiddleNameErrors;
  phoneErrors = PhoneErrors;
  emailErrors = EmailErrors;
  infoErrors = ContactInfoErrors;

  constructor(
    protected router: Router,
    protected contactService: ContactService,
    protected contactsService: ContactsService,
    protected alertToastService: AlertToastService,
    protected addressService: AddressService,
    protected invoiceService: InvoiceService,
    protected dialog: MatDialog,
    protected fb: UntypedFormBuilder,
    protected validationService: FormFieldValidationService,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    private toastr: ToastrService,
  ) {}

  get contactValue() {
    return this.contactForm.value;
  }

  get email(): AbstractControl {
    return this.contactForm.get('email');
  }

  get info(): AbstractControl {
    return this.contactForm.get('info');
  }

  get phone(): AbstractControl {
    return this.contactForm.get('phone');
  }

  get name() {
    return this.contactForm.get('name');
  }

  get surname() {
    return this.contactForm.get('surname');
  }

  get middleName() {
    return this.contactForm.get('middleName');
  }

  get contragentType(): 'Priv' | 'Org' {
    if (
      this.contact &&
      this.contact.contragent &&
      this.contact.contragent.ownerType
    ) {
      return this.contact.contragent.ownerType;
    } else if (this.ownerType) {
      return this.ownerType;
    }

    return 'Priv';
  }

  set contragentType(type: 'Priv' | 'Org') {
    if (this.contact) {
      this.contact.contragent.ownerType = type;
    }
  }

  initForm(): void {
    this.contactForm = this.fb.group({
      contragentType: ['Priv', Validators.required],
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          this.validationService.firstCharacterUpperValidator,
          this.validationService.hasDoubleApostropheValidator,
          this.validationService.hasDoubleDotsValidator,
          this.validationService.hasDoubleSpacesValidator,
          this.validationService.hasDoubleHyphenValidator,
          this.validationService.isValidCharactersValidator,
          this.validationService.isValidFirstCharValidator,
          this.validationService.isValidLastCharValidator,
        ],
      ],
      surname: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          this.validationService.firstCharacterUpperValidator,
          this.validationService.hasDoubleApostropheValidator,
          this.validationService.hasDoubleDotsValidator,
          this.validationService.hasDoubleSpacesValidator,
          this.validationService.hasDoubleHyphenValidator,
          this.validationService.isValidCharactersValidator,
          this.validationService.isValidFirstCharValidator,
          this.validationService.isValidLastCharValidator,
        ],
      ],
      middleName: [
        '',
        [
          Validators.minLength(2),
          Validators.maxLength(25),
          this.validationService.firstCharacterUpperValidator,
          this.validationService.hasDoubleApostropheValidator,
          this.validationService.hasDoubleDotsValidator,
          this.validationService.hasDoubleSpacesValidator,
          this.validationService.hasDoubleHyphenValidator,
          this.validationService.isValidCharactersValidator,
          this.validationService.isValidFirstCharValidator,
          this.validationService.isValidLastCharValidator,
        ],
      ],
      phone: ['', [Validators.required, Validators.minLength(12)]],
      email: [
        '',
        [
          Validators.maxLength(100),
          this.validationService.hasDoubleDotsValidator,
          this.validationService.isValidEmailValidator,
        ],
      ],
      info: [
        '',
        [
          Validators.pattern(/^[а-яА-Я0-9ёЁіІїЇєЄґҐюЮ\',.()-\\d\\ ]*$/),
          Validators.maxLength(100),
        ],
      ],
    });
  }

  trackById(id, item: any) {
    return item.id;
  }

  ngOnInit(): void {
    if (this.contactId) {
      this.pending = true;
      this.contactsService
        .getByRef(this.contactId)
        .pipe(
          switchMap((contact) => {
            this.contactForm.patchValue({
              email: contact.Email || '',
              info: contact.Info || '',
              name: contact.FirstName || '',
              surname: contact.LastName || '',
              middleName: contact.MiddleName || '',
              phone: contact.Phones || '',
            });
            return this.contactsService.getContragent(contact.CounterpartyRef);
          }),
          finalize(() => (this.pending = false)),
        )
        .subscribe(
          (contragent) => {
            this.initialOrg =
              contragent.Description === 'Приватна особа' ? null : contragent;
            this.contactForm.patchValue({
              contragentType:
                contragent.Description === 'Приватна особа' ? 'Priv' : 'Org',
            });

            this.updateContactAddress();
          },
          (errors: IApiError[]) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    } else {
      this.initialOrg = null;
    }
    this.initForm();
  }

  addNewAddress() {
    const newAddress = new Address();
    newAddress.city = new Locality();
    newAddress.city.name = '';
    newAddress.typeWarehouse = 'NEW_POST';
    this.newAddress = newAddress;
    const warehouse = new Street();
    warehouse.description = '';
    this.warehouse = warehouse;
  }

  saveAddress() {
    const addressData = this.contactValue.AddressSender;
    if (addressData.mode === 'create') {
      const address = mapNewAddressToAddressFormat(
        addressData.data,
        addressData.type,
        addressData.Note,
      );
      this.addressService
        .addAddressToContact(address as any, this.contactId)
        .subscribe(
          (data) => {
            this.getAddresses(this.contactId);
            this.newAddress = null;
            this.alertToastService.pushSuccess('Адреси успішно оновлено!');
          },
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    }
  }

  cancelEditMode() {
    this.newAddress = null;
  }

  addNewAddressContact() {
    this.addAddress = true;
    this.editAddressId = '';

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.addNewAddressContact`)
      .next({
        eventLabel: `Додати адресу`,
        eventCategory: componentName,
        eventAction: 'click',
      });
  }

  cancelAddress() {
    this.addAddress = false;
  }

  onChangeOrgType() {
    setTimeout(() => {
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.onChangeContactType`)
        .next({
          eventLabel: `Форма власності`,
          eventCategory: componentName,
          eventAction: 'select',
        });
    }, 30);
  }

  onKeyDownCompany(event) {
    setTimeout(() => {
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.onInputEdrpou`)
        .next({
          eventLabel: `Назва компанії`,
          eventCategory: componentName,
          eventAction: 'input',
        });
    }, 30);
  }

  save() {
    if (!this.contactValue.Contragent && this.contactValue.contragentType === 'Org') {
      this.toaster('Організація не існує або неправильно вказано код ЄДРПОУ');
      return
    }

    this.pending = true;
    if (this.contactForm.valid) {
      let contactSaveObservable: Observable<any>;
      if (!this.contactId) {
        if (this.contactValue.contragentType === 'Priv') {
          contactSaveObservable = this.contactService.add(
            this.mapFormToContact(this.contactValue),
          );
        } else if (this.contactValue.contragentType === 'Org') {
          contactSaveObservable = this.contactService
            .addContragentByEdpu(this.contactValue.Contragent.EDRPOU)
            .pipe(
              concatMap((contragent) => {
                let newContact = this.mapFormToContact(this.contactValue);
                newContact.contragent = contragent;
                return this.contactService.add(newContact);
              }),
            );
        }
      } else {
        if (this.contactValue.contragentType === 'Priv') {
          contactSaveObservable = this.contactService.update(
            this.mapFormToContact(this.contactValue),
          );
        } else if (this.contactValue.contragentType === 'Org') {
          contactSaveObservable = this.contactService
            .addContragentByEdpu(this.contactValue.ContactOrganization.edrpou)
            .pipe(
              concatMap((contragent) => {
                let newContact = this.mapFormToContact(this.contactValue);
                newContact.contragent = contragent;
                return this.contactService.update(newContact);
              }),
            );
        }
      }

      contactSaveObservable
        .pipe(
          switchMap((contact) => this.checkAddressAdding(contact)),
          finalize(() => (this.pending = false)),
        )
        .subscribe(
          (data) => {
            this.alertToastService.pushSuccess('Контакт успішно збережено!');
            this.dialogRef.close();
          },
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    } else {
      if (this.phone.invalid) {
        this.toaster('Телефон не заповнено');
      }
      this.alertToastService.pushError(
        'Для збереження контакту заповніть необхідні поля форми',
      );
      this.pending = false;
    }
  }

  toaster(message: string) {
    this.toastr.info(message, '', {
      closeButton: true,
      timeOut: 10000,
      toastClass: 'toast custom-style',
    });
  }

  checkAddressAdding(contact: Contact) {
    if (this.newAddress) {
      const addressData = this.contactValue.AddressSender;
      if (addressData.mode === 'create') {
        const address = mapNewAddressToAddressFormat(
          addressData.data,
          addressData.type,
          addressData.Note,
        );
        return this.addressService.addAddressToContact(
          address as any,
          contact.id,
        );
      }
    }
    return of(true);
  }

  mapFormToContact(formValue): Contact {
    const contact = {
      firstName: formValue.name,
      lastName: formValue.surname,
      middleName: formValue.middleName,
      phone: formValue.phone,
      additionalPhone: '',
      email: formValue.email,
      info: formValue.info,
      contragent: {},
      id: this.contactId ?? null,
    };

    return contact as Contact;
  }

  removeAddress(address: Address) {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.removeAddress`)
      .next({
        eventLabel: `Виадалити адресу`,
        eventCategory: componentName,
        eventAction: 'click',
      });
    this.pending = true;
    this.addressService.removeAddress(this.contactId, address.id).subscribe(
      (success) => {
        if (success) {
          this.updateContactAddress();
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.removeAddress.success`)
            .next({
              eventLabel: `Виадалити адресу, успіх`,
              eventCategory: componentName,
              eventAction: 'click.success',
            });
        } else {
          this.updateContactAddress();
          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.removeAddress.unsuccess`)
            .next({
              eventLabel: `Виадалити адресу, не успішно`,
              eventCategory: componentName,
              eventAction: 'click.unsuccess',
            });
        }
        this.pending = false;
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
        this.pending = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.removeAddress.error`)
          .next({
            eventLabel: `Виадалити адресу, помилка: ${
              errors[0] && errors[0].messageCode
            }`,
            eventCategory: componentName,
            eventAction: 'click.error',
          });
      },
    );
  }

  edit(address: Address) {
    this.addAddress = true;
    this.editAddressId = address.id;
    // this._popupAddressId = this._ngDialog.open({
    //   template: `<np-address-edit class="modal-flex" data-contact-id="${this
    //     .contact.id}" data-address-id="${address.id}"></np-address-edit>`,
    //   plain: true
    // }).id;

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.edit`)
      .next({
        eventLabel: `Редагувати адресу`,
        eventCategory: componentName,
        eventAction: 'click',
      });
  }

  updateContactAddress() {
    this.addressService.getList({ contactId: this.contactId }).subscribe(
      (data) => {
        this.contactAddresses.length = 0;
        this.contactAddresses.push(...data.list);
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
      },
    );
  }

  remove() {
    this.pending = true;
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.remove`)
      .next({
        eventLabel: `Видалити контакт`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    this.contactService.remove(this.contactId).subscribe(
      (success) => {
        this.alertToastService.pushSuccess('Успішно видалено!');
        this.dialogRef.close();
        this.pending = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.remove.success`)
          .next({
            eventLabel: `Видалити контакт, успіх`,
            eventCategory: componentName,
            eventAction: 'click.success',
          });
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
        this.pending = false;
        Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
          'analitics',
        )
          .subject(`${componentName}.remove.error`)
          .next({
            eventLabel: `Видалити контакт, помилка: ${
              errors[0] && errors[0].messageCode
            }`,
            eventCategory: componentName,
            eventAction: 'click.success.error',
          });
      },
    );
  }

  private getAddresses(id) {
    this.addressService
      .getList({ contactId: id })
      .pipe(map((data) => data.list))
      .subscribe(
        (data) => {
          this.contactAddresses = data;
        },
        (errors) => {
          if (errors[0]) {
            this.alertToastService.pushError(errors[0].translatedError);
          }
        },
      );
  }
}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService, ClaimService, ConfigService, WINDOW } from '@services';
import { Unsubscriber } from '@shared';
import { BehaviorSubject, fromEvent, map, Observable, of, pluck, switchMap, take } from 'rxjs';
import { ClaimFrameUrl } from '../../../../../../environments/environment.constants';
import { OAuth2Service } from '../../../../../services/auth/oAuth2.service';
import {
  ClaimLocale,
  ClaimSource,
  IframePostMessage,
  ShipmentFrameCookiePrefix,
  ShipmentFrameUrlParam,
} from './claim.constants';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimComponent extends Unsubscriber implements OnInit {
  @ViewChild('iframe') formFrame: ElementRef | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) private data: string,
    private auth: AuthService,
    private oAuth2Service: OAuth2Service,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private sanitizer: DomSanitizer,
    private config: ConfigService,
    private cs: ClaimService,
  ) {
    super();
  }

  showForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showForm$: Observable<boolean> = this.showForm.asObservable();

  private _iframeUrl: SafeResourceUrl = '';
  get iframeUrl(): SafeResourceUrl {
    return this._iframeUrl;
  }

  get frameUrl(): string {
    return this.transformUrl(ClaimFrameUrl);
  }

  @HostListener('window:keyup.esc') onKeyUp(): void {
    this.cs.close();
  }

  ngOnInit(): void {
    this.subscribeToPostMessage();
    this.openUrl();
  }

  private openUrl(): void {
    this.setIframeUrl(this.frameUrl);
    this.showForm.next(true);
  }

  private setIframeUrl(value: string): void {
    this._iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  private transformUrl(value: string): string {
    const params: Array<string> = [];

    const shipmentNumber = `shipmentNumber=${this.data}`;
    params.push(shipmentNumber);

    const locale = `${ShipmentFrameUrlParam.LOCALE}=${ClaimLocale}`;
    params.push(locale);

    // Here we use IdToken for compatibility with external frames
    let tokenParam = '';
    if (this.oAuth2Service.getIdToken()?.length) {
      tokenParam = this.oAuth2Service.getIdToken();
    } else {
      tokenParam = this.auth.authToken;
    }

    const token = `${ShipmentFrameUrlParam.TOKEN}=${tokenParam}`;
    params.push(token);

    const source = `${ShipmentFrameUrlParam.SOURCE}=${ClaimSource}`;
    params.push(source);

    return value + '?' + params.join('&');
  }

  private subscribeToPostMessage(): void {
    this.subscriptions = fromEvent(this.window, 'message')
      .pipe(
        pluck('data'),
        map((data: MessageEvent['data']) => data as IframePostMessage),
      )
      .subscribe((data: IframePostMessage) => this.handleError(data));
  }

  private handleError(data: IframePostMessage): void {
    switch (data.name) {
      case 'close':
        this.handleClose();
        break;
      case 'clickOutside':
        this.handleClickOutside();
        break;
      case 'doubleClickOutside':
        this.handleDoubleClickOutside();
        break;
      case 'authorization':
        this.handleAuth(data);
        break;
      default:
        break;
    }
  }

  private handleClose(): void {
    this.close();
  }

  private handleClickOutside(): void {
    this.close();
  }

  private handleDoubleClickOutside(): void {
    this.close();
  }

  private close(): void {
    this.cs.close();
  }

  private handleAuth(data: IframePostMessage): void {
    // It checks type of authentication to frame
    if (this.oAuth2Service.getIdToken()?.length) {
      // It checks expiration time of ID token adn refresh it
      this.subscriptions = this.oAuth2Service
        .checkTokenValidityIdTokenAndRefresh()
        .pipe(
          switchMap(() => of(this.oAuth2Service.getIdToken())),
          take(1),
        )
        .subscribe((token: string) => {
          this.postToken(token, data.id);
        });
    } else {
      this.postToken(this.auth.authToken, data.id);
    }
  }

  private postToken(token: string, id: string) {
    this.formFrame?.nativeElement.contentWindow.postMessage(
      {
        type: 'event',
        name: 'token_refresh',
        id,
        params: {
          token: `${ShipmentFrameCookiePrefix} ${token}`,
        },
      },
      this.frameUrl,
    );
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {MaterialModule} from '../../../../material';
import {ContactOrganizationComponent} from './contact-organization/contact-organization.component';
import {ContactPrivatePersonComponent} from './contact-private-person/contact-private-person.component';
import {PhoneInputModule} from '../../../../shared/modules/phone-input/phone-input.module';
import {CoreModule} from '../../../../core/core.module';
import {InvoiceDirectivesModule} from '../../../../shared/directives/invoice-directives/invoice-directives.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    PhoneInputModule,
    InvoiceDirectivesModule,
    NgxMaskModule,
  ],
  declarations: [
    ContactOrganizationComponent,
    ContactPrivatePersonComponent,
  ],
  exports: [
    ContactOrganizationComponent,
    ContactPrivatePersonComponent,
  ],
})
export class ContactTypesModule {
}

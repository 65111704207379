<div class="container">
  <div class="edit-field__content edit-field__content_open debt-container">
    <div class="edit-field__title">У вас існує заборгованість</div>
    <div class="edit-field__text-black">На жаль, станом на сьогодні можливість безготівкового розрахунку для вашої компанії обмежена.  <br>
        Причина: <br> <strong [innerHTML]="getDebtsDescriptionNew()"></strong> <br/>
        Для відновлення можливості безготівкового розрахунку - необхідно погасити заборгованість в повному обсязі.
    </div>
    <div class="edit-field__buttons">
      <button type="button"
              (click)="goToMutual()"
              mat-button color="primary">
        Детальніше
      </button>

      <button type="button"
              (click)="closePopup()"
              mat-button color="primary">
        Зрозуміло
      </button>
    </div>
  </div>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertToastService} from '@services';
import {Unsubscriber} from '@shared';
import {BehaviorSubject, combineLatest, finalize, Observable, take, timer} from 'rxjs';
import {
  MIN_ATTACHING_DURATION
} from '../../../../components/dashboard/business-services/courier-pickup/courier-pickup.constants';
import {
  CourierPickupApiService
} from '../../../../components/dashboard/business-services/services/courier-pickup-api/courier-pickup-api.service';
import {invoicesPartialSuccessConfig} from '../../partial-success/partial-success.model';
import {PartialSuccessService} from '../../partial-success/partial-success.service';
import {hasFailedEntities} from '../../partial-success/partial-success.utils';
import {CourierPickupSuccessModalData} from './courier-pickup-attaching.interface';

@Component({
  selector: 'app-courier-pickup-created',
  templateUrl: './courier-pickup-attaching.component.html',
  styleUrls: ['./courier-pickup-attaching.component.scss'],
})
export class CourierPickupAttachingComponent extends Unsubscriber implements OnInit {

  constructor(
    private dialog: MatDialogRef<CourierPickupAttachingComponent>,
    private partialSuccess: PartialSuccessService,
    private courierApiService: CourierPickupApiService,
    private alert: AlertToastService,
    @Inject(MAT_DIALOG_DATA) public data: CourierPickupSuccessModalData,
  ) {
    super();
  }

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean> = this._loading.asObservable();

  ngOnInit(): void {
    this.addInvoicesToCourierPickup();
    this.subscribeToBackdropClick();
  }

  success: boolean = true;

  closeParams: Record<string, boolean> | null = null;

  hasError: boolean = false;

  close(): void {
    if (!this._loading.value) {
      this.dialog.close(this.closeParams);
    }
  }

  get label(): string {
    return this.data.isCreated ? 'створено' : 'оновлено';
  }

  private addInvoicesToCourierPickup(): void {
    if (!this.data.invoices.length) {
      return;
    }

    this._loading.next(true);
    const minimalDelayTimer = timer(MIN_ATTACHING_DURATION);
    const insertObs =
      this.courierApiService.insertDocuments(
        this.data.barcode,
        this.data.invoices);
    const subscription = combineLatest([minimalDelayTimer, insertObs]);

    subscription.pipe(take(1))
      .pipe(
        finalize(() => this._loading.next(false)),
      )
      .subscribe(([_, res]) => {
        if (res) {
          if (hasFailedEntities(res)) {
            const config = {
              ...invoicesPartialSuccessConfig,
              label: res.Barcode ? `№${res.Barcode}` : '',
            };
            this.partialSuccess.open(res, config);
            this.dialog.close({success: false, partialSuccess: true});
          } else {
            this.closeParams = {success: true, partialSuccess: false};
          }
        } else {
          this.dialog.close({success: false, partialSuccess: false});
        }
      }, (err) => {
        if (err[0]) {
          this.alert.pushApiErrors(err);
        }

        this.hasError = true;
      });
  }

  private subscribeToBackdropClick(): void {
    this.subscriptions = this.dialog.backdropClick().subscribe(() => {
      if (!this._loading.value) {
        this.close();
      }
    });
  }
}

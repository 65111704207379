import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {
  AbstractFormContainer,
  CardTypes,
  clearField,
  getError,
  GetPromocodeResponseData,
} from '@shared';
import { AutoCompleteOptionItem, mapToOptions } from '@autocomplete';
import { AuthApi, AuthService } from '@services';
import { finalize } from 'rxjs/operators';
import { errors, label, maxLength } from './delivery-promocode.constants';
import { MatInput } from '@angular/material/input';
import { PromocodeValidator } from './delivery-promocode.validators';

@Component({
  selector: 'app-delivery-promocode',
  templateUrl: './delivery-promocode.component.html',
  styleUrls: ['./delivery-promocode.component.scss'],
})
export class DeliveryPromocodeComponent
  extends AbstractFormContainer
  implements OnInit
{
  getError = getError;

  clearField = clearField;

  maxLength = maxLength;

  promocodesLoaded = false;

  loading = false;

  items: Array<AutoCompleteOptionItem<GetPromocodeResponseData>> = null;

  errors = errors;

  @ViewChild(MatInput, { read: ElementRef }) input;

  @Input() controlName = 'Promocode';

  @Input() label = label;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private authApi: AuthApi,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  @HostListener('input', ['$event']) onKeyUp(event: Event): void {
    (event.target as HTMLInputElement).value = (
      event.target as HTMLInputElement
    ).value.toUpperCase();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls(): void {
    this.form.addControl(
      this.controlName,
      new UntypedFormControl('', [PromocodeValidator]),
    );
  }

  displayTransformFn(value: GetPromocodeResponseData | string): string {
    if (typeof value === 'string') {
      return value;
    }
    return value?.Promocode;
  }

  blur(): void {
    this.input.nativeElement.blur();
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const data = event.clipboardData.getData('text');

    if (!data) {
      return;
    }

    this.control.setValue(
      data.replace(/\s/g, '').slice(0, maxLength).toUpperCase(),
      {
        emitEvent: false,
      },
    );
  }

  getPromocodes(): void {
    if (
      this.promocodesLoaded ||
      this.authService.user.loyaltyCardType !== CardTypes.ROZNIZA
    ) {
      return;
    }
    this.loading = true;
    this.authApi
      .getPromocodeByPhone({ Phone: this.authService.user.phone, ShowAutoPromocodes: false })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.promocodesLoaded = true;
          this.cd.detectChanges();
        }),
      )
      .subscribe((promocodes) => {
        this.items = mapToOptions(promocodes);
        this.cd.detectChanges();
      });
  }
}

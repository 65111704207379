<div class="np-phone-form" [formGroup]="phoneForm">
  <mat-form-field class="mat-block" [ngClass]="{'input-readonly': readonly, 'input-readonly-black': blackText}">
    <input
      type="tel"
      class="np-phone-input"
      matInput
      [textMask]="{mask: !intertationalInvRecipient ? mask : false, guide: false}"
      formControlName="phone"
      [readonly]="readonly"
      [placeholder]="placeholder"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      (keyup)="onKeyUp($event)"
      (paste)="onPaste($event)"
      autocomplete="off"
      [attr.disabled]="disabled"
      autoScrollTarget>
    <mat-hint *ngIf="hintText">{{hintText}}</mat-hint>
    <mat-error>
      <ng-content></ng-content>
    </mat-error>
  </mat-form-field>
</div>

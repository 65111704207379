import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatSortHeader, Sort, SortDirection } from '@angular/material/sort';
import { filter, tap } from 'rxjs';

@Directive({
  selector: '[app-sort-header]',
  exportAs: 'appSortHeader',
})
export class AppSortHeaderDirective implements OnInit {
  readonly classes = {
    asc: 'mat-sort-header_sort-up',
    desc: 'mat-sort-header_sort-down',
  };

  constructor(
    private matSortHeader: MatSortHeader,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.matSortHeader._sort.sortChange
      .pipe(
        tap(() => this.handleActiveViewState()),
        filter((change: Sort) => this.matSortHeader.id === change.active),
      )
      .subscribe((change: Sort) => this.handleSortChange(change));
  }

  handleActiveViewState(): void {
    if (!this.checkActiveViewState()) {
      this.resetHeader();
    }
  }

  checkActiveViewState(): boolean {
    return this.matSortHeader._viewState.toState === 'active';
  }

  handleSortChange(change: Sort): void {
    if (!this.matSortHeader._isSorted()) {
      this.resetHeader();
    } else {
      this.changeHeader(change.direction);
    }
  }

  resetHeader(): void {
    Object.keys(this.classes).forEach((direction) =>
      this.renderer.removeClass(
        this.elRef.nativeElement,
        this.classes[direction],
      ),
    );
  }

  changeHeader(direction: SortDirection): void {
    if (direction === 'asc') {
      this.changeClass('desc', 'asc');
    } else if (direction === 'desc') {
      this.changeClass('asc', 'desc');
    } else {
      this.resetHeader();
    }
  }

  changeClass(from: string, to: string): void {
    this.renderer.removeClass(this.elRef.nativeElement, this.classes[from]);
    this.renderer.addClass(this.elRef.nativeElement, this.classes[to]);
  }
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  protected _config = {
    apiUrl: '/v2.0/'
  };

  constructor() {
    if ((window as any).CONFIG_APP) {
      this._config = (window as any).CONFIG_APP;
    } else {
      // throw { message: 'CONFIG_APP was not found!' };
    }
  }

  get(key: string): any {
    return this._config[key];
  }

  // Provider's factory function
  public $get(): any {
    return {
      get: (key: string) => {
        return this.get(key);
      },
    };
  }
}

import { Features } from '@services';
import { CardTypes } from '@shared';
import { Model } from './base.model';
import { Member } from './member.model';

export class Organization extends Model {
  apiKey: string;
  authToken: string;
  loyaltyCardNumber: string;
  fullNameCounterparty: string;
  type: string;
  description: string;
  contacts: any[];
  userLogin: string;
  authHash: string;
  hasLoyaltyCard: boolean;
  loyaltyCardSubType?: string;
  CheckQuestion: boolean;
  loyaltyCardType: CardTypes;
  Features: Features;

  constructor(contacts: Member[] = [], id: string = null) {
    super(id);
    this.contacts = contacts;
    if (contacts.length) {
      this.apiKey = contacts[0].ApiKey;
    }
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FilterByPipe} from '../core/pipes/filter-by.pipe';
import { SortPipe } from '../core/pipes/sort.pipe';
import {TruncateString} from '../core/pipes/truncate-string';
import {DateTransformPipe} from './date-transform.pipe';
import {DebouncePipe} from './debounce.pipe';
import {DigitRoundPipe} from './digit-round.pipe';
import {FineDocNumberPipe} from './fine-doc-number.pipe';
import {FineDocSearchPipe} from './fine-doc-search';
import {FineNumberPipe} from './fine-number.pipe';
import {NpDatePipe} from './np-date.pipe';
import {NpFinePhonePipe} from './np-fine-phone.pipe';
import {SumFormatPipe} from './sum-format.pipe';
import {ToDateFormatPipe} from './to-date-format.pipe';

@NgModule({
  declarations: [
    DebouncePipe,
    DigitRoundPipe,
    FineDocNumberPipe,
    FineDocSearchPipe,
    FineNumberPipe,
    NpDatePipe,
    NpFinePhonePipe,
    SumFormatPipe,
    ToDateFormatPipe,
    FilterByPipe,
    TruncateString,
    DateTransformPipe,
    SortPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DebouncePipe,
    DigitRoundPipe,
    FineDocNumberPipe,
    FineDocSearchPipe,
    FineNumberPipe,
    NpDatePipe,
    NpFinePhonePipe,
    SumFormatPipe,
    ToDateFormatPipe,
    FilterByPipe,
    TruncateString,
    DateTransformPipe,
    SortPipe,
  ],
})
export class PipeModule {
}

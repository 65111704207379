<ng-container [formGroup]="form">
  <mat-radio-group [attr.data-test-id]="config.testId"
                   [formControlName]="controlName"
                   class="filter__radiobutton-label d-flex flex-direction-column mt-6">
    <div class="filter__label_new">{{ config.title }}</div>
    <mat-radio-button class="mt-4 ml-0 mb-0"
                      *ngFor="let item of config.options"
                      [value]="item.value">
      {{ item.label }}
    </mat-radio-button>
  </mat-radio-group>
</ng-container>

<mat-form-field *ngIf="control">
  <mat-select (selectionChange)="changeInterval($event)"
              [disabled]="(pending$ | async)  || !recipientCityRef || error"
              [formControl]="control"
              [value]="initialInterval"
              placeholder="Часовий проміжок">
    <mat-option *ngIf="!error"
                [value]="1">
      Впродовж дня
    </mat-option>
    <mat-option *ngFor="let interval of timeIntervals"
                [value]="interval.Number">
      <ng-container *ngIf="interval.Start && interval.End; else missingParameters">
        {{interval.Start}} - {{interval.End}}
      </ng-container>

      <ng-template #missingParameters>
        <ng-container *ngIf="!interval.Start; else noEnd">
          до {{interval.End}}
        </ng-container>

        <ng-template #noEnd>
          з {{interval.Start}}
        </ng-template>
      </ng-template>
    </mat-option>
  </mat-select>
  <mat-error>Це поле обов'язкове</mat-error>
</mat-form-field>

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderSearchService {
  private searchSubject = new Subject<string>();

  private clearSearchSubject = new Subject<void>();

  get clear$(): Observable<void> {
    return this.clearSearchSubject.asObservable();
  }

  setSearchString(value: string): void {
    setTimeout(() => {
      this.searchSubject.next(value);
    });
  }

  getValue(): Observable<any> {
    return this.searchSubject;
  }

  clearSearch(): void {
    this.setSearchString('');
    this.clearSearchSubject.next();
  }
}

export const CityMinLength = 2;
export const CityMaxLength = 150;
export const CityDebounceTime = 300;
export const RequestLimit = 100;
export const CityControlName = 'city';
export const CityLabel = 'Населений пункт';
export const CityPlaceholder = '';
export const CityClasses = 'form-col w-30';
export const StreetClasses = 'form-col w-50';
export const OptionDisabledTooltip =
  'Немає відділень Нова пошта. Оберіть інший населений пункт';

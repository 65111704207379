import { GetCountriesData } from '@shared';
import { LatinLettersTooltip } from '../components/addresses/city-select-international/city-select.constants';
import {
  PolandPhoneMask,
  RecipientPhoneErrorsPoland,
  RecipientPhoneValidatorsPoland,
} from '../international-invoice.constants';

// Poland
export const PL: Partial<GetCountriesData> = {
  CityTooltip: LatinLettersTooltip,
  PhonePrefix: '+48',
  PhoneMask: PolandPhoneMask,
  PhoneValidators: RecipientPhoneValidatorsPoland,
  PhoneErrors: RecipientPhoneErrorsPoland,
  RecipientPostboxAvailable: true,
  RecipientSpotSelectionAvailable: true,
};

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  InvoiceStatusTab,
  InvoiceStatusTabFilter,
} from './status-tab/status-tab.component';
import { Invoice } from '../../../models/invoice/invoice.model';

export const invoiceStatusGroups = {
  neutral: new Set([1]),
  inWay: new Set([4, 41, 5, 6, 101, 104, 111, 112]),
  delivered: new Set([7, 8, 14]),
  received: new Set([9, 10, 11, 106]),
  problem: new Set([2, 3, 102, 103, 105, 107, 108]),
};

@Component({
  selector: 'app-invoice-status-tabs',
  templateUrl: 'invoice-status-tabs.component.html',
  styleUrls: ['./invoice-status-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceStatusTabsComponent implements OnInit {
  @Input('tabs')
  set customTabs(value: InvoiceStatusTab[]) {
    this._customTabs = value;
    this.mergeTabs();
  }

  tabs: InvoiceStatusTab[] = [];

  defaultTabs: InvoiceStatusTab[] = [
    {
      statusName: 'inWay',
      color: 'dark-blue',
      name: 'У дорозі',
      active: false,
      operation: 'or',
      fn: (i: Invoice) =>
        i.statusCode &&
        i.statusCode &&
        invoiceStatusGroups.inWay.has(Number(i.statusCode)),
    },
    {
      statusName: 'delivered',
      color: 'orange',
      name: 'У відділенні',
      active: false,
      operation: 'or',
      fn: (i: Invoice) =>
        i.statusCode &&
        i.statusCode &&
        invoiceStatusGroups.delivered.has(Number(i.statusCode)),
    },
    {
      statusName: 'received',
      name: 'Отримані',
      color: 'green',
      active: false,
      operation: 'or',
      fn: (i: Invoice) =>
        i.statusCode &&
        i.statusCode &&
        invoiceStatusGroups.received.has(Number(i.statusCode)),
    },
    {
      statusName: 'problem',
      name: 'Проблемні',
      color: 'red',
      active: false,
      operation: 'or',
      fn: (i: Invoice) =>
        i.statusCode &&
        i.statusCode &&
        invoiceStatusGroups.problem.has(Number(i.statusCode)),
    },
  ];

  private _customTabs: InvoiceStatusTab[] = [];

  @Output()
  statusCodesChange = new EventEmitter<Set<number>>();

  @Output()
  statusFilterFnChange = new EventEmitter<(...args) => boolean>();

  mergeTabs(): void {
    this.tabs = [...this._customTabs, ...this.defaultTabs];
  }

  ngOnInit(): void {
    this.mergeTabs();
  }

  onTabSelected(tab: InvoiceStatusTab): void {
    this.updateTab(tab);
    const fns = this.getFns();
    const filter = this.getFilter(fns);
    this.statusFilterFnChange.emit(filter);
  }

  private updateTab(newTab: InvoiceStatusTab): void {
    this.tabs = this.tabs.map((tab) =>
      tab.statusName === newTab.statusName
        ? { ...tab, active: !newTab.active }
        : tab,
    );
  }

  private getFns(): InvoiceStatusTabFilter[] {
    return this.tabs
      .filter((t) => t.active)
      .map((t) => ({ fn: t.fn, op: t.operation }));
  }

  private getFilter(
    fns: InvoiceStatusTabFilter[],
  ): (...args) => boolean | null {
    let filter;
    if (fns.length > 0) {
      const noop = () => true;
      const unionFns = fns.filter((f) => f.op === 'or');
      const intersectionFns = fns.filter((f) => f.op === 'and');

      const or =
        unionFns.length > 0
          ? (i: Invoice, init: boolean = false) =>
              unionFns.reduce((acc, f) => f.fn(i) || acc, init)
          : noop;

      const and =
        intersectionFns.length > 0
          ? (i: Invoice, init: boolean = true) =>
              intersectionFns.reduce((acc, f) => f.fn(i) && acc, init)
          : noop;

      filter = (i: Invoice) => and(i) && or(i);
    } else {
      filter = null;
    }
    return filter;
  }
}

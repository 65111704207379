import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap } from 'rxjs';
import {
  Address,
  Contact,
  Contragent,
  IApiError,
  IListData,
  IOrderParameter,
  Locality,
  OrgType,
  Street,
} from '@models';
import { AuthService } from '@services';
import { ConfigService } from '../config.service';
import { IContactFilterParam } from './icontact.api';
import { NoRestApiHelper } from '../no-rest-api.helper';
import { capitalize } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService {
  protected _privateContragentId: string = null;
  protected _orgTypes: OrgType[] = [];

  constructor(
    protected http: HttpClient,
    protected _ConfigStorage: ConfigService,
    protected _noRestApiHelper: NoRestApiHelper,
    protected authService: AuthService,
  ) {}

  getList(
    filter: IContactFilterParam,
    order: IOrderParameter,
    page = 1,
    limit = 100,
    counterpartyRefs?: string[],
  ): Observable<IListData<Contact>> {
    let contragentsPromises: any;
    let contragentId: string;
    const getAddress = filter.getAddress ? 1 : 0;

    if (filter.contactType === 'personal') {
      contragentsPromises = of(this.authService.user?.contragent?.id);
    } else if (filter.contactType === 'my') {
      contragentsPromises = this._getContragentsPromise();
    } else {
      contragentsPromises = of(undefined);
    }

    const methodProperties: any = {
      Page: page,
      Limit: limit,
      ContactProperty: filter.contactProperty,
      getContactPersonAddress: getAddress,
      SearchByCounterparties: this.authService.user.contacts ? 1 : null,
      iCounterparties: counterpartyRefs ? counterpartyRefs : null,
    };

    if (filter.lastName) {
      methodProperties.LastName = filter.lastName;
    }

    if (filter.contragentType) {
      if (filter.contragentType === 'Org') {
        methodProperties.CounterpartyType = 'Organization';
      } else {
        methodProperties.CounterpartyType = 'PrivatePerson';
      }
    }

    if (filter.phone) {
      if (filter.phone.length === 10) {
        filter.phone = '38' + filter.phone;
      }
      methodProperties.FindByPhone = filter.phone;

      methodProperties.ContactProperty = 'Recipient';
      methodProperties.getContactPersonAddress = '0';

      delete methodProperties.CounterpartyType;
    }

    if (filter.place === 'Cat') {
      return this.searchCatalogContact(filter);
    }

    return contragentsPromises.pipe(
      switchMap((contragentRef: string) => {
        contragentId = contragentRef;
        methodProperties.CounterpartyRef = contragentRef;

        const obs: any = this.http.post(
          `${this._ConfigStorage.get('apiUrl')}json/`,
          {
            system: this._ConfigStorage.get('system'),
            modelName: 'ContactPersonGeneral',
            calledMethod: filter.phone
              ? 'getContactPersonsCounterparty'
              : 'getContactPersonsList',
            methodProperties,
          },
        );

        return obs;
      }),
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data) => this.bindListToModel(data, filter.getAddress)),
      switchMap((data: any) => {
        if (
          filter.place === 'LocCat' &&
          data.total === 0 &&
          filter.lastName.length > 2
        ) {
          return this.searchCatalogContact(filter);
        }
        return of(data);
      }),
    );
  }

  getOne(id: string): Observable<Contact> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'ContactPersonGeneral',
        calledMethod: 'getContactPersonsList',
        methodProperties: {
          Ref: id,
        },
      },
    );

    return promise.pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => {
        if (!data.data[0]) {
          const errors: IApiError[] = [
            {
              messageCode: 'Контакт не знайдено',
              message: 'Контакт не знайдено',
              errorCode: 'Контакт не знайдено',
            },
          ];

          throw errors;
        }

        return data;
      }),
      map((data: any) => this._bindOneToModel(data)),
      mergeMap((contact: any) =>
        this.getOneContragent(contact.contragent.id).pipe(
          map((contragent) => {
            contact.contragent = contragent;
            return contact;
          }),
        ),
      ),
    );
  }

  remove(id: string): Observable<boolean> {
    const deletePromise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'ContactPerson',
        calledMethod: 'delete',
        methodProperties: {
          Ref: id,
        },
      },
    );

    //noinspection TypeScriptUnresolvedFunction
    return from(deletePromise).pipe(map((data: any) => data.success));
  }

  add(item: Contact): Observable<Contact> {
    return this.getPrivatConntragentRef(item.contragent.EDRPOU).pipe(
      concatMap((contragentId: string) => {
        const methodproperties: any = {
          CounterpartyRef: contragentId,
          FirstName: item.firstName,
          LastName: item.lastName,
          MiddleName: item.middleName,
          Phone: item.phone,
          AdditionalPhone: item.additionalPhone,
          Email: item.email,
          Info: item.info,
        };

        if (item.isCatalogContact) {
          delete methodproperties.CounterpartyRef;
          methodproperties.CatalogCounterpartyRef = item.contragent.id;
          contragentId = null;
        }

        const addPromise: any = this.http.post(
          `${this._ConfigStorage.get('apiUrl')}json/`,
          {
            system: this._ConfigStorage.get('system'),
            modelName: 'ContactPersonGeneral',
            calledMethod: 'save',
            methodProperties: methodproperties,
          },
        );

        return from(addPromise).pipe(
          map((data) => this._noRestApiHelper.checkErrors({ data })),
          map((data: any) => this._bindOneToModel(data.data)),
        );
      }),
    );
  }

  update(item: Contact): Observable<Contact> {
    return this.getPrivatConntragentRef().pipe(
      concatMap((contragentId: string) => {
        const updatePromise: any = this.http.post(
          `${this._ConfigStorage.get('apiUrl')}json/`,
          {
            system: this._ConfigStorage.get('system'),
            modelName: 'ContactPerson',
            calledMethod: 'update',
            methodProperties: {
              Ref: item.id,
              CounterpartyRef:
                item.contragent && item.contragent.id
                  ? item.contragent.id
                  : contragentId,
              FirstName: item.firstName,
              LastName: item.lastName,
              MiddleName: item.middleName,
              Phone: item.phone,
              AdditionalPhone: item.additionalPhone,
              Email: item.email,
              Info: item.info,
            },
          },
        );

        return from(updatePromise).pipe(
          map((data) => this._noRestApiHelper.checkErrors(data)),
          map((data: any) => this._bindOneToModel(data)),
        );
      }),
    );
  }

  bindListToModel(data: any, getAddress: boolean): IListData<Contact> {
    return {
      list: _.map(data.data, (el: any) => {
        const contact = new Contact(el.FirstName, el.LastName, el.Ref);
        contact.middleName = el.MiddleName;
        contact.description = el.Description;
        contact.phone = el.Phones;
        contact.email = el.Email;
        contact.info = el.Info || '';
        if (el.CounterpartyRef) {
          contact.contragent = new Contragent(el.CounterpartyRef);
          contact.contragent.name =
            el.CounterpartyDesctiption !== 'Приватна особа' &&
            el.Counterparty !== 'Приватна особа'
              ? el.CounterpartyDesctiption || el.Counterparty
              : 'Приватна особа';
          contact.contragent.ownerType =
            'Приватна особа' === contact.contragent.name ? 'Priv' : 'Org';
        } else {
          contact.contragent = new Contragent();
          contact.contragent.name = el.Description;
          contact.contragent.ownerType =
            'Приватна особа' === contact.contragent.name ? 'Priv' : 'Org';
          if (contact.contragent.ownerType === 'Org') {
            contact.contragent.EDRPOU = el.EDRPOU;
          }
        }
        if (el.RefCounterparty) {
          contact.isCatalogContact = true;
          contact.contragent.id = el.RefCounterparty;
        }

        if (getAddress) {
          const addresses: any[] = [];

          if (el.Addresses.DoorsAddresses) {
            addresses.push(...el.Addresses.DoorsAddresses);
          }
          if (el.Addresses.WarehouseAddresses) {
            addresses.push(...el.Addresses.WarehouseAddresses);
          }

          contact.addresses = _.map(addresses, (addr: any) => {
            const address = new Address(addr.Ref);

            address.street = new Street(addr.StreetRef);
            address.street.name = addr.StreetDescription;
            address.street.locality = new Locality(
              addr.CityRef || addr.SettlementRef,
            );
            address.street.locality.id2 = addr.SettlementRef;
            address.street.locality.name =
              addr.SettlementDescription ||
              addr.CityDescription ||
              addr.CityRef;
            address.street.locality.typeName = addr.Type;
            address.city = address.street.locality;
            address.building = addr.BuildingDescription || addr.BuildingNumber;
            address.street.type = addr.StreetsType;
            address.flat = addr.Flat;
            address.warehouseNumber = addr.WarehouseNumber;

            address.description = addr.Description || addr.AddressDescription;
            if (addr.AddressDescription && addr.CityDescription) {
              address.description =
                address.description + ', ' + addr.CityDescription;
            }

            address.type =
              address.street && address.street.id ? 'Doors' : 'Warehouse';

            return address;
          });
        }

        return contact;
      }),
      total: data.info.totalCount,
    };
  }

  getPrivatConntragentRef(edrpou?): Observable<string> {
    return this._getContragentsPromise(edrpou);
  }

  getOrgTypes(): Observable<OrgType[]> {
    if (this._orgTypes.length) {
      return of(this._orgTypes);
    }

    const promise: any = this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'Common',
        calledMethod: 'getOwnershipFormsList',
        methodProperties: {},
      })
      .pipe(map((data) => this._noRestApiHelper.checkErrors(data)))
      .subscribe((data) => {
        this._orgTypes = data.data.map((el: any) => {
          const orgType = new OrgType(el.Ref);
          orgType.shortName = el.Description;
          orgType.fullName = el.FullName;

          return orgType;
        });

        return this._orgTypes;
      });

    return of(this._orgTypes);
  }

  addContragentByEdpu(edpu: string): Observable<Contragent> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'save',
        methodProperties: {
          CityRef: '',
          FirstName: '',
          MiddleName: '',
          LastName: '',
          Phone: '',
          Email: '',
          CounterpartyType: 'Organization',
          CounterpartyProperty: 'Recipient',
          EDRPOU: edpu,
          OwnershipForm: '',
        },
      },
    );

    return from(promise).pipe(
      map((data) => this._noRestApiHelper.checkErrors({ data })),
      map((data: any) => this._bindContragent(data.data.data[0])),
      map((contragent: Contragent) => {
        contragent.isCatalog = true;

        return contragent;
      }),
      mergeMap((contragent) => {
        return this._fullContragentOrgTypes(contragent.orgType).pipe(
          map((orgType) => {
            contragent.orgType = orgType;

            return contragent;
          }),
        );
      }),
    );
  }

  addContragentByName(contragent: Contragent): Observable<Contragent> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'save',
        methodProperties: {
          CityRef: 'db5c88d7-391c-11dd-90d9-001a92567626',
          FirstName: contragent.name,
          CounterpartyType: 'Organization',
          OwnershipForm: contragent.orgType.id,
          CounterpartyProperty: 'Recipient',
        },
      },
    );

    return from(promise).pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => this._bindContragent(data.data.data[0])),
      mergeMap((c) => {
        return this._fullContragentOrgTypes(c.orgType).pipe(
          map((orgType) => {
            c.orgType = orgType;

            return c;
          }),
        );
      }),
    );
  }

  getByEdrpu(edrpou: string): Observable<Contragent> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'getCounterparties',
        methodProperties: {
          CounterpartyProperty: 'Recipient',
          EDRPOU: edrpou,
        },
      },
    );
    return from(promise).pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => this._bindContragent(data.data.data[0])),
      mergeMap((contragent) => {
        return this._fullContragentOrgTypes(contragent.orgType).pipe(
          map((orgType) => {
            contragent.orgType = orgType;

            return contragent;
          }),
        );
      }),
    );
  }

  getOneContragent(id: string): Observable<Contragent> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'getCounterparties',
        methodProperties: {
          CounterpartyProperty: 'Recipient',
          Ref: id,
        },
      },
    );

    return promise.pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => this._bindContragent(data.data[0])),
      mergeMap((contragent: any) =>
        this._fullContragentOrgTypes(contragent.orgType).pipe(
          map((orgType) => {
            contragent.orgType = orgType;

            return contragent;
          }),
        ),
      ),
    );
  }

  findContacts(query: string, phone: string): Observable<any> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'getCatalogCounterparty',
        methodProperties: {
          Phone: phone,
          LastName: capitalize(query),
        },
      },
    );
    return from(promise).pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => this.bindListToModel(data, false)),
    );
  }

  createContragentFromCatalog(
    contact: Contact,
    phone: string,
  ): Observable<any> {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'ContactPerson',
        calledMethod: 'save',
        methodProperties: {
          CatalogCounterpartyRef: contact.contragent.id,
          FirstName: contact.firstName,
          LastName: contact.lastName,
          MiddleName: contact.middleName,
          Phone: phone,
        },
      },
    );
    return from(promise).pipe(
      map((data) => this._noRestApiHelper.checkErrors(data)),
      map((data: any) => this._bindOneToModel(data)),
    );
  }

  protected searchCatalogContact(filter: IContactFilterParam) {
    const promise: any = this.http.post(
      `${this._ConfigStorage.get('apiUrl')}json/`,
      {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'getCatalogCounterparty',
        methodProperties: {
          Phone: filter.phone,
          LastName: capitalize(filter.lastName),
          CounterpartyType: filter.contragentType
            ? filter.contragentType === 'Org'
              ? 'Organization'
              : 'PrivatePerson'
            : undefined,
        },
      },
    );

    //noinspection TypeScriptUnresolvedFunction
    return from(promise).pipe(
      map((data: any) =>
        this._bindCatalogContact(data.data.data, filter.phone),
      ),
    );
  }

  protected _fullContragentOrgTypes(orgType: OrgType): Observable<OrgType> {
    return this.getOrgTypes().pipe(
      map((orgTypes) => {
        if (!orgType) {
          return null;
        }
        const locOrgType = _.find(orgTypes, (type) => type.id === orgType.id);

        if (locOrgType) {
          orgType.fullName = locOrgType.fullName;
        }

        return orgType;
      }),
    );
  }

  protected _bindContragent(item): Contragent {
    let contragent;
    if (!item) {
      return new Contragent();
    } else {
      contragent = new Contragent(item.Ref);
    }

    contragent.name = item.Description;

    if (
      (item.OwnershipForm || item.OwnershipFormRef) ===
      '7f0f351e-2519-11df-be9a-000c291af1b3'
    ) {
      contragent.name = ' ';
    }

    contragent.orgType = new OrgType(
      item.OwnershipForm || item.OwnershipFormRef,
    );
    contragent.orgType.shortName = item.OwnershipFormDescription;
    contragent.orgType.fullName = item.OwnershipFormDescription;
    contragent.EDRPOU = item.EDRPOU;
    contragent.ownerType =
      item.CounterpartyType === 'PrivatePerson' ? 'Priv' : 'Org';

    return contragent;
  }

  protected _getContragentsPromise(edrpou?): Observable<any> {
    return this.http
      .post(`${this._ConfigStorage.get('apiUrl')}json/`, {
        system: this._ConfigStorage.get('system'),
        modelName: 'Counterparty',
        calledMethod: 'getCounterparties',
        methodProperties: {
          CounterpartyProperty: 'Recipient',
        },
      })
      .pipe(
        map((data) => this._noRestApiHelper.checkErrors({ data })),
        map((data: any) => {
          data.data.data.map((el) => {
            if (el.CounterpartyType === 'PrivatePerson') {
              this._privateContragentId = _.find(
                data.data.data,
                (e: any) => e.CounterpartyType === 'PrivatePerson',
              ).Ref;
            } else {
              if (el.EDRPOU === edrpou) {
                this._privateContragentId = el.Ref;
              }
            }
          });

          return this._privateContragentId;
        }),
      );
  }

  protected _bindOneToModel(response: any): Contact {
    let contact: Contact;
    if (response.success && response.data[0]) {
      const el: any = response.data[0];
      contact = new Contact(el.FirstName, el.LastName, el.Ref);
      contact.middleName = el.MiddleName;
      contact.description = el.Description;
      contact.phone = el.Phones;
      contact.additionalPhone = el.AdditionalPhone;
      contact.email = el.Email;
      contact.contragent = new Contragent(el.Recipient || el.CounterpartyRef);
      contact.contragent.name = el.Counterparty || el.CounterpartyDesctiption;
      contact.info = el.Info;
    }

    return contact;
  }

  protected _bindCatalogContact(data, phone): IListData<Contact> {
    const list = _.map(data, (el: any) => {
      const contact = new Contact(
        el.FirstName,
        el.LastName,
        Math.random().toString(),
      );
      contact.middleName = el.MiddleName;
      contact.isCatalogContact = true;
      contact.phone = phone;

      if (el.CounterpartyRef) {
        contact.contragent = new Contragent(el.CounterpartyRef);
        contact.contragent.name =
          el.CounterpartyDesctiption !== 'Приватна особа' &&
          el.Counterparty !== 'Приватна особа'
            ? el.CounterpartyDesctiption || el.Counterparty
            : null;
        contact.contragent.ownerType =
          'Приватна особа' === contact.contragent.name ? 'Priv' : 'Org';
      } else {
        contact.contragent = new Contragent();
        contact.contragent.name = el.Description;
        contact.contragent.ownerType =
          'Приватна особа' === contact.contragent.name ? 'Priv' : 'Org';
        if (contact.contragent.ownerType === 'Org') {
          contact.contragent.EDRPOU = el.EDRPOU;
        }
      }

      return contact;
    });

    return { list, total: list.length };
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Organization, User, UserAnalyticsData } from '@models';
import {
  CardTypes,
  CourierCallFiltersStorageKey,
  InvoicesMyFiltersStorageKey,
  InvoicesTemplateFiltersStorageKey,
  md5,
} from '@shared';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { OrdersFilterConfig } from '../components/dashboard/fulfillment/components/orders/orders.filters';
import { ProductBalancesFilterConfig } from '../components/dashboard/fulfillment/components/product-balances/product-balances.filters';
import { AuthApi } from './auth-api.service';
import { OAuth2Service } from './auth/oAuth2.service';
import { FirebaseAuthService } from './firebase/firebase-auth.service';
import { GoogleAnalyticService } from './google-analytic.service';
import { WINDOW } from './window.service';
import { DOCUMENT } from '@angular/common';

const userLocalStorageKey = 'auth.user';
const accountLocalStorageKey = 'auth.account';
const authHashesLocalStorageKey = 'auth.authhashes';


export interface IForgotPasswordForm {
  email: string;
  loyaltyCard: string;
  password: string;
}

export interface Features {
  CanCourierCall: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isDebtorShowed = false;
  authSubject = new BehaviorSubject<User | null>(null);
  private route: ActivatedRouteSnapshot;

  constructor(
    protected authApi: AuthApi,
    protected router: Router,
    protected storage: LocalStorageService,
    protected firebaseAuthService: FirebaseAuthService,
    protected googleAnalyticsService: GoogleAnalyticService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private oAuth2Service: OAuth2Service,
  ) {
    this.route = activatedRoute.snapshot;
  }

  get invoicesMyFiltersStorageKey(): string {
    return `${this.user?.userLogin}_${InvoicesMyFiltersStorageKey}`;
  }

  get courierCallFiltersStorageKey(): string {
    return `${this.user?.userLogin}_${CourierCallFiltersStorageKey}`;
  }

  get invoicesTemplateFiltersStorageKey(): string {
    return `${this.user?.userLogin}_${InvoicesTemplateFiltersStorageKey}`;
  }

  get ordersFilterConfigStorageKey(): string {
    return `${this.user?.userLogin}_${OrdersFilterConfig.storageKey}`;
  }

  get productBalancesConfigStorageKey(): string {
    return `${this.user?.userLogin}_${ProductBalancesFilterConfig.storageKey}`;
  }

  get features(): Features {
    return {
      ...this._user?.Features,
      CanCourierCall: this._user?.Features?.CanCourierCall ?? false,
    };
  }

  protected _user: User;

  get user(): User {
    return this._user;
  }

  get authToken(): string {
    return this.user?.authToken ?? '';
  }

  get apiKey() {
    return this.user.apiKey ?? '';
  }

  get sessionAuth$(): Observable<boolean> {
    return this.firebaseAuthService.auth$.pipe(take(1));
  }

  get isOauth2() {
    return this.oAuth2Service.getToken();
  }

  setAuthUser(user: any): void {
    this.authSubject.next(user);
    if (user) {
      user.authHash =
        user.authHash ||
        this.getAuthHash(user.userLoginOldAuth || user.fullNameCounterparty);
      if (this.user?.authToken) {
        user.authToken = this.user.authToken;
      }
    }
    this._user = user;
  }

  setUser(): Observable<User | Organization> {
    return of(this.storage.isStorageAvailable()).pipe(
      switchMap((available) => (available ? this.sessionAuth$ : of(null))),
      switchMap((authorized) => (authorized ? this.getUser() : of(null))),
      take(1),
      tap((user) => this.setAuthUser(user)),
    );
  }

  login(code: string, login: string): Observable<User> {
    return this.authApi.confirmLogin(code, login).pipe(
      switchMap((user) => this.firebaseAuthService.signIn(user)),
      map((user: User) => this.processUserAfterLogin(user)),
      tap(user => this.pushUserToAnalytics(user)),
      take(1),
    );
  }

  getAuthType(phone: string) {
    return this.authApi.getAuthType(phone);
  }

  loginOAuth() {
    return this.getAccountInfo().pipe(
      switchMap((user) => this.firebaseAuthService.signIn(user)),
      map((user: any) => {
        this.storage.store('invoice-banner-shown', true);
        this.processUserAfterLogin(user);
        return user;
      }),
      take(1),
    );
  }

  loginWithToken(token: string): Observable<User> {
    return this.authApi.loginWithToken(token).pipe(
      switchMap((user) => this.firebaseAuthService.signIn(user)),
      map((user: User) => this.processUserAfterLogin(user)),
      tap(user => this.pushUserToAnalytics(user)),
      take(1),
    );
  }

  sendLoginSMS(login: string, password: string): Observable<any> {
    this.storage.store('banner_shown', true);
    return this.authApi.login(login, password).pipe(
      switchMap((user: any) => {
        if (user.data && user.data.data.length === 0) {
          return of(user);
        } else {
          return this.firebaseAuthService.signIn(user);
        }
      }),
      map((user: any) => {
        if (user.data && user.data.data.length === 0) {
          return user;
        } else {
          this.storage.store('banner_shown', true);
          this.storage.store('invoice-banner-shown', true);
          this.setAuthUser(user);
          const currentId = this.setAccountToLocalStorage(user);
          this.setAuthHash(user.userLoginOldAuth || user.fullNameCounterparty, user.authHash);
          this.storage.store(`loggedIn${user.email}`, true);
          if (user instanceof User) {
            if (this.user) {
              this.googleAnalyticsService.setUserId(this.user.loyaltyCardNumber || this.user.contragent.id);
              this.pushUserToAnalytics(this.user);
            }
            return { user, id: currentId };
          } else {
            if (this.user && user.contacts) {
              this.pushUserToAnalytics(user);
            }
            
            return { user, id: currentId };
          }
        }
      }),
      take(1),
    );
  }

  updateUser(user: User):void {
    if (this.storage.isStorageAvailable()) {
      const account = this.storage.retrieve(userLocalStorageKey);
      this.setAuthUser(user);
      this.googleAnalyticsService.setUserId(user.loyaltyCardNumber || this.user.contragent.id);
      this.storage.store(userLocalStorageKey, account);
    }
  }

  loginByToken(token: string):void {
    this.authApi
      .loginByToken(token)
      .pipe(switchMap((user) => this.firebaseAuthService.signIn(user)))
      .subscribe(
        (user: User) => {
          this.setAuthUser(user);
          const currentId = this.setAccountToLocalStorage(user);
          this.router.navigateByUrl('dashboard');
        },
        () => this.logout(),
      );
  }

  private clearOnLogout(): void {
    this.storage.clear(accountLocalStorageKey);
    this.storage.clear(userLocalStorageKey);
    this.storage.clear(this.invoicesMyFiltersStorageKey);
    this.storage.clear(this.courierCallFiltersStorageKey);
    this.storage.clear(this.invoicesTemplateFiltersStorageKey);
    this.storage.clear(this.ordersFilterConfigStorageKey);
    this.storage.clear(this.productBalancesConfigStorageKey);
  }

  logout(redirectUrl?: string, blockRedirect = false): void {
    if (this.storage.isStorageAvailable()) {
      this.clearOnLogout();
      this.removeLk2Flag();
    }
    this.setAuthUser(null);

    this.firebaseAuthService
      .signOut()
      .pipe(take(1))
      .subscribe(() => {
        if (!blockRedirect) {
          this.router.navigateByUrl(redirectUrl ?? '/');
        }
      });
    this.logoutOauth();
    this.isDebtorShowed = false;
  }

  removeLk2Flag(): void {
    if (this.user) {
      this.authApi.removeLk2Flag(this.user.userLogin);
    }
  }

  isFromLk2(): boolean {
    if (this.user) {
      return this.authApi.isFromLk2(this.user.userLogin);
    }
  }

  logoutOauth():void {
    this.oAuth2Service.logout();
  }

  getBonuses() {
    if (this.user.type === 'org') {
      return;
    }

    return this.authApi
      .getAccountInfo()
      .pipe(take(1))
      .subscribe((user: User) => {
        const account = this.storage.retrieve(userLocalStorageKey);
        account.discount = user.discount;
        this.setAuthUser(account);
        this.storage.store(userLocalStorageKey, account);
      });
  }

  updateBonuses(): void {
    this.authApi.getAccountInfo().subscribe((user: User) => {
      this.setAuthUser(user);
      this.storage.store(userLocalStorageKey, user);
    });
  }

  getAccountInfo() {
    return this.authApi.getAccountInfo();
  }

  register(regData) {
    return this.authApi.registration(regData);
  }

  requestRecoveryPassword(form: IForgotPasswordForm) {
    return this.authApi.requestRecoveryPassword(form);
  }

  activate(code: string): Observable<boolean> {
    return this.authApi.activate(code);
  }

  changepassword(oldPasword, newPasword): Observable<boolean> {
    return this.authApi.changePassword(
      oldPasword,
      newPasword,
      this.user.userLoginOldAuth,
    );
  }

  forgotLoaltyCardNumber(phone: string): Observable<boolean> {
    return this.authApi.forgotLoaltyCardNumber(phone);
  }

  sendEmailWithCode(login: string) {
    return this.authApi.sendEmailWithCode(login);
  }

  getAuthHash(login: string) {
    login = login.toLowerCase();
    const hashes = this.storage.retrieve(authHashesLocalStorageKey);
    return hashes && hashes[login] ? hashes[login] : '';
  }

  setAuthHash(login: string, authHash: string):void {
    login = login.toLowerCase();
    if (authHash) {
      let hashes = this.storage.retrieve(authHashesLocalStorageKey);
      if (hashes) {
        hashes[login] = authHash;
      } else {
        hashes = {
          [login]: authHash,
        };
      }
      this.storage.store(authHashesLocalStorageKey, hashes);
    }
  }

  private getUser(): Observable<User | Organization> {
    if (this.user) {
      return of(this.user);
    }

    const accountFromStorage = this.storage.retrieve(userLocalStorageKey);
    return of(accountFromStorage).pipe(
      switchMap((user) => {
        if (user) {
          this.pushUserToAnalytics(user);

          return !this.firebaseAuthService.auth.currentUser
            ? this.firebaseAuthService.signIn(user)
            : of(user);
        }

        return of(null);
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }

  private processUserAfterLogin(user: User): User {
    this.setAuthUser(user);
    this.setAccountToLocalStorage(user);
    this.setAuthHash(user.userLoginOldAuth || user.fullNameCounterparty, user.authHash);
    this.storage.store(`loggedIn${user.email}`, true);

    return user;
  }

  pushUserToAnalytics(data: User): void {
    if(!this.user || !data){
      return;
    }

    let analyticsData: UserAnalyticsData;

    if(!data.loyaltyCardType) {
      const user_id = !!data.Cid ? data.Cid : (!!data.CounterpartyCode ? data.CounterpartyCode : '');
      analyticsData = {
        account_type: '',
        user_type: '',
        user_id,
        page_title: this.document.title,
      };
    } else {
      if (data.loyaltyCardType.toLowerCase() !== CardTypes.ROZNIZA) {

        const isCorporate = !!data.contacts?.length;

        const accountType = data.loyaltyCardType.toLowerCase() === 'corporate' ? 'corporate' : [data?.loyaltyCardType ?? '', data?.loyaltyCardSubtype ?? '']
          .map((i) => i.toLowerCase())
          .filter((v) => !!v)
          .join('_');

        const corporateUserId = this.user.userLogin ? md5(this.user.userLogin) : '';

        const user_id = isCorporate ? corporateUserId : (!!data.Cid ? data.Cid : (!!data.CounterpartyCode ? data.CounterpartyCode : ''));

        analyticsData = {
          account_type: accountType,
          user_type: 'business',
          user_id,
          page_title: this.document.title,
        };
      } else {
        const user_id = !!data.Cid ? data.Cid : (!!data.CounterpartyCode ? data.CounterpartyCode : '');
        analyticsData = {
          account_type: 'loyalty_roznica',
          user_type: 'private',
          user_id,
          page_title: this.document.title,
        };
      }
    }

    if (this.getUserInfoFromDataLayer(analyticsData)) {
      this.setToDataLayer(analyticsData);
    } else {
      (window as any).dataLayer = (window as any).dataLayer || [];
      Object.entries(analyticsData).forEach(([key, value]) => {
        (window as any).dataLayer.push({ [key]: value });
      });
    }

    this.pushDataReady();
  }

  private pushDataReady(): void {
    (this.window as any).dataLayer.push({ event: 'data_ready' });
  }

  private setToDataLayer(data: UserAnalyticsData): void {
    const dataLayer = (this.window as any).dataLayer;
    Object.entries(data).forEach(([key, value]) => {
      for (const item of dataLayer) {
        if (item.hasOwnProperty(key)) {
          item[key] = value;
        }
      }
    });
  }

  getUserInfoFromDataLayer(data: UserAnalyticsData): boolean {
    const dataLayer = (this.window as any).dataLayer;
    if(!dataLayer){
      return false
    }
    return Object.keys(data).some((k) => {
      for (const item of dataLayer) {
        if (item.hasOwnProperty(k)) {
          return true;
        }
      }
    });
  }

  private setAccountToLocalStorage(user: User): void {
    if (this.storage.isStorageAvailable()) {
      this.storage.store(accountLocalStorageKey, user);
      this.storage.store(userLocalStorageKey, user);
    } else {
      throw new Error('Setting user: Storage is not available!');
    }
  }
}

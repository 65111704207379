import { Sort } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs';
import { ListDataResponse, TableDataSource } from '@shared';
import { InvoiceRegister } from '../../../models/invoice/invoice-register.model';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { InvoiceRegisterApiService } from '../../../services/registers/invoice-register.api.service';
import { IListData } from '@models';

export class RegistersDatasource extends TableDataSource<InvoiceRegister> {
  constructor(
    protected invoiceRegisterService: InvoiceRegisterApiService,
    protected invoiceService: InvoiceService,
  ) {
    super();
  }

  loadRegistries(
    filter: string,
    sortEvent: Sort = null,
    pageIndex: number,
    pageSize,
  ): void {
    this.loading.next(true);
    this.getRegistries(false, pageIndex + 1, pageSize)
      .pipe(
        catchError(() => of(new ListDataResponse<InvoiceRegister>())),
        finalize(() => this.loading.next(false)),
      )
      .subscribe((registries) => {
        this.total.next(Number(registries.total) || 0);
        this.totalInCurrentSearch.next(registries.list.length);

        this.source.next(registries.list || []);
      });
  }

  loadCustomRegistries(registries: InvoiceRegister[]) {
    this.total.next(Number(registries.length) || 0);
    this.source.next(registries || []);
  }

  loadInvoices(
    filter: string,
    sortEvent: Sort = null,
    pageIndex: number = 0,
    pageSize = 100,
    registerId: string,
  ) {
    const dbFilter = { type: 'register', registerId };

    return this.invoiceService
      .getInvoiceList(dbFilter, null, pageIndex + 1, pageSize)
      .pipe(take(1));
  }

  getRegistries(
    isOpen: boolean = false,
    pageIndex: number = 1,
    pageSize: number = 100,
  ): Observable<IListData<InvoiceRegister>> {
    return this.invoiceRegisterService.getList(
      { isOpen },
      null,
      pageIndex,
      pageSize,
    );
  }
}

<div [formGroup]="form">
  <div class="method-container">
    <np-inv-type-radio-group [formControlName]="'PaymentMethod'"
                             [types]="types"
                             [disabled]="disableRadio$ | async">
    </np-inv-type-radio-group>
    <div class="control-icons control-icons_help">
      <div class="control-icons__item">
        <div class="material-tooltip">
          <i class="material-icons icon-help material-tooltip__btn cursor-pointer">help_outline</i>
          <div class="material-tooltip__content">Безготівковий розрахунок для відправника або отримувача доступний лише
                                                 за умови підписання договору.
            <a href="https://novaposhta.ua/payment_korp_clients " target="_blank">Детальніше про послугу</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { ContentChild, Directive, ElementRef } from '@angular/core';
import { Unsubscriber } from '@shared';

import { BehaviorSubject, filter, fromEvent, map, Subscription, take } from 'rxjs';

// This directive handles iframe loading state
// And returns loading flag
// Html iframe element needs #iframe
// ------------------------
//  <ng-container iframeLoadHandler #iframeLoadHandler="iframeLoadHandler">
//    <mat-progress-bar *ngIf="iframeLoadHandler.loading"></mat-progress-bar>
//    <iframe #iframe [style.opacity]="+!iframeLoadHandler.loading"></iframe>
//  </ng-container>

@Directive({
  selector: '[iframeLoadHandler]',
  exportAs: 'iframeLoadHandler',
})
export class IframeLoadHandlerDirective extends Unsubscriber {
  constructor() {
    super();
  }

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  loading$ = this.loading.asObservable();
  private _iframeRef: ElementRef | null = null;

  @ContentChild('iframe') set iframeRef(iframeRef: ElementRef | null) {
    if (!iframeRef) {
      return;
    }
    this._iframeRef = iframeRef;
    this.onLoadIframe();
  }

  get iframeRef(): ElementRef | null {
    return this._iframeRef;
  }

  onLoadIframe(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());

    this.subscriptions = fromEvent(this.iframeRef!.nativeElement as HTMLIFrameElement, 'load')
      .pipe(
        map(e => e.target as HTMLIFrameElement),
        filter(target => target?.nodeName === 'IFRAME'),
        filter(t => t.ownerDocument.readyState === 'complete' || t.ownerDocument.readyState === 'interactive'),
        take(1),
      )
      .subscribe(() => this.loading.next(false));
  }
}

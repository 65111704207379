import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AbstractFormContainer, getError, TypedFormControl } from '@shared';
import { AuthService } from '../../../../../services/auth.service';
import {
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '../../../../../models/autocomplete-with-controls.model';
import { ErrorStateMatcher } from '@angular/material/core';
import { CitySearchResult, WarehouseResult } from '@models';
import { WarehouseMaxLength } from '../../../../../core/components/warehouse-select/warehouse-select.constants';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'np-inv-recommendation-warehouses',
  templateUrl: './recommendation-warehouses.component.html',
  styleUrls: ['./recommendation-warehouses.component.scss'],
})
export class RecommendationWarehousesComponent
  extends AbstractFormContainer
  implements TypedFormControl, OnInit
{
  readonly type = 'RecommendationWarehouses';
  protected readonly getError = getError;
  protected readonly maxLength = WarehouseMaxLength;

  label = 'Відділення';

  editForm: UntypedFormGroup;

  @Input()
  controlName = 'AddressSender';

  @Input()
  invoiceForm: UntypedFormGroup;

  @Input()
  controlTypeName = 'type';

  @Input()
  templateValidation: boolean;

  @Input()
  configCity: AutocompleteWithControlsConfig = {
    ariaLabel: 'Населений пункт',
    placeholder: 'Населений пункт',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configWarehouse: AutocompleteWithControlsConfig = {
    ariaLabel: 'Відділення',
    placeholder: 'Відділення',
    arrow: false,
    errorStateMatcher: null,
  };

  @Input()
  focused: boolean;
  errors: Record<string, string> = {
    required: 'Це поле обов\'язкове',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
  ) {
    super();
  }

  get warehouse(): FormControl{
    return this.editForm.get('warehouse') as FormControl;
  }

  private _warehouseOptions: Array<AutoCompleteOptionItem<WarehouseResult>> = [];

  @ViewChild('input', { read: ElementRef, static: false }) input: ElementRef;

  get warehouseOptions(): any {
    return this._warehouseOptions;
  }

  @Input() set warehouseOptions(value) {
    if (value.length) {
      this._warehouseOptions = value;
      this.cd.markForCheck();
    }
  }

  private _recipientCity: CitySearchResult;

  get recipientCity(): CitySearchResult {
    return this._recipientCity;
  }

  @Input() set recipientCity(city: CitySearchResult) {
    this._recipientCity = city;
  }

  get user() {
    return this.authService.user;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.warehouse.enable();
  }

  warehouseTransformFn: AutoCompleteTransformFn<WarehouseResult> = (
    value: WarehouseResult,
  ) => {
    if (value === null) {
      return '';
    }
    return typeof value === 'string' ? value : value.Description || '';
  };

  addFormControls(): void {
    if (this.form) {
      this.createControl();
    }
  }
  
  createControl(): void {
    this.editForm = this.fb.group({
      warehouse: ['', [Validators.required]],
    });
    this.form.setControl(this.controlName, this.editForm);
    this.form.get(this.controlTypeName).setValue(this.type);
  }

  warehouseOptionsFilterFn: AutocompleteOptionsFilterFn<WarehouseResult> = (
    value: string | WarehouseResult,
    options: Array<AutoCompleteOptionItem<WarehouseResult>>,
  ) => {
    const format = (y) =>
      y.replace(/[\s\+\-.,:!?#@'"()\[\]()'"-]+/g, '').toLowerCase();
    const group = (warehouse: WarehouseResult) =>
      `${warehouse.Description || ''}${warehouse.DescriptionRu || ''}`.trim();
    const v = format(typeof value === 'string' ? value : group(value));
    return options.filter((el) => format(group(el.option)).includes(v));
  };

  blur(): void {
    this.input.nativeElement.blur();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractFormContainer } from '@shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-floors-lifting',
  templateUrl: './floors-lifting.component.html',
  styleUrls: ['./floors-lifting.component.scss'],
})
export class FloorsLiftingComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  FloorMax = 99;
  FloorMin = 1;

  @Input() controlName = 'FloorsLiftingData';
  @Input() showTooltipFloor = false;
  @Input() showTooltipElevator = false;

  floorsLiftingGroup: UntypedFormGroup;

  floorsLiftingDataGroup: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    this.floorsLiftingGroup = this.form.get('AdditionalServices').get('floorsLifting') as UntypedFormGroup;
    this.floorsLiftingDataGroup = this.formBuilder.group({
      NumberOfFloorsLifting: ['', Validators.required],
      Elevator: [false, []],
    });
    this.floorsLiftingGroup.addControl(this.controlName, this.floorsLiftingDataGroup);
  }

  ngOnDestroy() {
    this.floorsLiftingGroup.removeControl(this.controlName);
  }
}

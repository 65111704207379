import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartialSuccessComponent } from './partial-success.component';
import { PartialSuccessConfig, PartialSuccessResponse } from './partial-success.model';

@Injectable({
  providedIn: 'root',
})
export class PartialSuccessService {
  constructor(private dialog: MatDialog) {
  }

  partialSuccessRef: MatDialogRef<PartialSuccessComponent> | null = null;

  open(data: Partial<PartialSuccessResponse>, config: Partial<PartialSuccessConfig>): void {
    this.partialSuccessRef = this.dialog.open(PartialSuccessComponent, {
      data: {
        data,
        config,
      },
      width: '649px',
      maxWidth: '649px',
      maxHeight: 'min(600px, calc(100% - 80px))',
      height: '100%',
      panelClass: 'no-padding',
      autoFocus: false,
    });
  }
}

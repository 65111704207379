import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concatMap, map} from 'rxjs';
import {PaymentInfo} from '../../../../models/acquiring/payment-info';
import {Invoice} from '../../../../models/invoice/invoice.model';
import {NoRestApiHelper} from '../../../../services/no-rest-api.helper';
import {AuthService} from '../../../../services/auth.service';
import {Observable} from 'rxjs';
import {ConfigService} from '../../../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AcquiringApiService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthService,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {

  }

  getPaymentInfo(invoices: Invoice[]): Observable<PaymentInfo> {

    let requestBody: any;

    if (invoices[0].payer === 'Sender') {
      requestBody = {
        modelName: 'Payment',
        calledMethod: 'getSenderPaymentInfo',
        methodProperties: {
          Documents: invoices.map(invoice => invoice.docNumber),
          Language: 'uk',
        },
        system: this.configService.get('system'),
      };
    } else {
      requestBody = {
        modelName: 'Payment',
        calledMethod: 'getRecipientPaymentInfo',
        methodProperties: {
          Document: invoices[0].docNumber,
          Language: 'uk',
        },
        system: this.configService.get('system'),
      };
    }

    return this.http
      .post(
      `${this.configService.get('apiUrl')}json/`,
      requestBody,
      ).pipe(
      map(data => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(data => {
        data = data.data.data[0];

        try {
          return Object.assign(
            new PaymentInfo(),
            {
              payeeId: data.Parameters.payee_id.toString(),
              billAmount: data.Parameters.bill_amount || invoices[0].deliveryPrice,
              description: data.Parameters.description,
              successUrl: data.Parameters.success_url,
              failureUrl: data.Parameters.failure_url,
              lang: data.Parameters.lang,
              preauthFlag: data.Parameters.preauth_flag,
              encoding: data.Parameters.encoding,
              serviceAction: data.ServiceAction,
            } as PaymentInfo,
          );
        } catch (e) {
        }
      },
      ),
      concatMap(paymentInfo => {
        let requestBody: any;

        if (invoices[0].payer === 'Sender') {
          requestBody = {
            modelName: 'Payment',
            calledMethod: 'CreateDraftPaymentOnSite',
            methodProperties: {
              Documents: invoices.map(invoice => invoice.docNumber),
              Language: 'uk',
            },
            system: this.configService.get('system'),
          };
        } else {
          requestBody = {
            modelName: 'Payment',
            calledMethod: 'CreateDraftPaymentOnSiteAlreadyPaid',
            methodProperties: {
              Document: invoices[0].docNumber,
              PaymentSystem: 'ed0cf178-5d63-42c0-80a1-5f5014bd94cc',
            },
            system: this.configService.get('system'),
          };
        }

        return this.http
          .post(
          `${this.configService.get('apiUrl')}json/`,
          requestBody,
          ).pipe(
          map(data => {
            return this.noRestApiHelper.checkErrors({ data });
          }),
          map(data => {
            data = data.data.data[0];
            paymentInfo.shopOrderNumber = data.DraftRef;

            return paymentInfo;
          }));
      }));
  }

  cancelPayment(docNumbers: string[]): Observable<string[]> {
    const requestBody = {
      modelName: 'Payment',
      calledMethod: 'cancelPayment',
      methodProperties: {
        Documents: docNumbers,
        Force: true,
      },
      system: this.configService.get('system'),
    };

    return this.http
      .post(
      `${this.configService.get('apiUrl')}json/`,
      requestBody,
      ).pipe(
      map(data => {
        return this.noRestApiHelper.checkErrors({ data });
      }),
      map(data => data.data.info.arDocuments || [],
      ))
    ;
  }
}

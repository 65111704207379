
import { ContactPerson, ContactType } from "@models";

export function getContactType(contactPerson: object): ContactType {
  if (typeof contactPerson !== 'object') {
    return 'PrivatePerson';
  } else if (
    typeof contactPerson === 'object' &&
    (contactPerson as ContactPerson).CounterpartyDesctiption ===
      'Приватна особа'
  ) {
    return 'PrivatePerson';
  } else {
    return 'Organization';
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StfAccordeonItemComponent} from './stf-accordeon-item/stf-accordeon-item.component';
import {StfAccordeonComponent} from './stf-accordeon/stf-accordeon.component';
import {MaterialModule} from '../../../material';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [StfAccordeonComponent, StfAccordeonItemComponent],
  exports: [StfAccordeonComponent, StfAccordeonItemComponent],
})
export class NgStfAccordeonModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material';
import { CoreModule } from '../../../../core/core.module';
import { InvoiceDirectivesModule } from '../../../../shared/directives/invoice-directives/invoice-directives.module';
import { TimeIntervalsComponent } from '../time-intervals/time-intervals.component';
import { AfterpaymentComponent } from './afterpayment/afterpayment.component';
import { BackDeliveryComponent } from './back-delivery/back-delivery.component';
import { BackDocumentsComponent } from './back-documents/back-documents.component';
import { CustomBackwardsDeliveryAddressComponent } from './custom-backwards-delivery-address/custom-backwards-delivery-address.component';
import { CustomReturnAddressComponent } from './custom-return-address/custom-return-address.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { DeliveryByHandComponent } from './delivery-by-hand/delivery-by-hand.component';
import { DeliveryCostCheckPopupComponent } from './delivery-cost-check-popup/delivery-cost-check-popup.component';
import { DifferentDocumentsComponent } from './different-documents/different-documents.component';
import { StampCheckboxesComponent } from './different-documents/stamp-checkboxes/stamp-checkboxes.component';
import { DocumentsSignedComponent } from './documents-signed/documents-signed.component';
import { DoorstepPickupComponent } from './doorstep-pickup/doorstep-pickup.component';
import { FloorsLiftingComponent } from './floors-lifting/floors-lifting.component';
import { ForwardingCountComponent } from './forwarding-count/forwarding-count.component';
import { LocalExpressModule } from './local-express/local-express.module';
import { PackingServiceComponent } from './packing-service/packing-service.component';
import { PackingService } from './packing-service/packing-service.service';
import { PreferredDateComponent } from './preferred-date/preferred-date.component';
import { SecurePaymentComponent } from './secure-payment/secure-payment.component';

const COMPONENTS = [
  BackDeliveryComponent,
  ForwardingCountComponent,
  FloorsLiftingComponent,
  PreferredDateComponent,
  DateTimeComponent,
  TimeIntervalsComponent,
  DeliveryByHandComponent,
  BackDocumentsComponent,
  PackingServiceComponent,
  CustomReturnAddressComponent,
  CustomBackwardsDeliveryAddressComponent,
  DeliveryCostCheckPopupComponent,
  AfterpaymentComponent,
  DocumentsSignedComponent,
  DifferentDocumentsComponent,
  StampCheckboxesComponent,
  SecurePaymentComponent,
  DoorstepPickupComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    InvoiceDirectivesModule,
    LocalExpressModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [PackingService],
})
export class AdditionalServicesModule {
}

import { Sort } from '@angular/material/sort';
import {
  compareByNestedKey,
  compareEmptyNumbers,
  compareNumbers,
  compareStringDates,
  compareStrings,
  compareStringWithoutRegister,
} from '@shared';

export const DisplayedColumns = [
  'select',
  'docNumber',
  'status',
  'scanSheetNumber',
  'date',
  'estimatedDeliveryDate',
  'cost',
  'price',
  'weight',
  'amount',
  'backDelivery',
  'paymentControl',
  'lightReturn',
  'barcode',
  'senderDescription',
  'senderContactPerson',
  'SendersPhone',
  'citySenderDescription',
  'senderAddressDescription',
  'counterpartyRecipientDescription',
  'receiver',
  'receiverPhone',
  'receiverCity',
  'address',
  'payerType',
  'options',
];

export const ColsShowConfig = new Map<string, string>([
  ['select', 'begin'],
  ['docNumber', 'begin'],
  ['options', 'end'],
]);

export const PayerNameTransations = {
  Sender: 'Відправник',
  Recipient: 'Отримувач',
  ThirdPerson: 'Третя особа',
};

export const ColsConfRun = [
  {
    name: 'Статус',
    show: true,
    class: 'status',
  },
  {
    name: 'Номер реєстру',
    show: true,
    class: 'scanSheetNumber',
  },
  {
    name: 'Дата створення',
    show: true,
    class: 'date',
  },
  {
    name: 'Плановий час доставки',
    show: true,
    class: 'estimatedDeliveryDate',
  },
  {
    name: 'Оголошена вартість',
    show: true,
    class: 'cost',
  },
  {
    name: 'Вартість доставки',
    show: true,
    class: 'price',
  },
  {
    name: 'Вага',
    show: true,
    class: 'weight',
  },
  {
    name: 'Кількість місць',
    show: true,
    class: 'amount',
  },
  {
    name: 'Грошовий переказ',
    show: true,
    class: 'backDelivery',
  },
  {
    name: 'Сейф-сервіс',
    show: false,
    class: 'securePayment',
  },
  {
    name: 'Контроль оплати',
    show: true,
    class: 'paymentControl',
  },
  {
    name: 'Внутрішній номер замовлення',
    show: true,
    class: 'barcode',
  },
  {
    name: 'Легке повернення посилки',
    show: true,
    class: 'lightReturn',
  },
  {
    name: 'Відправник',
    show: true,
    class: 'senderDescription',
  },
  {
    name: 'Контакт відправника',
    show: true,
    class: 'senderContactPerson',
  },
  {
    name: 'Телефон відправника',
    show: true,
    class: 'SendersPhone',
  },
  {
    name: 'Місто відправлення',
    show: true,
    class: 'citySenderDescription',
  },
  {
    name: 'Адреса відправлення',
    show: true,
    class: 'senderAddressDescription',
  },
  {
    name: 'Отримувач',
    show: true,
    class: 'counterpartyRecipientDescription',
  },
  {
    name: 'Контакт отримувача',
    show: true,
    class: 'receiver',
  },
  {
    name: 'Телефон отримувача',
    show: true,
    class: 'receiverPhone',
  },
  {
    name: 'Місто отримувача',
    show: true,
    class: 'receiverCity',
  },
  {
    name: 'Адреса отримання',
    show: true,
    class: 'address',
  },
  {
    name: 'Платник за доставку',
    show: true,
    class: 'payerType',
  },
];

export class RegisterShowCalculation {
  constructor(
    public totalWeight: number = 0,
    public totalPrice: number = 0,
    public totalCost: number = 0,
    public totalCheckCost: number = 0,
    public totalCheckWeight: number = 0,
    public totalBackward: number = 0,
    public totalAfterpayment: number = 0,
    public senderPrice: number = 0,
    public senderPriceCheck: number = 0,
  ) {
  }
}

export const IregisterDefaultSortEvent: Sort = {
  active: 'date',
  direction: 'desc',
};

export const IregisterSortConf = {
  docNumber: compareByNestedKey(compareNumbers, 'IntDocNumber'),
  status: compareByNestedKey(compareNumbers, 'state', 'id'),
  scanSheetNumber: compareByNestedKey(compareStrings, 'ScanSheetNumber'),
  weight: compareByNestedKey(compareNumbers, 'Weight'),
  amount: compareByNestedKey(compareNumbers, 'SeatsAmount'),
  date: compareByNestedKey(compareStringDates, 'CreateTime'),
  estimatedDeliveryDate: compareByNestedKey(
    compareStringDates,
    'EstimatedDeliveryDate',
  ),
  senderDescription: compareByNestedKey(compareStrings, 'SenderDescription'),
  senderContactPerson: compareByNestedKey(
    compareStrings,
    'SenderContactPerson',
  ),
  SendersPhone: compareByNestedKey(compareNumbers, 'SendersPhone'),
  citySenderDescription: compareByNestedKey(
    compareStrings,
    'CitySenderDescription',
  ),
  senderAddressDescription: compareByNestedKey(
    compareStrings,
    'SenderAddressDescription',
  ),
  recipientDescription: compareByNestedKey(
    compareStrings,
    'RecipientDescription',
  ),
  receiver: compareByNestedKey(compareStrings, 'RecipientContactPerson'),
  receiverPhone: compareByNestedKey(compareNumbers, 'RecipientContactPhone'),
  receiverCity: compareByNestedKey(
    compareStringWithoutRegister,
    'CityRecipientDescription',
  ),
  lightReturn: compareByNestedKey(compareStrings, 'LightReturnNumber'),
  address: compareByNestedKey(compareStrings, 'RecipientAddressDescription'),
  description: compareByNestedKey(compareStrings, 'description'),
  payerType: compareByNestedKey(compareStringWithoutRegister, 'PayerType'),
  barcode: compareByNestedKey(compareStrings, 'InfoRegClientBarcodes'),
  cost: compareByNestedKey(compareNumbers, 'Cost'),
  price: compareByNestedKey(compareNumbers, 'CostOnSite'),
  backDelivery: compareByNestedKey(
    compareEmptyNumbers,
    'BackwardDeliveryMoney',
  ),
  securePayment: compareByNestedKey(compareNumbers, 'securePayment'),
  card: compareByNestedKey(
    compareStrings,
    'RedeliveryPaymentCard',
    'CardMaskedNumber',
  ),
  paymentControl: compareByNestedKey(
    compareEmptyNumbers,
    'AfterpaymentOnGoodsCost',
  ),
  isPayPaid: compareByNestedKey(compareStrings, 'isPayPaid'),
  marketplace: compareByNestedKey(
    compareStrings,
    'MarketplacePartnerDescription',
  ),
  counterpartyRecipientDescription: compareByNestedKey(
    compareStrings,
    'RecipientDescription',
  ),
};

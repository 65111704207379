import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { distinctUntilChanged, filter, switchMap } from 'rxjs';
import { ContactsService } from '../../../../services/contacts/contacts.service';
import {
  AlertToastService,
  AuthService,
  GoogleAnalyticService,
} from '@services';
import { AutoCompleteOptionItem } from '@autocomplete-with-controls';
import { AddressRecipientComponent } from '../address-recipient/address-recipient.component';
import { ContactRecipientComponent } from '../contact-recipient/contact-recipient.component';
import { TypeRadioButton } from '@type-radio-group';
import { getAddressType } from '@shared';
import {
  AddressType,
  ContactType,
  DoorAddress,
  WarehouseAddress,
} from '@models';

@Component({
  selector: 'np-inv-recipient',
  templateUrl: 'recipient.component.html',
  styleUrls: ['./recipient.component.scss'],
})
export class RecipientComponent implements OnInit, OnDestroy {
  @Input()
  form: UntypedFormGroup;

  @Input()
  invoiceForm: UntypedFormGroup;

  @Input()
  contactFormName = 'ContactRecipient';

  @Input()
  contactActiveType: ContactType = 'PrivatePerson';

  @Input()
  addressFormName = 'AddressRecipient';

  @Input()
  addressActiveType: AddressType = 'Warehouse';

  focused = false;

  @Input() invoiceId: string;

  @ViewChild(ContactRecipientComponent, {
    static: false,
    read: ContactRecipientComponent,
  })
  contactRecipientComponent: ContactRecipientComponent;

  @ViewChild(AddressRecipientComponent, {
    static: false,
    read: AddressRecipientComponent,
  })
  addressRecipientComponent: AddressRecipientComponent;

  addressTypes: Array<TypeRadioButton<AddressType>> = [
    {
      value: 'Warehouse',
      label: 'Відділення',
    },
    {
      value: 'Doors',
      label: 'Адреса',
    },
  ];

  @Input()
  addressOptions: Array<
    AutoCompleteOptionItem<DoorAddress | WarehouseAddress>
  > = [];

  @Input()
  selectedAddress: any;

  clearFunction = false;

  @Input()
  removedContact = null;

  organizationDelegateDisabled = false;

  constructor(
    private authService: AuthService,
    private contactsService: ContactsService,
    private googleAnalyticsService: GoogleAnalyticService,
    private fb: UntypedFormBuilder,
    private alertToastService: AlertToastService,
  ) {}

  ngOnInit(): void {
    if (this.form) {
      this.form.valueChanges
        .pipe(
          filter(() => this.form.contains('AddressRecipient')),
          switchMap(() => this.form.controls.AddressRecipient.valueChanges),
          distinctUntilChanged(),
        )
        .subscribe((value) => this.toggleOrganizationDelegate(value));
    }
  }

  recipientAddressTypeIsDoors(value: any): boolean {
    return value.type === 'Doors';
  }

  toggleOrganizationDelegate(value): void {
    if (!value) {
      return;
    }

    const doors = this.recipientAddressTypeIsDoors(value);
    const recipientCounterpartyIsOrganizationDelegate =
      this.form.get('RecipientCounterpartyType').value ===
      'OrganizationDelegate';
    this.organizationDelegateDisabled = doors;

    if (doors && recipientCounterpartyIsOrganizationDelegate) {
      this.form.get('RecipientCounterpartyType').setValue('Organization');
      this.alertToastService.pushWarn(
        'Для здійснення адресної доставки необхідно вказати ПІБ отримувача',
      );
    }
  }

  onRecipientSelected() {
    this.clearFunction = true;
    const recipient = this.form.get('Recipient')?.value || null;
    const addresses = recipient?.Addresses
      ? [
          ...(recipient.Addresses.WarehouseAddresses || []),
          ...(recipient.Addresses.DoorsAddresses || []),
        ]
      : [];
    this.addressOptions = addresses.map(
      (address) =>
        ({
          option: address,
          config: { disabled: false, tooltip: '' },
        } as AutoCompleteOptionItem<DoorAddress | WarehouseAddress>),
    );

    this.contactRecipientComponent?.createControl(recipient);

    if (this.addressRecipientComponent) {
      if (!this.addressOptions.length) {
        this.addressRecipientComponent.changeMode({ triggerName: 'create' });
      } else if (this.addressRecipientComponent.mode === 'create') {
        this.addressRecipientComponent.cancel();
      }
      this.addressRecipientComponent.mode = this.addressOptions.length
        ? 'select'
        : 'create';
      this.form.get('AddressRecipient').patchValue({
        mode: this.addressOptions.length ? 'select' : 'create',
        type: this.addressOptions.length
          ? getAddressType(this.addressOptions[0].option)
          : 'Warehouse',
        data: this.addressOptions.length
          ? this.addressOptions[0].option
          : { city: '', warehouse: '' },
      });
      this.form
        .get('AddressRecipient')
        .updateValueAndValidity({ onlySelf: true });
    }
    if (recipient) {
      this.focused = true;
    }
    this.googleAnalyticsService.track(
      'analitics',
      `second-step.changeRecipient`,
      {
        eventLabel: `Змінено контакт отримувача`,
        eventCategory: 'create-invoice',
        eventAction: 'change',
      },
    );
  }

  ngOnDestroy(): void {}
}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IApiError } from './api-helper';

// import * as jsonErrors from './api-norest-errors.json';

@Injectable({
  providedIn: 'root',
})
export class NoRestApiHelper {
  errorsList: any;

  constructor() {
    // this.errorsList = require('./api-norest-errors.json');
    this.errorsList = [{ error: 'ERROR JSON' }];
  }

  getFormatedDateForRequest(date: Date): string {
    return `${date.getDate() > 9 ? date.getDate() : '0' +
      date.getDate().toString()}.${date.getMonth() > 8 ? (date.getMonth() + 1) : '0' +
      (date.getMonth() + 1).toString()}.${date.getFullYear()}`;
  }

  replaceErrorCodeOnCustom(errorCode: string): string {
    return this.errorsList[errorCode] ? this.errorsList[errorCode] : errorCode;
  }

  checkErrors(data: any): any {
    if (data?.data?.success === false) {

      const errors: IApiError[] = [];

      if (
        typeof data.data.errors === 'object' &&
        typeof data.data.errorCodes === 'object' &&
        typeof data.data.translatedErrors === 'object' &&
        data.data.errorsObject
      ) {
        Object.keys(data.data.errorsObject).map(key => {
          errors.push({
            messageCode: data.data.errorCodes?.[key] || '',
            message: data.data.errorCodes?.[key] || '',
            errorCode: data.data.errorCodes?.[key] || '',
            addInfo: data.data.errorsObject[key],
            translatedError: data.data.translatedErrors?.[key] || data.data.errors?.[key] || '',
          });
        });
        throw errors;
      } else {
        if (typeof data.data.errors === 'object') {

          const arr: any[] = [];
          for (const key in data.data.errors) {
            arr.push(data.data.errors[key]);

          }
          data.data.errors = arr;
        }
        _.each(data.data.errors, (e: string, key: number) => {
          errors.push({
            messageCode: data.data.errorCodes?.[key] || '',
            message: data.data.errorCodes?.[key] || '',
            errorCode: data.data.errorCodes?.[key] || '',
            addInfo: e,
            translatedError: data.data.translatedErrors?.[key] || data.data.errors?.[key] || '',
          });
        });

        throw errors;
      }
    }
    return data;
  }

  checkWarnings(data: any): any {
    const errors: IApiError[] = [];

    if (typeof data.data.warningCodes === 'object') {

      const arr: any[] = [];
      for (const key in data.data.warningCodes) {
        arr.push(data.data.warningCodes[key]);

      }
      data.data.warningCodes = arr;

    }

    if (typeof data.data.warnings === 'object') {

      const arr: any[] = [];
      for (const key in data.data.warnings) {
        arr.push(data.data.warnings[key]);

      }
      data.data.warnings = arr;

    }

    _.each(data.data.warningCodes, (e: string, key: number) => {
      errors.push({
        messageCode: e,
        message: e,
        errorCode: e,
        addInfo: data.data.warnings[key],
      });
    });

    if (errors.length === 0 && data.data.warnings.length) {
      _.each(data.data.warnings, (e: string) => {
        errors.push({
          messageCode: e,
          message: e,
          errorCode: e,
        });
      });

    }

    if (errors.length) {
      throw errors;
    }
    return data;
  }
}

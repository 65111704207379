import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Unsubscriber } from './unsubscriber';

@Directive()
export abstract class SmartFormContainer extends Unsubscriber implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  @Input() controlName = '';

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      this.addFormControls();
    }
  }

  ngOnDestroy(): void {
    if (this.form) {
      this.removeFormControls();
    }
  }

  addFormControls(): void {
    if (this.form) {
      this.form.addControl(this.controlName, this.fb.control(null));
    }
  }

  removeFormControls(): void {
    if (this.form) {
      this.form.removeControl(this.controlName);
    }
  }
}

<div *ngIf="edit?.enabled || create?.enabled">
  <div *ngIf="create?.enabled">
    <button mat-icon-button
            class="control-btn"
            type="button"
            [disabled]="disabled"
            (click)="triggerControl('create')">
      <mat-icon class="material-icons_red"
                [matTooltip]="create?.tooltip"
                matTooltipPosition="below">
        add_circle_outline
      </mat-icon>
    </button>
  </div>

  <div *ngIf="edit?.enabled">
    <button mat-icon-button
            class="control-btn"
            type="button"
            [disabled]="disabled"
            (click)="triggerControl('edit')">
      <mat-icon class="material-icons_grey"
                [matTooltip]="edit?.tooltip"
                matTooltipPosition="below">
        edit
      </mat-icon>
    </button>
  </div>
</div>

/**
 * Created by andrey on 03.07.16.
 */
import { Model } from './base.model';
import { Contact } from './contact.model';
import { Locality } from './locality.model';
import { Street } from './street.model';

export interface IAddressFilterParam {
  contactId?: string;
  cityId?: string;
  onlyAdresses?: boolean;
  onlyWarehouse?: boolean;
  isNoPostomat?: boolean;
  ids?: string[];
}

export interface ISettlementFilterParam {
  name: string;
  hasWarehouse?: boolean;
}

export interface IStreetFilterParam {
  name: string;
  localityRef: string;
}

export type WarehouseTypeName = 'POSTBOX' | 'NEW_POST' | 'IN_POST' | 'OTHER';

export class Address extends Model {
  city: Locality;
  flat: string;
  building: string;
  street: Street;
  note: string;
  contactPerson: Contact;
  contactPersonRef: string;
  noteAddressRecipient: string;
  floor: string;
  general: string;
  warehouseNumber: string;
  shortDescription: string;
  placeMaxWeightAllowed = 1000;
  nonCash?: boolean;

  protected _type: string;

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  _totalMaxWeightAllowed = 1000;

  get totalMaxWeightAllowed() {
    return this._totalMaxWeightAllowed;
  }

  set totalMaxWeightAllowed(weight: number) {
    this._totalMaxWeightAllowed = weight;
  }

  protected _typeWarehouse: WarehouseTypeName;

  get typeWarehouse(): WarehouseTypeName {
    return this._typeWarehouse;
  }

  set typeWarehouse(type: WarehouseTypeName) {
    this._typeWarehouse = type;
  }

  protected _description: string;

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;

    if (!this._description) {
      this._description = `${
        this.city
          ? (this.city.typeName ? this.city.typeName + ' ' : '') +
            this.city.name
          : ''
      }, ${
        this.street
          ? (this.street.type ? this.street.type + '' : '') + this.street.name
          : ''
      }  ${this.building ? this.building : ''}${
        this.flat ? ', кв. ' + this.flat : ''
      }`;
    }
  }
}

export enum AddressTypes {
  WAREHOUSE = 'Warehouse',
  DOORS = 'Doors',
  POSTBOX = 'Postbox',
  INDEX = 'Index',
  RECOMMENDATION_WAREHOUSES = 'RecommendationWarehouses',
}

export type AddressType = AddressTypes | 'Warehouse' | 'Doors' | 'Postbox';

export interface DoorAddress {
  Ref: string;
  CityRef: string;
  SettlementRef: string;
  SettlementDescription: string;
  Type: string;
  RegionDescription: string;
  AreaDescription: string;
  StreetRef: string;
  StreetDescription: string;
  Description: string;
  BuildingNumber: string;
  Flat: string;
  Floor: string;
  Note: string;
  NoteAddressRecipient: string;
  AddressName: string;
  General: string;
  StreetsTypeRef: string;
  StreetsType: string;
}

export interface WarehouseAddress {
  Ref: string;
  CityRef: string;
  CityDescription: string;
  AddressDescription: string;
  WarehouseNumber: string;
  TypeOfWarehouse: string;
  General: string;
  TotalMaxWeightAllowed: string;
  PlaceMaxWeightAllowed: string;
  CategoryOfWarehouse?: CategoryOfWarehouseEnum;
}

export interface ApiAddress {
  SettlementRef: string;
  AddressRef: string;
  AddressType: AddressTypes;
  BuildingNumber?: string;
  Flat?: string;
  Floor?: string;
  Note?: string;
  General?: string;
}

export interface CitySearchResponse {
  TotalCount: number;
  Addresses: CitySearchResult[];
}

export interface StreetSearchResponse {
  TotalCount: number;
  Addresses: StreetSearchResult[];
}

export interface CitySearchResult {
  Area: string;
  DeliveryCity: string;
  MainDescription: string;
  ParentRegionCode: string;
  ParentRegionTypes: string;
  Present: string;
  Ref: string;
  Region: string;
  RegionTypes: string;
  RegionTypesCode: string;
  SettlementTypeCode: string;
  StreetsAvailability: boolean;
  Warehouses: number;
}

export interface StreetSearchResult {
  Location: string;
  Present: string;
  SettlementRef: string;
  SettlementStreetDescription: string;
  SettlementStreetDescriptionRu: string;
  SettlementStreetRef: string;
  StreetsType: string;
  StreetsTypeDescription: string;
}

export interface WarehouseResult {
  CategoryOfWarehouse: CategoryOfWarehouseEnum;
  Description: string;
  DescriptionRu: string;
  Ref: string;
  PlaceMaxWeightAllowed: number;
  TotalMaxWeightAllowed: number;
  TypeOfWarehouse: string;
  SettlementTypeDescription?: string;
  CityDescription: string;
  SettlementRef: string;
  SettlementPresent: string;
  PostomatFor: 'Sender' | string;
  WarehouseIndex: string;
  WarehouseOwner: string;
  disabled?: boolean;
  tooltip?: string;
}

export enum CategoryOfWarehouseEnum {
  Warehouse = 'Warehouse',
  Postomat = 'Postomat',
  Store = 'Store',
}

import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AutocompleteWithControlsConfig } from '@autocomplete-with-controls';
import { CitySearchResult } from '@models';
import { cityResultMaskValidator, FormContainer, StreetMaskValidator, TypedFormControl } from '@shared';
import {
  AddressNoteMaxLength,
  AddressNotePattern,
} from '../../../../core/components/address-note/address-note.constants';
import {
  BuildingClasses,
  BuildingMaxLength,
} from '../../../../core/components/building/building.constants';
import { BuildingComponent } from '../../../../core/components/building/building.component';
import { StreetSelectComponent } from '../../../../core/components/street-select/street-select.component';
import { CityClasses, StreetClasses } from '../../../../core/components/city-select/city-select.constants';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-address',
  templateUrl: 'address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent
  extends FormContainer
  implements OnInit, TypedFormControl, OnDestroy {
  readonly type = 'Doors';

  addressFormGroup: UntypedFormGroup;

  @Input() showFlat = true;

  @Input() showNote = true;

  @HostBinding('attr.data-test-id') a = 'AddressTypeDoors';

  @Input() controlName = 'DoorsAddress';

  @Input() controlTypeName = 'type';

  @Input() isTemplate = false;

  @Input() buildingClasses = BuildingClasses;

  @Input() cityClasses = CityClasses;

  @Input() streetClasses = StreetClasses;

  @Input()
  configBuilding: AutocompleteWithControlsConfig = {
    ariaLabel: 'Будинок',
    placeholder: 'Будинок',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configRoom: AutocompleteWithControlsConfig = {
    ariaLabel: 'Квартира',
    placeholder: 'Квартира',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  cityRef: string = '';

  @ViewChild(StreetSelectComponent, { read: ElementRef })
  streetComponent: ElementRef;

  @ViewChild(BuildingComponent, { read: ElementRef })
  buildingComponent: ElementRef;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  get city(): AbstractControl | null {
    return this.addressFormGroup.get('city');
  }

  get street(): AbstractControl | null {
    return this.addressFormGroup.get('street');
  }

  get building(): AbstractControl | null {
    return this.addressFormGroup.get('building');
  }

  get room(): AbstractControl | null {
    return this.addressFormGroup.get('room');
  }

  get note(): AbstractControl | null {
    return this.addressFormGroup.get('note');
  }

  get cityValue(): CitySearchResult | null {
    return this.addressFormGroup.get('city').value;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    if (this.form) {
      this.createControl();
    }
  }

  createControl(): void {
    this.addressFormGroup = this.fb.group({
      city: [
        '',
        [
          ...(this.isTemplate ? [] : [Validators.required]),
          cityResultMaskValidator(),
        ],
      ],
      street: ['', [Validators.required, StreetMaskValidator()]],
      building: [
        '',
        [Validators.required, Validators.maxLength(BuildingMaxLength)],
      ],
      room: [''],
      note: [
        '',
        [
          Validators.maxLength(AddressNoteMaxLength),
          Validators.pattern(AddressNotePattern),
        ],
      ],
    });
    this.street.disable();
    this.building.disable();
    this.room.disable();
    this.form.setControl(this.controlName, this.addressFormGroup);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.form.updateValueAndValidity();
    this.initFormSubscriptions();
  }

  initFormSubscriptions(): void {
    this.initCitySubscriptions();
    this.initStreetSubscriptions();
  }

  initCitySubscriptions(): void {
    this.subscriptions = this.city.statusChanges.subscribe((status) => {
      if (status === 'VALID' && typeof this.city?.value !== 'string') {
        this.street.reset('');
        this.loadStreets();
        this.street.enable();
        setTimeout(() =>
          this.streetComponent.nativeElement.querySelector('input').focus(),
        );
      } else if (status === 'INVALID' || this.isTemplate) {
        this.street.disable();
        this.street.reset('');
      }
    });
  }

  initStreetSubscriptions() {
    this.subscriptions = this.street.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.building.enable();
        this.room.enable();
        setTimeout(() =>
          this.buildingComponent.nativeElement.querySelector('input').focus(),
        );
      } else {
        this.building.reset('');
        this.building.disable();
        this.room.reset('');
        this.room.disable();
      }
    });
  }

  loadStreets(): void {
    this.cityRef = this.cityValue?.Ref;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form.removeControl(this.controlName);
    this.form.removeControl('Note');
  }
}

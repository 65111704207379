<ng-container [formGroup]="editForm">
  <app-autocomplete-with-controls data-test-id="city"
                                  [formControlName]="'city'"
                                  [size]="2"
                                  [createTemplate]="false"
                                  [formControlParent]="editForm.get('city')"
                                  [config]="configCity"
                                  [controlsConfig]="null"
                                  [options]="cityOptions"
                                  [transformFn]="cityTransformFn">
    <ng-template appAutocompleteOption let-option let-disabled="disabled" let-data="data">
      <div [matTooltip]="data.limitationText">
        {{ option.Present }}
      </div>
    </ng-template>
    <app-autocomplete-error
      *ngIf="!!errorMessages['city'] &&
            (editForm.get('city').touched ||
    editForm.get('city').dirty)">
      {{ errorMessages['city'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>

  <app-autocomplete-with-controls data-test-id="warehouse"
                                  [formControlName]="'warehouse'"
                                  [size]="6"
                                  [createTemplate]="false"
                                  [formControlParent]="editForm.get('warehouse')"
                                  [config]="configWarehouse"
                                  [controlsConfig]="null"
                                  [options]="warehouseOptions"
                                  [transformFn]="warehouseTransformFn"
                                  [optionsFilterFn]="warehouseOptionsFilterFn"
                                  (focus)="onFocus($event)">
    <ng-template appAutocompleteOption let-option let-disabled="disabled" let-data="data">
      <div [matTooltip]="data.limitationText">
        {{ option.Description }}
      </div>
    </ng-template>
    <app-autocomplete-error
      *ngIf="!!errorMessages['warehouse'] &&
            (editForm.get('warehouse').touched || editForm.get('warehouse').dirty)">
      {{ errorMessages['warehouse'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>
</ng-container>

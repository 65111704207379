import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AuthService, GoogleAnalyticService } from '@services';
import { LocalStorageService } from 'ngx-webstorage';
import { interval, Subscription } from 'rxjs';
import { Banner, Banners } from './banners';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnDestroy {
  bannerShown = true;
  bannerSubscription: Subscription;
  banners: Banner[];
  currentBanner: {
    item: Banner;
    index: number;
  };
  bannerInterval = 15000;
  @Output() closeBanner: EventEmitter<void> = new EventEmitter<void>();

  get user() {
    return this.authService.user;
  }

  constructor(
    protected storage: LocalStorageService,
    protected authService: AuthService,
    protected cd: ChangeDetectorRef,
    protected googleAnalyticService: GoogleAnalyticService,
  ) {
  }

  bannerClose(): void {
    this.bannerShown = false;
    this.storage.store('banner_shown', this.bannerShown);
    this.bannerSubscription?.unsubscribe();
    this.closeBanner.next();
  }

  prepareBanners(): void {
    this.banners = JSON.parse(JSON.stringify(Banners))
      .filter(banner => banner.visibleFor.some(i => i === this.user.loyaltyCardType));
  }

  changeBanner(banner: { item, index }, action: 'next' | 'prev', resetTimer: boolean = true): void {
    const newIndex = action === 'next' ? banner.index + 1 : banner.index - 1;
    if (this.banners[newIndex]) {
      this.currentBanner = {
        item: this.banners[newIndex],
        index: newIndex,
      };
    } else {
      this.currentBanner = action === 'next' ?
        {
          item: this.banners[0],
          index: 0,
        } :
        {
          item: this.banners[this.banners.length - 1],
          index: this.banners.length - 1,
        };
    }
    if (resetTimer) {
      this.initBanner();
    }
    this.cd.detectChanges();
  }

  initBanner(): void {
    this.bannerSubscription?.unsubscribe();
    this.bannerSubscription = interval(this.bannerInterval)
      .subscribe(() => this.changeBanner(this.currentBanner, 'next', false));
  }

  ngOnInit(): void {
    this.prepareBanners();
    this.currentBanner = {
      item: this.banners[0],
      index: 0,
    };
    const item = localStorage.getItem('ng2-webstorage|banner_shown');
    this.bannerShown = item === null
      ? true
      : item === 'true';
    if (this.bannerShown) {
      this.initBanner();
    } else {
      this.closeBanner.next();
    }
    this.cd.detectChanges();
  }

  trackAnalytic(label: string): void {
    this.googleAnalyticService.track('analitics', `banner-link.click`, {
      eventLabel: label,
      eventCategory: `app-banner`,
      eventAction: 'click',
    });
  }

  ngOnDestroy(): void {
    this.bannerSubscription?.unsubscribe();
  }

}

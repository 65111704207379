import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import {
  AuthService,
  ErrorMessages,
  ErrorTypeMessages,
  ValidationService,
} from '@services';
import { AbstractFormContainer, formatUAPhone } from '@shared';
import { ContactsService } from '../../../../../services/contacts/contacts.service';
import {
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { CatalogContact, ContactPerson, Contragent } from '@models';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'np-inv-contact-private-person',
  templateUrl: 'contact-private-person.component.html',
  styleUrls: ['./contact-private-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPrivatePersonComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  @Input()
  controlName = 'PrivatePersonContact';
  matcher = new MyErrorStateMatcher();

  config: AutocompleteWithControlsConfig = {
    ariaLabel: 'Місто',
    placeholder: 'Місто',
    arrow: false,
  };

  @Input()
  contactProperty = 'Recipient';

  @Input()
  templateValidation: boolean;

  @Input() invoiceId: string;

  @Input()
  IsEnabledCancel = false;

  @Input()
  isMiddleNameRequired = false;

  @Input()
  isOrganizationDelegate = false;

  @Input() initialContactValue: ContactPerson;

  @Input() clearOnPhoneChange = true;

  @Input() patchRecipientControlName: string;
  @Input() patchRecipientData: string;
  @Input() hintSubstitution = true;

  @Output()
  onCancel = new EventEmitter<string>();

  @Output()
  recipientSelected: EventEmitter<ContactPerson> = new EventEmitter();

  privatePersonFormGroup: UntypedFormGroup;

  contactOptions: ContactPerson[] = [];

  catalogOptions: CatalogContact[] = [];

  showNotFoundContacts = false;

  errors: ErrorTypeMessages = {
    phone: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'minlength', message: "Це поле обов'язкове" },
    ],
    lastName: [{ key: 'required', message: "Це поле обов'язкове" }],
    firstName: [{ key: 'required', message: "Це поле обов'язкове" }],
    middleName: [{ key: 'required', message: "Це поле обов'язкове" }],
  };

  errorMessages: ErrorMessages = {};

  errorsSubscription: Subscription;

  changesSubscription: Subscription;
  lastNameAutocompleteConfig: AutocompleteWithControlsConfig = {
    ariaLabel: 'Прізвище',
    placeholder: 'Прізвище',
    arrow: true,
  };
  @ViewChild('lastNameInput') lastNameInput: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private contactService: ContactsService,
    private authService: AuthService,
    private validationService: ValidationService,
  ) {
    super();
  }

  get phoneControl(): AbstractControl {
    return this.privatePersonFormGroup.get('phone');
  }

  get lastNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('lastName');
  }

  get firstNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('firstName');
  }

  get middleNameControl(): AbstractControl {
    return this.privatePersonFormGroup.get('middleName');
  }

  get recipientTypeControl(): AbstractControl {
    return this.form.get('RecipientCounterpartyType');
  }

  transformFn: AutoCompleteTransformFn<ContactPerson> = (
    value: string | ContactPerson,
  ) =>
    typeof value === 'string'
      ? value
      : `${value.Description || ''} ${formatUAPhone(value.Phones || '')}`;

  ngOnInit() {
    super.ngOnInit();
    this.initLastNameSubscription();
    this.initStatusSubscriptions();
    this.initChangesSubscription();
    this.initRecipientCounterpartyTypeSubscription();
    this.errorsSubscription = this.form
      .get(this.controlName)
      .valueChanges.subscribe((value) => {
        this.errorMessages = this.validationService.getSingleErrorsObject(
          (this.form.get(this.controlName) as UntypedFormGroup).controls,
          this.errors,
        );
      });
    if (this.form.get(this.controlName)) {
      this.form.get(this.controlName).valueChanges.subscribe((value) => {
        if (
          this.form.get(this.controlName).get('firstName') &&
          this.form.get(this.controlName).get('firstName').status === 'DISABLED'
        ) {
          this.form.get(this.controlName).get('firstName').markAsPristine();
          this.form.get(this.controlName).get('firstName').markAsUntouched();
          this.form
            .get(this.controlName)
            .get('firstName')
            .updateValueAndValidity({ onlySelf: true });
        }
        if (
          this.form.get(this.controlName).get('lastName') &&
          this.form.get(this.controlName).get('lastName').status === 'DISABLED'
        ) {
          this.form.get(this.controlName).get('lastName').markAsPristine();
          this.form.get(this.controlName).get('lastName').markAsUntouched();
          this.form
            .get(this.controlName)
            .get('lastName')
            .updateValueAndValidity({ onlySelf: true });
        }
      });
    }

    if (this.templateValidation) {
      Object.keys(this.form.get(this.controlName)['controls']).forEach(
        (key) => {
          this.form.controls[this.controlName]['controls'][
            key
          ].clearValidators();
        },
      );
    }
  }

  initChangesSubscription(): void {
    this.changesSubscription = this.privatePersonFormGroup.valueChanges
      .pipe()
      .subscribe((value) => {
        this.form.removeControl('Recipient');
      });
  }

  initRecipientCounterpartyTypeSubscription(): void {
    if (this.recipientTypeControl) {
      this.recipientTypeControl.valueChanges.pipe().subscribe((type) => {
        if (type === 'OrganizationDelegate') {
          this.privatePersonFormGroup.patchValue({
            lastName: 'Представник',
            firstName: 'Представник',
            middleName: 'Представник',
          });
          this.lastNameControl.disable();
          this.firstNameControl.disable();
          this.middleNameControl.disable();
        }
        if (
          this.form.value.RecipientCounterpartyType ===
            'OrganizationDelegate' &&
          type !== 'OrganizationDelegate'
        ) {
          this.privatePersonFormGroup.patchValue({
            lastName: '',
            firstName: '',
            middleName: '',
          });
          if (this.phoneControl.valid) {
            this.lastNameControl.enable();
          }
        }
      });
    }
  }

  addFormControls(): void {
    this.privatePersonFormGroup = this.fb.group({
      phone: [
        this.initialContactValue ? this.initialContactValue.Phones : '',
        Validators.compose([Validators.required, Validators.minLength(12)]),
      ],
      lastName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.LastName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([Validators.required]),
      ],
      firstName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.FirstName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([Validators.required]),
      ],
      middleName: [
        {
          value: this.initialContactValue
            ? this.initialContactValue.MiddleName
            : '',
          disabled: this.clearOnPhoneChange,
        },
        Validators.compose([
          this.isMiddleNameRequired ? Validators.required : null,
        ]),
      ],
      ref: [{ value: '' }],
      counterRef: [{ value: '' }],
    });
    this.form.setControl(this.controlName, this.privatePersonFormGroup);
    this.patchData();
  }

  patchData() {
    if (this.patchRecipientControlName && this.patchRecipientData) {
      this.form.get(this.controlName).patchValue({
        lastName:
          this.form
            .get(this.patchRecipientControlName)
            .value[this.patchRecipientData].split(' ')[0] || '',
        firstName:
          this.form
            .get(this.patchRecipientControlName)
            .value[this.patchRecipientData].split(' ')[1] || '',
        middleName:
          this.form
            .get(this.patchRecipientControlName)
            .value[this.patchRecipientData].split(' ')[2] || '',
        phone:
          this.form.get(this.patchRecipientControlName).value.PhoneRecipient ||
          '',
      });
    }
  }

  initLastNameSubscription(): void {
    this.lastNameControl.valueChanges
      .pipe(
        tap((value) => {
          if (typeof value === 'string' && !this.isOrganizationDelegate) {
            if (value.length >= 2) {
              this.firstNameControl.enable();
              this.middleNameControl.enable();
              if (!this.cd['destroyed']) {
                this.cd.detectChanges();
              }
            }
          } else {
            this.contactOptions = [];
            if (!this.cd['destroyed']) {
              this.cd.detectChanges();
            }
          }
        }),
        debounceTime(600),
        tap((value) => {
          if (this.hintSubstitution) {
            if (typeof value === 'string' && !this.isOrganizationDelegate) {
              if (value.length >= 3) {
                this.searchRecipientContacts(value);
              }
            } else {
              this.contactOptions = [];
              if (!this.cd['destroyed']) {
                this.cd.detectChanges();
              }
            }
          }
        }),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.showNotFoundContacts = false;

        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      });
  }

  initStatusSubscriptions(): void {
    this.phoneControl.statusChanges.pipe().subscribe((status) => {
      if (
        status === 'VALID' &&
        !this.isOrganizationDelegate &&
        (!this.templateValidation ||
          (this.templateValidation && this.phoneControl.value?.length === 12))
      ) {
        this.lastNameControl.enable();
        if (!this.templateValidation) {
          this.lastNameInput.nativeElement.focus();
        }
      } else {
        this.contactOptions = [];
        this.catalogOptions = [];
        if (!this.isOrganizationDelegate && this.clearOnPhoneChange) {
          this.privatePersonFormGroup.patchValue({
            lastName: '',
            firstName: '',
            middleName: '',
          });
        }
        this.lastNameControl.disable();
        this.firstNameControl.disable();
        this.middleNameControl.disable();
      }
    });
  }

  searchRecipientContacts(query: string): void {
    this.contactService
      .searchContactsInCatalog(query, this.phoneControl.value)
      .subscribe((data) => {
        this.catalogOptions = data;
        this.contactOptions = [];
        this.cd.detectChanges();
      });
  }

  setOrganizationDisplay(contragent: Contragent) {
    if (this.form.get('NewContactOrganization')) {
      this.form.get('NewContactOrganization').patchValue({
        edrpou: contragent ? contragent.EDRPOU : '',
        companyName: contragent.Description
          ? contragent.Description
          : contragent.Description,
        orgType: contragent.OwnershipFormDescription
          ? contragent.OwnershipFormDescription
          : '',
      });
    }
  }

  setRecipientCounterpartyType(contragent: Contragent) {
    const recipientCounterpartyType =
      contragent.CounterpartyType === 'PrivatePerson'
        ? 'PrivatePerson'
        : 'Organization';
    if (this.form.get('RecipientCounterpartyType')) {
      this.form
        .get('RecipientCounterpartyType')
        .setValue(recipientCounterpartyType);
    } else if (this.form.get('recipientType')) {
      this.form.get('recipientType').setValue(recipientCounterpartyType);
    }
  }

  setPrivatePerson(contact: ContactPerson): void {
    this.form.get('RecipientCounterpartyType').setValue('PrivatePerson');

    if (this.form.get('Contragent')) {
      this.form.removeControl('Contragent');
    }

    if (this.form.get('NewContactOrganization')) {
      this.form.removeControl('NewContactOrganization');
    }

    this.patchPrivatePerson(contact);
  }

  setOrganization(contact: any): void {
    this.patchPrivatePerson(contact);
    this.form.get('RecipientCounterpartyType').setValue('Organization');
    this.form.setControl(
      'Contragent',
      new UntypedFormControl({ EDRPOU: contact.EDRPOU }),
    );
    if (this.form.get('NewContactOrganization')) {
      this.form.get('NewContactOrganization').patchValue({
        edrpou: contact.EDRPOU,
      });
    }
    this.form.updateValueAndValidity();
  }

  setContactFromCatalog(event): void {
    const catalogContact: CatalogContact = event.option.value;

    if (
      catalogContact.Description === 'Приватна особа' ||
      !catalogContact.EDRPOU
    ) {
      this.setPrivatePerson(catalogContact);
    } else {
      this.setOrganization(catalogContact);
    }
  }

  clear(): void {
    this.form.removeControl(this.contactProperty);
    this.privatePersonFormGroup.patchValue({
      phone: '',
      lastName: '',
      firstName: '',
      middleName: '',
    });
  }

  onChangePhone(event) {
    event.preventDefault();
    const srcElement = event.srcElement || event.target;
    const phone = srcElement ? srcElement.value : '';
    if (phone.length === 19) {
      this.lastNameInput.nativeElement.focus();
    }
  }

  cancel() {
    this.onCancel.next('select');
  }

  ngOnDestroy() {
    if (this.clearOnPhoneChange) {
      this.form.removeControl(this.controlName);
    }
    this.form.removeControl('NewSenderPrivateContact');
    this.errorsSubscription.unsubscribe();
  }

  private patchPrivatePerson(contact: ContactPerson): void {
    this.privatePersonFormGroup.patchValue({
      lastName: contact.LastName ? contact.LastName : '',
      firstName: contact.FirstName ? contact.FirstName : '',
      middleName: contact.MiddleName ? contact.MiddleName : '',
    });
  }
}

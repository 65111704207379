<div class="form-row mr-bottom-16" [formGroup]="contactForm">
  <div class="form-col form-col-2">
    <mat-form-field [attr.data-test-id]="'ContactPhone'"
                    class="form-input">
      <input [formControlName]="ControlNames.phone"
             [textMask]="PhoneMaskConfig"
             (blur)="phoneBlur()"
             type="tel"
             phone
             matInput
             placeholder="Телефон"
             autocomplete="off">
      <mat-error>{{getError(phone, phoneErrors)}}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-col form-col-2">
    <mat-form-field class="form-input">
      <input data-test-id="ContactLastname"
             type="text"
             placeholder="Прізвище"
             #lastNameInput
             class="text-capitalize"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             [formControlName]="ControlNames.lastName"
             matInput
             [matAutocomplete]="auto"
             autoScrollTarget>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="setContactFromCatalog($event)">
        <mat-option *ngFor="let option of catalogOptions"
                    [value]="option">
          <div class="option-field option-field_sm">
            <div class="option-field__hint">{{option.Description}}</div>
            <div class="option-field__text">{{option.LastName}} {{option.FirstName}} {{option.MiddleName}}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-error>{{getError(lastName, lastNameErrors)}}</mat-error>
    </mat-form-field>

  </div>
  <div class="form-col form-col-2">
    <mat-form-field class="form-input">
      <input data-test-id="ContactFirstname"
             matInput
             placeholder="Ім’я"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             class="text-capitalize"
             [formControlName]="ControlNames.firstName"
             autoScrollTarget>
      <mat-error>{{getError(firstName, firstNameErrors)}}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-col form-col-2 box-with-controls">
    <mat-form-field class="form-input">
      <input data-test-id="ContactMiddlename"
             matInput placeholder="По батькові"
             [LetterInput]="true"
             [allowDash]="true"
             [onlyCyrillic]="true"
             capitalizeWithDash
             class="text-capitalize"
             [formControlName]="ControlNames.middleName"
             autoScrollTarget>
      <mat-error>{{getError(middleName, middleNameErrors)}}</mat-error>
    </mat-form-field>
  </div>
</div>

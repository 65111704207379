import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@shared';
import { ChangeEwSuccessPopupData } from './change-ew-success-popup.interface';

@Component({
  selector: 'app-change-ew-success-popup',
  templateUrl: './change-ew-success-popup.component.html',
  styleUrls: ['./change-ew-success-popup.component.scss'],
})
export class ChangeEwSuccessPopupComponent extends Unsubscriber implements OnInit {

  constructor(
    private dialog: MatDialogRef<ChangeEwSuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ChangeEwSuccessPopupData,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToBackdropClick();
  }

  get Number(): string {
    return this.data.Number;
  }

  get messageText(): string {
    return this.data.MessageText;
  }

  get alertForCall(): string {
    return this.data.AlertForCall;
  }

  close(): void {
    this.dialog.close({ success: true });
  }

  private subscribeToBackdropClick(): void {
    this.subscriptions = this.dialog.backdropClick().subscribe(() => {
      this.close();
    });
  }
}

import {Directive, ElementRef, HostListener, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[NumberInput]',
})
export class NumberInputDirective {

  @Input() NumberInput: boolean;

  @Input() maxInputLength: number;

  @Input() min: number;

  @Input() max: number;

  @Input() maxValue: number;

  @Input() allowFloat: boolean;

  @Input() digitsAfterDot: number;

  allowedKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  constructor(private el: ElementRef, @Self() @Optional() private ngControl: NgControl) {
  }

  @HostListener('keypress', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent | any;
    const key = event.keyCode || event.which;
    const srcElement = e.srcElement || e.target;
    if (this.NumberInput) {
      if (srcElement) {
        if (this.maxInputLength && srcElement
          && srcElement.value.length === this.maxInputLength
          && srcElement.selectionStart === srcElement.selectionEnd) {
          e.preventDefault();
        }
        if (this.digitsAfterDot && srcElement && srcElement.selectionStart === srcElement.selectionEnd &&
          (srcElement.value.indexOf('.') > -1
            && srcElement.selectionStart > srcElement.value.indexOf('.')
            && srcElement.value.slice(srcElement.value.indexOf('.') + 1).length === this.digitsAfterDot) ||
          (srcElement.value.indexOf(',') > -1
            && srcElement.selectionStart > srcElement.value.indexOf(',')
            && srcElement.value.slice(srcElement.value.indexOf(',') + 1).length === this.digitsAfterDot)
        ) {
          e.preventDefault();
        }
      }
      if (this.allowFloat) {
        this.allowedKeyCodes.push(44);
        this.allowedKeyCodes.push(46);
      }
      if (this.allowedKeyCodes.indexOf(key) !== -1 ||
        // Allow: Ctrl+A
        (key === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (key === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (key === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (key === 88 && (e.ctrlKey || e.metaKey))
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (key < 48 || key > 57)) && (key <= 122 || key >= 65)
        && (key !== 8) && (e.key !== '.') && (e.key !== ',') && (key !== 9)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event']) onInput(event) {
    const e = event as KeyboardEvent | any;
    const key = event.keyCode || event.which;
    if (this.NumberInput) {
      if (this.maxValue && Number(e.target.value) > this.maxValue) {
        e.target.value = this.maxValue;
        if (this.ngControl) {
          this.ngControl.control.setValue(this.maxValue);
        }
      }
      if (this.max && Number(e.target.value) > this.max) {
        e.target.value = this.max;
        if (this.ngControl) {
          this.ngControl.control.setValue('');
        }
      }
      if (this.min && Number(e.target.value) < this.min) {
        e.target.value = this.min;
        if (this.ngControl) {
          this.ngControl.control.setValue('');
        }
      }
    }
  }
}

<div class="services-list__form" [formGroup]="deliveryByHand">
  <div [formArrayName]="controlName">
    <div class="form-row" [formGroupName]="i" *ngFor="let recipient of deliveryRecipientsArray['controls']; let i = index;">
      <div class="form-col form-col-2" >
        <mat-form-field class="mat-block">
          <input matInput
                 formControlName="surname"
                 placeholder="Прізвище"
                 autoScrollTarget>
        </mat-form-field>
      </div>
      <div class="form-col form-col-2" >
        <mat-form-field class="mat-block">
          <input matInput
                 formControlName="name"
                 placeholder="Ім’я"
                 autoScrollTarget>
        </mat-form-field>
      </div>
      <div class="form-col form-col-2 box-with-controls">
        <mat-form-field class="mat-block">
          <input matInput
                 formControlName="middleName"
                 placeholder="По батькові"
                 autoScrollTarget>
        </mat-form-field>
        <div class="control-icons" *ngIf="i > 0">
          <div class="control-icons__item">
            <button class="mat-icon-button mat-button" (click)="removeRecipient(i)">
              <mat-icon class="material-icons">
                close
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row form-row_button" *ngIf="deliveryRecipientsArray['controls'].length < 15">
    <div class="form-col form-col-3">
      <button type="button"
              (click)="addRecipient()"
              class="mat-button_transparent mat-button_sm mat-button_uppercase mat-button_with-icon"
              mat-button>
        <i class="material-icons">
          add_circle
        </i>
        ДОДАТИ ОТРИМУВАЧА
      </button>
    </div>
  </div>
</div>

import { Directive, Input } from '@angular/core';
import { CopyClipboardDirective } from './copy-clipboard.directive';

@Directive({
  selector: '[appPhoneCopy]',
  exportAs: 'appPhoneCopy',
})
export class PhoneClipboardDirective extends CopyClipboardDirective {
  @Input('appPhoneCopy')
  textToCopy: string;

  formatContent(value: string): string {
    if (!value || value.length === 0) {
      return '';
    }
    return `${value}`;
  }

  formatSuccessMessage(): string {
    return 'Номер скопійовано';
  }
}

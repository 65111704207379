<mat-progress-bar [style.opacity]="+(loading$ | async)" mode="indeterminate"></mat-progress-bar>
<div class="popup__header pb-4 pl-6 pr-6 pt-8">
    <div class="popup__title">
        {{ data.title }}
    </div>
</div>
<div class="popup__body pl-6 pr-6 pb-4" *ngIf="data.text">
    <p *ngIf="!data.isHtml" class="mb-2">{{ data.text }}</p>
    <p *ngIf="data.isHtml" class="mb-2" [innerHTML]="data.text"></p>
</div>
<div class="popup__footer d-flex align-items-center justify-content-space-between pl-4 pr-4 pb-4">
    <button type="button"
            matDialogClose
            mat-button
            [disabled]="loading$ | async">
        {{ data.cancelBtnText }}
    </button>
    <button type="button"
            color="primary"
            mat-button
            class="ml-a"
            (click)="confirm()"
            [disabled]="loading$ | async">
        {{ data.confirmBtnText }}
    </button>
</div>

<div class="banner" *ngIf="bannerShown && banners?.length">
  <button mat-icon-button class="banner__close">
    <mat-icon class="material-icons_grey"
              (click)="bannerClose()">close
    </mat-icon>
  </button>
  <div class="banner__info">
    <div class="arrow arrow__prev"
         (click)="changeBanner(currentBanner, 'prev')">
      <i class="material-icons">
        chevron_left
      </i>
    </div>

    <div class="warning-message">
      <div class="warning-message__title" [innerHTML]="currentBanner.item.title"></div>
      <div class="warning-message__text"
           [innerHTML]="currentBanner.item.text">
      </div>
    </div>

    <div class="arrow arrow__next"
         (click)="changeBanner(currentBanner, 'next')">
      <i class="material-icons">
        chevron_right
      </i>
    </div>
  </div>
</div>

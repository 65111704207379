<mat-progress-bar [style.opacity]="+(loading$ | async)" mode="indeterminate"></mat-progress-bar>
<div class="popup__header pl-6 pr-6 pt-8">
  <div class="popup__title">
    Змінити назву реєстру {{ registerNumber }}
  </div>
</div>
<div class="popup__body pl-6 pr-6 pb-4" [formGroup]="form">
  <mat-form-field class="form-input mt-6">
    <mat-label>Назва реєстру</mat-label>
    <input formControlName="description"
           class="input"
           matInput
           type="text"
           (blur)="onBlur($event)"
           autocomplete="off">
    <button *ngIf="description?.value"
            (click)="clearField()"
            class="p-0"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>{{ getError(description, descriptionErrors) }}</mat-error>
  </mat-form-field>
</div>
<div class="popup__footer d-flex align-items-center justify-content-flex-end pl-4 pr-4 pb-4">
  <button type="button"
          matDialogClose
          mat-button
          [disabled]="loading$ | async">
    Скасувати
  </button>
  <button type="button"
          color="primary"
          mat-button
          class="ml-a"
          (click)="confirm()"
          [disabled]="(loading$ | async)">
    Зберегти
  </button>
</div>

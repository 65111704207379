import { AbstractControl } from '@angular/forms';

export function checkObjectMask(validatedKeys: string[], isTemplate = false) {
  return (checkAll: boolean = true) => {
    return (control: AbstractControl) => {
      const { value } = control;

      if (isTemplate && !value) {
        return null;
      }

      if (!value) {
        return null;
      }

      if (!value || typeof value !== 'object') {
        return { objectMask: true };
      }

      const checkFn = (key) => value.hasOwnProperty(key);

      const isValid = checkAll
        ? validatedKeys.every(checkFn)
        : validatedKeys.some(checkFn);

      return isValid ? null : { objectMask: true };
    };
  };
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { AccountStatus, MasterpassCard } from '@models';
import { AlertToastService, AuthService } from '@services';
import { FormContainer } from '@shared';
import { debounceTime, Subscription, take } from 'rxjs';
import { MasterpassService } from '../../../../../services/masterpass.service';
import {
  PaymentProcessV3Component
} from '../../../../dashboard/acquiring/components/payment-process-v3/payment-process-v3.component';
import { DeliveryCostCheckPopupComponent } from '../delivery-cost-check-popup/delivery-cost-check-popup.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-inv-back-delivery',
  templateUrl: './back-delivery.component.html',
  styleUrls: ['./back-delivery.component.scss'],
})
export class BackDeliveryComponent extends FormContainer implements AfterViewInit, OnInit, OnDestroy {
  @Input() controlName = 'BackwardDeliveryData';

  @Input()
  displaySum = true;

  @Input()
  isInternetOrderConfig = false;

  @Input()
  displayAddCard = true;

  @Input()
  isChangeEw = false;

  @Input()
  changeEwSenderPhone;

  @Input()
  CanChangeCash2Card = false;

  @Input()
  invoiceNumber: string | number;

  addCardTooltip: string = '';
  BackwardDeliveryDataGroup: UntypedFormGroup;
  backDeliveryGroup: UntypedFormGroup;
  @Output()
  onRemove = new EventEmitter<void>();
  costSubscription: Subscription;
  subscrition: Subscription;
  showNeedAccess = false;
  showCards = false;
  accountStatus: AccountStatus;
  showCreateWallet = false;
  selectedCard: MasterpassCard;
  @Input() isCorporate = false;
  maxCostCash2Card = 29999;
  disableBackToCard = false;
  @Input() invoiceId: string;
  groupNames = ['Для списання', 'Для зарахування'];
  isLoading = false;
  targetValue: number;
  announcedCost: number;
  masterpassInfo = {
    sidWithoutCvv: '',
    sidWithCvv: '',
    retRefValWithCvv: '',
    retRefValWithoutCvv: '',
  };
  cardData: {
    pan: string;
    id: string;
  };
  @Output() onAddCardSuccess: EventEmitter<object> = new EventEmitter<object>();
  pendingAddCard = false;

  constructor(
    public dialog: MatDialog,
    protected formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    private masterpassService: MasterpassService,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  private _invoiceCopyState = false;

  get invoiceCopyState(): boolean {
    return this._invoiceCopyState;
  }

  @Input() set invoiceCopyState(value: boolean) {
    this._invoiceCopyState = value;
    this.getAddCardTooltip(value);
  }

  get receivingTypeIsCard(): boolean {
    return this.BackwardDeliveryDataGroup.get('ReceivingType')?.value === 'Card';
  }

  get cards() {
    return this.BackwardDeliveryDataGroup.get('cards');
  }

  set cards(cards) {
    this.BackwardDeliveryDataGroup.get('cards').setValue(cards);
  }

  get number() {
    return this.form.get('AdditionalServices.backDelivery.number')?.value;
  }

  _customAddresses: any[] = null;

  get customAddresses() {
    return this._customAddresses;
  }

  @Input() set customAddresses(addresses) {
    if (!addresses && this.isCorporate) {
      this.updateRef(null);
    }
    this._customAddresses = addresses;
  }

  get receivingTypeSelectAvailable(): boolean {
    return this.BackwardDeliveryDataGroup.get('CargoType').value === 'Money' && !this.authService.user.contacts;
  }

  get costControl(): AbstractControl {
    return this.isChangeEw ? this.form.get('Cost') : this.form?.parent?.get('stepDeliveryForm')?.get('Cost');
  }

  get costValue(): number {
    return !isNaN(this.costControl?.value) ? this.costControl.value : 0;
  }

  get senderName(): string | null {
    return this.form.get('ContactSender') ? this.form.get('ContactSender').value.Description : null;
  }

  get recipientName(): string | null {
    if (this.form.get('Recipient') && this.form.get('Recipient').value !== null) {
      return this.form.get('Recipient').value.Description;
    } else if (
      this.form.get('NewRecipientPrivateContact') &&
      this.form.get('NewRecipientPrivateContact').value.lastName
    ) {
      const value = this.form.get('NewRecipientPrivateContact').value;
      return `${value.lastName} ${value.firstName ? value.firstName : ''} ${value.middleName ? value.middleName : ''}`;
    }
    return null;
  }

  get recipientAddressType() {
    return this.form.get('AddressRecipient') ? this.form.get('AddressRecipient').value.type : null;
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkDisableCardState(this.form.value);
    this.form.valueChanges.subscribe((value) => {
      this.checkDisableCardState(value);
    });
  }

  checkDisableCardState(value) {
    if (
      (!value.AddressRecipient || !value.AddressSender) &&
      (!this.isChangeEw || (this.isChangeEw && !this.CanChangeCash2Card))
    ) {
      this.disableBackToCard = true;
      if (
        this.BackwardDeliveryDataGroup.value.ReceivingType === 'Card' &&
        value.AddressRecipient &&
        value.AddressSender
      ) {
        this.BackwardDeliveryDataGroup.patchValue({
          ReceivingType: 'Warehouse',
        });
        this.onChangeReceivingType({ value: 'Warehouse', source: null });
      }
    } else {
      this.disableBackToCard = false;
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  change(event) {
    this.targetValue = event.target.value.replace(/,/gi, '.');
    if (+this.targetValue > this.costValue) {
      this.openDialog(this.targetValue, this.costValue);
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.subscrition = this.BackwardDeliveryDataGroup.get('RedeliveryString')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        if (this.costControl) {
          this.announcedCost = this.costValue;
        }
      });

    this.BackwardDeliveryDataGroup.get('ReceivingType').valueChanges.subscribe(
      (receivingType: 'Warehouse' | 'Card') => {
        if (receivingType === 'Card') {
          this.BackwardDeliveryDataGroup.patchValue({
            PayerType: 'Recipient',
          });
        }
      },
    );
  }

  openDialog(value, announcedCost) {
    const dialogRef: MatDialogRef<DeliveryCostCheckPopupComponent> = this.dialog.open(DeliveryCostCheckPopupComponent, {
      panelClass: 'no-padding',
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.cost = value;
    dialogRef.componentInstance.announcedCost = announcedCost;
    dialogRef.componentInstance.type = this.controlName;

    this.subscriptions = dialogRef.componentInstance.onAdd.subscribe(() => {
      if (+value > +announcedCost && !this.isChangeEw) {
        this.form.parent.get('stepDeliveryForm').get('Cost').setValue(value);
        this.form.parent.get('stepDeliveryForm').get('Cost').updateValueAndValidity({ onlySelf: true });
      }
    });

    this.subscriptions = dialogRef.componentInstance.onClear.subscribe(() => {
      this.BackwardDeliveryDataGroup.get('RedeliveryString').setValue(this.costValue);
      this.BackwardDeliveryDataGroup.get('RedeliveryString').updateValueAndValidity({ onlySelf: true });
    });

    dialogRef.afterClosed();
  }

  updateRef(ref: string) {
    if (this.BackwardDeliveryDataGroup) {
      this.BackwardDeliveryDataGroup.get('CustomBackwardDeliveryParameter').setValue(ref);
    }
  }

  addFormControls() {
    this.backDeliveryGroup = this.form.get('AdditionalServices').get('backDelivery') as UntypedFormGroup;
    const cargoType = 'Money';

    this.BackwardDeliveryDataGroup = this.formBuilder.group({
      PayerType: ['Recipient', [Validators.required]],
      CargoType: [cargoType, [Validators.required]],
      CustomBackwardDeliveryParameter: [''],
      RedeliveryString: [
        this.costControl ? this.costValue : 0,
        [Validators.required, Validators.min(0), Validators.max(1000000)],
      ],
      ReceivingType: ['Warehouse', [Validators.required]],
      ReceivingData: [{}],
      InitialCard: '',
      cards: [],
      Cash2CardPayout_Id: '',
      Cash2CardAlias: '',
      Cash2CardPAN: '',
      Cash2CardCreditSid: '',
    });
    this.backDeliveryGroup.addControl(this.controlName, this.BackwardDeliveryDataGroup);
  }

  changeSum(value) {
    if (Number(value) > this.maxCostCash2Card) {
      this.BackwardDeliveryDataGroup.patchValue({
        ReceivingType: 'Warehouse',
      });
      this.BackwardDeliveryDataGroup.patchValue({
        InitialCard: '',
        cards: [],
        Cash2CardPayout_Id: '',
        Cash2CardAlias: '',
        Cash2CardPAN: '',
        Cash2CardCreditSid: '',
      });
    }
  }

  checkDisableToCard() {
    return (
      (this.BackwardDeliveryDataGroup.get('RedeliveryString').value > this.maxCostCash2Card ||
        this.disableBackToCard ||
        (this.isChangeEw && !this.CanChangeCash2Card)) &&
      !this.isInternetOrderConfig
    );
  }

  remove() {
    this.onRemove.next();
  }

  openAddCardModal() {
    this.pendingAddCard = true;
    this.masterpassService
      .initAddCard(
        this.isChangeEw ? this.changeEwSenderPhone : this.form.get('ContactSender.data').value.Phones,
        this.invoiceId ? this.invoiceNumber || this.number : null,
      )
      .pipe(take(1))
      .subscribe(
        (data) => {
          const dialogRef = this.dialog.open(PaymentProcessV3Component, {
            position: { right: '0' },
            width: '75%',
            height: '100%',
            panelClass: 'setting-content-popup',
          });
          dialogRef.componentInstance.url = `${data.Url}&lang=ua`;
          dialogRef.componentInstance.addCardSuccess.subscribe((cardData: any) => {
            this.pendingAddCard = false;
            this.cardData = cardData;
            this.cards.setValue([[cardData]]);
            this.BackwardDeliveryDataGroup.patchValue({
              Cash2CardPayout_Id: cardData.id,
              Cash2CardAlias: cardData.alias ?? 'Наименование карты',
              Cash2CardPAN: cardData.pan,
            });
            if (!this.form.get('AdditionalServices.backDelivery.number')) {
              (this.form.get('AdditionalServices.backDelivery') as UntypedFormGroup).addControl(
                'number',
                new UntypedFormControl(data.IntDocNumber),
              );
            } else {
              this.form.get('AdditionalServices.backDelivery.number').setValue(data.IntDocNumber);
            }
            dialogRef.close();
          });
          dialogRef.afterClosed().subscribe(() => {
            this.pendingAddCard = false;
            this.onAddCardSuccess.emit({
              ...this.cardData,
              docNumber: data.IntDocNumber,
            });
            this.cd.detectChanges();
          });
        },
        (errors) => {
          this.alertToastService.pushApiErrors(errors);
          this.pendingAddCard = false;
          this.cd.detectChanges();
        },
      );
  }

  ngOnDestroy() {
    this.subscrition.unsubscribe();
    this.backDeliveryGroup.removeControl(this.controlName);
  }

  getAddCardTooltip(value: boolean): void {
    if (value) {
      this.addCardTooltip =
        'З міркувань безпеки, при дублюванні накладної, необхідно повторно обрати картку для зарахування грошового переказу';
      return;
    }
    if (this.isInternetOrderConfig) {
      this.addCardTooltip = 'Обрати картку для зарахування грошового переказу можливо лише при створенні відправлення';
      return;
    }
    if (this.isChangeEw && !this.CanChangeCash2Card) {
      if (this.cards?.value?.length) {
        this.addCardTooltip =
          'Для даного відправлення відсутня можливість змінити вже обрану картку для зарахування переказу';
        return;
      }
      this.addCardTooltip = 'Для даного відправлення відсутня можливість отримати переказ на картку';
      return;
    }
    this.addCardTooltip = null;
  }

  checkDisableToCardTooltip() {
    if (this.isChangeEw && !this.CanChangeCash2Card) {
      if (this.cards?.value?.length) {
        return 'Для даного відправлення відсутня можливість змінити вже обрану картку для зарахування переказу';
      }
      return 'Для даного відправлення відсутня можливість отримати переказ на картку';
    }
    return null;
  }

  onChangeReceivingType($event: MatRadioChange) {
    if ($event.value === 'Warehouse') {
      this.BackwardDeliveryDataGroup.patchValue({
        InitialCard: '',
        cards: [],
        Cash2CardPayout_Id: '',
        Cash2CardAlias: '',
        Cash2CardPAN: '',
        Cash2CardCreditSid: '',
      });
    }
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertToastService } from '@services';
import { Unsubscriber } from '@shared';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { CourierCallCompact } from '../../../../components/dashboard/business-services/courier-pickup/courier-pickup.model';
import { CourierPickupApiService } from '../../../../components/dashboard/business-services/services/courier-pickup-api/courier-pickup-api.service';
import { invoicesPartialSuccessConfig, PartialSuccessResponse } from '../../partial-success/partial-success.model';
import { PartialSuccessService } from '../../partial-success/partial-success.service';
import { hasFailedEntities } from '../../partial-success/partial-success.utils';
import { newCourierPickup } from './courier-pickups-list.constants';

@Component({
  selector: 'app-courier-pickups-list',
  templateUrl: './courier-pickups-list.component.html',
  styleUrls: ['./courier-pickups-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourierPickupsListComponent extends Unsubscriber implements OnInit {

  constructor(
    private courierApiService: CourierPickupApiService,
    private dialog: MatDialogRef<CourierPickupsListComponent>,
    private alert: AlertToastService,
    private partialSuccess: PartialSuccessService,
    @Inject(MAT_DIALOG_DATA) private data: { invoiceIds: string[] },
  ) {
    super();
  }

  readonly newCourierPickup = newCourierPickup;

  selectedPickup: string = newCourierPickup;

  private _pickups: BehaviorSubject<CourierCallCompact[]> = new BehaviorSubject<CourierCallCompact[]>([]);
  pickups$: Observable<CourierCallCompact[]> = this._pickups.asObservable();

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this._loading.asObservable();

  set loading(value: boolean) {
    this._loading.next(value);
  }

  ngOnInit(): void {
    this.loadPickups();
  }

  close(): void {
    this.dialog.close(null);
  }

  save(): void {
    const number: string = this.selectedPickup === newCourierPickup ? '' : this.selectedPickup;

    if (!!number) {
      this.attachToSelected(number);
    } else {
      this.dialog.close({ success: false, partialSuccess: false, Barcode: number });
    }
  }

  private attachToSelected(number: string): void {
    this.loading = true;

    this.subscriptions = this.courierApiService.insertDocuments(number, this.data.invoiceIds)
      .pipe(
        finalize(() => this.loading = false),
        take(1),
      )
      .subscribe((res: PartialSuccessResponse) => {
          if (res) {
            if (hasFailedEntities(res)) {
              const config = {
                ...invoicesPartialSuccessConfig,
                label: res.Barcode ? `№${res.Barcode}` : ``,
              };
              this.partialSuccess.open(res, config);
              this.dialog.close({ success: true, partialSuccess: true, Barcode: number });
            } else {
              this.dialog.close({ success: true, partialSuccess: false, Barcode: number });
              this.alert.pushSuccess('Посилка(и) успішно додано');
            }
          } else {
            this.dialog.close({ success: false, partialSuccess: false, Barcode: number });
          }
        },
        (err) => {
          if (err[0]) {
            this.loading = false;
            this.dialog.close({ success: false, partialSuccess: false, Barcode: number });
            this.alert.pushApiErrors(err);
          }
        });
  }

  get saveBtnLabel(): string {
    return this.selectedPickup === newCourierPickup ? 'Створити заявку' : 'додати до заявки';
  }

  private loadPickups(): void {
    this.loading = true;

    const invoiceIdForSearch = this.data.invoiceIds.length === 1 ? this.data.invoiceIds[0] : undefined;

    this.subscriptions = this.courierApiService.getCourierPickupCompactList(invoiceIdForSearch)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (res: CourierCallCompact[]) => {
          if (res) {
            this._pickups.next(res);
          }
        },
        (err) => {
          if (err[0]) {
            this.alert.pushApiErrors(err);
          }
        });
  }
}

import {Component} from '@angular/core';
import {MessengersPopupComponent} from '../messengers-popup/messengers-popup.component';
import {MatDialog} from '@angular/material/dialog';

const componentName = 'app-header-chat';

@Component({
  selector: 'app-header-chat',
  templateUrl: './header-chat.component.html',
  styleUrls: ['./header-chat.component.scss'],
})
export class HeaderChatComponent {
  constructor(
    public dialog: MatDialog
  ) {
  }

  openMessengersPopup(): void {
    const dialogRef = this.dialog.open(MessengersPopupComponent, {
      panelClass: 'no-padding',
      autoFocus: false,
      restoreFocus: false,
      position: {
        bottom: '56px',
        right: '24px'
      },
    });
  }
}

<ng-container [formGroup]="editForm">
  <app-autocomplete-with-controls data-test-id="city"
                                  [size]="2"
                                  [config]="configCity"
                                  [createTemplate]="templateValidation"
                                  [formControlName]="'city'"
                                  [formControlParent]="editForm.get('city')"
                                  [controlsConfig]="null"
                                  [options]="cityOptions"
                                  [transformFn]="cityTransformFn">
    <ng-template appAutocompleteOption let-option>
      {{ option.Present }}
    </ng-template>
    <app-autocomplete-error *ngIf="!!errorMessages['city'] && (editForm.get('city').touched || editForm.get('city').dirty)">
      {{ errorMessages['city'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>

  <app-autocomplete-with-controls data-test-id="street"
                                  [size]="4"
                                  [config]="configStreet"
                                  [createTemplate]="templateValidation"
                                  [formControlParent]="editForm.get('street')"
                                  [formControlName]="'street'"
                                  [controlsConfig]="null"
                                  [options]="streetOptions"
                                  [transformFn]="streetTransformFn">
    <ng-template appAutocompleteOption let-option>
      {{ option.Present }}
    </ng-template>
    <app-autocomplete-error *ngIf="!!errorMessages['street'] && (editForm.get('street').touched || editForm.get('street').dirty)">
      {{ errorMessages['street'] }}
    </app-autocomplete-error>
  </app-autocomplete-with-controls>

  <app-autocomplete-with-controls data-test-id="building"
                                  [size]="1"
                                  [config]="configBuilding"
                                  [createTemplate]="templateValidation"
                                  [formControlName]="'building'"
                                  [formControlParent]="editForm.get('building')"
                                  [controlsConfig]="null">

  </app-autocomplete-with-controls>

  <ng-container *ngIf="showFlat">
    <app-autocomplete-with-controls data-test-id="room"
                                    [size]="1"
                                    [config]="configRoom"
                                    [formControlName]="'room'"
                                    [formControlParent]="editForm.get('room')"
                                    [controlsConfig]="null">

    </app-autocomplete-with-controls>
  </ng-container>
</ng-container>

import { ChangeEwConfigItem } from '../models/change-ew-config-item';

export const config = new Map<string, ChangeEwConfigItem>([
  [
    'Відправник',
    {
      before: 'BeforeChangeSenderContactPerson',
      after: 'AfterChangeSenderContactPerson',
    },
  ],
  [
    'Номер відправника',
    {
      before: 'BeforeChangeSenderPhone',
      after: 'AfterChangeSenderPhone',
    },
  ],
  [
    'Отримувач',
    {
      before: 'BeforeChangeRecipientContactPerson',
      after: 'AfterChangeRecipientContactPerson',
    },
  ],
  [
    'Номер отримувача',
    {
      before: 'BeforeChangeRecipientPhone',
      after: 'AfterChangeRecipientPhone',
    },
  ],
  [
    'Компанія відправник',
    {
      before: 'BeforeChangeSenderCounterparty',
      after: 'AfterChangeSenderCounterparty',
    },
  ],
  [
    'Компанія отримувач',
    {
      before: 'BeforeChangeRecipientCounterparty',
      after: 'AfterChangeRecipientCounterparty',
    },
  ],
  [
    'Платник за доставку',
    {
      before: 'BeforeChangePayerType',
      after: 'AfterChangePayerType',
      transform(value) {
        return value === 'Sender' ? 'Відправник' : 'Отримувач';
      },
    },
  ],
  [
    'Форма оплати за доставку',
    {
      before: 'BeforeChangePaymentMethod',
      after: 'AfterChangePaymentMethod',
      transform(value) {
        return value === 'Cash' ? 'Готівка' : 'Безготівка';
      },
    },
  ],
]);

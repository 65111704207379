import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  AutoCompleteOptionItem,
  AutocompleteOptionsFilterFn,
  AutoCompleteTransformFn,
  AutocompleteWithControlsConfig,
} from '@autocomplete-with-controls';
import { CitySearchResult, WarehouseResult } from '@models';
import { AddressService, AuthService, ErrorMessages, ErrorTypeMessages, ValidationService } from '@services';
import { AbstractFormContainer, cityResultMaskValidator, TypedFormControl, WarehouseMaskValidator } from '@shared';
import { debounceTime, distinctUntilChanged, filter, map, merge, of, Subscription, switchMap, tap } from 'rxjs';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'np-inv-address-warehouse',
  templateUrl: 'address-warehouse.component.html',
  styleUrls: ['./address-warehouse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressWarehouseComponent extends AbstractFormContainer implements TypedFormControl, OnInit, OnDestroy {
  readonly type = 'Warehouse';

  editForm: UntypedFormGroup;

  @HostBinding('attr.data-test-id') a = 'AddressTypeWarehouse';

  @Input()
  controlName = 'AddressSender';

  @Input()
  invoiceForm: UntypedFormGroup;

  @Input()
  controlTypeName = 'type';

  @Input()
  templateValidation: boolean;

  @Input()
  isTmz = false;

  @Input()
  configCity: AutocompleteWithControlsConfig = {
    ariaLabel: 'Населений пункт',
    placeholder: 'Населений пункт',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  configWarehouse: AutocompleteWithControlsConfig = {
    ariaLabel: 'Відділення',
    placeholder: 'Відділення',
    arrow: false,
    errorStateMatcher: new MyErrorStateMatcher(),
  };

  @Input()
  focused: boolean;

  cityOptions: Array<AutoCompleteOptionItem<CitySearchResult>> = [];

  warehouseOptions: Array<AutoCompleteOptionItem<WarehouseResult>> = [];
  totalMaxWeight = 0;
  placeMaxWeight = 0;
  errors: ErrorTypeMessages = {
    city: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'objectMask', message: 'Виберіть населений пункт' },
    ],
    warehouse: [
      { key: 'required', message: "Це поле обов'язкове" },
      { key: 'objectMask', message: 'Виберіть відділення' },
    ],
  };
  errorMessages: ErrorMessages = {};
  errorsSubscription: Subscription;
  @Output()
  onCitySelected = new EventEmitter<CitySearchResult | string>();
  STORAGE_SENDER_WAREHOUSE_ADDRESS_KEY = 'senderWarehouseAddress';
  limitationText = '';
  optionSeatsLength: number;
  @Input()
  latestCity: string | CitySearchResult;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private addressService: AddressService,
    private validationService: ValidationService,
    private element: ElementRef,
    private authService: AuthService,
  ) {
    super();
  }

  get user() {
    return this.authService.user;
  }

  get deliveryForm(): AbstractControl {
    return this.form && this.form.parent && this.form.parent.parent && this.form.parent.parent.get('stepDeliveryForm');
  }

  get city(): CitySearchResult | string {
    return this.editForm.get('city').value;
  }

  set city(value: CitySearchResult | string) {
    this.editForm.get('city').setValue(value);
  }

  get cityControl(): AbstractControl | null {
    return this.editForm.get('city');
  }

  get warehouseControl(): AbstractControl | null {
    return this.editForm.get('warehouse');
  }

  get defaultWarehouse() {
    return (
      this.getItems(this.STORAGE_SENDER_WAREHOUSE_ADDRESS_KEY)[0] &&
      this.getItems(this.STORAGE_SENDER_WAREHOUSE_ADDRESS_KEY).reverse()[0].data.warehouse
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.errorsSubscription = this.form.get(this.controlName).valueChanges.subscribe(() => {
      setTimeout(() => {
        if (this.focused) {
          this.element.nativeElement.querySelector('input').focus();
          this.focused = false;
        }
      });
      this.cd.detectChanges();
      this.errorMessages = this.validationService.getSingleErrorsObject(
        (this.form.get(this.controlName) as UntypedFormGroup).controls,
        this.errors,
      );
    });
    this.warehouseControl.markAsPristine();
    this.warehouseControl.updateValueAndValidity();
  }

  listenPlacesControl() {
    this.calculateMaxWeigth(this.deliveryForm.value);
    this.deliveryForm.valueChanges.subscribe((value) => {
      this.calculateMaxWeigth(value);
    });
  }

  checkParamSeats() {
    const paramsSeats = this.invoiceForm.get('stepDeliveryForm.paramsCommonSeats');
    if (paramsSeats && paramsSeats.value) {
      this.togglePostbox(true);
      return paramsSeats.valueChanges.pipe(
        map((value) => {
          return [value];
        }),
      );
    }
    return of([false]);
  }

  togglePostbox(value: boolean): void {
    if (this.invoiceForm.get('stepDeliveryForm.OptionsSeat')) {
      this.optionSeatsLength = this.invoiceForm.get('stepDeliveryForm.OptionsSeat').value.length;
    }
    const tooltipText =
      this.optionSeatsLength > 1
        ? 'Параметр місця, повинен бути один'
        : 'Для можливості здійснення відправлення необхідно вказати габарити відправлення (Довжина х Ширина х Висота, см)';
    this.warehouseOptions.map((opt: any) => {
      if (opt.option.Description && opt.option.Description.includes('Поштомат')) {
        opt.config.disabled = value;
        opt.config.data = value ? '' : this.limitationText;
        opt.config.tooltip = value ? tooltipText : '';
      }
    });
    this.cd.markForCheck();
  }

  calculateMaxWeigth(deliveryValue) {
    if (!deliveryValue.paramsCommonSeats && deliveryValue.OptionsSeat) {
      this.totalMaxWeight = Math.max(
        ...deliveryValue.OptionsSeat.map((place) => Number(place.weight)),
        ...deliveryValue.OptionsSeat.map((place) => Number(place.volumetricVolume)),
      );
      this.placeMaxWeight = Math.max(
        ...deliveryValue.OptionsSeat.map((place) => Number(place.weight)),
        ...deliveryValue.OptionsSeat.map((place) => Number(place.volumetricVolume)),
      );
    } else if (deliveryValue.commonParams) {
      this.totalMaxWeight = Math.max(
        Number(deliveryValue.commonParams.Weight),
        Number(deliveryValue.commonParams.VolumeWeight),
      );
      this.placeMaxWeight = 0;
    }
  }

  loadCities(nameQuery: string): void {
    this.addressService
      .getCities({ name: nameQuery })
      .pipe(
        tap(
          (response) =>
            (this.cityOptions = response.Addresses.map((city) => ({
              option: city,
              config: {
                disabled: city.Warehouses === 0,
                data: {
                  limitationText:
                    city.Warehouses === 0 ? 'Немає відділень Нова пошта. Оберіть інший населений пункт' : '',
                },
              },
            }))),
        ),
      )
      .subscribe(() => this.cd.detectChanges());
  }

  loadWarehouses(cityRef: string): void {
    this.addressService
      .getWarehouses(cityRef, undefined, this.isTmz ? false : undefined)
      .pipe(
        map(this.removePostmats),
        tap((warehouses) => {
          this.warehouseOptions = warehouses.map((w) => {
            const config = this.getWarehouseConfig(w);
            return {
              option: w,
              config: {
                disabled: config.disabled,
                data: config.data,
                tooltip: config.tooltip,
              },
            };
          });
          if (this.isTmz && !warehouses.length) {
            this.warehouseOptions = [
              {
                option: {
                  Description: 'В обраному населеному пункті  доставка ТМЦ можлива  лише до адреси',
                } as WarehouseResult,
                config: {
                  disabled: true,
                  data: {
                    limitationText: '',
                  },
                  tooltip: '',
                },
              },
            ];
          }
          this.cd.markForCheck();
        }),
        filter((warehouses) => !!warehouses.length),
        switchMap(() => {
          if (this.invoiceForm) {
            const stepDeliveryForm = this.invoiceForm.get('stepDeliveryForm');
            return merge(of(stepDeliveryForm.value), stepDeliveryForm.valueChanges).pipe(
              switchMap((value) => {
                if (value.CargoType === 'Cargo') {
                  if (value.OptionsSeat && value.OptionsSeat.length > 1) {
                    return of([true]);
                  } else {
                    return this.checkParamSeats();
                  }
                } else {
                  return of([false]);
                }
              }),
            );
          } else {
            return of([false]);
          }
        }),
      )
      .subscribe(([value]) => {
        if (this.invoiceForm) {
          this.togglePostbox(value);
        }
        this.cd.markForCheck();
      });
  }

  getWarehouseConfig(warehouse: WarehouseResult): { disabled; data; tooltip } {
    const limitationText = '';
    this.limitationText = limitationText;
    return { disabled: false, data: { limitationText }, tooltip: '' };
  }

  cityTransformFn: AutoCompleteTransformFn<CitySearchResult> = (value: CitySearchResult) => {
    if (value === null) {
      return '';
    }
    return typeof value === 'string' ? value : value.Present || '';
  };

  warehouseTransformFn: AutoCompleteTransformFn<WarehouseResult> = (value: WarehouseResult) => {
    if (value === null) {
      return '';
    }
    return typeof value === 'string' ? value : value.Description || '';
  };

  warehouseOptionsFilterFn: AutocompleteOptionsFilterFn<WarehouseResult> = (
    value: string | WarehouseResult,
    options: Array<AutoCompleteOptionItem<WarehouseResult>>,
  ) => {
    const format = (v) => v.replace(/[\s\+\-.,:!?#@'"()\[\]()'"-]+/g, '').toLowerCase();
    const group = (warehouse: WarehouseResult) =>
      `${warehouse.Description || ''}${warehouse.DescriptionRu || ''}`.trim();
    const v = format(typeof value === 'string' ? value : group(value));
    return options.filter((el) => format(group(el.option)).includes(v));
  };

  addFormControls() {
    if (this.form) {
      this.createControl();
    }
  }

  getItems(key) {
    const items = localStorage.getItem(`${key}_${this.user.userLogin}`);
    return items && Array.isArray(JSON.parse(items)) ? JSON.parse(items) : [];
  }

  createControl(): void {
    this.editForm = this.fb.group({
      city: [this.latestCity || '', [Validators.required, cityResultMaskValidator()]],
      warehouse: [
        {
          value: this.defaultWarehouse || '',
          disabled: !this.latestCity,
        },
        [Validators.required, WarehouseMaskValidator()],
      ],
    });
    this.form.setControl(this.controlName, this.editForm);
    this.form.get(this.controlTypeName).setValue(this.type);
    this.initFormSubscriptions();
  }

  initFormSubscriptions(): void {
    this.cityControl.statusChanges.subscribe((status) => {
      const cityValue = this.cityControl.value;
      if (status === 'VALID' && cityValue?.Ref) {
        this.onCitySelected.next(this.city);
        this.warehouseControl.enable();
        this.warehouseControl.markAsPristine();
        this.warehouseControl.updateValueAndValidity();
        setTimeout(() => {
          this.element.nativeElement.lastElementChild.querySelector('input').focus();
        });
        this.configWarehouse = { ...this.configWarehouse, focused: true };
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      } else {
        this.warehouseOptions = [];
        this.warehouseControl.reset('');
        this.warehouseControl.disable();
      }
    });
    this.cityControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string' && value.length >= 2) {
        this.element.nativeElement.querySelector('input').focus();
        this.loadCities(value);
      } else {
        this.cityOptions = [];
      }
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.errorsSubscription.unsubscribe();
  }

  onFocus(event) {
    this.loadWarehouses((this.city as CitySearchResult).DeliveryCity);
  }

  private removePostmats(warehouses: WarehouseResult[]): WarehouseResult[] {
    return warehouses.filter((warehouse) => !warehouse.Description.includes('Поштомат'));
  }
}

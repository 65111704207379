import { AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CitySearchResult, ContactPerson, WarehouseResult } from '@models';

export const NumberWithTwoDecimalsRegExp = /^[+-]?\d+(\.\d{1,2})?$/;

export function objectIsEmpty(object: object): boolean {
  return !!object && !Object.keys(object).length;
}

export function objectIsNotEmpty(object: object): boolean {
  return !objectIsEmpty(object);
}

export function emptyValue(val: string | number | boolean): boolean {
  return val === null || val === undefined || val === '';
}

export function closeDialog(dialogRef: MatDialogRef<any>) {
  if (dialogRef) {
    dialogRef.close();
  }
}

export function deleteEmptyValues(object: object): void {
  Object.keys(object).forEach((k) => emptyValue(object[k]) && delete object[k]);
}

export function datesEqual(d1: string, d2: string): boolean {
  const date1 = new Date(d1).setHours(0, 0, 0, 0);
  const date2 = new Date(d2).setHours(0, 0, 0, 0);

  return date1 === date2;
}

export function filterInputValue(
  v: string | null | object,
  minLength = 2,
): boolean {
  return (
    (typeof v === 'string' && (v.length > minLength - 1 || !v.length)) ||
    v === null
  );
}

export function clearField(
  field: AbstractControl,
  event: Event,
  value?: any,
): void {
  event.stopPropagation();
  if (field.reset) {
    if (value === '' || value === null) {
      field.reset(value);

      return;
    }
    field.reset();
  }
}

export function getCounterpartyDescription(
  counterparty: ContactPerson,
): string {
  return counterparty?.Description;
}

export function getCityDescription(city: CitySearchResult): string {
  return city?.Present;
}

export function getWarehouseDescription(warehouse: WarehouseResult): string {
  return warehouse?.Description;
}


export enum CardTypes {
  IDENTIFICATION = 'Identification',
  ROZNIZA = 'loyalty rozniza',
  CCARD = 'Ccard',
  CORPORATE = 'corporate',
}

export enum CardSubTypes {
  LIDER = 'Lider',
}

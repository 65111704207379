import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map, switchMap} from 'rxjs';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {ConfigService} from '../../../../services/config.service';
import {NoRestApiHelper} from '../../../../services/no-rest-api.helper';
import {ISmsTemplate} from '../models/sms-template.model';

@Injectable({
  providedIn: 'root',
})
export class SmsInformingService {

  constructor(
    private http: HttpClient,
    protected configService: ConfigService,
    private firebaseService: FirebaseService,
    protected noRestApiHelper: NoRestApiHelper,
  ) {
  }

  registerPhone(phone: string): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'SmsClubGeneral',
      calledMethod: 'registerConfirm',
      methodProperties: {
        Phone: phone,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject).pipe(
      map(data => this.noRestApiHelper.checkErrors({data})));
  }

  getAccessToken(confirmData: { confirmCode, phone, email, username}): Observable<string> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'SmsClubGeneral',
      calledMethod: 'getAccessToken',
      methodProperties: {
        Phone: confirmData.phone,
        Email: confirmData.email,
        UserName: confirmData.username,
        Code: confirmData.confirmCode,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        switchMap(data => {
          return this.firebaseService.saveSmsClubToken(data.data.data[0].Token);
        }),
      );
  }

  getConnectedAccessToken(): Observable<string> {
    return this.firebaseService.getSmsClubToken();
  }

  getBalance(token: string): Observable<{money: string, currency: string}> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'SmsClubGeneral',
      calledMethod: 'smsBalance',
      methodProperties: {
        Token: token,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(data => data.data.data[0].Info.info),
      );
  }

  getAlphaNames(token: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'SmsClubGeneral',
      calledMethod: 'smsOriginator',
      methodProperties: {
        Token: token,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(data => data.data.data[0].Info.info),
      );
  }

  sendMessage(alphaName: string, phone: string, content: string, token: string) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'SmsClubGeneral',
      calledMethod: 'smsSend',
      methodProperties: {
        Originator: alphaName,
        Phone: [phone],
        Message: content,
        Token: token,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(data => data.data),
      );
  }

  disconnectSmsClub(): Observable<void> {
    return this.firebaseService.removeSmsClubToken();
  }

  createTemplate(name: string, content: string): Observable<any> {
    return this.firebaseService.createSmsInformingTemplate(name, content);
  }

  getSmsInformingTemplates(): Observable<ISmsTemplate[]> {
    return this.firebaseService.getSmsInformingTemplates();
  }

  removeInformingTemplate(name: string): Observable<void> {
    return this.firebaseService.removeSmsInformingTemplate(name);
  }

}

import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Observable } from 'rxjs';
import { clearField, StreetMaskValidator } from '@shared';
import { getError } from 'app/shared/utils/error.utils';
import { StreetErrors } from 'app/shared/errors/error-messages';
import {
  RequestLimit,
  StreetClasses,
  StreetControlName,
  StreetDebounceTime,
  StreetLabel,
  StreetMaxLength,
  StreetMinLength,
  StreetPlaceholder,
} from './street-select.constants';
import { getStreetDescription } from './street-select.utils';
import { StreetSearchResponse, StreetSearchResult } from '@models';
import { AddressService } from '@services';

// Usage: pass existing form control as an input
// <app-street-select [formControl]="this.form.get(CITY_CONTROL_NAME)"></app-street-select>

@Component({
  selector: 'app-street-select',
  templateUrl: './street-select.component.html',
  styleUrls: ['./street-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StreetSelectComponent),
      multi: true,
    },
  ],
})
export class StreetSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  getError = getError;

  clearField = clearField;

  StreetErrors = StreetErrors;

  @Input() formControl: UntypedFormControl | AbstractControl = new UntypedFormControl('', [
    StreetMaskValidator(),
    Validators.required,
  ]);

  @Input() placeholder: string = StreetPlaceholder;

  @Input() label: string = StreetLabel;

  @Input() testId: string = StreetControlName;

  @Input() minLength: number = StreetMinLength;

  @Input() maxLength: number = StreetMaxLength;

  @Input() requestLimit: number = RequestLimit;

  @Input() debounceTime: number = StreetDebounceTime;

  @Input() getStreetDescription = getStreetDescription;

  @Input() clearEnabled: boolean = false;
  @Input()
  @HostBinding('class')
  classList = StreetClasses;
  streets: Array<StreetSearchResult> = [];
  loading: boolean = false;
  streetSubscription: Subscription = null;

  constructor(
    private addressService: AddressService,
    private cd: ChangeDetectorRef,
  ) {}

  private _cityRef: Subject<string> = new Subject<string>();

  cityRef$ = this._cityRef.asObservable();

  @Input() set cityRef(value: string) {
    this._cityRef.next(value);
  }

  get street(): AbstractControl {
    return this.formControl;
  }

  initStreetSubscription(): void {
    if (this.streetSubscription) {
      return;
    }
    this.streetSubscription = this.street?.valueChanges
      .pipe(
        map((q) => (q === null ? '' : q)),
        tap((query) => {
          if (query.length === 0) {
            this.streets = [];
          }
        }),
        filter((v) => v.length > this.minLength - 1),
        debounceTime(this.debounceTime),
        withLatestFrom(this.cityRef$),
      )
      .subscribe(([query, cityRef]) => this.getStreets(query, cityRef));
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }

  ngOnDestroy(): void {
    this.streetSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.initStreetSubscription();
  }

  private getStreets(query: string, cityRef: string): void {
    this.loading = true;
    this.getStreetList(query, cityRef)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((streets) => {
        this.streets = streets.Addresses;
        this.cd.markForCheck();
      });
  }

  private getStreetList(
    name: string,
    localityRef: string,
  ): Observable<StreetSearchResponse> {
    return this.addressService.getStreets(
      { name, localityRef },
      1,
      this.requestLimit,
    );
  }
}

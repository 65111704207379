import {
  Component,
  DoCheck,
  EventEmitter, HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {distinctUntilChanged} from 'rxjs';
import {Subject} from 'rxjs';

export interface AutocompleteControlsItem {
  enabled: boolean;
  tooltip?: string;
}

export interface AutocompleteControls {
  edit?: AutocompleteControlsItem;
  create?: AutocompleteControlsItem;
  clear?: AutocompleteControlsItem;
  delete?: AutocompleteControlsItem;
}

export interface AutocompleteControlsTriggeredEvent {
  triggerName: AutocompleteControlTriggerName;
}

export type AutocompleteControlTriggerName = keyof AutocompleteControls;

@Component({
  selector: 'app-autocomplete-controls',
  templateUrl: 'autocomplete-controls.component.html',
  styleUrls: ['./autocomplete-controls.component.scss'],
})
export class AutocompleteControlsComponent implements OnInit, OnDestroy, OnChanges, DoCheck {

  $configChanged = new Subject();
  @HostBinding('class.compat')
  @Input()
  stylesCompat = false;
  _config: AutocompleteControls;

  @Input()
  config: AutocompleteControls;

  @Input()
  disabled = false;

  @Input()
  intertationalInv;

  @Output()
  onTriggered = new EventEmitter<AutocompleteControlsTriggeredEvent>();

  get edit(): AutocompleteControlsItem {
    return this._config && this._config.edit;
  }

  get clear(): AutocompleteControlsItem {
    return this._config && this._config.clear;
  }

  get create(): AutocompleteControlsItem {
    return this._config && this._config.create;
  }

  get delete(): AutocompleteControlsItem {
    return this._config && this._config.delete;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.$configChanged.next(changes.config);
  }

  ngDoCheck() {
    this.$configChanged.next(this.config);
  }

  ngOnInit() {
    this.$configChanged.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ).subscribe(_config => {
      this._config = _config;
    });
  }

  ngOnDestroy() {
  }

  triggerControl(triggerName: keyof AutocompleteControls): void {
    this.onTriggered.emit({triggerName});
  }
}

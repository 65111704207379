import { AutocompleteWithControlsConfig } from '@autocomplete-with-controls';

export const config: AutocompleteWithControlsConfig = {
  ariaLabel: 'Місто',
  placeholder: 'Місто',
  arrow: false,
};

export const errors = {
  required: "Це поле обов'язкове",
};

export const phoneErrors = {
  required: "Це поле обов'язкове",
  minlength: 'Занадто короткий номер',
};

export enum ControlNames {
  phone = 'phone',
  lastName = 'lastName',
  firstName = 'firstName',
  middleName = 'middleName',
}

export const lastNameAutocompleteConfig: AutocompleteWithControlsConfig = {
  ariaLabel: 'Прізвище',
  placeholder: 'Прізвище',
  arrow: true,
};

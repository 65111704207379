import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[autoScrollTarget]',
})

export class AutoScrollTargetInputDirective {
  @HostListener('blur', ['$event']) onBlur(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.scrollLeft = 0;
  }
}

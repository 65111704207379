<div [formGroup]="form">
  <mat-form-field [attr.data-test-id]="controlName"
                  class="form-input mt-6">
    <mat-label>{{label}}</mat-label>

    <input [formControl]="control"
           matInput
           type="text"
           autocomplete="off"
           class="text-overflow-ellipsis"
           autoScrollTarget>
    <mat-error>{{getError(control, errors)}}</mat-error>
  </mat-form-field>
</div>

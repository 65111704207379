import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@app';
import { IApiError, InvoiceResponseModel, User } from '@models';
import { AlertToastService, AuthService, Features, GoogleAnalyticService } from '@services';
import { TableComponent } from '@shared';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { catchError, filter, finalize, of, Subscription, take, tap } from 'rxjs';
import { InvoiceRegister } from '../../../../models/invoice/invoice-register.model';
import { Invoice } from '../../../../models/invoice/invoice.model';
import { InvoiceApiService } from '../../../../services/invoices/invoice-api.service';
import { InvoiceService } from '../../../../services/invoices/invoice.service';
import { InvoiceRegisterApiService } from '../../../../services/registers/invoice-register.api.service';
import { ShareInvoiceStateService } from '../../../../services/registers/share-invoice-state.service';
import { ConfirmDialogModernConfig } from '../../confirm-dialog-modern/confirm-dialog-modern.component';
import { ConfirmDialogModernService } from '../../confirm-dialog-modern/confirm-dialog-modern.service';
import { PrintingSettingComponent } from '../../pringitng-setting/printing-setting.component';
import { RegisterRenameComponent } from '../register-rename/register-rename.component';
import { RegistersDatasource } from '../registers.datasource';
import { ShareTemplateMessageComponent } from '../share-template-message/share-template-message.component';
import {
  ColsConfRun,
  ColsShowConfig,
  DisplayedColumns,
  IregisterDefaultSortEvent,
  IregisterSortConf,
  PayerNameTransations,
} from './registers-show.constants';

import { RegistersShowDatasource } from './registers-show.datasource';

const componentName = 'app-registers-show';

@Component({
  selector: 'app-registers-show',
  templateUrl: './registers-show.component.html',
  styleUrls: [
    './registers-show.component.scss',
    '../../../../../styles/mat-override/mat-table.common.scss',
  ],
})
export class RegistersShowComponent
  extends TableComponent<InvoiceResponseModel, RegistersShowDatasource>
  implements OnInit, OnDestroy {
  defaultSortEvent = IregisterDefaultSortEvent;
  currentSortEvent: Sort;
  registersDataSource: RegistersDatasource;

  dataSource: RegistersShowDatasource;
  displayedColumns = DisplayedColumns;
  colsShowConfig = ColsShowConfig;
  payerNameTransations = PayerNameTransations;
  colsConf = ColsConfRun;
  disableColumns: any[];
  sortConf = IregisterSortConf;
  itemsPerPage: number = 5;

  storageKey = componentName;
  registerId: string;
  register: InvoiceRegister;

  @Input()
  set registerDescription(value: string) {
    if (this.register) {
      this.register.description = value;
    }
  }

  user: User;
  selection = new SelectionModel<InvoiceResponseModel>(true, []);
  printing70x100: boolean = false;
  printingm100x100: boolean = false;
  invoices: Invoice[];
  printLoad: boolean = false;
  isPrinting: boolean = false;

  @Input()
  customRegisters: InvoiceRegister[];

  @Input()
  testId;

  @Output()
  onRemovedFromRegister = new EventEmitter<string[]>();

  @Output()
  onRemoveRegister = new EventEmitter<void>();

  @Output()
  onPrintRegister = new EventEmitter<string>();

  @Output()
  onRenameRegister = new EventEmitter<string>();

  getInvoiceStatusClass(statusId: number): string {
    switch (+statusId) {
      case 1:
        return '';
      case 5:
        return 'status-col status-col--received';
      case 6:
        return 'status-col status-col--problem';
      case 3:
        return 'delivery-status_in-way';
      case 2:
        return 'delivery-status_in-way';
      default:
        return 'status-col status-col--delivered';
    }
  }

  constructor(
    private alertToastService: AlertToastService,
    protected storage: LocalStorageService,
    public route: ActivatedRoute,
    public router: Router,
    public invoiceApiService: InvoiceApiService,
    public invoiceService: InvoiceService,
    public invoiceRegisterService: InvoiceRegisterApiService,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
    protected authService: AuthService,
    protected shareInvoiceStateService: ShareInvoiceStateService,
    protected googleAnalyticsService: GoogleAnalyticService,
    private confirmService: ConfirmDialogModernService
  ) {
    super();
  }

  private _subscriptions: Subscription[] = [];

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(subscription: any) {
    this._subscriptions.push(subscription);
  }

  get features(): Features {
    return this.authService.features;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.user = this.authService.user;

    this.registerId = this.route.snapshot.params.idRegistries || this.testId;
    this.initRegisterDataSource();
    this.initDataSource();
    this.currentSortEvent = this.defaultSortEvent
      ? this.defaultSortEvent
      : {
        active: 'date',
        direction: 'desc',
      };
    this.initRegister();
    this.setDisplayedColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  setDisplayedColumns(): void {
    const name = this.storageKey;
    const getChangeCol = JSON.parse(localStorage.getItem(name));
    this.displayedColumns = getChangeCol
      ? getChangeCol
      : JSON.parse(JSON.stringify(this.displayedColumns));
  }

  initDataSource(): void {
    this.dataSource = new RegistersShowDatasource(this.invoiceService);
    this.dataSource.loadRegistryShowInvoices(
      '',
      this.defaultSortEvent,
      0,
      this.itemsPerPage,
      this.registerId,
      this.sortConf,
    );
  }

  update(): void {
    this.selection.clear();
    this.dataSource.loadRegistryShowInvoices(
      '',
      this.defaultSortEvent,
      this.paginator.pageIndex,
      this.itemsPerPage,
      this.registerId,
      this.sortConf,
    );
  }

  rename(): void {
    const renameDialog = this.dialog.open(RegisterRenameComponent, {
      data: this.register,
      panelClass: 'no-padding',
      autoFocus: false,
      minWidth: '440px',
      disableClose: true,
    });

    renameDialog.afterClosed().pipe(take(1)).subscribe((result: { success?: boolean, description?: string }) => {
      if (result.success && result.description) {
        this.register.description = result.description;
        this.onRenameRegister.emit(result.description);
      }
    });
  }

  initRegister(): void {
    this.invoiceRegisterService
      .getOne(this.registerId)
      .pipe(tap((register) => (this.register = register)))
      .pipe(take(1))
      .subscribe();
  }

  goToRegisties(): void {
    this.router.navigate(['registries']);
  }

  show(item: InvoiceResponseModel): void {
    this.router.navigate([AppRoutes.invoice_edit, item.Ref]);

    this.googleAnalyticsService.track('analitics', `${componentName}.show`, {
      eventLabel: `Показати посилку з реєстру`,
      eventCategory: componentName,
      eventAction: 'click',
    });
  }

  removeRegister(id: string): void {
    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.removeRegister`,
      {
        eventLabel: `Видалити реєстр`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    this.subscriptions = this.invoiceRegisterService.remove(id)
      .pipe(
        catchError((errors) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.update();
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.removeRegister.error`,
            {
              eventLabel: `Видалити реєстр: ${errors[0]?.messageCode}`,
              eventCategory: componentName,
              eventAction: 'removeRegister.error',
            },
          );
          return of(false);
        }),
        filter((data) => !!data),
        finalize(() => this.onRemoveRegister.next()),
      )
      .subscribe(
        (data) => {
          if (data?.ScanSheetRefs?.Errors?.length) {
            this.alertToastService.pushRegisterRemoveApiErrors(
              Array.from(data?.ScanSheetRefs?.Errors),
              'Помилка розформування',
            );
            this.googleAnalyticsService.track(
              'analitics',
              `${componentName}.removeRegister.error`,
              {
                eventLabel: `Помилка видалення реєстру`,
                eventCategory: componentName,
                eventAction: 'removeRegister.error',
              },
            );
          } else {
            this.alertToastService.pushSuccess('Реєстр успішно розформовано!');
            this.googleAnalyticsService.track(
              'analitics',
              `${componentName}.removeRegister.success`,
              {
                eventLabel: `Видалено реєстр`,
                eventCategory: componentName,
                eventAction: 'removeRegister.success',
              },
            );
          }
        },
      );
  }

  printRegisters(registers: InvoiceRegister[], type, orientation): void {
    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printRegisters`,
      {
        eventLabel: `Друкувати реєстр`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );
    this.printLoad = true;
    if (!registers.length) {
      return;
    }

    const ids = registers.map((register: InvoiceRegister) => register.id);

    this.subscriptions = this.invoiceRegisterService
      .printDocumentsNew(ids, type, orientation)
      .subscribe(
        () => {
          this.printLoad = false;
          this.update();
          this.markRegisterAsPrinted();
          this.cd.detectChanges();
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printRegisters.success`,
            {
              eventLabel: `Друкувати реєстр`,
              eventCategory: componentName,
              eventAction: 'printRegisters.success',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printRegisters.error`,
            {
              eventLabel: `Друкувати реєстр: ${errors[0]?.messageCode}`,
              eventCategory: componentName,
              eventAction: 'printRegisters.error',
            },
          );
        },
      );
  }

  markRegisterAsPrinted(): void {
    this.onPrintRegister.emit('1');
    this.register.printed = '1';
  }

  remove(id: string): void {
    this.googleAnalyticsService.track('analitics', `${componentName}.remove`, {
      eventLabel: `Видалити посилку з реєстру`,
      eventCategory: componentName,
      eventAction: 'click',
    });

    const config: ConfirmDialogModernConfig = {
      title: `Ви справді хочете видалити цю накладну з реєстру?`,
      text: `Після видалення посилку можна додати до реєстру повторно`,
      confirmBtnText: 'Так, видалити',
      cancelBtnText: 'Ні, залишити',
      isAsync: true,
      callback: () => this.handleRemove(id),
    }
    this.confirmService.confirm(config);
  }

  private handleRemove(id: string):void{
    this.tableLoading(true);
    this.subscriptions = this.invoiceRegisterService.deleteInvoices(this.registerId, [id])
      .pipe(finalize(()=>this.confirmService.close()))
      .subscribe(
      (success) => {
        if (success) {
          this.update();
          this.initRegister();
          this.onRemovedFromRegister.emit([id]);
          this.alertToastService.pushSuccess('Успішно видалено з реєстру');
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.remove.success`,
            {
              eventLabel: `Видалено посилку з реєстру`,
              eventCategory: componentName,
              eventAction: 'remove.success',
            },
          );
        }
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
        this.update();
        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.remove.error`,
          {
            eventLabel: `Видалити посилку з реєстру: ${errors[0]?.messageCode}`,
            eventCategory: componentName,
            eventAction: 'remove.error',
          },
        );
      },
    );
  }

  removeSelected(): void {
    if (!this.selection.hasValue()) {
      return;
    }

    const ids = this.selection.selected.map((i: any) => i.Ref);

    const config: ConfirmDialogModernConfig = {
      title: `Ви справді хочете видалити ${this.selection.selected.length > 1 ? 'ці накладні' : 'цю накладну'} з реєстру?`,
      text: `Після видалення ${this.selection.selected.length > 1 ? 'посилки' : 'посилку'} можна додати до реєстру повторно`,
      confirmBtnText: 'Так, видалити',
      cancelBtnText: 'Ні, залишити',
      isAsync: true,
      callback: () => this.handleRemoveSelected(ids),
    }
    this.confirmService.confirm(config);
  }

  private handleRemoveSelected(ids: string[]): void {
    this.subscriptions = this.invoiceRegisterService.deleteInvoices(this.registerId, ids)
      .pipe(finalize(()=>this.confirmService.close()))
      .subscribe(
      () => {
        this.update();
        this.initRegister();
        this.onRemovedFromRegister.emit(ids);
        this.alertToastService.pushSuccess('Успішно видалено з реєстру');
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
        this.update();
      },
    );
  }

  edit(id: string): void {
    this.router.navigate(['invoices_my.edit_invoice'], {
      state: {
        idInvoice: id,
      },
    });
  }

  print(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track('analitics', `${componentName}.print`, {
      eventLabel: `Друк експресс-посилки`,
      eventCategory: componentName,
      eventAction: 'click',
    });

    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '1',
      Copies: '2',
    };
    this.subscriptions = this.invoiceService.printDocuments(invoicesIds, type, props).subscribe(
      () => {
        this.update();

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.print.success`,
          {
            eventLabel: `Роздруковано експресс-посилки`,
            eventCategory: componentName,
            eventAction: 'print.success',
          },
        );
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }
        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.print.error`,
          {
            eventLabel: `Друк експресс-посилки: ${errors[0]?.messageCode}`,
            eventCategory: componentName,
            eventAction: 'print.error',
          },
        );
      },
    );
  }

  printMarking(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printMarking`,
      {
        eventLabel: `Друк маркування 70*100`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    this.subscriptions = this.invoiceService.printMarking(invoicesIds, type).subscribe(
      () => {
        this.update();
        this.markRegisterAsPrinted();
        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printMarking.success`,
          {
            eventLabel: `Роздруковано маркування 70*100`,
            eventCategory: componentName,
            eventAction: 'printMarking.success',
          },
        );
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printMarking.error`,
          {
            eventLabel: `Друк маркування 70*100 ${errors[0]?.errorCode}`,
            eventCategory: componentName,
            eventAction: 'printMarking.error',
          },
        );
      },
    );
  }

  printRegisterTwoCopy(invoices, type): void {
    this.printLoad = true;
    this.printRegister(invoices.list, type);
  }

  printRegister(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }
    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printRegister`,
      {
        eventLabel: `Друк експресс-посилки`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '1',
      Copies: '2',
    };

    this.subscriptions = this.invoiceService.printDocuments(invoicesIds, type, props).subscribe(
      () => {
        this.update();
        this.printLoad = false;
        this.cd.detectChanges();
        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printRegister.success`,
          {
            eventLabel: `Роздруковано експресс-посилки`,
            eventCategory: componentName,
            eventAction: 'printRegister.success',
          },
        );
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printRegister.error`,
          {
            eventLabel: `Друк експресс-посилки: ${errors[0]?.errorCode}`,
            eventCategory: componentName,
            eventAction: 'printRegister.error',
          },
        );
      },
    );
  }

  printRegisterOneCopy(invoices, type): void {
    this.printLoad = true;
    this.printOneCopy(invoices.list, type);
  }

  printOneCopy(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printOneCopy`,
      {
        eventLabel: `Друк експресс-посилки, 1 копія`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    const props = {
      printForm: 'Document_new',
      PageFormat: 'A4',
      Position: '1',
      Copies: '1',
    };
    this.subscriptions = this.invoiceService.printDocuments(invoicesIds, type, props).subscribe(
      () => {
        this.update();
        this.printLoad = false;
        this.cd.detectChanges();
        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printOneCopy.success`,
          {
            eventLabel: `Роздруковано експресс-посилки, 1 копія`,
            eventCategory: componentName,
            eventAction: 'printOneCopy.success',
          },
        );
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printOneCopy.error`,
          {
            eventLabel: `Друк експресс-посилки, 1 копія: ${errors[0]?.errorCode}`,
            eventCategory: componentName,
            eventAction: 'printOneCopy.error',
          },
        );
      },
    );
  }

  printA5Register(invoices, type): void {
    this.printLoad = true;
    this.printA5(invoices.list, type);
  }

  printA5(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }
    const invoicesIds = invoices.map((el: any) => el.Ref);

    this.googleAnalyticsService.track('analitics', `${componentName}.printA5`, {
      eventLabel: `Друк експрес посилки А5`,
      eventCategory: componentName,
      eventAction: 'click',
    });

    this.subscriptions = this.invoiceService
      .printDocuments(invoicesIds, 'pdf', {
        printForm: 'Document_new',
        PageFormat: 'A5',
        Position: '1',
        Copies: '1',
      })
      .subscribe(
        () => {
          this.printLoad = false;
          this.update();
          this.cd.detectChanges();
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printA5.success`,
            {
              eventLabel: `Роздруковано експрес посилки А5`,
              eventCategory: componentName,
              eventAction: 'printA5.success',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printA5.error`,
            {
              eventLabel: `Друк експрес посилки А5: ${errors[0]?.errorCode}`,
              eventCategory: componentName,
              eventAction: 'printA5.error',
            },
          );
        },
      );
  }

  printRegisterMarkingZebraHundred(invoices, type): void {
    this.printLoad = true;
    this.printMarkingZebraHundred(invoices.list, type);
  }

  printMarkingZebraHundred(invoices: Invoice[], type: string): void {
    if (!invoices.length) {
      return;
    }
    this.printLoad = true;
    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printMarkingZebraHundred`,
      {
        eventLabel: `Друк маркування 'Зебра' 100x100`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    this.subscriptions = this.invoiceService.printMarkingZebraHundred(invoicesIds, type)
      .subscribe(
        () => {
          this.printLoad = false;
          this.cd.detectChanges();
          this.update();
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printMarkingZebraHundred.success`,
            {
              eventLabel: `Роздруковано маркування 'Зебра' 100x100`,
              eventCategory: componentName,
              eventAction: 'printMarkingZebraHundred.success',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printMarkingZebraHundred.error`,
            {
              eventLabel: `Друк маркування 'Зебра' 100x100: ${errors[0]?.errorCode}`,
              eventCategory: componentName,
              eventAction: 'printMarkingZebraHundred.error',
            },
          );
        },
      );
  }

  getInvoices(registerId, total): void {
    this.isPrinting = true;
    this.subscriptions = this.registersDataSource
      .loadInvoices('', null, 0, total, registerId)
      .subscribe((invoices) => {
        this.invoices = invoices;
        if (!!this.invoices) {
          this.isPrinting = false;
        }
        this.cd.markForCheck();
      });
  }

  initRegisterDataSource(): void {
    this.registersDataSource = new RegistersDatasource(
      this.invoiceRegisterService,
      this.invoiceService,
    );
  }

  printMarkingZebra(invoices: InvoiceResponseModel[], type: string): void {
    if (!invoices.length) {
      return;
    }

    const invoicesIds = invoices.map((el: InvoiceResponseModel) => el.Ref);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printMarkingZebra`,
      {
        eventLabel: `Друк маркування 'Зебра'`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );

    this.subscriptions = this.invoiceService.printMarkingZebra(invoicesIds, type).subscribe(
      () => {
        this.update();

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printMarkingZebra.success`,
          {
            eventLabel: `Роздруковано маркування 'Зебра'`,
            eventCategory: componentName,
            eventAction: 'printMarkingZebra.success',
          },
        );
      },
      (errors: IApiError[]) => {
        if (errors[0]) {
          this.alertToastService.pushApiErrors(errors);
        }

        this.googleAnalyticsService.track(
          'analitics',
          `${componentName}.printMarkingZebra.error`,
          {
            eventLabel: `Друк маркування 'Зебра': ${errors[0]?.errorCode}`,
            eventCategory: componentName,
            eventAction: 'printMarkingZebra.error',
          },
        );
      },
    );
  }

  printRegisterA4(invoices, value?): void {
    this.printLoad = true;
    this.printSettings(invoices.list, value);
  }

  printSettings(invoices, value?): void {
    const dialogRef = this.dialog.open(PrintingSettingComponent, {
      panelClass: 'no-padding',
    });
    this.printLoad = false;
    if (invoices) {
      dialogRef.componentInstance.invoiceIds = invoices.map(
        (el: InvoiceResponseModel) => el.Ref,
      );
    } else {
      dialogRef.componentInstance.invoiceIds = this.selection.selected.map(
        (inv) => inv.Ref,
      );
    }
    if (value) {
      dialogRef.componentInstance.value = value;
    }

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.update();
        this.cd.detectChanges();
      });
  }

  printSelected(): void {
    if (this.selection.hasValue()) {
      this.print(this.selection.selected, 'pdf');
    }
  }

  copyDocNumber(invoice: InvoiceResponseModel): void {
    this.shareInvoiceStateService.copyInvoiceDocNumber(
      this.dataSource.getBindedInvoice(invoice.Ref),
    );
    this.alertToastService.pushSuccess('Скопійовано');
  }

  shareStatus(invoice: InvoiceResponseModel): void {
    const dialogRef = this.dialog.open(ShareTemplateMessageComponent, {
      panelClass: 'no-padding',
    });

    dialogRef.componentInstance.invoice = invoice;
    dialogRef.componentInstance.estimatedDeliveryDate = moment(
      invoice.EstimatedDeliveryDate,
    ).toDate();
    dialogRef.componentInstance.contactPhone =
      invoice.RecipientContactPhone as string;

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.shareStatus`,
      {
        eventLabel: `Поділитися статусом`,
        eventCategory: componentName,
        eventAction: 'click',
      },
    );
  }
}

// Do not shorthand AddressSettings import below to avoid circular dependency issue
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { checkObjectMask } from '../validators/validators.util';
import { AddressSettings } from '../enums/address.enums';

const addressDoorsKeys = [
  'Ref',
  'CityRef',
  'SettlementRef',
  'SettlementDescription',
  'Type',
  'RegionDescription',
  'AreaDescription',
  'StreetRef',
  'StreetDescription',
  'Description',
  'BuildingNumber',
  'Flat',
  'Floor',
  'Note',
  'AddressName',
  'General',
  'StreetsTypeRef',
  'StreetsType',
];

const ReturnRequestAddressKeys = ['Ref', 'City', 'Address'];

const WarehouseAddressKeys = [
  'Ref',
  'CityRef',
  'CityDescription',
  'AddressDescription',
  'TypeOfWarehouse',
  'General',
  'TotalMaxWeightAllowed',
  'PlaceMaxWeightAllowed',
];

const CityKeys = [
  'Area',
  'DeliveryCity',
  'MainDescription',
  'ParentRegionCode',
  'ParentRegionTypes',
  'Present',
  'Ref',
  'Region',
  'RegionTypes',
  'RegionTypesCode',
  'SettlementTypeCode',
  'StreetsAvailability',
  'Warehouses',
];

const StreetKeys = [
  'Location',
  'Present',
  'SettlementRef',
  'SettlementStreetDescription',
  'SettlementStreetDescriptionRu',
  'SettlementStreetRef',
  'StreetsType',
  'StreetsTypeDescription',
];

const WarehouseKeys = [
  'CategoryOfWarehouse',
  'Description',
  'DescriptionRu',
  'PlaceMaxWeightAllowed',
  'Ref',
  'TotalMaxWeightAllowed',
  'TypeOfWarehouse',
];

export function ValidateAddress(control: AbstractControl) {
  const value = control.value;

  if (!value) {
    return null;
  }

  if (typeof value !== 'object') {
    return { address: true };
  }

  const isValid =
    addressDoorsKeys.every((key) => value.hasOwnProperty(key)) ||
    ReturnRequestAddressKeys.every((key) => value.hasOwnProperty(key)) ||
    WarehouseAddressKeys.every((key) => value.hasOwnProperty(key));

  return isValid ? null : { address: true };
}

export function ValidateCity(control: AbstractControl) {
  const value = control.value;

  if (!value || typeof value !== 'object') {
    return { city: true };
  }

  const isValid = CityKeys.every((key) => value.hasOwnProperty(key));

  return isValid ? null : { city: true };
}

export const StreetMaskValidator = checkObjectMask(StreetKeys);

export const TemplateStreetMaskValidator = checkObjectMask(StreetKeys, true);

export const WarehouseMaskValidator = checkObjectMask(WarehouseKeys);

export const TemplateWarehouseMaskValidator = checkObjectMask(
  WarehouseKeys,
  true,
);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '../../../../../models/invoice/invoice.model';
import { CargoType } from '../../../../../services/invoices/invoice.api';

@Component({
  selector: 'app-invoice-short-info',
  templateUrl: './invoice-short-info.component.html',
  styleUrls: ['./invoice-short-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceShortInfoComponent {
  @Input() invoice: Invoice;
  @Input() isIn: boolean;
  @Input() isOut: boolean;
  CargoType = CargoType;
}

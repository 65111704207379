import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { DoubleDateTableFilter, getError, SmartFormContainer } from '@shared';

@Component({
  selector: 'app-hot-double-date-filter',
  templateUrl: './hot-double-date-filter.component.html',
  styleUrls: ['./hot-double-date-filter.component.scss'],
})
export class HotDoubleDateFilterComponent extends SmartFormContainer implements OnInit {
  readonly getError = getError;
  @Input() config: DoubleDateTableFilter;
  @Input() initialForm: object;
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;
  @Output() filterApplied: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

  get controlFrom(): FormControl {
    return this.form.get(this.config.fromFilter.controlName) as FormControl;
  }

  get controlTo(): FormControl {
    return this.form.get(this.config.toFilter.controlName) as FormControl;
  }

  override ngOnInit(): void {
    if (this.form) {
      this.addFormControls();
    }
  }

  addFormControls(): void {
    if (this.form) {
      this.form.addControl(
        this.config.controlName,
        this.fb.group(
          {
            [this.config.fromFilter.controlName]: this.fb.control(null),
            [this.config.toFilter.controlName]: this.fb.control(null),
          },
          { validators: this.config.validators },
        ),
      );
    }
  }

  resetValue(control: string): void {
    if (this.changed(control)) {
      this.form.get(control).setValue(this.initialForm[control]);
    }
  }

  notChanged(name: string): boolean {
    return this.initialForm[this.config.controlName][name] === this.form.getRawValue()[name];
  }

  changed(name: string): boolean {
    return !this.notChanged(name);
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  clearField(field: AbstractControl, event?: Event, value?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (field.reset) {
      if (value === '' || value === null) {
        field.reset(value);

        return;
      }
      field.reset();
    }
  }

  applyFilterForm(): void {
    this.filterApplied.emit(this.form.value);
    this.menuTrigger.closeMenu();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'np-inv-cancel-control-button',
  templateUrl: 'cancel-control-button.component.html',
  styleUrls: ['./cancel-control-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelControlButtonComponent {
  @Output()
  onCancel = new EventEmitter<void>();

  @Input()
  tooltip = 'Назад';

  cancel(): void {
    this.onCancel.next();
  }
}

import { Injectable } from '@angular/core';
import { AddressTypes } from '@models';
import { AdditionalServicesService } from '@services';
import { Unsubscriber } from '@shared';
import * as moment from 'moment/moment';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { AvailableServiceNames } from '../availableServices';

@Injectable()
export class LocalExpressService extends Unsubscriber {
  tooltip: BehaviorSubject<string> = new BehaviorSubject<string>('');
  recipientCityRef: BehaviorSubject<string> = new BehaviorSubject<string>('');
  DateTime: BehaviorSubject<string> = new BehaviorSubject<string>('');
  timeIntervalSelectionAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Observables
  tooltip$ = this.tooltip.asObservable();
  recipientCityRef$ = this.recipientCityRef.asObservable();
  timeInterval = this.additionalServicesService.localExpressTimeInterval;
  localExpress$ = this.additionalServicesService.localExpress$;
  timeIntervalSelectionAvailable$ = this.timeIntervalSelectionAvailable.asObservable();
  disabled$ = this.disabled.asObservable();
  DateTime$ = this.DateTime.asObservable();

  constructor(private additionalServicesService: AdditionalServicesService) {
    super();
    this.init();
  }

  init(): void {
    this.subscriptions = this.localExpress$
      .pipe(
        switchMap(() =>
          combineLatest([
            this.additionalServicesService.form.valueChanges.pipe(
              distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
              // Verifying the controls exist not to get errors
              filter(() => this.additionalServicesService.form.contains('AddressRecipient')),
              filter(() => this.additionalServicesService.form.contains('AddressSender')),
              filter(() => this.additionalServicesService.form.contains('AdditionalServices')),
            ),
            this.additionalServicesService.AvailableServices$,
          ]),
        ),
      )
      .subscribe(([v]) => this.handleLocalExpress(v));
  }

  private handleLocalExpress({ AddressSender, AddressRecipient, AdditionalServices }): void {
    const availableByGetCounterpartyOptions = this.additionalServicesService.availableByGetCounterpartyOptions(
      AvailableServiceNames.localExpress,
    );

    const availableBySenderAddress = AddressSender.type === AddressTypes.DOORS;
    const availableByPreferredDate = !AdditionalServices.preferredDate.selected;
    const disabled = !(availableByGetCounterpartyOptions && availableBySenderAddress && availableByPreferredDate);

    if (!availableByGetCounterpartyOptions) {
      this.tooltip.next(
        'Послуга «Локал Експрес» надається тільки Бізнес-клієнтам та Інтернет-магазинам за попереднім погодженням з персональним менеджером',
      );
    } else if (!availableByPreferredDate) {
      this.tooltip.next('Не можливо замовити одночасно «Локал Експрес» та «Бажана дата та час доставки»');
    } else if (!availableBySenderAddress) {
      this.tooltip.next('Послуга доступна лише за умови відправки з адреси');
    } else {
      this.tooltip.next('');
    }

    this.recipientCityRef.next(
      AddressRecipient.type !== AddressTypes.INDEX
        ? // case create Address
          AddressRecipient.data?.city?.Ref ||
            // case select Address
            AddressRecipient.data?.SettlementRef
        : null,
    );

    this.DateTime.next(
      AdditionalServices.dateTime.DateTime
        ? moment(AdditionalServices.dateTime.DateTime).format('DD.MM.YYYY')
        : moment().format('DD.MM.YYYY'),
    );

    this.timeIntervalSelectionAvailable.next(
      AddressRecipient.type === AddressTypes.DOORS &&
        this.additionalServicesService.serviceSelected(AvailableServiceNames.localExpress),
    );

    if (disabled) {
      this.additionalServicesService.toggleSelected(AvailableServiceNames.localExpress, false, false);
    }
    this.disabled.next(disabled);
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertToastService, AuthService } from '@services';
import { FormContainer } from '@shared';
import { debounceTime, filter } from 'rxjs';
import { MasterpassService } from '../../../../../services/masterpass.service';
import { PaymentProcessV3Component } from '../../../../dashboard/acquiring/components/payment-process-v3/payment-process-v3.component';
import { DeliveryCostCheckPopupComponent } from '../delivery-cost-check-popup/delivery-cost-check-popup.component';

@Component({
  selector: 'app-secure-payment',
  templateUrl: './secure-payment.component.html',
  styleUrls: ['./secure-payment.component.scss'],
})
export class SecurePaymentComponent extends FormContainer implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  controlName = 'BackwardDeliveryData';

  @Input()
  displaySum = true;

  @Input()
  isInternetOrderConfig = false;

  @Input()
  isChangeEw = false;

  @Input()
  changeEwSenderPhone;

  @Input()
  CanChangeCash2Card = false;

  @Input()
  invoiceNumber: string | number;

  @Input()
  invoiceId: string;

  @Input()
  resetInvoiceNumber: boolean;

  addCardTooltip: string = '';
  BackwardDeliveryDataGroup: UntypedFormGroup;
  backDeliveryGroup: UntypedFormGroup;
  minCostCash2Card = 1;
  maxCostCash2Card = 29999;
  targetValue: number;
  announcedCost: number;
  pendingAddCard = false;

  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private masterpassService: MasterpassService,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  private _invoiceCopyState = false;

  get invoiceCopyState(): boolean {
    return this._invoiceCopyState;
  }

  @Input() set invoiceCopyState(value: boolean) {
    this._invoiceCopyState = value;
    this.getAddCardTooltip(value);
  }

  get card() {
    return this.BackwardDeliveryDataGroup.get('card');
  }

  set card(card) {
    this.BackwardDeliveryDataGroup.get('card').setValue(card);
  }

  get number() {
    return this.form.get('AdditionalServices.securePayment.number')?.value;
  }

  get costControl(): AbstractControl {
    return this.form?.parent?.get('stepDeliveryForm')?.get('Cost');
  }

  get costValue(): number {
    return !isNaN(this.costControl?.value) ? this.costControl.value : 0;
  }

  get senderName(): string | null {
    return this.form.get('ContactSender') ? this.form.get('ContactSender').value.Description : null;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  change(event) {
    this.targetValue = event.target.value.replace(/,/gi, '.');
    if (+this.targetValue > this.costValue) {
      this.openDialog(this.targetValue, this.costValue);
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions = this.BackwardDeliveryDataGroup.get('RedeliveryString')
      .valueChanges.pipe(
        debounceTime(1000),
        filter(() => !!this.costControl),
      )
      .subscribe(() => (this.announcedCost = this.costValue));
  }

  openDialog(value, announcedCost) {
    const dialogRef: MatDialogRef<DeliveryCostCheckPopupComponent> = this.dialog.open(DeliveryCostCheckPopupComponent, {
      panelClass: 'no-padding',
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.cost = value;
    dialogRef.componentInstance.announcedCost = announcedCost;
    dialogRef.componentInstance.type = 'securePayment';

    this.subscriptions = dialogRef.componentInstance.onAdd.subscribe(() => {
      if (+value > +announcedCost && !this.isChangeEw) {
        this.costControl.setValue(value);
        this.costControl.updateValueAndValidity({ onlySelf: true });
      }
    });

    this.subscriptions = dialogRef.componentInstance.onClear.subscribe(() => {
      this.BackwardDeliveryDataGroup.get('RedeliveryString').setValue(this.costValue);
      this.BackwardDeliveryDataGroup.get('RedeliveryString').updateValueAndValidity({ onlySelf: true });
    });

    dialogRef.afterClosed();
  }

  updateRef(ref: string) {
    if (this.BackwardDeliveryDataGroup) {
      this.BackwardDeliveryDataGroup.get('CustomBackwardDeliveryParameter').setValue(ref);
    }
  }

  addFormControls(): void {
    this.backDeliveryGroup = this.form.get('AdditionalServices').get('securePayment') as UntypedFormGroup;

    this.BackwardDeliveryDataGroup = this.formBuilder.group({
      PayerType: ['Recipient', [Validators.required]],
      CargoType: ['Money', [Validators.required]],
      CustomBackwardDeliveryParameter: [''],
      RedeliveryString: [
        this.getSecurePaymentAmount(this.costValue),
        [Validators.required, Validators.min(this.minCostCash2Card), Validators.max(this.maxCostCash2Card)],
      ],
      ReceivingType: ['Card', [Validators.required]],
      ReceivingData: [{}],
      InitialCard: '',
      card: null,
      Cash2CardPayout_Id: '',
      Cash2CardAlias: '',
      Cash2CardPAN: '',
      Cash2CardCreditSid: '',
    });
    this.backDeliveryGroup.addControl(this.controlName, this.BackwardDeliveryDataGroup);
  }

  getSecurePaymentAmount(announcedCost: number, maxValue: number = this.maxCostCash2Card): number {
    if (announcedCost) {
      return announcedCost > maxValue ? maxValue : announcedCost;
    }

    return 0;
  }

  changeSum(value) {
    if (Number(value) > this.maxCostCash2Card) {
      this.BackwardDeliveryDataGroup.patchValue({
        ReceivingType: 'Warehouse',
      });
      this.BackwardDeliveryDataGroup.patchValue({
        InitialCard: '',
        card: null,
        Cash2CardPayout_Id: '',
        Cash2CardAlias: '',
        Cash2CardPAN: '',
        Cash2CardCreditSid: '',
      });
    }
  }

  openAddCardModal(): void {
    this.pendingAddCard = true;
    let invoiceNumber;

    if (this.resetInvoiceNumber) {
      invoiceNumber = null;
    } else if (this.invoiceId) {
      invoiceNumber = this.invoiceNumber || this.number;
    } else {
      invoiceNumber = null;
    }
    this.subscriptions = this.masterpassService
      .initAddCard(
        this.isChangeEw
          ? this.changeEwSenderPhone
          : this.form.get('ContactSender.data').value.Phones || this.form.get('ContactSender.data').value.phone,
        invoiceNumber,
      )
      .subscribe(
        (data) => {
          const dialogRef = this.dialog.open(PaymentProcessV3Component, {
            position: { right: '0' },
            width: '75%',
            height: '100%',
            panelClass: 'setting-content-popup',
          });
          dialogRef.componentInstance.url = `${data.Url}&lang=ua`;
          dialogRef.componentInstance.addCardSuccess.subscribe((cardData: any) => {
            this.pendingAddCard = false;
            this.BackwardDeliveryDataGroup.patchValue({
              Cash2CardPayout_Id: cardData.id,
              Cash2CardAlias: cardData.alias,
              Cash2CardPAN: cardData.pan,
              card: cardData,
            });
            if (!this.form.get('AdditionalServices.securePayment.number')) {
              (this.form.get('AdditionalServices.securePayment') as UntypedFormGroup).addControl(
                'number',
                new UntypedFormControl(data.IntDocNumber),
              );
            } else {
              this.form.get('AdditionalServices.securePayment.number').setValue(data.IntDocNumber);
            }
            dialogRef.close();
          });
          this.subscriptions = dialogRef.afterClosed().subscribe(() => {
            this.pendingAddCard = false;
            this.cd.detectChanges();
          });
        },
        (errors) => {
          this.alertToastService.pushApiErrors(errors);
          this.pendingAddCard = false;
          this.cd.detectChanges();
        },
      );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.backDeliveryGroup.removeControl(this.controlName);
  }

  getAddCardTooltip(value: boolean): void {
    if (value) {
      this.addCardTooltip =
        'З міркувань безпеки, при дублюванні накладної необхідно повторно обрати картку для зарахування переказу Сейф-сервіс';
      return;
    }
    if (this.isInternetOrderConfig) {
      this.addCardTooltip =
        'Обрати картку для зарахування переказу Сейф-сервіс можливо лише при створенні відправлення';
      return;
    }
    this.addCardTooltip = null;
  }
}

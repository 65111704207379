<div *ngIf="config" class="filter__buttons p-2">
  <button (click)="clearFilters()"
          *ngIf="config.buttons.clearFilters"
          [attr.data-test-id]="config.buttons.clearFilters.testId"
          [autofocus]="config.buttons.clearFilters.autofocus"
          [class.hidden]="!(isDirty || isFiltered) || isLoading"
          [class]="config.buttons.clearFilters.class"
          mat-button>
    {{ config.buttons.clearFilters.text }}
  </button>

  <button (click)="applyFilters()"
          *ngIf="config.buttons.applyFilters"
          [attr.data-test-id]="config.buttons.applyFilters.testId"
          [color]="config.buttons.applyFilters.color"
          [disabled]="!(isValid && (isFiltered || isDirty)) || isLoading"
          mat-raised-button>
    {{ config.buttons.applyFilters.text }}
  </button>
</div>

<div class="modal modal_full-height">
    <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); close()">
        <i class="material-icons">close</i>
    </span>
  <div class="modal-header">
    <div class="form-row">
      <div class="form-col form-col-8">
        <h3 class="modal-header__title">Заявку {{ data.barcode }} на виклик машини успішно {{ label }}</h3>
      </div>
    </div>
  </div>

  <mat-progress-bar [style.opacity]="+(loading$ | async)" mode="indeterminate"></mat-progress-bar>

  <main class="modal-content">
    <div class="form-row mr-bottom-16">
      <div class="form-col form-col-6">
        <p class="secondary-color modal-text mt-8 mb-4">
          <ng-container *ngIf="(loading$ | async); else added">
            Зачекайте, ми додаємо посилку(и) до заявки
          </ng-container>
        </p>

        <ng-template #added>
          {{ hasError ? 'Посилка(и) не додані до заявки' : 'Посилка(и) додані до заяки' }}
        </ng-template>
      </div>
    </div>
  </main>
</div>

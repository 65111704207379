<button class="np-table-col-filter-menu-trigger" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon [ngStyle]="{'color': color}">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div *ngFor="let item of cols; trackBy: trackByFn">
    <button class="np-table-col-filter-button" [disabled]="item.disabled" (click)="onChange($event, item)" mat-menu-item>
      <mat-icon>{{item.show ? 'check' : ''}}</mat-icon>
      <span>{{item.name}}</span>
    </button>
  </div>
</mat-menu>

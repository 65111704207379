import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  AddressTypes,
  ApiAddress,
  CategoryOfWarehouseEnum,
  CitySearchResponse,
  Contact,
  IAddressFilterParam,
  IListData,
  IOrderParameter,
  Locality,
  Region,
  Schedule,
  State,
  Street,
  StreetSearchResponse,
  Warehouse,
  WarehouseResult,
  WarehouseTypeName,
} from '@models';
import { AddressSettings } from '@shared';
import * as _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs';
import { AddressContactPersonGeneralSaveResponse } from '../models/address.service.models';
import { AuthService } from './auth.service';
import { ApiMethods } from './api/apiMethods';
import { ConfigService } from './config.service';
import { NoRestApiHelper } from './no-rest-api.helper';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  STORAGE_LIVING_ADDRESSES_KEY = 'livingAddresses';
  private _privateContragentId: string = null;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
    private authService: AuthService,
  ) {
  }

  get user() {
    return this.authService.user;
  }

  get livingAddresses() {
    const addresses = localStorage.getItem(
      `${this.STORAGE_LIVING_ADDRESSES_KEY}_${this.user.userLogin}`,
    );
    return addresses && Array.isArray(JSON.parse(addresses))
      ? JSON.parse(addresses)
      : [];
  }

  getLivingAddresses(): Observable<any[]> {
    return of(this.livingAddresses);
  }

  saveLivingAddress(address): void {
    const addresses = this.livingAddresses;
    addresses.push(address);
    localStorage.setItem(
      `${this.STORAGE_LIVING_ADDRESSES_KEY}_${this.user.userLogin}`,
      JSON.stringify(addresses),
    );
  }

  getCities(
    filter: any,
    page = 1,
    limit = 100,
  ): Observable<CitySearchResponse> {
    const postObject: any = {
      system: this.configService.get('system'),
      modelName: 'Address',
      calledMethod: 'searchSettlements',
      methodProperties: {
        CityName: filter.name,
        Limit: limit,
        Page: page,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as CitySearchResponse),
      );
  }

  getWarehousesByIndex(WarehouseIndex: string): Observable<WarehouseResult[]> {
    return this._getWarehouses({ WarehouseIndex });
  }

  getWarehouses(
    cityRef: string,
    typeOfWarehouseRef?: string,
    warehouseForAgent?: boolean,
    forSender?: boolean,
    CategoryOfWarehouse?: CategoryOfWarehouseEnum,
  ): Observable<WarehouseResult[]> {
    return this._getWarehouses({
      CityRef: cityRef,
      ...(warehouseForAgent !== undefined
        ? { WarehouseForAgent: warehouseForAgent }
        : {}),
      ...(CategoryOfWarehouse ? { CategoryOfWarehouse } : {}),
      ...(forSender ? { PostomatFor: 'Sender' } : {}),
      ...(typeOfWarehouseRef ? { TypeOfWarehouseRef: typeOfWarehouseRef } : {}),
    });
  }

  getStreets(
    filter: any,
    page = 1,
    limit = 10,
  ): Observable<StreetSearchResponse> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'Address',
      calledMethod: 'searchSettlementStreets',
      methodProperties: {
        StreetName: filter.name,
        SettlementRef: filter.localityRef,
        Limit: limit,
        Page: page,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0] as StreetSearchResponse),
      );
  }

  getOneAddress(ref: string, contactRef: string = null): Observable<any> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AddressContactPersonGeneral',
      calledMethod: 'getAddressByRef',
      methodProperties: {
        ContactPersonRef: contactRef,
        Ref: ref,
      },
    };
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response),
      );
  }

  addAddressToContact(address: Partial<ApiAddress>, contactRef: string): Observable<AddressContactPersonGeneralSaveResponse> {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AddressContactPersonGeneral',
      calledMethod: ApiMethods.save,
      methodProperties: {
        ContactPersonRef: contactRef,
        ...this.mapAddressToMethodProperties(address),
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0]),
      );
  }

  getAddresses(contactRef: string, page = 1, limit = 20) {
    const postObject = {
      system: this.configService.get('system'),
      modelName: 'AddressContactPersonGeneral',
      calledMethod: 'getAddresses',
      methodProperties: {
        Page: page - 1,
        limit,
        ContactPersonRef: contactRef,
      },
    };

    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data[0]),
      );
  }

  mapAddressToMethodProperties(address: Partial<ApiAddress>): ApiAddress {
    const methodProperties: any = {};

    methodProperties.SettlementRef = address.SettlementRef;
    methodProperties.AddressRef = address.AddressRef;
    methodProperties.AddressType =
      address.AddressType === AddressTypes.DOORS
        ? AddressTypes.DOORS
        : AddressTypes.WAREHOUSE;
    if (methodProperties.AddressType === AddressTypes.DOORS) {
      methodProperties.BuildingNumber = address.BuildingNumber;
      methodProperties.Flat = address.Flat;
      methodProperties.Floor = address.Floor;
      methodProperties.Note = address.Note;
      methodProperties.General = address.General;
    }

    return methodProperties as ApiAddress;
  }

  getList(
    filter: IAddressFilterParam = {},
    order: IOrderParameter = null,
    page = 1,
    limit = 20,
  ): Observable<IListData<Address>> {
    let promise: any;
    const storedLimit = limit;

    if (filter.onlyAdresses || filter.onlyWarehouse || filter.isNoPostomat) {
      limit = 1000;
    }

    if (!filter.contactId) {
      promise = this.http
        .post(`${this.configService.get('apiUrl')}json/`, {
          system: this.configService.get('system'),
          modelName: 'ContactPersonGeneral',
          calledMethod: 'getContactPersonsList',
          methodProperties: {
            Page: page - 1,
            limit,
            CounterpartyRef:
              this.authService.user && this.authService.user?.contragent?.id,
          },
        })
        .pipe(
          map((data: any) => {
            filter.contactId = data.data.data[0].Ref;

            return this.http.post(`${this.configService.get('apiUrl')}json/`, {
              system: this.configService.get('system'),
              modelName: 'AddressContactPersonGeneral',
              calledMethod: 'getAddresses',
              methodProperties: {
                Page: page - 1,
                limit,
                ContactPersonRef: filter.contactId,
                CityRef: filter.cityId,
              },
            });
          }),
        );
    } else {
      promise = this.http.post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'getAddresses',
        methodProperties: {
          Page: page - 1,
          limit,
          ContactPersonRef: filter.contactId,
          CityRef: filter.cityId,
        },
      });
    }

    return promise.pipe(
      map((data) => this.noRestApiHelper.checkErrors({ data })),
      map((data) => this._bindListToModel(data, filter)),
      map((data: IListData<Address>) => {
        if (data.list.length > storedLimit) {
          data.list.length = storedLimit;
        }

        return data;
      }),
    );
  }

  getPrivatConntragentRef(): Observable<string> {
    return from(this._getContragentsPromise());
  }

  _getContragentsPromise(): Promise<any> {
    let contragentsPromise: any;

    if (this._privateContragentId) {
      contragentsPromise = this._privateContragentId;
    } else {
      contragentsPromise = this.http
        .post(`${this.configService.get('apiUrl')}json/`, {
          system: this.configService.get('system'),
          modelName: 'Counterparty',
          calledMethod: 'getCounterparties',
          methodProperties: {
            CounterpartyProperty: 'Recipient',
          },
        })
        .pipe(map((data) => this.noRestApiHelper.checkErrors(data)))
        .subscribe((data: any) => {
          this._privateContragentId = _.find(
            data.data.data,
            (el: any) => el.CounterpartyType === 'PrivatePerson',
          ).Ref;

          return this._privateContragentId;
        });
    }

    return contragentsPromise;
  }

  getOne(id: string, contactId: string = null): Observable<Address> {
    let promise: any;

    if (!contactId) {
      promise = this.http
        .post(`${this.configService.get('apiUrl')}json/`, {
          system: this.configService.get('system'),
          modelName: 'ContactPersonGeneral',
          calledMethod: 'getContactPersonsList',
          methodProperties: {
            Page: 1,
            limit: 10,
            CounterpartyRef:
              this.authService.user && this.authService.user.contragent.id,
          },
        })
        .pipe(
          map((data: any) => {
            contactId = data.data.data[0].Ref;

            return this.http.post(`${this.configService.get('apiUrl')}json/`, {
              system: this.configService.get('system'),
              modelName: 'AddressContactPersonGeneral',
              calledMethod: 'getAddressByRef',
              methodProperties: {
                ContactPersonRef: contactId,
                Ref: id,
              },
            });
          }),
        );
    } else {
      promise = this.http.post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'getAddressByRef',
        methodProperties: {
          ContactPersonRef: contactId,
          Ref: id,
        },
      });
    }

    return promise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this._bindOneToModel(data)),
    );
  }

  remove(id: string): Observable<boolean> {
    return new Observable<boolean>();
  }

  removeAddress(contactRef: string, refAddress: string): Observable<Address> {
    const addPromise: any = this.http.post(
      `${this.configService.get('apiUrl')}json/`,
      {
        system: this.configService.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'delete',
        methodProperties: {
          Ref: refAddress,
          ContactPersonRef: contactRef,
        },
      },
    );

    return from(addPromise).pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this._bindOneToModel(data)),
    );
  }

  add(address: Address): Observable<Address> {
    const addPromise: any = this.http.post(
      `${this.configService.get('apiUrl')}json/`,
      {
        system: this.configService.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'save',
        methodProperties: this._addressToMethodParams(address),
      },
    );

    return addPromise.pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      switchMap((data: any) =>
        this.getOne(data.data.data[0].Ref, data.data.data[0].ContactPersonRef),
      ),
    );
  }

  update(address: Address): Observable<Address> {
    const updatePromise: any = this.http.post(
      `${this.configService.get('apiUrl')}json/`,
      {
        system: this.configService.get('system'),
        modelName: 'AddressContactPersonGeneral',
        calledMethod: 'update',
        methodProperties: this._addressToMethodParams(address),
      },
    );

    return from(updatePromise).pipe(
      map((data) => this.noRestApiHelper.checkErrors(data)),
      map((data) => this._bindOneToModel(data)),
    );
  }

  autocomplateCity(query: string, limit: number): Observable<string[]> {
    const cities: string[] = [];

    const service = new (
      window as any
    ).google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: query,
        types: ['(cities)'],
        componentRestrictions: { country: 'UA' },
        language: 'uk',
      },
      (data) => console.log(data),
    );

    return of(cities);
  }

  _detectTypeWarehouse(idType: string): WarehouseTypeName {
    switch (idType) {
      case '6f8c7162-4b72-4b0a-88e5-906948c6a92f':
        return 'NEW_POST';
      case '9a68df70-0267-42a8-bb5c-37f427e36ee4':
        return 'NEW_POST';
      case '841339c7-591a-42e2-8233-7a0a00f0ed6f':
        return 'NEW_POST';
      case '95dc212d-479c-4ffb-a8ab-8c1b9073d0bc':
        return 'POSTBOX';
      case AddressSettings.POSTBOX_TYPE:
        return 'POSTBOX';
      case 'cab18137-df1b-472d-8737-22dd1d18b51d':
        return 'IN_POST';
      default:
        return 'OTHER';
    }
  }

  updateAddressWarehouse(address: Address): Observable<Address> {
    address.type = 'Warehouse';
    address.building = null;
    address.flat = null;
    address.floor = null;
    return this.update(address);
  }

  updateAddressDoors(address: Address): Observable<Address> {
    address.type = 'Doors';
    return this.update(address);
  }

  addAddressWarehouse(address: Address): Observable<Address> {
    address.type = 'Warehouse';
    address.building = null;
    address.flat = null;
    address.floor = null;
    return this.add(address);
  }

  addAddressDoors(address: Address): Observable<Address> {
    address.type = 'Doors';
    return this.add(address);
  }

  protected _bindListToModel(
    data: any,
    filter: IAddressFilterParam,
  ): IListData<Address> {
    let addresses: any[] = [];
    if (!filter.onlyAdresses) {
      addresses.push(...data.data.data[0].Warehouses);
    }
    if (!filter.onlyWarehouse) {
      addresses.push(...data.data.data[0].Addresses);
    }

    if (filter.isNoPostomat) {
      addresses = _.filter(
        addresses,
        (address) =>
          this._detectTypeWarehouse(address.TypeOfWarehouse) !== 'POSTBOX',
      );
    }

    return {
      list: _.map(addresses, (el: any) => {
        const address = new Address(el.Ref);

        address.street = new Street(el.StreetRef);
        address.street.name = el.StreetDescription;
        address.street.locality = new Locality(el.CityRef || el.SettlementRef);
        address.street.locality.id2 = el.SettlementRef;
        address.street.locality.name =
          el.SettlementDescription || el.CityDescription || el.CityRef;
        address.street.locality.typeName = el.Type;
        address.city = address.street.locality;
        address.building = el.BuildingDescription || el.BuildingNumber;
        address.street.type = el.StreetsType;
        address.flat = el.Flat;
        address.warehouseNumber = el.WarehouseNumber;
        address.note = el.Note;
        address.noteAddressRecipient = el.NoteAddressRecipient;

        if (el.PlaceMaxWeightAllowed && el.PlaceMaxWeightAllowed !== '0') {
          address.placeMaxWeightAllowed = +el.PlaceMaxWeightAllowed;
        }

        if (el.TotalMaxWeightAllowed && el.TotalMaxWeightAllowed !== '0') {
          address.totalMaxWeightAllowed = +el.TotalMaxWeightAllowed;
        }

        address.description = el.Description || el.AddressDescription;
        if (el.AddressDescription && el.CityDescription) {
          address.description = address.description + ', ' + el.CityDescription;
        }

        address.type =
          address.street && address.street.id ? 'Doors' : 'Warehouse';
        address.typeWarehouse = this._detectTypeWarehouse(el.TypeOfWarehouse);

        // address.description = address.description.replace('Відділення ', '').replace('відділення ', '');
        return address;
      }),
      total: 100,
    };
  }

  protected _bindWarehousesListToModel(data: any): IListData<Warehouse> {
    return {
      list: _.map(data.data.data, (el: any) => {
        const warehouse = new Warehouse(el.Ref);
        warehouse.description = el.Description;
        warehouse.descriptionRu = el.DescriptionRu;
        warehouse.city = new Locality(el.CityRef);
        warehouse.city.description = el.CityDescription || el.CityRef;
        warehouse.city.name = el.CityDescription || el.CityRef;
        warehouse.buildingNumber = el.Number;
        warehouse.phone = el.Phone;
        warehouse.type = el.Phone;
        warehouse.schedule = new Schedule();
        warehouse.warehouseNumber = el.WarehouseNumber || el.Number;

        warehouse.type = this._detectTypeWarehouse(el.TypeOfWarehouse);

        warehouse.description = warehouse.description
          .replace('Відділення ', '')
          .replace('відділення ', '')
          .replace('Поштомат ', '');

        return warehouse;
      }),
      total: data.data.info.totalCount,
    };
  }

  protected _bindCitiesListToModel(data: any): IListData<Locality> {
    return {
      list: _.map(data.data.data[0].Addresses, (el: any) => {
        const city = new Locality(el.DeliveryCity);
        city.id2 = el.Ref;
        city.name = el.MainDescription;
        city.description = el.SettlementTypeCode + ' ' + el.MainDescription;
        city.typeName = el.SettlementTypeCode;
        city.warehousesCount = el.Warehouses;
        /*city.state = new State();
        city.state.name = el.Region;*/
        city.state = new State();
        city.state.name = el.Area ? el.Area : el.Region;
        city.region = new Region();
        city.region.name = el.Area ? el.Region : null;
        city.region.description = el.Area
          ? `${el.Region}, ${el.Area}`
          : el.Region;
        city.fullName = el.Present;
        return city;
      }),
      total: data.data.data[0].TotalCount,
    };
  }

  protected _bindStreetsListToModel(data: any): IListData<Street> {
    return {
      list: _.map(data.data.data[0].Addresses, (el: any) => {
        const street = new Street(el.SettlementStreetRef);
        street.name = el.SettlementStreetDescription;
        street.description = el.Present;
        street.type = el.StreetsTypeDescription;
        street.locality = new Locality(el.SettlementRef);
        return street;
      }),
      total: data.data.data[0].TotalCount,
    };
  }

  protected _bindOneToModel(response: any): Address {
    let address: Address;

    if (response.data.success) {
      const el: any = response.data.data[0];

      address = new Address(el.Ref);
      address.street = new Street(el.StreetRef);
      address.street.name = el.StreetDescription;
      address.street.description = el.StreetDescription;
      address.street.locality = new Locality(el.CityRef);
      address.street.locality.id2 = el.SettlementRef;
      address.street.locality.name =
        el.SettlementDescription || el.CityDescription;
      address.street.locality.description = address.street.locality.name;
      address.street.locality.typeName = el.Type;
      address.city = address.street.locality;
      address.building = el.BuildingNumber;
      address.flat = el.Flat;
      address.note = el.Note;
      address.city = address.street.locality;
      address.noteAddressRecipient = el.NoteAddressRecipient;
      address.type = el.AddressType;

      if (!address.city.warehousesCount && address.type === 'Warehouse') {
        address.city.warehousesCount = 1;
      }

      address.warehouseNumber =
        el.WarehouseNumber ||
        (el.TypeOfWarehouse && 'None kostil need from api');

      address.description =
        el.Description ||
        (el.AddressDescription
          ? el.AddressDescription +
          (el.CityDescription ? ', ' + el.CityDescription : '')
          : '');
      address.contactPerson = new Contact(null, null, el.ContactPersonRef);

      if (el.TypeOfWarehouse) {
        address.typeWarehouse = this._detectTypeWarehouse(el.TypeOfWarehouse);
        address.setFullLoaded(true);
      }

      if (address.type === 'Warehouse') {
        delete address.street;
      }

      let maxWeigth = parseFloat(el.TotalMaxWeightAllowed);
      if (maxWeigth) {
        address.totalMaxWeightAllowed = maxWeigth;
      }

      maxWeigth = parseFloat(el.PlaceMaxWeightAllowed);
      if (maxWeigth) {
        address.placeMaxWeightAllowed = maxWeigth;
      }

      address.setFullLoaded(true);
    }

    return address;
  }

  protected _addressToMethodParams(address: Address): any {
    const methodParams: any = {};

    methodParams.ContactPersonRef =
      address.contactPerson?.id ?? address.contactPersonRef;
    if (address.id) {
      methodParams.Ref = address.id;
    }
    methodParams.SettlementRef = address.city.id2;
    methodParams.AddressRef = address.street.id;
    methodParams.AddressType = address.type;
    if (address.type === 'Doors') {
      methodParams.BuildingNumber = address.building;
      methodParams.Flat = address.flat;
      methodParams.Floor = address.floor;
      methodParams.Note = address.note;
      methodParams.General = address.general;
      methodParams.NoteAddressRecipient = address.noteAddressRecipient;
    }

    return methodParams;
  }

  private _getWarehouses(
    methodProperties: object,
  ): Observable<WarehouseResult[]> {
    return this.http
      .post(`${this.configService.get('apiUrl')}json/`, {
        system: this.configService.get('system'),
        modelName: 'AddressGeneral',
        calledMethod: ApiMethods.getWarehouses,
        methodProperties,
      })
      .pipe(
        map((data) => this.noRestApiHelper.checkErrors({ data })),
        map((response) => response.data.data as WarehouseResult[]),
      );
  }

  private getWarehouseTypes() {
  }
}

import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

export function isInt(value): boolean {
  return !isNaN(value) && parseInt('' + Number(value), 10) === value && !isNaN(parseInt(value, 10));
}

export function isNullish(value): boolean {
  return value === null || value === undefined || value === '';
}

export function formatUAPhone(value: string): string {
  let fP = '';
  if (value && typeof value === 'string') {
    if (value.length === 19) {
      return value;
    }

    fP = value.replace(/^\+/, '').replace(/^38/, '');
    if (fP.length === 14) {
      fP = parseNumbers(fP);
    }
    if (fP.length === 10) {
      fP = `+380 (${fP[1]}${fP[2]}) ${fP[3]}${fP[4]}${fP[5]}-${fP[6]}${fP[7]}-${fP[8]}${fP[9]}`;
    }
  }
  return fP;
}

export function convertToNumber(value) {
  return +value;
}

export function processColumns(data, columnsConfig) {
  return data.map((el) =>
    columnsConfig
      .map((column) => ({
        ...column,
        keys: (typeof column.key === 'object'
               ? column.key
               : [column.key]
        ).filter((key) => _.has(el, key)),
      }))
      .reduce((acc, { keys, name, transform }) => {
        const value = keys.map((key) => _.get(el, key, '')) || '';
        return {
          ...acc,
          [name]: transform ? transform(...value, el) : value.join(' '),
        };
      }, {}),
  );
}

export function saveAsCSV(data, type, filename = 'export.csv') {
  if (data.length) {
    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);
    filename =
      'report_' +
      type +
      '_' +
      moment().format('DD-MM-YYYY') +
      '_' +
      moment().format('HH-mm') +
      '.csv';
    saveAs(new Blob([csv], { type: 'text/plain;charset=utf-8' }), filename);
  }
}

export function createXLSX(data): any {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  return XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'array',
  });
}

export function saveAsXLSX(data, type): void {
  const xlsx = data.length ? createXLSX(data) : data;
  const filename =
    'report_' +
    type +
    '_' +
    moment().format('DD-MM-YYYY') +
    '_' +
    moment().format('HH-mm') +
    '.xlsx';
  saveAs(new Blob([xlsx], { type: 'application/octet-stream' }), filename);
}

export function parseNumbers(text: string): string {
  if (!text) {
    return '';
  }
  return text.replace(/\D/gi, '');
}

export function createShortNote(note: string): string {
  if (note && typeof note === 'string') {
    let sliced = note?.slice(0, 75);
    if (sliced.length < note.length) {
      sliced += '...';
    }
    return sliced;
  }
  return '';
}

export function capitalize(text: string): string {
  if (!text.length) {
    return '';
  }

  return text[0]?.toUpperCase() + text.substring(1);
}

export function objectIsEmpty(object: object): boolean {
  return !!object && !Object.keys(object).length;
}

export function isNumeric(value: string | number): boolean {
  return /^\d+$/.test(String(value));
}

export function roundSum(a: string, b: string): number {
  const v = parseFloat(a) + parseFloat(b);
  if (v > 0) {
    return Math.round(v * 100) / 100;
  }
  return 0;
}

export function datesEqual(d1: string, d2: string): boolean {
  const date1 = new Date(d1).setHours(0, 0, 0, 0);
  const date2 = new Date(d2).setHours(0, 0, 0, 0);

  return date1 === date2;
}

export function checkPricingValue(value: number | string): number {
  if (value === null || value === undefined || value === '') {
    return -1;
  } else {
    return +value;
  }
}

export function trimSpaces(value: string): string {
  return value?.trim().trimStart() ?? '';
}

export function getStringLength(value: string): number {
  // https://stackoverflow.com/a/54369605
  // str.length gives the count of UTF-16 units.
  // Unicode-proof way to get string length in codepoints (in characters)
  // is [...str].length as iterable protocol splits the string to codepoints.
  return value ? [...value].length : 0;
}

export function trimMaxLength(value: string, length: number): string {
  return value.substring(0, length);
}

import { Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { RadioTableFilter, TableChip, TableFilter, TableFilterConfig } from '@shared';

export const ProductBalancesArticleFilter: TableFilter = {
  title: 'Артикул',
  controlName: 'article',
  type: 'input',
  testId: 'inputFilter',
  initialValue: '',
  apiFieldName: 'ArticleList',
  validators: [Validators.minLength(1), Validators.maxLength(50)],
  errors: {
    maxlength: 'Максимальна довжина 50 символів',
    minlength: 'введіть, будь ласка, повний артикул',
  },
  placeholder: 'Введіть артикул',
  hotFilter: {
    quickButtonText: 'Артикул',
    overlayTitle: 'Артикул',
    overlayButtonText: 'Знайти у залишках',
  },
};

export const ProductBalancesStatusFilter: RadioTableFilter = {
  title: 'Статус номенклатури',
  type: 'radio',
  testId: 'product-balances-status-filter',
  errors: {},
  placeholder: '',
  controlName: 'status',
  initialValue: 'Всі',
  apiFieldName: 'StatusList',
  validators: [],
  options: [
    { value: 'Всі', label: 'Всі', testId: 'Всі' },
    { value: 'Кондиція', label: 'Кондиція', testId: 'Кондиція' },
    { value: 'Втрачено', label: 'Втрачено', testId: 'Втрачено' },
    { value: 'Брак', label: 'Брак', testId: 'Брак' },
    { value: 'Брак приймання', label: 'Брак приймання', testId: 'Брак приймання' },
    { value: 'Брак складу', label: 'Брак складу', testId: 'Брак складу' },
    { value: 'Бій', label: 'Бій', testId: 'Бій' },
    { value: 'Переупаковка', label: 'Переупаковка', testId: 'Переупаковка' },
    { value: 'Прострочення', label: 'Прострочення', testId: 'Прострочення' },
    { value: 'Утилізація', label: 'Утилізація', testId: 'Утилізація' },
  ],
  hotFilter: {
    quickButtonText: 'Статус номенклатури',
    overlayTitle: 'Статус номенклатури',
    overlayButtonText: 'Застосувати',
  },
};

export const ProductBalancesFilterConfig: TableFilterConfig = {
  title: 'Фільтри',
  storageKey: 'product-balances-filters',
  buttons: {
    clearFilters: {
      text: 'Очистити все',
      testId: 'clearFiltersBtn',
      autofocus: false,
      class: 'btn',
    },
    applyFilters: {
      text: 'Застосувати фільтри',
      testId: 'applyFiltersBtn',
      color: 'primary' as ThemePalette,
    },
  },
  filterGroups: [
    {
      title: 'Дані про залишки',
      testId: 'group1',
      controlName: 'group1',
      expandedByDefault: true,
      filters: [ProductBalancesArticleFilter, ProductBalancesStatusFilter],
    },
  ],
};

export function getChips(filterGroups: Record<string, any>): TableChip[] {
  if (!filterGroups) {
    return [];
  }

  const article = filterGroups['group1']['article'] !== ProductBalancesArticleFilter.initialValue && filterGroups['group1']['article'];
  const status = filterGroups['group1']['status'] !== ProductBalancesStatusFilter.initialValue && filterGroups['group1']['status'];

  return [
    ...(article ? [{ content: `Артикул: ${article}` }] : []),
    ...(status ? [{ content: status }] : []),
  ];
}

/**
 * Created by andrey on 04.07.16.
 */
import {Country} from  './country.model';

export class State {
    name: string;
    description: string;
    country: Country;
}

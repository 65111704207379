import { AbstractControl } from '@angular/forms';

export function clearField(
  field: AbstractControl,
  event: Event,
  value?: any,
): void {
  event.stopPropagation();
  if (field.reset) {
    if (value === '' || value === null) {
      field.reset(value);

      return;
    }
    field.reset();
  }
}

export function preventCyrillyc(event) {
  const k = event.keyCode || event.which;
  if ((k >= 1040 && k <= 1103) || (k >= 1105 && k <= 1111) || (k >= 1025 && k <= 1031) || (k >= 1168 && k <= 1169)) {
    event.preventDefault();
  }
}

export const DAY = 1000 * 60 * 60 * 24;

export function dateToString(date: Date, delimiter: string = '.'): string {
  return ('0' + date.getDate()).slice(-2) + delimiter
    + ('0' + (date.getMonth() + 1)).slice(-2) + delimiter
    + date.getFullYear();
}

export function dateTimeToString(date: Date, delimiter: string = '.'): string {
  return ('0' + date.getDate()).slice(-2) + delimiter
    + ('0' + (date.getMonth() + 1)).slice(-2) + delimiter
    + date.getFullYear() + ' ' + date.toLocaleTimeString();
}

export function toMidnight(date: Date, offset: number = 0): Date {
  const newDate = new Date(date);
  const midnight = new Date(newDate.setHours(0, 0, 0, 0));
  return new Date(midnight.setDate(midnight.getDate() + offset));
}

export function checkToday(date: Date): boolean {
  return toMidnight(date).getTime() === toMidnight(new Date()).getTime();
}

export function toDay(time: number): number {
  return Math.floor(time / DAY);
}

export function todayEqualOrGreaterThan(date: Date): boolean {
  const today = toMidnight(new Date());
  const storageDay = toMidnight(date);
  return today.getTime() >= storageDay.getTime();
}

export function todayGreaterThan(date: Date): boolean {
  const today = toMidnight(new Date());
  const storageDay = toMidnight(date);
  return today.getTime() > storageDay.getTime();
}

export function todayEqualOrLessThan(date: Date): boolean {
  const today = toMidnight(new Date());
  const storageDay = toMidnight(date);
  return today.getTime() <= storageDay.getTime();
}

export function todayLessThan(date: Date): boolean {
  const today = toMidnight(new Date());
  const storageDay = toMidnight(date);
  return today.getTime() < storageDay.getTime();
}

/**
 * Created by andrey on 05.07.16.
 */
import { Model } from './base.model';
import { OrgType } from './org-type.model';

export class Contragent extends Model {
  name: string;
  ownerType: 'Priv' | 'Org';
  EDRPOU: string;
  orgType: OrgType;
  isCatalog = false;
  City: string;
  CityDescription: string;
  Counterparty: string | null;
  CounterpartyFullName: string;
  CounterpartyType: string;
  Description: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  OwnershipFormDescription: string;
  OwnershipFormRef: string;
  Ref: string;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableFilterConfig } from '../../table-filter-menu.models';

@Component({
  selector: 'app-table-filter-menu-footer',
  templateUrl: './table-filter-menu-footer.component.html',
  styleUrls: ['./table-filter-menu-footer.component.scss'],
})
export class TableFilterMenuFooterComponent {
  @Input() config: TableFilterConfig;
  @Input() isLoading: boolean;
  @Input() isDirty: boolean;
  @Input() isFiltered: boolean;
  @Input() isValid: boolean;
  @Output() filtersApplied: EventEmitter<void> = new EventEmitter<void>();
  @Output() filtersCleared: EventEmitter<void> = new EventEmitter<void>();

  applyFilters(): void {
    this.filtersApplied.emit();
  }

  clearFilters(): void {
    this.filtersCleared.emit();
  }
}

<div class="services-list__form" [formGroup]="preferredDateGroup">
  <div class="form-row">
    <div class="form-col form-col-2" (click)="picker.open()">
      <mat-form-field class="mat-block"
                      [matTooltip]="!recipientCityRef ? 'Оберіть отримувача' : null">
        <input
          [min]="customMinDate ? customMinDate : minDate"
          [max]="maxDate"
          [disabled]="!recipientCityRef"
          matInput
          readonly
          [matDatepicker]="picker"
          [value]="dateValue"
          (dateInput)="onDateChange($event.value)"
          [placeholder]="intervalMode === 'Pickup' ? 'Коли приїхати машині' : 'Дата'">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="initialDate ? initialDate : minDate"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-col form-col-2"
         [matTooltip]="!recipientCityRef ? 'Оберіть отримувача' : null">
      <app-time-intervals (onIntervalChange)="setTimeInterval($event)"
                         [recipientCityRef]="recipientCityRef"
                         [intervalMode]="intervalMode"
      ></app-time-intervals>
    </div>
  </div>
</div>

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CompareFunction } from '../interfaces/compare-function';
import { filter, tap } from 'rxjs';
import { AfterViewInit, Directive, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { merge, Observable, of, Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TableDataSource } from './table-data-source';
import { LocalStorageService } from 'ngx-webstorage';
import { SelectionModel } from '@angular/cdk/collections';

export interface PaginationEvent {
  pageIndex: number;
  pageSize: number;
  length: number;
}

export interface IColConfig {
  name: string;
  class: string;
  show: boolean;
  disabled?: boolean;
}

export interface TableColFilterChange {
  cols: IColConfig[];
}

@Directive()
export abstract class TableComponent<T, D extends TableDataSource<T>>
  implements AfterViewInit, OnInit
{
  @ViewChild(MatPaginator, { static: true, read: MatPaginator })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true, read: MatSort }) sort: MatSort;

  @ViewChild(MatProgressBar, { static: true, read: MatProgressBar })
  progressBar: MatProgressBar;

  private tableLoadingSubject: Subject<boolean> = new Subject();

  tableLoading$: Observable<boolean>;

  abstract dataSource: D;

  abstract storageKey: string;

  abstract disableColumns: any[];

  abstract displayedColumns: string[];

  readonly COLS_STORAGE_KEY = 'colsConfRun';

  readonly ITEMS_PER_PAGE_STORAGE_KEY = 'itemsPerPage';

  readonly SORT_STORAGE_KEY = 'sortConf';

  readonly limitsList: number[] = [5, 10, 20, 50, 100];

  selection: SelectionModel<T>;

  itemsPerPage = 5;

  colsConfRun: IColConfig[];

  colsToShow;

  sortConf: { [key: string]: CompareFunction<T> } = {};

  colsShowConfig: Map<string, string> = new Map();

  protected colsConf: IColConfig[] = [];

  protected storage: LocalStorageService;

  defaultSortEvent: Sort;

  abstract update(): void;

  abstract initDataSource(): void;

  ngOnInit() {
    this.initCols();
    this.itemsPerPage =
      this.storage.retrieve(
        this.makeLocalStorageName(this.ITEMS_PER_PAGE_STORAGE_KEY),
      ) || this.itemsPerPage;
    const sortConf = this.storage.retrieve(
      this.makeLocalStorageName(this.SORT_STORAGE_KEY),
    );
    this.defaultSortEvent = sortConf ? sortConf : null;
  }

  dropEvent(event: CdkDragDrop<string[]> | any) {
    const name = this.storageKey;
    const getSaveCol = JSON.parse(localStorage.getItem(name));

    if (event) {
      localStorage.setItem('fixPosition', 'true');
      if (this.disableColumns[event.currentIndex].disable !== true) {
        moveItemInArray(
          this.displayedColumns,
          event.previousIndex,
          event.currentIndex,
        );

        if (getSaveCol || localStorage.getItem('fixPosition')) {
          localStorage.setItem(name, JSON.stringify(this.displayedColumns));
        } else {
          localStorage.setItem('fixPosition', 'false');
        }
      } else {
        return false;
      }
    }
  }

  onColsChange($event: TableColFilterChange): void {
    this.colsConfRun = $event.cols;
    localStorage.setItem('fixPosition', 'false');
    const hashCols = {};
    this.colsConfRun.forEach((col) => (hashCols[col.class] = col.show));
    this.storage.store(
      this.makeLocalStorageName(this.COLS_STORAGE_KEY),
      hashCols,
    );
    this.generateColsClassesForHiding();
  }

  onPageChange($event: PaginationEvent): void {
    this.itemsPerPage = $event.pageSize;
    this.storage.store(
      this.makeLocalStorageName(this.ITEMS_PER_PAGE_STORAGE_KEY),
      this.itemsPerPage,
    );
  }

  protected makeLocalStorageName(key): string {
    return `${this.storageKey}_${key}`;
  }

  protected initCols(): void {
    this.colsConfRun = JSON.parse(JSON.stringify(this.colsConf));
    const hashCols = this.storage.retrieve(
      this.makeLocalStorageName(this.COLS_STORAGE_KEY),
    );
    if (hashCols) {
      this.colsConfRun = this.colsConfRun.map((col) => {
        if (hashCols[col.class] !== undefined) {
          col.show = hashCols[col.class] || false;
        } else {
          col.show = true;
        }
        if (col.disabled) {
          col.show = col.disabled;
        }
        return col;
      });
    }
    this.generateColsClassesForHiding();
  }

  private generateColsClassesForHiding(): void {
    const name = this.storageKey;

    if (this.displayedColumns.length < 1) {
      console.warn('wrong column definition');
      return;
    }

    if (this.displayedColumns[this.displayedColumns.length - 1] === 'options') {
      if (this.displayedColumns.length < 2) {
        console.warn('wrong column definition for table with options');
        return;
      }
    }

    if (!this.colsConfRun.length || this.colsConfRun.length === 0) {
      return;
    }

    const colsBegin = [];

    const colsEnd = [];

    for (const colName of this.displayedColumns) {
      const colPosition = this.colsShowConfig.get(colName);

      if (colPosition && colPosition === 'begin') {
        colsBegin.push(colName);
      }

      if (colPosition && colPosition === 'end') {
        colsEnd.push(colName);
      }
    }

    this.colsToShow = Object.keys(this.colsConfRun)
      .filter((key) => this.colsConfRun[key].show)
      .map((key) => this.colsConfRun[key].class);
    this.displayedColumns = [...colsBegin, ...this.colsToShow, ...colsEnd];
    if (
      localStorage.getItem('fixPosition') !== 'true' ||
      localStorage.getItem('fixPosition') === 'null'
    ) {
      localStorage.setItem(name, JSON.stringify(this.displayedColumns));
    }
  }

  ngAfterViewInit() {
    this.initPaginator();
    this.initLoading();
  }

  initPaginator(): void {
    if (this.paginator) {
      this.initPageUpdate();
      this.initCheckEmptyPage();
    }
  }

  initLoading(): void {
    const tableLoading$ = !!this.progressBar
      ? this.tableLoadingSubject.asObservable()
      : of(false);
    const datasourceLoading$ = this.dataSource.loading$;
    this.tableLoading$ = merge(tableLoading$, datasourceLoading$).pipe();
  }

  tableLoading(value: boolean): void {
    this.tableLoadingSubject.next(value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getSnapshot().length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource
          .getSnapshot()
          .forEach((row) => this.selection.select(row));
  }

  initPageUpdate(): void {
    this.paginator.page.pipe(tap(() => this.update())).subscribe();
  }

  initCheckEmptyPage(): void {
    this.dataSource.totalInCurrentSearch$
      .pipe(filter((totalInCurrentSearch) => !totalInCurrentSearch))
      .subscribe(() => this.setPreviousPage());
  }

  private setPreviousPage(): void {
    if (!this.paginator.hasPreviousPage()) {
      return;
    }
    this.paginator.previousPage();
  }

  sortData(event: Sort): void {
    if (event.direction === '') {
      this.dataSource.resetSort();
    } else {
      this.dataSource.sort(
        this.sortConf[event.active],
        event.direction === 'asc',
      );
    }
    this.storage.store(this.makeLocalStorageName(this.SORT_STORAGE_KEY), event);
  }

  onFilterFnChanged(fil: (...args) => boolean): void {
    this.dataSource.filter = fil || null;
  }
}

<div class="confirm">
  <div class="confirm__content">
    <div class="confirm__text">
      <p>{{message}}</p>
      <p>{{description}} <span class="currency">{{cost}} грн.</span></p>
    </div>
  </div>
  <div class="confirm__buttons">
    <button type="button"
            (click)="close()"
            class="mat-button_transparent mat-button_sm mat-button_uppercase"
            mat-button
            [matTooltip]="confirmCloseTooltip"
            color="primary">
      Скасувати
    </button>
    <button type="button"
            (click)="onButtonClick()"
            class="mat-button_transparent mat-button_sm mat-button_uppercase"
            mat-button
            color="primary">
      Прирівняти
    </button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from '@autocomplete';
import { AutocompleteWithControlsModule } from '@autocomplete-with-controls';
import { MaterialModule } from '@material';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { AddressRecipientNoteComponent } from '../components/invoice-form/components/address-recipient-note/address-recipient-note.component';
import { AddressRecipientComponent } from '../components/invoice-form/components/address-recipient/address-recipient.component';
import { ContactRecipientCounterpartyComponent } from '../components/invoice-form/components/contact-recipient-counterparty/contact-recipient-counterparty.component';
import { ContactRecipientComponent } from '../components/invoice-form/components/contact-recipient/contact-recipient.component';
import { ContactSenderComponent } from '../components/invoice-form/components/contact-sender/contact-sender.component';
import { ContactTypesModule } from '../components/invoice-form/components/contact-types/contact-types.module';
import { RecipientCounterpartyRadioGroupComponent } from '../components/invoice-form/components/recipient-counterparty-radio-group/recipient-counterparty-radio-group.component';
import { RecipientSearchComponent } from '../components/invoice-form/components/recipient-search/recipient-search.component';
import { RecipientComponent } from '../components/invoice-form/components/recipient/recipient.component';
import { AdditionalServicesModule } from '../components/invoice-form/components/services/additional-services.module';
import { AddressSenderModule } from '../components/invoice-form/modules/address-sender.module';
import { CoreModule } from '../core/core.module';
import { PipeModule } from '../pipes/pipes.module';
import { AccompanyingDocumentsComponent } from './components/accompanying-documents/accompanying-documents.component';
import { AddBirthDateComponent } from './components/add-birth-date/add-birth-date.component';
import { AddToRegisterComponent } from './components/add-to-register/add-to-register.component';
import { AddressByIndexComponent } from './components/addresses/address-by-index/address-by-index.component';
import { AddressComponent } from './components/addresses/address/address.component';
import { EditContainerComponent } from './components/addresses/edit-container/edit-container.component';
import { PostboxComponent } from './components/addresses/postbox/postbox.component';
import { AddressBlockComponent } from './components/addresses/recipient-address/address-block.component';
import { WarehouseComponent } from './components/addresses/warehouse/warehouse.component';
import { BackInvoicesFormComponent, BackSuccessPopupComponent } from './components/back-invoices-form';
import { BannerComponent } from './components/banner/banner.component';
import { ChangeCustomerReturnComponent } from './components/change-customer-return/change-customer-return.component';
import { ChangeEwContactFieldComponent } from './components/change-ew/change-ew-contact-field/change-ew-contact-field.component';
import { ChangeEwContactInputComponent } from './components/change-ew/change-ew-contact-input/change-ew-contact-input.component';
import { ChangeEwModalComponent } from './components/change-ew/change-ew-modal/change-ew-modal.component';
import { ChangeEwSuccessPopupComponent } from './components/change-ew/change-ew-success-popup/change-ew-success-popup.component';
import { ChangeEwComponent } from './components/change-ew/change-ew.component';
import { ContactOrganizationChangeEwComponent } from './components/change-ew/contact-organization-change-ew/contact-organization-change-ew.component';
import { PrivatePersonFormChangeEwComponent } from './components/change-ew/private-person-form-change-ew/private-person-form-change-ew.component';
import { PrivatePersonSenderFormChangeEwComponent } from './components/change-ew/private-person-sender-form/private-person-sender-form.component';
import { ConfirmDialogModule } from './components/confirm-dialog';
import { ContactEditComponent } from './components/contact-edit/contact-edit.component';
import {
  CourierPickupAttachingComponent,
  CourierPickupRemoveInvoiceConfirmComponent,
  CourierPickupsListComponent,
} from './components/courier-pickup';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { CreateTemplateDialogComponent } from './components/create-template-dialog/create-template-dialog.component';
import { CustomGridFooterComponent } from './components/custom-grid-footer/custom-grid-footer.component';
import { DebtPopupComponent } from './components/debt-popup/debt-popup.component';
import { DeliveryAdditionalInfoComponent } from './components/delivery-additional-info/delivery-additional-info.component';
import { DeliveryBarcodeComponent } from './components/delivery-barcode/delivery-barcode.component';
import { DeliveryCargoCommonComponent } from './components/delivery-cargo/components/delivery-cargo-common/delivery-cargo-common.component';
import { DeliveryCargoPlacesComponent } from './components/delivery-cargo/components/delivery-cargo-places/delivery-cargo-places.component';
import { DeliverySeatsCheckboxComponent } from './components/delivery-cargo/components/delivery-seats-checkbox/delivery-seats-checkbox.component';
import { DeliveryCargoComponent } from './components/delivery-cargo/delivery-cargo.component';
import { DeliveryCostComponent } from './components/delivery-cost/delivery-cost.component';
import { DeliveryDescriptionComponent } from './components/delivery-description/delivery-description.component';
import { DeliveryDocumentsAmountComponent } from './components/delivery-documents/components/delivery-documents-amount/delivery-documents-amount.component';
import { DeliveryDocumentsComponent } from './components/delivery-documents/delivery-documents.component';
import { DeliveryPackingNumberComponent } from './components/delivery-packing-number/delivery-packing-number.component';
import { DeliveryPalletSeatsComponent } from './components/delivery-pallets/components/delivery-pallet-seats/delivery-pallet-seats.component';
import { DeliveryPalletsComponent } from './components/delivery-pallets/delivery-pallets.component';
import { DeliveryThirdPersonComponent } from './components/delivery-payer/components/delivery-third-person/delivery-third-person.component';
import { DeliveryPayerComponent } from './components/delivery-payer/delivery-payer.component';
import { DeliveryPaymentComponent } from './components/delivery-payment/delivery-payment.component';
import { DeliveryPromocodeComponent } from './components/delivery-promocode/delivery-promocode.component';
import { DonateButtonComponent } from './components/donate-button/donate-button.component';
import { HeaderChatComponent } from './components/header-chat/header-chat.component';
import { InvoiceFullInfoModalComponent } from './components/invoice-full-info-modal/invoice-full-info-modal.component';
import { InvoiceInfoComponent } from './components/invoice-info/invoice-info.component';
import { InvoiceNavbarComponent } from './components/invoice-navbar/invoice-navbar.component';
import { InvoiceStatusTabsComponent } from './components/invoice-status-tabs/invoice-status-tabs.component';
import { StatusTabComponent } from './components/invoice-status-tabs/status-tab/status-tab.component';
import { LightReturnModalConfirmedComponent } from './components/light-return-modal/light-return-modal-confirmed/light-return-modal-confirmed.component';
import { LightReturnModalComponent } from './components/light-return-modal/light-return-modal.component';
import { LogoComponent } from './components/logo/logo.component';
import { MessengersPopupInstructionComponent } from './components/messengers-popup/messengers-popup-instruction/messengers-popup-instruction.component';
import { MessengersPopupComponent } from './components/messengers-popup/messengers-popup.component';
import { PartialSuccessComponent } from './components/partial-success/partial-success.component';
import { PrintingSettingComponent } from './components/pringitng-setting/printing-setting.component';
import { PrivatePersonFormComponent } from './components/private-person-form/private-person-form.component';
import { RedirectInvoicesFormComponent, RedirectSuccessPopupComponent } from './components/redirect-invoices-form';
import { InvoiceDetailedInfoComponent } from './components/redirect-invoices-form/components/invoice-detailed-info/invoice-detailed-info.component';
import { InvoiceShortInfoComponent } from './components/redirect-invoices-form/components/invoice-short-info/invoice-short-info.component';
import { RecommendationWarehousesComponent } from './components/redirect-invoices-form/components/recommendation-warehouses/recommendation-warehouses.component';
import { NewRegisterComponent } from './components/registries/new-register';
import { RegisterRenameComponent } from './components/registries/register-rename/register-rename.component';
import { RegistersShowComponent } from './components/registries/registers-show/registers-show.component';
import { RegistersViewComponent } from './components/registries/registers-view/registers-view.component';
import { ShareTemplateMessageComponent } from './components/registries/share-template-message/share-template-message.component';
import { ServicesBannerComponent } from './components/services-banner/services-banner.component';
import { ShareInvoiceStateComponent } from './components/share-invoice-state/share-invoice-state.component';
import { ShowInvoiceComponent } from './components/show-invoice/show-invoice.component';
import { TableColFilterModule } from './components/table-col-filter/table-col-filter.module';
import { TableFilterMenuModule } from './components/table-filter-menu/table-filter-menu.module';
import { IframeLoadHandlerDirective } from './directives/iframe-load-handler/iframe-load-handler.directive';
import { InvoiceDirectivesModule } from './directives/invoice-directives/invoice-directives.module';
import { OrderNumberDirective } from './directives/order-number/order-number.directive';
import { CopyClipboardModule } from './modules/copy-clipboard/copy-clipboard.module';
import { NgEditTableCellModule } from './modules/edit-table-cell/edit-table-cell.module';
import { AppMatTableModule } from './modules/mat-table/app-mat-table.module';
import { NgSearchInputModule } from './modules/ng-search-input/ng-search-input.module';
import { NgStfAccordeonModule } from './modules/ng-stf-accordeon/ng-stf-accordeon.module';
import { PhoneInputModule } from './modules/phone-input/phone-input.module';
import { StfNgSelectModule } from './modules/stf-ng-select/stf-ng-select.module';
import { TableToolbarModule } from './modules/table-toolbar/table-toolbar.module';

const COMPONENTS = [
  BannerComponent,
  PrintingSettingComponent,
  HeaderChatComponent,
  DebtPopupComponent,
  AddBirthDateComponent,
  LogoComponent,
  ServicesBannerComponent,
  RecipientComponent,
  RecipientSearchComponent,
  ContactRecipientComponent,
  ContactRecipientCounterpartyComponent,
  RecipientCounterpartyRadioGroupComponent,
  AddressRecipientComponent,
  AddressRecipientNoteComponent,
  ContactSenderComponent,
  InvoiceInfoComponent,
  ShareInvoiceStateComponent,
  ChangeEwContactFieldComponent,
  ChangeEwContactInputComponent,
  ChangeEwModalComponent,
  ChangeEwComponent,
  ContactOrganizationChangeEwComponent,
  PrivatePersonFormChangeEwComponent,
  PrivatePersonSenderFormChangeEwComponent,
  CreateTemplateDialogComponent,
  CustomGridFooterComponent,
  InvoiceFullInfoModalComponent,
  InvoiceStatusTabsComponent,
  StatusTabComponent,
  PrivatePersonFormComponent,
  InvoiceDetailedInfoComponent,
  InvoiceShortInfoComponent,
  RecommendationWarehousesComponent,
  RedirectInvoicesFormComponent,
  ShowInvoiceComponent,
  AddToRegisterComponent,
  BackInvoicesFormComponent,
  ChangeCustomerReturnComponent,
  RegistersViewComponent,
  RegisterRenameComponent,
  RegistersShowComponent,
  ShareTemplateMessageComponent,
  ContactEditComponent,
  PrivatePersonFormComponent,
  InvoiceShortInfoComponent,
  RedirectSuccessPopupComponent,
  BackSuccessPopupComponent,
  InvoiceDetailedInfoComponent,
  PrivatePersonFormChangeEwComponent,
  PrivatePersonSenderFormChangeEwComponent,
  ContactOrganizationChangeEwComponent,
  CustomGridFooterComponent,
  AddressBlockComponent,
  EditContainerComponent,
  WarehouseComponent,
  AddressComponent,
  PostboxComponent,
  AddressByIndexComponent,
  MessengersPopupComponent,
  MessengersPopupInstructionComponent,
  DeliveryPromocodeComponent,
  DeliveryBarcodeComponent,
  AccompanyingDocumentsComponent,
  CreateContactComponent,
  DeliveryPackingNumberComponent,
  DeliveryAdditionalInfoComponent,
  DeliveryDocumentsComponent,
  DeliveryDocumentsAmountComponent,
  DeliveryPalletsComponent,
  DeliveryPalletSeatsComponent,
  DeliveryCargoComponent,
  DeliveryCargoCommonComponent,
  DeliveryCargoPlacesComponent,
  DeliverySeatsCheckboxComponent,
  DeliveryCostComponent,
  DeliveryDescriptionComponent,
  DeliveryPayerComponent,
  DeliveryThirdPersonComponent,
  DeliveryPaymentComponent,
  InvoiceNavbarComponent,
  LightReturnModalComponent,
  LightReturnModalConfirmedComponent,
  DonateButtonComponent,
  IframeLoadHandlerDirective,
  CourierPickupAttachingComponent,
  CourierPickupsListComponent,
  CourierPickupRemoveInvoiceConfirmComponent,
  PartialSuccessComponent,
  OrderNumberDirective,
  ChangeEwSuccessPopupComponent,
  NewRegisterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputModule,
    ContactTypesModule,
    AutocompleteWithControlsModule,
    PipeModule,
    AddressSenderModule,
    InvoiceDirectivesModule,
    ConfirmDialogModule,
    CopyClipboardModule,
    CoreModule,
    NgStfAccordeonModule,
    NgxMaskModule.forRoot(),
    AdditionalServicesModule,
    StfNgSelectModule,
    NgSearchInputModule,
    AppMatTableModule,
    TableToolbarModule,
    NgEditTableCellModule,
    TableColFilterModule,
    AutocompleteModule,
    TextMaskModule,
    TableFilterMenuModule,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, NgxMaskModule, AutocompleteModule],
})
export class SharedModule {}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-stamp-checkboxes',
  templateUrl: './stamp-checkboxes.component.html',
  styleUrls: ['./stamp-checkboxes.component.scss'],
})
export class StampCheckboxesComponent implements OnInit, OnDestroy {

  @Input()
  selectedDocumentGroup: UntypedFormGroup;

  constructor(
    protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
  }

  check(controlName: string, checked: boolean) {
    const services = this.selectedDocumentGroup.value.services;
    services[controlName].checked = checked;
    this.selectedDocumentGroup.get('services').setValue(services);
  }

  ngOnDestroy() {
  }

  checkDisableState(state: { name: string, checked: boolean }) {
    const services = this.selectedDocumentGroup.value.services;
    const checkedServices = Object.keys(services).map(key => services[key]).filter(item => item.checked);
    if (checkedServices.length === 1 && checkedServices[0].name === state.name) {
      return true;
    }
    return false;
  }
}

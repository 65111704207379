import {Directive, ElementRef, HostListener, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[InputTrim]',
})
export class InputTrimDirective {

  constructor(private el: ElementRef, @Self() @Optional() private ngControl: NgControl) {
  }

  @HostListener('blur', ['$event']) onBlur(e: Event | any) {
    e.target.value = e.target.value.trim();
    if (this.ngControl) {
      this.ngControl.control.setValue(e.target.value.trim());
    }
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-custom-return-address',
  templateUrl: './custom-return-address.component.html',
  styleUrls: ['./custom-return-address.component.scss'],
})
export class CustomReturnAddressComponent {
  @Input() options: Array<{ value: string, label: string }> = [];
  @Input() controlName = '';
  @Input() formGroup: UntypedFormGroup;
  @Input() showLabel = true;
  @Output() updateRef: EventEmitter<string> = new EventEmitter<string>();

  private _disabled = false;

  get disabled() {
    return this._disabled;
  }

  @Input() set disabled(data) {
    this._disabled = data;
    this.formGroup.get(this.controlName)[!!data ? 'disable' : 'enable']();
  };

  updateValue(event: MatSelectChange) {
    this.updateRef.emit(event.value);
  }

}

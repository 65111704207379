import { AbstractControl } from '@angular/forms';
import { ContactPerson } from '@models';

const validatedKeys = [
  'CounterpartyDesctiption',
  'CounterpartyRef',
  'Description',
  'Email',
  'FirstName',
  'Info',
  'LastName',
  'MiddleName',
  'Phones',
  'Ref',
];

export function ValidateContact(control: AbstractControl) {
  const value = control.value as ContactPerson;

  if (!value || typeof value !== 'object') {
    return { contact: true };
  }

  const isValid = validatedKeys.every(key => value.hasOwnProperty(key));

  return isValid ? null : { contact: true };
}

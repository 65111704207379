import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IApiError } from '@models';
import { ClaimService } from '@services';
import {
  checkCanEdit,
  handleFirstDayStorage,
  handleReturnCargoDate,
  RedirectSuccessPopupComponent,
  RedirectSuccessPopupData,
  todayEqualOrGreaterThan,
  Unsubscriber,
} from '@shared';
import { isNumber } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, delayWhen, filter, finalize, map, merge, Observable, of, switchMap, take, timer } from 'rxjs';
import { AppRoutes } from '../../../app.routes';
import { MasterpassPaymentComponent } from '../../../components/dashboard/acquiring/components/masterpass-payment/masterpass-payment.component';
import { PaymentCostsInfo } from '../../../models/acquiring/payment-costs-info';
import { InvoiceServiceRequest } from '../../../models/invoice/invoice-service-request.model';
import { Invoice } from '../../../models/invoice/invoice.model';
import { AlertToastService } from '../../../services/alert-toast.service';
import { AuthService } from '../../../services/auth.service';
import { BusinessSettingsService } from '../../../services/business-settings.service';
import { FirebaseService } from '../../../services/firebase/firebase.service';
import { GoogleAnalyticService } from '../../../services/google-analytic.service';
import { HeaderSearchService } from '../../../services/header-search.service';
import { InvoiceApiService } from '../../../services/invoices/invoice-api.service';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { MasterpassService } from '../../../services/masterpass.service';
import { TrackDeliveryService } from '../../../services/track-delivery.service';
import { AllowedStatusCodes } from '../../constants';
import { PayInvoiceType } from '../../other/payment-strategy';
import { BackSuccessPopupComponent, BackSuccessPopupData } from '../back-invoices-form';
import { BackInvoicesFormComponent } from '../back-invoices-form/back-invoices-form.component';
import { ChangeCustomerReturnComponent } from '../change-customer-return/change-customer-return.component';
import { ChangeEwSuccessPopupComponent } from '../change-ew/change-ew-success-popup/change-ew-success-popup.component';
import { ChangeEwSuccessPopupData } from '../change-ew/change-ew-success-popup/change-ew-success-popup.interface';
import { ChangeEwComponent } from '../change-ew/change-ew.component';
import { LightReturnService } from '../light-return-modal/light-return.service';
import { RedirectInvoicesFormComponent } from '../redirect-invoices-form/redirect-invoices-form.component';
import { payerNameTranslationsLong, paymentMethodTranslations, payerNameTranslationsShort } from './show-invoice.constants';
import * as moment from 'moment';

const componentName = 'np-show-invoice';

export type DialogComponentType =
  | RedirectInvoicesFormComponent
  | ShowInvoiceComponent;

@Component({
  selector: 'np-show-invoice',
  templateUrl: './show-invoice.component.html',
  styleUrls: [
    './show-invoice.component.scss',
    '../../../../styles/invoice-show.scss',
  ],
})
export class ShowInvoiceComponent extends Unsubscriber implements OnInit, OnDestroy {
  protected readonly checkCanEdit = checkCanEdit;
  @HostBinding('class')
  @Input()
  classes = 'modal-flex';
  @Input() type: PayInvoiceType;
  @Input() inputInvoice: any;
  @Input() contactPhone: string;
  @Input() recipientCounterpartyType: string;
  @Input() backMoney: number;
  @Input() paymentControl: number;
  @Input() isInvoiceFromSearch = false;

  invoice: Invoice | any;

  newInvoice: Invoice;

  formattedInvoiceNumber: string;

  pending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pending$ = this.pending.asObservable();
  loading$: Observable<any> = merge(this.lightReturnService.pending$, this.pending$);

  isShowFull = false;

  redirectRequest: InvoiceServiceRequest;

  returnRequest: InvoiceServiceRequest;

  trackingDocuments = [];

  itemsPerPage = 100;

  isPossibilityDuplicate: boolean;

  closed: EventEmitter<{ success: boolean }> = new EventEmitter<{ success: boolean }>();
  shouldReload: boolean = false;

  paymentCostsInfo: PaymentCostsInfo;
  styleProgress = { 'background-size': '0' };
  readonly payerNameTranslationsLong = payerNameTranslationsLong;
  readonly payerNameTranslationsShort = payerNameTranslationsShort;
  readonly paymentMethodTranslations = paymentMethodTranslations;
  printing70x100 = false;
  printingm100x100 = false;
  currentStateIsArchived = false;
  corporate = false;
  paymentSum: string;
  paymentPopupOpened = false;

  allowManageButton: boolean;

  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private invoicesService: InvoiceApiService,
    private alertToastService: AlertToastService,
    public dialogRef: MatDialogRef<ShowInvoiceComponent>,
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    protected storage: LocalStorageService,
    public authService: AuthService,
    public headerSearchService: HeaderSearchService,
    protected googleAnalyticsService: GoogleAnalyticService,
    protected masterpassService: MasterpassService,
    public businessSettingsService: BusinessSettingsService,
    private trackDeliveryService: TrackDeliveryService,
    private lightReturnService: LightReturnService,
    private cs: ClaimService,
  ) {
    super();
  }

  get statusClass(): string {
    return this.invoice && this.invoice.state
      ? `invoice-state-${this.invoice.state.id}`
      : '';
  }

  get user() {
    return this.authService.user;
  }

  ngOnInit(): void {
    this.backMoney = this.inputInvoice.backMoney;
    this.paymentControl = this.inputInvoice.paymentControl;
    this.corporate = !!this.user.contacts;
    this.currentStateIsArchived = this.router.url.includes(
      AppRoutes.invoices_archived,
    );
    this.paymentCalc();
    this.dialog.afterAllClosed
      .pipe(take(1))
      .subscribe(() => this.dialog.closeAll());
    this.getInvoice();
    this.initTrackingDocuments();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  paymentCalc() {
    if (this.inputInvoice.backMoney) {
      this.paymentSum = (this.inputInvoice.backMoney * 0.02 + 20).toFixed(2);
    }
  }

  removeFromTrackingDocuments(): void {
    this.dialogRef.close({ event: 'removeFromTrackingDocs' });
  }

  isPressentedInTrackingDocuments(docNumber: string): boolean {
    return this.trackingDocuments.indexOf(docNumber) > -1;
  }

  switchShowFull(): void {
    this.isShowFull = !this.isShowFull;

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.switchShowFull`,
      {
        eventLabel: `Повна інформація`,
        eventCategory: `${componentName}`,
        eventAction: this.isShowFull ? 'open' : 'close',
      },
    );
  }

  redirectInvoice(invoice: Invoice): void {
    const dialogRef = this.dialog.open(RedirectInvoicesFormComponent, {
      panelClass: 'no-padding',
    });

    const typeOfDocument = (invoice.TypeOfDocument === 'Incoming' || this.type === 'in') ? 'in' : 'out';

    dialogRef.componentInstance.type = typeOfDocument;
    dialogRef.componentInstance.inputInvoice = invoice;

    dialogRef.afterClosed()
      .pipe(switchMap((value) => this.handleRedirectPopupClose(value, typeOfDocument)))
      .subscribe(data => {
        if (data?.success) {
          this.dialogRef.close({ success: true });
        }
      });

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.redirectInvoice`,
      {
        eventLabel: `Заявка на переадресацію`,
        eventCategory: `${componentName}`,
        eventAction: 'click',
      },
    );
  }

  private handleRedirectPopupClose(value: RedirectSuccessPopupData, type: string): Observable<{ success: boolean }> {
    if (!value) {
      return of({ success: false });
    }

    if (!isNumber(value?.Pricing) && !value.ScheduledDeliveryDate && value.success) {
      return of({ success: true });
    }

    const successDialog = this.dialog.open(RedirectSuccessPopupComponent, {
      data: {
        isCreated: value.isCreated,
        Number: value.Number,
        Invoice: this.inputInvoice,
        MessageText: value.MessageText,
        Type: type,
        Pricing: value.Pricing,
        ScheduledDeliveryDate: value.ScheduledDeliveryDate,
      },
      panelClass: 'no-padding',
    });
    return successDialog.afterClosed();
  }

  copy(invoice: any) {
    this.invoicesService
      .getForRef(invoice.docNumber)
      .pipe(map((response) => response.data.data))
      .subscribe((data) => {
        data.map((i) => {
          this.router.navigate([AppRoutes.invoice_copy, i.Ref]);
          this.closePopups();
        });
      });

    this.googleAnalyticsService.track('analitics', `${componentName}.copy`, {
      eventLabel: `Дублювати накладну`,
      eventCategory: `${componentName}`,
      eventAction: 'click',
    });
  }

  returnInvoice(invoice: Invoice): void {
    const dialogRef = this.dialog.open(BackInvoicesFormComponent, {
      panelClass: 'no-padding',
    });
    const typeOfDocument = (invoice.TypeOfDocument === 'Incoming' || this.type === 'in') ? 'in' : 'out';
    dialogRef.componentInstance.type = typeOfDocument;
    dialogRef.componentInstance.invoice = invoice;
    dialogRef.keydownEvents().pipe(take(1), filter((e) => e.key === 'Escape')).subscribe(data => {
      dialogRef.close();
    });
    dialogRef.afterClosed().pipe(switchMap((value) => this.handleReturnPopupClose(value, invoice, typeOfDocument)))
      .subscribe(data => {
        if (data?.success) {
          this.dialogRef.close({ success: true });
        }
      });
  }

  private handleReturnPopupClose(value: BackSuccessPopupData, invoice: Invoice, type: string): Observable<{
    success: boolean
  }> {
    if (!value) {
      return of({ success: false });
    }

    if (!isNumber(value?.Pricing) && !value.ScheduledDeliveryDate && value.success) {
      return of({ success: true });
    }

    const successDialog = this.dialog.open(BackSuccessPopupComponent, {
      data: {
        isCreated: value.isCreated,
        Number: value.Number,
        Pricing: value.Pricing,
        ScheduledDeliveryDate: value.ScheduledDeliveryDate,
        Invoice: invoice,
        Type: type,
        MessageText: value.MessageText,
      },
      panelClass: 'no-padding',
    });
    return successDialog.afterClosed();
  }

  print(invoices: Invoice[]): void {
    if (!invoices.length) {
      return;
    }

    const invoicesDocNumbers = invoices.map((el: Invoice) => el.docNumber);
    this.googleAnalyticsService.track('analitics', `${componentName}.print`, {
      eventLabel: `Друк експрес-накладної, 2 копiї`,
      eventCategory: `${componentName}`,
      eventAction: 'click',
    });

    this.invoiceService
      .printCopies(invoicesDocNumbers, 2)
      .subscribe(
        () => {
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.print.success`,
            {
              eventLabel: `Друк експрес-накладної, 2 копiї`,
              eventCategory: `${componentName}`,
              eventAction: 'print.success',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.print.error`,
            {
              eventLabel: `Друк експрес-накладної, 2 копiї: ${
                errors[0] && errors[0].errorCode
              }`,
              eventCategory: `${componentName}`,
              eventAction: 'print.error',
            },
          );
        },
      );
  }

  printOneCopy(invoices: Invoice[]): void {
    if (!invoices.length) {
      return;
    }

    const invoicesDocNumbers = invoices.map((el: Invoice) => el.docNumber);

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.printOneCopy`,
      {
        eventLabel: `Друк експресс-накладної, 1 копія`,
        eventCategory: `${componentName}`,
        eventAction: 'click',
      },
    );

    this.invoiceService
      .printCopies(invoicesDocNumbers)
      .subscribe(
        () => {
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printOneCopy.success`,
            {
              eventLabel: `Друк експресс-накладної, 1 копія`,
              eventCategory: `${componentName}`,
              eventAction: 'printOneCopy.success',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.printOneCopy.error`,
            {
              eventLabel: `Друк експресс-накладної, 1 копія: ${
                errors[0] && errors[0].errorCode
              }`,
              eventCategory: `${componentName}`,
              eventAction: 'printOneCopy.error',
            },
          );
        },
      );
  }

  changeEw(invoice: Invoice): void {
    if (!invoice) {
      console.warn('Invoice is undefined');
      return;
    }

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.changeEw`,
      {
        eventLabel: `Заявка на зміну даних`,
        eventCategory: `${componentName}`,
        eventAction: 'click',
      },
    );

    const dialogRef = this.dialog.open(ChangeEwComponent, {
      panelClass: 'no-padding',
      disableClose: true,
    });
    dialogRef.componentInstance.type = 'out';
    dialogRef.componentInstance.invoice = invoice;
    dialogRef.keydownEvents().pipe(take(1), filter((e) => e.key === 'Escape')).subscribe(data => {
      dialogRef.close();
    });
    dialogRef.afterClosed()
      .pipe(take(1), switchMap((value) => this.handleChangeEwPopupClose(value)))
      .subscribe(data => {
        if (data?.success) {
          this.dialogRef.close({ success: true });
        }
      });
  }

  private handleChangeEwPopupClose(value: ChangeEwSuccessPopupData): Observable<{ success: boolean }> {
    if (!value) {
      return of({ success: false });
    }

    const successDialog = this.dialog.open(ChangeEwSuccessPopupComponent, {
      data: {
        AlertForCall: value.AlertForCall,
        MessageText: value.MessageText,
        Number: value.Number,
      },
      panelClass: 'no-padding',
    });
    return successDialog.afterClosed();
  }

  changeCustomerReturn(invoice: Invoice): void {
    if (!invoice) {
      console.warn('Invoice is undefined');
      return;
    }

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.changeCustomerReturn`,
      {
        eventLabel: `Заявка на подовження терміну зберігання`,
        eventCategory: `${componentName}`,
        eventAction: 'click',
      },
    );

    const dialogRef = this.dialog.open(ChangeCustomerReturnComponent, {
      panelClass: 'no-padding',
      disableClose: true,
    });
    dialogRef.componentInstance.type = this.type;
    dialogRef.componentInstance.invoice = this.inputInvoice;
    dialogRef.componentInstance.user = this.corporate ? null : this.user;

    const keys$ = dialogRef
      .keydownEvents()
      .pipe(filter((e) => e.key === 'Escape'));
    const backdrop$ = dialogRef.backdropClick();

    merge(keys$, backdrop$, dialogRef.afterClosed()).subscribe((data) => {
      if (data?.success) {
        this.dialogRef.close({ success: true });
      }
    });
  }

  checkIsCorporatePaymentDisabled() {
    return this.user.contacts && !this.paymentCostsInfo?.UseNovaPay;
  }

  checkPaymentDisabled() {
    return (
      !this.paymentCostsInfo ||
      (this.paymentCostsInfo && this.checkIfPaid(this.paymentCostsInfo)) ||
      this.checkIsCorporatePaymentDisabled() ||
      this.paymentPopupOpened ||
      this.paymentCostsInfo.UseNovaPay === null
    );
  }

  addDocumentToArchive(invoice: Invoice): void {
    const delay = () => timer(2000);
    this.invoicesService
      .addDocumentToArchive(invoice, this.type as 'in' | 'out')
      .pipe(delayWhen(delay))
      .subscribe(
        () => {
          this.alertToastService.pushSuccess(`Накладну успiшно заархiвовано`);
          this.dialogRef.close({ success: true });

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.addDocumentToArchive`,
            {
              eventLabel: `Архівувати`,
              eventCategory: `${componentName}`,
              eventAction: 'click',
            },
          );
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.addDocumentToArchive.error`,
            {
              eventLabel: `\`Помилка Заархiвувати: ${invoice.docNumber} ${
                errors[0] && errors[0].errorCode
              }`,
              eventCategory: `${componentName}`,
              eventAction: 'addDocumentToArchive.error',
            },
          );
        },
      );
  }

  goToNewInvoice(docNumber, event): void {
    event.preventDefault();
    this.closePopups();
    this.showNewInvoice();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.dialog.closeAll();
    }
  }

  createPayment(invoice: Invoice, type: PayInvoiceType) {
    this.paymentPopupOpened = true;
    const dialogRef = this.dialog.open(MasterpassPaymentComponent, {
      position: { right: '0' },
      width: '75%',
      height: '100%',
      panelClass: 'setting-content-popup',
    });
    dialogRef.componentInstance.type = type;
    dialogRef.componentInstance.inputInvoice = this.inputInvoice;
    dialogRef.componentInstance.paymentCostsInfo = this.paymentCostsInfo;
    dialogRef.componentInstance.recipientCounterpartyType =
      invoice.recipientCounterpartyType;

    dialogRef.afterClosed().subscribe((d) => {
      this.setPending(true);
      this.paymentPopupOpened = false;
      this.masterpassService
        .getPaymentInfo(
          this.invoice.docNumber,
          this.type === 'in'
            ? this.invoice.recipient && (this.invoice.recipient.phone as string)
            : this.authService.user.phone ||
            (this.invoice.sender ? this.invoice.sender.phone : ''),
        )
        .subscribe((data) => {
          this.paymentCostsInfo = data;
          this.setPending(false);
        });
    });

    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.createPayment`,
      {
        eventLabel: `Сплатити`,
        eventCategory: `${componentName}`,
        eventAction: 'click',
      },
    );
  }

  checkIfPaid(paymentCostsInfo: PaymentCostsInfo) {
    if (!paymentCostsInfo) {
      return true;
    }
    if (
      paymentCostsInfo.Services > 0 ||
      paymentCostsInfo.AfterPayment > 0 ||
      paymentCostsInfo.MoneyTransfer > 0 ||
      paymentCostsInfo.InternationalDelivery > 0 ||
      paymentCostsInfo.Commission > 0 ||
      paymentCostsInfo.Redirecting > 0 ||
      paymentCostsInfo.Return > 0
    ) {
      return false;
    }
    return true;
  }

  showNewInvoice(): void {
    this.openPopup(ShowInvoiceComponent, 'arch', this.newInvoice);
  }

  openPopup(
    componentType: ComponentType<DialogComponentType>,
    type: PayInvoiceType,
    invoice: Invoice,
  ): void {
    const dialogRef = this.dialog.open(componentType, {
      panelClass: 'no-padding',
      disableClose: true,
    });
    dialogRef.componentInstance.type = type;
    dialogRef.componentInstance.inputInvoice = invoice;
  }

  closePopups(): void {
    this.dialog.closeAll();
  }

  handleInvoiceMutations(invoice: Invoice): void {
    this.invoice = handleFirstDayStorage(invoice);
    this.invoice = handleReturnCargoDate(this.invoice);
  }

  private initTrackingDocuments(): void {
    if (this.storage.isStorageAvailable()) {
      const trackingDocumentsArray =
        this.trackDeliveryService.getTrackingDocuments();
      if (!!trackingDocumentsArray) {
        this.trackingDocuments = trackingDocumentsArray;
      }
    }
  }

  lightReturnPending$ = this.lightReturnService.pending$;

  lightReturn(): void {
    this.lightReturnService.init(this.inputInvoice);
  }

  claim(invoice: Invoice): void {
    this.cs.open(invoice.docNumber);
  }

  private setPending(value: boolean): void {
    this.pending.next(value);
  }

  private getInvoice() {
    this.formattedInvoiceNumber = this.invoiceService.makeFormatedInvoiceNumber(
      this.inputInvoice.docNumber,
    );
    this.setPending(true);
    this.invoiceService
      .track(
        this.inputInvoice.docNumber,
        this.inputInvoice.$isTracking,
        this.inputInvoice?.PhoneSender ?? undefined,
      )
      .pipe(
        switchMap((invoice) => {
          invoice.announcedPrice =
            this.inputInvoice?.announcedPrice || this.inputInvoice?.Cost;
          invoice.RecipientFullName = this.inputInvoice?.RecipientFullName;
          this.inputInvoice.statusCode = invoice.statusCode;
          this.allowManageButton = AllowedStatusCodes.some(
            (e) => invoice.statusCode === e,
          );
          this.inputInvoice.actualDeliveryDate = invoice.actualDeliveryDate;
          this.inputInvoice.dateReturnCargo = invoice.dateReturnCargo;
          this.inputInvoice.dateFirstDayStorage = invoice.dateFirstDayStorage;
          this.inputInvoice.serviceRequests = invoice.serviceRequests;
          this.inputInvoice.sender = invoice.sender;
          this.inputInvoice.recipient = invoice.recipient;
          this.handleInvoiceMutations(this.isInvoiceFromSearch ? this.inputInvoice : invoice);
          this.invoice.CheckWeightMethod = invoice.CheckWeightMethod;
          this.inputInvoice.state = this.invoice.state;

          const isTodayGreaterThanFirstDayStorage = (i: Invoice): boolean => {
            if (!i) {
              return false;
            }
            try {
              const dateFirstDayStorage = new Date(i.dateFirstDayStorage);
              return todayEqualOrGreaterThan(dateFirstDayStorage);
            } catch (e) {
              return false;
            }
          };

          if (
            this.isInvoiceFromSearch &&
            !this.invoice.dateReturnCargo &&
            !(
              invoice &&
              invoice.statusCode &&
              !!invoice.dateFirstDayStorage &&
              (Number(invoice.statusCode) === 7 ||
                Number(invoice.statusCode) === 8) &&
              isTodayGreaterThanFirstDayStorage(invoice)
            )
          ) {
            this.invoice.state.name = invoice.state.name;
          }

          if (this.invoice.serviceRequests) {
            this.invoice.serviceRequests.forEach((request) => {
              if (request.serviceName === 'Redirecting') {
                this.redirectRequest = request;
              }

              if (request.serviceName === 'CargoReturn') {
                this.returnRequest = request;
              }
            });
          }

          if (this.redirectRequest || this.returnRequest) {
            this.invoiceService
              .track(
                (this.redirectRequest || this.returnRequest).newInvoiceNumber,
              )
              .pipe(take(1))
              .subscribe((i) => {
                this.newInvoice = i;
              });
          }

          return this.masterpassService.getPaymentInfo(
            this.invoice.docNumber,
            this.type === 'in'
              ? this.invoice.recipient &&
              (this.invoice.recipient.phone as string)
              : this.authService.user.phone ||
              (this.invoice.sender ? this.invoice.sender.phone : ''),
          );
        }),
        finalize(() => this.setPending(false)),
        take(1),
      )
      .subscribe((paymentCostsInfo) => {
        this.paymentCostsInfo = paymentCostsInfo;
      });
  }
}

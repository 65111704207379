import {Injectable} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {Invoice} from '../../models/invoice/invoice.model';

@Injectable({
  providedIn: 'root',
})
export class ShareInvoiceStateService {

  backMoney;

  readonly url = 'https://novaposhta.ua/tracking/?cargo_number=';

  constructor(private cbService: ClipboardService) {
  }

  copyInvoiceDocNumber(invoice: Invoice): void {
    this.cbService.copyFromContent(invoice.docNumber);
  }

  copyInvoiceStatus(invoice: Invoice): void {
    this.cbService.copyFromContent(this.formText(invoice));
  }

  formText(invoice: any): string {
    if (invoice.BackwardDeliveryData && invoice.BackwardDeliveryData !== {} && invoice.BackwardDeliveryData[0]
      && invoice.BackwardDeliveryData[0].CargoTypeRef === 'Money' && invoice.Cash2CardPAN === '') {
      this.backMoney = '\nВартість зворотної доставки: ' + (+invoice.BackwardDeliveryData[0].RedeliveryString * 0.02 + 20)
        + ' грн';
    } else if (invoice.backMoney && invoice.backMoney !== 0 && invoice.Cash2CardPAN === '') {
      this.backMoney = '\nВартість зворотної доставки: ' + (+invoice.backMoney * 0.02 + 20)
        + ' грн';
    } else {
      this.backMoney = '';
    }
    return 'Відправлення: ' + invoice.docNumber + '' +
      (invoice.receivingTime ? '\n\n' + this.checkStatus(invoice) + ': ' + (this.getFormattedDate(invoice.receivingTime)) : '') + ''
      + (invoice.estimatedDelivery ? '\n\n' + this.checkStatus(invoice) + ': ' + (invoice.estimatedDeliveryDate) : '') + ''
      + '\nВартість доставки: ' + invoice.deliveryPrice + ' грн'
      + this.backMoney
      + '\n\nСтатус вашої посилки можна перевірити за посиланням ' + this.url + '' + invoice.docNumber;
  }

  getFormattedDate(date: Date): string {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    return `${this.appendZero(dd)}.${this.appendZero(mm)}.${yyyy}`;
  }

  private checkStatus(invoice: Invoice): string {
    return invoice.statusCode === '9' ? 'Було доставлено' : 'Плановий час доставки';
  }

  private appendZero(n: number | string): string {
    return '' + (Number(n) < 10 ? '0' + n : n);
  }

}

import { Injectable } from '@angular/core';
import Rxmq, { Channel } from 'rxmq';
import { AnalyticService } from '../shared/interfaces/analytic-service';

export interface AnalyticEvent {
  eventLabel: string;
  eventCategory: string;
  eventAction: string;
  eventValue?: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticService implements AnalyticService {
  constructor() {
    this.init();
  }

  private init(): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .observe('#')
      .subscribe((event) => {
      });
  }

  setUserId(userId: string): void {
  }

  track<AnalyticEvent>(
    name: string = '',
    subject: string = '',
    payload: AnalyticEvent = {} as AnalyticEvent,
  ): void {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(name)
      .subject(subject)
      .next(payload);
  }

  saveTransaction(
    product: {
      id;
      name;
      brand?;
      category?;
      price?;
      quantity?;
      variant?;
      coupon?;
    },
    transaction: { id; affiliation?; revenue?; shipping?; tax?; coupon? },
  ) {
  }

  refundTransaction(transactionId: string) {
  }
}

import {ISmsTemplate} from '../../../../models/sms-template.model';
import {DeliveryDatePipe} from '../../../../../../../core/pipes/delivery-date.pipe.';

export const templateVariables = [
  {
    name: '@TTN',
    getValue: invoice => invoice.docNumber || invoice.IntDocNumber,
  },
  {
    name: '@delivery_date',
    getValue: invoice => new DeliveryDatePipe().transform(invoice.scheduleDeliveryDate || invoice.estimatedDeliveryDate),
  },
  {
    name: '@warehouse',
    getValue: invoice => invoice.recipientAddress ? invoice.recipientAddress.description : `${invoice.CityRecipient}, ${invoice.RecipientAddress}`,
  },
  {
    name: '@delivery_price',
    getValue: invoice => invoice.deliveryPrice !== undefined ?
      (Number(invoice.deliveryPrice) + (invoice.StoragePrice ? Number(invoice.StoragePrice) : 0)) + ' грн' :
      invoice.CostOnSite + ' грн',
  },
  {
    name: '@money_transfer',
    getValue: invoice => {
      if (invoice?.BackwardDeliveryData?.[0]?.RedeliveryString && invoice?.BackwardDeliveryData?.[0]?.CargoTypeRef === 'Money') {
        return invoice.BackwardDeliveryData[0].RedeliveryString + ' грн';
      }
      if (invoice.BackwardDeliveryMoney) {
        return invoice.BackwardDeliveryMoney + ' грн';
      }
      if (invoice.backMoney > 0 || invoice.backMoney === undefined && invoice.paymentControl === 0 && invoice.AfterpaymentOnGoodsCost === '0') {
        return invoice.backMoney ? invoice.backMoney + ' грн' : '0';
      } else {
        return (invoice.paymentControl || invoice.AfterpaymentOnGoodsCost || 0) + ' грн';
      }
    },
  },
];

export const initialTemplates: ISmsTemplate[] = [
  {
    name: 'Відправлено',
    content: `<div class="template-text">Відправлення: <span class="tag">@TTN</span> <br>Плановий час доставки: <span class="tag">@delivery_date</span> <br>Вартість доставки: <span class="tag">@delivery_price</span> <br>Сума грошового переказу: <span class="tag">@money_transfer</span> <br>Статус: https://novaposhta.ua/tracking/?cargo_number=<span class="tag">@TTN</span></div>`,
    custom: false,
  },
  {
    name: 'Скоро початок платного зберігання',
    content: `<div class="template-text">Vidpravlennia <span class="tag">@TTN</span> zberihayet'sya u viddilenni  vzhe N dniv. Z DD.MM.YYYY zberigannia platne.</div>`,
    custom: false,
  },
  {
    name: 'Скоро автоматичне повернення',
    content: `<div class="template-text">Vidpravlennia <span class="tag">@TTN</span> zberihayet'sya u viddilenni vzhe N dniv. <br>Avtopovernennya z DD.MM.YYYY</div>`,
    custom: false,
  },
];

export const letterLimits = {
  en: [160, 306, 459, 612],
  cyr: [70, 134, 201, 268],
};

export const parseContentHtml = (content: string) => {
  content = content.replace(/<div class="template-text">/g, '');
  content = content.replace(/<\/div>/g, '');
  content = content.replace(/<br>/g, '\n');
  for (const variable of templateVariables) {
    const regexp = new RegExp(`<span class="tag">${variable.name}</span>`, 'g');
    content = content.replace(regexp, variable.name);
  }
  return content;
};

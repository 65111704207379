import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@shared';
import { ClaimComponent } from '../../components/dashboard/components/claim/claim/claim.component';

@Injectable({
  providedIn: 'root',
})
export class ClaimService extends Unsubscriber {

  constructor(private dialog: MatDialog) {
    super();
  }

  claimRef: MatDialogRef<ClaimComponent> | null = null;

  open(data: string): void {
    this.claimRef = this.dialog.open(ClaimComponent, {
      data,
      width: '100%',
      height: '100%',
      minWidth: '100%',
      restoreFocus: false,
      disableClose: true,
      enterAnimationDuration: '0ms',
      panelClass: ['fullscreen-transparent', 'no-padding'],
    });
  }

  close(): void {
    this.claimRef.close();
  }
}

<button (menuClosed)="resetValue(config.controlName)"
        [attr.data-test-id]="'hot-'+config.controlName+'-trigger'"
        [matMenuTriggerFor]="input"
        [class.active]="control?.value && control?.value !== config.initialValue"
        class="chip chip_rect" mat-ripple>{{ config.hotFilter.quickButtonText }}
</button>

<mat-menu #input="matMenu"
          [formGroup]="form"
          class="hot-filter-list-menu hot-filter-list-menu_invoice">
  <div (click)="stopPropagation($event)" class="menu-content">
    <app-input-filter (click)="stopPropagation($event)"
                      [config]="config"
                      [controlName]="config.controlName"
                      [form]="form"></app-input-filter>

    <button (click)="applyFilterForm()"
            [attr.data-test-id]="'hot-'+config.controlName+'-apply'"
            [disabled]="control?.invalid || notChanged(config.controlName)"
            class="mt-8"
            color="primary"
            mat-raised-button>{{ config.hotFilter.overlayButtonText }}
    </button>
  </div>
</mat-menu>

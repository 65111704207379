import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppRoutes } from '@app';
import { AlertToastService } from '@services';
import { LightReturnResponse, Unsubscriber } from '@shared';
import { BehaviorSubject, catchError, filter, finalize, merge, of, take } from 'rxjs';
import { Invoice } from '../../../models/invoice/invoice.model';
import { InvoiceApiService } from '../../../services/invoices/invoice-api.service';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { PayInvoiceType } from '../../other/payment-strategy';
import { LightReturnModalConfirmedComponent } from './light-return-modal-confirmed/light-return-modal-confirmed.component';
import { LightReturnControlName } from './light-return-modal.enums';
import { LightReturnService } from './light-return.service';

@Component({
  selector: 'app-light-return-modal',
  templateUrl: './light-return-modal.component.html',
  styleUrls: ['./light-return-modal.component.scss'],
})
export class LightReturnModalComponent extends Unsubscriber implements OnInit {

  constructor(private fb: UntypedFormBuilder,
              public invoiceService: InvoiceService,
              public invoiceApiService: InvoiceApiService,
              private dialogRef: MatDialogRef<LightReturnModalComponent>,
              private dialog: MatDialog,
              private router: Router,
              private alertToastService: AlertToastService,
              private lightReturnService: LightReturnService,
  ) {
    super();
  }

  ControlName = LightReturnControlName;

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _invoice: Invoice | null = null;

  @Input() type: PayInvoiceType = 'out';

  @Input() set invoice(invoice: Invoice) {
    this._invoice = invoice;
  }

  @Input()
  reasons: any = [];

  get invoice(): Invoice {
    return this._invoice;
  }

  setLoading(value: boolean): void {
    this.loading.next(value);
  }

  form: UntypedFormGroup;

  private dialogConfirm: MatDialogRef<LightReturnModalConfirmedComponent>;

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      [LightReturnControlName.SubtypeReason]: [null, Validators.required],
    });
  }

  submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.setLoading(true);
    this.subscriptions = this.invoiceService.createLightReturn(this.form.getRawValue().SubtypeReason.Ref, this.invoice.docNumber)
      .pipe(
        catchError((errors) => {
          this.close(true);
          this.dialog.closeAll();
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          return of(null);
        }),
        finalize(() => this.setLoading(false)),
      )
      .subscribe((lightReturn: LightReturnResponse) => {
        this.handleUnsuccessfulLightReturn(lightReturn);

        this.handleSuccessfulLightReturn(lightReturn);
      });
  }

  private handleUnsuccessfulLightReturn(lightReturn: LightReturnResponse): void {
    if (!lightReturn?.success) {
      this.close(true);
      this.dialog.closeAll();
      return;
    }
  }

  private handleSuccessfulLightReturn(lightReturn: LightReturnResponse): void {
    this.lightReturnService.lightReturnInitialised = true;

    this.dialog.closeAll();
    this.setLoading(false);

    this.dialogConfirm = this.dialog.open(
      LightReturnModalConfirmedComponent,
      {
        panelClass: 'no-padding',
        width: '568px',
        height: '305px',
        autoFocus: false,
        restoreFocus: false,
      },
    );

    this.dialogConfirm.componentInstance.number = lightReturn.data[0].Number;

    this.lightReturnService.shouldReloadGrid.next(false);

    const backdrop$ = this.dialogConfirm.backdropClick();
    const keydown$ = this.dialogConfirm.keydownEvents()
      .pipe(filter((e) => e.key === 'Escape'));

    merge(backdrop$, keydown$)
      .pipe(take(1))
      .subscribe(() => {
        this.dialogConfirm.close({reload: true});
        this.close(true);
      });

    this.dialogConfirm.afterClosed().pipe(take(1),
      finalize(() => this.resetLightReturnInitializer()),
    ).subscribe((result) => {
      if (result?.reload) {
        this.close(true);
      } else {
        this.dialog.closeAll();
        this.router.navigate([AppRoutes.dashboard]);
      }
    });
  }

  private resetLightReturnInitializer(): void {
    // Here we reset state of lightReturn feature
    setTimeout(() => {
      this.lightReturnService.lightReturnInitialised = false;
    });
  }

  private close(result: boolean = false): void {
    if (result) {
      this.lightReturnService.shouldReloadGrid.next(true);
    }
    this.dialogRef.close();
  }

  trackBy(item: any): string {
    return item.Ref;
  }
}

import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { AuthService } from './auth.service';
import { InvoiceService } from './invoices/invoice.service';

@Injectable({
  providedIn: 'root',
})
export class TrackDeliveryService {

  trackingDocuments: string[] = this.storage.retrieve(`trackingDocuments${this.authService.user.userLogin}`);

  constructor(
    private invoiceService: InvoiceService,
    private storage: LocalStorageService,
    private authService: AuthService,
  ) {
  }

  track(invoiceNumber: string): Observable<string> {
    return this.checkInvoiceNumber(invoiceNumber)
      .pipe(
        switchMap(isValid => {
          if (isValid) {
            let trackDocs = this.storage.retrieve(`trackingDocuments${this.authService.user.userLogin}`);
            if (!(trackDocs instanceof Array)) {
              trackDocs = [];
            }
            if (trackDocs.indexOf(invoiceNumber) < 0) {
              trackDocs.push(invoiceNumber);
            }
            this.storage.store(`trackingDocuments${this.authService.user.userLogin}`, trackDocs);
            this.trackingDocuments = trackDocs;
            return of('');
          } else {
            return of('Некоректно вказано номер відправлення або номер не знайдено.');
          }
        }),
      );
  }

  checkInvoiceNumber(invoiceNumber: string): Observable<boolean> {
    return this.invoiceService.trackManyWithBinding([invoiceNumber], 100, false)
      .pipe(take(1), map(invoices => invoices[0].statusCode !== '3'));
  }

  removeFromLocalStorage(docNumber: string) {
    if (this.storage.isStorageAvailable()) {
      const localStorageKey = `trackingDocuments${this.authService.user.userLogin}`;
      if (this.storage.retrieve(localStorageKey)) {
        const newTrackingDocumentsArray = this.storage.retrieve(localStorageKey)
          .filter(item => item !== docNumber);
        this.trackingDocuments = newTrackingDocumentsArray;
        this.storage.store(localStorageKey, newTrackingDocumentsArray);
      }
    }
  }

  getTrackingDocuments(): string[] {
    this.trackingDocuments = this.storage.retrieve(`trackingDocuments${this.authService.user.userLogin}`);
    return this.trackingDocuments;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { merge } from 'rxjs';
import { take } from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
  selector: 'np-mobile-modal-dialog',
  templateUrl: './mobile-modal-dialog.component.html',
  styleUrls: ['./mobile-modal-dialog.component.scss'],
})
export class MobileModalDialogComponent implements OnInit {

  @Input()
  login;

  constructor(public dialogRef: MatDialogRef<MobileModalDialogComponent>,
              protected storage: LocalStorageService,
              private meta: Meta) {
  }

  closeModalDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    const bc = this.dialogRef.backdropClick();
    const af = this.dialogRef.afterClosed();

    merge(bc, af)
      .pipe(take(1))
      .subscribe(() => {
        this.meta.removeTag('name=viewport');
        if (this.login) {
          this.storage.store(`loggedIn${this.login}`, true);
        }
        window.location.reload();
      });
  }

}

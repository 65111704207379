<div *ngIf="formGroup"
     [attr.data-test-id]="AvailableServiceNames.localExpress"
     [formGroup]="formGroup"
     class="services-list__item standalone">
  <div class="services-list__info">
    <div class="d-flex align-items-center">
      <mat-slide-toggle [disabled]="disabled$ | async"
                        [matTooltip]="tooltip$ | async"
                        class="services-list__checkbox"
                        formControlName="selected">{{name}}</mat-slide-toggle>
      <i [matTooltip]="description"
         class="material-icons icon-help cursor-pointer"
         matTooltipPosition="below">help_outline</i>
    </div>

    <div class="services-list__form">
      <div class="form-row">
        <div class="form-col form-col-2">
          <div class="container" [matTooltip]="(recipientCityRef$ | async) ? '' : 'Спочатку вкажіть адресу отримання'">
            <app-local-express-time-intervals *ngIf="!(disabled$ | async) && (timeIntervalSelectionAvailable$ | async)"
                                [form]="formGroup"
                                [dateTime]="DateTime$ | async"
                                [recipientCityRef]="recipientCityRef$ | async"
            ></app-local-express-time-intervals>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileModalDialogComponent } from './mobile-modal-dialog/mobile-modal-dialog.component';
import {MaterialModule} from "../../../material";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [MobileModalDialogComponent],
    exports: [MobileModalDialogComponent]
})
export class MobileModalDialogModule {
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { config } from './change-ew-modal.config';
import { ChangeEwOrderItem } from '../models';

const componentName = 'np-change-ew-modal';

@Component({
  selector: componentName,
  templateUrl: 'change-ew-modal.component.html',
  styleUrls: ['./change-ew-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEwModalComponent implements OnInit {
  @Input()
  item: Partial<ChangeEwOrderItem> = {};

  list: Array<{ key: string; before: string; after: string }>;

  constructor(private dialogRef: MatDialogRef<ChangeEwModalComponent>) {}

  ngOnInit(): void {
    this.list = this.handleConfig();
  }

  handleConfig(): Array<{ key: string; before: string; after: string }> {
    const result = [];
    Array.from(config.entries()).forEach(([key, data]) => {
      const before = this.item[data.before];
      const after = this.item[data.after];
      if (before || after) {
        const cb = data.transform;
        return result.push({
          key,
          before: cb ? cb(before) : before || '',
          after: cb ? cb(after) : after || '',
        });
      }
    });
    return result;
  }

  close(): void {
    this.dialogRef.close();
  }
}

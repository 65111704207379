<div class="form-section__search-box">
  <div class="form-section__title nowrap">Одержувач</div>
  <div class="form-search">
    <np-inv-recipient-search
      (onClear)="onRecipientSelected()"
      (recipientSelected)="onRecipientSelected()"
      [form]="form"
      data-test-id="RecipientSearch"></np-inv-recipient-search>
  </div>
</div>

<div *ngIf="form.get('Contragent') !== null && form.get('RecipientSearchObject') !== null
            && form.get('RecipientSearchObject').value !== '' && clearFunction
            && removedContact !== null && !removedContact
            || form.get('Contragent') !== null && form.get('RecipientSearchObject') !== null
            && (form.get('RecipientSearchObject').value && form.get('RecipientSearchObject').value.Ref)"
     class="edit-form form-section__edit">
  <np-inv-recipient-counterparty-radio-group
    (onClear)="onRecipientSelected()"
    [form]="form"
    [invoiceId]="invoiceId"
    data-test-id="RecipientCounterpartyType"></np-inv-recipient-counterparty-radio-group>
  <np-inv-contact-recipient-counterparty
    *ngIf="(form.get('RecipientCounterpartyType')?.value === 'Organization' || form.get('RecipientCounterpartyType')?.value === 'OrganizationDelegate')"
    [form]="form"
    data-test-id="RecipientOrganizationSelect"
  ></np-inv-contact-recipient-counterparty>

  <np-inv-contact-recipient [form]="form"
                            data-test-id="RecipientContactSelect"></np-inv-contact-recipient>

  <np-inv-address-recipient [form]="form"
                            [invoiceForm]="invoiceForm" [invoiceId]="invoiceId" [options]="addressOptions"
                            [removedContact]="removedContact" [selectedAddress]="selectedAddress"
                            [templateValidation]="false"
                            data-test-id="RecipientAddress"></np-inv-address-recipient>
</div>

<div *ngIf="(form.get('RecipientSearchObject').value === '')
        || form.get('RecipientSearchObject').value === null
        || (!!form.get('RecipientSearchObject').value && !form.get('RecipientSearchObject').value.Ref)"
     class="edit-form form-section__edit">
  <np-inv-recipient-counterparty-radio-group
    (onClear)="onRecipientSelected()"
    [form]="form" [invoiceId]="invoiceId" [organizationDelegateDisabled]="organizationDelegateDisabled"
    data-test-id="RecipientCounterpartyType"></np-inv-recipient-counterparty-radio-group>
  <np-inv-contact-recipient-counterparty
    *ngIf="form.get('RecipientCounterpartyType') && (form.get('RecipientCounterpartyType').value === 'Organization' || form.get('RecipientCounterpartyType').value === 'OrganizationDelegate') && form.get('Contragent') && form.get('Contragent').value.EDRPOU === ''"
    [form]="form"
  ></np-inv-contact-recipient-counterparty>
  <np-inv-contact-organization
    *ngIf="form.get('RecipientCounterpartyType') && (form.get('RecipientCounterpartyType').value === 'Organization' || form.get('RecipientCounterpartyType').value === 'OrganizationDelegate') && ((form.get('Contragent') && form.get('Contragent').value.EDRPOU !== '') || !form.get('Contragent')) "
    [contactProperty]="'Recipient'" [controlName]="'NewContactOrganization'"
    [form]="form"
    [templateValidation]="false"
    data-test-id="RecipientOrganizationCreate"
  ></np-inv-contact-organization>
  <np-inv-contact-private-person
    (recipientSelected)="onRecipientSelected()"
    [contactProperty]="'Recipient'" [controlName]="'NewRecipientPrivateContact'"
    [form]="form"
    [invoiceId]="invoiceId"
    [isOrganizationDelegate]="form.get('RecipientCounterpartyType').value === 'OrganizationDelegate'"
    [templateValidation]="false" data-test-id="RecipientContactCreate"></np-inv-contact-private-person>
  <np-inv-address-recipient [clearFunction]="clearFunction"
                            [focused]="focused" [form]="form" [invoiceForm]="invoiceForm"
                            [invoiceId]="invoiceId" [options]="addressOptions"
                            [removedContact]="removedContact" [templateValidation]="false"
                            data-test-id="RecipientAddress"></np-inv-address-recipient>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs';
import { AvailableServiceNames, SERVICE_NAMES } from '../availableServices';
import { LocalExpressService } from './local-express.service';

@Component({
  selector: 'app-local-express',
  templateUrl: './local-express.component.html',
  styleUrls: ['./local-express.component.scss'],
  providers: [LocalExpressService],
})
export class LocalExpressComponent implements OnInit {
  readonly AvailableServiceNames = AvailableServiceNames;
  @Input() formGroup: UntypedFormGroup;

  disabled$ = this.service.disabled$;
  tooltip$ = this.service.tooltip$;

  recipientCityRef$ = this.service.recipientCityRef$;
  timeIntervalSelectionAvailable$ = this.service.timeIntervalSelectionAvailable$;
  DateTime$ = this.service.DateTime$;

  constructor(private service: LocalExpressService) {}

  get name(): string {
    return SERVICE_NAMES[AvailableServiceNames.localExpress].name;
  }

  get description(): string {
    return SERVICE_NAMES[AvailableServiceNames.localExpress].description;
  }

  ngOnInit(): void {
    this.service.localExpress$
      .pipe(
        tap((enable) => this.formGroup.get('selected').setValue(enable)),
        switchMap(() => this.formGroup.valueChanges),
        filter(() => this.formGroup.contains('TimeInterval')),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        take(1),
      )
      .subscribe(() => {
        this.formGroup.get('TimeInterval').setValue(this.service.timeInterval.value);
      });
  }
}

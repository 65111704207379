import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs';
import {ConfigService} from '../../../../../services/config.service';
import {NoRestApiHelper} from '../../../../../services/no-rest-api.helper';
import {Package} from './package.model';

@Injectable({
  providedIn: 'root',
})
export class PackingService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private noRestApiHelper: NoRestApiHelper,
  ) {

  }

  getPackListSpecial(
    method = 'getPackList',
    props?: {
      volumetricWeight?: string,
      senderWarehouseRef?: string,
      recipientWarehouseRef?: string,
      cargoType?: string,
    }): Observable<{ Filtered: Package[], Common: Package[] }> {
    const postObject: any = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: method,
      methodProperties: {
        PackForSale: '1',
      },
    };
    if (props.volumetricWeight) {
      postObject.methodProperties.VolumetricWeight = props.volumetricWeight;
    }
    if (props.recipientWarehouseRef) {
      postObject.methodProperties.WarehouseRef = props.recipientWarehouseRef;
    }
    if (props.senderWarehouseRef) {
      postObject.methodProperties.SenderWarehouseRef = props.senderWarehouseRef;
    }
    if (props.cargoType) {
      postObject.methodProperties.CargoType = props.cargoType;
    }
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data: any) =>
          data.warnings.length && !data.errors.length ? {...data, errors: data.warnings, success: false} : data),
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(response => response.data.data[0] as { Filtered: Package[], Common: Package[] } ),
      );
  }

  getPackList(): Observable<Package[]> {
    const postObject: any = {
      system: this.configService.get('system'),
      modelName: 'Common',
      calledMethod: 'getPackList',
      methodProperties: {
        PackForSale: '1',
      },
    };
    return this.http.post(`${this.configService.get('apiUrl')}json/`, postObject)
      .pipe(
        map((data: any) =>
          data.warnings.length && !data.errors.length ? {...data, errors: data.warnings, success: false} : data),
        map(data => this.noRestApiHelper.checkErrors({data})),
        map(response => response.data.data as Package[]),
      );
  }
}

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertToastService} from '@services';
import {AbstractFormContainer} from '@shared';
import {of} from 'rxjs';
import {catchError, take} from 'rxjs';
import {BusinessSettingsService} from '../../../../../services/business-settings.service';
import {CustomReturnAddressComponent} from '../custom-return-address/custom-return-address.component';

@Component({
  selector: 'app-custom-backwards-delivery-address',
  templateUrl: './custom-backwards-delivery-address.component.html',
  styleUrls: ['./custom-backwards-delivery-address.component.scss'],
})
export class CustomBackwardsDeliveryAddressComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  @ViewChild('customAddress', {read: CustomReturnAddressComponent}) customAddress;
  @Input() isCorporate = false;
  @Input() controlName = 'BackwardDeliveryData';
  @Input() templateValidation: boolean;
  @Input() cargoType: string = null;
  @Input() stepDeliveryForm: UntypedFormGroup;
  backwardDeliveryDataGroup: UntypedFormGroup;
  backDeliveryGroup: UntypedFormGroup;

  returnAddressControlName = 'CustomBackwardDeliveryParameterCustomAddress';

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private settingService: BusinessSettingsService,
    private cd: ChangeDetectorRef,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  _customAddresses: any[] = null;
  _ref = null;

  @Input() set ref(ref: string) {
    this._ref = ref;
    if (this.isCorporate) {
      this.getReturnAdresses(ref, this.cargoType);
    }
  }

  get ref() {
    return this._ref;
  }

  get customAddresses() {
    return this._customAddresses;
  }

  set customAddresses(addresses) {
    if (!addresses && this.isCorporate) {
      this.updateRef(null);
    }
    this._customAddresses = addresses;
  }

  getReturnAdresses(ref: string, cargoType: string) {
    this.settingService.getReturnAdresses(ref, cargoType, 'CargoReturn')
      .pipe(take(1),
        catchError(e => of(e)))
      .subscribe(data => {
        if (!data.length) {
          this.alertToastService.pushError('Відсутні нестандартні адреси повернення');
          this.backDeliveryGroup.patchValue({
            selected: false,
          });
        }
        this.customAddresses = data.length > 0 ?
          data.map(el => ({
            label: el.Address,
            value: el.Ref,
          })) : '';
        this.cd.detectChanges();
      });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.templateValidation) {
      const formGroup = this.form.get('AdditionalServices.customBackwardsDeliveryAddress').get(this.controlName);
      Object.keys(formGroup['controls']).forEach(key => formGroup['controls'][key].clearValidators());
    }

  }

  updateRef(ref: string) {
    this.backwardDeliveryDataGroup.get('CustomBackwardDeliveryParameterCustomAddress').setValue(ref);
  }

  addFormControls() {
    this.backDeliveryGroup = (this.form.get('AdditionalServices').get('customBackwardsDeliveryAddress') as UntypedFormGroup);
    this.backwardDeliveryDataGroup = this.formBuilder.group({
      CargoType: [this.cargoType, [
        Validators.required,
      ]],
      CustomBackwardDeliveryParameterCustomAddress: [''],
    });
    this.backDeliveryGroup.addControl(this.controlName, this.backwardDeliveryDataGroup);
  }

  ngOnDestroy() {
    this.backDeliveryGroup.removeControl(this.controlName);
  }

}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models';
import { AuthService } from '@services';
import {
  CardTypes,
  clearField,
  DoubleDateTableFilter,
  getError,
  objectIsEmpty,
  TableFilterConfig,
  Unsubscriber,
} from '@shared';
import { LocalStorageService } from 'ngx-webstorage';
import { debounceTime, Observable } from 'rxjs';

@Component({
  selector: 'app-table-filter-menu',
  templateUrl: './table-filter-menu.component.html',
  styleUrls: ['./table-filter-menu.component.scss'],
})
export class TableFilterMenuComponent extends Unsubscriber implements OnInit {
  readonly clearField = clearField;
  changedControls: Record<string, number> = {};
  user: User = this.authService.user;

  getError = getError;

  form: UntypedFormGroup;
  initialForm: any;
  isLoading: boolean;
  isDirty: boolean;

  @Input() filtered$: Observable<boolean>;
  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtersCleared: EventEmitter<void> = new EventEmitter<void>();
  private _filtersFromStorage: Record<string, any> = {};

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private storage: LocalStorageService,
    private dialogRef: MatDialogRef<TableFilterMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public config: TableFilterConfig,
  ) {
    super();
  }

  get filters(): Record<string, any> {
    return this._filtersFromStorage;
  }

  set filters(filters: Record<string, any>) {
    this._filtersFromStorage = filters;
  }

  private get storageKey(): string {
    return `${this.authService.user.userLogin}_${this.config.storageKey}`;
  }

  getGroup(groupName: string): UntypedFormGroup {
    return this.form.get(groupName) as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.initForm();

    this.setChangedControlsSubscription();

    this.patchForm();
  }

  clearFilters(): void {
    this.isLoading = true;
    this.storage.clear(this.storageKey);
    this.filtersCleared.emit();
    this.form.reset(this.initialForm);
    this.isLoading = false;
    this.dialogRef.close();
  }

  applyFilterForm(): void {
    this.isLoading = true;
    this.saveFiltersToStorage(this.form.getRawValue());
    this.filtersApplied.emit(this.form.getRawValue());
    this.dialogRef.close();
  }

  saveFiltersToStorage(filters: any): void {
    this.storage.store(
      this.storageKey,
      objectIsEmpty(filters) ? {} : filters,
    );
    this.retrieveFiltersFromStorage();
  }

  private getChangedControlsAmount(formGroupName: string, currentValue: any): number {
    const initialForm = this.initialForm[formGroupName];

    // Stringify is used to compare objects
    return Object.entries(currentValue).reduce((acc, [name, value]) => acc + +(JSON.stringify(initialForm[name]) !== JSON.stringify(value)), 0);
  }

  private retrieveFiltersFromStorage(): Record<string, any> {
    this.filters = this.storage.retrieve(this.storageKey) ?? {};

    return this.filters;
  }

  private initForm(): void {
    this.form = this.fb.group(
      this.config.filterGroups.reduce((acc, group) => {
        if (!group.controlName) {
          return;
        }
        acc[group.controlName] = this.fb.group(
          group.filters.reduce((acc, filter) => {
            if (filter.type === 'double-date') {
              const ddFilter = filter as DoubleDateTableFilter;
              acc[ddFilter.controlName] = this.fb.group({
                [ddFilter.fromFilter.controlName]: [ddFilter.fromFilter.initialValue, ddFilter.fromFilter.validators],
                [ddFilter.toFilter.controlName]: [ddFilter.toFilter.initialValue, ddFilter.toFilter.validators],
              }, {validators: ddFilter.validators});
              return acc;
            } else {
              acc[filter.controlName] = [filter.initialValue, filter.validators];
              return acc;
            }
          }, {}),
        );
        return acc;
      }, {}),
    );
    this.saveInitialForm();
  }

  private saveInitialForm(): void {
    this.initialForm = this.form.getRawValue();
  }

  private patchForm(): void {
    this.form.patchValue(this.retrieveFiltersFromStorage());
  }

  private setChangedControlsSubscription(): void {
    this.subscriptions = this.form.valueChanges.pipe(debounceTime(300)).subscribe((v) => {
      this.config.filterGroups.forEach((g) => {
        this.changedControls[g.controlName] = this.getChangedControlsAmount(g.controlName, v[g.controlName]);
      });
      this.isDirty = Object.values(this.changedControls).find((v) => v > 0) > 0;
    });
  }
}

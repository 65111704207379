import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractFormContainer} from '@shared';

@Component({
  selector: 'app-documents-signed',
  templateUrl: './documents-signed.component.html',
  styleUrls: ['./documents-signed.component.scss'],
})
export class DocumentsSignedComponent extends AbstractFormContainer implements OnInit, OnDestroy {

  @Input() controlName = 'BackwardDeliveryData';
  @Input() isCorporate = false;

  backwardDeliveryDataGroup: UntypedFormGroup;

  signedDocumentsGroup: UntypedFormGroup;
  _customAddresses: any[] = null;

  constructor(
    protected formBuilder: UntypedFormBuilder) {
    super();
  }

  get customAddresses() {
    return this._customAddresses;
  }

  get stepAddressControl(): AbstractControl {
    return this.form.parent.get('stepAddressForm');
  }

  get AdditionalServices(): AbstractControl {
    return this.stepAddressControl.get('AdditionalServices');
  }

  @Input() set customAddresses(addresses) {
    if (!addresses && this.isCorporate) {
      this.updateRef(null);
    }
    this._customAddresses = addresses;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  updateRef(ref: string) {
    this.backwardDeliveryDataGroup.get('CustomBackwardDeliveryParameter').setValue(ref);
  }

  addFormControls() {
    this.signedDocumentsGroup = (this.form.get('AdditionalServices').get('signedDocuments') as UntypedFormGroup);

    this.backwardDeliveryDataGroup = this.formBuilder.group({
      PayerType: ['Recipient', [
        Validators.required,
      ]],
      CargoType: ['SignedDocuments', [
        Validators.required,
      ]],
      RedeliveryString: ['', [
        Validators.required,
        Validators.maxLength(20),
      ]],
      CustomBackwardDeliveryParameter: [''],
    });
    this.signedDocumentsGroup.addControl(this.controlName, this.backwardDeliveryDataGroup);
  }

  ngOnDestroy() {
    this.signedDocumentsGroup.removeControl(this.controlName);
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AbstractFormContainer, getError } from '@shared';
import { errors, label, maxLength } from './accompanying-documents.constants';

@Component({
  selector: 'app-accompanying-documents',
  templateUrl: './accompanying-documents.component.html',
  styleUrls: ['./accompanying-documents.component.scss'],
})
export class AccompanyingDocumentsComponent
  extends AbstractFormContainer
  implements OnInit, OnDestroy
{
  getError = getError;

  errors = errors;

  label = label;

  @Input() controlName = 'AccompanyingDocuments';

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    this.form.addControl(
      this.controlName,
      new UntypedFormControl(
        '',
        Validators.compose([Validators.maxLength(maxLength)]),
      ),
    );
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }

  blockUnicode(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    const reg = new RegExp('[\u{FFFF}-\u{10FFFF}]', 'gu');
    if (reg.test(pastedText)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}

<div class="form-row box-with-controls" [formGroup]="organizationFormGroup">
  <div class="form-col form-col-2">
    <mat-form-field class="mat-block"
                    data-test-id="InputEDRPOU">
      <input matInput
             placeholder="Код ЄДРПОУ/РНОКПП"
             matAutofocus
             formControlName="edrpou"
             [mask]="'0000000000'"
             [validation]="false"
             [errorStateMatcher]="matcher"
             (blur)="onTouchValidation()">
      <mat-error>{{errorMessages['edrpou']}}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-col form-col-4">
    <mat-form-field class="mat-block"
                    data-test-id="CompanyName">
      <input matInput placeholder="Назва компанії" formControlName="companyName">
    </mat-form-field>
  </div>
  <div class="form-col form-col-2 box-with-controls"
       [class.last-col]="IsEnabledCancel">
    <mat-form-field class="mat-block"
                    data-test-id="OrganizationType">
      <input matInput placeholder="Форма власності" formControlName="orgType">
    </mat-form-field>
    <div class="control-icons"
         *ngIf="IsEnabledCancel">
      <div class="control-icons__item">
        <button mat-icon-button (click)="cancel()">
          <mat-icon class="material-icons_grey">
            cancel
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

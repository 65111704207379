import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractFormContainer } from '@shared';
import { DeliveryCostCheckPopupComponent } from '../../../components/invoice-form/components/services/delivery-cost-check-popup/delivery-cost-check-popup.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-cost',
  templateUrl: './delivery-cost.component.html',
  styleUrls: ['./delivery-cost.component.scss'],
})
export class DeliveryCostComponent
  extends AbstractFormContainer
  implements OnInit
{
  @Input() controlName = 'Cost';

  @Input() isTemplate: boolean;

  constructor(protected formBuilder: UntypedFormBuilder, public dialog: MatDialog) {
    super();
  }

  get backDeliveryControl() {
    return this.form.parent.get(
      'stepAddressForm.AdditionalServices.backDelivery.BackwardDeliveryData.RedeliveryString',
    );
  }

  get afterpaymentControl() {
    return this.form.parent.get(
      'stepAddressForm.AdditionalServices.afterpayment.AfterpaymentOnGoodsCost',
    );
  }

  get securePaymentControl() {
    return this.form.parent.get(
      'stepAddressForm.AdditionalServices.securePayment.BackwardDeliveryData.RedeliveryString',
    );
  }

  get serviceCost() {
    return this.backDeliveryControl?.value || this.afterpaymentControl?.value || this.securePaymentControl?.value;
  }

  get costControl() {
    return this.form.get(this.controlName);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls(): void {
    const descriptionControl = new UntypedFormControl(200, [
      ...(this.isTemplate ? [] : [Validators.required]),
      Validators.max(1000000),
      Validators.min(0.1),
    ]);
    this.form.setControl(this.controlName, descriptionControl);
  }

  change() {
    if (
      this.serviceCost &&
      +this.serviceCost > +this.costControl.value.toString().replace(/,/gi, '.')
    ) {
      this.openDialog(this.serviceCost);
    }
  }

  openDialog(value) {
    const dialogRef: MatDialogRef<DeliveryCostCheckPopupComponent> =
      this.dialog.open(DeliveryCostCheckPopupComponent, {
        panelClass: 'no-padding',
      });

    dialogRef.disableClose = true;
    dialogRef.componentInstance.cost = value;
    dialogRef.componentInstance.announcedCost = this.costControl.value;
    if (this.securePaymentControl) {
      dialogRef.componentInstance.type = 'securePayment';
    } else if (this.backDeliveryControl) {
      dialogRef.componentInstance.type = 'BackwardDeliveryData';
    } else {
      dialogRef.componentInstance.type = null;
    }

    dialogRef.componentInstance.onAdd.subscribe(() => {
      if (+value > +this.costControl.value) {
        this.costControl.setValue(value);
        this.costControl.updateValueAndValidity({ onlySelf: true });
      }
    });

    dialogRef.componentInstance.onClear.subscribe(() => {
      if (+value > +this.costControl.value) {
        const service = this.securePaymentControl || this.backDeliveryControl || this.afterpaymentControl;
        service.setValue(this.costControl.value);
        service.updateValueAndValidity({ onlySelf: true });
      }
    });

    dialogRef.afterClosed();
  }
}

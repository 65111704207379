<div class="modal modal_large">
  <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); dialogRef.close()">
    <i class="material-icons">close</i>
  </span>
  <div class="modal-header relative">
    <div class="form-row">
      <div class="form-col form-col-5">
        <h3 class="modal-header__title np-invoices-show__success">Заявка на подовження терміну зберігання</h3>
      </div>
    </div>
  </div>
  <main class="modal-content" [formGroup]="customerReturnForm">
    <div class="np-invoices-show__success relative">
      <div class="form-row">
        <div class="form-col form-col-8">
          <div class="banner">
            <div class="banner__info">
              <div class="warning-message">
                <div class="warning-message__text">Для подовження терміну зберігання відправлення оберіть: <br>
                  1. дату повернення (максимальний термін подовження {{type === 'in' ? '3 дні' : '10 днів'}})<br>
                  2. платника за зберігання<br>
                  3. спосіб оплати<br>
                  Вартість послуги розраховується згідно з
                  <a class="link-red" target="_blank"
                     href="https://novaposhta.ua/zberigannia">чинними тарифами</a> компанії «Нова Пошта»
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mr-bottom-16">
        <section class="form-col form-col-8">
          <stf-accordeon>
            <stf-accordeon-item class="np-header-invoice-redirect-info">
              <div header>
                <app-invoice-short-info [isIn]="type === 'in'"
                                        [isOut]="type === 'out'"
                                        [invoice]="invoice"></app-invoice-short-info>
              </div>
              <div body>
                <app-invoice-detailed-info [invoice]="invoice"></app-invoice-detailed-info>
              </div>
            </stf-accordeon-item>
          </stf-accordeon>
        </section>
      </div>
      <div class="form-row mr-bottom-16">
        <div class="form-col form-col-2">
          <mat-form-field class="mat-block" (click)="pickerDate.open()">
            <input matInput [matDatepicker]="pickerDate" formControlName="StorageFinalDate"
                   [min]="minDate"
                   [readonly]="true"
                   [matDatepickerFilter]="dateFilter"
                   [placeholder]="'Дата повернення'"
                   class="date-input">
            <mat-datepicker #pickerDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-col form-col-1 mat-block_with_symbol_md">
          <mat-form-field class="mat-block mat-block_with_symbol" data-symbol="днів">
            <input matInput formControlName="PeriodOfStorageCargo"
                   class="period-storage" placeholder="Термін подовження">
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col form-col-3">
          <div class="label-radio label-radio_dark">Платник за зберігання</div>
          <mat-radio-group formControlName="PayerType">
            <mat-radio-button [value]="'Sender'">Відправник</mat-radio-button>
            <mat-radio-button [value]="'Recipient'">Отримувач</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="form-col form-col-3">
          <div class="label-radio label-radio_dark">Спосіб оплати</div>
          <mat-radio-group formControlName="PaymentMethod">
            <mat-radio-button [value]="'Cash'">Готівка</mat-radio-button>
            <mat-radio-button [value]="'NonCash'">Безготівка</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </main>
  <div class="modal-footer modal-footer_pad-8">
    <div class="modal-footer-buttons">
      <button class="np-submit-redirect-invoice"
              color="primary"
              type="submit"
              [disabled]="customerReturnForm.invalid || pending"
              (click)="save()"
              mat-button>Створити заявку
      </button>
    </div>
  </div>
</div>

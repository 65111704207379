<div class="form-search">
  <mat-form-field [floatLabel]="'always'">
    <mat-spinner *ngIf="searching; else searchIcon"
                 class="form-search__spinner"></mat-spinner>
    <ng-template #searchIcon>
      <mat-icon class="form-search__icon">search</mat-icon>
    </ng-template>
    <mat-icon class="form-search__icon-close" tabindex="0" (click)="clearSearch()" *ngIf="inputFocused">close
    </mat-icon>
    <mat-label></mat-label>
    <input class="form-search__input"
           type="text"
           placeholder="Пошук у контактах за ПІБ"
           aria-label="Number"
           matInput
           autocomplete="off"
           [formControl]="control"
           (blur)="onBlur($event)"
           (focus)="onFocus($event)"
           (keydown.enter)="$event.preventDefault()">
  </mat-form-field>
</div>

import { AddressService, AlertToastService, ErrorTypeMessages } from '@services';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { checkCanEdit, formatUAPhone, getAddressType, getOrderRef, ValidateAddress } from '@shared';
import * as _ from 'lodash';
import { isNumber } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilKeyChanged, filter, finalize, Subscription, take } from 'rxjs';
import * as moment from 'moment';
import Rxmq, { Channel } from 'rxmq';
import {
  Address,
  AddressTypes,
  AutoCompleteOptionItem,
  AutoCompleteTransformFn,
  Contact,
  DoorAddress,
  IApiError,
  WarehouseAddress,
  WarehouseResult,
} from '@models';
import { InvoiceContact } from '../../../models/invoice/invoice-contact.model';
import { Invoice } from '../../../models/invoice/invoice.model';
import { ContactService } from '../../../services/contacts/contact.service';
import { RedirectInvoicesService } from '../../../services/redirect-invoices.service';
import { AnalyticEvent } from '../../interfaces/analytic-event';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { RedirectInvoice, RedirectInvoiceResponse } from '../../../models/redirect-invoice';
import { PayInvoiceType } from '../../other/payment-strategy';
import { ConfirmDialogModernConfig } from '../confirm-dialog-modern/confirm-dialog-modern.component';
import { ConfirmDialogModernService } from '../confirm-dialog-modern/confirm-dialog-modern.service';
import { ControlModes } from '../addresses/recipient-address/address-block.enums';

const componentName = 'np-redirect-invoices-form';

@Component({
  selector: componentName,
  templateUrl: './redirect-invoices-form.component.html',
  styleUrls: ['./redirect-invoices-form.component.scss'],
})
export class RedirectInvoicesFormComponent implements OnInit, OnDestroy {
  protected readonly ControlModes = ControlModes;

  @ViewChild('modalContent') modalContent: ElementRef;

  Pricing: number | null = null;
  ScheduledDeliveryDate: Date | null = null;

  AddressTypes = AddressTypes;
  @Input()
  inputInvoice;

  showFlat = false;

  @Input()
  type: PayInvoiceType = 'in';

  descriptionMaxLength = 36;
  errorsMessages: { [key: string]: string } = {};
  invoice: Invoice;
  isPending = false;
  isCheckPending = false;
  cancelPending = false;
  recipientAddresses: Address[];
  form: UntypedFormGroup;
  _canCreate = false;
  isCalculated = false;
  formValuesAfterCalculation = {};

  options: Array<AutoCompleteOptionItem<any>> = [];
  contactValue;
  recipientRef: string;
  formGroup: UntypedFormGroup;
  latestCity;
  recipientCity;
  recipientMode: 'create' | 'select' = 'select';
  organizationMode: 'create' | 'select' = 'select';
  recipientPhone: string;
  customerIsSender: boolean;
  allowRecipientNameChanges: boolean;
  recommendationWarehouses: Array<AutoCompleteOptionItem<Partial<WarehouseResult>>>;
  recommendationWarehousesDisabled = false;
  recommendationWarehousesHidden = false;
  settlementRecipient: string;
  recipientEDRPOU: string;
  privatePersonRef: string;

  recipientAddressChangeSub: Subscription;
  checkRedirectResponse: RedirectInvoiceResponse | null = null;

  recipientAddressLabel = '';
  redirectInvoiceOrderNumber = '';

  get submitDisabled(): boolean {
    return this.isPending || this.incorrectCheckData || this.cancelPending;
  }

  get submitButtonTooltip(): string {
    if (this.incorrectCheckData) {
      return 'Відсутні дані про відправлення, зверніться до підтримки';
    }
    return '';
  }

  get incorrectCheckData(): boolean {
    return !this.checkRedirectResponse?.data[0]?.ServiceType ?? false;
  }

  private redirectInvoicesServiceSubscription: Subscription;
  private addAddressSubscr: Subscription;
  private addContactSubscr: Subscription;
  private errors: ErrorTypeMessages;
  private phoneBefore: string;
  private recipientBefore: InvoiceContact;
  private orgRecipientAddress: Address;
  private orgRecipient: InvoiceContact;
  private privateRecipient: InvoiceContact;
  private privateRecipientAddress: Address;
  private recipientSubscription: Subscription;

  constructor(
    public router: Router,
    private addressService: AddressService,
    private alertToastService: AlertToastService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private contactService: ContactService,
    private contactsService: ContactsService,
    private redirectInvoicesService: RedirectInvoicesService,
    public dialogRef: MatDialogRef<RedirectInvoicesFormComponent>,
    private confirmService: ConfirmDialogModernService,
    private toastr: ToastrService,
  ) {
  }

  get redirectOrderRef(): string {
    return getOrderRef(this.inputInvoice.OrderingAdditionalServices, 'Redirecting') ?? '';
  }

  get recipientType(): FormControl {
    return this.form.get('recipientType') as FormControl;
  }

  get canCreate(): boolean {
    return this._canCreate;
  }

  set canCreate(value: boolean) {
    this._canCreate = value;
  }

  get privatePerson(): boolean {
    return this.recipientType.value === 'PrivatePerson';
  }

  get recipientData() {
    return this.form.get('recipientData');
  }

  get recipientCounterparty() {
    return this.form.get('recipientCounterparty');
  }

  get recipientAddress(): UntypedFormGroup {
    return this.form.get('recipientAddress') as UntypedFormGroup;
  }

  get addressTypeValue(): AddressTypes {
    return this.form.get('addressType')?.value;
  }

  ngOnDestroy(): void {
    this.addAddressSubscr.unsubscribe();
    this.addContactSubscr.unsubscribe();
    this.recipientAddressChangeSub?.unsubscribe();
  }

  mode: 'edit' | 'create' = 'create';

  get isCreateMode(): boolean {
    return this.mode === 'create';
  }

  private setMode(): void {
    this.mode = checkCanEdit(this.inputInvoice.OrderingAdditionalServices, 'Redirecting') ? 'edit' : 'create';
    if (this.isCreateMode) {
      this.form.get('recipientAddress').enable();
      return;
    }
    if (this.isAddressEditing) {
      this.form.get('recipientAddress').enable();
    } else {
      this.form.get('recipientAddress').disable();
    }
  }

  get formLabel(): string {
    return this.mode === 'create' ? 'Заявка на переадресацію' : 'Редагування переадресації ' + this.redirectInvoiceOrderNumber || '';
  }

  isAddressEditing = false;

  toggleAddressEditing(): void {
    this.isAddressEditing = !this.isAddressEditing;
    if (this.isAddressEditing) {
      this.form.get('recipientAddress').enable();
    } else {
      this.form.get('recipientAddress').disable();
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.checkIfCanRedirect();
    this.initOnRecipientChange();
    this.initOnRecipientAddressChange();
    this.iniOnAddressAdded();
    this.iniOnContactAdded();
    this.recipientData.disable();
    this.getPrivatePersonRef();
    this.initFormValueChangesSubscription();
    this.setMode();
  }

  initFormValueChangesSubscription(): void {
    this.form.valueChanges
      .subscribe((value) => {
        const hasChanges = JSON.stringify(value) !== JSON.stringify(this.formValuesAfterCalculation);

        if (hasChanges) {
          this.redirectInvoicesServiceSubscription?.unsubscribe();
        }

        if (this.isCalculated && hasChanges) {
          this.canCreate = false;
        }
      });
  }

  cancel($event: Event): void {
    $event.preventDefault();

    const OrderRef = this.redirectOrderRef;

    if (!OrderRef) {
      return;
    }

    const cancelConfig: ConfirmDialogModernConfig = {
      title: 'Ви впевнені, що бажаєте відмінити переадресацію?',
      text: 'Після відміни переадресацію можна створити повторно.</br></br>Якщо ви замовили й уже оплатили послугу "Переадресування", кошти будуть повернені найближчим часом.',
      isHtml: true,
      confirmBtnText: 'Так, відмінити',
      cancelBtnText: 'Ні, залишити',
      isAsync: true,
      hasLoading: true,
      callback: () => this.handleCancelRedirect(OrderRef),
    };

    this.confirmService.confirm(cancelConfig);
  }

  private handleCancelRedirect(OrderRef: string): void {
    this.cancelPending = true;

    this.redirectInvoicesService
      .remove(OrderRef)
      .pipe(take(1), finalize(() => {
        this.cancelPending = false;
      }))
      .subscribe(data => {
          if (data) {
            this.confirmService.close();
            this.dialogRef.close({ success: true });
            this.alertToastService.pushSuccess('Заявку на видалення прийнято. Дані буде оновлено протягом декількох хвилин');

            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
              'analitics',
            )
              .subject(`${componentName}.remove.success`)
              .next({
                eventLabel: `Видалити заявку на перенаправлення: ${this.redirectInvoiceOrderNumber}`,
                eventCategory: componentName,
                eventAction: 'remove.success',
              });
          }
        }, errors => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.confirmService.close();

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.remove.error`)
            .next({
              eventLabel: `Видалити заявку на перенаправлення: ${errors[0] && errors[0].messageCode}`,
              eventCategory: componentName,
              eventAction: 'remove.error',
            });
        },
      );
  }

  getPrivatePersonRef(): void {
    this.contactsService
      .getPrivatePersonInfo()
      .subscribe((data) => (this.privatePersonRef = data.Ref));
  }

  createForm() {
    this.form = this.fb.group({
      recipientType: ['PrivatePerson', Validators.required],
      phone: [
        '',
        Validators.compose([Validators.required, Validators.minLength(12)]),
      ],
      recipient: [null, Validators.required],
      recipientData: [null, Validators.required],
      recipientCounterparty: [null, Validators.required],
      recipientAddress: [null, Validators.required],
      payer: [null, Validators.required],
      PaymentMethod: [null, Validators.required],
      addressType: [{ value: 'Warehouse', disabled: true }, Validators.required],
      noteAddressRecipient: [null],
      description: [
        'Переадресація через Бізнес кабінет',
        Validators.compose([
          Validators.required,
          (control) =>
            /^[а-яА-ЯїЇєЄіІёЁґҐ`ʼ'-. ]{0,}$/.test(control.value)
              ? null
              : { 'no-russian': true },
          Validators.maxLength(this.descriptionMaxLength),
        ]),
      ],
    });

    this.errors = {
      phone: [
        { key: 'required', message: 'Це поле не може бути пустим' },
        { key: 'minlength', message: 'Телефон має містити 12 цифр' },
      ],
      description: [
        { key: 'required', message: 'Це поле не може бути пустим' },
        {
          key: 'no-russian',
          message: 'Містить невалідні символи або латиницю',
        },
        {
          key: 'maxlength',
          message: `Максимальна довжина ${this.descriptionMaxLength}`,
        },
      ],
    };
    this.createControl('');
  }

  setLatestCity(city) {
    this.latestCity = city;
  }

  setRecipientCity(city) {
    this.recipientCity = city;
  }

  createControl(
    defaultValue: DoorAddress | WarehouseAddress | '',
    defaultMode: string = 'select',
  ): void {
    this.formGroup = this.fb.group({
      mode: [defaultMode],
      type: [defaultValue ? getAddressType(defaultValue) : 'Warehouse'],
      data: [
        defaultValue,
        Validators.compose([Validators.required, ValidateAddress]),
      ],
    });
    this.form.setControl('recipientAddress', this.formGroup);
  }

  onFocus() {
    this.phoneBefore = this.form.value.phone;
  }

  onOpenCloseInvoiceInfo(event) {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.onOpenCloseInvoiceInfo`)
      .next({
        eventLabel: `Наклада заявки, перегляд детальної інформації`,
        eventCategory: componentName,
        eventAction: event.open ? 'open' : 'close',
      });
  }

  onInputReason(event) {
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.onInputReason`)
      .next({
        eventLabel: `Причина`,
        eventCategory: componentName,
        eventAction: 'input',
      });
  }

  remapRedirectFormValue(formValue) {
    const invoiceData = _.cloneDeep(formValue);

    // TODO how to handle if invoice was from address?
    if (invoiceData.recipientAddress?.data) {
      if (this.addressTypeValue === AddressTypes.WAREHOUSE) {
        invoiceData.recipientAddress = {
          id: invoiceData.recipientAddress.data.warehouse.Ref,
          addressType: invoiceData.addressType,
        };
      }

      if (this.addressTypeValue === AddressTypes.POSTBOX) {
        invoiceData.recipientAddress = {
          id: invoiceData.recipientAddress.data.postbox.Ref,
          addressType: invoiceData.addressType,
        };
      }

      if (this.addressTypeValue === AddressTypes.INDEX) {
        invoiceData.recipientAddress = {
          id: invoiceData.recipientAddress.data.warehouse.Ref,
          addressType: AddressTypes.WAREHOUSE,
        };
      }

      if (this.addressTypeValue === AddressTypes.DOORS) {
        invoiceData.recipientAddress = {
          city: this.remapRefToId(invoiceData.recipientAddress.data.city),
          building: invoiceData.recipientAddress.data.building,
          street: invoiceData.recipientAddress.data.street.SettlementStreetDescription,
          id: invoiceData.recipientAddress.data.street.SettlementStreetRef,
          noteAddressRecipient: invoiceData.recipientAddress.data.note,
        };
      }

      if (
        invoiceData.recipientAddress.type ===
        AddressTypes.RECOMMENDATION_WAREHOUSES
      ) {
        invoiceData.recipientAddress = {
          city: {
            ...invoiceData.recipientAddress.data.city,
            id: this.settlementRecipient,
          },
          id: invoiceData.recipientAddress.data.warehouse.Ref,
          addressType: invoiceData.addressType,
        };
      }
    } else {
      if (this.checkRedirectResponse.data[0].ServiceType === 'WarehouseDoors' || this.checkRedirectResponse.data[0].ServiceType === 'DoorsDoors') {
        invoiceData.recipientAddress = {
          id: this.checkRedirectResponse?.data[0]?.RecipientSettlementStreet ?? '',
          building: this.checkRedirectResponse?.data[0]?.BuildingNumber ?? '',
          city: { id: this.checkRedirectResponse?.data[0]?.SettlementRecipient ?? '' },
          street: this.checkRedirectResponse?.data[0]?.RecipientSettlementStreet ?? '',
          noteAddressRecipient: this.checkRedirectResponse?.data[0]?.NoteAddressRecipient ?? '',
        };
      } else {
        invoiceData.recipientAddress = { id: this.checkRedirectResponse?.data[0]?.WarehouseRef ?? '' };
      }
    }

    invoiceData.IntDocNumber = this.checkRedirectResponse?.data[0]?.Ref ?? '';

    invoiceData.recipient = {
      description: invoiceData.recipientData
        ? this.joinName(invoiceData.recipientData)
        : invoiceData.recipient.RecipientName,
      phone: invoiceData.recipientData
        ? invoiceData.recipientData.phone
        : invoiceData.recipient.PhoneRecipient,
    };

    if (!this.checkRedirectResponse.data[0].PhoneRecipient) {
      invoiceData.recipient.phone = '';
    }

    if (
      invoiceData.recipientType === 'Organization' &&
      invoiceData.Contragent
    ) {
      invoiceData.recipient.contragent = invoiceData.Contragent.Ref;
    } else if (
      invoiceData.recipientType === 'Organization' &&
      !invoiceData.Contragent
    ) {
      invoiceData.recipient.contragent = this.recipientRef;
    }

    if (invoiceData.recipientType === 'PrivatePerson') {
      invoiceData.recipient.contragent = this.privatePersonRef;
    }

    return invoiceData;
  }

  remapRefToId(obj) {
    return obj && obj.Ref ? { ...obj, id: obj.Ref } : obj;
  }

  private scrollToBottom(): void {
    const modalContent = this.modalContent.nativeElement;
    modalContent.scrollTop = modalContent.scrollHeight;
  }

  get isOrganizationExist(): boolean {
    return this.form.controls.recipientType.value === 'Organization'
      && this.organizationMode === 'create'
      && !this.form.controls.Contragent
  }

  calculate(event: Event): void {
    event.preventDefault();

    if (this.isOrganizationExist) {
      this.toaster('Організація не існує або неправильно вказано код ЄДРПОУ');
      return;
    }

    if (this.form.invalid) {
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.submit.invalid`)
        .next({
          eventLabel: `Переадресація: ${this.inputInvoice.docNumber}, не валідна форма`,
          eventCategory: componentName,
          eventAction: 'submit.invalid',
        });
      this.markFormGroupTouched(this.form);
      return;
    }

    this.isPending = true;
    this.scrollToBottom();

    const redirectInvoice = Object.assign(
      new RedirectInvoice(),
      this.remapRedirectFormValue(this.form.value),
    );

    redirectInvoice.customer = this.checkRedirectResponse.info.Customer ?? '';

    const OrderRef = this.redirectOrderRef;

    let addressType: AddressTypes = AddressTypes.WAREHOUSE;

    if (this.isCreateMode || this.isAddressEditing) {
      addressType = this.addressTypeValue;
    } else {
      if (this.checkRedirectResponse.data[0].ServiceType === 'WarehouseDoors' || this.checkRedirectResponse.data[0].ServiceType === 'DoorsDoors') {
        addressType = AddressTypes.DOORS;
      }
    }

    this.redirectInvoicesServiceSubscription?.unsubscribe();

    if (this.isCreateMode) {
      this.redirectInvoicesServiceSubscription = this.redirectInvoicesService.getPricing(redirectInvoice, this.inputInvoice, addressType, OrderRef)
        .pipe(finalize(() => {
          this.isPending = false;
        }))
        .subscribe(
          (data) => this.handleCalculateResponse(data),
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    } else {
      this.redirectInvoicesServiceSubscription = this.redirectInvoicesService.getPricingForUpdate(redirectInvoice, this.inputInvoice, addressType, OrderRef)
        .pipe(finalize(() => {
          this.isPending = false;
        }))
        .subscribe(
          (data) => this.handleCalculateResponse(data),
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    }
  }

  private handleCalculateResponse(data): void {
    this.isPending = false;
    this.Pricing = isNumber(data.Pricing?.Total) ? data.Pricing?.Total : -1;
    this.ScheduledDeliveryDate = data.ScheduledDeliveryDate ? moment(data.ScheduledDeliveryDate, 'YYYY-MM-DD HH:mm:ss').toDate() : null;

    this.canCreate = true;
    this.isCalculated = true;
    this.formValuesAfterCalculation = this.form.value;
    setTimeout(() => this.scrollToBottom());
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control) => {
      if (!control.disabled) {
        this.markControlTouched(control);
      }
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  private markControlTouched(control: FormControl | AbstractControl): void {
    control.markAsDirty();
    control.markAsTouched();
  }

  private toaster(message: string) {
    this.toastr.info(message, '', {
      closeButton: true,
      timeOut: 10000,
      toastClass: 'toast custom-style',
    });
  }

  submit(event: Event): void {
    event.preventDefault();

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.submit`)
      .next({
        eventLabel: `Переадресація: ${this.inputInvoice.docNumber}`,
        eventCategory: componentName,
        eventAction: 'submit',
      });

    if (!this.form.valid) {
      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.submit.invalid`)
        .next({
          eventLabel: `Переадресація: ${this.inputInvoice.docNumber}, не валідна форма`,
          eventCategory: componentName,
          eventAction: 'submit.invalid',
        });
      return;
    }

    this.isPending = true;

    const redirectInvoice = Object.assign(
      new RedirectInvoice(),
      this.remapRedirectFormValue(this.form.value),
    );

    redirectInvoice.customer = this.checkRedirectResponse.info.Customer;

    let payloadInvoice = {
      ...this.inputInvoice,
    };

    if (!this.isCreateMode) {
      payloadInvoice.Ref = this.checkRedirectResponse.data[0].Ref;
      payloadInvoice.docNumber = undefined;
    }

    let addressType: AddressTypes = AddressTypes.WAREHOUSE;

    if (this.isCreateMode || this.isAddressEditing) {
      addressType = this.addressTypeValue;
    } else {
      if (this.checkRedirectResponse.data[0].ServiceType === 'WarehouseDoors' || this.checkRedirectResponse.data[0].ServiceType === 'DoorsDoors') {
        addressType = AddressTypes.DOORS;
      }
    }

    const orderRef = this.checkRedirectResponse.data[0].Ref ?? '';

    const request = this.isCreateMode
      ? this.redirectInvoicesService.create(redirectInvoice, payloadInvoice, addressType)
      : this.redirectInvoicesService.update(redirectInvoice, payloadInvoice, addressType, false, orderRef);

    request
      .pipe(finalize(() => this.isPending = false))
      .subscribe(
        (data) => {
          this.dialogRef.close({
            isCreated: this.isCreateMode,
            success: true,
            Invoice: this.inputInvoice,
            Number: data.Number,
            MessageText: data.MessageText,
            Pricing: isNumber(data.Pricing?.Total) ? data.Pricing?.Total : -1,
            ScheduledDeliveryDate: data.ScheduledDeliveryDate ? moment(data.ScheduledDeliveryDate, 'YYYY-MM-DD HH:mm:ss').toDate() : null,
          });

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.submit.success`)
            .next({
              eventLabel: `Переадресація: ${this.inputInvoice.docNumber}`,
              eventCategory: componentName,
              eventAction: 'submit.success',
            });
        },
        (errors) => {
          this.alertToastService.pushApiErrors(errors);

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
            'analitics',
          )
            .subject(`${componentName}.submit.error`)
            .next({
              eventLabel: `Переадресація: ${
                this.inputInvoice.docNumber
              }, помилка: ${errors[0] && errors[0].message}`,
              eventCategory: componentName,
              eventAction: 'submit.error',
            });
        },
      );
  }

  changeMode(modeType: string, action: string) {
    this[modeType] = action;
    this.setDefaultRecipient();
  }

  transformFn: AutoCompleteTransformFn<any> = (value: string | any) =>
    typeof value === 'string'
      ? value
      : `${(value && value.RecipientName) || ''} ${formatUAPhone(
          value.PhoneRecipient || '',
        )}`;

  joinName(recipient): string {
    if (!recipient) {
      return '';
    }
    const firstName = recipient.firstName;
    const lastName = recipient.lastName;
    const middleName = recipient.middleName
      ? recipient.middleName[0]?.toUpperCase() +
        recipient.middleName.substring(1)
      : '';
    return [
      lastName[0]?.toUpperCase() + lastName.substring(1),
      firstName[0]?.toUpperCase() + firstName.substring(1),
      middleName,
    ]
      .join(' ')
      .trim();
  }

  joinDescription(recipient): string {
    if (!recipient) {
      return '';
    }
    return [
      recipient.lastName,
      recipient.firstName,
      recipient.middleName,
      formatUAPhone(recipient.phone),
    ]
      .join(' ')
      .trim();
  }

  private checkIfCanRedirect() {
    const OrderRef = getOrderRef(this.inputInvoice.OrderingAdditionalServices, 'Redirecting');
    this.isCheckPending = true;
    this.redirectInvoicesService.check(this.inputInvoice.docNumber, OrderRef)
      .pipe(finalize(() => this.isCheckPending = false))
      .subscribe(
        (success) => {
          if (!success) {
            return;
          }

          const res = success.data[0];
          this.checkRedirectResponse = success;
          this.redirectInvoiceOrderNumber = res.Number;
          if (!res.RecommendationWarehouses?.length) {
            this.recommendationWarehousesDisabled = true;
          }
          this.recommendationWarehouses =
            res.RecommendationWarehouses?.map((warehouse) => ({
              option: {
                Ref: warehouse.WarehouseRef,
                Description: warehouse.WarehouseDescription,
              },
            }));

          this.inputInvoice.ServiceType = res.ServiceType;
          if (
            res.ServiceType === 'WarehouseWarehouse' ||
            res.ServiceType === 'DoorsWarehouse'
          ) {
            this.recommendationWarehousesHidden = true;
            const str: string[] = [];
            str.push(res.CityRecipientDescription || res.SettlementRecipientDescription);
            str.push(res.WarehouseDescription);

            this.recipientAddressLabel = str.filter(v => !!v).join(', ');
          }
          if (
            res.ServiceType !== 'WarehouseWarehouse' &&
            res.ServiceType !== 'DoorsWarehouse'
          ) {
            this.setRecipientCity({
              Ref: res.CityRecipient || '',
              Present: res.CityRecipientDescription,
            });
            this.recipientAddressLabel = '';
          }

          if (res.ServiceType === 'WarehouseDoors' || res.ServiceType === 'DoorsDoors') {
            this.recipientAddressLabel = res.AddressDescription;
          }

          this.settlementRecipient = success.data[0].SettlementRecipient;

          this.customerIsSender = success.info.Customer === 'Sender';
          this.allowRecipientNameChanges =
            success.info.Customer === 'Recipient' &&
            res.InternationalDeliveryType === 'Import';
          this.recipientEDRPOU = res.EdrpouRecipient;

          success.data.map((value) => {
            this.recipientPhone = value?.PhoneRecipient;
            this.contactValue = `${(value && value.RecipientName) || ''}`;
            this.recipientRef = value.CounterpartyRecipientRef;

            this.form.get('recipient').setValue(value);
            this.form.get('payer').setValue(value.PayerType);
            this.form.get('PaymentMethod').setValue(value.PaymentMethod);
            this.form.get('phone').setValue(this.inputInvoice.PhoneRecipient);
            this.recipientType.setValue(this.getRecipientType(value));
            this.recipientCounterparty.setValue(value.CounterpartyRecipientDescription);
          });
          this.setDefaultRecipient();
          this.form.get('addressType').enable();
        },
        (errors) => {
          this.dialogRef.close();
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
        },
      );
  }

  private getRecipientType(value) {
    return value.CounterpartyRecipientDescription === 'Приватна особа'
      ? 'PrivatePerson'
      : 'Organization';
  }

  private setDefaultRecipient(): void {
    this.recipientData.patchValue({
      lastName: this.contactValue.split(' ')[0],
      firstName: this.contactValue.split(' ')[1],
      middleName: this.contactValue.split(' ')[2] || '',
      phone: this.recipientPhone,
    });
    this.recipientData.updateValueAndValidity({ onlySelf: true });
  }

  private getRecipientAddresses(recipientId) {
    this.addressService
      .getList(
        { contactId: recipientId, isNoPostomat: true, onlyWarehouse: true },
        null,
        1,
        1000,
      )
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.recipientAddresses = data.list;

          if (this.recipientAddresses.length === 1) {
            this.form.patchValue({
              recipientAddress: this.recipientAddresses[0],
            });
          }

          this.cd.markForCheck();
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            if (
              errors[0].errorCode ===
              'LOADING_OF_ADDRESSES_FAILED_CONTACT_IS_DELETED'
            ) {
              this.form.patchValue({ recipientAddress: null });
              this.form.patchValue({ recipient: null });
            } else {
              this.alertToastService.pushApiErrors(errors);
            }

            this.cd.markForCheck();
          }
        },
      );
  }

  private iniOnAddressAdded() {
    this.addAddressSubscr = (Rxmq.channel<Channel<Address>, Address, Address>(
      'np-address-edit',
    ) as any)
      .subject('added')
      .subscribe((address) => {
        this.dialog.closeAll();
        this.form.patchValue({ recipientAddress: address });
        this.getRecipientAddresses(
          this.form.value.recipient && this.form.value.recipient.id,
        );
      });
  }

  private iniOnContactAdded() {
    this.addContactSubscr = (Rxmq.channel<Channel<Contact>, Contact, Contact>(
      'np-contact-form',
    ) as any)
      .subject('added')
      .subscribe((recipient) => {
        this.dialog.closeAll();
        this.form.patchValue({ phone: recipient.phone });
        this.form.patchValue({ recipient });
        this.form.patchValue({
          recipientType: recipient.contragent.ownerType,
        });
      });
  }

  private initOnRecipientChange() {
    this.form
      .get('recipient')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilKeyChanged('id'),
        filter((recipient) => recipient?.id !== this.recipientBefore?.id),
      )
      .subscribe((rec: InvoiceContact) => {
        if (!rec.isCatalogContact) {
          this.getRecipientAddresses(rec.id);
          if (rec && rec.id) {
            if (this.recipientSubscription) {
              this.recipientSubscription.unsubscribe();
              this.recipientSubscription = null;
            }
            this.recipientSubscription = this.contactService
              .getOne(rec.id)
              .pipe(take(1))
              .subscribe(
                (recipient) => {
                  this.form.patchValue({ recipient });
                  this.form.patchValue({ phone: recipient.phone });
                },
                (errors: IApiError[]) => {
                  this.form.patchValue({ recipient: null });

                  if (errors[0].errorCode === 'CONTACT_IS_DELETED') {
                    this.alertToastService.pushWarn(errors[0].translatedError);
                  } else {
                    this.alertToastService.pushError(errors[0].translatedError);
                  }
                },
              );
          }
        } else {
          if (this.recipientSubscription) {
            this.recipientSubscription.unsubscribe();
            this.recipientSubscription = null;
          }

          this.recipientSubscription = this.contactService
            .add(rec)
            .pipe(take(1))
            .subscribe((recipient) => {
              this.form.patchValue({ recipient });
            });
        }

        if (this.form.controls.recipientType.value === 'Priv') {
          this.privateRecipient = rec;
        } else {
          this.orgRecipient = rec;
        }
      });
  }

  private initOnRecipientAddressChange(): void {
    this.recipientAddressChangeSub?.unsubscribe();
    this.recipientAddressChangeSub = this.form.controls.recipientAddress.valueChanges
      .pipe(
        filter(
          (recipientAddress) =>
            (recipientAddress && recipientAddress.id) !==
            (this.recipientBefore && this.recipientBefore.id),
        ),
        debounceTime(300),
      )
      .subscribe((recipientAddress) => {
        if (this.form.controls.recipientType.value === 'Priv') {
          this.privateRecipientAddress = recipientAddress;
        } else {
          this.orgRecipientAddress = recipientAddress;
        }
      });
  }
}

<div class="form-search">
  <mat-form-field appearance="fill"
                  class="search-input-field">
    <!--Prefix-->
    <button *ngIf="loading; else search"
            class="p-0 transform"
            mat-button
            matPrefix
            mat-icon-button
            [disabled]="true">
      <mat-spinner [diameter]="22" class="search-spinner input-spinner-gray"></mat-spinner>
    </button>

    <ng-template #search>
      <button class="p-0 transform"
              mat-button
              matPrefix
              mat-icon-button
              [disabled]="true">
        <mat-icon matPrefix class="search-icon">search
        </mat-icon>
      </button>

    </ng-template>
    <!--Prefix-->

    <input [formControl]="control"
           [placeholder]="placeholder"
           [maxLength]="searchMaxLength"
           class="search-input"
           matInput
           autocomplete="off"
           (click)="$event.stopPropagation()">

    <!--Suffix-->
    <button *ngIf="control?.value"
            (click)="clearSearch($event)"
            class="p-0 clear-btn"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>
    <!--Suffix-->
  </mat-form-field>
</div>

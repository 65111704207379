import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutocompleteMode, AutoCompleteOptionItem } from '@autocomplete-with-controls';
import { AddressType, AddressTypes, CounterpartyName, Model } from '@models';
import { AddressService, AlertToastService, GoogleAnalyticService } from '@services';
import { AnalyticEvent, checkCanEdit, checkPricingValue, getOrderRef } from '@shared';
import { TypeRadioButton } from '@type-radio-group';
import * as moment from 'moment';
import { BehaviorSubject, distinctUntilChanged, filter, finalize, Subscription, switchMap, take } from 'rxjs';
import Rxmq, { Channel } from 'rxmq';
import { ReturnReason } from '../../../components/dashboard/business-services/models/return-reason';
import { Invoice } from '../../../models/invoice/invoice.model';
import { BackInvoicesService } from '../../../services/back-invoices.service';
import { ContactService } from '../../../services/contacts/contact.service';
import { InvoiceService } from '../../../services/invoices/invoice.service';
import { PayInvoiceType } from '../../other/payment-strategy';
import { ConfirmDialogModernConfig } from '../confirm-dialog-modern/confirm-dialog-modern.component';
import { ConfirmDialogModernService } from '../confirm-dialog-modern/confirm-dialog-modern.service';
import { ReturnDocumentType, ReturnRequestControlName } from './return-request.enums';
import { CheckPossibilityCreateReturnData } from './return-request.interfaces';

export interface IReturnAddress {
  id: string;
  description: string;
  nonCash: boolean;
  type: string;
  city: string;
  contactPerson: string;
  counterParty: string;
  phone: string;
  payerTypeDefault: string;
}

const componentName = 'np-back-invoices-form';

@Component({
  selector: componentName,
  templateUrl: './back-invoices-form.component.html',
  styleUrls: ['./back-invoices-form.component.scss'],
})
export class BackInvoicesFormComponent implements OnInit {
  @ViewChild('modalContent') modalContent: ElementRef;

  private _invoice: Invoice = null;

  @Input() type: PayInvoiceType = 'out';

  @Input() set invoice(invoice: Invoice) {
    this._invoice = invoice;
  }

  get invoice(): Invoice {
    return this._invoice;
  }

  ControlName = ReturnRequestControlName;

  form: UntypedFormGroup;
  returnReasons: ReturnReason[];
  returnSubReasons: ReturnReason[];

  types: TypeRadioButton[] = [
    {
      value: 'Cash',
      label: 'Готівка',
    },
    {
      value: 'NonCash',
      label: 'Безготівка',
    },
  ];

  defaultReason: any = {
    id: '49754eb2-a9e1-11e3-9fa0-0050568002cf',
    title: 'Відмова від доставки',
    SubtypeReason: null,
  };

  loading: boolean = false;
  _canCreate = false;
  isCalculated = false;
  cancelPending = false;
  mode: 'edit' | 'create' = 'create';
  formValuesAfterCalculation = null;
  backInvoicesServiceSubscription: Subscription;
  recipientCounterpartyHidden: boolean = false;
  returnInvoiceOrderNumber = '';

  Pricing: number | null = null;
  ScheduledDeliveryDate: Date | null = null;

  expressWaybillDocument: AutoCompleteOptionItem<CheckPossibilityCreateReturnData> =
    null;
  orderReturnDocument: AutoCompleteOptionItem<CheckPossibilityCreateReturnData> =
    null;
  addresses: Array<AutoCompleteOptionItem<CheckPossibilityCreateReturnData>> =
    [];
  addressesSubject = new BehaviorSubject<
    Array<AutoCompleteOptionItem<CheckPossibilityCreateReturnData>>
  >([]);
  addresses$ = this.addressesSubject.asObservable();

  get isCreateMode(): boolean {
    return this.mode === 'create';
  }

  private setMode(): void {
    this.mode = checkCanEdit(this.invoice.OrderingAdditionalServices, 'CargoReturn') ? 'edit' : 'create';

    if (this.isCreateMode) {
      this.form.get('SubtypeReason').enable();
    } else {
      this.form.get('SubtypeReason').disable();
    }
  }

  get recipientAddress(): UntypedFormGroup {
    return this.form?.get(
      ReturnRequestControlName.recipientAddress,
    ) as UntypedFormGroup;
  }

  constructor(
    private addressService: AddressService,
    private alertToastService: AlertToastService,
    private fb: UntypedFormBuilder,
    private contactService: ContactService,
    private invoiceService: InvoiceService,
    private backInvoicesService: BackInvoicesService,
    private dialogRef: MatDialogRef<BackInvoicesFormComponent>,
    private dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticService,
    private confirmService: ConfirmDialogModernService,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.checkReturnPossibility();
    this.subscribeToAddressChange();
    this.initFormValueChangesSubscription();
    this.setMode();
    this.getReasons();
    this.getSubReasons(this.defaultReason.id);
  }

  initFormValueChangesSubscription(): void {
    this.form.valueChanges
      .subscribe((value) => {
        value?.SubtypeReason && delete value.SubtypeReason;
        this.formValuesAfterCalculation?.SubtypeReason && delete this.formValuesAfterCalculation.SubtypeReason;

        const hasChanges = JSON.stringify(value) !== JSON.stringify(this.formValuesAfterCalculation);

        if (hasChanges) {
          this.backInvoicesServiceSubscription?.unsubscribe();
        }

        if (this.isCalculated && hasChanges) {
          this.canCreate = false;
        }
      });
  }

  cancel($event: Event): void {
    $event.preventDefault();

    const OrderRef = this.returnOrderRef;

    if (!OrderRef) {
      return;
    }

    const cancelConfig: ConfirmDialogModernConfig = {
      title: 'Ви впевнені, що бажаєте відмінити повернення?',
      text: 'Після відміни повернення можна створити повторно.<br><br>Якщо ви замовили й уже оплатили послугу "Повернення", кошти будуть повернені найближчим часом.',
      isHtml: true,
      confirmBtnText: 'Так, відмінити',
      cancelBtnText: 'Ні, залишити',
      isAsync: true,
      hasLoading: true,
      callback: () => this.handleCancelReturn(OrderRef),
    };

    this.confirmService.confirm(cancelConfig);
  }

  private handleCancelReturn(OrderRef: string): void {
    this.cancelPending = true;

    this.backInvoicesService
      .remove(OrderRef)
      .pipe(take(1), finalize(() => this.cancelPending = false))
      .subscribe((data) => {
          if (data) {
            this.confirmService.close();
            this.dialogRef.close({ success: true });
            this.alertToastService.pushSuccess('Заявку на видалення прийнято. Дані буде оновлено протягом декількох хвилин');

            Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>('analitics')
              .subject(`${componentName}.remove.success`).next({
              eventLabel: `Видалити заявку на повернення: ${this.returnInvoiceOrderNumber}`, eventCategory: componentName, eventAction: 'remove.success',
            });
          }
        },
        (errors) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }

          this.confirmService.close();

          Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>('analitics')
            .subject(`${componentName}.remove.error`).next({
            eventLabel: `Видалити заявку на повернення: ${errors[0] &&
            errors[0].messageCode}`, eventCategory: componentName, eventAction: 'remove.error',
          });
        });
  }

  get returnOrderRef(): string {
    return getOrderRef(this.invoice.OrderingAdditionalServices, 'CargoReturn') ?? '';
  }

  get canCreate(): boolean {
    return this._canCreate;
  }

  set canCreate(value: boolean) {
    this._canCreate = value;
  }

  get submitDisabled(): boolean {
    return this.loading || this.cancelPending;
  }

  get formLabel(): string {
    return this.mode === 'create' ? 'Заявка на повернення' : 'Редагування повернення ' + (this.returnInvoiceOrderNumber ?? '');
  }

  onChangeSubReason(): void {
    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.onChangeSubReason`,
      {
        eventLabel: `Підпричина повернення`,
        eventCategory: `${componentName}`,
        eventAction: 'select',
      },
    );
  }

  onOpenCloseInvoiceInfo(event): void {
    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.onOpenCloseInvoiceInfo`,
      {
        eventLabel: `Наклада заявки, перегляд детальної інформації`,
        eventCategory: `${componentName}`,
        eventAction: event.open ? 'open' : 'close',
      },
    );
  }

  scrollToBottom(): void {
    const modalContent = this.modalContent.nativeElement;
    modalContent.scrollTop = modalContent.scrollHeight;
  }

  private handleCalculateResponse(data): void {
    this.Pricing = checkPricingValue(data.Pricing?.Total);
    this.ScheduledDeliveryDate = data.ScheduledDeliveryDate ? moment(data.ScheduledDeliveryDate, 'YYYY-MM-DD HH:mm:ss').toDate() : null;

    this.canCreate = true;
    this.isCalculated = true;
    setTimeout(() => this.scrollToBottom());
  }

  calculate(event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.setLoading(true);
    const value = this.form.getRawValue();
    this.formValuesAfterCalculation = this.form.value;
    const OrderRef = this.returnOrderRef;

    const address = {
      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.WAREHOUSE
        ? {
          RecipientWarehouse: value.recipientAddress.data.warehouse.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.INDEX
        ? {
          RecipientWarehouse: value.recipientAddress.data.warehouse.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.POSTBOX
        ? {
          RecipientWarehouse: value.recipientAddress.data.postbox.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.DOORS
        ? {
          RecipientSettlement: value.recipientAddress.data.city.Ref,
          RecipientSettlementStreet:
          value.recipientAddress.data.street.SettlementStreetRef,
          BuildingNumber: value.recipientAddress.data.building,
          NoteAddressRecipient: value.recipientAddress.data.note,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.SELECT
        ? { ReturnAddressRef: value.recipientAddress.data.Ref }
        : {}),
    };

    this.backInvoicesServiceSubscription?.unsubscribe();

    if (this.isCreateMode) {
      const parameters = {
        PaymentMethod: value.PaymentMethod,
        Reason: value.Reason.id,
        SubtypeReason: value.SubtypeReason.id,
      };

      this.backInvoicesServiceSubscription = this.backInvoicesService
        .getPricing(this.invoice, { ...parameters, ...address }, OrderRef)
        .pipe(finalize(() => this.setLoading(false)))
        .subscribe(
          (data) => this.handleCalculateResponse(data),
          (errors) => {
            this.alertToastService.pushApiErrors(errors);
          },
        );
    } else {
      const parameters = {
        PaymentMethod: value.PaymentMethod,
      };

      this.backInvoicesServiceSubscription = this.backInvoicesService.getPricingForUpdate(this.invoice, { ...parameters, ...address }, OrderRef)
        .pipe(finalize(() => this.setLoading(false)))
        .subscribe(
          (data) => this.handleCalculateResponse(data),
          (errors) => {
            if (errors[0]) {
              this.alertToastService.pushApiErrors(errors);
            }
          },
        );
    }
  }

  submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.form.markAllAsTouched();

      this.googleAnalyticsService.track(
        'analitics',
        `${componentName}.submit.invalid`,
        {
          eventLabel: `Повернення. Не валідна форма`,
          eventCategory: `${componentName}`,
          eventAction: 'submit.invalid',
        },
      );
      return;
    }

    this.setLoading(true);

    const value = this.form.getRawValue();
    const OrderRef = this.returnOrderRef;
    const address = this.mapPayload(value);

    const request = this.isCreateMode
      ? this.backInvoicesService.create(this.invoice, {
        PaymentMethod: value.PaymentMethod,
        Reason: value.Reason.id,
        SubtypeReason: value.SubtypeReason.id,
        ...address,
      })
      : this.backInvoicesService.update(this.invoice, {
        PaymentMethod: value.PaymentMethod,
        ...address,
      }, OrderRef);

    request
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe(
        (data) => {
          this.dialogRef.close({
            isCreated: this.isCreateMode,
            success: true,
            Number: data.Number,
            MessageText: data.MessageText,
            Pricing: checkPricingValue(data.Pricing?.Total),
            ScheduledDeliveryDate: data.ScheduledDeliveryDate ? moment(data.ScheduledDeliveryDate, 'YYYY-MM-DD HH:mm:ss').toDate() : null,
          });

          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.submit.success`,
            {
              eventLabel: `Повернення. Успіх`,
              eventCategory: `${componentName}`,
              eventAction: 'submit.success',
            },
          );
        },
        (errors) => {
          this.alertToastService.pushApiErrors(errors);
          this.googleAnalyticsService.track(
            'analitics',
            `${componentName}.submit.error`,
            {
              eventLabel: `Повернення. Помилка`,
              eventCategory: `${componentName}`,
              eventAction: 'submit.error',
            },
          );
        },
      );
  }

  private mapPayload(value: any): any {
    return {
      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.WAREHOUSE
        ? {
          RecipientWarehouse: value.recipientAddress.data.warehouse.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.INDEX
        ? {
          RecipientWarehouse: value.recipientAddress.data.warehouse.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.POSTBOX
        ? {
          RecipientWarehouse: value.recipientAddress.data.postbox.Ref,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.CREATE &&
      value.recipientAddress.type === AddressTypes.DOORS
        ? {
          RecipientSettlement: value.recipientAddress.data.city.Ref,
          RecipientSettlementStreet:
          value.recipientAddress.data.street.SettlementStreetRef,
          BuildingNumber: value.recipientAddress.data.building,
          NoteAddressRecipient: value.recipientAddress.data.note,
        }
        : {}),

      ...(value.recipientAddress.mode === AutocompleteMode.SELECT
        ? { ReturnAddressRef: value.recipientAddress.data.Ref }
        : {}),
    };
  }

  trackBy(item: Model): string {
    return item.$uid;
  }

  setLoading(value: boolean): void {
    this.loading = value;
  }

  private checkReturnPossibility(): void {
    const OrderRef = getOrderRef(this.invoice.OrderingAdditionalServices, 'CargoReturn');

    this.setLoading(true);
    this.backInvoicesService
      .checkIfPossibleCreate(this.invoice.docNumber, OrderRef)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe((response) => {
          if (!response) {
            return;
          }
          const mappedAddresses = this.mapAddressesToOptions(response.data);
          this.setAddresses(mappedAddresses);
          this.returnInvoiceOrderNumber = response.info.Number ?? '';
        },
        (e) =>
          this.handleReturnAddressesError(e),
      );
  }

  private createForm(): void {
    this.form = this.fb.group({
      [ReturnRequestControlName.SubtypeReason]: [null, Validators.required],
      [ReturnRequestControlName.Reason]: [
        this.defaultReason,
        Validators.required,
      ],
      [ReturnRequestControlName.PaymentMethod]: ['Cash', Validators.required],
      [ReturnRequestControlName.recipientContact]: null,
      [ReturnRequestControlName.recipientCounterparty]: null,
    });
  }

  subscribeToAddressChange(): void {
    this.addresses$
      .pipe(
        filter((a) => !!a.length),
        switchMap(() => this.form.valueChanges),
        filter(() =>
          this.form.contains(ReturnRequestControlName.recipientAddress),
        ),
        distinctUntilChanged(),
        switchMap(() => this.recipientAddress.valueChanges),
        distinctUntilChanged(),
      )
      .subscribe(
        (recipientAddress: {
          data: CheckPossibilityCreateReturnData;
          mode: AutocompleteMode;
          type: AddressType;
        }) => {
          const source: CheckPossibilityCreateReturnData =
            recipientAddress.mode === AutocompleteMode.CREATE &&
            (this.expressWaybillDocument || this.orderReturnDocument)
              ? this.orderReturnDocument
                ? this.orderReturnDocument.option
                : this.expressWaybillDocument.option
              : this.recipientAddress?.getRawValue()?.data;

          this.form.patchValue({
            [ReturnRequestControlName.recipientContact]: source?.ContactPerson,
            [ReturnRequestControlName.recipientCounterparty]:
            source?.Counterparty,
            [ReturnRequestControlName.PaymentMethod]: source?.NonCash
              ? 'NonCash'
              : 'Cash',
          });

          this.recipientCounterpartyHidden =
            source?.Counterparty === CounterpartyName.PrivatePerson;

          if (this.isCreateMode) {
            this.form
              .get(ReturnRequestControlName.PaymentMethod)
              [!source?.NonCash ? 'disable' : 'enable']();
          }
        },
      );
  }

  setAddresses(
    addresses: Array<AutoCompleteOptionItem<CheckPossibilityCreateReturnData>>,
  ): void {
    this.addresses = addresses;
    this.addressesSubject.next(addresses);
    this.expressWaybillDocument = addresses.find(
      (a) => a.option.Type === ReturnDocumentType.ExpressWaybill,
    );
    this.orderReturnDocument = addresses.find(
      (a) => a.option.Type === ReturnDocumentType.OrderReturn,
    );
  }

  private getReasons(): void {
    this.returnReasons = [this.defaultReason];
  }

  private getSubReasons(idReasons: string): void {
    if (this.isCreateMode) {
      this.backInvoicesService
        .getSubReasons(idReasons)
        .subscribe((reasons) => (this.returnSubReasons = reasons));
    }
  }

  private logRxmq(error: Error): void {
    this.googleAnalyticsService.track(
      'analitics',
      `${componentName}.checkReturnPosibilty`,
      {
        eventLabel: `Повернення. Помилка: ${error[0] && error[0].message}`,
        eventCategory: `${componentName}`,
        eventAction: 'select',
      },
    );
  }

  mapAddressesToOptions(
    addresses: CheckPossibilityCreateReturnData[],
  ): Array<AutoCompleteOptionItem<CheckPossibilityCreateReturnData>> {
    return addresses.map((address) => ({ option: address }));
  }

  private handleReturnAddressesError(error: Error): void {
    this.dialogRef.close();
    this.alertToastService.pushApiError(error[0]);
    this.logRxmq(error);
  }
}

import { Features } from '@services';
import { Contact } from './contact.model';
import { Member } from './member.model';

/**
 * Created by andrey on 22.07.16.
 */
export interface CorporateContact {
  ApiKey: string;
  ContactSender: string;
  CounterpartyRef: string;
  Description: string;
  LoyaltyCardNumber: string;
  LoyaltyCardSubtype: string;
  LoyaltyCardType: string;
  UserLogin: string;
  CounterpartyCode: string;
}

export class User extends Contact {
  authToken: string;
  apiKey: string;
  loyaltyCardNumber: string;
  discount: number;
  loginEmail: string;
  loyaltyCardType: string;
  loyaltyCardSubtype: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullNameCounterparty: string;
  type: 'private' | 'org';
  contacts?: CorporateContact[] & Member[];
  authHash: string;
  hasLoyaltyCard: boolean;
  CheckQuestion: boolean;
  UseOAuth2: boolean;
  Features: Features;
}

export interface UserAnalyticsData {
  account_type: string;
  user_type: string;
  user_id: string;
  page_title: string;
}

import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { WarehouseSelectComponent } from '../warehouse-select.component';
import {
  PostboxClasses,
  PostboxControlName,
  PostboxDebounceTime,
  PostboxLabel,
  PostboxMaxLength,
  PostboxMinLength,
  PostboxPlaceholder,
  RequestLimit,
} from './postbox-select.constants';
import { PostboxErrors } from 'app/shared/errors/error-messages';
import { getWarehouseDescription } from '../warehouse-select.utils';
import { CategoryOfWarehouseEnum, WarehouseResult } from '@models';
import { AddressService } from '@services';

@Component({
  selector: 'app-postbox-select',
  templateUrl: '../warehouse-select.component.html',
  styleUrls: ['../warehouse-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostboxSelectComponent),
      multi: true,
    },
  ],
})
export class PostboxSelectComponent extends WarehouseSelectComponent {
  WarehouseErrors = PostboxErrors;

  @Input() placeholder: string = PostboxPlaceholder;

  @Input() label: string = PostboxLabel;

  @Input() testId: string = PostboxControlName;

  @Input() minLength: number = PostboxMinLength;

  @Input() maxLength: number = PostboxMaxLength;

  @Input() requestLimit: number = RequestLimit;

  @Input() debounceTime: number = PostboxDebounceTime;
  @Input() forSender: boolean = false;

  @Input() getWarehouseDescription = getWarehouseDescription;

  @Input()
  @HostBinding('class')
  classList = PostboxClasses;

  constructor(
    protected addressService: AddressService,
    protected element: ElementRef,
  ) {
    super(addressService, element);
  }

  getWarehouses(cityRef: string): Observable<WarehouseResult[]> {
    return this.addressService.getWarehouses(
      cityRef,
      null,
      undefined,
      this.forSender,
      CategoryOfWarehouseEnum.Postomat,
    );
  }
}

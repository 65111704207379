import { GetCountriesData } from '@shared';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';

// USA
export const US: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
  State: {
    getMethodName: 'getUSStates',
    config: {
      ariaLabel: 'Штат',
      placeholder: 'Штат',
      arrow: true,
    },
    errorSnackMessages: {
      empty: 'Не заповнений штат отримувача',
      fromList: 'Оберіть штат зі списку',
    }
  },
};

<div class="header">
  <h3 class="header-title">
    Зверніть увагу
  </h3>
  <button class="close-btn"
          mat-button
          mat-icon-button
          (click)="close()">
    <mat-icon class="material-icons_grey">close</mat-icon>
  </button>
</div>

<div class="body">
  <div class="body-wrapper">
    <div class="banner">
      <div class="banner-title">
        <mat-icon *ngIf="config.banner.icon">{{ config.banner.icon }}</mat-icon>
        {{ config.banner.title }} {{ titleLabel }}
      </div>
      <div class="banner-container">
        <div class="banner-label" *ngIf="config.banner.successLabel">
          {{ config.banner.successLabel }} <span class="banner-value">{{ succeededAmount }}
          з {{ totalAmount }}</span>
        </div>
        <div class="banner-label" *ngIf="config.banner.failedLabel">
          {{ config.banner.failedLabel }} <span class="banner-value">{{ failedAmount }}
          з {{ totalAmount }}</span>
        </div>
      </div>
    </div>
    <div class="caption">
      {{ config.caption }}
    </div>
  </div>

  <div class="options">
    <div class="options-header">
      <div class="options-header-identifier">
        {{ config.options.identifierLabel }}
      </div>
      <div class="options-header-reason">
        {{ config.options.reasonLabel }}
      </div>
    </div>
    <div class="option" *ngFor="let option of failedOptions">
      <div class="identifier">
        <ng-container [ngSwitch]="config.dataType">
          <ng-container *ngSwitchCase="PartialSuccessDataType.Invoice">
            <ng-container
              *ngTemplateOutlet="invoice; context:{ option: option }">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="reason">
        <ng-container *ngIf="option.errorMessage; else dash">
          {{ option.errorMessage }}
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button mat-button (click)="close()" color="primary">
    зрозуміло
  </button>
</div>

<ng-template #invoice let-option="option">
    <span class="nowrap doc-number copy-content" *ngIf="option.number; else dash">
      <span [innerHTML]="option.number | fineDocNumber" class="fine-doc-number"></span>
      <button
        [appCopy]="option.number"
        [matTooltip]="'Скопіювати'"
        class="copy-content__btn"
        mat-icon-button
        matTooltipPosition="below">
        <mat-icon class="material-icons_grey copy-content__icon">content_copy</mat-icon>
      </button>
    </span>
</ng-template>

<ng-template #dash>—</ng-template>

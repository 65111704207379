import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractFormContainer, getError } from '@shared';
import { errors, label, maxLength } from './delivery-barcode.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-barcode',
  templateUrl: './delivery-barcode.component.html',
  styleUrls: ['./delivery-barcode.component.scss'],
})
export class DeliveryBarcodeComponent
  extends AbstractFormContainer
  implements OnInit
{
  getError = getError;

  errors = errors;

  @Input() controlName = 'InfoRegClientBarcodes';

  label = label;

  maxLength = maxLength;

  constructor() {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    this.form.addControl(
      this.controlName,
      new UntypedFormControl('', [Validators.maxLength(maxLength)]),
    );
  }
}

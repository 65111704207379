import {Component, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MessengersPopupInstructionComponent} from './messengers-popup-instruction/messengers-popup-instruction.component';
import {merge} from 'rxjs';
import {filter} from 'rxjs';
import {Unsubscriber} from '../../classes/unsubscriber';


@Component({
  selector: 'app-messengers-popup',
  templateUrl: './messengers-popup.component.html',
  styleUrls: ['./messengers-popup.component.scss']
})
export class MessengersPopupComponent extends Unsubscriber implements OnDestroy {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<MessengersPopupComponent>
  ) {
    super();
  }


  openInstruction(): void {
    const dialogRef = this.dialog.open(MessengersPopupInstructionComponent, {
      panelClass: 'no-padding',
      autoFocus: false,
      restoreFocus: false,
      width: '370px',
      position: {
        bottom: '56px',
        right: '110px'
      },
    });
    const backdrop$ = dialogRef.backdropClick();
    const link$ = dialogRef.afterClosed().pipe(filter((v) => v === 'link'));
    this.subscriptions = merge(backdrop$, link$).subscribe(() => {
      this.dialog.closeAll();
    });
  }
}


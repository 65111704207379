import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AbstractFormContainer } from '@shared';

@Component({
  selector: 'app-delivery-cargo',
  templateUrl: './delivery-cargo.component.html',
  styleUrls: ['./delivery-cargo.component.scss'],
})
export class DeliveryCargoComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  @Input() controlName = 'paramsCommonSeats';

  @Input() invoiceId: string;

  @Input() isTemplate: boolean;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    const paramsCommonSeatsControl = new UntypedFormControl(false);

    if (this.form) {
      this.form.addControl(this.controlName, paramsCommonSeatsControl);
    }
  }

  ngOnDestroy() {
    this.form.removeControl(this.controlName);
  }
}

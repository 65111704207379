<div class="form-row w100">
    <mat-form-field  class="form-input">
        <mat-label>{{label}}</mat-label>
        <input [formControl]="warehouse"
               [matAutocomplete]="auto"
               [maxLength]="maxLength"
               #input
               type="text"
               class="input"
               aria-label="Warehouse"
               matInput
               autocomplete="off"
               autoScrollTarget
               [keyInputPrevent]="true"
        >
        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="warehouseTransformFn"
                          (optionSelected)="blur()">
            <mat-option *ngFor="let option of warehouseOptions" [value]="option.option">
                {{ option.option.Description }}
            </mat-option>
        </mat-autocomplete>
        <mat-error>{{ getError(warehouse, errors) }}</mat-error>
    </mat-form-field>
</div>

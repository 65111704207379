import { Injectable } from '@angular/core';
import { combineLatest ,  ReplaySubject } from 'rxjs';
import { map } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerEl;
  private status: ReplaySubject<boolean> = new ReplaySubject(1);
  private _active = false;

  constructor(private authService: AuthService) {
    this.spinnerEl = window.document.getElementById('spinner');

    combineLatest([this.status$, this.authService.sessionAuth$])
      .pipe(map(([isLoading, isSessionAuthSet]) => isLoading && !isSessionAuthSet))
      .subscribe(isLoading => {
        if (this.spinnerEl) {
          if (isLoading) {
            this.spinnerEl.classList.add('show');
          } else {
            this.spinnerEl.classList.remove('show');
          }
        }
      });
  }

  public get status$() {
    return this.status.asObservable();
  }

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public start(): void {
    this.active = true;
  }

  public stop(): void {
    this.active = false;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertToastService } from '@services';
import { FormContainer } from '@shared';
import * as moment from 'moment';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { InvoiceApiService } from '../../../../../../../services/invoices/invoice-api.service';
import { TimeInterval } from '../../../../time-intervals/time-intervals.model';

@Component({
  selector: 'app-local-express-time-intervals',
  templateUrl: './local-express-time-intervals.component.html',
  styleUrls: ['./local-express-time-intervals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalExpressTimeIntervalsComponent extends FormContainer implements OnDestroy {
  hiddenIntervals: string[] = ['CityDeliveryTimeInterval3', 'CityDeliveryTimeInterval6'];
  timeIntervals: TimeInterval[] = [];
  error = false;
  pending$ = new BehaviorSubject(false);
  initialInterval: string = null;

  constructor(
    private invoiceService: InvoiceApiService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private alertToastService: AlertToastService,
  ) {
    super();
  }

  get control(): AbstractControl {
    return this.form?.get('TimeInterval');
  }

  private _dateTime: string = moment().format('DD.MM.YYYY');

  get dateTime(): string {
    return this._dateTime;
  }

  @Input()
  set dateTime(date: string) {
    if (date === this.dateTime) {
      return;
    }
    this._dateTime = date;
    this.getTimeIntervals(date, this.recipientCityRef, date !== this.dateTime);
  }

  private _recipientCityRef: string = '';

  get recipientCityRef(): string {
    return this._recipientCityRef;
  }

  @Input()
  set recipientCityRef(ref: string) {
    this._recipientCityRef = ref;
    this.getTimeIntervals(this.dateTime, ref);
  }

  getTimeIntervals(dateTime, recipientCityRef, shouldResetInterval = true): void {
    if (!dateTime || !recipientCityRef) {
      return;
    }
    this.pending$.next(true);
    this.invoiceService
      .getTimeIntervals(dateTime, recipientCityRef)
      .pipe(
        take(1),
        finalize(() => this.pending$.next(false)),
      )
      .subscribe(
        (data) => {
          this.error = false;
          this.timeIntervals = data
            .filter((i) => (this.hiddenIntervals.length ? i.Number && this.hiddenIntervals.includes(i.Number) : true))
            .filter((i) => i.Start || i.End);
          // Reset interval after city or date change
          if (shouldResetInterval) {
            this.changeInterval({ value: null });
          }

          // If interval which is substituted is not found in the list
          // reset it
          if (!this.timeIntervals.find((i) => i.Number === this.control?.value) && this.control?.value !== 1) {
            this.changeInterval({ value: null });
            this.control?.setValue(null);
          }
          this.cd.detectChanges();
        },
        (errors) => {
          this.error = true;
          this.timeIntervals = [];
          this.changeInterval({ value: null });
          this.control?.setValue(null);
          this.cd.detectChanges();
          this.alertToastService.pushApiError(errors[0]);
        },
      );
  }

  changeInterval(event): void {
    this.initialInterval = event.value;
  }

  addFormControls(): void {
    this.form?.addControl('TimeInterval', this.fb.control(null, [Validators.required]));
  }

  ngOnDestroy(): void {
    this.form?.removeControl('TimeInterval');
  }
}

<mat-form-field class="mat-block mat-block_with_symbol " data-symbol="грн" [formGroup]="form">
          <input matInput npNumber
                 [formControlName]="controlName"
                 placeholder="Оголошена вартість"
                 NumberInput="true"
                 [maxValue]="1000000"
                 [allowFloat]="true"
                 (change)="change()"
                 [digitsAfterDot]="2">
</mat-form-field>

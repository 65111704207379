import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MasterpassCard, PaymentCostsInfo } from '@models';
import { AlertToastService, AuthService, GoogleAnalyticService, WINDOW } from '@services';
import { CardTypes, handleFirstDayStorage, handleReturnCargoDate } from '@shared';
import { finalize, switchMap } from 'rxjs';
import { Invoice } from '../../../../../models/invoice/invoice.model';
import { InvoiceApiService } from '../../../../../services/invoices/invoice-api.service';
import { InvoiceService } from '../../../../../services/invoices/invoice.service';
import { MasterpassService } from '../../../../../services/masterpass.service';
import { AcquiringService } from '../../services/acquiring.service';

const componentName = 'app-masterpass-payment';

@Component({
  selector: 'app-masterpass-payment',
  templateUrl: './masterpass-payment.component.html',
  styleUrls: ['./masterpass-payment.component.scss'],
})
export class MasterpassPaymentComponent implements OnInit {
  cards: MasterpassCard[] = [];

  isLoading = false;

  invoice: Invoice;

  newInvoice: Invoice;

  @Input()
  type: string;

  @Input()
  inputInvoice: Invoice;

  @Input()
  recipientCounterpartyType: string;

  @Input()
  enableManualPay = true;

  @Input()
  paymentCostsInfo: PaymentCostsInfo;

  formattedInvoiceNumber: string;

  isPending = false;

  selectedServices = {
    Services: false,
    MoneyTransfer: false,
    AfterPayment: false,
    InternationalDelivery: false,
    Redirecting: false,
    Return: false,
  };

  selectedCard: MasterpassCard;

  costSum = 0;

  isPaid = false;

  user = null;

  sid: string;

  paymentPopupOpened = false;

  public payerNameTransations = {
    Sender: 'Відправник',
    Recipient: 'Отримувач',
    ThirdPerson: 'Третя особа',
  };

  paymentSuccess = false;
  paymentError = false;
  bonusPayment: UntypedFormControl;
  bonusPaymentAmount = null;
  bonusPaymentHidden = false;
  promocodePaymentHidden = false;

  promocodeSuccessUse = false;
  promocodeErrorMessage;

  isNewPaymentV3 = true;

  form: UntypedFormGroup;

  promocodeLabel = 'Промокод';

  constructor(
    private alertToastService: AlertToastService,
    public cd: ChangeDetectorRef,
    private masterpassService: MasterpassService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MasterpassPaymentComponent>,
    private invoiceService: InvoiceService,
    private invoicesService: InvoiceApiService,
    private acquiringService: AcquiringService,
    private googleAnalyticsService: GoogleAnalyticService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    @Inject(WINDOW) private window: Window
  ) {}

  get promocode(): AbstractControl {
    return this.form?.get('Promocode');
  }

  ngOnInit() {
    this.preparePayment(this.paymentCostsInfo);
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({});
  }

  preparePayment(paymentCostsInfo: PaymentCostsInfo) {
    this.isLoading = true;
    this.user = this.authService.user;
    if (this.user.loyaltyCardType !== CardTypes.IDENTIFICATION) {
      this.authService.getBonuses();
    }
    this.checkSelectedServices();
    this.setCostSum();
    this.getInvoice();
    this.initBonusInput();
    this.isNewPaymentV3 = true;
    this.isLoading = false;
  }

  initBonusInput() {
    const amount =
      this.paymentCostsInfo.Services || this.inputInvoice.CostOnSite || this.inputInvoice.SumBeforeCheckWeight;
    const max = amount > this.user.discount ? this.user.discount : amount;
    if (this.user && this.user.discount) {
      this.bonusPayment = new UntypedFormControl(max, [
        Validators.min(0.01),
        Validators.max(max),
      ]);
    }
  }

  get userBonusesAmount(): string {
    return (this.user.discount - this.bonusPaymentAmount).toFixed(2);
  }

  payWithBonuses(amount: string | number) {
    if (!amount) {
      return;
    }
    this.isLoading = true;
    this.masterpassService
      .payWithBonuses(this.inputInvoice.docNumber, amount)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (debitingAmount) => {
          this.bonusPaymentAmount = debitingAmount;
          this.costSum -= Number(debitingAmount);
          this.paymentCostsInfo.Services -= Number(debitingAmount);
          this.paymentCostsInfo.Services = Number(this.paymentCostsInfo.Services.toFixed(2));
          this.masterpassService.saveToEcommerce(
            debitingAmount.toString(),
            'NPBonus',
            this.inputInvoice,
            this.selectedServices,
            this.type,
            this.paymentCostsInfo,
          );
          this.masterpassService.getPaymentInfo(this.invoice.docNumber, this.invoice.SendersPhone).subscribe((data) => {
            this.paymentCostsInfo = data;
            this.preparePayment(this.paymentCostsInfo);
          });
          this.googleAnalyticsService.track('analitics', `${componentName}.payWithBonuses`, {
            eventLabel: `Оплата бонусами`,
            eventCategory: `${componentName}`,
            eventAction: 'pay.success',
          });
        },
        (errors) => {
          this.alertToastService.pushApiErrors(errors);

          this.googleAnalyticsService.track('analitics', `${componentName}.payWithBonuses`, {
            eventLabel: `Оплата бонусами: помилка ${errors[0] && errors[0].errorCode}`,
            eventCategory: `${componentName}`,
            eventAction: 'pay.error',
          });
        },
      );
  }

  payWithPromocode(): void {
    let promocode: string;

    if (!this.promocode.value) {
      return;
    }

    if (typeof this.promocode.value === 'object') {
      promocode = this.promocode.value.Promocode;
    }

    if (typeof this.promocode.value === 'string') {
      promocode = this.promocode.value;
    }

    this.isLoading = true;
    this.promocodeSuccessUse = null;
    this.promocodeErrorMessage = null;
    this.masterpassService
      .payWithPromocode(this.inputInvoice.docNumber, promocode)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.promocodeSuccessUse = true;
          this.masterpassService.getPaymentInfo(this.invoice.docNumber, this.invoice.SendersPhone).subscribe((data) => {
            this.paymentCostsInfo = data;
            this.alertToastService.pushSuccess('Промокод успішно використано!');
            if (this.checkIfPaid(this.paymentCostsInfo)) {
              this.dialogRef.close('SuccessPayment');
            }
            this.preparePayment(this.paymentCostsInfo);
          });
          this.googleAnalyticsService.track('analitics', `${componentName}.payWithPromocode`, {
            eventLabel: `Оплата промокодом`,
            eventCategory: `${componentName}`,
            eventAction: 'pay.success',
          });
        },
        (errors) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.googleAnalyticsService.track('analitics', `${componentName}.payWithPromocode`, {
            eventLabel: `Оплата промокодом: помилка ${errors[0]?.addInfo}`,
            eventCategory: `${componentName}`,
            eventAction: 'pay.error',
          });
        },
      );
  }

  checkSelectedServices() {
    this.selectedServices.Services = this.paymentCostsInfo.Services > 0;
    this.selectedServices.MoneyTransfer = this.paymentCostsInfo.MoneyTransfer > 0;
    this.selectedServices.AfterPayment = this.paymentCostsInfo.AfterPayment > 0;
    this.selectedServices.InternationalDelivery = this.paymentCostsInfo.InternationalDelivery > 0;
    this.selectedServices.Redirecting = this.paymentCostsInfo.Redirecting > 0;
    this.selectedServices.Return = this.paymentCostsInfo.Return > 0;
  }

  checkIfPaid(paymentCostsInfo: PaymentCostsInfo) {
    if (!paymentCostsInfo) {
      return true;
    }
    if (
      paymentCostsInfo.Services > 0 ||
      paymentCostsInfo.AfterPayment > 0 ||
      paymentCostsInfo.MoneyTransfer > 0 ||
      paymentCostsInfo.InternationalDelivery > 0 ||
      paymentCostsInfo.Commission > 0 ||
      paymentCostsInfo.Return > 0 ||
      paymentCostsInfo.Redirecting > 0
    ) {
      return false;
    }
    return true;
  }

  setCostSum() {
    let sum = Object.keys(this.selectedServices).reduce((acc, service) => {
      return this.selectedServices[service] ? acc + this.paymentCostsInfo[service] : acc;
    }, 0);
    if (this.selectedServices.MoneyTransfer) {
      sum += this.paymentCostsInfo.Commission;
    }
    this.costSum = sum;
  }

  handleInvoiceMutations(invoice: Invoice): void {
    this.invoice = handleFirstDayStorage(invoice);
    this.invoice = handleReturnCargoDate(this.invoice);
  }

  onPaste(event: ClipboardEvent) : void {
    event.preventDefault();

    let clipboardData = event.clipboardData || (this.window as any).clipboardData;
    let value = clipboardData.getData('text').toString().toUpperCase();

    value = value.replace(',','.');

    const roundedValue = parseFloat(value).toFixed(2);
    if (roundedValue === 'NaN') {
      return;
    }

    this.bonusPayment.setValue(roundedValue, { emitEvent: true });
  }

  initPayment() {
    this.paymentPopupOpened = true;
    this.masterpassService
      .initPayment(this.inputInvoice, this.paymentCostsInfo, this.selectedServices, this.selectedCard, this.type)
      .pipe(
        switchMap((data) => {
          const url = `${data.Url}&lang=ua`;
          return this.masterpassService.processPaymentV3(
            url,
            this.inputInvoice,
            this.selectedServices,
            this.type,
            this.paymentCostsInfo,
          );
        }),
        switchMap((data) => this.masterpassService.getPaymentInfo(this.invoice.docNumber, this.invoice.SendersPhone)),
      )
      .subscribe(
        (data) => {
          this.paymentCostsInfo = data;
          this.paymentPopupOpened = false;
          this.preparePayment(this.paymentCostsInfo);
          if (this.checkIfPaid(this.paymentCostsInfo)) {
            this.dialogRef.close('SuccessPayment');
          }
        },
        (errors) => {
          this.paymentPopupOpened = false;
          if (errors) {
            this.alertToastService.pushApiErrors(errors);
          }
        },
      );
  }

  private getInvoice() {
    this.formattedInvoiceNumber = this.invoiceService.makeFormatedInvoiceNumber(this.inputInvoice.docNumber);
    this.handleInvoiceMutations(this.inputInvoice);
  }
}

import { Address, Contact, Model } from '@models';
import { OrderState } from '../components/dashboard/business-services/models/order-state';
import { Pricing } from '../components/dashboard/business-services/models/pricing';
import { InvoicePaymentMethod } from './invoice/invoice-payment-method.model';
import { InvoiceState } from './invoice/invoice-state.model';

export class RedirectInvoice extends Model {
  Number: string;
  state: OrderState;
  createDateTime: Date;
  docNumber: string;
  description: string;
  recipientAddress: Address;
  recipient: Contact;
  payer: 'Recipient' | 'Sender' | 'ThirdPerson';
  customer: 'Recipient' | 'Sender';
  paymentMethod: InvoicePaymentMethod;
  deliveryCost: number;
  estimatedDeliveryDate: Date;
  newDocNumber: string;
  newDocState: InvoiceState;
  addressType?: string;
  noteAddressRecipient: string;
  CityRecipient: string;
  CounterpartyRecipient: string;
  DateTime: string;
  DeliveryCost: number;
  DocumentNumber: string;
  EstimatedDeliveryDate: string;
  ExpressWaybillNumber: string;
  ExpressWaybillStatus: string;
  Note: string;
  OrderNumber: string;
  OrderRef: string;
  OrderStatus: string;
  PayerType: string;
  PaymentMethod: string;
  PhoneRecipient: string;
  RecipientAddress: string;
  RecipientName: string;
  CanDeleteOrder: boolean;
  ScheduledDeliveryDate: string;
  Pricing: Pricing;
  MessageText: string;
}

export interface RedirectInvoiceResponse {
  data: Array<RedirectInvoiceData>;
  info: { Customer: string };
}

export interface RedirectInvoiceData {
  CityRecipient: string;
  CityRecipientDescription: string;
  CounterpartyRecipientDescription: string;
  CounterpartyRecipientRef: string;
  DocumentType: string;
  DocumentWeight: string;
  EdrpouRecipient: string;
  InternationalDeliveryType: string;
  Number: string;
  PayerType: string;
  PaymentMethod: string;
  PhoneRecipient: string;
  PhoneSender: string;
  RecipientName: string;
  RecommendationWarehouses: Array<RecommendationWarehouses>;
  Ref: string;
  ServiceType: string;
  SettlementRecipient: string;
  SettlementRecipientDescription: string;
  SettlementType: string;
  WarehouseDescription: string;
  WarehouseRef: string;
  AddressDescription: string;
  BuildingNumber: string;
  NoteAddressRecipient: string;
  RecipientSettlementStreet: string;
}

export interface RecommendationWarehouses {
  WarehouseRef: string
  WarehouseDescription: string,
}

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DateAdapter, NativeDateAdapter} from '@angular/material/core';
import {AbstractFormContainer} from '@shared';
import * as moment from 'moment';
import {TimeIntervalsComponent} from '../../time-intervals/time-intervals.component';

@Component({
  selector: 'app-inv-preferred-date',
  templateUrl: './preferred-date.component.html',
  styleUrls: ['./preferred-date.component.scss'],
})
export class PreferredDateComponent extends AbstractFormContainer implements OnInit, OnDestroy {

  @Input() controlName = 'PreferredDeliveryDate';

  preferredDateGroup: UntypedFormGroup;

  dateValue;

  @Input()
  recipientCityRef: string;

  @Input()
  customMinDate;

  @ViewChild(TimeIntervalsComponent) timeIntervalsComponent: TimeIntervalsComponent;

  @Input()
  maxDate = moment()
    .add(7, 'days')
    .toDate();

  @Input()
  initialDate: Date;

  @Input()
  intervalMode: 'ID' | 'Pickup' = 'ID';

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('uk-UA');
  }

  get minDate() {
    const dateTime = this.form.get('AdditionalServices').get('dateTime') ?
      this.form.get('AdditionalServices').get('dateTime').value :
      null;
    if (dateTime && dateTime.DateTime && dateTime.DateTime !== '') {
      const date = moment(dateTime.DateTime, 'DD.MM.YYYY')
        .add(1, 'days')
        .toDate();
      if (date.getDay() === 0) {
        return moment(dateTime.DateTime, 'DD.MM.YYYY')
          .add(2, 'days')
          .toDate();
      }
      return date;
    }
    return moment()
      .add(1, 'days')
      .toDate();
  }

  dateFilter = (date: Date) => date.getDay() !== 0;

  ngOnInit() {
    super.ngOnInit();
    if (this.initialDate) {
      this.onDateChange(this.initialDate);
    }
  }

  addFormControls() {
    this.preferredDateGroup = (this.form.get('AdditionalServices.preferredDate') as UntypedFormGroup);
    this.preferredDateGroup.addControl(this.controlName, new UntypedFormControl(
      '',
      [
        Validators.required,
      ],
    ));
    this.preferredDateGroup.get(this.controlName).valueChanges
      .subscribe(date => {
        setTimeout(() => {
          if (!this.timeIntervalsComponent.pending) {
            this.timeIntervalsComponent
              .getTimeIntervals(date, this.recipientCityRef);
          }
          this.timeIntervalsComponent.initialInterval = this.preferredDateGroup.get('TimeInterval').value;
        });

      });
    this.preferredDateGroup.addControl('TimeInterval', new UntypedFormControl(''));

    if (this.preferredDateGroup.get(this.controlName).value !== '') {
      this.dateValue = moment(this.preferredDateGroup.get(this.controlName).value, 'DD.MM.YYYY').toDate();
    }
  }

  onDateChange($event) {
    const date = new Date($event);
    this.dateValue = date;
    this.preferredDateGroup.patchValue({
      [this.controlName]: moment(date).format('DD.MM.YYYY'),
    });
    if (this.timeIntervalsComponent) {
      this.timeIntervalsComponent.dateTime = moment(date).format('DD.MM.YYYY');
      this.timeIntervalsComponent
        .getTimeIntervals(moment(date).format('DD.MM.YYYY'), this.recipientCityRef);
    }
  }

  setTimeInterval(timeInterval: string) {
    this.preferredDateGroup.patchValue({
      TimeInterval: timeInterval,
    });
  }

  ngOnDestroy() {
    this.preferredDateGroup.removeControl(this.controlName);
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormFieldValidationService {
  firstCharacterUpperValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const firstChar = control.value[0];
    if (firstChar) {
      if (firstChar === firstChar.toUpperCase()) {
        return null;
      } else {
        return { firstCharNotUp: { value: control.value } };
      }
    } else {
      return null;
    }
  }

  hasOptionsValidator(optionsCount: number | null): ValidatorFn {
    return (): ValidationErrors | null => optionsCount ? null : { noOptions: true };
  }

  hasDoubleApostropheValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = /\`\`/.test(control.value);
    return forbidden ? { hasDoubleApostrophe: { value: control.value } } : null;
  }

  hasDoubleDotsValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = /\.\./.test(control.value);
    return forbidden ? { hasDoubleDots: { value: control.value } } : null;
  }

  hasDoubleSpacesValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = /\ \ /.test(control.value);
    return forbidden ? { hasDoubleSpaces: { value: control.value } } : null;
  }

  hasDoubleHyphenValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = /\-\-/.test(control.value);
    return forbidden ? { hasDoubleHyphen: { value: control.value } } : null;
  }

  isValidLastCharValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = !/[а-яїєіёґ.`]{1}$/.test(
      typeof control.value === 'object'
        ? control.value.Description
        : control.value,
    );
    return forbidden ? { hasInvalidLastChar: { value: control.value } } : null;
  }

  isValidFirstCharValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = !/^[^`ʼ'-.]{1}/.test(
      typeof control.value === 'object'
        ? control.value.Description
        : control.value,
    );
    return forbidden ? { hasInvalidFirstChar: { value: control.value } } : null;
  }

  isValidCharactersValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = !/^[а-яА-ЯїЇєЄіІёЁґҐ`ʼ'-. ]+$/.test(
      typeof control.value === 'object'
        ? control.value.Description
        : control.value,
    );
    return forbidden
      ? { hasInvalidCharacters: { value: control.value } }
      : null;
  }

  isValidEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    // tslint:disable-next-line:max-line-length
    const forbidden =
      !/^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9]){1,}$/.test(
        control.value,
      );
    return forbidden ? { invalidEmail: { value: control.value } } : null;
  }

  isValidPasswordValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    let forbidden = false;
    const messages = ['Пароль повинен містити щонайменше:'];
    const regexps = [
      {
        regexp: new RegExp('(?=[a-zA-Zа-яА-Я])'),
        message: 'одну літеру,',
      },
      {
        regexp: new RegExp('(?=.*[0-9])'),
        message: 'одну цифру,',
      },
      {
        regexp: new RegExp('(?=.{8,})'),
        message: '8 символів,',
      },
    ];
    for (const regexp of regexps) {
      if (!regexp.regexp.test(control.value)) {
        forbidden = true;
        messages.push(regexp.message);
      }
    }
    return forbidden
      ? { invalidPassword: { value: messages.join(' ').slice(0, -1) } }
      : null;
  }

  isPasswordMatch(controlToMatch: string): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => control.value !== control.parent?.controls[controlToMatch].value ? { notEqual: true } : null;
  }
}

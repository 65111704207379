import { DatePipe } from '@angular/common';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {
  datesEqual,
  DoubleDateTableFilter,
  RadioTableFilter,
  TableChip,
  TableFilter,
  TableFilterConfig,
} from '@shared';
import * as moment from 'moment/moment';

export const creationDateRangeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  if (!fg.get('creationDateFrom') || !fg.get('creationDateTo')) {
    return null;
  }
  const from = fg.get('creationDateFrom').value;
  const to = fg.get('creationDateTo').value;
  if (!from || !to) {
    fg.get('creationDateTo').setErrors(null);

    return null;
  }
  const valid = from && to && moment(from) <= moment(to);

  fg.get('creationDateFrom').markAsTouched();
  fg.get('creationDateTo').markAsTouched();

  if (!valid) {
    fg.get('creationDateTo').setErrors({ range: true });
  } else {
    fg.get('creationDateTo').setErrors(null);
  }

  return valid ? null : { range: true };
};

export const OrdersNumberOrderFilter: TableFilter = {
  title: 'Номер замовлення',
  controlName: 'numberOrder',
  type: 'input',
  testId: 'inputFilter',
  initialValue: '',
  apiFieldName: 'orderNumberList',
  validators: [Validators.minLength(1), Validators.maxLength(25)],
  errors: {
    maxlength: 'Максимальна довжина 25 символів',
    minlength: 'Введіть номер замовлення',
  },
  placeholder: 'Введіть номер замовлення',
  hotFilter: {
    quickButtonText: 'Номер замовлення',
    overlayTitle: 'Номер замовлення',
    overlayButtonText: 'Знайти у замовленнях',
  },
};

export const OrdersStatusFilter: RadioTableFilter = {
  title: 'Статус замовлення',
  type: 'radio',
  testId: 'orders-status-filter',
  errors: {},
  placeholder: '',
  controlName: 'status',
  initialValue: 'Всі',
  apiFieldName: 'orderStatusList',
  validators: [],
  options: [
    { value: 'Всі', label: 'Всі', testId: 'Всі' },
    { value: 'Новий', label: 'Новий', testId: 'Новий' },
    { value: 'Відібраний', label: 'Відібраний', testId: 'Відібраний' },
    { value: 'Запакований', label: 'Запакований', testId: 'Запакований' },
    { value: 'Відвантажений', label: 'Відвантажений', testId: 'Відвантажений' },
    { value: 'Скасований', label: 'Скасований', testId: 'Скасований' },
    { value: 'Заблокований', label: 'Заблокований', testId: 'Заблокований' },
    { value: 'До планування', label: 'До планування', testId: 'До планування' },
    { value: 'Спланований частково', label: 'Спланований частково', testId: 'Спланований частково' },
    { value: 'Спланований', label: 'Спланований', testId: 'Спланований' },
    { value: 'Готовий до відбору', label: 'Готовий до відбору', testId: 'Готовий до відбору' },
    { value: 'До відбору', label: 'До відбору', testId: 'До відбору' },
    { value: 'У відборі', label: 'У відборі', testId: 'У відборі' },
    { value: 'Проконтрольований', label: 'Проконтрольований', testId: 'Проконтрольований' },
    { value: 'Готовий до відвантаження', label: 'Готовий до відвантаження', testId: 'Готовий до відвантаження' },
  ],
  hotFilter: {
    quickButtonText: 'Статус замовлення',
    overlayTitle: 'Статус замовлення',
    overlayButtonText: 'Застосувати',
  },
};

export const OrdersCreationDateFilter: DoubleDateTableFilter = {
  title: 'Дата створення',
  type: 'double-date',
  testId: 'orders-creation-date-filter',
  errors: {
    range: 'Вказано некоректний діапазон',
  },
  placeholder: '',
  initialValue: '',
  controlName: 'creationDate',
  apiFieldName: 'startPeriod',
  validators: [creationDateRangeValidator],
  hotFilter: {
    quickButtonText: 'Дата створення',
    overlayTitle: 'Дата створення',
    overlayButtonText: 'Застосувати',
  },
  fromFilter: {
    title: 'Створили з',
    type: 'date',
    testId: 'orders-creation-date-filter-from',
    errors: {},
    placeholder: 'Не обрано',
    controlName: 'creationDateFrom',
    initialValue: null,
    apiFieldName: 'startPeriod',
    validators: [],
    minDate: moment().subtract(2, 'month').toDate(),
    maxDate: moment().toDate(),
  },
  toFilter: {
    title: 'по',
    type: 'date',
    testId: 'orders-creation-date-filter-to',
    errors: {
      range: 'Вказано некоректний діапазон',
    },
    placeholder: 'не обрано',
    controlName: 'creationDateTo',
    initialValue: null,
    apiFieldName: 'endPeriod',
    validators: [],
    minDate: moment().subtract(2, 'month').toDate(),
    maxDate: moment().toDate(),
  },
};

export const OrdersFilterConfig: TableFilterConfig = {
  title: 'Фільтри',
  storageKey: 'orders-filters',
  buttons: {
    clearFilters: {
      text: 'Очистити все',
      testId: 'clearFiltersBtn',
      autofocus: false,
      class: 'btn',
    },
    applyFilters: {
      text: 'Застосувати фільтри',
      testId: 'applyFiltersBtn',
      color: 'primary' as ThemePalette,
    },
  },
  filterGroups: [
    {
      title: 'Дані про замовлення',
      testId: 'group1',
      controlName: 'group1',
      expandedByDefault: true,
      filters: [OrdersNumberOrderFilter, OrdersCreationDateFilter, OrdersStatusFilter],
    },
  ],
};

export function getChips(filterGroups: Record<string, any>): TableChip[] {
  if (!filterGroups) {
    return [];
  }

  const numberOrder =
    filterGroups['group1']['numberOrder'] !== OrdersNumberOrderFilter.initialValue &&
    filterGroups['group1']['numberOrder'];
  const status =
    filterGroups['group1']['status'] !== OrdersStatusFilter.initialValue && filterGroups['group1']['status'];
  const creationDateFrom =
    filterGroups['group1']['creationDate']['creationDateFrom'] !== OrdersCreationDateFilter.fromFilter.initialValue &&
    filterGroups['group1']['creationDate']['creationDateFrom'];
  const creationDateTo =
    filterGroups['group1']['creationDate']['creationDateTo'] !== OrdersCreationDateFilter.toFilter.initialValue &&
    filterGroups['group1']['creationDate']['creationDateTo'];

  const creationDateChipContent =
    creationDateTo && creationDateFrom ? `${creationDateFrom} - ${creationDateTo}` : creationDateFrom || creationDateTo;
  return [
    ...(numberOrder ? [{ content: `Номер замовлення: ${numberOrder}` }] : []),
    ...(status ? [{ content: status }] : []),
    ...(creationDateChipContent ? [{ content: getCreationDateChipContent(creationDateFrom, creationDateTo) }] : []),
  ];
}

function getCreationDateChipContent(creationDateFrom: string, creationDateTo: string): string {
  const pipe = new DatePipe('en-US');
  if (creationDateFrom && !creationDateTo) {
    return `Створили з ${pipe.transform(creationDateFrom, 'dd.MM.yyy')}`;
  }

  if (!creationDateFrom && creationDateTo) {
    return `Створили до ${pipe.transform(creationDateTo, 'dd.MM.yyy')}`;
  }

  return `Створили ${pipe.transform(creationDateFrom, 'dd.MM.yyy')}${
    datesEqual(creationDateFrom, creationDateTo) ? '' : ' - ' + pipe.transform(creationDateTo, 'dd.MM.yyy')
  }`;
}


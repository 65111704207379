import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { BuildingErrors } from 'app/shared/errors/error-messages';
import { getError } from 'app/shared/utils/error.utils';
import {
  BuildingClasses,
  BuildingControlName,
  BuildingLabel,
  BuildingMaxLength,
  BuildingPlaceholder,
} from './building.constants';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingComponent),
      multi: true,
    },
  ],
})
export class BuildingComponent implements ControlValueAccessor {
  getError = getError;

  BuildingErrors = BuildingErrors;

  @Input() formControl: UntypedFormControl | AbstractControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  @Input() placeholder: string = BuildingPlaceholder;

  @Input() label: string = BuildingLabel;

  @Input() testId: string = BuildingControlName;

  @Input() minLength: number = BuildingMaxLength;

  @Input() maxLength: number = BuildingMaxLength;

  @Input()
  @HostBinding('class')
  classList = BuildingClasses;

  get building(): AbstractControl {
    return this.formControl;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    if (obj === this.formControl.value) {
      return;
    }
    this.formControl.setValue(obj);
  }
}

import {API_URL} from './environment.constants';

export const environment = {
  production: true,
  apiUrl: API_URL,
  printUrl: 'https://my.novaposhta.ua/',
  functionsUrl: 'https://us-central1-api-project-902242221193.cloudfunctions.net',
  tasLink: 'https://np.taslink.com.ua',
  firebaseConfig: {
    apiKey: 'AIzaSyATFxXJ5C7UsncoJqZ9w2F6pkxtbPSAuEw',
    authDomain: 'api-project-902242221193.firebaseapp.com',
    databaseURL: 'https://api-project-902242221193-a98af.firebaseio.com',
    projectId: 'api-project-902242221193',
    storageBucket: 'api-project-902242221193.appspot.com',
    messagingSenderId: '902242221193',
    appId: '1:902242221193:web:0be6bba3888f6bbeb19a58',
    measurementId: 'G-H1YL0PPQW1',
  },
};

import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {filter} from 'rxjs';
import {ConfirmDialogService} from './confirm-dialog.service';

@Directive({
  selector: '[confirm]',
  exportAs: 'confirm',
})
export class ConfirmDialogDirective {

  @Input()
  confirmMsg = '';

  @Input()
  confirmTitle = '';

  @Output()
  confirm = new EventEmitter<void>();

  @Input()
  confirmBtnText = 'Ok';

  @Input()
  cancelBtnText = 'Cancel';

  @Input()
  isConfirmHtml = false;

  constructor(private confirmDialogService: ConfirmDialogService) {
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    this.confirmDialogService.confirm({
      cancelBtnText: this.cancelBtnText,
      confirmBtnText: this.confirmBtnText,
      confirmMsg: this.confirmMsg,
      confirmTitle: this.confirmTitle,
      isConfirmHtml: this.isConfirmHtml,
    })
      .pipe(filter(confirm => !!confirm))
      .subscribe(() => {
        this.confirm.emit();
      });
  }
}

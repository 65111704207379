import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogDirective } from './confirm-dialog.directive';
import { ConfirmDialogService } from './confirm-dialog.service';
import {MaterialModule} from '../../../material';
import {PopupModule} from '../popup/popup.module';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        ConfirmDialogDirective,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        PopupModule,
    ],
    exports: [
        ConfirmDialogComponent,
        ConfirmDialogDirective,
    ]
})
export class ConfirmDialogModule {
  static forRoot(): ModuleWithProviders<ConfirmDialogModule> {
    return {
      ngModule: ConfirmDialogModule,
      providers: [ConfirmDialogService],
    };
  }
}

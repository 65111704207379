import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { GoogleAnalyticService } from '@services';
import { AbstractFormContainer } from '@shared';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delivery-seats-checkbox',
  templateUrl: './delivery-seats-checkbox.component.html',
  styleUrls: ['./delivery-seats-checkbox.component.scss'],
})
export class DeliverySeatsCheckboxComponent extends AbstractFormContainer implements OnInit {
  disableButton$;
  checked = false;

  @Input() isTemplate: boolean;

  @Input() invoiceId: string;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticService,
    protected storage: LocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    const deliveryTypeControl = new UntypedFormControl(false);
    this.form.addControl(this.controlName, deliveryTypeControl);
  }

  changeSeats(event) {
    this.storage.store('paramsCommonSeats', event.checked);
  }
}

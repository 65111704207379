import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ReturnReason } from '../components/dashboard/business-services/models/return-reason';
import { BackInvoice } from '../models/back-invoice';
import { IListData } from '../models/iapi';
import { Invoice } from '../models/invoice/invoice.model';
import { CheckPossibilityCreateReturnApiResponse } from '../shared/components/back-invoices-form/return-request.interfaces';
import { BackInvoicesApiService } from './back-invoices-api.service';

@Injectable({
  providedIn: 'root',
})
export class BackInvoicesService {
  constructor(private backApi: BackInvoicesApiService) {
    this.backApi = backApi;
  }

  create(invoice: Invoice, data): Observable<Partial<BackInvoice>> {
    return this.backApi.create(invoice, data).pipe(map((data: Partial<BackInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
        MessageText: data.MessageText,
      }),
    ));
  }

  update(invoice: Invoice, data, Ref?: string): Observable<Partial<BackInvoice>> {
    return this.backApi.update(invoice, data, false, Ref).pipe(map((data: Partial<BackInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
        MessageText: data.MessageText,
      }),
    ));
  }

  getPricing(invoice: Invoice, data, Ref?: string): Observable<Partial<BackInvoice>> {
    return this.backApi.create(invoice, data, true, Ref).pipe(map((data: Partial<BackInvoice>) =>
      ({
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
      }),
    ));
  }

  getPricingForUpdate(invoice: Invoice, data, Ref?: string): Observable<Partial<BackInvoice>> {
    return this.backApi.update(invoice, data, true, Ref).pipe(map((data: Partial<BackInvoice>) =>
      ({
        Number: data.Number,
        ScheduledDeliveryDate: data.ScheduledDeliveryDate,
        Pricing: data.Pricing,
      }),
    ));
  }

  getList(
    page: number = 1,
    limit: number = 10,
  ): Observable<IListData<BackInvoice>> {
    return this.backApi.getList(page, limit);
  }

  getOne(orderId: string): Observable<BackInvoice> {
    return this.backApi.getOne(orderId);
  }

  getReasons() {
    return this.backApi.getReasons();
  }

  getSubReasons(idReason: string): Observable<ReturnReason[]> {
    return this.backApi.getSubReasons(idReason);
  }

  remove(idOrder: string): Observable<boolean> {
    return this.backApi.remove(idOrder);
  }

  checkIfPossibleCreate(
    docNumber: string,
    OrderRef?: string,
  ): Observable<CheckPossibilityCreateReturnApiResponse> {
    return this.backApi.checkIfPossibleCreate(docNumber, OrderRef);
  }
}

<mat-form-field [attr.data-test-id]="testId"
                class="form-input">
  <mat-label>{{label}}</mat-label>

  <input [formControl]="control"
         [matAutocomplete]="autocomplete"
         [maxLength]="maxLength"
         (focus)="initSubscription()"
         [placeholder]="placeholder"
         matInput
         type="text"
         autocomplete="off"
         class="text-overflow-ellipsis"
         autoScrollTarget>

  <button *ngIf="!control?.value && !loading && items?.length"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
  </button>

  <button *ngIf="clearEnabled && control?.value && !loading"
          (click)="clearField(control, $event, '')"
          class="p-0"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear">
    <mat-icon>close</mat-icon>
  </button>

  <button *ngIf="loading"
          class="p-0 transform"
          mat-button
          matSuffix
          mat-icon-button
          [disabled]="true">
    <mat-spinner [diameter]="19.5" class="form-field-spinner"></mat-spinner>
  </button>

  <mat-autocomplete #autocomplete="matAutocomplete"
                    [displayWith]="getDescription"
                    (optionSelected)="blur()">
    <mat-option *ngFor="let item of items"
                [disabled]="item.config?.disabled"
                [matTooltip]="item.config?.tooltip"
                [value]="item.option">
      {{getDescription(item.option)}}
    </mat-option>
    <mat-option *ngIf="nothingFound" disabled="true">
      {{nothingFoundLabel}}
    </mat-option>
  </mat-autocomplete>

  <mat-error>{{getError(control, errors)}}</mat-error>
</mat-form-field>

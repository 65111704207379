<div class="services-list__form" [formGroup]="forwardingCountGroup">
  <div class="form-row">
    <div class="form-col form-col-1 form-col-1_with_symbol">
      <mat-form-field class="mat-block mat-block_with_symbol" data-symbol="шт">
        <input matInput
               npNumber
               [maxLength]="99999999"
               placeholder="Кількість"
               OnlyNumber="true"
               [formControlName]="controlName"
               autoScrollTarget>
      </mat-form-field>
    </div>
  </div>
</div>

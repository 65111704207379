import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IApiError } from '@models';
import { AlertToastService } from '@services';
import { getError, getStringLength, trimMaxLength, trimSpaces, Unsubscriber } from '@shared';
import * as moment from 'moment/moment';
import { BehaviorSubject, distinctUntilChanged, finalize, map, pipe, startWith } from 'rxjs';
import { InvoiceRegister } from '../../../../models/invoice/invoice-register.model';
import { InvoiceRegisterApiService } from '../../../../services/registers/invoice-register.api.service';
import { NewRegisterModalPayload, NewRegisterMode } from './new-register.constants';

const descriptionErrors = {
  maxlength: 'Максимальна довжина 30 символів',
  pattern: 'Містить недопустимий символ',
};

@Component({
  selector: 'app-new-register',
  templateUrl: './new-register.component.html',
  styleUrls: ['./new-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewRegisterComponent extends Unsubscriber implements OnInit {
  protected readonly descriptionErrors = descriptionErrors;
  protected readonly getError = getError;
  readonly newRegisterName: string = 'new';
  readonly pattern = /^[а-яА-Я,ёЁіІїЇєЄґҐюЮa-zA-Z\d\s\.№;!:/\-'\*\+#`',\/\(\)"«»\\\\_&„""]*$/ui;

  private _pending = new BehaviorSubject(false);
  pending$ = this._pending.asObservable();

  set pending(value: boolean) {
    this._pending.next(value);
  }

  registers: InvoiceRegister[] = [];

  invoicesIds: string[] = [];

  minDate = moment().toDate();

  dateValue = new Date();

  maxLength = 30;

  maxInputLength = 50;

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: NewRegisterModalPayload,
    public dialogRef: MatDialogRef<NewRegisterComponent>,
    private invoiceRegisterService: InvoiceRegisterApiService,
    private alertToastService: AlertToastService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
  ) {
    super();
    dateAdapter.setLocale('uk-UA');
  }

  get title(): string {
    return this.data.title ?? 'Додати до реєстру';
  }

  get canViewDescription(): boolean {
    return this.data.canViewDescription ?? true;
  }

  get canViewDate(): boolean {
    return this.data.canViewDate ?? true;
  }

  setMode(): void {
    this.mode = this.data.newRegisterMode ?? 'default';
  }

  mode: NewRegisterMode = 'default';

  get description(): AbstractControl {
    return this.form?.get('description') as AbstractControl;
  }

  get registerRef(): AbstractControl {
    return this.form?.get('registerRef') as AbstractControl;
  }

  get date(): AbstractControl {
    return this.form?.get('date') as AbstractControl;
  }

  onDateChange($event): void {
    this.date.setValue(new Date($event));
  }

  onBlur($event: FocusEvent): void {
    let value = ($event.target as HTMLInputElement).value;
    this.checkMaxStringLength(value, true);
  }

  private initCheckMaxStringLengthSubscription(): void {
    this.subscriptions = this.description?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string) => this.checkMaxStringLength(value));
  }

  private checkMaxStringLength(value: string, shouldTrimSpaces: boolean = false): void {
    if (!this.description) {
      return;
    }

    const cleanValue = shouldTrimSpaces ? trimSpaces(value) : value;
    if (getStringLength(trimSpaces(value)) > this.maxInputLength) {
      this.description?.setValue(trimMaxLength(trimSpaces(value), this.maxInputLength));
    } else {
      this.description?.setValue(cleanValue);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.setMode();
    this.initRegisters();
    this.initCheckMaxStringLengthSubscription();
  }

  private initRegisters(): void {
    if (this.registers.length === 0 && this.data.getRegistersFromApi) {
      this.getRegistries();
    }
  }

  registerDescription(register: InvoiceRegister): string {
    const parts: string[] = [];

    parts.push(register.docNumber || '&mdash;');

    if (!!register.dateTime) {
      parts.push('від ' + moment(register.dateTime ?? '').format('DD.MM.YYYY'));
    }

    if (register.description) {
      parts.push(`(${register.description})`);
    }

    return parts.join(' ');
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.pending = true;

    if (this.mode === 'empty') {
      if (this.registerRef.value === this.newRegisterName) {
        this.createEmptyRegister();
      } else {
        this.dialogRef.close({ register: this.registerRef.value, type: 'exist', success: true });
      }
    } else {
      this.registerRef.value === this.newRegisterName
        ? this.addToNewRegister()
        : this.insertIntoExistRegister(this.registerRef.value);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      registerRef: [this.newRegisterName],
      description: ['', [Validators.maxLength(30), Validators.pattern(this.pattern)]],
      date: [new Date(), Validators.required],
    });

    if (this.data.canEditDescription) {
      this.description.enable();
      this.subscribeToRegisterRefChanges();
    } else {
      this.description.disable();
    }

    if (this.data.canEditDate) {
      this.date.enable();
    } else {
      this.date.disable();
    }
  }

  private getRegistries(): void {
    this.pending = true;

    this.invoiceRegisterService.getList({ isOpen: false }, null)
      .pipe(
        finalize(() => this.pending = false),
        map((data: any) => data.list),
      ).subscribe((registers: InvoiceRegister[]) => {
      this.registers = registers.filter((register) => register.printed === '0');
    });
  }

  private subscribeToRegisterRefChanges(): void {
    this.subscriptions = this.registerRef?.valueChanges
      .pipe(startWith(this.newRegisterName), pipe(distinctUntilChanged()))
      .subscribe((value) => {
        if (value === this.newRegisterName) {
          if (this.data.canEditDescription) {
            this.description.enable();
            this.clearField();
          } else {
            this.description.disable();
          }

          if (this.data.canEditDate) {
            this.date.enable();
          } else {
            this.date.disable();
          }

          this.date.setValue(new Date());
        } else {
          this.clearField();
          this.description.setValue(this.registerRef.value.description);
          this.date.setValue(this.registerRef.value.dateTime);
          this.description.disable();
          this.date.disable();
        }
      });
  }

  clearField(): void {
    this.description?.setValue('');
  }

  private addToNewRegister(): void {
    const invoiceRegister = new InvoiceRegister();
    invoiceRegister.description = this.description.value;

    this.invoiceRegisterService
      .addWithInvoices(invoiceRegister, this.invoicesIds, this.date.value)
      .pipe(finalize(() => this.pending = false))
      .subscribe(
        (register) => {
          this.alertToastService.pushSuccess('Успішно додано до реєстру');
          this.dialogRef.close({ register, type: 'new', success: true });
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.dialogRef.close({ register: null, type: 'new', success: true });
        },
      );
  }

  private createEmptyRegister(): void {
    this.invoiceRegisterService.createEmptyRegister(this.date.value, this.description.value)
      .pipe(finalize(() => (this.pending = false)))
      .subscribe(
        (register) => {
          this.dialogRef.close({ register, type: 'new', success: true });
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.dialogRef.close({ register: null, type: 'new', success: true });
        },
      );
  }

  private insertIntoExistRegister(register: InvoiceRegister): void {
    this.invoiceRegisterService
      .insertDocuments(this.registerRef.value.id, this.invoicesIds)
      .pipe(finalize(() => (this.pending = false)))
      .subscribe(
        (success) => {
          if (success) {
            this.alertToastService.pushSuccess('Успішно додано до реєстру');
            this.dialogRef.close({ register, type: 'exist', success: true });
          }
        },
        (errors: IApiError[]) => {
          if (errors[0]) {
            this.alertToastService.pushApiErrors(errors);
          }
          this.dialogRef.close({ register: null, type: 'new', success: true });
        },
      );
  }
}

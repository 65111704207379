import { AddressTypes } from '@models';
import { GetCountriesData } from '@shared';
import { LatinLettersTooltip } from '../components/addresses/city-select-international/city-select.constants';
import { DefaultCountryPhoneConfig } from '../international-invoice.constants';

// Germany
export const DE: Partial<GetCountriesData> = {
  ...DefaultCountryPhoneConfig,
  CityTooltip: LatinLettersTooltip,
  RecipientSpotSelectionAvailable: true,
  RecipientRegistrationAddressRequired: true,
  DisplayRecipientRegistrationAddressFor: [AddressTypes.WAREHOUSE, AddressTypes.POSTBOX],
};

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {AbstractFormContainer} from '@shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-address-sender-note',
    templateUrl: './address-sender-note.component.html'
})

export class AddressSenderNoteComponent extends AbstractFormContainer implements OnInit {

    @Input() controlName = 'Note';

    constructor(protected formBuilder: UntypedFormBuilder) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    addFormControls() {
        const noteControl = new UntypedFormControl('', Validators.maxLength(40));
        this.form.addControl(this.controlName, noteControl);
    }
}

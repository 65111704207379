import {Model} from '../base.model';

export class PaymentCard extends Model {
  description: string;
  maskedNumber: string;
  expires: Date;
  state: PaymentCardState;
}

export class PaymentCardState extends Model {
  title: string;
}

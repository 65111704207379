import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MasterpassCard, PaymentCostsInfo, User } from '@models';
import { AlertToastService, AuthService } from '@services';
import { objectIsEmpty, Unsubscriber } from '@shared';
import { finalize, switchMap, tap } from 'rxjs';
import { Invoice } from '../../../../../models/invoice/invoice.model';
import { MasterpassService } from '../../../../../services/masterpass.service';
import { RedirectSuccessPopupData } from './redirect-success-popup.interface';

@Component({
  selector: 'app-redirect-success-popup',
  templateUrl: './redirect-success-popup.component.html',
  styleUrls: ['./redirect-success-popup.component.scss'],
})
export class RedirectSuccessPopupComponent extends Unsubscriber implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RedirectSuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RedirectSuccessPopupData,
    private masterpassService: MasterpassService,
    private authService: AuthService,
    private alertToastService: AlertToastService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  pending = false;

  paymentCostsInfo = {} as any;

  paymentPopupOpened = false;

  paymentPending = false;

  API_TIMEOUT = 1000;

  ngOnInit(): void {
    this.subscribeToBackdropClick();
    this.pending = true;
    setTimeout(() => {
      this.getPaymentInfo();
    }, this.API_TIMEOUT);
  }

  getPaymentInfo(): void {
    this.pending = true;
    this.subscriptions = this.masterpassService
      .getPaymentInfo(this.invoice.docNumber, this.phone)
      .pipe(
        finalize(() => {
          this.pending = false;
        }),
      )
      .subscribe(
        (paymentCostsInfo: PaymentCostsInfo) => {
          this.paymentCostsInfo = paymentCostsInfo;
        },
        () => {
          this.paymentCostsInfo = {};
        },
      );
  }

  get Number(): string {
    return this.data.Number;
  }

  get isCreated(): boolean {
    return this.data.isCreated;
  }

  get cost(): number {
    return this.data.Pricing;
  }

  get showMessageText(): boolean {
    return !!this.data.MessageText;
  }

  get messageText(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.MessageText);
  }

  get scheduledDeliveryDate(): Date {
    return this.data.ScheduledDeliveryDate;
  }

  close(): void {
    this.dialogRef.close({ success: true });
  }

  selectedCard: MasterpassCard;

  selectedServices = {
    Services: false,
    MoneyTransfer: false,
    AfterPayment: false,
    InternationalDelivery: false,
    Redirecting: true,
    Return: false,
  };

  get phone(): string {
    return this.type === 'in'
      ? this.invoice.recipient && (this.invoice.recipient.phone as string)
      : this.user.phone || this.invoice.SendersPhone || this.invoice.PhoneSender;
  }

  navigateToPaymentFrame(): void {
    this.paymentPending = true;
    this.pending = true;

    this.masterpassService
      .initPayment(this.invoice, this.paymentCostsInfo, this.selectedServices, this.selectedCard, this.type)
      .pipe(
        tap(() => (this.pending = false)),
        switchMap((data) => {
          const url = `${data.Url}&lang=ua`;
          this.paymentPopupOpened = true;
          return this.masterpassService.processPaymentV3(
            url,
            this.invoice,
            this.selectedServices,
            this.type,
            this.paymentCostsInfo,
          );
        }),
        tap(() => {
          this.pending = true;
          this.paymentPopupOpened = false;
        }),
        switchMap(() => this.masterpassService.getPaymentInfo(this.invoice.docNumber, this.phone)),
        finalize(() => {
          this.pending = false;
          this.paymentPending = false;
        }),
      )
      .subscribe(
        (data) => {
          this.paymentCostsInfo = data;
          this.paymentPending = false;
          if (this.checkIfPaid(this.paymentCostsInfo)) {
            this.close();
          }
        },
        (error) => {
          if (error[0]) {
            this.alertToastService.pushApiErrors(error);
          }
        },
      );
  }

  get user(): User {
    return this.authService.user;
  }

  get invoice(): Invoice | any {
    return this.data.Invoice;
  }

  get type(): string {
    return this.data.Type;
  }

  checkIsCorporatePaymentDisabled(): boolean {
    return this.user.contacts && !this.paymentCostsInfo?.UseNovaPay;
  }

  checkPaymentDisabled(): boolean {
    return (
      objectIsEmpty(this.paymentCostsInfo) ||
      (this.paymentCostsInfo && this.checkIfPaid(this.paymentCostsInfo)) ||
      this.checkIsCorporatePaymentDisabled() ||
      this.paymentPopupOpened ||
      this.paymentCostsInfo.UseNovaPay === null ||
      this.paymentPending
    );
  }

  checkIfPaid(paymentCostsInfo: PaymentCostsInfo): boolean {
    if (!paymentCostsInfo?.Redirecting || !paymentCostsInfo?.UseNovaPay) {
      return true;
    }

    return paymentCostsInfo.Redirecting <= 0;
  }

  private subscribeToBackdropClick(): void {
    this.subscriptions = this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }
}

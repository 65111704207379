import { Component, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-light-return-modal-confirmed',
  templateUrl: './light-return-modal-confirmed.component.html',
  styleUrls: ['./light-return-modal-confirmed.component.scss'],
})
export class LightReturnModalConfirmedComponent {

  constructor(private dialog: MatDialogRef<LightReturnModalConfirmedComponent>) {
  }

  closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  number: string = '';

  close(): void {
    this.dialog.close({ reload: true });
  }

  closeAndRedirect(): void {
    this.dialog.close({ reload: false });
  }
}

<form [formGroup]="form">
  <div class="modal modal_large">
  <span class="modal__close ngdialog-close" (click)="$event.stopPropagation(); close()">
    <i class="material-icons">close</i>
  </span>
    <div class="modal-header relative">
      <div class="form-row">
        <div class="form-col form-col-3">
          <h3 class="modal-header__title np-invoices-show__success">Заявка на зміну даних</h3>
        </div>
      </div>
      <mat-progress-bar class="mat-progress-bar-host"
                        [style.opacity]="isLoadingProgress ? 1 : 0"
                        mode="indeterminate"></mat-progress-bar>
    </div>
    <main class="modal-content" #modalContent>
      <div class="np-invoices-show__success relative">
        <div class="mat-spinner-host" [class.is-hidden]="!isLoadingSpinner">
          <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
        <div class="form-row">
          <div class="form-col form-col-8">
            <div class="banner">
              <div class="banner__info">
                <div class="warning-message">
                  <div class="warning-message__text"> Ви можете самостійно змінювати дані посилки, коли відправлення в
                    дорозі. Вартість послуги розраховується
                    згідно з <a class="link-red" target="_blank"
                                href="https://novaposhta.ua/izmenenie_dannyh_v_ekspress_nakladnoy_chk">чинними
                      тарифами</a> компанії «Нова Пошта»
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mr-bottom-16">
          <section class="form-col form-col-8">
            <stf-accordeon>
              <stf-accordeon-item class="np-header-invoice-redirect-info">
                <div header>
                  <app-invoice-short-info [isIn]="type === 'in'" [isOut]="type === 'out'"
                                          [invoice]="invoice"></app-invoice-short-info>
                </div>
                <div body>
                  <app-invoice-detailed-info [invoice]="invoice"></app-invoice-detailed-info>
                </div>
              </stf-accordeon-item>
            </stf-accordeon>
          </section>
        </div>
        <div class="form-row">
          <div class="form-col">
            <h4 class="modal-content__title">Відправник</h4>
          </div>
        </div>

        <ng-container>
          <np-change-ew-contact-input
            *ngIf="possibilityChangeEw?.SenderCounterparty !== 'Приватна особа'"
            [placeholder]="'Організація'"
            [showControls]="false"
            [value]="possibilityChangeEw?.SenderCounterparty"
          ></np-change-ew-contact-input>

          <np-change-ew-contact-input [placeholder]="'Контакт відправника'"
                                      [value]="getContactDescription('Sender')"
                                      [disableCreation]="!possibilityChangeEw?.CanChangeSender"
                                      [BlockChangeSenderDataIfAvailableCargoTypeMoney]="possibilityChangeEw?.BlockChangeSenderDataIfAvailableCargoTypeMoney"
                                      *ngIf="senderMode === 'select'"
                                      (onCreate)="changeMode('senderMode',$event)"></np-change-ew-contact-input>

          <app-private-person-sender-form-change-ew
            [form]="form"
            [patchSenderControlName]="'senderData'"
            [patchSenderData]="'description'"
            [controlName]="'NewSender'"
            *ngIf="senderMode === 'create'"
            (onCancel)="changeMode('senderMode', $event); removeControl('NewSender')"
            [IsEnabledCancel]="true"
            [clearOnPhoneChange]="false"
            [disablePhoneInput]="false"
            [isMiddleNameRequired]="false"
          ></app-private-person-sender-form-change-ew>
        </ng-container>

        <div class="form-row">
          <div class="form-col">
            <h4 class="modal-content__title">Отримувач</h4>
          </div>

        </div>

        <ng-container>
          <np-change-ew-contact-input
            *ngIf="possibilityChangeEw?.RecipientCounterparty !== 'Приватна особа' && organizationMode === 'select'"
            [placeholder]="'Організація'"
            [showControls]="false"
            [value]="possibilityChangeEw?.RecipientCounterparty"
          ></np-change-ew-contact-input>

          <div class="form-row" *ngIf="organizationMode === 'create'">
            <div class="form-col form-col-7">
              <mat-radio-group
                class="radio-row"
                [formControl]="form.get('recipientType')"
              >
                <mat-radio-button
                  [value]="'PrivatePerson'"
                >Приватна особа
                </mat-radio-button>
                <mat-radio-button
                  [value]="'Organization'"
                >Організація
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <app-contact-organization-change-ew
            [form]="form"
            [recipientEDRPOU]="possibilityChangeEw.EdrpouRecipient"
            *ngIf="form.get('recipientType').value === 'Organization' && organizationMode === 'create'"
          ></app-contact-organization-change-ew>

          <app-private-person-form-change-ew
            [form]="form"
            [patchSenderControlName]="'recipientData'"
            [patchSenderData]="'description'"
            [controlName]="'NewRecipient'"
            *ngIf="recipientMode === 'create'"
            (onCancel)="changeMode('recipientMode',$event);
            resetRecipientCounterparty();
            changeMode('organizationMode',$event);
            removeControl('NewRecipient')"
            [IsEnabledCancel]="true"
            [clearOnPhoneChange]="false"
            [disablePhoneInput]="false"
            [isMiddleNameRequired]="false"
          ></app-private-person-form-change-ew>

          <np-change-ew-contact-input [placeholder]="'Контакт отримувача'"
                                      (onCreate)="changeMode('recipientMode',$event); changeMode('organizationMode',$event)"
                                      *ngIf="recipientMode === 'select'"
                                      [disableCreation]="!possibilityChangeEw?.CanChangeRecipient"
                                      [value]="getContactDescription('Recipient')"></np-change-ew-contact-input>
        </ng-container>

        <div class="form-row">
          <div class="form-col form-col-3"
               [class.form-col-5]="payerType === 'ThirdPerson'">
            <div class="label-radio label-radio_dark">Платник за доставку</div>
            <mat-radio-group formControlName="PayerType"
                             [disabled]="!form.get('CanChangePayerTypeOrPaymentMethod').value">
              <mat-radio-button [value]="'Sender'">Відправник</mat-radio-button>
              <mat-radio-button [value]="'Recipient'">Отримувач</mat-radio-button>
              <mat-radio-button *ngIf="payerType === 'ThirdPerson'"
                                [value]="'ThirdPerson'">Третя особа
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="form-col form-col-3">
            <div class="label-radio label-radio_dark">Спосіб оплати</div>
            <mat-radio-group formControlName="PaymentMethod"
                             [disabled]="!form.get('CanChangePayerTypeOrPaymentMethod').value">
              <mat-radio-button [value]="'Cash'"
                                [disabled]="form.get('PayerType').value === 'ThirdPerson'">Готівка
              </mat-radio-button>
              <mat-radio-button [value]="'NonCash'">Безготівка</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <h4 class="modal-content__title">Додаткові послуги</h4>
          </div>

        </div>
        <div class="services-list-change-ew" [formGroup]="servicesGroup">
          <div class="services-list__item flex-direction-column align-items-start" formGroupName="backDelivery"
               *ngIf="displayServices.backDelivery">
            <div class="services-list__info d-flex align-items-center">
              <mat-slide-toggle class="services-list__checkbox"
                                formControlName="selected">
                {{servicesNames.backDelivery.name}}</mat-slide-toggle>
              <i class="material-icons icon-help cursor-pointer"
                 [matTooltip]="servicesNames.backDelivery.description"
                 matTooltipPosition="below">help_outline</i>
            </div>
            <app-inv-back-delivery *ngIf="servicesGroup.get('backDelivery').value.selected"
                                   [form]="form"
                                   [isChangeEw]="true"
                                   [changeEwSenderPhone]="possibilityChangeEw.SenderPhone"
                                   [invoiceId]="invoice.docNumber"
                                   [invoiceNumber]="invoice.docNumber"
                                   [CanChangeCash2Card]="possibilityChangeEw.CanChangeCash2Card"></app-inv-back-delivery>
          </div>
          <div class="services-list__item flex-direction-column align-items-start" formGroupName="backDocuments"
               *ngIf="displayServices.backDocuments">
            <div class="services-list__info d-flex align-items-center">
              <mat-slide-toggle class="services-list__checkbox"
                                formControlName="selected">{{servicesNames.backDocuments.name}}</mat-slide-toggle>
              <i class="material-icons icon-help cursor-pointer"
                 [matTooltip]="servicesNames.backDocuments.description"
                 matTooltipPosition="below">help_outline</i>
            </div>
            <np-inv-back-documents
              [form]="form"
              *ngIf="servicesGroup.get('backDocuments').value.selected"></np-inv-back-documents>
          </div>

          <div class="services-list__item flex-direction-column align-items-start" formGroupName="differentDocuments"
               *ngIf="displayServices.differentDocuments">
            <div class="services-list__info d-flex align-items-center">
              <mat-slide-toggle class="services-list__checkbox"
                                formControlName="selected">{{servicesNames.differentDocuments.name}}</mat-slide-toggle>
              <i class="material-icons icon-help cursor-pointer"
                 [matTooltip]="servicesNames.differentDocuments.description"
                 matTooltipPosition="below">help_outline</i>
            </div>
            <app-inv-different-documents
              [form]="form"
              *ngIf="servicesGroup.get('differentDocuments').value.selected"></app-inv-different-documents>
          </div>
          <div class="services-list__item flex-direction-column align-items-start" formGroupName="afterpayment"
               *ngIf="displayServices.afterpayment">
            <div class="services-list__info d-flex align-items-center">
              <mat-slide-toggle class="services-list__checkbox"
                                [matTooltip]="showTooltipAfterpayment ? 'Відсутня можливість зміни для даного відправлення' : ''"
                                formControlName="selected">{{servicesNames.afterpayment.name}}</mat-slide-toggle>
              <i class="material-icons icon-help cursor-pointer"
                 [matTooltip]="servicesNames.afterpayment.description"
                 matTooltipPosition="below">help_outline</i>
            </div>
            <app-afterpayment
              [form]="form"
              [isChangeEw]="true"
              [showTooltipAfterpayment]="showTooltipAfterpayment"
              *ngIf="servicesGroup.get('afterpayment').value.selected"></app-afterpayment>
          </div>
          <div class="services-list__item flex-direction-column align-items-start" formGroupName="floorsLifting"
               *ngIf="displayServices.floorsLifting">
            <div class="services-list__info d-flex align-items-center">
              <mat-slide-toggle class="services-list__checkbox"
                                (change)="scroll(); setFloorsLifting(possibilityChangeEw, $event.checked)"
                                formControlName="selected"
                                [disabled]="form.get('AddressRecipient')?.value?.type !== 'Doors'"
                                [matTooltip]="form.get('AddressRecipient')?.value?.type !== 'Doors' && showTooltip ? 'Відсутня можливість зміни для даного відправлення' : ''">
                {{servicesNames.floorsLifting.name}}</mat-slide-toggle>
              <i class="material-icons icon-help cursor-pointer"
                 matTooltipClass="multiline-tooltip"
                 [matTooltip]="servicesNames.floorsLifting.description"
                 matTooltipPosition="below">help_outline</i>
            </div>
            <app-floors-lifting
              [form]="form"
              [showTooltipFloor]="showTooltipFloor"
              *ngIf="servicesGroup.get('floorsLifting').value.selected"></app-floors-lifting>
          </div>
          <div #floors></div>
        </div>
      </div>
    </main>
    <div class="modal-footer modal-footer_pad-8">
      <div class="modal-footer-buttons">
        <button class="np-submit-redirect-invoice"
                color="primary"
                type="submit"
                (click)="submit()"
                [disabled]="isLoadingSpinner || isLoadingProgress"
                mat-button>Створити заявку
        </button>
      </div>
    </div>
  </div>
</form>

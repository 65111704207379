export class PaymentInfo {
    payeeId: string;
    shopOrederNumber: string;
    billAmount: number;
    description: string;
    successUrl: string;
    failureUrl: string;
    lang: string;
    preauthFlag: string;
    encoding: string;
    serviceAction: string;
    shopOrderNumber: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {forkJoin, from as fromPromise, Observable, of, throwError} from 'rxjs';
import { map, mapTo, take } from 'rxjs';
import {defaultRemoteConfig} from './default-remote-config';
import { FirebaseAuthService } from './firebase-auth.service';
import { FirebaseDatabaseService } from './firebase-database.service';

import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/remote-config';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {

  get remoteConfig() {
    return firebase.remoteConfig();
  }

  constructor(
    protected http: HttpClient,
    protected fbDatabaseService: FirebaseDatabaseService,
    protected fbAuthService: FirebaseAuthService,
  ) {
    this.initRemoteConfig();
  }

  private initRemoteConfig() {
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: 60000,
    };
    this.remoteConfig.defaultConfig = defaultRemoteConfig;
    fromPromise(this.remoteConfig.fetchAndActivate())
      .subscribe(
        () => {},
        err => console.error(err),
      );
  }

  checkShowTutorial(): Observable<boolean> {
    if (this.fbAuthService.currentUser) {
      return fromPromise(this.fbDatabaseService.db.ref(`users/${this.fbAuthService.currentUser.uid}/showTutorial`).once('value'))
        .pipe(
          map(data => {
            const showTutorial = data.val();
            return showTutorial !== null ? showTutorial : true;
          }),
        );
    }
    return of(false);
  }

  getTrackingDocuments(): Observable<any[]> {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.getArrayByRef(`users/${this.fbAuthService.currentUser.uid}/trackingDocuments`);
    }
    return of([]);
  }

  track(invoiceNumber: string): Observable<string> {
    return this.fbDatabaseService.setToRef(
      `users/${firebase.auth().currentUser.uid}/trackingDocuments/${invoiceNumber}`,
      invoiceNumber,
    )
      .pipe(mapTo(''));
  }

  removeTrackingDocument(invoiceNumber: string) {
    return this.fbDatabaseService.removeByRef(`users/${this.fbAuthService.currentUser.uid}/trackingDocuments/${invoiceNumber}`);
  }

  checkShowReview(): Observable<boolean> {
    if (this.fbAuthService.currentUser) {
      return forkJoin(
        this.fbDatabaseService.getSnapshotByRef('review/dateLaunchQuestion'),
        this.fbDatabaseService.getSnapshotByRef(`users/${this.fbAuthService.currentUser.uid}/lastLaunchQuestion`),
      ).pipe(
        map(([showReviewDateString, lastLaunchQuestionString]) => {
          if (!showReviewDateString) {
            return false;
          }

          const showReviewDate = new Date(showReviewDateString);
          if (showReviewDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
            if (!lastLaunchQuestionString) {
              return true;
            }

            const lastReviewShow = new Date(lastLaunchQuestionString);
            if (showReviewDate.getTime() > lastReviewShow.getTime()) {
              return true;
            }
          }

          return false;
        }),
      );
    }
    return of(false);
  }

  getReviewUrl() {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.getSnapshotByRef(`review/reviewUrl`);
    }
    return of('');
  }

  closeReview() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return this.fbDatabaseService
      .setToRef(`users/${this.fbAuthService.currentUser.uid}/lastLaunchQuestion`, `${month}/${day}/${year}`);
  }

  saveSmsClubToken(token: string) {
    return this.fbDatabaseService.setToRef(`users/${this.fbAuthService.currentUser.uid}/smsClubToken`, token)
      .pipe(
        map(data => token),
        take(1),
      );
  }

  removeSmsClubToken() {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.removeByRef(`users/${this.fbAuthService.currentUser.uid}/smsClubToken`);
    } else {
      return throwError(new Error('No user!'));
    }
  }

  getSmsClubToken() {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.getSnapshotByRef(`users/${this.fbAuthService.currentUser.uid}/smsClubToken`);
    } else {
      return throwError(new Error('No user!'));
    }
  }

  savePromToken(token: string) {
    return this.fbDatabaseService.setToRef(`users/${this.fbAuthService.currentUser.uid}/promToken`, token);
  }

  removePromToken() {
    return this.fbDatabaseService.removeByRef(`users/${this.fbAuthService.currentUser.uid}/promToken`);
  }

  getPromToken() {
    return this.fbDatabaseService.getSnapshotByRef(`users/${this.fbAuthService.currentUser.uid}/promToken`);
  }

  savePromDefaultData(data) {
    return this.fbDatabaseService.setToRef(`users/${this.fbAuthService.currentUser.uid}/promDefaultData`, data);
  }

  getPromDefaultData() {
    return this.fbDatabaseService.getSnapshotByRef(`users/${this.fbAuthService.currentUser.uid}/promDefaultData`);
  }

  createSmsInformingTemplate(name: string, content: string) {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.setToRef(`users/${this.fbAuthService.currentUser.uid}/smsClubTemplates/${name}`, {
        name,
        content,
        custom: true,
      });
    } else {
      return throwError(new Error('No user!'));
    }
  }

  getSmsInformingTemplates() {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.getArrayByRef(`users/${this.fbAuthService.currentUser.uid}/smsClubTemplates`);
    } else {
      return throwError(new Error('No user!'));
    }
  }

  removeSmsInformingTemplate(name: string) {
    if (this.fbAuthService.currentUser) {
      return this.fbDatabaseService.removeByRef(`users/${this.fbAuthService.currentUser.uid}/smsClubTemplates/${name}`);
    } else {
      return throwError(new Error('No user!'));
    }
  }

}

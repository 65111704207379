import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'np-change-ew-contact-input',
  templateUrl: 'change-ew-contact-input.component.html',
  styleUrls: ['./change-ew-contact-input.component.scss'],
})
export class ChangeEwContactInputComponent {
  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Input()
  disableCreation = false;

  @Input()
  BlockChangeSenderDataIfAvailableCargoTypeMoney = false;

  @Input()
  showControls = true;

  @Output()
  onCreate = new EventEmitter<string>();

  create() {
    this.onCreate.next('create');
  }
}

<div class="checkboxes-group" [formGroup]="selectedDocumentGroup">
  <mat-checkbox *ngIf="selectedDocumentGroup.value.services.stamp"
                [checked]="selectedDocumentGroup.value.services.stamp.checked"
                [disabled]="checkDisableState(selectedDocumentGroup.value.services.stamp)"
                (change)="check('stamp', $event.checked)">Печатка</mat-checkbox>
  <mat-checkbox *ngIf="selectedDocumentGroup.value.services.sign"
                [checked]="selectedDocumentGroup.value.services.sign.checked"
                [disabled]="checkDisableState(selectedDocumentGroup.value.services.sign)"
                (change)="check('sign', $event.checked)">Підпис</mat-checkbox>
  <mat-checkbox *ngIf="selectedDocumentGroup.value.services.stampSign"
                [checked]="selectedDocumentGroup.value.services.stampSign.checked"
                [disabled]="checkDisableState(selectedDocumentGroup.value.services.stampSign)"
                (change)="check('stampSign', $event.checked)">Печатка і підпис</mat-checkbox>
</div>

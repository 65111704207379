import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@shared';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ConfirmDialogModernConfig {
  title?: string,
  text?: string,
  isHtml?: boolean,
  confirmBtnText?: string,
  cancelBtnText?: string,
  isAsync?: boolean,
  restoreFocus?: boolean,
  hasLoading?: boolean,
  callback?: () => any,
}

const confirmDialogModernDefault: ConfirmDialogModernConfig = {};

@Component({
  selector: 'app-confirm-dialog-modern',
  templateUrl: './confirm-dialog-modern.component.html',
  styleUrls: ['./confirm-dialog-modern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogModernComponent extends Unsubscriber {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this._loading.asObservable();

  public set loading(value: boolean) {
    this._loading.next(value);
  }

  constructor(
    private dialog: MatDialogRef<ConfirmDialogModernComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModernConfig,
  ) {
    super();
  }

  cancel(): void {
    this.dialog.close(false);
  }

  confirm(): void {
    this.loading = true;

    if (this.data.callback) {
      this.data.callback();
    }

    if (this.data.isAsync) {
      this.loading = true;
    } else {
      this.cancel();
    }
  }
}

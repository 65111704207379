<div [formGroup]="BackwardDeliveryDataGroup" class="services-list__form">
  <div class="form-row mr-bottom-16">
    <div *ngIf="displaySum" class="form-col form-col-1 form-col-1_with_symbol">
      <mat-form-field class="mat-block mat-block_with_symbol" data-symbol="грн">
        <input #sum
               (change)="change($event)"
               (input)="changeSum(sum.value)"
               [numeric]="true"
               [allowFloat]="false"
               [min]="minCostCash2Card"
               [max]="maxCostCash2Card"
               formControlName="RedeliveryString"
               matInput
               npNumber
               placeholder="Сума">
      </mat-form-field>
    </div>

    <div [matTooltip]="addCardTooltip"
         class="form-col form-col_with-btn"
         matTooltipPosition="below">
      <button (click)="openAddCardModal()"
              [disabled]="(isChangeEw && !CanChangeCash2Card) || isInternetOrderConfig || pendingAddCard"
              color="primary"
              mat-button>
        {{card?.value ? 'Змінити картку' : 'Додати картку'}}
      </button>
    </div>

    <ng-container *ngIf="card?.value">
      <div [class.change-ew-card-select]="isChangeEw"
           class="form-col card-list">
        <mat-form-field>
          <mat-select [disabled]="true"
                      [(ngModel)]="card"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Отримати на картку">
            <mat-option [value]="card">{{card.value.pan}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="control-icons control-icons_help pr-3">
        <div class="control-icons__item">
          <div class="material-tooltip">
            <i class="material-icons icon-help material-tooltip__btn cursor-pointer">help_outline</i>
            <div [class.display-left]="isChangeEw" class="material-tooltip__content">У разі незарахування коштів на
                                                                                     картку, ви зможете отримати
                                                                                     грошовий переказ готівкою з
                                                                                     наступного дня у будь-якому
                                                                                     відділенні Нової пошти. Ми
                                                                                     направимо вам повідомлення.
              <a href="https://novaposhta.ua/money_order" target="_blank">Детальніше</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="form-row">
    <div class="form-col">
      <div class="form-section__hint mb-0">
        Зверніть увагу! Використання карток фізичних осіб для здійснення підприємницької діяльності заборонено.
      </div>
    </div>
  </div>
</div>

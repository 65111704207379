import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertToastService } from '@services';
import { Unsubscriber } from '@shared';
import { fromEvent, take } from 'rxjs';

@Component({
  selector: 'app-payment-process-v3',
  templateUrl: './payment-process-v3.component.html',
  styleUrls: ['./payment-process-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentProcessV3Component extends Unsubscriber implements OnInit {
  @Input() url: string;

  @Output() paymentSuccess = new EventEmitter<void>();

  @Output() addCardSuccess: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    public dialogRef: MatDialogRef<PaymentProcessV3Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    public toast: AlertToastService,
  ) {
    super();
  }

  get iframeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url ?? this.data.url);
  }

  ngOnInit(): void {
    this.subscribeToPostMessage();
  }

  subscribeToPostMessage(): void {
    this.subscriptions = fromEvent(window, 'message')
      .subscribe((event: MessageEvent) => {
        if (event.data === 'close') {
          this.dialogRef.close();
          return;
        } else if (event.data === 'payment_success') {
          console.log('valid NovaPay event.data');
          this.paymentSuccess.next();
          return;
        }
        if (this.isJsonString(event.data)) {
          const data = JSON.parse(event.data);
          if (data?.pan && data?.id) {
            console.log('valid NovaPay event.data');
            this.addCardSuccess.next({ pan: data.pan, id: data.id, alias: data.alias ?? '' });
          }
          return;
        }

        this.toast.pushWarn('Сталася помилка обробки даних фрейму, будь ласка, спробуйте ще раз');
      });
  }

  private isJsonString(str): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import Rxmq, { Channel } from 'rxmq';
import { ShareTemplateMessageComponent } from '../../../components/dashboard/settings/components/settings-sms-service/share-template-message/share-template-message.component';
import { ShareInvoiceStateService } from '../../../services/registers/share-invoice-state.service';
import { AlertToastService } from '../../../services/alert-toast.service';
import { AnalyticEvent } from '../../interfaces/analytic-event';

const componentName = 'np-share-invoice-state';

@Component({
  selector: 'np-share-invoice-state',
  templateUrl: './share-invoice-state.component.html',
  styleUrls: ['./share-invoice-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareInvoiceStateComponent implements OnInit, OnDestroy {
  @Input() invoice: any;
  @Input() backMoney: number;
  @Input() paymentControl: number;
  @Input() estimatedDeliveryDate: Date;
  @Input() contactPhone: string;

  isShowShare = false;

  private close: any;

  constructor(
    private alertToastService: AlertToastService,
    private cd: ChangeDetectorRef,
    private element: ElementRef,
    private cbService: ClipboardService,
    private shareInvoiceStateService: ShareInvoiceStateService,
    private dialog: MatDialog,
  ) {}

  copy() {
    this.element.nativeElement.querySelector('textarea').select();
    this.alertToastService.pushSuccess('Cкопійовано');
    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.copy`)
      .next({
        eventLabel: `Скопіювати`,
        eventCategory: componentName,
        eventAction: 'click',
      });

    try {
      document.execCommand('copy');

      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.copy.success`)
        .next({
          eventLabel: `Скопіювати, успіх`,
          eventCategory: componentName,
          eventAction: 'click.success',
        });
    } catch (err) {
      this.alertToastService.pushSuccess('Помилка копіювання до буферу');

      Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
        'analitics',
      )
        .subject(`${componentName}.copy.error`)
        .next({
          eventLabel: `Скопіювати, помилка, браузур не підтримує`,
          eventCategory: componentName,
          eventAction: 'click.error',
        });
    }
  }

  ngOnInit() {
    this.close = () => {
      this.isShowShare = false;
      this.cd.markForCheck();
    };
    document.addEventListener('click', this.close);
  }

  getStatusText(): string {
    return this.shareInvoiceStateService.formText(this.invoice);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.close);
  }

  onWrapClick($event) {
    $event.stopPropagation();
  }

  share() {
    const dialogRef = this.dialog.open(ShareTemplateMessageComponent, {
      panelClass: 'no-padding',
    });

    dialogRef.componentInstance.invoice = this.invoice;
    dialogRef.componentInstance.estimatedDeliveryDate =
      this.estimatedDeliveryDate;
    dialogRef.componentInstance.contactPhone = this.contactPhone;
    dialogRef.componentInstance.backMoney = this.backMoney;
    dialogRef.componentInstance.paymentControl = this.paymentControl;

    Rxmq.channel<Channel<AnalyticEvent>, AnalyticEvent, AnalyticEvent>(
      'analitics',
    )
      .subject(`${componentName}.share`)
      .next({
        eventLabel: `Поділитися статусом`,
        eventCategory: componentName,
        eventAction: 'click',
      });
  }

  copyNumber(): void {
    this.shareInvoiceStateService.copyInvoiceDocNumber(this.invoice);
    this.alertToastService.pushSuccess('Номер скопійовано');
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormContainer } from '@shared';
import { Subscription } from 'rxjs';
import { DeliveryCostCheckPopupComponent } from '../delivery-cost-check-popup/delivery-cost-check-popup.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-afterpayment',
  templateUrl: './afterpayment.component.html',
  styleUrls: ['./afterpayment.component.scss'],
})
export class AfterpaymentComponent extends FormContainer implements OnInit, OnDestroy {
  @Input() controlName = 'AfterpaymentOnGoodsCost';
  @Input() invoiceId: string;
  @Input() showTooltipAfterpayment: boolean;

  afterpaymentGroup: UntypedFormGroup;

  costSubscription: Subscription;

  targetValue: number;

  @Input()
  isChangeEw = false;

  constructor(protected formBuilder: UntypedFormBuilder, public dialog: MatDialog) {
    super();
  }

  get stepAddressControl(): AbstractControl {
    return this.form.parent.get('stepAddressForm');
  }

  get AdditionalServices(): AbstractControl {
    return this.stepAddressControl.get('AdditionalServices');
  }

  get costControl(): AbstractControl {
    return this.isChangeEw ? this.form.get('Cost') : this.form?.parent?.get('stepDeliveryForm')?.get('Cost');
  }

  get costValue(): number {
    return !isNaN(this.costControl?.value) ? this.costControl.value : 0;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  change(event) {
    this.targetValue = event.target.value.replace(/,/gi, '.');
    if (+this.targetValue > +this.costValue) {
      this.openDialog(this.targetValue);
    }
  }

  openDialog(value) {
    const dialogRef: MatDialogRef<DeliveryCostCheckPopupComponent> = this.dialog.open(DeliveryCostCheckPopupComponent, {
      panelClass: 'no-padding',
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.cost = value;
    dialogRef.componentInstance.announcedCost = this.costValue;
    dialogRef.componentInstance.type = this.controlName;

    this.subscriptions = dialogRef.componentInstance.onAdd.subscribe(() => {
      if (+value > this.costValue) {
        this.form.parent.get('stepDeliveryForm').get('Cost').setValue(value);
        this.form.parent.get('stepDeliveryForm').get('Cost').updateValueAndValidity({ onlySelf: true });
      }
    });

    this.subscriptions = dialogRef.componentInstance.onClear.subscribe(() => {
      this.afterpaymentGroup.get(this.controlName).setValue(this.costValue);
      this.afterpaymentGroup.get(this.controlName).updateValueAndValidity({ onlySelf: true });
    });

    dialogRef.afterClosed();
  }

  addFormControls() {
    this.afterpaymentGroup = this.form.get('AdditionalServices').get('afterpayment') as UntypedFormGroup;
    const cost = this.costValue;
    this.afterpaymentGroup.addControl(
      this.controlName,
      new UntypedFormControl(
        cost,
        Validators.compose([Validators.required, Validators.min(0), Validators.max(1000000)]),
      ),
    );
  }

  ngOnDestroy() {
    this.afterpaymentGroup.removeControl(this.controlName);
    if (this.costSubscription) {
      this.costSubscription.unsubscribe();
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { AbstractFormContainer } from '@shared';
import * as moment from 'moment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent extends AbstractFormContainer implements OnInit, OnDestroy {
  @Input() controlName = 'DateTime';

  dateTimeGroup: UntypedFormGroup;

  minDate = moment().toDate();

  maxDate = moment().add(6, 'days').toDate();

  constructor(private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('uk-UA');
  }

  get control(): AbstractControl {
    return this.dateTimeGroup.get(this.controlName);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addFormControls() {
    this.dateTimeGroup = this.form.get('AdditionalServices').get('dateTime') as UntypedFormGroup;
    this.dateTimeGroup.addControl(
      this.controlName,
      new UntypedFormControl('', Validators.compose([Validators.required])),
    );
  }

  ngOnDestroy() {
    this.dateTimeGroup.removeControl(this.controlName);
  }
}

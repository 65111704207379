import { AutocompleteConfig } from './autocomplete.interfaces';
import { AutocompleteControls } from '@models';

export const DEFAULT_AUTOCOMPLETE_SIZE = 8;

export const OPTIONS_TO_ENABLE_SEARCH = 20;

export const SEARCH_DEBOUNCE_TIME = 300;

export const SEARCH_MAX_LENGTH = 255;

export const DefaultConfig: AutocompleteConfig = {
  arrow: false,
  hint: '',
  ariaLabel: '',
  placeholder: 'Оберіть зі списку',
  focused: false,
};

export const DefaultControlsConfig: AutocompleteControls = {
  edit: {
    enabled: false,
    tooltip: 'Редагувати',
  },
  create: {
    enabled: false,
    tooltip: 'Створити',
  },
};

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FormContainer } from '@shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'np-inv-delivery-pallet-seats',
  templateUrl: './delivery-pallet-seats.component.html',
  styleUrls: ['./delivery-pallet-seats.component.scss'],
})
export class DeliveryPalletSeatsComponent
  extends FormContainer
  implements OnInit, OnDestroy
{
  seatsValue = '1';

  @Input() controlName = 'seats';
  @Input() isTemplate: boolean;
  @Input() invoiceId: string;
  @Output() seatsChange = new EventEmitter();

  get seatsControl(): UntypedFormControl {
    return this.form?.get(this.controlName) as UntypedFormControl;
  }

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  @Input()
  get seats() {
    return this.seatsValue;
  }

  set seats(val) {
    this.seatsValue = val;
    this.seatsChange.emit(this.seatsValue);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addFormControls(): void {
    this.form.setControl(
      this.controlName,
      this.fb.control('1', [Validators.required, Validators.min(1)]),
    );
    this.subscriptions = this.form
      .get(this.controlName)
      .valueChanges.subscribe((v) => (this.seats = v));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form.removeControl(this.controlName);
  }

  preventDecimal(event) {
    return (
      (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 0
    );
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog.component';

export interface ConfirmDialogOptions {
  confirmMsg?: string;
  confirmTitle?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  isConfirmHtml?: boolean;
}

@Injectable()
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) {
  }

  confirm(data: ConfirmDialogOptions): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'no-padding',
      data,
    });
    return dialogRef.afterClosed()
      .pipe(take(1));
  }
}

export interface InvoiceStateConfig {
  id: string;
  shortName: string;
  isPayAllowed: boolean;
}
// @TODO remove this mapper when possible. It leads to weird invoices sorting
export const states: { [key: string]: InvoiceStateConfig } = {
  1: {
    id: '1',
    shortName: 'В обробці',
    isPayAllowed: true,
  },
  2: {
    id: '6',
    shortName: 'Видалено',
    isPayAllowed: false,
  },
  3: {
    id: '6',
    shortName: 'Номер не знайдено',
    isPayAllowed: false,
  },
  4: {
    id: '2',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  41: {
    id: '2',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  5: {
    id: '3',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  6: {
    id: '3',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  7: {
    id: '4',
    shortName: 'Прибув у відділення',
    isPayAllowed: true,
  },
  8: {
    id: '4',
    shortName: 'Прибув у відділення',
    isPayAllowed: true,
  },
  101: {
    id: '3',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  9: {
    id: '5',
    shortName: 'Отримано',
    isPayAllowed: false,
  },
  10: {
    id: '5',
    shortName: 'Отримано',
    isPayAllowed: false,
  },
  11: {
    id: '5',
    shortName: 'Отримано',
    isPayAllowed: false,
  },
  14: {
    id: '4',
    shortName: 'Отримано',
    isPayAllowed: false,
  },
  104: {
    id: '5',
    shortName: 'Змінено адресу',
    isPayAllowed: false,
  },
  106: {
    id: '5',
    shortName: 'Отримано',
    isPayAllowed: false,
  },
  102: {
    id: '6',
    shortName: 'Відмова одержувача',
    isPayAllowed: true,
  },
  103: {
    id: '6',
    shortName: 'Відмова одержувача',
    isPayAllowed: true,
  },
  108: {
    id: '6',
    shortName: 'Відмова одержувача',
    isPayAllowed: true,
  },
  105: {
    id: '6',
    shortName: 'Припинено зберігання',
    isPayAllowed: false,
  },
  107: {
    id: '6',
    shortName: 'Нараховується плата за зберігання',
    isPayAllowed: true,
  },
  12: {
    id: '1',
    shortName: 'В обробці',
    isPayAllowed: true,
  },
  111: {
    id: '3',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
  112: {
    id: '3',
    shortName: 'У дорозі',
    isPayAllowed: true,
  },
};
